import { MetricTreeNodeTypes } from 'data/metrics/types';
import { type PermissionList } from 'data/roles/permissions';
import { type ReactText } from 'react';
import { TemplateElementType } from './types';

export const RowTypeToTemplateTypeMap = {
  [MetricTreeNodeTypes.Metric]: TemplateElementType.Metric,
  [MetricTreeNodeTypes.Blank]: TemplateElementType.Blank,
  [MetricTreeNodeTypes.Dimension]: null,
  [MetricTreeNodeTypes.Section]: TemplateElementType.Section,
  [MetricTreeNodeTypes.SectionMetric]: null,
  [MetricTreeNodeTypes.TempRow]: null,
};

const BASE_QUERY_KEY = 'templates';

export const TemplateStoreKeys = {
  QueryKey: BASE_QUERY_KEY,
  SingleRecord: ({
    resource,
    resourceId,
  }: {
    resource: PermissionList;
    resourceId: ReactText;
  }): (ReactText | undefined | null)[] => [TemplateStoreKeys.QueryKey, resource, resourceId],
};
