import { PorscheThemeWrapper } from 'components/ui/porsche-theme-wrapper';
import { type ReactElement, type ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '../button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionsWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing[24]};
  margin-right: ${({ theme }) => theme.spacing[4]};
`;

interface Props {
  message: ReactNode;
  defaultAction?: () => void;
  primaryAction: () => void;
  primaryLabel: string;
  cleanUp(): void;
}

export const TextAndButtons = ({
  message,
  primaryAction,
  primaryLabel,
  cleanUp,
}: Props): ReactElement => {
  return (
    <PorscheThemeWrapper>
      <Wrapper>
        {message}

        <ActionsWrapper>
          <Button
            size="extraSmall"
            type="primary"
            onClick={() => {
              primaryAction();
              cleanUp();
            }}
          >
            {primaryLabel}
          </Button>
        </ActionsWrapper>
      </Wrapper>
    </PorscheThemeWrapper>
  );
};
