import { useQuery } from '@tanstack/react-query';
import { type User, UsersApi } from 'data/users';

export const useUser = (): {
  user: User;
  status: 'error' | 'success' | 'loading';
  isDtUser: boolean;
} => {
  const { data: user = {} as User, status } = useQuery(
    ['current-user'],
    () => UsersApi.currentUser(),
    {
      staleTime: Infinity,
    },
  );

  const isDtUser = user.username?.endsWith('@drivetrain.ai');

  return {
    status,
    user,
    isDtUser,
  };
};
