import { type ReactElement } from 'react';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';

const Box = styled.div<Props>`
  cursor: pointer;
  height: ${({ size }) => (size === 'small' ? '12px' : '24px')};
  width: ${({ size }) => (size === 'small' ? '12px' : '24px')};
  background-color: ${({ color }) => color};
  border-radius: ${({ size, theme: { borderRadius } }) => {
    return size === 'small' ? borderRadius.xs : borderRadius.m;
  }};
  outline-offset: 2px;
  outline: ${({ theme, isActive }) => {
    if (isActive) {
      return `1px solid ${theme.colors.primary500}`;
    }

    return 'none';
  }};
`;

interface Props {
  color?: string;
  isActive?: boolean;
  size?: 'small' | 'medium';
  onClick?: (color: string) => void;
}

export const ColorBox = ({
  color = defaultTheme.colors.gray00,
  isActive = false,
  onClick,
  size,
}: Props): ReactElement => {
  return <Box color={color} isActive={isActive} size={size} onClick={() => onClick?.(color)} />;
};
