import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import {
  type MetricApplicableDimensionValue,
  type MetricApplicableDimension,
} from 'data/dimension';
import { SCENARIO, type Scenario } from 'data/scenarios';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { MetricsApi } from './api';
import { MetricStoreKeys } from './constants';
import { type MetricDefinition, type MetricInfoType, type MetricsMetaResponseData } from './types';

interface Props {
  metricName?: string;
  fetchDimensions?: boolean;
}

export const useMetricDefinition = ({
  metricName = '',
  fetchDimensions = true,
}: Props): UseQueryResult<MetricDefinition, unknown> => {
  return useQuery(
    ['metric-definition', metricName],
    () => MetricsApi.getDefinition(metricName, fetchDimensions),
    {
      enabled: Boolean(metricName),
    },
  );
};

export const useMetrics = (): UseQueryResult<MetricsMetaResponseData[], unknown> => {
  return useQuery(MetricStoreKeys.AllRecords(), () => MetricsApi.getMetrics());
};

export const useMetricInfoQuery = (
  metrics: { displayName: string; name: string }[],
): UseQueryResult<MetricInfoType[]> =>
  useQuery(['metrics-info', metrics.map((metric) => metric.name).join(',')], () =>
    Promise.allSettled(metrics.map((metric) => MetricsApi.fetchInfo(metric.name))).then((data) =>
      data.map((d) => (d.status === 'fulfilled' ? d.value : {})),
    ),
  );

export const useGetMetricSpaces = (
  metricName?: string,
): UseQueryResult<{ dimensions: MetricApplicableDimension[]; scenarios?: Scenario[] }, unknown> => {
  return useQuery(
    ['metric-dimensions', metricName],
    () => {
      if (!metricName) {
        return {};
      }

      return MetricsApi.fetchSpaces(metricName);
    },
    { enabled: !!metricName, onError: defaultApiErrorHandler, staleTime: StaleTime.Shortest },
  );
};

export const useGetMetricDimensionValues = (
  metricName: string,
  dimensionName: string,
): UseQueryResult<MetricApplicableDimensionValue[], unknown> => {
  return useQuery(
    ['metric-dimension-values', metricName, dimensionName],
    () => {
      if (!metricName || !dimensionName || dimensionName === SCENARIO) {
        return [];
      }

      return MetricsApi.fetchDimensionValues(metricName, dimensionName);
    },
    {
      enabled: !!metricName && !!dimensionName,
      onError: defaultApiErrorHandler,
      staleTime: StaleTime.Shortest,
    },
  );
};
