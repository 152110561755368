import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ReactComponent as FolderOpenIcon } from 'assets/v2/folder-open.svg';
import { ReactComponent as FolderIcon } from 'assets/v2/folder.svg';
import { Tooltip, IconShell } from 'components/ui/atomic-components';
import { SortableItem } from 'components/ui/dnd-kit/sortable-item';
import { PermissionList } from 'data/roles/permissions';
import { type MouseEventHandler, type ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHasScope } from 'utils/hooks';
import { EmojiRenderer } from '../../emoji-picker/emoji-render';
import { type ItemType } from '../../menu';
import { styles as directoryTreeNodeStyles } from '../directory-tree-node/styles';
import { type DirectoryTreeNodeDataType } from '../types';
import { DirectoryNodeLeftSectionWrapper } from './left-section-wrapper';
import { useDirectoryDragDrop } from './use-directory-drag-drop';

const Wrapper = styled.div<{ hideLine?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ::after {
    content: '';
    background-color: ${({ theme }) => theme.colors.gray200};
    position: absolute;
    top: ${({ theme }) => theme.spacing[0]};
    bottom: ${({ theme }) => theme.spacing[24]};
    left: ${({ theme }) => theme.spacing[12]};
    width: ${({ hideLine }) => (hideLine ? '0' : '1px')};
  }
`;

const TooltipWrapper = styled.div`
  width: calc(100% - 24px);
  height: 100%;
`;

interface Props {
  treeData: DirectoryTreeNodeDataType[];
  selectedKeys?: (string | number)[];
  expandedKeys?: (string | number)[];
  defaultExpandAll?: boolean;
  textToHighlight?: string;
  level?: number;
  enableDragAndDrop?: boolean;
  overflowMenuItems?: (
    directoryTreeNodeData: DirectoryTreeNodeDataType,
    level: number,
  ) => ItemType[];
  onSelect?: (directoryTreeNode: DirectoryTreeNodeDataType) => void;
}

export const Directory = ({
  treeData,
  selectedKeys,
  expandedKeys,
  defaultExpandAll,
  enableDragAndDrop,
  textToHighlight,
  level = 0,
  overflowMenuItems,
  onSelect,
}: Props): ReactElement => {
  const { sensors, onDragEnd, onDragStart, directoryTree } = useDirectoryDragDrop(treeData);

  return (
    <Wrapper hideLine={level === 0}>
      <DndContext sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <SortableContext
          disabled={!enableDragAndDrop}
          items={directoryTree.map((t) => String(t.key))}
          strategy={verticalListSortingStrategy}
        >
          {directoryTree.map((directoryTreeNode, idx) => (
            <DirectoryTreeNode
              key={directoryTreeNode.key}
              defaultExpandAll={defaultExpandAll}
              directoryTreeNode={directoryTreeNode}
              expandedKeys={expandedKeys}
              isLastNodeFolder={!directoryTreeNode.isLeaf && idx === directoryTree.length - 1}
              level={level}
              overflowMenuItems={overflowMenuItems}
              selectedKeys={selectedKeys}
              textToHighlight={textToHighlight}
              onSelect={onSelect}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};

const {
  DirectoryNodeWrapper,
  DirectoryNodeContent,
  DirectoryNodeTitle,
  StyledMoreActionsDropdown,
} = directoryTreeNodeStyles;

interface Props1 {
  directoryTreeNode: DirectoryTreeNodeDataType;
  selectedKeys?: (string | number)[];
  expandedKeys?: (string | number)[];
  defaultExpandAll?: boolean;
  textToHighlight?: string;
  isLastNodeFolder?: boolean;
  level: number;
  overflowMenuItems?: (
    directoryTreeNodeData: DirectoryTreeNodeDataType,
    level: number,
  ) => ItemType[];
  onSelect?: (directoryTreeNode: DirectoryTreeNodeDataType) => void;
}

const DirectoryTreeNode = ({
  directoryTreeNode,
  selectedKeys,
  expandedKeys,
  defaultExpandAll,
  textToHighlight,
  isLastNodeFolder,
  level,
  overflowMenuItems,
  onSelect,
}: Props1): ReactElement => {
  const nodeContentRef = useRef<HTMLDivElement>(null);
  const hasScope = useHasScope();

  const [showSubTree, setShowSubTree] = useState(
    defaultExpandAll || expandedKeys?.includes(directoryTreeNode.key),
  );

  const isNodeActive = directoryTreeNode.isLeaf && selectedKeys?.includes(directoryTreeNode.key);

  const menuItems = overflowMenuItems?.(directoryTreeNode, level);

  const handleNodeClick: MouseEventHandler = (e) => {
    setShowSubTree(!showSubTree);
    onSelect?.(directoryTreeNode);
  };

  const handleMoreActionsFocus = (focus: boolean) => {
    if (focus) {
      nodeContentRef.current?.classList.add(isNodeActive ? 'node-active-focus' : 'node-focus');
    } else {
      nodeContentRef.current?.classList.remove('node-focus', 'node-active-focus');
    }
  };

  useEffect(() => {
    setShowSubTree(defaultExpandAll || expandedKeys?.includes(directoryTreeNode.key));
  }, [defaultExpandAll, directoryTreeNode.key, expandedKeys]);

  return (
    <DirectoryNodeWrapper hideLine={isLastNodeFolder}>
      <SortableItem id={String(directoryTreeNode.key)} locked={!directoryTreeNode.isLeaf}>
        {(isDragging) => (
          <DirectoryNodeContent
            ref={nodeContentRef}
            className={isNodeActive ? 'node-active' : undefined}
            hideLine={level === 0}
            isDragging={isDragging}
            style={{ pointerEvents: isDragging ? 'none' : undefined }}
          >
            <Tooltip
              align={{ offset: [40] }}
              mouseEnterDelay={1.5}
              placement="right"
              title={directoryTreeNode.title}
            >
              <TooltipWrapper>
                <DirectoryNodeLeftSectionWrapper
                  linkTo={directoryTreeNode.linkTo}
                  onClick={handleNodeClick}
                >
                  <span>
                    {directoryTreeNode.icon || directoryTreeNode.isLeaf ? (
                      <EmojiRenderer
                        id={(directoryTreeNode.icon || 'bar_chart') as string}
                        size="13px"
                      />
                    ) : (
                      <IconShell icon={showSubTree ? FolderOpenIcon : FolderIcon} />
                    )}
                  </span>

                  <DirectoryNodeTitle
                    highlightClassName="highlight"
                    searchWords={[textToHighlight]}
                    textToHighlight={directoryTreeNode.title}
                    unhighlightClassName="unhighlight"
                  />
                </DirectoryNodeLeftSectionWrapper>
              </TooltipWrapper>
            </Tooltip>

            <StyledMoreActionsDropdown
              className="more-actions"
              iconColor="iconHover"
              menu={{
                items: menuItems,
                onClick: () =>
                  nodeContentRef.current?.classList.remove('node-focus', 'node-active-focus'),
              }}
              size="extraSmall"
              onOpenChange={handleMoreActionsFocus}
            />
          </DirectoryNodeContent>
        )}
      </SortableItem>

      {showSubTree && directoryTreeNode.children && (
        <Directory
          defaultExpandAll={defaultExpandAll}
          enableDragAndDrop={hasScope(PermissionList.All)}
          expandedKeys={expandedKeys}
          level={level + 1}
          overflowMenuItems={overflowMenuItems}
          selectedKeys={selectedKeys}
          textToHighlight={textToHighlight}
          treeData={directoryTreeNode.children}
          onSelect={onSelect}
        />
      )}
    </DirectoryNodeWrapper>
  );
};
