import { StateField } from '@codemirror/state';
import { type Tooltip, showTooltip } from '@codemirror/view';
import { getCursorTooltip } from './utils/get-cursor-tooltip';

export const functionInfoTooltip = StateField.define<readonly Tooltip[]>({
  create: getCursorTooltip,

  update(tooltips, tr) {
    if (!tr.docChanged && !tr.selection) {
      return tooltips;
    }

    return getCursorTooltip(tr.state);
  },

  provide: (f) => showTooltip.computeN([f], (state) => state.field(f)),
});
