import { ReactComponent as WarningIcon } from 'assets/ic_alert-error.svg';
import { BackButton, IconText, Space } from 'components/ui/atomic-components';
import { Layout } from 'components/ui/atomic-components/layout/index';
import { PageLayout } from 'components/ui/layout/layout-v2';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

const { EmphasizedContainer, Wrapper, SubContainer, Title, Description, SecondaryTitle } = styles;

export const LoginHelp: FC<React.PropsWithChildren<unknown>> = () => {
  const br = <br />;
  const b = (text: string) => <b>{text}</b>;
  const i = (text: string) => <i>{text}</i>;
  const em = (text: string) => (
    <EmphasizedContainer>
      <IconText icon={WarningIcon} size="lg">
        {text}
      </IconText>
    </EmphasizedContainer>
  );

  return (
    <PageLayout>
      <Layout>
        <Wrapper>
          <SubContainer>
            <Space direction="vertical">
              <Space>
                <BackButton to="login" />
                <FormattedMessage id="login.help.go_back" />
              </Space>
              <Title>
                <FormattedMessage id="login.help.create_account" />
              </Title>
              <Description>
                <FormattedMessage id="login.help.desc" />
              </Description>

              <SecondaryTitle>
                <FormattedMessage id="login.help.signing_in" />
              </SecondaryTitle>
              <Description>
                <FormattedMessage id="login.help.list" values={{ b, br, em }} />
              </Description>
              <Description>
                <FormattedMessage id="login.help.reminder" />
              </Description>
              <Description>
                <FormattedMessage id="login.help.desc_error" values={{ i }} />
              </Description>
              <SecondaryTitle>
                <FormattedMessage id="login.help.forgot_password" />
              </SecondaryTitle>
              <Description>
                <FormattedMessage id="login.help.forgot_password.desc" values={{ b, br }} />
              </Description>
            </Space>
          </SubContainer>
        </Wrapper>
      </Layout>
    </PageLayout>
  );
};
