import { type UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ResourceUsageApi } from '../api';
import { type ResourceUsageResp, type ResourceUsageType } from '../types';

export const useLoadResourceUsage = ({
  name,
  type,
  options,
}: {
  name?: string;
  type?: ResourceUsageType;
  options: { enabled?: boolean; refetchOnWindowFocus?: boolean };
}): UseQueryResult<ResourceUsageResp, unknown> => {
  const query = useQuery({
    queryKey: ['resource-usage', name, type],
    queryFn: () => {
      if (!name || !type) {
        throw 'name and type not passed';
      }

      return ResourceUsageApi.checkUsages(name, type);
    },
    staleTime: StaleTime.Shortest,
    ...options,
  });

  return query;
};

export const usePrefetchResourceUsage = (): ((
  name: string,
  type: ResourceUsageType,
) => Promise<ResourceUsageResp>) => {
  const queryClient = useQueryClient();

  const prefetch = (name: string, type: ResourceUsageType) =>
    queryClient.fetchQuery({
      queryKey: ['resource-usage', name, type],
      queryFn: () => ResourceUsageApi.checkUsages(name, type),
    });

  return prefetch;
};
