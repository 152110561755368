import { Tag as AntdTag } from 'antd';
import styled, { css } from 'styled-components';
import { switchProp, prop } from 'styled-tools';
import { Caption, P } from 'styles/typography';
import { Button } from '../button';
import { type ColorKey } from '../commons';
import { textColor } from '../utils/text-color';
import { type TagProps } from './types';

type Props = Omit<TagProps, 'isDragging'> & { $isDragging?: boolean };

export const styles = {
  Tag: styled(AntdTag)<Props>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: relative;
    background: ${({ theme, disabled, color }) => {
      return disabled ? theme.colors.black40 : theme.colors[(color || 'black20') as ColorKey];
    }};
    color: ${({ color, disabled }) => textColor(color, disabled)} !important;
    box-shadow: ${({ theme, $isDragging }) => {
      return $isDragging ? theme.boxShadow[24] : 'none';
    }};
    border: none;
    padding: ${({ theme: { spacing } }) => `0 ${spacing[8]}`};

    ${switchProp(prop('size', 'middle'), {
      small: css`
        ${Caption};

        height: ${({ theme }) => theme.height.sm}px;

        ${({ closable }: TagProps) =>
          closable &&
          css`
            padding-right: ${({ theme: { spacing, height } }) =>
              height.sm + parseInt(spacing[4])}px;
          `}
      `,
      middle: css`
        ${Caption};

        height: ${({ theme }) => theme.height.md}px;

        ${({ closable }: TagProps) =>
          closable &&
          css`
            padding-right: ${({ theme: { spacing, height } }) =>
              height.md + parseInt(spacing[4])}px;
          `}
      `,
      large: css`
        ${P};

        height: ${({ theme }) => theme.height.lg}px;

        ${({ closable }: TagProps) =>
          closable &&
          css`
            padding-right: ${({ theme: { spacing, height } }) =>
              height.lg + parseInt(spacing[4])}px;
          `}
      `,
    })};

    .anticon-close.ant-tag-close-icon {
      display: none;
    }
  `,

  ImageIconWrapper: styled.div`
    display: inline-flex;
    margin-right: ${({ theme: { spacing } }) => spacing[8]};
  `,

  TagCloseBtnIcon: styled(Button)`
    &&& {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: transparent;
      height: 100%;
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      color: ${({ theme, disabled }) => {
        return disabled ? theme.colors.textTertiary : 'inherit';
      }};
      border-radius: ${({ theme: { border } }) => `0 ${border.radius.sm} ${border.radius.sm} 0`};
      border-bottom-left-radius: 0;
      border: none;
      box-shadow: none;

      :hover {
        background: ${({ theme, disabled }) => {
          return disabled ? theme.colors.black40 : theme.colors.red20;
        }};
        color: ${({ theme, disabled }) => {
          return disabled ? theme.colors.textTertiary : theme.colors.red60;
        }};
      }

      svg {
        color: inherit;
      }
    }
  `,
};
