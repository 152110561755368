import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { ListsApi, type ListRow } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';

export const useListReconMatchOptions = (): UseQueryResult<ListRow[], unknown> => {
  const listId = useListStoreContext((s) => s.id);

  return useQuery(['list-recon-match-options', listId], () =>
    ListsApi.getListReconMatchOptions(listId),
  );
};
