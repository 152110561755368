import { type DrawerProps as AntdDrawerProps } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { type ReactNode, type FC } from 'react';
import { IconShell } from '../icon-shell';
import { ModalFooter } from '../modal/footer';
import { SizeMapping } from './constants';
import { Drawer as DrawerAtom, IconContainer } from './styles';

interface DrawerProps extends Omit<AntdDrawerProps, 'size'> {
  size?: 'sm' | 'md' | 'lg';
  cancelText?: ReactNode;
  confirmLoading?: boolean;
  okText?: ReactNode;
  okDisabled?: boolean;
  footerExtra?: ReactNode;
  onOk?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const Drawer: FC<React.PropsWithChildren<DrawerProps>> = ({
  size = 'md',
  cancelText = '',
  confirmLoading,
  okText = '',
  onOk,
  okDisabled,
  footerExtra,
  ...props
}) => {
  return (
    <DrawerAtom
      closeIcon={
        <IconContainer aria-label="close">
          <IconShell icon={CloseIcon} />
        </IconContainer>
      }
      footer={
        (cancelText || okText) && (
          <ModalFooter
            cancelText={cancelText}
            confirmLoading={confirmLoading}
            footerExtra={footerExtra}
            okDisabled={okDisabled}
            okText={okText}
            onCancel={props?.onClose}
            onOk={onOk}
          />
        )
      }
      rootClassName={props.className}
      width={SizeMapping[size]}
      {...props}
    />
  );
};
