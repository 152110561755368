import { type EditorView } from '@codemirror/view';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { type Granularity } from 'data';
import { type MetricApplicableDimension, TimeDimensionName } from 'data/dimension';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { formatName } from 'utils/data-formatter';
import { DimensionSelector } from './dimension-selector';
import { DimensionValueSelector } from './dimension-value-selector';
import { DimensionValueSingleSelect } from './dimension-value-single-select';
import { TimeValueSelector } from './time-value-selector';
import { getSelectedValue } from './utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
`;

interface Props {
  metricName: string;
  timeFilterGranularity: Granularity;
  initialSelectionsMap: Record<string, string[]>;
  onChange: (map: Record<string, string[]>) => void;
  closeMenu?: () => void;
  setCustomTimeEditorView?: (editorView: EditorView | null) => void;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
}

// temporarily adding the flag until tess supports it
const disableFormulaMultiselect = true;

export const DimensionsSelectorMenu = ({
  metricName,
  timeFilterGranularity,
  initialSelectionsMap,
  onChange,
  closeMenu,
  setCustomTimeEditorView,
  customTimeAutoCompletionOptions,
}: Props): ReactElement => {
  const [selectedDimension, setSelectedDimension] = useState<MetricApplicableDimension | null>(
    null,
  );
  const [selectionsMap, setSelectionsMap] =
    useState<Record<string, string[]>>(initialSelectionsMap);
  const [isAllSelected, setIsAllSelected] = useState(
    !selectedDimension || !selectionsMap[selectedDimension.name],
  );

  const handleDimensionValueSelect = (selectedValue: string) => {
    if (!selectedDimension) {
      return;
    }

    const selectionsMapState = { ...selectionsMap };

    if (!selectionsMapState[selectedDimension.name]) {
      selectionsMapState[selectedDimension.name] = [];
    }

    if (selectionsMapState[selectedDimension.name].includes(selectedValue)) {
      selectionsMapState[selectedDimension.name] = selectionsMapState[
        selectedDimension.name
      ].filter((val) => val !== selectedValue);
    } else {
      selectionsMapState[selectedDimension.name].push(selectedValue);
    }

    setSelectionsMap(selectionsMapState);
  };

  const handleDimensionValueSelectAllToggle = () => {
    if (!selectedDimension) {
      return;
    }

    const checked = !isAllSelected;
    const selectionsMapState = { ...selectionsMap };

    setIsAllSelected(checked);
    setSelectionsMap(selectionsMapState);
  };

  const handleEditorViewUpdate = (selectionsMapState: Record<string, string[]>) => {
    onChange(selectionsMapState);
    closeMenu?.();
  };

  const handleTimeValueChange = (value: string) => {
    if (!value) {
      closeMenu?.();

      return;
    }

    const selectionsMapState = { ...selectionsMap };

    selectionsMapState[TimeDimensionName] = [value];

    setSelectionsMap(selectionsMapState);
    handleEditorViewUpdate?.(selectionsMapState);
    closeMenu?.();
  };

  return (
    <Wrapper>
      {!selectedDimension && (
        <DimensionSelector
          metricName={metricName}
          selectionsMap={selectionsMap}
          timeFilterGranularity={timeFilterGranularity}
          onClickDimension={(dim) => setSelectedDimension(dim)}
        />
      )}

      {selectedDimension &&
        selectedDimension.name !== TimeDimensionName &&
        (disableFormulaMultiselect ? (
          <DimensionValueSingleSelect
            dimensionName={selectedDimension.name}
            metricName={metricName}
            selectedValue={getSelectedValue(selectionsMap[selectedDimension.name]?.[0])}
            title={formatName(selectedDimension.name)}
            onClickBack={() => setSelectedDimension(null)}
            onMenuItemClick={(val) => {
              const selectionsMapState = { ...selectionsMap };

              selectionsMapState[selectedDimension.name] = [val];

              setSelectionsMap(selectionsMapState);
              handleEditorViewUpdate({ ...selectionsMapState });
            }}
          />
        ) : (
          <DimensionValueSelector
            isAllSelected={isAllSelected}
            metricName={metricName}
            selectedDimension={selectedDimension}
            selectedValues={selectionsMap[selectedDimension.name]}
            onClickApply={() => handleEditorViewUpdate({ ...selectionsMap })}
            onClickBack={() => setSelectedDimension(null)}
            onItemSelect={handleDimensionValueSelect}
            onSelectAllToggle={handleDimensionValueSelectAllToggle}
          />
        ))}

      {selectedDimension?.name === TimeDimensionName && (
        <TimeValueSelector
          customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
          setCustomEditorView={setCustomTimeEditorView}
          timeFilterGranularity={timeFilterGranularity}
          value={selectionsMap[TimeDimensionName]?.[0]}
          onClickBack={() => setSelectedDimension(null)}
          onCustomValueEnter={handleTimeValueChange}
          onItemSelect={handleTimeValueChange}
        />
      )}
    </Wrapper>
  );
};
