import { DEPLOY_ENV } from 'config/environment';
import { type User } from 'data/users';
import zipy from 'zipyai';

const isProd = DEPLOY_ENV === 'production';

if (isProd) {
  zipy.init('8ce9ebd7');
}

export const setupZipy = (user: User): void => {
  zipy.identify(`${user.username}_${user.tenant}`, {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.username,
    tenantId: user.tenant,
    domain: window.location.hostname,
  });
};
