import { Button } from 'components/ui/atomic-components';
import { EntityStatus } from 'data';
import { type ListUpdate } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  listUpdate: ListUpdate;
  onAccept: () => void;
}

export const AcceptButton = ({ listUpdate, onAccept }: Props): ReactElement => {
  return (
    <Button size="small" type="primary" onClick={onAccept}>
      {listUpdate.status === EntityStatus.Active ? (
        <FormattedMessage id="accept" />
      ) : (
        <FormattedMessage id="close" />
      )}
    </Button>
  );
};
