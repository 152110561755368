import { type PropsWithChildren, type MouseEventHandler, type ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[4]};
  color: inherit;

  > span > svg {
    vertical-align: sub;
  }

  :hover {
    color: inherit;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[4]};

  > span > svg {
    vertical-align: sub;
  }
`;

interface Props {
  linkTo?: string;
  onClick: MouseEventHandler;
}

export const DirectoryNodeLeftSectionWrapper = ({
  linkTo,
  onClick,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  if (linkTo) {
    return (
      <StyledLink to={linkTo} onClick={onClick}>
        {children}
      </StyledLink>
    );
  }

  return <StyledDiv onClick={onClick}>{children}</StyledDiv>;
};
