import { defaultTheme } from 'styles/theme';

export const BgColor = {
  info: defaultTheme.colors.gray100,
  warning: defaultTheme.colors.yellow100,
  error: defaultTheme.colors.red100,
  success: defaultTheme.colors.green100,
};

export const BorderColor = {
  info: defaultTheme.colors.gray200,
  warning: defaultTheme.colors.yellow200,
  error: defaultTheme.colors.red200,
  success: defaultTheme.colors.green200,
};
