import {
  ViewPlugin,
  EditorView,
  Decoration,
  type ViewUpdate,
  type MatchDecorator,
} from '@codemirror/view';

export const constructViewPlugin = (matchDecorator: MatchDecorator): ViewPlugin<object> => {
  return ViewPlugin.fromClass(
    class {
      placeholders;

      constructor(view: EditorView) {
        this.placeholders = matchDecorator.createDeco(view);
      }

      update(update: ViewUpdate) {
        this.placeholders = matchDecorator.updateDeco(update, this.placeholders);
      }
    },
    {
      decorations: (v) => v.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return view.plugin(plugin)?.placeholders || Decoration.none;
        }),
    },
  );
};
