import { type EditorView } from '@codemirror/view';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'antd/es/form/Form';
import axios from 'axios';
import { Popover } from 'components/ui/atomic-components';
import { type QueryTableColumnDataType } from 'data/big-query';
import { trimFormula } from 'data/formula-editor/utils';
import {
  type ReactElement,
  type SetStateAction,
  type Dispatch,
  type RefObject,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { ColumnEditor } from '../../../column-editor';
import { type ColumnType } from '../../../column-editor/column-type-select/utils';
import { useColumnMutations } from '../../../hooks/use-grid-crud/use-column-mutations';
import { removeCacheForAllListsWithFormulaColumns } from '../utils';
import { updateListColumnInCache } from './utils';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  colName: string;
  columnType: QueryTableColumnDataType | 'FORMULA';
  dateFormat?: string;
  columnWrapperDomRef: RefObject<HTMLDivElement>;
  formula?: {
    formulaText: string | undefined;
    query: string | undefined;
  };
}

export const EditColumn = ({
  isOpen,
  colName,
  columnType,
  dateFormat,
  columnWrapperDomRef,
  formula,
  setIsOpen,
}: Props): ReactElement => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { onUpdateColumn } = useColumnMutations();

  const [form] = useForm<{
    name: string;
    type: ColumnType;
    formula?: {
      formulaText: string | undefined;
      query: string | undefined;
    };
    aiEnabledFormula?: boolean;
  }>();

  const editorViewRef = useRef<EditorView | null>(null);
  const setEditorView = (editorView: EditorView | null) => (editorViewRef.current = editorView);

  const listId = useListStoreContext((s) => s.id);
  const updateListColumnToStore = useListStoreContext((s) => s.updateColumn);
  const resetUndoRedoStack = useListStoreContext((s) => s.resetUndoRedoStack);

  const columnWidth = (columnWrapperDomRef.current?.clientWidth || 0) + 8;

  const onConfirm = () => {
    form.validateFields().then(async (data) => {
      const requestBody = {
        columnName: colName,
        newColumnName: data.name,
        type: data.type.key,
        dateFormat: data.type.dateFormat,
      } as {
        columnName: string;
        newColumnName?: string;
        type?: QueryTableColumnDataType;
        dateFormat?: string;
        formula?: string;
        query?: string;
      };

      if (data.type.key === 'FORMULA') {
        const key = data.aiEnabledFormula ? 'query' : 'formula';

        requestBody[key] = trimFormula(editorViewRef.current?.state?.doc?.toString() ?? '');
        delete requestBody.type;
      }

      if (formatName(colName) === data.name) {
        delete requestBody.newColumnName;
      }

      try {
        const colUpdateResponse = await onUpdateColumn.mutateAsync(requestBody);

        setIsOpen(false);

        if (columnType !== data.type.key) {
          queryClient.invalidateQueries(['lists', listId]);
        }

        if (data.type.key !== 'FORMULA') {
          updateListColumnToStore(colName, {
            columnName: colUpdateResponse?.name,
            type: data.type.key as QueryTableColumnDataType,
            dateFormat: data.type.dateFormat,
          });
        }

        updateListColumnInCache({
          queryClient,
          listId,
          columnName: colName,
          updatedInfo: {
            columnName: colUpdateResponse?.name,
            type: data.type.key as QueryTableColumnDataType,
          },
        });

        removeCacheForAllListsWithFormulaColumns(queryClient, listId);

        resetUndoRedoStack();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          form.setFieldValue(
            'error',
            intl.formatMessage({
              id: data.aiEnabledFormula
                ? 'formula_bar.error.error_in_prompt'
                : 'formula_bar.error.error_in_formula',
            }),
          );
        }
      }
    });
  };

  return (
    <Popover
      align={{ targetOffset: [columnWidth, 0] }}
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={onUpdateColumn.isLoading}
      confirmText={<FormattedMessage id="update" />}
      content={
        <ColumnEditor
          colFormula={formula}
          colName={colName}
          colType={columnType}
          dateFormat={dateFormat}
          editorViewRef={editorViewRef}
          form={form}
          isEdit
          setEditorView={setEditorView}
          onConfirm={onConfirm}
        />
      }
      destroyTooltipOnHide
      open={isOpen}
      placement="leftTop"
      onCancel={() => setIsOpen(false)}
      onConfirm={onConfirm}
      onOpenChange={(o) => setIsOpen(o)}
    >
      <></>
    </Popover>
  );
};
