import { type ID } from 'data';
import { type DataFormattingType } from 'utils/data-formatter/types';

export const ResourceUsageDependencyTypeMap = {
  datasets: 'DATASETS',
  lists: 'LISTS',
  boards: 'BOARDS',
  metrics: 'METRICS',
  variables: 'VARIABLES',
  dimensions: 'DIMENSIONS',
} as const;

export type ResourceUsageType =
  | 'DATASETS'
  | 'LISTS'
  | 'METRICS'
  | 'VARIABLES'
  | 'DIMENSIONS'
  | 'DIMENSION_GROUPS';

export interface ResourceUsageMetric {
  name: string;
  displayName: string;
  type: DataFormattingType;
}

export interface ResourceUsageReport {
  name: string;
  id: ID;
}

export interface ResourceUsageVariable {
  name: string;
  displayName: string;
  moduleName: string;
  type: DataFormattingType;
}

export interface ResourceUsageList {
  name: string;
  displayName?: string;
  id: ID;
  isDerived?: boolean;
}

export interface ResourceUsageDimension {
  name: string;
  id: ID;
}

export interface ResourceUsageDataset {
  name: string;
  id: ID;
  displayName: string;
}

export type ResourceUsageDependency =
  | ResourceUsageMetric
  | ResourceUsageReport
  | ResourceUsageVariable
  | ResourceUsageList
  | ResourceUsageDimension
  | ResourceUsageDataset;

export interface ResourceUsageResp {
  dependencies: Record<keyof typeof ResourceUsageDependencyTypeMap, ResourceUsageDependency[]>;
}
