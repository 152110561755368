import { Form, Input } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { type IntegrationInputForm } from '../../../types';

interface Props {
  input: IntegrationInputForm;
}

export const FormPassword = ({ input }: Props): ReactElement => {
  return (
    <Form.Item
      label={input.label}
      name={input.name}
      rules={[
        {
          required: !input.optional,
        },
      ]}
    >
      <Input type="password" />
    </Form.Item>
  );
};
