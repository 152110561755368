import { type UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { removeVersion } from '../utils';

export const useDeleteVersionMutation = (): {
  deleteModelVersion: UseMutationResult<void, AxiosError<unknown>, ModelVersion, unknown>;
} => {
  const queryClient = useQueryClient();

  const deleteModelVersion = useMutation(
    (version: ModelVersion) => ModelVersionApi.delete(version.id),
    {
      onSuccess: (_, data) => {
        removeVersion(queryClient, data);
      },
      onError: defaultApiErrorHandler,
    },
  );

  return { deleteModelVersion };
};
