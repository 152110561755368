import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

interface Props {
  inputValue: string;
}

export const CreateLabel = ({ inputValue }: Props): ReactElement => {
  return (
    <Wrapper>
      <IconShell color="iconHover" icon={PlusIcon} />
      <FormattedMessage id="create_value" values={{ value: inputValue }} />
    </Wrapper>
  );
};
