import { type PropsWithChildren, type ReactElement, type ReactNode } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const Label = styled.div`
  ${FontXs}

  color: ${({ theme }) => theme.colors.gray600};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

interface Props {
  title: ReactNode;
}

export const SelectorItem = ({ title, children }: PropsWithChildren<Props>): ReactElement => {
  return (
    <Wrapper>
      <Label>{title}</Label>

      {children}
    </Wrapper>
  );
};
