import styled from 'styled-components';
import { Button } from '../button';

const EditButton = styled(Button)<{ align?: 'center' | 'top' }>`
  position: absolute;
  right: -${({ theme }) => theme.spacing[16]};
  top: ${({ align }) => (align === 'top' ? 0 : 'auto')};
  margin-top: auto;
  margin-bottom: auto;
  visibility: hidden;
`;

export const InlineEditStyles = {
  EditButton,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  ContentWrapper: styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: ${({ theme }) => theme.spacing[16]};

    &:hover ${EditButton} {
      visibility: visible;
    }
  `,
};
