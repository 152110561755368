import axios from 'axios';
import { type ModelTemplate } from './types';

export const TemplatesApi = {
  getAll: async (): Promise<ModelTemplate[]> => {
    return axios.get('v1/templates').then(({ data }) => {
      return data;
    });
  },

  find: async (name: string): Promise<ModelTemplate> => {
    return axios.get(`v1/templates/${name}`).then(({ data }) => {
      return data;
    });
  },

  create: async (name: string, body: ModelTemplate): Promise<void> => {
    return axios.post(`v1/templates/${name}/create`, body).then(({ data }) => {
      return data;
    });
  },

  apply: async (name: string, body: ModelTemplate): Promise<void> => {
    return axios.post(`v1/templates/${name}/apply`, body).then(({ data }) => {
      return data;
    });
  },

  reset: async (name: string): Promise<void> => {
    return axios.patch(`v1/templates/${name}/reset`).then(({ data }) => {
      return data;
    });
  },
};
