import { PeriodTerms } from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { TimeDimensionName } from 'data/dimension';
import { isUndefined } from 'lodash';
import { decodeString } from 'utils/decode-string';

const doesFormulaContainPeriodExpr = (allExpressions: string[]) =>
  allExpressions.length > 0 &&
  PeriodTerms.some((term) => allExpressions[allExpressions.length - 1].startsWith(`${term}=`));

export const extractSubscriptValues = (metricTerm: RegExpExecArray): Record<string, string[]> => {
  if (isUndefined(metricTerm[2])) {
    return {};
  }

  const result: Record<string, string[]> = {};

  const metricName = metricTerm[1];
  const subscripts = metricTerm[0].replace(metricName, '').slice(1, -1).split('][');
  const timeSubscript = doesFormulaContainPeriodExpr(subscripts) ? subscripts.at(-1) || null : null;

  (timeSubscript ? subscripts.slice(0, -1) : subscripts).forEach((subscript) => {
    const [dimensionName, dimensionValues] = subscript.split('=');

    const decodedName = decodeString(dimensionName);
    const decodedValues = dimensionValues.split(',').map((value) => decodeString(value) || '');

    if (decodedName) {
      result[decodedName] = decodedValues;
    }
  });

  if (timeSubscript) {
    result[TimeDimensionName] = [timeSubscript];
  }

  return result;
};
