import axios from 'axios';
import { type SearchItem, type SearchRequest } from './types';

const apiPath = '/v1/search';

export const SearchApi = {
  advancedSearch: async (
    request: Partial<SearchRequest>,
    exact?: boolean,
  ): Promise<SearchItem[]> => {
    const path = exact ? `${apiPath}/advanced/exact` : `${apiPath}/advanced`;
    const { data } = await axios.post(path, request);

    return data;
  },
};
