import { ReactComponent as CurrencyIcon } from 'assets/metrics/currency.svg';
import { ReactComponent as NumberIcon } from 'assets/metrics/number.svg';
import { ReactComponent as PercentageIcon } from 'assets/metrics/percentage.svg';
import { ReactComponent as CurrencyDollarIcon } from 'assets/v2/currency-dollar.svg';
import { ReactComponent as HashIcon } from 'assets/v2/hash.svg';
import { ReactComponent as PercentageIconV2 } from 'assets/v2/percentage.svg';
import { IconShell, type IconType, type OptionBase } from 'components/ui/atomic-components';
import { FormattedMessage } from 'react-intl';
import { DataFormattingType } from 'utils/data-formatter/types';

export type MetricTypeList = OptionBase<{
  icon: IconType;
}>;

export const metricTypesList: MetricTypeList[] = [
  {
    label: <FormattedMessage id="number" />,
    value: DataFormattingType.Number,
    icon: NumberIcon,
  },
  {
    label: <FormattedMessage id="planning.metric.percentage" />,
    value: DataFormattingType.Percentage,
    icon: PercentageIcon,
  },
  {
    label: <FormattedMessage id="currency" />,
    value: DataFormattingType.Currency,
    icon: CurrencyIcon,
  },
];

export const metricTypesListV2 = [
  {
    label: <FormattedMessage id="number" />,
    value: DataFormattingType.Number,
    icon: <IconShell color="textBody2" icon={HashIcon} />,
  },
  {
    label: <FormattedMessage id="planning.metric.percentage" />,
    value: DataFormattingType.Percentage,
    icon: <IconShell color="textBody2" icon={PercentageIconV2} />,
  },
  {
    label: <FormattedMessage id="currency" />,
    value: DataFormattingType.Currency,
    icon: <IconShell color="textBody2" icon={CurrencyDollarIcon} />,
  },
];
