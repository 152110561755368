import { DateTimeFormats } from 'config/constants';
import { QueryTableColumnDataType } from 'data/big-query';
import dayjs from 'dayjs';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';

export const searchHighlightCellClassRule = ({
  type,
  store,
  value,
}: {
  type: QueryTableColumnDataType;
  store: StoreApi<ListsStoreApi>;
  value: string;
}): boolean => {
  const { searchQuery } = store.getState();
  const trimmedSearchQuery = searchQuery.toLowerCase().trim();

  const cellDisplayValue =
    type === QueryTableColumnDataType.Date
      ? dayjs(value).format(DateTimeFormats.HumanReadableDate)
      : value;

  return (
    trimmedSearchQuery !== '' && String(cellDisplayValue).toLowerCase().includes(trimmedSearchQuery)
  );
};
