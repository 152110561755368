import { DimensionFilterParam } from 'data/dimension';
import { withDefault, StringParam } from 'use-query-params';

export const DtqlQueryPageQueryParams = {
  metricName: withDefault(StringParam, ''),
  metricDisplayName: withDefault(StringParam, ''),
  f: withDefault(DimensionFilterParam, {}),
  startDate: withDefault(StringParam, ''),
  endDate: withDefault(StringParam, ''),
};

export const TABLE_DEFAULT_SCHEMA_NAME = 'default';

const BASE_QUERY_KEY = 'query-builder';

export const QueryBuilderStoreKeys = {
  QueryKey: BASE_QUERY_KEY,
  findRecord: (metricName: string): [string, string] => [BASE_QUERY_KEY, metricName],
};
