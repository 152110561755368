import { useChartConfigStore } from 'components/modules/boards-v2/custom-board/pivot-slider/store';
import { type DimensionQueryParamsObject, DimensionFilterParam } from 'data/dimension';
import { useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  type QueryParamConfig,
  type SetQuery,
  type DecodedValueMap,
  BooleanParam,
  useQueryParam,
} from 'use-query-params';

export type BoardParamsProps = {
  startDate: QueryParamConfig<string | null | undefined, string | null | undefined>;
  endDate: QueryParamConfig<string | null | undefined, string | null | undefined>;
  f: QueryParamConfig<DimensionQueryParamsObject | null | undefined>;
  dateOption?: QueryParamConfig<string | null | undefined>;
  granularity?: QueryParamConfig<string | null | undefined, string | null | undefined>;
  lastFilterDimName?: QueryParamConfig<string | null | undefined>;
  currency?: QueryParamConfig<string | null | undefined>;
};

export const useBoardQueryParams = (): [
  DecodedValueMap<BoardParamsProps>,
  SetQuery<BoardParamsProps>,
] => {
  const boardQueryParams = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
    f: DimensionFilterParam,
    dateOption: StringParam,
    granularity: StringParam,
    lastFilterDimName: StringParam,
    currency: StringParam,
  });

  return boardQueryParams;
};

export const useEditModeQueryParam = (): [boolean | null | undefined, (value: boolean) => void] => {
  const [isInEditMode, setIsInEditMode] = useQueryParam('edit_mode', BooleanParam);

  const setCurrentlyEditingChartId = useChartConfigStore((s) => s.setCurrentlyEditingChartId);

  const updateEditModeParam = (value: boolean) => {
    // undefined removed the param from url
    setIsInEditMode(value ? value : undefined, 'replaceIn');
  };

  // this is to reset chart config section
  useEffect(() => {
    if (!isInEditMode) {
      setCurrentlyEditingChartId(null);
    }
  }, [isInEditMode, setCurrentlyEditingChartId]);

  return [isInEditMode, updateEditModeParam];
};

export const useIsInPrintMode = (): boolean => {
  const [isInPrintMode] = useQueryParam('is_in_print_mode', BooleanParam);

  return !!isInPrintMode;
};

export const useLazyLoadCharts = (): boolean => {
  const [isLazyLoad] = useQueryParam('lazy_load', BooleanParam);

  return isLazyLoad !== false;
};
