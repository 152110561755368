import { useBootstrap } from 'data/bootstrap';
import { formatTransformer } from '../utils/formatted-date';

export const useSupportedDateFormats = (): string[] => {
  const { data: { dateFormats, dateFormat } = {} } = useBootstrap();

  if (dateFormats && dateFormat) {
    return [
      dateFormat,
      ...dateFormats.filter((f) => formatTransformer(f) !== formatTransformer(dateFormat)),
    ];
  }

  return [];
};
