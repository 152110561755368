import { type ResourceUsageDependency } from 'data/resource-usage/types';
import { type ReactNode, type ReactElement } from 'react';
import styles from './styles';

const {
  Wrapper,
  DividerLine,
  UsageWrapper,
  ResourceDependencyTitle,
  ResourceName,
  ResourceDependencyCount,
  ResourceDependencyList,
} = styles;

interface Props {
  data: ResourceUsageDependency[];
  icon: ReactNode;
  resourceDisplayLabel: string;
  isLastItem: boolean;
  itemRenderer: (item: ResourceUsageDependency) => ReactNode;
}

export const ResourceUsage = ({
  data,
  icon,
  resourceDisplayLabel,
  isLastItem,
  itemRenderer,
}: Props): ReactElement => {
  return (
    <Wrapper>
      <div>
        <div>{icon}</div>
        {!isLastItem && <DividerLine />}
      </div>

      <UsageWrapper>
        <ResourceDependencyTitle>
          <ResourceName>{resourceDisplayLabel}</ResourceName>
          <ResourceDependencyCount>{data.length}</ResourceDependencyCount>
        </ResourceDependencyTitle>

        <ResourceDependencyList>{data.map((item) => itemRenderer(item))}</ResourceDependencyList>
      </UsageWrapper>
    </Wrapper>
  );
};
