import { type CSVPreviewRowItem } from 'data/lists';
import { keyBy } from 'lodash';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';
import { type ListGridRow } from '../types';

export const updateListWithPastedValues = (
  pastedRows: CSVPreviewRowItem[],
  storeApi: StoreApi<ListsStoreApi>,
): void => {
  const { setFilteredRows, filteredRows, id } = storeApi.getState();
  // merge with existing list
  const rowLookup = keyBy(pastedRows, 'rowId');
  const existingRowIds = new Set();

  const mapper = (row: ListGridRow) => {
    if (row?.backendId && rowLookup[row?.backendId]) {
      existingRowIds.add(row.backendId);

      return {
        ...row,
        data: rowLookup[row.backendId]?.values,
      };
    }

    return row;
  };

  const getExtendedRows = () =>
    pastedRows
      .filter((row) => !existingRowIds.has(row.rowId))
      .map((row) => {
        return {
          data: row.values,
          backendId: row.rowId,
          position: row.position,
          listId: id,
          id: row.rowId,
        } as ListGridRow;
      });

  setFilteredRows([
    ...filteredRows.map(mapper).filter((row) => !!row.backendId),
    ...getExtendedRows(),
  ]);

  storeApi.setState((prevState) => ({
    rows: [...prevState.rows.map(mapper).filter((row) => !!row.backendId), ...getExtendedRows()],
  }));
};
