import { type CSVPreviewRowItem } from 'data/lists';
import { readCsv } from 'utils/csv';

export const validateColumnNames = (colNames: string[]): { error?: string } => {
  const findDup: { [key: string]: boolean } = {};
  const result: { error?: string } = {};

  if (colNames.findIndex((colName: string) => !colName.trim()) !== -1) {
    result.error = 'planning.lists.upload.blank_column_names';

    return result;
  }

  colNames.some((colName: string) => {
    if (findDup[colName]) {
      result.error = 'planning.lists.upload.duplicate_columns';

      return true;
    }
    findDup[colName] = true;

    return false;
  });

  return result;
};

export const getColumnNamesFromCSVFile = async (file: unknown): Promise<string[]> => {
  const { data } = await readCsv(file as Papa.LocalFile, { preview: 1 });

  return ((data?.[0] || []) as string[]).map((col) => col.trim());
};

export const attachRowId = (data: CSVPreviewRowItem[]): CSVPreviewRowItem[] => {
  return data.map((d, rowId) => ({ ...d, rowId }));
};

export const replaceSpecialCharsFromName = (name: string): string => {
  const regex = /[^a-zA-Z0-9]/g;

  return name.replaceAll(regex, '_').replaceAll(/_+$/g, '');
};
