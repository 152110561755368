import { type ReactElement } from 'react';
import { type SingleValueProps, components } from 'react-select';
import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';
import { type DimensionSelectOption } from '.';

const Title = styled.span`
  ${SingleLineEllipsis};

  display: block;
  align-items: center;
  justify-content: center;
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.textDisabled};
`;

export const SingleValueComponent = ({
  data,
  ...rest
}: SingleValueProps<DimensionSelectOption, true>): ReactElement => {
  return (
    <components.SingleValue data={data} {...rest}>
      <Title>{data.label}</Title>
      {!data.value && <Placeholder>{rest.selectProps.placeholder}</Placeholder>}
    </components.SingleValue>
  );
};
