import { type User } from 'data/users';
import mixpanel from 'mixpanel-browser';

mixpanel.init('d6d5a0bbc42eec652098e12de93fbf08');

export const setupMixpanel = (user: User): void => {
  mixpanel.identify(`${user.username}_${user.tenant}`);

  mixpanel.people.set({
    $email: user.username,
    TENANT_ID: user.tenant,
    DOMAIN: window.location.hostname,
  });
};
