import { ReactComponent as CloseIcon } from 'assets/v2/x.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type ReactElement } from 'react';
import { components, type MultiValueRemoveProps } from 'react-select';
import { type OptionBaseOld } from './option';

export const MultiValueRemove = <T extends OptionBaseOld, IsMulti extends boolean>(
  props: MultiValueRemoveProps<T, IsMulti>,
): ReactElement => {
  return (
    <components.MultiValueRemove {...props}>
      <IconShell icon={CloseIcon} size="xs" />
    </components.MultiValueRemove>
  );
};
