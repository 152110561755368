import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as XIcon } from 'assets/v2/x.svg';
import { Input, IconShell } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const SearchBox = styled(Input)`
  width: 416px;
`;

export const SearchBlock: FC<
  React.PropsWithChildren<{
    searchText: string;
    onSearchTextChange: (item: string) => void;
  }>
> = ({ searchText, onSearchTextChange }) => {
  const intl = useIntl();
  const placeholderText = intl.formatMessage({
    id: 'search',
  });

  return (
    <SearchBox
      allowClear={{ clearIcon: <IconShell icon={XIcon} /> }}
      placeholder={placeholderText}
      prefix={<IconShell color="black60" icon={SearchIcon} size="sm" />}
      value={searchText}
      onChange={(e) => onSearchTextChange(e.target.value)}
    />
  );
};
