import styled from 'styled-components';
import { FontM, FontXs } from 'styles/typography';

export default {
  NoReportPlaceholder: styled.div`
    display: flex;
    width: 400px;
    padding: ${({ theme: { spacing } }) => spacing[8]} ${({ theme: { spacing } }) => spacing[0]};
    flex-direction: column;
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing[16]};
  `,
  NoReportTitle: styled.span`
    ${FontM};

    font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  `,
  NoReportDescription: styled.span`
    font-size: ${({ theme: { fontSize } }) => fontSize.s};
    color: ${({ theme: { colors } }) => colors.gray500};
    text-align: center;
  `,
  NoReportContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing[8]};
    width: 100%;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing[4]};
    align-self: stretch;
  `,
  NoReportsPlaceholder: styled.div`
    ${FontXs};

    padding: 80px ${({ theme: { spacing } }) => spacing[0]};
    gap: ${({ theme: { spacing } }) => spacing[8]};
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme: { colors } }) => colors.textPlaceholder};
  `,
  PlaceholderImageContainer: styled.img``,
};
