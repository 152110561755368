export const waitTillCondition = async (
  condition: () => boolean,
  delay: number,
  nRetries: number,
): Promise<void> => {
  for (let i = 0; i < nRetries; i += 1) {
    if (!condition()) {
      await new Promise((resolve) => setTimeout(resolve, (i + 1) * delay));
    } else {
      break;
    }
  }
};
