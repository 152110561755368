import { type ColDef } from 'ag-grid-community';
import { QueryTableColumnDataType } from 'data/big-query';
import { type CSVListPreviewData } from 'data/lists';
import { useMemo } from 'react';
import { formatName, numberFormatter } from 'utils/data-formatter';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  ZeroFormatType,
} from 'utils/data-formatter/types';
import { PreviewCellItem } from '../cell-components';
import { ColumnHeader } from '../col-header';

export const usePreviewGridColDefs = ({
  columnNames,
  colTypeMap,
}: {
  columnNames: string[];
  colTypeMap?: CSVListPreviewData['columnTypeMap'];
}): ColDef[] => {
  return useMemo(
    () =>
      columnNames.map((colName) => {
        const columnType = colTypeMap?.[colName];

        return {
          colId: colName,
          field: `values.${colName}`,
          valueFormatter: ({ value }) => {
            if (columnType === QueryTableColumnDataType.Numeric) {
              return numberFormatter({
                value,
                type: DataFormattingType.Number,
                displayUnit: DisplayUnitType.Full,
                digitsAfterDecimal: value % 1 !== 0 ? 2 : 0,
                zeroFormat: ZeroFormatType.Number,
                blankValueFormat: BlankValueType.Blank,
              });
            }

            return value;
          },
          cellDataType: 'text',
          headerName: formatName(colName),
          minWidth: 140,
          resizable: true,
          suppressMovable: true,
          editable: true,
          flex: 1,
          cellRenderer: PreviewCellItem,
          cellClassRules: {
            cellupdated: (params) => {
              const edits = params.data?.edits || {};

              return colName in edits;
            },
          },
          cellRendererParams: {
            colName,
          },
          headerComponent: ColumnHeader,
        } as ColDef;
      }),
    [columnNames, colTypeMap],
  );
};
