import { type RouteObject } from 'react-router-dom';

export enum PreloadPriority {
  High = 'HIGH',
  Low = 'LOW',
}

export type RouteObjWithPreload = Omit<RouteObject, 'children'> & {
  preload?: () => Promise<unknown>;
  children?: RouteObjWithPreload[];
  preloadPriority?: PreloadPriority;
};
