import { ReactComponent as HelpIcon } from 'assets/v2/help_24.svg';
import { Popover } from 'components/ui/atomic-components';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LeftNavWrapper } from '../left-nav-item';
import { Content } from './content';

const Wrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .beamer_icon.active {
    background-color: ${({ theme }) => theme.colors.orange500} !important;
    color: ${({ theme }) => theme.colors.orange500};
    width: 10px;
    height: 10px;
    font-size: 0;
    top: 6px;
    right: 14px;
    border: 1px solid ${({ theme }) => theme.colors.gray00};
  }
`;

export const HelpButton = (): ReactElement => {
  const { tempBeamer } = useFlags();

  if (!tempBeamer) {
    return <></>;
  }

  return (
    <LeftNavWrapper>
      <Popover content={<Content />} placement="right" trigger={['hover']}>
        <Wrapper className="beamerTrigger" data-beamer-click="false">
          <IconShell icon={HelpIcon} />
          <span>
            <FormattedMessage id="help" />
          </span>
        </Wrapper>
      </Popover>
    </LeftNavWrapper>
  );
};
