import { type CustomCellRendererProps } from 'ag-grid-react';
import { formatTransformer } from 'data/date-format/utils/formatted-date';
import { DEFAULT_DATE_FORMAT } from 'data/modelling/lists/constants';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.gray400};
  opacity: 0;

  :hover {
    opacity: 1;
  }
`;

export const DateCell = ({ colDef, valueFormatted }: CustomCellRendererProps): ReactElement => {
  const dateFormat = colDef?.headerComponentParams?.dateFormat || DEFAULT_DATE_FORMAT;

  if (!valueFormatted) {
    return <Placeholder>{formatTransformer(dateFormat)}</Placeholder>;
  }

  return <div>{valueFormatted}</div>;
};
