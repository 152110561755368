import { AuthorisedAccess } from 'components/modules/authorised-access';
import { FindAndRedirectLastBoard } from 'components/modules/boards-v2/find-and-redirect-last-board';
import { HtmlHead } from 'components/ui/html-head';
import { PermissionList } from 'data/roles/permissions';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

export const BoardLastViewRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const lastViewedBoardId = localStorage.getItem('last-viewed-board-id');

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'board.html_title' });

  if (lastViewedBoardId) {
    return <Navigate replace to={`/reports/${lastViewedBoardId}`} />;
  }

  return (
    <AuthorisedAccess permissionType={PermissionList.Boards}>
      <>
        <HtmlHead titles={[pageTitle]} />
        <FindAndRedirectLastBoard />
      </>
    </AuthorisedAccess>
  );
};
