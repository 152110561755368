import { TimeDimensionName } from 'data/dimension';
import { type FrameElement } from 'data/reports/types';
import { constructTimeDimKey } from 'data/reports/utils';
import { isEmpty } from 'lodash';
import { type DimensionValue } from '../types';

export const getLeafDimensionsInfo = (
  frameHierarchy: FrameElement[],
  dimensionValues: Record<string, DimensionValue> = {},
  result: Record<string, DimensionValue>[] = [],
): Record<string, DimensionValue>[] => {
  if (isEmpty(frameHierarchy)) {
    result.push(dimensionValues);
  }

  for (const hierarchy of frameHierarchy) {
    const isLeaf = isEmpty(hierarchy.children);

    if (isLeaf && hierarchy.isMasked) {
      continue;
    }

    const currentDimensionValue: DimensionValue = {
      value: hierarchy.key,
      displayValue: hierarchy.displayName,
    };

    const currentDimensionValues = {
      ...dimensionValues,
      [hierarchy.dimensionName]: currentDimensionValue,
    };

    if (hierarchy.dimensionName === TimeDimensionName && hierarchy.period) {
      currentDimensionValues[TimeDimensionName] = {
        value: constructTimeDimKey(hierarchy.key, hierarchy.period),
        displayValue: hierarchy.displayName,
      };
    }

    getLeafDimensionsInfo(hierarchy.children, currentDimensionValues, result);
  }

  return result;
};
