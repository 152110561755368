import { Currency } from 'data/currencies';
import { BlankValueType, DataFormattingType } from 'utils/data-formatter/types';
import { DisplayUnitType, NegativeValueType, ZeroFormatType } from './types';
import { formatters, formattersForNegativeType, formattersForZeroType } from './utils';

const DIGITS_AFTER_DECIMAL_LIMIT = 10;

const numberFormatterHelper = ({
  valAsInt,
  type,
  currency,
  displayUnit,
  digitsAfterDecimal,
  roundingPrecision,
  isCompact,
}: {
  valAsInt: number;
  type: DataFormattingType;
  currency: Currency;
  displayUnit: DisplayUnitType;
  digitsAfterDecimal: number;
  roundingPrecision: 'auto' | 'morePrecision';
  isCompact: boolean;
}): string => {
  let defaultOptions: Intl.NumberFormatOptions;

  if (roundingPrecision === 'morePrecision') {
    defaultOptions = {
      maximumFractionDigits: DIGITS_AFTER_DECIMAL_LIMIT,
      minimumFractionDigits: 0,
    };
  } else {
    defaultOptions = {
      maximumFractionDigits: digitsAfterDecimal,
      minimumFractionDigits: digitsAfterDecimal,
    };
  }

  return (
    formatters[displayUnit]?.[type]?.({
      valAsInt,
      type,
      currency,
      displayUnit,
      isCompact,
      defaultOptions,
    }) || ''
  );
};

export const numberFormatter = ({
  value,
  type,
  ...formattingOptions
}: {
  value: number | string | null;
  type: DataFormattingType;
  currency?: Currency;
  displayUnit?: DisplayUnitType;
  digitsAfterDecimal?: number;
  zeroFormat?: ZeroFormatType;
  negativeValueFormat?: NegativeValueType;
  blankValueFormat?: BlankValueType;
  roundingPrecision?: 'auto' | 'morePrecision';
  isCompact?: boolean;
}): string => {
  const valAsInt = parseFloat(`${value}`);

  // Special handling for options if they take null values
  const displayUnit = formattingOptions.displayUnit || DisplayUnitType.Full;
  const digitsAfterDecimal =
    formattingOptions.digitsAfterDecimal ?? (type === DataFormattingType.Number ? 0 : 2);
  const zeroFormat = formattingOptions.zeroFormat || ZeroFormatType.Dash;
  const negativeValueFormat = formattingOptions.negativeValueFormat || NegativeValueType.Brackets;
  const blankValueFormat = formattingOptions.blankValueFormat || BlankValueType.Zero;
  const roundingPrecision = formattingOptions.roundingPrecision || 'auto';
  const isCompact = formattingOptions.isCompact ?? true;
  const currency = formattingOptions.currency || Currency.USD;

  if (isNaN(valAsInt)) {
    if (blankValueFormat === BlankValueType.Zero) {
      return formattersForZeroType[zeroFormat]?.[type]?.({
        digitsAfterDecimal,
        currency,
      });
    }

    return typeof value === 'string' ? value : '';
  }

  if (valAsInt === 0) {
    return formattersForZeroType[zeroFormat]?.[type]?.({
      digitsAfterDecimal,
      currency,
    });
  }
  const formattedData = numberFormatterHelper({
    valAsInt,
    type,
    currency,
    displayUnit,
    digitsAfterDecimal,
    roundingPrecision,
    isCompact,
  });

  if (valAsInt < 0) {
    const formattedDataWithoutMinusSign = formattedData.replace('-', '');

    return formattersForNegativeType[negativeValueFormat](formattedDataWithoutMinusSign);
  }

  return formattedData;
};
