import axios, { type AxiosResponse } from 'axios';
import { type User } from './types';

export const SIGN_OUT_URL = '/v1/sign-out';
export const FETCH_CURRENT_USER_URL = '/v1/users/me';

export const UsersApi = {
  async currentUser(): Promise<User> {
    try {
      const { data } = await axios.get<User>(FETCH_CURRENT_USER_URL);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleRedirectStatus(error.response);
      }

      throw error;
    }
  },

  async getUsers(searchQuery?: string): Promise<User[]> {
    const { data } = await axios.get(`/v1/users${searchQuery ? `?name=${searchQuery}` : ''}`);

    return data;
  },

  async signout(): Promise<void> {
    await axios.post(SIGN_OUT_URL);
  },

  async deleteUser(userId: number): Promise<void> {
    return axios.delete(`/v1/users/${userId}`);
  },

  async getAllUsers(): Promise<User[]> {
    const { data } = await axios.get('v1/users');

    return data;
  },

  async create(user: Partial<User>): Promise<void> {
    const { data } = await axios.post('v1/users', user);

    return data;
  },

  async setViewAsUser(username: string): Promise<void> {
    const { data } = await axios.post('v1/users/view-as-user/set', { username });

    return data;
  },

  async exitViewAsUser(): Promise<void> {
    const { data } = await axios.post('v1/users/view-as-user/exit', {});

    return data;
  },
};

const handleRedirectStatus = (response?: AxiosResponse) => {
  if (response?.status === 302) {
    const redirectToUrl = response.headers.redirecturl;

    if (redirectToUrl) {
      window.location.replace(redirectToUrl + window.location.search);
    }
  }
};
