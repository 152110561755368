import { useBootstrap } from 'data/bootstrap';
import { type CurrencyOption } from 'data/bootstrap/types';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

export const useTenantCurrencies = (): CurrencyOption[] => {
  const { data: { tenantCurrencies, currencies } = {} } = useBootstrap();

  const currenciesMap = useMemo(() => keyBy(currencies, 'name'), [currencies]);

  const result = [];

  for (const tenantCurrency of tenantCurrencies || []) {
    if (currenciesMap[tenantCurrency]) {
      result.push(currenciesMap[tenantCurrency]);
    }
  }

  return result;
};
