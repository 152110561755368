import { defaultTheme } from 'styles/theme';

export const DefaultColoringOption = {
  background: defaultTheme.colors.gray100,
  border: defaultTheme.colors.gray200,
};

export const ColoringOptions = [
  {
    background: defaultTheme.colors.green200,
    border: defaultTheme.colors.green200,
  },
  {
    background: defaultTheme.colors.magenta200,
    border: defaultTheme.colors.magenta200,
  },
  {
    background: defaultTheme.colors.yellow200,
    border: defaultTheme.colors.yellow200,
  },
  {
    background: defaultTheme.colors.aqua200,
    border: defaultTheme.colors.aqua200,
  },
  {
    background: defaultTheme.colors.orange200,
    border: defaultTheme.colors.orange200,
  },
  {
    background: defaultTheme.colors.pink200,
    border: defaultTheme.colors.pink200,
  },
  {
    background: defaultTheme.colors.blue200,
    border: defaultTheme.colors.blue200,
  },
  {
    background: defaultTheme.colors.red200,
    border: defaultTheme.colors.red200,
  },
];
