import {
  HighlightStyle,
  syntaxHighlighting as codemirrorSyntaxHighlighting,
} from '@codemirror/language';
import { tags } from '@drivetrainai/optimus-formula-parser/dist/highlight';
import { defaultTheme } from 'styles/theme';

// temp styles
export const syntaxHighlighting = codemirrorSyntaxHighlighting(
  HighlightStyle.define([
    {
      tag: tags.function,
      color: defaultTheme.colors.magenta500,
      fontWeight: defaultTheme.fontWeight.medium,
      textTransform: 'uppercase',
    },
  ]),
);
