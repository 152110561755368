import axios from 'axios';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';
import { type ResourceUsageList, type ResourceUsageResp, type ResourceUsageType } from './types';

export const ResourceUsageApi = {
  checkUsages: async (name: string, type: ResourceUsageType): Promise<ResourceUsageResp> => {
    return axios
      .get('v1/usages', {
        params: { name, resourceType: type },
        paramsSerializer: (params) => stringify(params),
      })
      .then(({ data }) => {
        // merge dimension_groups with lists, remove once BE list and dimension_groups are merged
        const dimensionGroupDependency = data?.dependencies?.dimension_groups?.map(
          (item: ResourceUsageList) => ({
            ...item,
            isDerived: true,
          }),
        );

        if (!isEmpty(dimensionGroupDependency)) {
          data.dependencies.lists = [
            ...(data.dependencies.lists || []),
            ...dimensionGroupDependency,
          ];

          delete data.dependencies.dimension_groups;
        }

        return data;
      });
  },
};
