import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { DeleteConfirmWithUsage } from 'components/ui/resource-usage-widget';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { b } from 'styles/html-elements';
import { formatName } from 'utils/data-formatter';

interface Props {
  listId: number;
  listName: string;
  open: boolean;
  onClose: () => void;
}

export const DeleteListsModal = ({ open, onClose, listId, listName }: Props): ReactElement => {
  const { deleteListMutation } = useListMutations();

  return (
    <DeleteConfirmWithUsage
      confirmLoading={deleteListMutation.isLoading}
      displayName={formatName(listName)}
      name={listName}
      noUsagesMessage={
        <FormattedMessage
          id="delete_modal_formula_del.list.question"
          values={{ list: formatName(listName), b }}
        />
      }
      open={open}
      title={<FormattedMessage id="lists.delete_list_modal.title" />}
      type="LISTS"
      onCancel={onClose}
      onOk={() => deleteListMutation.mutateAsync(listId).then(onClose)}
    />
  );
};
