import { css } from 'styled-components';

export const MentionsStyles = css`
  .ant-mentions-dropdown-menu {
    padding: ${({ theme: { spacing } }) => spacing[4]} 0;
  }

  .ant-mentions-dropdown-menu-item {
    padding: ${({ theme: { spacing } }) => spacing[8]};

    :first-child,
    :last-child {
      border-radius: 0;
    }

    :hover {
      background: ${({ theme }) => theme.colors.black10};
    }
  }
`;
