import { ReactComponent as ExcelFileIcon } from 'assets/file-excel.svg';
import { ReactComponent as FileUploadLargeIcon } from 'assets/v2/file-upload-large.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM, FontS } from 'styles/typography';

const Wrapper = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.spacing[32]};
`;

const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.iconDefault};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
`;

const Title = styled.span`
  ${FontM};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textTitle2};
`;

const SubTitle = styled.span`
  ${FontS};

  color: ${({ theme }) => theme.colors.textPlaceholder};
`;

interface Props {
  fileName?: string;
  downloadAsExcel: () => void;
}

export const ModalHeader = ({ fileName, downloadAsExcel }: Props): ReactElement => {
  return (
    <Wrapper>
      <TitleIcon>
        <FileUploadLargeIcon />

        <TitleWrapper>
          <Title>
            <FormattedMessage id="lists.upload_csv.title" />
          </Title>
          <SubTitle>{fileName}</SubTitle>
        </TitleWrapper>
      </TitleIcon>

      <Button
        icon={<IconShell icon={ExcelFileIcon} size="xs" />}
        size="small"
        type="text"
        onClick={downloadAsExcel}
      >
        <FormattedMessage id="lists.upload_csv.export_as_excel" />
      </Button>
    </Wrapper>
  );
};
