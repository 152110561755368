import { type OptionBaseOld } from 'components/ui/atomic-components/select';
import { type ReactElement } from 'react';
import { type OptionProps, components } from 'react-select';
import { InitialsIconText } from './icon';

export const RoleOption = (props: OptionProps<OptionBaseOld, false>): ReactElement => {
  return (
    <components.Option {...props}>
      <InitialsIconText text={props.label} />
    </components.Option>
  );
};
