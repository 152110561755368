import { Modal } from 'components/ui/atomic-components';
import { CSVUploadWorkflowStage, type CSVListPreviewData } from 'data/lists';
import { isEmpty } from 'lodash';
import { useMemo, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { PreviewDataGrid } from '../preview-data-grid';
import { type PreviewTableContextParams } from '../preview-data-grid/context';
import { ModalFooter } from './footer';
import { ModalHeader } from './header';
import { ImportInfo } from './info';
import { ImportSteps } from './stepts';

const StyledModal = styled(Modal)`
  .ant-modal-close {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }
`;

interface Props {
  data?: CSVListPreviewData;
  isFreshUpload: boolean;
  columnNames: string[];
  visible: boolean;
  noSavableRowsFound?: boolean;
  cellEditsFound?: boolean;
  validationLoading?: boolean;
  saveLoading?: boolean;
  currentStage: CSVUploadWorkflowStage;
  fileName?: string;
  updatePropertyTypeMap: PreviewTableContextParams['updatePropertyTypeMap'];
  onClose: () => void;
  onValidate: () => void;
  onBack: () => void;
  onSubmit: () => void;
  downloadAsExcel: () => void;
  onEdit: (rowId: number, propName: string, newVal: string) => void;
}

export const PreviewModal = ({
  isFreshUpload,
  columnNames,
  visible,
  fileName,
  onClose,
  data,
  onValidate,
  onBack,
  onSubmit,
  downloadAsExcel,
  noSavableRowsFound,
  cellEditsFound,
  onEdit,
  validationLoading,
  saveLoading,
  currentStage,
  updatePropertyTypeMap,
}: Props): ReactElement => {
  const [errorFilter, setErrorFilter] = useState(false);

  const gridData = useMemo(() => {
    if (errorFilter) {
      return (data?.rows || []).filter((rowData) => !isEmpty(rowData.errors));
    }

    return data?.rows || [];
  }, [errorFilter, data]);

  const errorRowCount = useMemo(() => {
    return (data?.rows || []).filter((rowData) => !isEmpty(rowData.errors)).length;
  }, [data]);

  const onCellEdit = (rowId: number, propName: string, newVal: string) => {
    onEdit(rowId, propName, newVal);
  };

  const onStepBack = () => {
    setErrorFilter(false);
    onBack();
  };

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      destroyOnClose
      footerExtra={
        <ModalFooter
          cellEditsFound={cellEditsFound}
          currentStage={currentStage}
          noSavableRowsFound={noSavableRowsFound}
          saveLoading={saveLoading}
          validationLoading={validationLoading}
          onBack={isFreshUpload ? onStepBack : undefined}
          onClose={onClose}
          onSubmit={onSubmit}
          onValidate={onValidate}
        />
      }
      hideCancelButton
      maskClosable={false}
      okText={<FormattedMessage id="preview" />}
      open={visible}
      title={<ModalHeader downloadAsExcel={downloadAsExcel} fileName={fileName} />}
      width={Math.min(document.body.clientWidth - 300, 1360)}
      onCancel={onClose}
    >
      {isFreshUpload && <ImportSteps currentStage={currentStage} />}

      <ImportInfo
        currentStage={currentStage}
        errorFilter={errorFilter}
        errorRowCount={errorRowCount}
        setErrorFilter={setErrorFilter}
      />

      <PreviewDataGrid
        colTypeMap={data?.columnTypeMap}
        columnNames={columnNames}
        data={gridData}
        dateFormatMap={data?.dateFormatMap}
        isColumnTypeEditMode={currentStage === CSVUploadWorkflowStage.ColumnMapping}
        updatePropertyTypeMap={updatePropertyTypeMap}
        onCellEdit={onCellEdit}
      />
    </StyledModal>
  );
};
