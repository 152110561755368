import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';

const SortableItemDiv = styled.div`
  position: relative;
  max-width: 100%;
  overflow: inherit;
  height: max-content;
`;

export const SortableItem: FC<{
  disabled?: boolean;
  locked?: boolean;
  id: string;
  children: (isDragging: boolean) => ReactNode;
}> = ({ disabled, locked, id, children }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : 0,
  };

  return (
    <SortableItemDiv ref={locked ? null : setNodeRef} style={style} {...attributes} {...listeners}>
      {children(isDragging)}
    </SortableItemDiv>
  );
};
