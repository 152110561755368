import { useOktaAuth } from '@okta/okta-react';
import { OktaSignInWidget } from 'components/modules/login/okta-sign-in-widget';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { type FC } from 'react';
import { Navigate } from 'react-router-dom';
import { SamlLoginWidget } from './saml-login-widget';

export const Login: FC<React.PropsWithChildren<unknown>> = () => {
  const { authState } = useOktaAuth();
  const client = useLDClient();
  const { samlLoginWidget } = useFlags();

  if (!client) {
    return <></>;
  }

  if (samlLoginWidget) {
    return <SamlLoginWidget />;
  }

  return authState?.isAuthenticated ? <Navigate to={{ pathname: '/' }} /> : <OktaSignInWidget />;
};
