import { notification } from 'components/ui/atomic-components';
import { RipplingCallbackApi } from 'data/integrations/rippling';
import { type FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { StringParam, useQueryParam } from 'use-query-params';

export const RipplingIntegration: FC<
  React.PropsWithChildren<{
    sandbox: boolean;
  }>
> = ({ sandbox }) => {
  const intl = useIntl();
  const [code] = useQueryParam('code', StringParam);
  const [redirectUrl] = useQueryParam('redirect_uri', StringParam);

  const onNullParams = useCallback(() => {
    notification.error({
      message: intl.formatMessage({ id: 'integrations.rippling.failure.no_params' }),
    });
  }, [intl]);

  const onSuccess = useCallback(() => {
    notification.success({
      message: intl.formatMessage({ id: 'integrations.rippling.successful' }),
    });

    code && redirectUrl ? (window.location.href = redirectUrl) : onNullParams();
  }, [code, redirectUrl, onNullParams, intl]);

  const onError = useCallback(() => {
    notification.error({
      message: intl.formatMessage({ id: 'integrations.rippling.failure.backend' }),
    });
  }, [intl]);

  useEffect(() => {
    code && redirectUrl
      ? RipplingCallbackApi.tokenCallback(code, sandbox).then(onSuccess).catch(onError)
      : onNullParams();
  }, [code, redirectUrl, sandbox, onSuccess, onError, onNullParams]);

  return <></>;
};
