import { type InputRef, type FormInstance } from 'antd';
import { useChipColoring } from 'components/modules/modelling/_commons/dimension-chip-coloring/use-chip-coloring';
import {
  AsyncDimensionsSelect,
  type DimensionSelectOption,
} from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select';
import { Form, Input } from 'components/ui/atomic-components';
import { ColumnBasedFilters } from 'components/ui/column-based-filters';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { isEmpty } from 'lodash';
import { useMemo, type ReactElement, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { type MultiValue } from 'react-select';
import styled from 'styled-components';
import { onChangeWithSpaceHandling } from '../utils';
import { DuplicateRowsData } from './duplicate-rows-data';
import { type FormProps } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  overflow: auto;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.black400};
  font-size: ${({ theme }) => theme.fontSize.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const StyledColumnBasedFilters = styled(ColumnBasedFilters)`
  .column-based-filters-header-item {
    padding: 0;
  }

  .column-based-filters-rule-card-item {
    max-width: 450px;
  }
`;

interface Props {
  form: FormInstance<FormProps>;
  initialValues?: Partial<FormProps>;
  editMode?: boolean;
  className?: string;
  duplicatesData?: DimensionGroup;
}

export const DimensionGroupCreateEditForm = ({
  form,
  initialValues,
  editMode,
  className,
  duplicatesData,
}: Props): ReactElement => {
  const groupDimensions = Form.useWatch('groupDimensions', form);

  const dims = useMemo(() => groupDimensions?.map((d) => d.value) || [], [groupDimensions]);
  const chipColoringScheme = useChipColoring({ dims });

  const inputRef = useRef<InputRef>(null);

  const onGroupDimensionsChange = (newValue: MultiValue<DimensionSelectOption>) => {
    form.setFieldsValue({ groupDimensions: newValue as DimensionSelectOption[] });
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
  }, []);

  return (
    <Form form={form} initialValues={initialValues}>
      <Wrapper className={className}>
        <div>
          <Label>
            <FormattedMessage id="lists.name" />
          </Label>
          <Form.Item name="groupName" noStyle>
            <Input
              ref={inputRef}
              disabled={editMode}
              size="medium"
              onChange={onChangeWithSpaceHandling(form, 'groupName')}
            />
          </Form.Item>
        </div>

        <div>
          <Label>
            <FormattedMessage id="dimension_groups.create_edit_form.select_dimensions.label" />
          </Label>
          <Form.Item name="groupDimensions" noStyle>
            <AsyncDimensionsSelect
              chipColoringScheme={chipColoringScheme}
              groupRelatedResults
              isMulti
              preventDimensionGroupSearch
              preventFormulaColumnSearch
              searchAllTables
              onChange={onGroupDimensionsChange}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item name="filters" noStyle>
            <StyledColumnBasedFilters
              selectedDimensions={
                groupDimensions?.map(({ value }) => value)?.filter((dim) => dim) || []
              }
            />
          </Form.Item>
        </div>

        {!isEmpty(duplicatesData) && <DuplicateRowsData data={duplicatesData} />}
      </Wrapper>
    </Form>
  );
};
