import { DimensionBlankIntermValue } from 'components/ui/dimensions-bar-v2/multiselect/utils';
import { DimensionAllMarker } from 'data/dimension';
import { encodeString } from 'utils/encode-string';

export const constructSubscript = (
  dimensionsSubscript: Record<string, string[]>,
  timeSubscript: string | null,
): string => {
  let res = '';

  for (const [dimensionName, dimensionValues] of Object.entries(dimensionsSubscript)) {
    if (dimensionValues.includes(DimensionAllMarker)) {
      continue;
    }

    res = res.concat(
      `[${encodeString(dimensionName)}=${dimensionValues
        .map((value) => (value !== DimensionBlankIntermValue ? encodeString(value) : ''))
        .join(',')}]`,
    );
  }

  if (timeSubscript) {
    res = res.concat(`[${timeSubscript}]`);
  }

  return res;
};
