import { QueryTableColumnDataType } from 'data/big-query';
import { type RowStructure } from 'data/modelling/lists';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

const stringComparator = (
  nodeA: RowStructure,
  nodeB: RowStructure,
  column: string,
  isDescending: boolean,
): number => {
  const valA = (nodeA[column] || '') as string;
  const valB = (nodeB[column] || '') as string;

  // Move a to the end
  if (!valA) {
    return 1;
  }

  // Move b to the end
  if (!valB) {
    return -1;
  }

  if (isDescending) {
    return valB.localeCompare(valA);
  }

  return valA.localeCompare(valB);
};

const numericComparator = (
  nodeA: RowStructure,
  nodeB: RowStructure,
  column: string,
  isDescending: boolean,
): number => {
  const valA = nodeA?.[column] as string;
  const valB = nodeB?.[column] as string;

  // Move a to the end
  if (isNil(valA)) {
    return 1;
  }

  // Move b to the end
  if (isNil(valB)) {
    return -1;
  }

  if (isDescending) {
    return Number(valB) - Number(valA);
  }

  return Number(valA) - Number(valB);
};

const dateComparator = (
  nodeA: RowStructure,
  nodeB: RowStructure,
  column: string,
  isDescending: boolean,
): number => {
  const valA = nodeA[column] as string;
  const valB = nodeB[column] as string;

  // Move a to the end
  if (!valA) {
    return 1;
  }

  // Move b to the end
  if (!valB) {
    return -1;
  }

  if (isDescending) {
    return dayjs(valB).diff(dayjs(valA));
  }

  return dayjs(valA).diff(dayjs(valB));
};

export const columnSortComparatorMap: Record<
  string,
  (nodeA: RowStructure, nodeB: RowStructure, column: string, isDescending: boolean) => number
> = {
  [QueryTableColumnDataType.String]: stringComparator,
  [QueryTableColumnDataType.Numeric]: numericComparator,
  [QueryTableColumnDataType.Date]: dateComparator,
};
