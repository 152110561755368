import { useMutation } from '@tanstack/react-query';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { IntegrationsApi } from '../api';

export const useMailMutation = (
  onSuccess: () => void,
): { sendMail: (id: number) => void; isLoading: boolean } => {
  const { mutate: sendMail, isLoading } = useMutation(
    (id: number) => IntegrationsApi.contactUs(id),
    {
      onSuccess,
      onError: defaultApiErrorHandler,
    },
  );

  return {
    sendMail,
    isLoading,
  };
};
