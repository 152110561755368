const theme = {
  colors: {
    /** @deprecated */
    white: '#ffffff',
    /** @deprecated */
    black10: '#fafbfc',
    /** @deprecated */
    black20: '#f4f5f7',
    /** @deprecated */
    black30: '#ebecf0',
    /** @deprecated */
    black40: '#dfe1e6',
    /** @deprecated */
    black50: '#c1c7d0',
    /** @deprecated */
    black60: '#b3bac5',
    /** @deprecated */
    black70: '#a5adba',
    /** @deprecated */
    black80: '#97a0af',
    /** @deprecated */
    black90: '#8993a4',
    /** @deprecated */
    black100: '#7a869a',
    /** @deprecated */
    black200: '#6b778c',
    /** @deprecated */
    black300: '#5e6c84',
    /** @deprecated */
    black400: '#505f79',
    /** @deprecated */
    black500: '#42526e',
    /** @deprecated */
    black600: '#344563',
    /** @deprecated */
    black700: '#253858',
    /** @deprecated */
    black800: '#172b4d',
    /** @deprecated */
    black900: '#091e42',

    /** @deprecated */
    red10: '#fef2f2',
    /** @deprecated */
    red20: '#fee2e2',
    /** @deprecated */
    red30: '#fecaca',
    /** @deprecated */
    red40: '#fca5a5',
    /** @deprecated */
    red50: '#f87171',
    /** @deprecated */
    red60: '#ef4444',
    /** @deprecated */
    red70: '#dc2626',
    /** @deprecated */
    red80: '#b91c1c',
    /** @deprecated */
    red90: '#991b1b',
    /** @deprecated */
    red100: '#7f1d1d',

    /** @deprecated */
    rose10: '#fff1f2',
    /** @deprecated */
    rose20: '#ffe4e6',
    /** @deprecated */
    rose30: '#fecdd3',
    /** @deprecated */
    rose40: '#fda4af',
    /** @deprecated */
    rose50: '#fb7185',
    /** @deprecated */
    rose60: '#f43f5e',
    /** @deprecated */
    rose70: '#e11d48',
    /** @deprecated */
    rose80: '#be123c',
    /** @deprecated */
    rose90: '#9f1239',
    /** @deprecated */
    rose100: '#881337',

    /** @deprecated */
    pink10: '#fdf2f8',
    /** @deprecated */
    pink20: '#fce7f3',
    /** @deprecated */
    pink30: '#fbcfe8',
    /** @deprecated */
    pink40: '#f9a8d4',
    /** @deprecated */
    pink50: '#f472b6',
    /** @deprecated */
    pink60: '#ec4899',
    /** @deprecated */
    pink70: '#db2777',
    /** @deprecated */
    pink80: '#be185d',
    /** @deprecated */
    pink90: '#9d174d',
    /** @deprecated */
    pink100: '#831843',

    /** @deprecated */
    purple10: '#f5f3ff',
    /** @deprecated */
    purple20: '#ede9fe',
    /** @deprecated */
    purple30: '#ddd6fe',
    /** @deprecated */
    purple40: '#c4b5fd',
    /** @deprecated */
    purple50: '#a78bfa',
    /** @deprecated */
    purple60: '#8b5cf6',
    /** @deprecated */
    purple70: '#7c3aed',
    /** @deprecated */
    purple80: '#6d28d9',
    /** @deprecated */
    purple90: '#5b21b6',
    /** @deprecated */
    purple100: '#4c1d95',

    /** @deprecated */
    indigo10: '#eef2ff',
    /** @deprecated */
    indigo20: '#e0e7ff',
    /** @deprecated */
    indigo30: '#c7d2fe',
    /** @deprecated */
    indigo40: '#a5b4fc',
    /** @deprecated */
    indigo50: '#818cf8',
    /** @deprecated */
    indigo60: '#6366f1',
    /** @deprecated */
    indigo70: '#4f46e5',
    /** @deprecated */
    indigo80: '#4338ca',
    /** @deprecated */
    indigo90: '#3730a3',
    /** @deprecated */
    indigo100: '#312e81',

    /** @deprecated */
    blue10: '#f6f4ff',
    /** @deprecated */
    blue20: '#dedaff',
    /** @deprecated */
    blue30: '#beb5ff',
    /** @deprecated */
    blue40: '#9d8fff',
    /** @deprecated */
    blue50: '#7d6aff',
    /** @deprecated */
    blue60: '#5c45ff',
    /** @deprecated */
    blue70: '#4a37cc',
    /** @deprecated */
    blue80: '#372999',
    /** @deprecated */
    blue90: '#251c66',
    /** @deprecated */
    blue100: '#120e33',

    /** @deprecated */
    skyblue10: '#f0f9ff',
    /** @deprecated */
    skyblue20: '#e0f2fe',
    /** @deprecated */
    skyblue30: '#bae6fd',
    /** @deprecated */
    skyblue40: '#7dd3fc',
    /** @deprecated */
    skyblue50: '#38bdf8',
    /** @deprecated */
    skyblue60: '#0ea5e9',
    /** @deprecated */
    skyblue70: '#0284c7',
    /** @deprecated */
    skyblue80: '#0369a1',
    /** @deprecated */
    skyblue90: '#075985',
    /** @deprecated */
    skyblue100: '#0c4a6e',

    /** @deprecated */
    cyan10: '#ecfeff',
    /** @deprecated */
    cyan20: '#cffafe',
    /** @deprecated */
    cyan30: '#a5f3fc',
    /** @deprecated */
    cyan40: '#67e8f9',
    /** @deprecated */
    cyan50: '#22d3ee',
    /** @deprecated */
    cyan60: '#06b6d4',
    /** @deprecated */
    cyan70: '#0891b2',
    /** @deprecated */
    cyan80: '#0e7490',
    /** @deprecated */
    cyan90: '#155e75',
    /** @deprecated */
    cyan100: '#164e63',

    /** @deprecated */
    teal10: '#f0fdfa',
    /** @deprecated */
    teal20: '#ccfbf1',
    /** @deprecated */
    teal30: '#99f6e4',
    /** @deprecated */
    teal40: '#5eead4',
    /** @deprecated */
    teal50: '#2dd4bf',
    /** @deprecated */
    teal60: '#14b8a6',
    /** @deprecated */
    teal70: '#0d9488',
    /** @deprecated */
    teal80: '#0f766e',
    /** @deprecated */
    teal90: '#115e59',
    /** @deprecated */
    teal100: '#134e4a',

    /** @deprecated */
    green10: '#ecfdf5',
    /** @deprecated */
    green20: '#d1fae5',
    /** @deprecated */
    green30: '#a7f3d0',
    /** @deprecated */
    green40: '#6ee7b7',
    /** @deprecated */
    green50: '#34d399',
    /** @deprecated */
    green60: '#10b981',
    /** @deprecated */
    green70: '#059669',
    /** @deprecated */
    green80: '#047857',
    /** @deprecated */
    green90: '#065f46',

    /** @deprecated */
    yellow10: '#fffbeb',
    /** @deprecated */
    yellow20: '#fef3c7',
    /** @deprecated */
    yellow30: '#fde68a',
    /** @deprecated */
    yellow40: '#fcd34d',
    /** @deprecated */
    yellow50: '#fbbf24',
    /** @deprecated */
    yellow60: '#f59e0b',
    /** @deprecated */
    yellow70: '#d97706',
    /** @deprecated */
    yellow80: '#b45309',
    /** @deprecated */
    yellow90: '#92400e',

    /** @deprecated */
    orange10: '#fff7ed',
    /** @deprecated */
    orange20: '#ffedd5',
    /** @deprecated */
    orange30: '#fed7aa',
    /** @deprecated */
    orange40: '#fdba74',
    /** @deprecated */
    orange50: '#fb923c',
    /** @deprecated */
    orange60: '#f97316',
    /** @deprecated */
    orange70: '#ea580c',
    /** @deprecated */
    orange80: '#c2410c',
    /** @deprecated */
    orange90: '#9a3412',

    /** @deprecated */
    textSecondary: '#42526e',
    /** @deprecated */
    textTertiary: '#7a869a',
  },
  fontSize: {
    /** @deprecated */
    h1: '2rem', // 32px
    /** @deprecated */
    h2: '1.75rem', // 28px
    /** @deprecated */
    h3: '1.5rem', // 24px
    /** @deprecated */
    h4: '1.25rem', // 20px
    /** @deprecated */
    h5: '1rem', // 16px
    /** @deprecated */
    h6: '0.875rem', // 14px
    /** @deprecated */
    paragraph: '0.875rem', // 14px
    /** @deprecated */
    caption: '0.75rem', // 12px
    /** @deprecated */
    footnote: '0.625rem', // 10px
  },
  lineHeight: {
    /** @deprecated */
    h1: '3rem', // 48px
    /** @deprecated */
    h2: '2.625rem', // 42px
    /** @deprecated */
    h3: '2.25rem', // 36px
    /** @deprecated */
    h4: '1.875rem', // 30px
    /** @deprecated */
    h5: '1.5rem', // 24px
    /** @deprecated */
    h6: '1.25rem', // 20px
    /** @deprecated */
    paragraph: '1.25rem', // 20px
    /** @deprecated */
    caption: '1rem', // 16px
    /** @deprecated */
    footnote: '1rem', // 16px,
    /** @deprecated */
    sm: '1rem', // 16px
    /** @deprecated */
    md: '1rem', // 16px
    /** @deprecated */
    lg: '1.25rem', // 20px
  },
  fontWeight: {
    /** @deprecated */
    bold: 500,
    /** @deprecated */
    semiBold: 500,
    /** @deprecated */
    normal: 400,
  },

  /** @deprecated */
  border: {
    color: '#DFE1E6',
    radius: {
      none: '0',
      sm: '2px',
      md: '4px',
      lg: '8px',
      xl: '12px',
      full: '100px',
    },
  },

  /** @deprecated */
  height: {
    xs: 24,
    sm: 28,
    md: 32,
    lg: 36,
    xl: 48,
  },

  /** @deprecated */
  iconSize: {
    16: '16px',
    20: '20px',
    32: '32px',
  },

  /** @deprecated */
  boxShadow: {
    2: '0 1px 2px 0 rgba(0,0,0,0.10)',
    8: '0 4px 8px 0 rgba(0,0,0,0.10)',
    12: '0 6px 12px 0 rgba(0,0,0,0.10)',
    16: '0 8px 16px 0 rgba(0,0,0,0.10)',
    24: '0 12px 24px 0 rgba(0,0,0,0.10)',
    gridLeftPinnedColumn: '4px 0px 8px rgba(0, 0, 0, 0.1)',
  },

  /** @deprecated */
  width: {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },

  spacing: {
    0: '0',
    2: '2px',
    4: '4px',
    6: '6px',
    8: '8px',
    12: '12px',
    16: '16px',
    20: '20px',
    24: '24px',
    28: '28px',
    32: '32px',
    36: '36px',
    40: '40px',
    44: '44px',
    48: '48px',
    56: '56px',
  },

  zIndex: {
    auto: 'auto',
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    1000: 1000,
    1030: 1030,
    9999: 9999,
  },
};

export const defaultTheme = theme;
