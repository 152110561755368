import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { ListsApi, type RowReorderRequest, type ListRow } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useRowMutations = (): {
  reorderRowMutation: UseMutationResult<
    ListRow[],
    AxiosError,
    {
      id: number;
      reorderRequest: RowReorderRequest;
    },
    unknown
  >;
} => {
  const intl = useIntl();

  const reorderRowMutation = useMutation(
    ({ id, reorderRequest }: { id: number; reorderRequest: RowReorderRequest }) =>
      ListsApi.reorderRows(id, reorderRequest),
    {
      onSuccess: () => {
        notification.success({
          message: intl.formatMessage({
            id: 'lists.toasts.rows.update_success',
          }),
        });
      },
      onError: defaultApiErrorHandler,
    },
  );

  return {
    reorderRowMutation,
  };
};
