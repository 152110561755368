import { type EditorState } from '@codemirror/state';
import { Button } from 'components/ui/atomic-components/button';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontFormula, FontS, FontXs } from 'styles/typography';
import { ContextWrapper } from '../../tag-components/context-wrapper';
import { getContentInParentheses } from './utils/get-content-in-parentheses';
import { getCurrentArgIdx } from './utils/get-current-arg-idx';

const Title = styled.div`
  ${FontFormula};

  font-weight: 600;
  color: ${({ theme }) => theme.colors.magenta500};
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
  background: ${({ theme }) => theme.colors.magenta100};
`;

const Highlight = styled.span`
  background-color: ${({ theme }) => theme.colors.magenta500};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.gray00};
  padding: ${({ theme: { spacing } }) => `${spacing[2]} ${spacing[4]}`};
`;

const SuggestionButton = styled(Button)`
  padding-top: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.magenta500};
  text-decoration: underline;

  &&&:hover {
    color: ${({ theme }) => theme.colors.magenta500};
  }
`;

const Description = styled.div`
  ${FontS};

  border-top: 1px solid ${({ theme }) => theme.colors.magenta200};
  color: ${({ theme }) => theme.colors.textBody2};
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
`;

const SubTitle = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textLabel};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  margin: ${({ theme }) => theme.spacing[8]} 0;
`;

const highlightText = (text: string, highlight: string) => {
  if (!highlight) {
    return text;
  }

  const escapeSpecialChars = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regex with the highlight text
  const regex = new RegExp(`(${escapeSpecialChars})`, 'g');

  // Split text by regex to wrap matching parts with highlight span
  const parts = text.split(regex);

  return parts.map((part) => {
    return regex.test(part) ? <Highlight data-testid="highlight">{part}</Highlight> : part;
  });
};

export const FormulaFunctionTooltip = ({
  state,
  functionInfo,
}: {
  state: EditorState;
  functionInfo: {
    syntax: string;
    desc: string;
    ex?: string;
  };
}): ReactElement => {
  const [showSuggestion, setShowSuggestion] = useState(false);

  const argIdx = getCurrentArgIdx({ state });

  const functionArgs = getContentInParentheses(functionInfo.syntax)?.split(' , ') || [];

  return (
    <ContextWrapper>
      <Title>
        <div>{highlightText(functionInfo.syntax, functionArgs[argIdx])}</div>
        <SuggestionButton type="link" onClick={() => setShowSuggestion((state) => !state)}>
          {showSuggestion ? (
            <FormattedMessage id="formula.hints.hide_suggestion" />
          ) : (
            <FormattedMessage id="formula.hints.see_suggestion" />
          )}
        </SuggestionButton>
      </Title>
      {showSuggestion && (
        <Description>
          {functionInfo.desc && (
            <>
              <SubTitle>
                <FormattedMessage id="formula.hints.about" />
              </SubTitle>
              {functionInfo.desc}
            </>
          )}

          {functionInfo.ex && (
            <>
              {functionInfo.desc && <Divider />}
              <SubTitle>
                <FormattedMessage id="formula.hints.example" />
              </SubTitle>
              {functionInfo.ex}
            </>
          )}
        </Description>
      )}
    </ContextWrapper>
  );
};
