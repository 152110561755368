import { StringParam, useQueryParams } from 'use-query-params';

export const useConnectorQueryParams = (): [
  queryParams: Record<string, string | null | undefined>,
  setQueryParams: (params: Record<string, string>) => void,
] => {
  const [queryParams, setQueryParams] = useQueryParams({
    code: StringParam,
    scope: StringParam,
    realmId: StringParam,
    service: StringParam,
    region: StringParam,
    codeVerifier: StringParam,
    reAuth: StringParam,
    state: StringParam,
  });

  return [queryParams, setQueryParams];
};
