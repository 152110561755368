import { AuthorisedAccess } from 'components/modules/authorised-access';
import { Spinner } from 'components/ui/atomic-components/spinner';
import { PageLayout } from 'components/ui/layout';
import { useRoles } from 'data/roles/hooks/use-roles';
import { PermissionList } from 'data/roles/permissions';
import { isEmpty } from 'lodash';
import { type FC } from 'react';
import { Navigate } from 'react-router-dom';

export const RolesSettingRedirectionPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { roles, isLoading } = useRoles();

  return (
    <AuthorisedAccess permissionType={PermissionList.Settings}>
      {isLoading ? (
        <PageLayout>
          <Spinner />
        </PageLayout>
      ) : (
        <Navigate replace to={`/settings/roles/${isEmpty(roles) ? 'new' : roles?.[0].id}`} />
      )}
    </AuthorisedAccess>
  );
};
