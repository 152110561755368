import styled, { css } from 'styled-components';
import { switchProp, prop } from 'styled-tools';
import { type defaultTheme } from 'styles/theme-old';
import { Caption, P } from 'styles/typography';
import { textColor } from '../utils/text-color';

type ColorKey = keyof typeof defaultTheme.colors;

interface BadgeProps {
  color?: ColorKey;
  size?: 'small' | 'base';
  disabled?: boolean;
}

const StyledBadge = styled.div<BadgeProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[4]}`};
  font-weight: ${({ theme: { fontWeight } }) => `${fontWeight.semiBold}`};
  border-radius: ${({ theme: { border } }) => `${border.radius.sm}`};
  background: ${({ theme, color }) => {
    return theme.colors[(color || 'black20') as ColorKey];
  }};
  color: ${({ color }) => textColor(color)};

  ${switchProp(prop('size', 'base'), {
    small: css`
      ${Caption};
    `,
    base: css`
      ${P};
    `,
  })};
`;

const BadgeRibbon = StyledBadge;

export { BadgeRibbon };
