export const useLeftNavResize = (
  pageId: string,
): { currentWidth?: number; onResize: (size: number) => void } => {
  const leftNavWidth = sessionStorage.getItem(pageId);
  const currentWidth = leftNavWidth ? Number(leftNavWidth) : undefined;

  const onResize = (size: number) => {
    sessionStorage.setItem(pageId, String(size));
  };

  return {
    currentWidth,
    onResize,
  };
};
