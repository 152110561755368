import { type ID } from 'data';
import { type ListUpdate } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { FontM, FontXs } from 'styles/typography';
import { MatchOptionSelect } from './match-option-select';
import { Updates } from './updates-section';
import { getTitleText } from './utils/get-title-text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  color: ${({ theme }) => theme.colors.textBody2};
  gap: ${({ theme }) => theme.spacing[8]};
`;

const Title = styled.div`
  ${FontXs};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const Name = styled.div`
  ${FontM};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

interface Props {
  listUpdate: ListUpdate;
  isRecommendation: boolean;
  pickedRecommendationRowId?: ID | null;
  onPickRecommendation?: (key?: ID) => void;
}

export const LeftSection = ({
  listUpdate,
  isRecommendation,
  pickedRecommendationRowId,
  onPickRecommendation,
}: Props): ReactElement => {
  const { autoReconcile, showAutoReconciliations, entityColumnName } = useListStoreContext(
    (s) => s.reconciliationConfig,
  );

  const titleText = getTitleText({
    action: listUpdate.cardType,
    entityColumnName,
    isRecommendation,
    isAutoReconAndShowUpdate: autoReconcile && showAutoReconciliations,
  });

  return (
    <Wrapper>
      <Title>
        <Name>{listUpdate.entityColumnValue}</Name>
        {titleText}
        {isRecommendation && (
          <MatchOptionSelect listUpdate={listUpdate} onPickRecommendation={onPickRecommendation} />
        )}
      </Title>
      <Updates
        isRecommendation={isRecommendation}
        listUpdate={listUpdate}
        pickedRecommendationRowId={pickedRecommendationRowId}
      />
    </Wrapper>
  );
};
