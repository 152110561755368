import { type QueryClient } from '@tanstack/react-query';
import { type ID } from 'data';
import { QueryTableColumnDataType } from 'data/big-query';
import { type List } from 'data/modelling/lists';
import { DEFAULT_DATE_FORMAT } from 'data/modelling/lists/constants';

interface Props {
  queryClient: QueryClient;
  listId: ID;
  columnName: string;
  type: QueryTableColumnDataType;
  dateFormat?: string;
}

export const addListColumnInCache = ({
  queryClient,
  listId,
  columnName,
  type,
  dateFormat,
}: Props): void => {
  queryClient.setQueryData(['lists'], (existingLists?: List[]) => {
    return existingLists?.map((list) => {
      if (list.id === listId) {
        return {
          ...list,
          config: {
            ...list.config,
            columnOrder: [...list.config.columnOrder, columnName],
            columnTypeMap: { ...list.config.columnTypeMap, [columnName]: type },
            dateFormatMap: {
              ...(list.config.dateFormatMap || {}),
              ...(type === QueryTableColumnDataType.Date
                ? { [columnName]: dateFormat || DEFAULT_DATE_FORMAT }
                : {}),
            },
          },
        };
      }

      return list;
    });
  });

  // queryClient.setQueryData(['lists', listId], (list?: List) => {
  //   if (!list) {
  //     return undefined;
  //   }

  //   return {
  //     ...list,
  //     config: {
  //       ...list.config,
  //       columnOrder: [...(list.config.columnOrder || []), columnName],
  //       columnTypeMap: { ...list.config.columnTypeMap, [columnName]: type },
  //       dateFormatMap: {
  //         ...(list.config.dateFormatMap || {}),
  //         ...(type === QueryTableColumnDataType.Date
  //           ? { [columnName]: dateFormat || DEFAULT_DATE_FORMAT }
  //           : {}),
  //       },
  //     },
  //   } as List;
  // });
};
