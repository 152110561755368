import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { DimensionGroupApi } from 'data/modelling/dimension-group/api';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { ListsApi } from 'data/modelling/lists/api';
import { type List } from 'data/modelling/lists/types';

type GeneralList = List | DimensionGroup;

export const useLists = (): UseQueryResult<GeneralList[], unknown> => {
  const allListsQuery = useQuery(
    ['lists'],
    async () => {
      const promises: Promise<GeneralList[]>[] = [ListsApi.findAll()];

      promises.push(DimensionGroupApi.findAll());

      const promiseResult = await Promise.allSettled(promises);

      const [lists, derivedLists] =
        promiseResult
          .filter((r) => r.status === 'fulfilled')
          .map((r) => (r as PromiseFulfilledResult<GeneralList[]>).value) || [];

      const combinedList = [...(lists || []), ...(derivedLists || [])];

      combinedList.sort((a: GeneralList, b: GeneralList) =>
        (a.displayName || '').localeCompare(b.displayName || ''),
      );

      return combinedList;
    },
    {
      keepPreviousData: false,
      staleTime: StaleTime.Short,
    },
  );

  return allListsQuery;
};
