import styled from 'styled-components';

export default {
  PrimaryText: styled.div`
    color: ${({ theme }) => theme.colors.textLabel};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  SecondaryText: styled.div`
    color: ${({ theme }) => theme.colors.textLabel};
  `,
  Wrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[12]};
  `,
};
