import axios from 'axios';
import { type ID, type SharedType } from 'data';
import { type DateFilterPresetName } from 'data/bootstrap/types';
import { type Currency } from 'data/currencies';
import { type Dimension, type DimensionQueryParamsObject } from 'data/dimension';
import { type ReOrderItem, type ItemMoveRequest } from 'data/folder';
import { generateDataLookupMap } from 'data/reports/utils';
import { type DecodedValueMap } from 'use-query-params';
import { formatDimNames } from 'utils/data-formatter';
import { processLastFilterDimNameToSendInApi } from 'utils/dimensions';
import { preProcessAttributes } from 'utils/reports/pre-process-attributes';
import { createPeriodRangesMap } from 'utils/reports/pre-process-period-ranges';
import { preProcessFrame } from '../../utils/reports/pre-process-frame';
import {
  type Chart,
  ChartType,
  type PreviewChartRequest,
  type PreviewChartResponse,
  type UpdateChartApiProp,
} from './charts/types';
import { type BoardParamsProps } from './hooks';
import { type BoardDimensions, type Board, type BoardDetail } from './types';
import { getScheduleIdFromUrl } from './utils/get-schedule-id-from-url';
import { sanitizeChartPreviewRequest } from './utils/sanitize-chart-preview-request';

export const BoardsApi = {
  getBoardsList: (): Promise<Board[]> => {
    return axios.get('v1/reports').then(({ data }) => data);
  },

  createBoard: (board: Partial<Board>): Promise<Board> => {
    return axios.post('v1/reports', board).then(({ data }) => data.board);
  },

  editBoard: async (id: ID, board: Partial<Board>): Promise<BoardDetail> => {
    const { data } = await axios.patch<{ board: BoardDetail }>(`v1/reports/${id}`, board);

    return data.board;
  },

  moveBoard: (itemMoveRequest: ItemMoveRequest): Promise<void> => {
    return axios.patch(`v1/reports/${itemMoveRequest?.entityId}`, {
      folderId: itemMoveRequest?.folderId,
    });
  },

  getBoardData: async (id: ID): Promise<BoardDetail> => {
    const { data } = await axios.get<{ board: BoardDetail }>(`v1/reports/${id}`);

    return data.board;
  },

  getBoardDimensions: async (
    id: ID,
    query: DecodedValueMap<BoardParamsProps>,
  ): Promise<BoardDimensions> => {
    const { data } = await axios.post<{ board: BoardDimensions }>(
      `v1/reports/${id}/conditional-filters`,
      {
        ...query,
        f: undefined,
        lastFilterDimName: processLastFilterDimNameToSendInApi(
          `report-${id}`,
          query.lastFilterDimName,
        ),
        filters: query.f || null,
        scheduleId: getScheduleIdFromUrl(),
      },
    );

    formatDimNames(data?.board?.granularDimensions);
    formatDimNames(data?.board?.dimensions);

    return data.board;
  },

  deleteBoard: (id: ID): Promise<void> => {
    return axios.delete(`v1/reports/${id}`);
  },

  saveDraftBoard: ({ draftBoardId }: { draftBoardId: ID }): Promise<BoardDetail> => {
    return axios.post(`v1/reports/draft/${draftBoardId}`).then(({ data }) => data.board);
  },

  saveAsDraftBoard: ({
    draftBoardId,
    board,
  }: {
    draftBoardId: ID;
    board: Partial<Board>;
  }): Promise<BoardDetail> => {
    return axios
      .post(`v1/reports/draft/${draftBoardId}/save-as`, board)
      .then(({ data }) => data.board);
  },

  getChart: async (
    boardId: ID,
    chartId: ID,
    options?: Partial<{
      f: DimensionQueryParamsObject | null;
      boardFilters: DimensionQueryParamsObject | null;
      boardGranularity?: string;
      chartFilters: DimensionQueryParamsObject | null;
      granularity: string | null;
      boardStartDate?: string | null;
      boardEndDate?: string | null;
      boardDateOption?: DateFilterPresetName;
      startDate?: string | null;
      endDate?: string | null;
      dateOption?: DateFilterPresetName;
      boardCurrency?: Currency;
      chart: Partial<Chart>;
    }>,
    enableConsistentChartColorsForAutomation?: boolean,
  ): Promise<Chart> => {
    const { data: chartResponse } = await axios.post(`v1/reports/${boardId}/charts/${chartId}`, {
      ...options,
      scheduleId: getScheduleIdFromUrl(),
    });

    formatDimNames(chartResponse?.filterDimensions);
    formatDimNames(chartResponse?.granularDimensions);

    if ([ChartType.Text, ChartType.Empty, ChartType.Heading].includes(chartResponse.type)) {
      return chartResponse;
    }

    const {
      data,
      frame,
      attributes: { rows = [], columns = [], metricDimensionOverrides = {} } = {},
    } = chartResponse;

    const dataLookupMap = generateDataLookupMap({
      data,
      rowsOrder: rows,
      columnsOrder: columns,
      metricDimensionOverrides,
    });

    const processedFrame = preProcessFrame(frame);

    const processedAttributes = preProcessAttributes(
      { ...chartResponse, frame: processedFrame },
      enableConsistentChartColorsForAutomation,
    );

    const periodRangesMap = createPeriodRangesMap(chartResponse?.periodRanges);

    return {
      ...chartResponse,
      data: dataLookupMap,
      frame: processedFrame,
      attributes: processedAttributes,
      periodRangesMap,
    };
  },

  addEditChart: ({
    id,
    chart,
    type,
  }: {
    id: ID;
    chart: PreviewChartResponse;
    type: 'added' | 'updated';
  }): Promise<BoardDetail> => {
    const data: UpdateChartApiProp = {
      [type]: [chart],
    };

    return axios.post(`v1/reports/${id}/charts`, data).then(({ data }) => data.board);
  },

  addMultipleCharts: ({
    id,
    charts,
    type,
  }: {
    id: ID;
    charts: PreviewChartResponse[];
    type: 'added' | 'updated';
  }): Promise<BoardDetail> => {
    const data: UpdateChartApiProp = {
      [type]: charts,
    };

    return axios.post(`v1/reports/${id}/charts`, data).then(({ data }) => data.board);
  },

  deleteChart: ({ boardId, chartId }: { boardId: ID; chartId: ID }): Promise<void> => {
    const data = {
      removed: [{ id: chartId, boardId }],
    };

    return axios.post(`v1/reports/${boardId}/charts`, data);
  },

  updateBoard: ({
    boardId,
    charts,
    attributes,
  }: {
    boardId: ID;
    attributes?: BoardDetail['attributes'];
    charts?: Chart[];
  }): Promise<void> => {
    const data: {
      updated?: Chart[];
      attributes?: BoardDetail['attributes'];
    } = {};

    if (charts) {
      data.updated = charts;
    }

    if (attributes) {
      data.attributes = attributes;
    }

    return axios.post(`v1/reports/${boardId}/charts`, data);
  },

  getPreviewChart: async (
    previewChartReq: PreviewChartRequest,
    enableConsistentChartColorsForAutomation?: boolean,
  ): Promise<Chart & { dimensions: Dimension[] }> => {
    let { data: chartPreview } = await axios.post(
      `v1/reports/chart-preview?boardId=${previewChartReq?.boardId}`,
      sanitizeChartPreviewRequest(previewChartReq),
    );

    chartPreview = {
      ...chartPreview,
      attributes: {
        ...previewChartReq.chart.attributes,
        ...chartPreview.attributes,
      },
    };

    formatDimNames(chartPreview?.filterDimensions);
    formatDimNames(chartPreview?.granularDimensions);

    const {
      data,
      frame,
      attributes: { rows = [], columns = [], metricDimensionOverrides = {} } = {},
    } = chartPreview;

    if ([ChartType.Text, ChartType.Empty].includes(chartPreview.type)) {
      return chartPreview;
    }

    const dataLookupMap = generateDataLookupMap({
      data,
      rowsOrder: rows,
      columnsOrder: columns,
      metricDimensionOverrides,
    });

    const processedFrame = preProcessFrame(frame);

    const processedAttributes = preProcessAttributes(
      { ...chartPreview, frame: processedFrame },
      enableConsistentChartColorsForAutomation,
    );
    const periodRangesMap = createPeriodRangesMap(chartPreview?.periodRanges);

    return {
      ...chartPreview,
      data: dataLookupMap,
      frame: processedFrame,
      attributes: processedAttributes,
      periodRangesMap,
    };
  },

  reorder: ({ items, shared }: { items: ReOrderItem[]; shared: SharedType }): Promise<void> => {
    return axios.post('v1/reports/reorder', { order: items, shared }).then(({ data }) => data);
  },

  reorderReports: (params: {
    reportId: ID;
    nextReportId?: ID;
    prevReportId?: ID;
  }): Promise<{ [key: string]: string }> => {
    return axios.post('v1/reports/reorder', params).then(({ data }) => data);
  },

  moveChart: ({
    chartId,
    fromReport,
    toReport,
  }: {
    chartId: ID;
    fromReport: ID;
    toReport: ID;
  }): Promise<Chart> => {
    const data = {
      chartId,
      fromReport,
      toReport,
    };

    return axios.post('v1/reports/move', data).then(({ data }) => data);
  },
};
