import { type DataFormattingType } from 'utils/data-formatter/types';
import { DisplayUnitType, type FormatterProps } from '../types';
import { otherFormattersForMetricType } from '../utils/other-formatter-map';

export const displayUnitFormattersIND: {
  [key in DisplayUnitType.Lac | DisplayUnitType.Cr]: {
    [key in DataFormattingType | 'default']: (props: FormatterProps) => string;
  };
} = {
  [DisplayUnitType.Lac]: otherFormattersForMetricType,
  [DisplayUnitType.Cr]: otherFormattersForMetricType,
};
