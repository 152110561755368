import { type PreviewChartRequest } from '../charts/types';

// removing some of the keys not needed for data calculations, to make payload size small
export const sanitizeChartPreviewRequest = (
  previewChartReq: PreviewChartRequest,
): PreviewChartRequest => {
  const attributes = previewChartReq?.chart?.attributes || {};

  return {
    ...previewChartReq,
    chart: {
      ...previewChartReq.chart,
      attributes: {
        ...attributes,
        labelType: undefined,
        legendPosition: undefined,
        seriesColorMap: undefined,
        cols: undefined,
        sparklineColor: undefined,
        textBody: undefined,
        textBoxConfig: undefined,
        blankRowsPlacement: undefined,
        tableChartConfig: undefined,
        comboConfig: undefined,
        waterfallConfig: undefined,
        pieChartType: undefined,
        sortConfig: undefined,
        axisConfig: undefined,
      },
    },
  };
};
