import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { Analytics } from 'config/analytics';
import { type ID } from 'data';
import { type IntegrationModalContent, IntegrationsApi } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { useNavigate } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useConnectInputFlow = (
  item: IntegrationModalContent,
  onConnectionSuccess: () => void,
): UseMutationResult<
  {
    data: {
      id: ID;
    };
  },
  AxiosError,
  Record<string, string>,
  unknown
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (data: Record<string, string>) =>
      IntegrationsApi.connectIntegration({ ...data, service: item.id }),
    {
      onSuccess: (data) => {
        Analytics.track('New source', {
          category: 'Data',
          meta: `Source name - ${item.displayName}`,
        });

        return queryClient
          .invalidateQueries(IntegrationKeys.ConnectedIntegrations)
          .then(onConnectionSuccess)
          .then(() => {
            navigate(`/data/connected/${data?.data?.id}`);
          });
      },
      onError: (error: AxiosError) => {
        defaultApiErrorHandler(error);
      },
    },
  );
};
