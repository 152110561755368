import { ReactComponent as DriveAiIcon } from 'assets/v2/drive_ai.svg';
import { Form, IconShell } from 'components/ui/atomic-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../styles';

const { FormulaLabelWrapper, StyledAIButton } = styles;

export const FormulaHeader = ({
  aiEnabledFormula,
  toggleAIButton,
}: {
  aiEnabledFormula: boolean;
  toggleAIButton: () => void;
}): ReactElement => {
  const { deLoreanColumnInLists } = useFlags();

  return (
    <FormulaLabelWrapper>
      <FormattedMessage id="formula" />
      <Form.Item hidden={!deLoreanColumnInLists} name={'aiEnabledFormula'}>
        <StyledAIButton
          $active={!!aiEnabledFormula}
          htmlType="button"
          icon={<IconShell icon={DriveAiIcon} />}
          size="extraSmall"
          type="text"
          onClick={toggleAIButton}
        >
          {aiEnabledFormula ? (
            <FormattedMessage id="formula_bar.title.writing_with_ai" />
          ) : (
            <FormattedMessage id="formula_bar.title.write_with_ai" />
          )}
        </StyledAIButton>
      </Form.Item>
    </FormulaLabelWrapper>
  );
};
