import { Button, Form } from 'components/ui/atomic-components';
import styled from 'styled-components';

export default {
  Wrapper: styled.div`
    padding: ${({ theme }) => theme.spacing[16]};
    min-width: 236px;
  `,

  StyledForm: styled(Form)`
    .ant-form-item {
      margin-bottom: ${({ theme }) => theme.spacing[8]};
    }

    .ant-form-item-label {
      padding: ${({ theme }) => `0 0 ${theme.spacing[4]}`};
    }

    .ant-form-item-label > label::after {
      width: 0;
      margin: 0;
    }
  `,
  FormulaLabelWrapper: styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: -4px;
    height: 24px;

    .ant-form-item {
      margin-bottom: 0;
    }
  `,

  StyledAIButton: styled(Button)<{ $active: boolean }>`
    background: ${({ theme, $active }) => ($active ? theme.colors.primary50 : theme.colors.gray00)};
    color: ${({ theme, $active }) => ($active ? theme.colors.primary500 : theme.colors.gray600)};

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: ${({ theme, $active }) => {
        return $active ? theme.colors.primary50 : theme.colors.gray00;
      }};
      color: ${({ theme, $active }) => ($active ? theme.colors.primary500 : theme.colors.gray600)};
    }
  `,
};
