export const FAREYE_TENANT_DOMAINS = [
  'https://fareye.drivetrain.ai',
  'https://202.drivetrain.ai',
  'https://fareyefinance.drivetrain.ai',
  'https://214.drivetrain.ai',
  'https://fareyefinance2.drivetrain.ai',
  'https://216.drivetrain.ai',
];

export const XERO_ENABLED_TENANT_DOMAINS = [
  'https://1049.drivetrain.ai',
  'https://1217.drivetrain.ai',
  'https://1226.drivetrain.ai',
];
