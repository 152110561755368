import { type ID } from 'data';
import { type ContainerType } from '.';

export const CONVERSATION_EVENT_NAME = 'conversationEvent';
const BASE_KEY = 'conversations';

export const ConversationsStoreKeys = {
  BaseKey: BASE_KEY,
  AllRecords: ({
    container,
    containerId,
  }: {
    container: ContainerType;
    containerId: ID;
  }): (string | ContainerType | ID)[] => [ConversationsStoreKeys.BaseKey, container, containerId],
  AllRecordsWithIdentifier: ({
    container,
    containerId,
    identifier,
  }: {
    container: ContainerType;
    containerId: ID;
    identifier: string;
  }): (string | ContainerType | ID)[] => [
    ConversationsStoreKeys.BaseKey,
    container,
    containerId,
    identifier,
  ],
};
