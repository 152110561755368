import { type ID, type SharedType } from 'data';
import { type PageTemplate } from 'data/page-template';
import { type Module } from '../modelling/modules';

type FolderId = number;

export interface Folder {
  displayName: string;
  description: string;
  modules: Module[];
  folders: Folder[];
  template?: PageTemplate;
  parentId?: FolderId;
  id: FolderId;
  parentFolders: Folder[];
  type: FolderType;
  shared?: SharedType;
}

export enum FolderType {
  Folder = 'FOLDER',
  Board = 'BOARD',
  Module = 'MODULE',
}

export interface BaseHierarchialResource {
  displayName: string;
  description?: string;
  id: ID;
  children?: BaseHierarchialResource[];
  shared: SharedType;
  editable?: boolean;
  type: FolderType;
  icon?: string;
}

export interface HierarchialResource extends BaseHierarchialResource {
  parent?: HierarchialResource;
  children?: HierarchialResource[];
}

export type ItemMoveRequest = {
  entityName?: string;
  entityId?: ID;
  folderId: ID | null;
  folderType: FolderType;
};

export interface ReOrderItem {
  id: ID;
  identifier: FolderType;
}
