import { ReactComponent as AlertIcon } from 'assets/v2/alert-triangle.svg';
import { Chips, IconShell, IconText, Modal } from 'components/ui/atomic-components';
import { isEmpty } from 'lodash';
import { type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { ColumnDetailSection } from './column-detail-section';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageInfo = styled.div`
  ${FontS}

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.red200};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImportErrorModal: FC<
  React.PropsWithChildren<{
    visible: boolean;
    missingCols: string[];
    newCols: string[];
    onClose: () => void;
  }>
> = ({ missingCols, newCols, visible, onClose }) => {
  const intl = useIntl();

  return (
    <Modal
      cancelText={intl.formatMessage({
        id: 'close',
      })}
      centered
      destroyOnClose
      open={visible}
      title={
        <IconText
          color="red500"
          icon={AlertIcon}
          text={intl.formatMessage({ id: 'planning.lists.upload.import_error_modal.title' })}
        />
      }
      width={560}
      onCancel={onClose}
    >
      <Wrapper>
        <MessageInfo>
          <FormattedMessage id="planning.lists.upload.import_error_modal.message" />
        </MessageInfo>

        {!isEmpty(missingCols) && (
          <ColumnDetailSection
            chips={
              <>
                {missingCols.map((col) => (
                  <Chips
                    key={col}
                    bgColor="gray100"
                    borderColor="gray200"
                    size="small"
                    textColor="textTitle2"
                  >
                    {col}
                  </Chips>
                ))}
              </>
            }
            count={missingCols.length}
            icon={
              <IconWrapper>
                <IconShell color="red600" icon={AlertIcon} />
              </IconWrapper>
            }
            title={
              <FormattedMessage id="planning.lists.upload.import_error_modal.cols_not_found" />
            }
          />
        )}

        {!isEmpty(newCols) && (
          <ColumnDetailSection
            chips={
              <>
                {newCols.map((col) => (
                  <Chips
                    key={col}
                    bgColor="gray100"
                    borderColor="gray200"
                    size="small"
                    textColor="textTitle2"
                  >
                    {col}
                  </Chips>
                ))}
              </>
            }
            count={newCols.length}
            icon={
              <IconWrapper>
                <IconShell color="red600" icon={AlertIcon} />
              </IconWrapper>
            }
            isLastItem
            title={
              <FormattedMessage id="planning.lists.upload.import_error_modal.new_cols_detected" />
            }
          />
        )}
      </Wrapper>
    </Modal>
  );
};
