import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'antd/es/form/Form';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { Popover } from 'components/ui/atomic-components';
import { DataMaskingPopoverContent } from 'components/ui/data-masking-popover-content';
import { type FormType } from 'components/ui/data-masking-popover-content/types';
import { Analytics } from 'config/analytics';
import { type ListConfig } from 'data/modelling/lists';
import { useUsers } from 'data/users';
import { keyBy } from 'lodash';
import { type ReactElement, type RefObject, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { getUserFullName } from 'utils';
import { formatName } from 'utils/data-formatter';
import { useUser } from 'utils/hooks';
import { RemoveButton } from './remove-button';

interface Props {
  columnName: string;
  isOpen: boolean;
  closeOpenPopup: (val: boolean) => void;
  columnWrapperDomRef: RefObject<HTMLDivElement>;
}

export const DataMasking = ({
  columnName,
  closeOpenPopup,
  isOpen,
  columnWrapperDomRef,
}: Props): ReactElement => {
  const queryClient = useQueryClient();
  const { updateListMutation } = useListMutations();
  const { data: allUsers = [] } = useUsers();
  const { user: currentUser } = useUser();

  const [form] = useForm<FormType>();

  const listId = useListStoreContext((s) => s.id);
  const config = useListStoreContext((s) => s.config);
  const setConfig = useListStoreContext((s) => s.setConfig);

  const columnWidth = (columnWrapperDomRef.current?.clientWidth || 0) + 8;

  const handleConfirm = async () => {
    const data = await form.validateFields();

    const updatedConfig = {
      ...config,
      maskedColumns: {
        ...config.maskedColumns,
        [columnName]: {
          allowedUsers: data.allowedUsers?.map((u) => u.value) || [],
          allowedDimensions: data.allowedDimensions?.map((d) => d.name) || [],
        },
      },
    } as ListConfig;

    await updateListMutation.mutateAsync({
      id: listId,
      list: { config: updatedConfig },
    });

    setConfig(updatedConfig);

    queryClient.invalidateQueries(['lists', listId]);

    closeOpenPopup(false);

    Analytics.track('Data masking', {
      category: 'Lists',
      meta: `Allowed dimensions: ${updatedConfig?.maskedColumns?.[columnName]?.allowedDimensions}`,
    });
  };

  useEffect(() => {
    const allowedUsers = config?.maskedColumns?.[columnName]?.allowedUsers;
    const allowedDimensions = config?.maskedColumns?.[columnName]?.allowedDimensions;

    const allUsersMap = keyBy([currentUser, ...allUsers], 'username');

    form.setFieldsValue({
      allowedUsers:
        allowedUsers?.map((u) => ({
          label: getUserFullName(allUsersMap[u]),
          value: u,
        })) || [],
      allowedDimensions:
        allowedDimensions?.map((d) => ({
          label: formatName(d),
          value: d,
          name: d,
        })) || [],
    });
  }, [allUsers, columnName, config?.maskedColumns, form, currentUser]);

  return (
    <Popover
      align={{ targetOffset: [columnWidth, 0] }}
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={updateListMutation.isLoading}
      confirmText={<FormattedMessage id="add" />}
      content={<DataMaskingPopoverContent form={form} />}
      footerExtra={
        <RemoveButton
          closePopup={() => closeOpenPopup(false)}
          columnName={columnName}
          form={form}
        />
      }
      open={isOpen}
      placement="leftTop"
      onCancel={() => closeOpenPopup(false)}
      onConfirm={handleConfirm}
      onOpenChange={(val) => {
        if (document.querySelector('.column-select__menu-portal')) {
          return;
        }

        closeOpenPopup(val);
      }}
    >
      <></>
    </Popover>
  );
};
