import { type AxiosError } from 'axios';
import { InlineEditInput } from 'components/ui/atomic-components/inline-edit-input';
import { NAME_CHAR_LIMIT } from 'data/modelling/constants';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { type ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { useListMutations } from '../hooks';
import { useListOptimisticMutations } from '../hooks/use-list-optimistic-mutations';

const InlineEditInputStyled = styled(InlineEditInput)`
  top: -2px;
`;

interface Props {
  name: string;
  isReadOnly?: boolean;
}

export const InlineTitleInput = ({ name, isReadOnly }: Props): ReactElement => {
  const intl = useIntl();
  const { listId } = useParams() as { listId: string };

  const [titleText, setTitleText] = useState(name);

  const { updateListMutation } = useListMutations();
  const { updateDimensionGroup } = useDimensionGroupMutations();
  const { updateDisplayName } = useListOptimisticMutations();

  const onNameUpdate = async (newName: string) => {
    if (newName === name) {
      return;
    }
    setTitleText(newName);

    const isDerivedList = listId.startsWith(DIM_GROUP_ID_PREFIX);
    const id = Number(listId.replace(DIM_GROUP_ID_PREFIX, ''));

    try {
      const updatedName = (newName || '').trim();

      if (isDerivedList) {
        updateDimensionGroup.mutateAsync({ displayName: updatedName, id });
      } else {
        await updateListMutation.mutate({
          id,
          list: { displayName: updatedName },
        });
      }

      updateDisplayName(id, newName, isDerivedList);
    } catch (err) {
      defaultApiErrorHandler(err as AxiosError);
    }
  };

  useEffect(() => {
    setTitleText(name);
  }, [name]);

  return (
    <div>
      <InlineEditInputStyled
        $inputType={'ghostText'}
        defaultValue={name}
        editable={!isReadOnly}
        maxLength={NAME_CHAR_LIMIT}
        placeholder={intl.formatMessage({
          id: 'untitled',
        })}
        size="small"
        value={titleText}
        onBlur={() => onNameUpdate(titleText)}
        onChange={(e) => setTitleText(e.target.value.replaceAll(/\s{2,}/g, ' '))}
      />
    </div>
  );
};
