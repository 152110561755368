import { DatePicker as AntDatePicker } from 'antd';
import { type RangePickerProps } from 'antd/es/date-picker';
import generatePicker, { type PickerProps } from 'antd/es/date-picker/generatePicker';
import { ReactComponent as CalendarEventIcon } from 'assets/v2/calendar-event.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right_16.svg';
import { ReactComponent as CircleXFilledIcon } from 'assets/v2/circle-x-filled.svg';
import { type Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { type ReactElement } from 'react';
import { IconShell } from '../icon-shell';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DefaultDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as any);

const clearIconProp = { clearIcon: <IconShell icon={CircleXFilledIcon} /> };

export const DatePicker = (
  props: PickerProps<Dayjs> & {
    status?: '' | 'error' | 'warning' | undefined;
    hashId?: string | undefined;
    popupClassName?: string | undefined;
    rootClassName?: string | undefined;
  },
): ReactElement => {
  return (
    <DefaultDatePicker
      nextIcon={<IconShell icon={ChevronRightIcon} />}
      prevIcon={<IconShell icon={ChevronLeftIcon} />}
      suffixIcon={<IconShell color="gray400" icon={CalendarEventIcon} />}
      superNextIcon={<IconShell color="gray400" icon={ChevronRightIcon} />}
      superPrevIcon={<IconShell color="gray400" icon={ChevronLeftIcon} />}
      {...props}
      allowClear={props.allowClear !== false ? clearIconProp : false}
    />
  );
};

const CustomRangePicker = (
  props: RangePickerProps & {
    dropdownClassName?: string | undefined;
    popupClassName?: string | undefined;
    rootClassName?: string | undefined;
  },
): ReactElement => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AntDatePicker.RangePicker
      suffixIcon={<IconShell color="gray400" icon={CalendarEventIcon} />}
      superNextIcon={<IconShell color="gray400" icon={ChevronRightIcon} />}
      superPrevIcon={<IconShell color="gray400" icon={ChevronLeftIcon} />}
      {...props}
      allowClear={props.allowClear !== false ? clearIconProp : false}
    />
  );
};

DatePicker.RangePicker = CustomRangePicker as unknown as typeof DefaultDatePicker.RangePicker;
DatePicker.WeekPicker = DefaultDatePicker.WeekPicker;
DatePicker.MonthPicker = DefaultDatePicker.MonthPicker;
DatePicker.YearPicker = DefaultDatePicker.YearPicker;
DatePicker.TimePicker = DefaultDatePicker.TimePicker;
DatePicker.QuarterPicker = DefaultDatePicker.QuarterPicker;
