import { type TooltipProps as AntdTooltipProps } from 'antd';
import styled from 'styled-components';
import { type IconType } from '../icon-shell';

export type CustomTooltipProps = AntdTooltipProps & {
  spacing?: 'comfortable' | 'compact';
  header?: string;
  icon?: IconType;
  shortcuts?: string[];
};

export default {
  TooltipWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme: { spacing } }) => spacing[4]};
    align-self: stretch;
  `,

  Label: styled.span<{ $spacingIsComfortable: boolean }>`
    display: flex;
    font-size: ${({ theme: { fontSize }, $spacingIsComfortable }) => {
      return $spacingIsComfortable ? fontSize.xs : fontSize.xxs;
    }};
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
    line-height: ${({ theme: { lineHeight }, $spacingIsComfortable }) => {
      return $spacingIsComfortable ? lineHeight.xs : lineHeight.xxs;
    }};
  `,

  Space: styled.span<{ $spacingIsComfortable: boolean }>`
    display: flex;
    padding: ${({ theme: { spacing }, $spacingIsComfortable }) => {
      return $spacingIsComfortable ? `${spacing[4]} ${spacing[0]}` : `${spacing[2]} ${spacing[0]}`;
    }};
    align-items: flex-start;
    gap: ${({ theme: { spacing } }) => spacing[4]};
    align-self: stretch;
  `,

  ShortcutKey: styled.span`
    display: flex;
    padding: ${({ theme: { spacing } }) => spacing[2]};
    gap: ${({ theme: { spacing } }) => spacing[8]};
    border-radius: ${({ theme: { spacing } }) => spacing[2]};
    background-color: ${({ theme: { colors } }) => colors.gray700};
    height: ${({ theme: { spacing } }) => spacing[16]};
    width: ${({ theme: { spacing } }) => spacing[16]};
    align-items: center;
    justify-content: center;

    > span {
      color: ${({ theme: { colors } }) => colors.iconDefault};
      font-size: ${({ theme: { spacing } }) => spacing[8]};
      top: 1px;
      position: relative;
    }
  `,

  HeaderWrapper: styled.div`
    opacity: 0.5;
    font-size: ${({ theme: { fontSize } }) => fontSize.xs};
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
    margin-bottom: ${({ theme: { spacing } }) => spacing[2]};
  `,

  IconContainer: styled.div`
    svg {
      height: ${({ theme: { spacing } }) => spacing[16]};
      width: ${({ theme: { spacing } }) => spacing[16]};
    }
  `,
};
