import { Modal as AntdModal, Button } from 'components/ui/atomic-components';
import styled, { css } from 'styled-components';
import { FontS } from 'styles/typography';

export default {
  ImageContainer: styled.img`
    width: 402px;
    object-fit: contain;
  `,
  CancelButton: styled(Button)`
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  `,
  OkButton: styled(Button)``,

  Modal: styled(AntdModal)<{ $showCancel: boolean; $isFormVisible: boolean }>`
    ${({ $isFormVisible }) =>
      $isFormVisible &&
      css`
        top: 40px;
        &.ant-modal .ant-modal-content .ant-modal-footer {
          display: none;
        }

        .ant-modal-body {
          height: 600px;
        }
      `}

    &.ant-modal {
      width: ${({ $isFormVisible }) => ($isFormVisible ? '815px' : '450px')} !important;
      left: auto;
    }

    &.ant-modal .ant-modal-content .ant-modal-body {
      padding: ${({ $isFormVisible, theme }) => {
        return $isFormVisible ? theme.spacing[0] : theme.spacing[24];
      }};
    }

    .ant-modal .ant-modal-content .ant-modal-header {
      height: 64px;
      display: flex;
    }

    .ant-modal .ant-modal-content .ant-modal-close {
      top: 20px;
    }

    .ant-modal-footer > div:last-child {
      display: none;
    }

    &.ant-modal .ant-modal-content .ant-modal-footer {
      justify-content: ${({ $showCancel }) => {
        return $showCancel ? 'space-between' : 'end';
      }};
    }
  `,
  Label: styled.div`
    ${FontS}

    align-items: center;
    padding-top: ${({ theme }) => theme.spacing[24]};
  `,
  ButtonSectionWrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[12]};
  `,
  G2Iframe: styled.iframe`
    height: 600px;
    width: 815px;
    border-width: ${({ theme }) => theme.borderRadius.none};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.m};
  `,
};
