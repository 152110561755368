const BASE_QUERY_KEY = 'integrations';

export const IntegrationKeys = {
  ConnectedIntegrations: [BASE_QUERY_KEY, 'connection-instances'],
  DatasetList: [BASE_QUERY_KEY, 'dataset-list'],
  ConnectionTables: [BASE_QUERY_KEY, 'connection-tables'],
  InstanceSchema: (id: number): (string | number)[] => [
    BASE_QUERY_KEY,
    'connection-instances-schema',
    id,
  ],
  TableSchema: (tableName: string): (string | number)[] => [
    BASE_QUERY_KEY,
    'connection-tables',
    tableName,
  ],
  TableMeta: (tableName: string): string[] => [BASE_QUERY_KEY, 'table-meta', tableName],
};
