import { ReactComponent as EyeIcon } from 'assets/v2/scan-eye.svg';
import {
  IconShell,
  SpinnerIcon,
  type ItemType,
  notification,
} from 'components/ui/atomic-components';
import { usePrefetchResourceUsage } from 'data/resource-usage/hooks';
import { type ResourceUsageType } from 'data/resource-usage/types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRefSync } from 'utils/hooks';
import { hasNoUsageForResource } from '../utils';

interface ReturnProps {
  usageWidgetMenuItem: ItemType;
  isPrefetchingUsage: boolean;
  usageModalProps?: {
    name: string;
    displayName: string;
    type: ResourceUsageType;
  };
  isTryingToCloseWhileUsageFetch: (open: boolean) => boolean;
  closeUsageModal: () => void;
}

export const useResourceUsageWidget = ({
  name,
  displayName,
  type,
  closeMenu,
}: {
  name: string;
  displayName: string;
  type: ResourceUsageType;
  closeMenu: () => void;
}): ReturnProps => {
  const intl = useIntl();

  const ResourceNameIntlMap: Record<ResourceUsageType, string> = {
    DATASETS: 'Dataset',
    DIMENSIONS: 'Dimension',
    LISTS: 'List',
    METRICS: 'Metric',
    VARIABLES: 'Variable',
    DIMENSION_GROUPS: 'List',
  };

  const prefetchUsage = usePrefetchResourceUsage();
  const [isPrefetchingUsage, setIsPrefetchingUsage] = useState(false);
  const isPrefetchingUsageRef = useRefSync(isPrefetchingUsage);

  const [usageModalProps, setUsageModalProps] = useState<
    | {
        name: string;
        displayName: string;
        type: ResourceUsageType;
      }
    | undefined
  >();

  const isTryingToCloseWhileUsageFetch = (open: boolean) => {
    return !open && isPrefetchingUsageRef.current;
  };

  const onCheckUsageMenuClick = async () => {
    try {
      setIsPrefetchingUsage(true);
      const usageData = await prefetchUsage(name, type);

      setIsPrefetchingUsage(false);
      closeMenu();

      if (hasNoUsageForResource(usageData.dependencies)) {
        notification.info({
          message: intl.formatMessage(
            { id: 'resource_usage.no_usage' },
            { resource: ResourceNameIntlMap[type] },
          ),
        });

        return;
      }

      setUsageModalProps({
        name,
        displayName,
        type,
      });
    } catch {
      setIsPrefetchingUsage(false);
    }
  };

  const closeUsageModal = () => {
    setUsageModalProps(undefined);
  };

  const usageWidgetMenuItem: ItemType = {
    key: 'resource-traceability',
    label: <FormattedMessage id="resource_usage.menu_title" />,
    icon: isPrefetchingUsage ? <SpinnerIcon size="small" /> : <IconShell icon={EyeIcon} />,
    onClick: onCheckUsageMenuClick,
  };

  return {
    usageWidgetMenuItem,
    isPrefetchingUsage,
    usageModalProps,
    isTryingToCloseWhileUsageFetch,
    closeUsageModal,
  };
};
