import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { IntegrationsApi } from '../api';
import { IntegrationKeys } from '../constants';
import { type IntegrationConnectedItem, IntegrationConnectedItemStatus } from '../types';

const SHORT_INTERVAL = 2 * 5000; //10 sec
const LONG_INTERVAL = 900 * 1000; //15 mins

export const useGetConnectedIntegrations = (): UseQueryResult<
  IntegrationConnectedItem[],
  unknown
> => {
  const [deb] = useQueryParam('deb', StringParam);
  const [refetchInterval, setRefetchInterval] = useState(SHORT_INTERVAL);

  return useQuery(
    IntegrationKeys.ConnectedIntegrations,
    () => {
      return IntegrationsApi.getConnectedIntegrations(deb);
    },
    {
      refetchInterval,
      onSuccess: (list) => {
        list.every((item) => item.status === IntegrationConnectedItemStatus.Connected)
          ? setRefetchInterval(LONG_INTERVAL)
          : setRefetchInterval(SHORT_INTERVAL);
      },
    },
  );
};
