const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
};

const rgbToHex = (r: number, g: number, b: number): string => {
  const toHex = (c: number) => `0${c.toString(16)}`.slice(-2);

  return `#${toHex(Math.round(r))}${toHex(Math.round(g))}${toHex(Math.round(b))}`;
};

/* 
https://www.youtube.com/watch?v=LKnqECcg6Gw
Computer Color is Broken - minutephysics
*/
export const weightedColorAverage = (color1: string, color2: string, weight: number): string => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const averageRgb = {
    r: Math.sqrt(rgb1.r ** 2 * weight + rgb2.r ** 2 * (1 - weight)),
    g: Math.sqrt(rgb1.g ** 2 * weight + rgb2.g ** 2 * (1 - weight)),
    b: Math.sqrt(rgb1.b ** 2 * weight + rgb2.b ** 2 * (1 - weight)),
  };

  return rgbToHex(averageRgb.r, averageRgb.g, averageRgb.b);
};

export const getColorStrip = (color1: string, color2: string, length: number): string[] => {
  const colorStrip = [];

  for (let i = 0; i < length; i += 1) {
    colorStrip.push(weightedColorAverage(color2, color1, i / (length - 1)));
  }

  return colorStrip;
};
