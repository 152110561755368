import { type VisualQueryBasicFilterRuleValue } from 'data/big-query';
import dayjs from 'dayjs';
import { isNaN } from 'lodash';

export const numberTypeValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data) {
    return data.length === 1 && !isNaN(Number(data[0].value));
  }

  return false;
};

export const stringTypeValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data) {
    return data.length === 1 && typeof data[0].value === 'string';
  }

  return false;
};

export const dateTypeValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data) {
    return data.length === 1 && dayjs(data[0].value).isValid();
  }

  return false;
};

export const arrayTypeValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data?.length) {
    return data.every(
      (valObj) => typeof valObj.value === 'string' || typeof valObj.value === 'number',
    );
  }

  return !!data && Array.isArray(data);
};

export const booleanTypeValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data) {
    return data.length === 1 && Boolean(data[0].value);
  }

  return false;
};

export const betweenTypeValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data) {
    return (
      data.length === 2 &&
      !!data[0].value &&
      !!data[1].value &&
      !isNaN(Number(data[0].value)) &&
      !isNaN(Number(data[1].value))
    );
  }

  return false;
};

export const betweenDateValidator = (data?: VisualQueryBasicFilterRuleValue[]): boolean => {
  if (data) {
    return (
      data.length === 2 &&
      !!data[0].value &&
      !!data[1].value &&
      dayjs(data[0].value).isSameOrBefore(data[1].value)
    );
  }

  return false;
};
