import { css } from 'styled-components';

export const HighchartsGlobalStyles = css`
  .highcharts-legend-item > span {
    position: relative;
    overflow: visible !important;
  }

  .highcharts-tooltip-container {
    z-index: ${({ theme: { zIndex } }) => zIndex[9999]} !important;

    > svg {
      display: none;
    }
  }

  .highcharts-custom-x-axis-label > span {
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: normal;
    text-align: center;
  }
`;
