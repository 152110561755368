import { type Extension } from '@codemirror/state';
import { highlightActiveLine, highlightActiveLineGutter, lineNumbers } from '@codemirror/view';
import { indentationMarkers } from '@replit/codemirror-indentation-markers';
import { defaultTheme } from 'styles/theme';

export const beautify = (): Extension => {
  return [
    lineNumbers(),
    highlightActiveLine(),
    highlightActiveLineGutter(),
    indentationMarkers({
      colors: {
        light: defaultTheme.colors.gray200,
        activeLight: defaultTheme.colors.gray400,
      },
    }),
  ];
};
