import styled from 'styled-components';

const Sizes = {
  small: 16,
  medium: 24,
  large: 32,
};

const InnerSize = {
  small: 12,
  medium: 20,
  large: 24,
};

export type SizeType = {
  size: keyof typeof Sizes;
};

export const SpinnerIcon = styled.div<SizeType>`
  background: linear-gradient(
    0deg,
    rgba(118 103 233 / 20%) 33%,
    ${({ theme }) => theme.colors.primary400} 100%
  );
  border-radius: 50%;
  width: ${({ size }) => Sizes[size]}px;
  height: ${({ size }) => Sizes[size]}px;
  animation: rotation 1s linear infinite;
  display: inline-block;
  box-sizing: border-box;

  ::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${({ size }) => InnerSize[size]}px;
    height: ${({ size }) => InnerSize[size]}px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray00};
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
