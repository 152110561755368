import { useMergeLink } from '@mergeapi/react-merge-link';
import { useEffect, type ReactElement } from 'react';
import { waitTillCondition } from 'utils';
import { useRefSync } from 'utils/hooks';

interface Props {
  mergeToken: string;
  onSuccess: (publicToken: string) => void;
  onExit: () => void;
}

export const MergeWidget = ({ mergeToken, onSuccess, onExit }: Props): ReactElement => {
  const { open, isReady } = useMergeLink({
    linkToken: mergeToken,
    onSuccess,
    onExit,
  });

  const openRef = useRefSync(open);
  const isReadyRef = useRefSync(isReady);

  // on merge token change, wait for updated useMergeLink to be ready, then open merge modal
  useEffect(() => {
    setTimeout(async () => {
      await waitTillCondition(() => isReadyRef.current, 1000, 4); // check for 4secs max if ready

      openRef.current();
    }, 100);
  }, [mergeToken, openRef, isReadyRef]);

  return <></>;
};
