const primaryColors = [
  'gray',
  'primary',
  'red',
  'yellow',
  'green',
  'magenta',
  'orange',
  'aqua',
  'pink',
  'blue',
];

export const getAvatarColorBase = (input?: string): string => {
  if (!input) {
    return 'gray';
  }

  let numberSum = 0;

  for (let i = 0; i < input.length; i += 1) {
    numberSum += input.charCodeAt(i);
  }

  const primaryColorsIndex = numberSum % primaryColors.length;

  return primaryColors[primaryColorsIndex];
};
