import { type ID } from 'data';
import { type RowStructure } from 'data/modelling/lists';
import { isEmpty, isNil, omit } from 'lodash';
import { type ListGridRow } from '../../types';

export const isAllRowValuesEmpty = (data: RowStructure): boolean => {
  if (!data) {
    return true;
  }

  return Object.keys(data).every((key) => isNil(data[key]));
};

export const getRowBackendIds = (rowIds: ID[], rows: ListGridRow[]): ID[] => {
  const rowIdsSet = new Set(rowIds);

  return rows
    .filter((row) => rowIdsSet.has(row.id) && !!row.backendId)
    .map((row) => row.backendId as number);
};

export const replaceSubstringsToUppercase = (
  originalString?: string,
  substrings?: string[],
): string | undefined => {
  if (!originalString || isEmpty(substrings)) {
    return originalString;
  }

  const pattern = new RegExp(
    (substrings?.map((substring) => String.raw`\b${substring}\s?(?=\()`) || []).join('|'),
    'gi',
  );

  return originalString.replace(pattern, (match) => match.toUpperCase());
};

export const getUpdatedRowData = (data: RowStructure, maskedColumns: string[]): RowStructure => {
  if (isEmpty(maskedColumns)) {
    return data;
  }

  return omit(data, maskedColumns);
};
