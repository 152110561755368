import { type ValueSetterParams } from 'ag-grid-community';
import { DateTimeFormats } from 'config/constants';
import { CurrencySymbolMapping } from 'data/currencies';
import dayjs from 'dayjs';
import { isFinite, isNil } from 'lodash';
import { convertPercentageToDecimal, isPercentage } from 'utils/validators';
import { type ListGridRow } from '../../../../types';

export const stringSetter = ({
  params,
  key,
}: {
  params: ValueSetterParams<ListGridRow>;
  key: string;
}): boolean => {
  const { data, newValue } = params;

  if (!data?.data) {
    data.data = {};
  }

  data.data[key] = newValue || undefined;

  return true;
};

const currencySymbolsEscapedForRegex = Object.values(CurrencySymbolMapping).map((symbol) =>
  symbol.replace(/([.$])/g, '\\$1'),
);

export const numericSetter = ({
  params,
  key,
}: {
  params: ValueSetterParams<ListGridRow>;
  key: string;
}): boolean => {
  const { data, newValue } = params;

  let sanitizedNewValue = String(newValue || '').replaceAll(
    new RegExp(`${currencySymbolsEscapedForRegex.join('|')}|,`, 'g'),
    '',
  );

  if (isPercentage(sanitizedNewValue)) {
    sanitizedNewValue = convertPercentageToDecimal(sanitizedNewValue);
  }

  const isValid = isFinite(Number(sanitizedNewValue));

  if (!isValid) {
    return false;
  }

  data.data[key] = (
    !!sanitizedNewValue && !isNil(sanitizedNewValue) ? parseFloat(sanitizedNewValue) : undefined
  ) as number;

  return true;
};

export const dateSetter = ({
  params,
  key,
}: {
  params: ValueSetterParams<ListGridRow>;
  key: string;
}): boolean => {
  const { data, newValue } = params;

  const isValid = newValue ? dayjs(newValue).isValid() : true;

  if (!isValid) {
    return false;
  }

  data.data[key] = (
    newValue && !isNil(newValue) ? dayjs(newValue).format(DateTimeFormats.APIDate) : undefined
  ) as string;

  return true;
};
