import { ReactComponent as ForbiddenIcon } from 'assets/empty/403.svg';
import { Result } from 'components/ui/atomic-components';
import { type PermissionList } from 'data/roles/permissions';
import { type FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useHasScope } from 'utils/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HelmetProxy: any = Helmet;

export const AuthorisedAccess: FC<React.PropsWithChildren<{ permissionType: PermissionList }>> = ({
  permissionType,
  children,
}) => {
  const hasScope = useHasScope();

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: '403.page_title' });

  return !hasScope(permissionType) ? (
    <Wrapper>
      <HelmetProxy>
        <title>{pageTitle}</title>
      </HelmetProxy>
      <Result
        icon={<ForbiddenIcon />}
        subTitle={intl.formatMessage({ id: '403.sub_title' })}
        title={intl.formatMessage({ id: '403.title' })}
      />
    </Wrapper>
  ) : (
    <>{children}</>
  );
};
