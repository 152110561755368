/* eslint-disable no-template-curly-in-string */
export enum FormulaBarTypes {
  MetricExpression = 'MetricExpression',
  DimensionExpression = 'DimensionExpression',
  DimensionGroupExpression = 'DimensionGroupExpression',
  DatasetExpression = 'DatasetExpression',
  Text = 'Text',
  Math = 'Math',
  Period = 'Period',
  LastActualsDate = 'LastActualsDate',
  ListColumnExpression = 'ListColumnExpression',
  DatasetColumnExpression = 'DatasetColumnExpression',
}
export const DimensionPropExprPrefix = 'dims.';
export const DatasetExprPrefix = 'dims.datasets.';
export const DimensionGroupExprPrefix = 'dims.dimgroups.';
export const MetricExprPrefix = 'metrics';
export const ListColumnExprPrefix = 'tbl.';

// Text only expressions will have no regex.
export const FormulaExpressionMapping: Record<FormulaBarTypes, RegExp | undefined> = {
  [FormulaBarTypes.MetricExpression]: /(metrics\.\w+)(\[(.*?)\])*/,
  [FormulaBarTypes.DatasetExpression]: /dims\.datasets\.[-\w.~%]+\.([-\w.~%]+)\.([-\w.~%]+)/,
  [FormulaBarTypes.DimensionGroupExpression]: /dims\.dimgroups\.([-\w.~%]+)\.([-\w.~%]+)/,
  [FormulaBarTypes.DimensionExpression]: /dims\.([-\w.~%]+)\.([-\w.~%]+)/,
  [FormulaBarTypes.Text]: /dims\.t/,
  [FormulaBarTypes.Period]: /time\.period/,
  [FormulaBarTypes.LastActualsDate]: /time\.moduleActualsEndDate/,
  [FormulaBarTypes.ListColumnExpression]: /tbl\.([-\w.~%]+)\.([-\w.~%]+)/,
  [FormulaBarTypes.DatasetColumnExpression]: /datasets\.[-\w.~%]+\.([-\w.~%]+)\.([-\w.~%]+)/,
  // Always math should be the last option for regex evaluation
  [FormulaBarTypes.Math]: undefined,
};

export const FormulaBarPopoverOverlayClass = 'formula-tag-overlay-item';
export const FormulaBarDimSelectClassPrefix = 'formula-dimension-select';
