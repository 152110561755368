import styled from 'styled-components';

export const styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
    width: 258px;
  `,

  Title: styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.textLabel};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]} 0 ${theme.spacing[12]}`};
    cursor: default;
  `,

  CustomPickerSection: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[16]};

    .ant-popover.ant-color-picker {
      left: ${({ theme }) => theme.spacing[12]} !important;
    }

    .ant-popover {
      .ant-popover-content {
        .ant-popover-inner {
          box-shadow: none;
          border: none;
        }
      }
    }

    .ant-color-picker-trigger {
      opacity: 0;
      height: 0;
    }

    .ant-color-picker-color-block,
    .ant-input-number-handler-wrap {
      display: none;
    }
  `,

  ColorPickerWrapper: styled.div`
    height: 242px;
  `,

  CustomPickerFooter: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[4]};
    justify-content: flex-end;
    padding: ${({ theme }) => `0 ${theme.spacing[8]} ${theme.spacing[8]} 0`};
  `,
};
