import { Modal } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import styled from 'styled-components';
import { SearchBox } from './search-box';

const StyledModal = styled(Modal)`
  && .ant-modal-footer {
    display: none;
  }
`;

export const GlobalSearchModal = (): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useHotkeys(['ctrl+k', 'meta+k'], () => setIsModalOpen((val) => !val), [setIsModalOpen]);

  return (
    <StyledModal
      cancelText={false}
      closable={false}
      destroyOnClose
      maskClosable
      open={isModalOpen}
      width={560}
      onCancel={() => setIsModalOpen(false)}
    >
      <SearchBox onPickOption={() => setIsModalOpen(false)} />
    </StyledModal>
  );
};
