import styled from 'styled-components';
import { FontXs, SingleLineEllipsis } from 'styles/typography';

export default {
  Wrapper: styled.div`
    .metric-filter-rule-actions {
      display: none;
    }

    :hover {
      .metric-filter-rule-actions {
        display: flex;
      }
    }
  `,
  CardTitle: styled.div`
    ${FontXs}

    color: ${({ theme }) => theme.colors.textPlaceholder};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  `,
  CardWrapper: styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing[8]};
    border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    max-width: 400px;
    overflow: hidden;

    :hover {
      .metric-filter-rule-actions {
        opacity: 1;
      }
    }
  `,
  RuleContainer: styled.div`
    ${FontXs};

    color: ${({ theme }) => theme.colors.textBody1};
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
  `,

  Operator: styled.span`
    width: fit-content;
    overflow: visible;
  `,
  ValueWrapper: styled.span`
    ${SingleLineEllipsis};
    ${FontXs};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textPrimary};
    cursor: pointer;
  `,
};
