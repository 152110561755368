import { type ReactNode, type ReactElement } from 'react';
import styles from './styles';

const { Wrapper, DividerLine, InnerWrapper, InfoTitleWrapper, InfoTitle, InfoCount, ItemsWrapper } =
  styles;

interface Props {
  chips: ReactNode;
  icon: ReactNode;
  title: ReactNode;
  isLastItem?: boolean;
  count: number;
}

export const ColumnDetailSection = ({
  chips,
  icon,
  title,
  isLastItem,
  count,
}: Props): ReactElement => {
  return (
    <Wrapper>
      <div>
        <div>{icon}</div>
        {!isLastItem && <DividerLine />}
      </div>

      <InnerWrapper>
        <InfoTitleWrapper>
          <InfoTitle>{title}</InfoTitle>
          <InfoCount>{count}</InfoCount>
        </InfoTitleWrapper>

        <ItemsWrapper>{chips}</ItemsWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};
