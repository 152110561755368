import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';

export const Tabs = styled(AntdTabs)`
  width: 100%;
  height: 100%;

  .ant-tabs-content {
    width: 100%;
    height: 100%;
  }
`;
