import { lightenOrDarkenColor } from 'utils/charts/lighten-or-darken-color';
import { getColorList } from './get-color-list';

interface Props {
  seriesIdx: number;
  seriesCount: number;
  version: string | null;
  hash: number;
  versionsColorShadeMap: Record<string, number>;
}

export const getSeriesColor = ({
  seriesIdx,
  seriesCount,
  version,
  hash,
  versionsColorShadeMap,
}: Props): string => {
  const colorList = getColorList(seriesCount);

  const baseColor = colorList[(hash + seriesIdx) % colorList.length];

  if (version === null) {
    return baseColor;
  }

  return lightenOrDarkenColor(baseColor, versionsColorShadeMap[version]);
};
