import { type GridApi } from 'ag-grid-community';
import { type ListConfig } from 'data/modelling/lists';
import { generateRandomNumber } from 'utils';
import { type ListGridRow } from '../../types';

interface Props {
  api?: GridApi;
  config: ListConfig;
  rows: ListGridRow[];
  filteredRows: ListGridRow[];
  addNewRow: (row: ListGridRow) => void;
}

export const addRowToGrid = ({ api, config, rows, filteredRows, addNewRow }: Props): void => {
  api?.clearRangeSelection();

  const randomId = generateRandomNumber();

  const row = {
    id: randomId,
    data: {},
    position: (rows.at(-1)?.position ?? 0) + 1,
  } as ListGridRow;

  const lastRow = filteredRows.at(-1);
  let lastRowIndex = filteredRows.length - 1;

  if (!lastRow || lastRow?.backendId) {
    addNewRow(row);
    lastRowIndex += 1;
  }

  setTimeout(() => {
    api?.ensureIndexVisible(lastRowIndex);
    api?.setFocusedCell(lastRowIndex, config.columnOrder[0]);

    api?.addCellRange({
      rowStartIndex: lastRowIndex,
      rowEndIndex: lastRowIndex,
      columns: [config.columnOrder[0]],
    });
  }, 10);
};
