import { type PageTemplate } from 'data/page-template';
import { type Frame } from 'data/reports/types';
import { structureMetricsLevel } from 'utils/grid/table-view';

export const preProcessFrame = ({
  frame,
  template,
}: {
  frame: Frame;
  template: PageTemplate;
}): Frame => {
  const templateBody = template.body;
  const pivot = template.pivots[0];

  const metricLevelStructuredRows = structureMetricsLevel(
    frame.rows,
    templateBody.elements,
    pivot.viewType,
  );

  return { ...frame, rows: metricLevelStructuredRows };
};
