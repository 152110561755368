import { type ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { type ForwardRefExoticComponent, type RefAttributes } from 'react';
import styled, { type StyledComponent, type DefaultTheme } from 'styled-components';
import { type StyledMarginsProps } from 'styles/styled-atoms';
import { buttonStyle } from './styles';

export type ButtonProps = Omit<AntButtonProps, 'size'> & {
  size?: 'extraSmall' | 'small' | 'middle' | 'large' | 'extraLarge';
} & StyledMarginsProps;

type CompoundedComponent = ForwardRefExoticComponent<ButtonProps & RefAttributes<HTMLElement>> & {
  Group: typeof AntdButton.Group;
};

export const Button = styled(AntdButton).attrs(({ children }) => ({
  children: children ? <span className="btn-content">{children}</span> : children,
}))<ButtonProps>`
  ${({ theme }) => buttonStyle({ theme })};
` as StyledComponent<CompoundedComponent, DefaultTheme, ButtonProps, never>;
