import { stringifyUrl } from 'query-string';

export const constructMetricSearchUrl = ({
  metricName,
  f,
  q,
}: {
  metricName?: string;
  f?: string;
  q?: string;
}): string => {
  return stringifyUrl({
    url: `/explore/${metricName}`,
    query: {
      f,
      q,
    },
  });
};
