import { DEPLOY_ENV } from 'config/environment';
import { useEffect, type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from 'utils/hooks';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    beamer_config?: unknown;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HelmetProxy: any = Helmet;

const isProd = DEPLOY_ENV === 'production';

export const BeamerWhatsNewWidget = (): ReactElement => {
  const { user } = useUser();

  useEffect(() => {
    if (!isProd) {
      return;
    }

    // https://www.getbeamer.com/docs#parameters
    window.beamer_config = {
      product_id: 'PMhMLZSe71630',
      notification_prompt: 'none',
      user_email: user.username,
      selector: '.beamerTrigger',
      mobile: false,
      button: false,
    };
  }, [user.username]);

  if (!isProd) {
    return <></>;
  }

  return (
    <HelmetProxy>
      <script defer src="https://app.getbeamer.com/js/beamer-embed.js" type="text/javascript" />
    </HelmetProxy>
  );
};
