import { Analytics } from 'config/analytics';
import dayjs from 'dayjs';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles';
import { G2_REVIEW_KEY, MAX_VIEW_LIMIT } from './utils';

interface Props {
  showForm: boolean;
  showCancel: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  existingUser: boolean;
}

const { CancelButton, OkButton } = styles;

export const G2Footer = ({
  showCancel,
  showForm,
  setShowForm,
  setShowPopup,
  existingUser,
}: Props): ReactElement => {
  const intl = useIntl();

  if (showForm) {
    return <></>;
  }
  const setDoNotShowAgain = () => {
    Analytics.track("G2 Popup: Don't show again clicked");

    localStorage.setItem(
      G2_REVIEW_KEY,
      JSON.stringify({
        lastViewedTime: dayjs(),
        viewCount: MAX_VIEW_LIMIT,
      }),
    );
    setShowPopup(false);
  };

  const okButtonText = intl.formatMessage({
    id: existingUser ? 'g2review.popup.oktext.existing_user' : 'g2review.popup.oktext',
  });

  return (
    <>
      {showCancel && (
        <CancelButton size="small" type="text" onClick={setDoNotShowAgain}>
          <FormattedMessage id="g2review.popup.canceltext" />
        </CancelButton>
      )}
      <OkButton
        size="small"
        type="primary"
        onClick={async () => {
          setShowForm(true);
        }}
      >
        {okButtonText}
      </OkButton>
    </>
  );
};
