/* eslint-disable filenames/match-regex */
import { createGlobalStyle } from 'styled-components';
import { H1, H2, H3, H4, H5, H6, P } from './typography';
import { FullscreenStyle, HighchartsGlobalStyles } from './vendor';
import { LinkStyles } from './vendor/antd/link-tag';
import { ListStyle } from './vendor/antd/list';
import { MentionsStyles } from './vendor/antd/mentions';

export const GlobalStylesV1 = createGlobalStyle`
  h1 {
    ${H1};
  }

  h2 {
    ${H2};
  }

  h3 {
    ${H3};
  }

  h4 {
    ${H4};
  }

  h5 {
    ${H5};
  }

  h6 {
    ${H6};
  }

  p {
    ${P};
  }

  ${LinkStyles};
  ${FullscreenStyle};
  ${HighchartsGlobalStyles};
  ${ListStyle};
  ${MentionsStyles};
`;
