import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { MonitoringMetricsKeys } from 'data/metrics';
import { MetricBuilderApi } from '../api';
import { type MonitoringMetric } from '../types';

export const useMonitoringMetrics = ({
  query,
  onEmptyLoadTopItems,
  options,
}: {
  query?: string | null;
  onEmptyLoadTopItems?: boolean;
  options?: { enabled?: boolean; staleTime?: number };
}): UseQueryResult<MonitoringMetric[], unknown> => {
  return useQuery(
    MonitoringMetricsKeys.MetricsByQuery(query),
    () => {
      if (query || onEmptyLoadTopItems) {
        return MetricBuilderApi.searchMonitoringMetrics(query || '');
      }

      return MetricBuilderApi.getAllMonitoringMetrics();
    },
    options,
  );
};
