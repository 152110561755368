import { ReactComponent as CurrencyIcon } from 'assets/v2/currency-dollar.svg';
import { ReactComponent as NumberIcon } from 'assets/v2/hash.svg';
import { ReactComponent as PercentageIcon } from 'assets/v2/percentage.svg';
import { DataFormattingType } from 'utils/data-formatter/types';

export const MetricTypeIconMapping = {
  [DataFormattingType.Currency]: CurrencyIcon,
  [DataFormattingType.Number]: NumberIcon,
  [DataFormattingType.Percentage]: PercentageIcon,
};
