import { defaultTheme } from 'styles/theme';

export const getColorList = (seriesCount: number): string[] => {
  const { chartColors } = defaultTheme;

  if (seriesCount <= 7) {
    return chartColors.base7;
  }

  if (seriesCount <= 14) {
    return chartColors.base14;
  }

  if (seriesCount <= 21) {
    return chartColors.base21;
  }

  return chartColors.base28;
};
