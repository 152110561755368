import styled from 'styled-components';
import { InlineEditStyles } from '../styles';

export const InlineEditPopoverStyles = {
  ...InlineEditStyles,
  EditButton: styled(InlineEditStyles.EditButton)<{ $isEditing?: boolean }>`
    right: -${({ theme }) => theme.spacing[20]};
    visibility: ${({ $isEditing }) => ($isEditing ? 'visible' : 'hidden')};
  `,
};
