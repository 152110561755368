import { type ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { ComponentValue } from './cell-components/component-value-renderer';

export const useColDefs = (): ColDef[] => {
  const colDefs: ColDef[] = useMemo(() => {
    return [
      {
        headerName: '',
        field: 'name',
        resizable: true,
        flex: 2,
        cellClass: 'left-border',
        headerClass: 'left-border',
        suppressMenu: true,
        sortable: false,
        tooltipField: 'name',
        maxWidth: 480,
      },
      {
        headerName: 'Value',
        field: 'value',
        cellRenderer: ComponentValue,
        resizable: true,
        flex: 1,
        cellClass: 'ag-right-aligned-cell',
        headerClass: 'ag-right-aligned-header',
        suppressMenu: true,
        sortable: true,
      },
    ] as ColDef[];
  }, []);

  return colDefs;
};
