import { type CompletionContext, autocompletion, type Completion } from '@codemirror/autocomplete';
import { type EditorState, type Extension } from '@codemirror/state';
import { type EditorView } from '@codemirror/view';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';

const applyCompletion = (option: FormulaAutocompleteOption, state: EditorState) => {
  return (view: EditorView, completion: Completion, from: number, to: number) => {
    const { replacementText } = option;

    if (!replacementText) {
      return;
    }

    view.dispatch({
      changes: { from, to, insert: replacementText },
    });
  };
};

export const autoCompletion = (autoCompletionOptions: FormulaAutocompleteOption[]): Extension => {
  const customCompletions = (ctx: CompletionContext) => {
    const matchBeforeWord = ctx.matchBefore(/\w*/);

    if (!matchBeforeWord || (matchBeforeWord.from === matchBeforeWord.to && !ctx.explicit)) {
      return null;
    }

    const options = autoCompletionOptions.map((option) => ({
      label: option.displayText as string,
      displayLabel: option.displayText,
      apply: applyCompletion(option, ctx.state),
    }));

    return {
      options,
      from: matchBeforeWord.from,
      validFor: /^\s*.*\s*$/,
    };
  };

  return autocompletion({
    override: [customCompletions],
  });
};
