import { Tooltip } from 'components/ui/atomic-components';
import { VisualQueryFilterOperator, type VisualQueryFilterRule } from 'data/big-query';
import { isEmpty } from 'lodash';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles';
import { ValueTooltip } from './value-tooltip';

const { ValueWrapper } = styles;

interface Props {
  rule: VisualQueryFilterRule;
}

export const ValueRenderer = ({ rule }: Props): ReactElement => {
  const intl = useIntl();

  const blankLabel = intl.formatMessage({ id: 'dimensions.blank' });

  if (
    isEmpty(rule.data) &&
    ![VisualQueryFilterOperator.IsEmpty, VisualQueryFilterOperator.IsNotEmpty].includes(
      rule.operator,
    )
  ) {
    return (
      <>
        <FormattedMessage id="select" />
        ...
      </>
    );
  }

  if (rule.operator === VisualQueryFilterOperator.Between) {
    return (
      <ValueWrapper>
        <span>{rule?.data?.[0]?.value}</span> <FormattedMessage id="and" />{' '}
        <span>{rule?.data?.[1]?.value}</span>
      </ValueWrapper>
    );
  } else if (
    [VisualQueryFilterOperator.SelectFromValues, VisualQueryFilterOperator.NotOneOf].includes(
      rule.operator,
    )
  ) {
    const valueItems = rule?.data?.map((item) => item.value || blankLabel) || [];

    return (
      <ValueWrapper>
        <Tooltip title={<ValueTooltip values={valueItems} />}>
          <span>
            {valueItems?.[0] || blankLabel}
            {valueItems?.length > 1
              ? `, +${valueItems?.length - 1} ${intl.formatMessage({ id: 'more' })}`
              : ''}
          </span>
        </Tooltip>
      </ValueWrapper>
    );
  } else if (
    [VisualQueryFilterOperator.IsEmpty, VisualQueryFilterOperator.IsNotEmpty].includes(
      rule.operator,
    )
  ) {
    return <></>;
  }

  const value = rule?.data?.[0]?.value;

  return (
    <ValueWrapper>
      <span>{value === '' ? blankLabel : value}</span>
    </ValueWrapper>
  );
};
