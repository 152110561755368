import {
  PeriodOptions,
  PeriodOptionsQuarterly,
  PeriodOptionsWeekly,
  PeriodOptionsYearly,
} from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { DimensionBlankIntermValue } from 'components/ui/dimensions-bar-v2/multiselect/utils';
import { Granularity } from 'data';
import { DimensionAllMarker } from 'data/dimension';
import { isUndefined } from 'lodash';
import { type PeriodOptionType } from './time-value-selector/menu-options/types';

export const getSelectedValue = (value?: string): string => {
  if (isUndefined(value)) {
    return DimensionAllMarker;
  }

  if (value === '') {
    return DimensionBlankIntermValue;
  }

  return value;
};

export const getPeriodOption = (value?: Granularity): PeriodOptionType[] => {
  if (value === Granularity.Weekly) {
    return PeriodOptionsWeekly;
  }
  if (value === Granularity.Yearly) {
    return PeriodOptionsYearly;
  }
  if (value === Granularity.Quarterly) {
    return PeriodOptionsQuarterly;
  }

  return PeriodOptions;
};
