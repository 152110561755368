import { type CustomCellRendererProps } from 'ag-grid-react';
import { type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { SelectorCheckbox } from './selector-checkbox';

export const IdCellRenderer = ({ value, data, node }: CustomCellRendererProps): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);
  const isReadOnly = useListStoreContext((s) => s.isReadOnly);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const config = useListStoreContext((s) => s.config);
  const isDerivedList = useListStoreContext((s) => s.isDerivedList);

  const showSelectorCheckbox =
    !isDerivedList && hasListAccess(accessAction, isReadOnly, config.backedByBQ).selectRows;
  const rowId = data.id;

  if (selectedRowIds.includes(rowId)) {
    return <SelectorCheckbox node={node} rowId={rowId} />;
  }

  return (
    <>
      <div className="id-cell">{value}</div>
      {showSelectorCheckbox && (
        <SelectorCheckbox className="row-selection-cell" node={node} rowId={rowId} />
      )}
    </>
  );
};
