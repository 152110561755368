import {
  type DragEndEvent,
  type DragStartEvent,
  useSensor,
  useSensors,
  type SensorDescriptor,
  type SensorOptions,
  PointerSensor,
} from '@dnd-kit/core';
import { ReorderItemsContext } from 'components/modules/boards-v2/reorder-items-context';
import { applySort } from 'components/ui/dnd-kit/utils';
import { useContext, useEffect, useState } from 'react';
import { type DirectoryTreeNodeDataType } from '../types';

export const useDirectoryDragDrop = (
  treeData: DirectoryTreeNodeDataType[],
): {
  onDragStart: (event: DragStartEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
  activeIndex: number | undefined;
  directoryTree: DirectoryTreeNodeDataType[];
  isReordering: boolean;
} => {
  const [directoryTree, setDirectoryTree] = useState<DirectoryTreeNodeDataType[]>(treeData);
  const [activeIndex, setActiveIndex] = useState<number>();
  const { onReorderItems, isReordering } = useContext(ReorderItemsContext);

  useEffect(() => {
    setDirectoryTree(treeData);
  }, [treeData]);

  const onDragEnd = (event: DragEndEvent) => {
    const { active } = event;
    const newOrderedItems = applySort('key', directoryTree || [], event);

    if (newOrderedItems) {
      onReorderItems?.(Number(active.id), newOrderedItems);

      setDirectoryTree(newOrderedItems);
      setActiveIndex(undefined);
    }
  };

  const onDragStart = (event: DragStartEvent) => {
    if (directoryTree) {
      const index = directoryTree.findIndex((d) => d.key === Number(event.active.id));

      index >= 0 && setActiveIndex(index);
    }
  };

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const sensors = useSensors(pointerSensor);

  return {
    sensors,
    onDragEnd,
    onDragStart,
    activeIndex,
    isReordering: !!isReordering,
    directoryTree,
  };
};
