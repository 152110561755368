import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormConnectorStyles } from '../../integrations/styles';
import { type IntegrationInputFlowComp } from '../../types';
import { ConnectForm } from './connect-form';

const { FullWidthWrapper, RightPanel, RightTitle } = FormConnectorStyles;

interface Props {
  flow: IntegrationInputFlowComp;
  item: IntegrationModalContent;
  onConnectionSuccess: () => void;
}

export const InputsFlow = ({ flow, item, onConnectionSuccess }: Props): ReactElement => {
  const InfoComp = flow.infoContent;

  return (
    <FullWidthWrapper>
      {InfoComp && <InfoComp item={item} />}

      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <ConnectForm flow={flow} item={item} onConnectionSuccess={onConnectionSuccess} />
      </RightPanel>
    </FullWidthWrapper>
  );
};
