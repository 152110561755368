import styled from 'styled-components';
import { FontXs, FontXxs } from 'styles/typography';

export const Styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[2]};
  `,

  Update: styled.div`
    ${FontXs};

    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
    padding: ${({ theme }) => `${theme.spacing[2]} 0`};
  `,

  UpdateKey: styled.div`
    color: ${({ theme }) => theme.colors.textDisabled};
  `,

  UpdateValue: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
  `,

  ChipTextWrapper: styled.div`
    ${FontXxs}
  `,
};
