import { Currency, CurrencyRegexMap } from 'data/currencies';

// const DecimalStartingWith$Regex = /^[+-]?\$(\.\d+|\d+\.?\d*)$/;
const DecimalEndingWithPercentRegex = /^[+-]?(\d+|(\.\d+))(\.\d+)?%$/;
const DecimalNumberRegex = /^[+-]?((\d*\.\d+)|(\d+\.\d*))$/;

export const isNumeric = (value: string): boolean => value !== '' && !isNaN(Number(value));

export const isFraction = (value: string): boolean => parseFloat(value) - parseInt(value) !== 0;

export const isPercentage = (value: string | number): boolean =>
  DecimalEndingWithPercentRegex.test(`${value}`);

export const isDecimalNumber = (value: string | number): boolean =>
  DecimalNumberRegex.test(`${value}`);

export const isCurrency = (value: string | number, currency = Currency.USD): boolean =>
  CurrencyRegexMap[currency].test(`${value}`);

// TODO @arnav: Temporary workaround for dimensions page. Need to find a proper currency way for both dimensions grid and Formula bar.
export const isCurrencyWithOptionalSymbol = (value: string | number): boolean =>
  /(?=.*?\d)^[+-]?\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d+)?$/.test(`${value}`);

export const stripCurrency = (value: string, currency = Currency.USD): string =>
  value.replace(new RegExp(`[${currency}]`), '');

export const stripPercentage = (value: string | number): string => String(value).replace(/[%]/, '');

export const convertPercentageToDecimal = (value: string | number): string =>
  String(Number(stripPercentage(value)) / 100);
