import { Button } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { MetricErrorPopover } from '../../modules/detail-v2/table/cell-components/metric-cell/metric-error-popover';
import { ErrorIndicator } from '../../modules/detail-v2/table/cell-components/metric-cell/metric-error-popover/error-indicator';

interface Props {
  errors: { msg: string }[];
}

export const ErrorPopover = ({ errors }: Props): ReactElement => {
  return (
    <MetricErrorPopover errors={errors.map((e) => e.msg)}>
      <Button size="extraSmall" type="link">
        <ErrorIndicator />
      </Button>
    </MetricErrorPopover>
  );
};
