import { type ReactNode, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { ContextWrapper } from '../context-wrapper';

const Wrapper = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textNegative};
`;

const Tag = ({ children }: { children?: ReactNode }): ReactElement => {
  const intl = useIntl();
  const deletedMetricLabel = intl.formatMessage({ id: 'formula_bar.warning.missing_metric' });

  return <Wrapper>{`{{${deletedMetricLabel}: ${children}}}`}</Wrapper>;
};

export const DeletedMetricTag = ({ children }: { children?: ReactNode }): ReactElement => {
  return (
    <ContextWrapper>
      <Tag>{children}</Tag>
    </ContextWrapper>
  );
};
