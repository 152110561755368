import { type ColDef } from 'ag-grid-community';
import { MASKING_PLACEHOLDER } from 'data/constants';

export const maskedColumnDef: ColDef = {
  cellEditor: undefined,
  cellRendererSelector: undefined,
  editable: false,
  valueGetter: () => MASKING_PLACEHOLDER,
  valueFormatter: undefined,
};
