import { syntaxTree } from '@codemirror/language';
import { type EditorState } from '@codemirror/state';

export const getCurrentArgIdx = ({ state }: { state: EditorState }): number => {
  const syntaxTreeObj = syntaxTree(state);

  const cursorPosition = state.selection.main.head;

  let functionNode: ReturnType<typeof syntaxTreeObj.resolveInner> | null =
    syntaxTreeObj.resolveInner(cursorPosition, 0);

  while (functionNode && functionNode.type.name !== 'Function') {
    functionNode = functionNode.parent;
  }

  const functionArgumentsNode = functionNode?.getChild('FunctionArguments');

  if (!functionArgumentsNode || !functionNode) {
    return -1;
  }

  const functionText = state.sliceDoc(functionNode.from, functionNode.to);

  if (
    cursorPosition - functionNode.from <= functionText.indexOf('(') ||
    cursorPosition - functionNode.from > functionText.lastIndexOf(')')
  ) {
    return -1;
  }

  const argsNodes = functionArgumentsNode.getChildren('FunctionArgument');

  let argsLeftOfCursor = state.sliceDoc(functionArgumentsNode.from, cursorPosition);

  // remove all arguments and leave only commas
  for (let idx = argsNodes.length - 1; idx >= 0; idx -= 1) {
    const argNode = argsNodes[idx];

    argsLeftOfCursor =
      argsLeftOfCursor.slice(0, argNode.from - functionArgumentsNode.from) +
      argsLeftOfCursor.slice(argNode.to - functionArgumentsNode.from);
  }

  return argsLeftOfCursor.match(new RegExp(',', 'g'))?.length ?? 0;
};
