import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';

export const styles = {
  StyledEditor: styled.div`
    .cm-editor.cm-focused {
      outline: 1px solid ${({ theme }) => theme.colors.primary400};
      border-radius: ${({ theme }) => theme.borderRadius.xs};

      .cm-content {
        border: none;
      }
    }

    .cm-content {
      padding: 0;
      background-color: ${({ theme }) => theme.colors.gray00};
      border-radius: ${({ theme }) => theme.borderRadius.xs};
      border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    }

    .cm-line {
      ${FontXs};

      color: ${({ theme }) => theme.colors.textBody2};
      padding: ${({ theme }) => theme.spacing[8]};
    }
  `,

  TooltipWrapper: styled.div`
    .cm-tooltip.cm-tooltip-autocomplete {
      position: static !important;
      top: 0 !important;
      left: 0 !important;
      border: none;
      background-color: ${({ theme }) => theme.colors.gray00};
      border-radius: ${({ theme }) => theme.borderRadius.xs};
      padding: ${({ theme: { spacing } }) => `0 ${spacing[6]} ${spacing[4]} ${spacing[6]}`};

      ul {
        font-family: ${({ theme }) => theme.font.text};
        margin-top: ${({ theme }) => theme.spacing[8]};
        max-height: 224px;
        min-width: auto;

        li {
          display: flex;
          align-items: center;
          padding: ${({ theme }) => theme.spacing[4]};
          margin-bottom: ${({ theme }) => theme.spacing[4]};

          .cm-completionLabel {
            ${FontS};

            flex: 1;
            color: ${({ theme }) => theme.colors.textTitle1};

            .cm-completionMatchedText {
              background-color: ${({ theme }) => theme.colors.yellow400};
              text-decoration: none;
            }
          }

          .cm-completionIcon {
            display: none;
          }
        }

        li[aria-selected='true'] {
          background-color: ${({ theme }) => theme.colors.primary50};
          border-radius: ${({ theme }) => theme.borderRadius.xs};
        }
      }
    }
  `,
};
