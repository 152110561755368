import { type EditorView } from '@codemirror/view';
import { Radio } from 'components/ui/atomic-components/radio';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { Granularity } from 'data';
import { useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { CustomTimeAutoComplete } from './custom-time-auto-complete';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const Label = styled.div`
  display: flex;
`;

export const CustomOption = ({
  selectedValue,
  isSelected,
  timeFilterGranularity,
  customTimeAutoCompletionOptions,
  onPressEnter,
  setEditorView,
}: {
  selectedValue?: string;
  isSelected?: boolean;
  timeFilterGranularity: Granularity;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  onPressEnter?: (value: string) => void;
  setEditorView?: (editorView: EditorView | null) => void;
}): ReactElement => {
  const intl = useIntl();
  const [value, setValue] = useState(isSelected ? selectedValue : '');

  return (
    <Wrapper>
      <Label>
        <Radio checked={isSelected} size="small" />
        {timeFilterGranularity === Granularity.Weekly ? (
          <FormattedMessage id="formula_bar.expressions.custom.weekly" />
        ) : (
          <FormattedMessage id="formula_bar.expressions.custom" />
        )}
      </Label>

      <CustomTimeAutoComplete
        autoCompletionOptions={customTimeAutoCompletionOptions}
        placeholderText={intl.formatMessage({
          id:
            timeFilterGranularity === Granularity.Weekly
              ? 'formula_bar.expressions.custom.example.weekly'
              : 'formula_bar.expressions.custom.example',
        })}
        setEditorView={setEditorView}
        value={value?.split('=')[1]}
        onPressEnter={(value) => {
          const valueWithoutSpaces = value.replaceAll(' ', '');

          onPressEnter?.(valueWithoutSpaces || '');
          setValue(valueWithoutSpaces);
        }}
      />
    </Wrapper>
  );
};
