import isHotkey from 'is-hotkey';
import { useEffect } from 'react';
import { useListStoreInstance } from 'store/lists';
import { undoRedoActionsMap } from '../actions';

export const useListsUndoRedoListener = (): void => {
  const listStore = useListStoreInstance();

  useEffect(() => {
    const handlerKeyDown = (e: KeyboardEvent) => {
      const { undoStack, redoStack, isReadOnly } = listStore.getState();

      const target = e.target as HTMLElement;

      if (target && ['input', 'select', 'textarea'].includes(target.tagName)) {
        return;
      }

      if (isReadOnly) {
        return;
      }

      if (isHotkey('mod+z', e)) {
        e.preventDefault();

        if (!undoStack.length) {
          return;
        }

        const action = undoStack.pop();

        if (action?.type) {
          const undoFn = undoRedoActionsMap[action?.type];

          undoFn({
            listStore,
            type: 'undo',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            meta: action.meta as any,
          });
        }
      }

      if (isHotkey('mod+y', e) || isHotkey('mod+shift+z', e)) {
        e.preventDefault();

        if (!redoStack.length) {
          return;
        }

        const action = redoStack.pop();

        if (action?.type) {
          const redoFn = undoRedoActionsMap[action?.type];

          redoFn({
            listStore,
            type: 'redo',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            meta: action.meta as any,
          });
        }
      }
    };

    document.addEventListener('keydown', handlerKeyDown);

    return () => {
      document.removeEventListener('keydown', handlerKeyDown);
    };
  }, [listStore]);
};
