import { type UploadFile } from 'antd';
import { ReactComponent as UploadIcon } from 'assets/v2/upload.svg';
import { Button, IconShell, Upload, notification } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UploadFlow } from './upload-flow';

export const UploadCSV = (): ReactElement => {
  const intl = useIntl();

  const [file, setFile] = useState<UploadFile<string | Blob>>();

  const uploadProps = {
    maxCount: 1,

    accept: '.csv',
    showUploadList: false,
    onRemove: () => setFile(undefined),

    beforeUpload: (file: UploadFile<string | Blob>) => {
      const isCsv = file.type === 'text/csv';

      if (!isCsv) {
        notification.error({
          message: intl.formatMessage({
            id: 'planning.lists.upload.invalid_file',
          }),
        });
      } else {
        setFile(file);
      }

      return false;
    },
  };

  return (
    <>
      <Upload {...uploadProps}>
        <Button icon={<IconShell icon={UploadIcon} />} size="small">
          <FormattedMessage id="lists.upload_csv.title" />
        </Button>
      </Upload>

      {Boolean(file) && <UploadFlow file={file} onUploadFlowEnded={() => setFile(undefined)} />}
    </>
  );
};
