import { QueryableMetricSelectDropdown } from 'components/ui/metric/async-select';
import { type MetricDropdownOption, MetricsApi } from 'data/metrics';
import { Field, Operator, SearchItemType } from 'data/search';
import { type FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { type SelectInstance, type SingleValue } from 'react-select';
import styled from 'styled-components';
import { H5 } from 'styles/typography';
import { StringParam, useQueryParams } from 'use-query-params';
import { constructMetricSearchUrl } from 'utils/search/construct-metric-search-url';
import { GlobalSearchBoxControl } from './control';
import { GlobalSearchLoadingMessage } from './loading';
import { GlobalSearchBoxMenu } from './menu';
import { GlobalSearchNoOptionsMessage } from './no-options';
import { GlobalSearchBoxOption } from './option';

const Dropdown = styled(QueryableMetricSelectDropdown)`
  height: 56px;
  width: 100%;

  .react-select__control {
    ${H5};

    border: none;
    border-radius: ${({ theme: { border } }) => border.radius.lg};
    box-shadow: none;
    height: 100%;
    padding: 0 ${({ theme: { spacing } }) => spacing[16]};

    &--menu-is-open {
      border-radius: ${({ theme: { border } }) => `${border.radius.lg} ${border.radius.lg} 0 0`};
    }
  }

  .react-select__menu {
    padding: 0;
    margin: 0;
    min-height: 100px;
    box-shadow: none;
    border-top: solid 1px ${({ theme: { border } }) => border.color};
    border-radius: ${({ theme: { border } }) => `0  0 ${border.radius.lg} ${border.radius.lg}`};

    &-list {
      padding: 0;
      border-radius: ${({ theme: { border } }) => `0  0 ${border.radius.lg} ${border.radius.lg}`};
    }

    .react-select__option {
      border-top: solid 1px ${({ theme: { border } }) => border.color};
      padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[16]}`};

      &:first-child {
        border: none;
        margin-top: 0;
      }
    }
  }
`;

export const SearchBox: FC<React.PropsWithChildren<{ onPickOption(): void }>> = ({
  onPickOption,
}) => {
  const navigate = useNavigate();
  const { metricName } = useParams() as { metricName: string };

  const selectRef = useRef<SelectInstance<MetricDropdownOption>>(null);
  const [selectedValue, setSelectedValue] = useState<MetricDropdownOption>();

  const [query] = useQueryParams({
    q: StringParam,
  });

  const { q } = query;

  useEffect(() => {
    if (q) {
      MetricsApi.query(q).then((data) => setSelectedValue(data.find((m) => m.name === metricName)));
    }
  }, [q, metricName]);

  const onChange = (value: SingleValue<MetricDropdownOption>) => {
    const url = constructMetricSearchUrl({
      metricName: value?.name,
      q: value?.displayName,
    });

    navigate(url);

    onPickOption();
  };

  useEffect(() => {
    setTimeout(() => selectRef.current?.focus(), 10);
  }, []);

  return (
    <Dropdown
      components={{
        Control: GlobalSearchBoxControl,
        Option: GlobalSearchBoxOption,
        DropdownIndicator: null,
        Menu: GlobalSearchBoxMenu,
        NoOptionsMessage: GlobalSearchNoOptionsMessage,
        LoadingMessage: GlobalSearchLoadingMessage,
        LoadingIndicator: undefined,
      }}
      filters={[
        {
          field: Field.Type,
          value: SearchItemType.Dimension,
          operator: Operator.NOT,
        },
      ]}
      placeholder={<FormattedMessage id="global_search.placeholder" />}
      selectRef={selectRef}
      size="large"
      value={selectedValue}
      onChange={onChange}
    />
  );
};
