import { DEPLOY_ENV } from 'config/environment';

export const getCallbackUrl = (): string => {
  switch (DEPLOY_ENV) {
    case 'production':
      return 'https://id.drivetrain.ai/integrations/auth-callback';
    case 'preprod':
      return 'https://id.preprod.drivetrain.ai/integrations/auth-callback';
    case 'staging':
      return 'https://id.staging.drivetrain.ai/integrations/auth-callback';
  }

  return 'http://localhost:3000/integrations/auth-callback';
};

export const getState = (id: number): string =>
  `service~${id}~~tenantOrigin~${window.location.origin}`;

export const getReauthState = (id: number, instanceId: number): string =>
  `${getState(id)}~~re-auth~${instanceId}`;

export const extractValueFromState = (key: string, state?: string | null): string | undefined => {
  const regex = new RegExp(`${key}~(.*?)(~~|$)`);

  return (state || '').match(regex)?.[1];
};

export const GoogleSheetScopes = [
  'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fspreadsheets.readonly',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
];
