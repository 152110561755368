import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import { DimensionBlankIntermValue } from 'components/ui/dimensions-bar-v2/multiselect/utils';
import { type Granularity } from 'data';
import { DimensionAllMarker } from 'data/dimension';
import { findDeep } from 'deepdash-es/standalone';
import { isUndefined } from 'lodash';
import { type IntlShape } from 'react-intl';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { FormulaTermRegexMap } from '../../../extensions/expression-constructs/constants';
import { getPeriodOption } from '../dimensions-selector-menu/utils';

export const getSelectedValue = (value?: string): string => {
  if (isUndefined(value)) {
    return DimensionAllMarker;
  }

  if (value === '') {
    return DimensionBlankIntermValue;
  }

  return value;
};

export const getTimeSubscriptDisplayValue = (
  intl: IntlShape,
  timeValueSubscript?: string,
  timeFilterGranularity?: Granularity,
): string | null => {
  if (!timeValueSubscript || timeValueSubscript === 't=t') {
    return null;
  }

  const timeValue = timeValueSubscript.split('=')?.[1];

  const periodOptions = getPeriodOption(timeFilterGranularity);

  const periodDisplayValue = findDeep(periodOptions, (option) => option.value === timeValue, {
    childrenPath: ['children'],
  })?.value?.label;

  if (periodDisplayValue) {
    return periodDisplayValue;
  }

  return timeValueSubscript
    .replaceAll(
      'time.moduleActualsEndDate',
      intl.formatMessage({ id: 'formula.constructs.last_actuals_date' }),
    )
    .replaceAll(FormulaTermRegexMap[FormulaBarTypes.DatasetExpression], (match) =>
      formatName(getColumnNameFromRawDimensionName(match)),
    )
    .replaceAll(FormulaTermRegexMap[FormulaBarTypes.DimensionExpression], (match) =>
      formatName(getColumnNameFromRawDimensionName(match)),
    )
    .replaceAll(FormulaTermRegexMap[FormulaBarTypes.DimensionGroupExpression], (match) =>
      formatName(getColumnNameFromRawDimensionName(match)),
    );
};
