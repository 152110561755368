import { ReactComponent as PinIcon } from 'assets/v2/pin.svg';
import { ReactComponent as PinnedOffIcon } from 'assets/v2/pinned-off.svg';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { IconShell, type ItemType } from 'components/ui/atomic-components';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';

export const useFrozenColumn = (
  colName: string,
): {
  menuItem: ItemType;
} => {
  const listId = useListStoreContext((s) => s.id);
  const config = useListStoreContext((s) => s.config);
  const setConfig = useListStoreContext((s) => s.setConfig);

  const { updateListMutation } = useListMutations();

  const columnsSet = new Set(config.columnOrder);
  const validFrozenColumns = config.frozenColumns?.filter((col) => columnsSet.has(col));

  const isLastColumnFrozen = validFrozenColumns?.at(-1) === colName;
  const columnIndex = config.columnOrder.findIndex((col) => col === colName);

  const freezeColumn = () => {
    let updateFrozenColumns: string[] = [];

    if (!isLastColumnFrozen) {
      updateFrozenColumns = config.columnOrder.filter((_, idx) => idx <= columnIndex);
    }

    const updatedConfig = { ...config, frozenColumns: updateFrozenColumns };

    setConfig(updatedConfig);

    updateListMutation.mutate({
      id: listId,
      list: { config: updatedConfig },
      skipToast: true,
    });
  };

  const menuItem = {
    key: 'freeze',
    label: isLastColumnFrozen ? (
      <FormattedMessage id="lists.un_freeze_column" />
    ) : (
      <FormattedMessage id="lists.freeze_column" />
    ),
    icon: <IconShell icon={isLastColumnFrozen ? PinnedOffIcon : PinIcon} />,
    onClick: freezeColumn,
  };

  return { menuItem };
};
