import { type ID } from 'data';
import { type ModelDimension, type ModelDimensionChildren } from 'data/modelling/dimension';

export enum PermissionList {
  All = 'ALL',
  Boards = 'BOARDS',
  Model = 'MODEL',
  Dimensions = 'DIMENSIONS',
  Modules = 'MODULES',
  DTML = 'DTMLS',
  Settings = 'SETTINGS',
  Lists = 'LISTS',
  DimensionGroups = 'DIMENSION_GROUPS',
  Metrics = 'METRICS',
  Datasets = 'DATASETS',
}

export enum PermissionAccessType {
  None = 'NONE',
  Allow = 'ALLOW',
  FullAccess = 'FULL_ACCESS',
}

export enum AccessAction {
  RemoveAccess = 'REMOVE_ACCESS',
  ReadAccess = 'READ_ACCESS',
  ContributorAccess = 'CONTRIBUTOR_ACCESS',
  FullAccess = 'FULL_ACCESS',
}

interface BasePermission {
  name: string;
  accessType: PermissionAccessType;
}

export interface BaseObject {
  id: number;
  accessType: PermissionAccessType;
  name: string;
}

export interface HierarchialPermissionResource {
  accessAction: AccessAction;
  displayName: string;
  id: ID;
  type: 'FOLDER' | 'BOARD' | 'MODULE' | 'LIST' | 'DIMENSION_GROUP' | 'DIMENSION' | 'DATASET';
  children?: HierarchialPermissionResource[];
}

export interface Permission extends BasePermission {
  accessAction?: AccessAction;
  type: PermissionList;
  items: HierarchialPermissionResource[];
}
export type ModelDimensionPermissionNodeObject = ModelDimensionChildren & BaseObject;

interface ModelDimensionPermissionObject extends Omit<ModelDimension, 'children'> {
  children: ModelDimensionPermissionNodeObject[];
}
export interface DimensionPermissionView extends BaseObject, ModelDimensionPermissionObject {}
export interface DimensionPermission extends BasePermission {
  type: PermissionList.Dimensions;
  items: DimensionPermissionView[];
}

export type RootPermission = Permission | DimensionPermission;
