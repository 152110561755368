import axios from 'axios';

export const OutTablesApi = {
  getAllTables: async (): Promise<string[]> => {
    const result = await axios.get('v1/system/data/out-tables').then(({ data }) => data);

    return result;
  },
  skipDBTExpectations: async (tables: string[]): Promise<void> => {
    const result = await axios
      .post('v1/system/data/dbt-expectations-skip', { tables })
      .then(({ data }) => data);

    return result;
  },
};
