import { Tooltip as AntdTooltip } from 'antd';
import { isEmpty } from 'lodash';
import { type ReactNode, type FC } from 'react';
import { IconShell } from '../icon-shell';
import styles, { type CustomTooltipProps } from './styles';

const { TooltipWrapper, Space, ShortcutKey, HeaderWrapper, Label, IconContainer } = styles;

export const Tooltip: FC<React.PropsWithChildren<CustomTooltipProps>> = ({
  title,
  children,
  spacing,
  header,
  icon,
  shortcuts,
  ...rest
}) => {
  const spacingIsComfortable = spacing === 'comfortable';

  const titleHtml = (
    <>
      {spacingIsComfortable && header && <HeaderWrapper>{header}</HeaderWrapper>}
      <TooltipWrapper>
        {spacingIsComfortable && icon && (
          <IconContainer>
            <IconShell color="iconDefault" icon={icon} size="xs" />
          </IconContainer>
        )}
        <Label $spacingIsComfortable={spacingIsComfortable}>{title as ReactNode}</Label>
        {shortcuts && (
          <Space $spacingIsComfortable={spacingIsComfortable}>
            {shortcuts.map((s) => (
              <ShortcutKey key={s}>
                <span>{s}</span>
              </ShortcutKey>
            ))}
          </Space>
        )}
      </TooltipWrapper>
    </>
  );

  return title && !isEmpty(title) ? (
    <AntdTooltip
      title={titleHtml}
      {...rest}
      arrow={false}
      rootClassName={spacingIsComfortable ? 'comfortable' : ''}
    >
      {children}
    </AntdTooltip>
  ) : (
    <>{children}</>
  );
};
