import { DatasetDimPrefix } from 'data/dimension';

export const getColumnNameFromRawDimensionName = (name: string): string => {
  return name.split('.').at(-1) || '';
};

export const getDetailedTableName = (fullDimName: string): string => {
  const nameBreakdown = String(fullDimName).split('.');

  nameBreakdown.pop();

  return nameBreakdown.join('.');
};

export const getTableNameFromFqColName = (fqColName: string): string => {
  let tableName = fqColName?.split('.')?.at(-2) || '';

  if (fqColName?.startsWith(DatasetDimPrefix)) {
    tableName = tableName?.split('-')?.at(-1) || '';
  }

  return tableName;
};

export const checkIfColumnAlreadyExists = ({
  selectedColumn,
  existingColumns,
}: {
  selectedColumn: string;
  existingColumns: string[];
}): boolean => {
  const existingShortColumnNames = existingColumns.map((name) =>
    getColumnNameFromRawDimensionName(name),
  );

  return existingShortColumnNames.includes(getColumnNameFromRawDimensionName(selectedColumn));
};
