import { type FC, type SVGProps } from 'react';
import { defaultTheme } from 'styles/theme';

const sizeMaps = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
} as const;

export type SizeValues = keyof typeof sizeMaps;

const generateSvgDimensions = (size?: SizeValues) => {
  if (!size) {
    return {};
  }

  const mappedSize = sizeMaps[size];

  return {
    width: mappedSize,
    height: mappedSize,
  };
};

export type IconType = FC<React.PropsWithChildren<SVGProps<SVGSVGElement> & { title?: string }>>;

export type SvgIconColor = keyof typeof defaultTheme.colors;
interface Props extends SVGProps<SVGSVGElement> {
  className?: string;
  color?: SvgIconColor;
  fill?: SvgIconColor;
  icon: IconType;
  size?: SizeValues;
}

export const IconShell: FC<React.PropsWithChildren<Props>> = ({
  color,
  fill,
  icon,
  size,
  ...rest
}) => {
  const IconComp = icon;

  return (
    <IconComp
      {...generateSvgDimensions(size)}
      {...(color && { color: defaultTheme.colors[color] })}
      {...(fill && { fill: defaultTheme.colors[fill] })}
      {...rest}
    />
  );
};
