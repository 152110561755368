/* eslint-disable @typescript-eslint/no-explicit-any */
import { type TraceNode } from 'data/formula-trace/types';
import { create } from 'zustand';

export interface FormulaTraceStore {
  traceNode?: TraceNode;
  setTraceNode: (node: TraceNode) => void;
  clear: () => void;
}

export const useFormulaTraceStore = create<FormulaTraceStore>()((set, get) => ({
  traceNode: undefined,

  setTraceNode: (node) => set((prev) => ({ traceNode: node })),
  clear: () => set(() => ({ traceNode: undefined })),
}));
