import { type AxiosError } from 'axios';
import { Modal, notification } from 'components/ui/atomic-components';
import { ListsApi, type ListDataTypes } from 'data/modelling/lists';
import { isEmpty, omit } from 'lodash';
import { useEffect, useMemo, useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { PreviewDataGrid } from '../../secondary-top-bar/upload-csv/upload-flow/preview-data-grid';
import { PreSaveModal } from './pre-save-modal';
import { updateListWithPastedValues } from './utils';

export const BulkPasteFlow = (): ReactElement => {
  const [showPreSaveWarning, setShowPreSaveWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const config = useListStoreContext((s) => s.config);
  const id = useListStoreContext((s) => s.id);
  const bulkPasteRows = useListStoreContext((s) => s.bulkPasteRows);
  const setBulkPasteRows = useListStoreContext((s) => s.setBulkPasteRows);
  const listStore = useListStoreInstance();

  const intl = useIntl();

  const reset = () => {
    setBulkPasteRows?.(undefined);
    setShowPreSaveWarning(false);
  };

  const onSaveRows = async (skipErrorCells?: boolean) => {
    listStore.setState({ cellUpdateInProgress: true });

    try {
      let rowsToSubmit = bulkPasteRows || [];

      setLoading(true);

      if (skipErrorCells) {
        rowsToSubmit = (bulkPasteRows || [])
          .map((row) => {
            const validCellValues = omit(row.values, Object.keys(row.errors || {}));

            return {
              ...row,
              values: validCellValues,
            };
          })
          .filter((row) => !isEmpty(row.values));
      }

      const pastedRows = await ListsApi.bulkPaste(id, rowsToSubmit);

      if (pastedRows?.some((row) => !isEmpty(row.errors))) {
        setBulkPasteRows?.(pastedRows);
        setShowPreSaveWarning(true);
      } else {
        updateListWithPastedValues(pastedRows, listStore);

        reset();

        notification.success({
          key: 'pasting-cells',
          message: intl.formatMessage({ id: 'lists.bulk_paste_rows.notification.success' }),
        });
      }
      setLoading(false);
      listStore.setState({ cellUpdateInProgress: false });
    } catch (error) {
      setLoading(false);
      listStore.setState({ cellUpdateInProgress: false });
      defaultApiErrorHandler(error as AxiosError);
      reset();
    }
  };

  const onCellEdit = (rowId: number, propName: string, newVal: string) => {
    setBulkPasteRows?.(
      (bulkPasteRows || []).map((row, index) => {
        if (row?.rowId === rowId) {
          return {
            ...row,
            values: {
              ...row.values,
              [propName]: newVal,
            },
            errors: omit(row.errors || {}, [propName]),
          };
        }

        return row;
      }),
    );
  };

  const { errorCellsCount, savableCellsCount } = useMemo(() => {
    let total = 0;
    let errorCells = 0;

    (bulkPasteRows || []).forEach((row) => {
      errorCells += Object.keys(row?.errors || {}).length || 0;
      total += Object.keys(row?.values || {}).length || 0;
    });

    return {
      errorCellsCount: errorCells,
      savableCellsCount: total - errorCells,
    };
  }, [bulkPasteRows]);

  const colNames = useMemo(() => {
    const formulaColumnLookup = new Set(config.columnFormulae?.map((col) => col.colName));

    return config.columnOrder.filter((col) => !formulaColumnLookup.has(col));
  }, [config.columnFormulae, config.columnOrder]);

  useEffect(() => {
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        confirmLoading={loading}
        destroyOnClose
        maskClosable={false}
        okText={<FormattedMessage id="lists.bulk_paste_rows.preview_error_modal.ok_text" />}
        open={!isEmpty(bulkPasteRows) && !showPreSaveWarning}
        title={<FormattedMessage id="lists.bulk_paste_rows.preview_error_modal.title" />}
        width={Math.min(document.body.clientWidth - 300, 1360)}
        onCancel={reset}
        onOk={() => onSaveRows()}
      >
        <PreviewDataGrid
          colTypeMap={config?.columnTypeMap as { [key: string]: ListDataTypes }}
          columnNames={colNames}
          data={bulkPasteRows || []}
          dateFormatMap={config?.dateFormatMap}
          isColumnTypeEditMode={false}
          updatePropertyTypeMap={() => undefined}
          onCellEdit={onCellEdit}
        />
      </Modal>
      <PreSaveModal
        errorCells={errorCellsCount}
        savableCells={savableCellsCount}
        visible={!isEmpty(bulkPasteRows) && showPreSaveWarning}
        onBack={() => setShowPreSaveWarning(false)}
        onSave={() => onSaveRows(true)}
      />
    </>
  );
};
