import { type ID } from 'data';
import { type ReactText } from 'react';
import { type FolderType } from './types';

const BASE_QUERY_KEY = 'folders';

export const StoreKeys = {
  QueryKey: BASE_QUERY_KEY,
  SingleRecordByType: (resource: FolderType): string[] => [StoreKeys.QueryKey, resource],
  SingleRecordById: (folderId: ID): ReactText[] => [StoreKeys.QueryKey, folderId],
};
