import { ACTUAL } from 'data/versions';

export const getBaseColorVersion = (versions: string[], isScenarioPresent: boolean): string => {
  if (isScenarioPresent) {
    return versions.find((version) => version !== ACTUAL) || ACTUAL;
  }

  if (versions.includes(ACTUAL)) {
    return ACTUAL;
  }

  return versions[0];
};
