import { type DimensionQueryParamsObject } from 'data/dimension';
import { isEmpty } from 'lodash';

export const DimensionBlankIntermValue = '<dtBlank>';

export const encodeBlankInQueryParam = (
  query: DimensionQueryParamsObject,
): DimensionQueryParamsObject => {
  const queryClone = { ...query };

  if (!isEmpty(queryClone)) {
    Object.keys(queryClone).forEach((key) => {
      const values = queryClone[key];

      queryClone[key] = values.map((val) => (val === '' ? DimensionBlankIntermValue : val));
    });
  }

  return queryClone;
};

export const decodeBlankInQueryParam = (
  query: DimensionQueryParamsObject,
): DimensionQueryParamsObject => {
  const queryClone = { ...query };

  if (!isEmpty(queryClone)) {
    Object.keys(queryClone).forEach((key) => {
      const values = queryClone[key];

      queryClone[key] = values.map((val) => (val === DimensionBlankIntermValue ? '' : val));
    });
  }

  return queryClone;
};
