import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { type ID } from 'data';
import { RolesApi } from '../api';
import { type PermissionList } from '../permissions';
import { type ResourcePermissions, type UsersAndRoles } from '../types';

export const useAllUsersAndRoles = (): UseQueryResult<UsersAndRoles> => {
  return useQuery(['users-roles'], () => RolesApi.getAllUsersAndRoles(), {
    staleTime: StaleTime.Long,
  });
};

export const useAllUsersAndRolesWithPermissions = (
  resourceType: PermissionList,
  resourceId: ID,
): UseQueryResult<ResourcePermissions, unknown> => {
  return useQuery(
    ['users-roles', resourceType, resourceId],
    () => RolesApi.getAllUsersAndRolesWithPermissions(resourceType, resourceId),
    { staleTime: StaleTime.Last },
  );
};
