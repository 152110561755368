import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type ReactElement, type ReactNode } from 'react';
import styled from 'styled-components';
import { FontS, FontXs, SingleLineEllipsis } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[6]}`};
  margin: 0 ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.xs};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgMedium};
  }
`;

const ItemLabel = styled.div`
  ${SingleLineEllipsis};
  ${FontS};

  padding: 0 ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.textTitle1};
`;

const ItemRightSection = styled.div`
  ${FontXs};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const RightSectionLabel = styled.div`
  ${SingleLineEllipsis};

  color: ${({ theme }) => theme.colors.textLabel};
`;

interface Props {
  label: ReactNode;
  secondaryLabel?: ReactNode;
  onClick?: () => void;
}

export const MenuItem = ({ label, secondaryLabel, onClick }: Props): ReactElement => {
  return (
    <Wrapper onClick={onClick}>
      <ItemLabel>{label}</ItemLabel>
      <ItemRightSection>
        <RightSectionLabel>{secondaryLabel}</RightSectionLabel>
        <IconShell color="iconDefault" icon={ChevronRightIcon} />
      </ItemRightSection>
    </Wrapper>
  );
};
