import { type FormattedPeriod, Granularity } from 'data';
import { type DateFilterPreset, DateFilterPresetName } from 'data/bootstrap/types';
import { type FrameElement } from 'data/reports/types';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';

const currentDate = dayjs();

export const checkIfPeriodIsToExpand = ({
  period,
  defaultExpandedCols,
  columnStructure,
}: {
  period: FormattedPeriod;
  defaultExpandedCols?: string[];
  columnStructure: FrameElement[];
}): boolean => {
  if (defaultExpandedCols?.length) {
    return defaultExpandedCols.includes(period.id);
  }

  if (!isEmpty(columnStructure) || period.order.group.length >= 2) {
    return (
      period.type === Granularity.Monthly &&
      currentDate.isSameOrAfter(dayjs(period.startDate)) &&
      currentDate.isSameOrBefore(dayjs(period.endDate))
    );
  }

  return false;
};

export const findSelectedPresetDateOption = (
  selectedDateRange: [string, string] | [],
  datePresetOptions: DateFilterPreset[],
  dateOption?: DateFilterPresetName,
): DateFilterPreset | undefined => {
  if (isNil(selectedDateRange) || !selectedDateRange.length) {
    if (dateOption === DateFilterPresetName.CustomRange) {
      return;
    }

    return (
      datePresetOptions.find((option) => option.name === dateOption) ||
      datePresetOptions.find((option) => option.isDefault)
    );
  }

  return datePresetOptions.find((option) => {
    return option.value[0] === selectedDateRange[0] && option.value[1] === selectedDateRange[1];
  });
};
