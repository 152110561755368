import { Form as AntdForm, type List } from 'antd';
import { type FormProvider } from 'antd/es/form/context';
import type ErrorList from 'antd/es/form/ErrorList';
import { type useForm, type useWatch } from 'antd/es/form/Form';
import type useFormInstance from 'antd/es/form/hooks/useFormInstance';
import styled from 'styled-components';
import {
  StyledMargins,
  StyledPaddings,
  type StyledMarginsProps,
  type StyledPaddingProps,
} from 'styles/styled-atoms';

const FormItem = styled(AntdForm.Item)<StyledMarginsProps & StyledPaddingProps>`
  &&.ant-form-item {
    ${StyledMargins};
    ${StyledPaddings};
  }
`;

type InternalFormType = typeof AntdForm;
type CompoundedComponent = InternalFormType & {
  useForm: typeof useForm;
  useFormInstance: typeof useFormInstance;
  useWatch: typeof useWatch;
  Item: typeof FormItem;
  List: typeof List;
  ErrorList: typeof ErrorList;
  Provider: typeof FormProvider;
};

export const Form = styled(AntdForm).attrs(({ layout }) => ({
  layout: layout ?? 'vertical',
}))`
  .ant-col.ant-form-item-label {
    padding-bottom: ${({ theme }) => theme.spacing[4]};
  }

  .ant-form-item {
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }

  label {
    width: 100%;

    &.ant-form-item-required {
      &::before {
        width: 0;
        margin: -${({ theme }) => theme.spacing[4]};
      }
    }

    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .ant-form-item-explain-error {
    margin-top: ${({ theme }) => theme.spacing[4]};
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }
` as unknown as CompoundedComponent;

Form.Item = FormItem;
