import Cookies from 'js-cookie';
import {
  VERSION_MODE_COOKIE_KEY,
  VERSION_ACCESS_COOKIE_KEY,
  type VersionAccessModes,
} from './constants';

export const isInVersionMode = (modes: VersionAccessModes[]): boolean => {
  const versionId = Cookies.get(VERSION_MODE_COOKIE_KEY);
  const accessMode = Cookies.get(VERSION_ACCESS_COOKIE_KEY);

  return modes.some((m) => {
    return !!versionId && !!accessMode && accessMode === m;
  });
};
