import { type Dimension, TimeDimensionName, DimSpace } from 'data/dimension';
import {
  FORMULA_LOOKUP_DIMENSION_PREFIX,
  type TypeFormula,
  type FormulaLookupMap,
} from 'data/modelling/metric';
import { type ModuleQueryParams, type Module } from 'data/modelling/modules';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { BASE_CASE_NAME, SCENARIO } from 'data/scenarios';
import { isEmpty, set } from 'lodash';
import { type DecodedValueMap } from 'use-query-params';
import { constructRootHierarchyClanFromFilter } from '../../table/hooks/use-construct-grid-column-defs/utils/construct-root-hierarchy-clan-from-filter';

interface RowNodeItem {
  name: string;
  children?: { value: string }[];
}

// derive row node dimensions in the pivot order including the metric
const metricRowNodeHierarchy = ({
  dimensions,
  pivotRows,
  pageFilters,
  tempSccl,
}: {
  dimensions: Dimension[];
  pivotRows: string[];
  pageFilters: {
    [x: string]: string[];
  };
  tempSccl?: boolean;
}): RowNodeItem[] => {
  const rootHierarchy = constructRootHierarchyClanFromFilter({
    filter: pageFilters,
  });

  const dimLookup: { [key: string]: RowNodeItem } = {};

  dimensions.forEach((dim) => {
    if (!tempSccl || dim.dimSpace !== DimSpace.Plan) {
      dimLookup[dim.name] = dim;
    }
  });

  const nodeItems: RowNodeItem[] = [];

  rootHierarchy?.forEach((clanNode) => {
    if (clanNode.name === SCENARIO && clanNode.value === BASE_CASE_NAME) {
      // skip scenario base case
      return;
    }
    nodeItems.push({
      name: clanNode.name,
      children: [{ value: clanNode.value || '' }],
    });
  });

  pivotRows?.forEach((dimName) => {
    if (dimName === PIVOT_DIMENSION_METRIC_ROW_NAME) {
      nodeItems.push({
        name: PIVOT_DIMENSION_METRIC_ROW_NAME,
      });
    } else if (dimLookup[dimName]) {
      if (dimName in pageFilters && pageFilters?.[dimName]?.length === 1) {
        return;
      }
      nodeItems.push(dimLookup[dimName]);
    }
  });

  return nodeItems;
};

export const generateFormulaLookupPath = (dimName: string, dimValue: string): string => {
  const encodedDimName = encodeURIComponent(dimName || '');
  const encodedDimValue = encodeURIComponent(dimValue || '');

  return `['${FORMULA_LOOKUP_DIMENSION_PREFIX}']['${encodedDimName}']['${encodedDimValue}']`;
};

const canSkipIfIrrelevantScenarioFormula = ({
  selectedScenario,
  formula,
}: {
  selectedScenario: string;
  formula: TypeFormula;
}) => {
  // if base case selected, skip other scenario formulas
  if (!selectedScenario && formula.scenario) {
    return true;
  }

  // skip other scenario formulas other than base case or not selected ones
  if (selectedScenario && formula.scenario && formula.scenario !== selectedScenario) {
    return true;
  }

  return false;
};

export const generateFormulaeLookup = (
  module: Module,
  metricPivotMap: Map<
    string,
    {
      rows: string[];
      columns: string[];
    }
  >,
  queryRef: React.MutableRefObject<DecodedValueMap<ModuleQueryParams>>,
  tempSccl?: boolean,
): {
  formulaLookup: FormulaLookupMap;
} => {
  const formulaLookup = {} as FormulaLookupMap;

  module?.metrics?.forEach((metric) => {
    const pageFilters = { ...(queryRef.current.f || {}) };

    const nodeHierarchy = metricRowNodeHierarchy({
      dimensions: metric.dimensions,
      pivotRows: metricPivotMap.get(metric.name)?.rows || [],
      pageFilters,
      tempSccl,
    });

    const { formulae } = metric;

    (formulae || []).forEach((formula) => {
      if (
        canSkipIfIrrelevantScenarioFormula({ selectedScenario: queryRef.current.Scenario, formula })
      ) {
        return;
      }

      let inheritedByDefault = false;
      const singleColumn = formula?.periods?.length === 1;

      // dimension lookup for this formula
      const dimLookup: { [key: string]: string } = {};

      formula.dimensions.forEach((d) => {
        if (!tempSccl || d.dimSpace !== DimSpace.Plan) {
          dimLookup[d.name] = d.value;
        }
      });

      dimLookup[PIVOT_DIMENSION_METRIC_ROW_NAME] = metric?.name;

      let keySegmentLists: string[][] = [[]];

      nodeHierarchy.forEach((node) => {
        if (isEmpty(Object.keys(dimLookup))) {
          return;
        }

        if (node.name in dimLookup) {
          keySegmentLists.forEach((segList) => {
            const segment = generateFormulaLookupPath(node.name, dimLookup[node.name]);

            segList.push(segment);
          });
          delete dimLookup[node.name];
        } else {
          // singleColumn formula should be for leaf cells and should not be considered as aggregate formulas
          if (!singleColumn) {
            if (node.name !== SCENARIO) {
              inheritedByDefault = true;
            }
            const updatedKeySegmentLists: string[][] = [];

            (node?.children || []).forEach((child) => {
              keySegmentLists.forEach((segList) => {
                const segment = generateFormulaLookupPath(node.name, child.value);

                updatedKeySegmentLists.push([...segList, segment]);
              });
            });
            keySegmentLists = updatedKeySegmentLists;
          }
        }
      });

      if (!isEmpty(Object.keys(dimLookup))) {
        return;
      }

      if (singleColumn) {
        keySegmentLists.forEach((segList) => {
          const segment = generateFormulaLookupPath(TimeDimensionName, formula?.periods?.[0]);

          segList.push(segment);
        });
      }

      keySegmentLists.forEach((segList) => {
        const key = segList.join('');

        set(formulaLookup, key, { formula, inheritedByDefault, singleColumn });
      });
    });
  });

  return {
    formulaLookup,
  };
};
