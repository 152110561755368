import { ReactComponent as SpyIcon } from 'assets/v2/spy.svg';
import { Banner, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  userFullName?: string;
  exitViewAsUserMode: () => void;
}

export const ViewAsUserBanner = ({ userFullName, exitViewAsUserMode }: Props): ReactElement => {
  return (
    <Banner
      icon={<IconShell icon={SpyIcon} />}
      title={
        <>
          <FormattedMessage id="settings.users.view_as_user.logged_in_as" /> {userFullName}
        </>
      }
      onClose={exitViewAsUserMode}
    />
  );
};
