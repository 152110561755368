import styled from 'styled-components';
import { FontM, FontXxs } from 'styles/typography';

export default {
  Wrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[16]};
    position: relative;
  `,

  DividerLine: styled.div`
    height: calc(100% - 28px);
    width: 1.5px;
    background-color: ${({ theme }) => theme.colors.gray200};
    position: absolute;
    left: 14px;
  `,

  UsageWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[12]};
  `,

  ResourceDependencyTitle: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
  `,

  ResourceName: styled.div`
    ${FontM};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.gray600};
  `,

  ResourceDependencyCount: styled.div`
    ${FontXxs};

    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textBody2};
    background-color: ${({ theme }) => theme.colors.bgDark};
    border-radius: ${({ theme }) => theme.borderRadius.full};
  `,

  ResourceDependencyList: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing[8]};
    padding-bottom: ${({ theme }) => theme.spacing[32]};
  `,
};
