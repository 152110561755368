import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { type VisualQueryFilterRule } from 'data/big-query';
import { type List, ListsApi } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { ListStoreKeys } from 'data/modelling/lists/store-keys';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useListStoreContext } from 'store/lists';

export const useListQueryById = (
  listId: number | null,
  options?: {
    filters?: VisualQueryFilterRule[];
  },
): UseQueryResult<List, unknown> => {
  return useQuery(
    ListStoreKeys.SingleRecord(listId as number, options),
    () => {
      if (!listId) {
        throw 'activeListId not set';
      }

      return ListsApi.findList(listId, { filters: options?.filters });
    },
    {
      enabled: !!listId,
    },
  );
};

export const useActiveList = (): UseQueryResult<List, unknown> => {
  const activeListId = useListStoreContext((s) => s.activeListId);
  const setActiveListId = useListStoreContext((s) => s.setActiveListId);
  const setColumnFilters = useListStoreContext((s) => s.setColumnFilters);

  const { listId } = useParams() as { listId: string };

  useEffect(() => {
    if (!listId.startsWith(DIM_GROUP_ID_PREFIX)) {
      setActiveListId(Number(listId));
    }
    setColumnFilters(undefined);
  }, [listId, setActiveListId, setColumnFilters]);

  const activeListQuery = useListQueryById(activeListId);

  return activeListQuery;
};
