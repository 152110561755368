import { ReactComponent as DataIcon } from 'assets/v2/database-icon.svg';
import { ReactComponent as ModelIcon } from 'assets/v2/geometry.svg';
import { ReactComponent as NavBarExpanded } from 'assets/v2/layout-sidebar-left-collapse.svg';
import { ReactComponent as NavBarCollapsed } from 'assets/v2/layout-sidebar-left-expand.svg';
import { ReactComponent as MetricIcon } from 'assets/v2/metrics-icon.svg';
import { ReactComponent as ReportsIcon } from 'assets/v2/report-icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/v2/settings.svg';
import { Button } from 'components/ui/atomic-components/button';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { useEditModeQueryParam } from 'data/boards';
import { PermissionList } from 'data/roles/permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';
import { useHasScope } from 'utils/hooks';
import { useLeftNavStore } from '../left-nav-store';
import { GlobalSearchModal } from '../search-box/global-search-modal';
import { HealthStatusBadge } from './health-status-badge';
import { HelpButton } from './help-button';
import { LeftNavItem } from './left-nav-item';
import { LogoutButton } from './logout-button';
import styles from './styles';

const { PrimaryMenu, NavLogoWrapper, Logo, Divider, Slider } = styles;

const SecondaryMenu = styled(PrimaryMenu)`
  height: auto;
  padding-bottom: ${({ theme: { spacing } }) => spacing[8]};
`;

export const LeftNavV2: FC<React.PropsWithChildren<unknown>> = () => {
  const LEFT_NAV_WIDTH = 72;

  const intl = useIntl();
  const navigate = useNavigate();
  const hasScope = useHasScope();

  const { tempGlobalSearch } = useFlags();

  const isPanelOpen = useLeftNavStore((state) => state.showSecondaryLeftNav);
  const setIsPanelOpen = useLeftNavStore((state) => state.setShowSecondaryLeftNav);
  const boardTitle = intl.formatMessage({ id: 'board.title' });
  const modelTitle = intl.formatMessage({ id: 'model.title' });
  const metricTitle = intl.formatMessage({ id: 'metric-builder.title' });
  const dataTitle = intl.formatMessage({ id: 'data' });
  const settingsTitle = intl.formatMessage({ id: 'settings' });
  const { pathname: currentPath } = useLocation();
  const [isInEditMode] = useEditModeQueryParam();
  const showExpandToggle = !isInEditMode && currentPath.includes('reports');

  return (
    <Slider collapsedWidth={LEFT_NAV_WIDTH} defaultCollapsed width={LEFT_NAV_WIDTH}>
      <PrimaryMenu>
        <li>
          <NavLogoWrapper>
            {showExpandToggle && (
              <Button
                className="nav-expand-button"
                icon={<IconShell icon={isPanelOpen ? NavBarExpanded : NavBarCollapsed} size="md" />}
                onClick={() => setIsPanelOpen(!isPanelOpen)}
              />
            )}

            <Logo
              alt={intl.formatMessage({ id: 'drivetrain' })}
              className={showExpandToggle ? 'drivetrain-logo' : ''}
              src={getAssetUrl('drivetrain-logo.svg')}
              title="Drivetrain"
              onClick={() => navigate('/')}
            />
          </NavLogoWrapper>
        </li>

        <li>
          <Divider />
        </li>

        {tempGlobalSearch && <GlobalSearchModal />}

        {hasScope(PermissionList.Boards) && (
          <LeftNavItem icon={ReportsIcon} label={boardTitle} navlink="/reports" />
        )}

        {hasScope(PermissionList.Model) && (
          <LeftNavItem icon={ModelIcon} label={modelTitle} navlink="/models" />
        )}

        {hasScope(PermissionList.Metrics) && (
          <LeftNavItem icon={MetricIcon} label={metricTitle} navlink="/metrics" />
        )}

        {hasScope(PermissionList.Datasets) && (
          <LeftNavItem icon={DataIcon} label={dataTitle} navlink="/data" />
        )}
      </PrimaryMenu>

      <SecondaryMenu>
        <Divider />

        <HelpButton />

        {hasScope(PermissionList.All) && (
          <HealthStatusBadge>
            <LeftNavItem icon={SettingsIcon} label={settingsTitle} navlink="/settings" />
          </HealthStatusBadge>
        )}

        <LogoutButton />
      </SecondaryMenu>
    </Slider>
  );
};
