import { type EditorView } from '@codemirror/view';

export const metricExpressionUpdator = ({
  view,
  metricTerm,
  from,
}: {
  view: EditorView;
  metricTerm: RegExpExecArray;
  from: number;
}): ((value: string) => void) => {
  const subscriptStartIdx = from + metricTerm[1].length;
  const subscriptEndIdx = from + metricTerm[0].length;

  return (value: string): void => {
    const change = { from: subscriptStartIdx, to: subscriptEndIdx, insert: value };

    view.dispatch({ changes: change });
    view.focus();
  };
};
