/* eslint-disable filenames/match-regex */
import { css } from 'styled-components';

export const PopoverStyles = css`
  .ant-popover {
    animation-timing-function: ease;
    font-size: ${({ theme }) => theme.fontSize.s};

    .ant-popover-title {
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.spacing[8]};
      padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[16]}`};
      border-top-left-radius: ${({ theme }) => theme.borderRadius.xl};
      border-top-right-radius: ${({ theme }) => theme.borderRadius.xl};
      border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      margin-bottom: 0;
    }

    .ant-popover-content {
      .ant-popover-inner {
        padding: 0;
        border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
        box-shadow: ${({ theme }) => theme.shadow.softMedium};
        border-radius: ${({ theme }) => theme.borderRadius.xl};
      }
    }

    &.ant-zoom-big-enter.ant-zoom-big-enter-active,
    &.ant-zoom-big-appear.ant-zoom-big-appear-active {
      animation-name: popover-open;
    }

    &.ant-zoom-big-leave.ant-zoom-big-leave-active {
      animation-name: popover-close;
    }

    @keyframes popover-open {
      0% {
        transform: scale(0.97);
        opacity: 0;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @keyframes popover-close {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(0.97);
        opacity: 0;
      }
    }
  }

  .ant-avatar-group-popover {
    .ant-popover-content {
      .ant-popover-inner {
        padding: ${({ theme }) => theme.spacing[4]};
        border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
        box-shadow: ${({ theme }) => theme.shadow.softMedium};

        .ant-popover-inner-content {
          display: flex;
          max-width: 300px;
          overflow: auto;
        }
      }
    }
  }

  .full-ant-popover {
    &.ant-popover {
      height: 100%;
      width: 100%;
      padding: 10px 10px 10px 75px;
      backdrop-filter: blur(1px);
      background: #11182714;

      .ant-popover-title {
        height: 60px;
        padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
      }

      .ant-popover-content {
        height: 100%;
      }

      .ant-popover-inner {
        height: 100%;
        overflow: hidden;
      }

      .ant-popover-inner-content {
        height: 100%;
      }

      .ant-collapse {
        height: 100%;
      }
    }
  }
`;
