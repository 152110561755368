import { useQueryClient } from '@tanstack/react-query';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type List } from 'data/modelling/lists';

export const useListOptimisticMutations = (): {
  updateDisplayName: (listId: number, newName: string, isDerivedList: boolean) => void;
} => {
  const queryClient = useQueryClient();

  const updateDisplayName = (listId: number, newName: string, isDerivedList: boolean) => {
    queryClient.setQueryData(['lists'], (existingLists?: (List | DimensionGroup)[]) =>
      (existingLists || []).map((list) => {
        const isDerived = !!(list as DimensionGroup)?.uniqueKeyColumns;

        if (list.id === listId && isDerivedList === isDerived) {
          return {
            ...list,
            displayName: newName,
          };
        }

        return list;
      }),
    );
  };

  return {
    updateDisplayName,
  };
};
