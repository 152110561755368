import { ReactComponent as AlertTriangleIcon } from 'assets/v2/alert-triangle.svg';
import { ConfirmationModal, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirmEdit: () => void;
}

export const EditConfirmModal = ({
  isLoading,
  open,
  onClose,
  onConfirmEdit,
}: Props): ReactElement => {
  return (
    <ConfirmationModal
      confirmLoading={isLoading}
      okText={<FormattedMessage id="lists.update_from_data.edit_modal.ok_text" />}
      open={open}
      title={<FormattedMessage id="lists.reconciliation.modal.title" />}
      titleIcon={<IconShell color="red600" icon={AlertTriangleIcon} />}
      onCancel={onClose}
      onOk={onConfirmEdit}
    >
      <FormattedMessage id="lists.update_from_data.edit_modal.message" />
    </ConfirmationModal>
  );
};
