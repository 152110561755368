import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Analytics } from 'config/analytics';
import { type IntegrationConnectionItem, IntegrationsApi } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { getCallbackUrl } from '../../../../../common/util';

export const useCommonLoginFlow = (item: IntegrationConnectionItem): { isLoading: boolean } => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams({
    code: StringParam,
    realmId: StringParam,
    region: StringParam,
    codeVerifier: StringParam,
  });
  const queryClient = useQueryClient();

  const {
    mutate: connectService,
    isLoading,
    isError,
  } = useMutation(
    ({
      code,
      realmId,
      region,
      codeVerifier,
    }: {
      code: string;
      realmId?: string | null;
      region?: string | null;
      codeVerifier?: string | null;
    }) => {
      return IntegrationsApi.connectIntegration({
        redirectUrl: getCallbackUrl(),
        service: item.id,
        code,
        realmId,
        region,
        codeVerifier,
      });
    },
    {
      onSuccess: (data) => {
        return queryClient.invalidateQueries(IntegrationKeys.ConnectedIntegrations).then(() => {
          navigate(`/data/connected/${data?.data?.id}?pending=add-data`);
        });
      },
      onError: defaultApiErrorHandler,
    },
  );

  const debouncedConnect = useRef(
    debounce(({ code, realmId, region, codeVerifier }) => {
      connectService({ code, realmId, region, codeVerifier });

      Analytics.track('New source', {
        category: 'Data',
        meta: `Source name - ${item.displayName}`,
      });
    }, 200),
  );

  useEffect(() => {
    const { code, realmId, region, codeVerifier } = queryParams;

    if (item && code && !isError) {
      // react 18 useEffect runs twice, debounced only called once
      debouncedConnect.current({ code, realmId, region, codeVerifier });
    }
  }, [connectService, item, queryParams, isError]);

  return {
    isLoading,
  };
};
