import { Alert as AntdAlert, type AlertProps } from 'antd';
import { ReactComponent as ErrorIcon } from 'assets/ic_alert-error.svg';
import { ReactComponent as InfoIcon } from 'assets/ic_alert-info.svg';
import { ReactComponent as WarningIcon } from 'assets/ic_alert-warning.svg';
import { type FC } from 'react';
import styled from 'styled-components';
import { IconShell } from '../icon-shell';
import { BgColor } from './constants';

const StyledAlert = styled(AntdAlert).attrs<AlertProps>(({ type }) => type)`
  background: ${({ type }) => (type ? BgColor[type] : 'inherit')};
  border-radius: ${({ theme }) => theme.border.radius.md};
  margin: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[16]}`};

  .ant-alert-message {
    color: ${({ theme }) => theme.colors.black900};
    font-size: ${({ theme }) => theme.fontSize.paragraph};
  }

  .ant-alert-description {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSize.caption};
  }
`;

export const AlertOld: FC<React.PropsWithChildren<AlertProps>> = ({ type, ...rest }) => {
  const renderAlert = (type?: 'success' | 'warning' | 'info' | 'error') => {
    if (type) {
      switch (type) {
        case 'success':
          return <StyledAlert type="success" {...rest} />;
        case 'info':
          return (
            <StyledAlert icon={<IconShell icon={InfoIcon} />} showIcon type="info" {...rest} />
          );
        case 'warning':
          return (
            <StyledAlert
              icon={<IconShell icon={WarningIcon} />}
              showIcon
              type="warning"
              {...rest}
            />
          );
        case 'error':
          return (
            <StyledAlert icon={<IconShell icon={ErrorIcon} />} showIcon type="error" {...rest} />
          );
      }
    }

    return <StyledAlert {...rest} />;
  };

  return <>{renderAlert(type)}</>;
};
