/* eslint-disable @calm/react-intl/missing-formatted-message */
import { type ReactElement } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

const Message = styled.h3`
  font-style: italic;
`;

const StackTraceBlock = styled.pre`
  padding: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const StackTrackBlock = (): ReactElement => {
  const error = useRouteError() as Error;
  const stack = error instanceof Error ? error.stack : null;

  let message = '';

  if (isRouteErrorResponse(error)) {
    message = `${error.status} ${error.statusText}`;
  } else {
    if (error instanceof Error) {
      message = error.message as string;
    } else {
      message = JSON.stringify(error);
    }
  }

  return (
    <>
      <h2>Unexpected Application Error!</h2>
      <Message>{message}</Message>
      {stack && <StackTraceBlock>{stack}</StackTraceBlock>}
    </>
  );
};
