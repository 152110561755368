import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';

export default {
  Title: styled.div`
    ${FontXs};

    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing[8]};
    padding-bottom: 0;
    color: ${({ theme }) => theme.colors.gray600};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    gap: ${({ theme }) => theme.spacing[4]};
  `,

  SearchWrapper: styled.div`
    padding: ${({ theme }) => theme.spacing[8]};
  `,

  SelectAllWrapper: styled.div`
    display: flex;
    width: 100%;
    padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[4]} ${spacing[8]}`};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
    margin-bottom: ${({ theme: { spacing } }) => spacing[4]};
  `,

  ListItem: styled.div<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    padding: ${({ theme: { spacing } }) => `${spacing[6]}`};
    cursor: pointer;
    background-color: ${({ theme: { colors }, isActive }) => {
      return isActive ? colors.primary50 : colors.gray00;
    }};

    :hover {
      background-color: ${({ theme }) => theme.colors.bgMedium};
    }
  `,

  Label: styled.div`
    ${FontS};

    color: ${({ theme }) => theme.colors.textTitle1};
    margin-left: ${({ theme }) => theme.spacing[6]};
  `,
};
