import { InputNumber as AntdInput } from 'antd';
import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down_12.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/v2/chevron-up_12.svg';
import styled from 'styled-components';
import { IconShell } from '../icon-shell';

export const InputNumber = styled(AntdInput).attrs({
  controls: {
    upIcon: <IconShell icon={ChevronUpIcon} />,
    downIcon: <IconShell icon={ChevronDownIcon} />,
  },
})`
  .ant-input-number-handler-wrap {
    opacity: 1;
  }

  .ant-input-number-handler:hover {
    height: 40%;
  }
`;
