import { Tooltip } from 'components/ui/atomic-components';
import { useBootstrap } from 'data/bootstrap';
import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';
import { useUser } from 'utils/hooks';

const Wrapper = styled.div`
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.red500};
  border-radius: ${({ theme }) => theme.borderRadius.full};
`;

interface Props {
  children?: ReactNode | undefined;
}

export const HealthStatusBadge = ({ children }: Props): ReactElement => {
  const { isDtUser } = useUser();
  const { data: bootstrapData = {} } = useBootstrap();

  if (isDtUser && 'healthStatus' in bootstrapData && bootstrapData?.healthStatus !== 'ok') {
    return (
      <Wrapper>
        {children}
        <Tooltip title={bootstrapData?.healthStatus as string}>
          <Badge />
        </Tooltip>
      </Wrapper>
    );
  }

  return <>{children}</>;
};
