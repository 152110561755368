/**
 * This is a Generics based Conversation - Comment - Meta interface.
 * While a conversation could be attached to a plan view, the T = GridData
 * then accessing the meta object would transform to the meta type we pass
 * to the component.
 */

import { type RowNode } from 'ag-grid-community';
import type { ID } from 'data';
import { type PermissionList } from 'data/roles/permissions';
import { type User } from 'data/users';

export type ResourceReference = {
  columnType?: string;
  dimensions?: Record<string, string>;
  scenario?: string;
  version?: string;
  baselineVersion?: string;
  chartId?: number;
};

export type MetaData = {
  reference: ResourceReference;
  dataValue?: string | number;
};

export interface Comment {
  id: number;
  createdBy: User;
  createdAt: string;
  message: string;
  meta?: MetaData;
  updatedAt?: string;
  containerId?: ID;
}

export interface ConversationInfoType {
  metricNames?: string[];
  columnType?: string;
  period?: string;
  dimensions?: Record<string, string>;
  chartName?: string;
  version?: string;
  baselineVersion?: string;
  scenario?: string;
}

export interface Conversation {
  id: number;
  comments: Comment[];
  meta: MetaData;
  container: ContainerType;
  containerId: ID;
  planId: number;
  metricName: string;
  metricDisplayName: string;
  members: string[];
  access: ConversationAccessType;
  resolved?: boolean;
  displayInfo: ConversationInfoType;
}

export const ConversationId = (conversationId: string): ConversationId => Number(conversationId);

export type ConversationId = number;
export type ObjectWithConversation = {
  conversationIds?: ConversationId[];
};

export enum ConversationAccessType {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export interface ConversationsRowNode extends RowNode {
  conversations?: { newConversationData?: Partial<Conversation>; cellIdentifier: string };
}

export enum ConversationEventType {
  Message = 'MESSAGE',
  AddMemberSelf = 'ADD_MEMBER_SELF',
  AddMemberOthers = 'ADD_MEMBER_OTHERS',
  Resolve = 'RESOLVE',
  Mention = 'MENTION',
  UpdateMessage = 'UPDATE_MESSAGE',
  DeleteMessage = 'DELETE_MESSAGE',
}

export interface ConversationWSData {
  conversationId: number;
  createdBy: string;
  eventName: ConversationEventType;
  notificationHeader: string;
  notificationMessage: string;
  redirectionLink: string;
  resourceId: number;
  resourceType: PermissionList;
  resourceName: string;
  entityInfo: MetaData;
}
export enum ContainerType {
  Boards = 'BOARDS',
  Modules = 'MODULES',
}
