import { useQuery } from '@tanstack/react-query';
import { type AxiosRequestConfig } from 'axios';
import { ScenarioApi } from '../api';
import { ScenarioStoreKeys } from '../constants';
import { type Scenario } from '../types';

export const useScenarios = (
  config?: AxiosRequestConfig,
): {
  scenarios: Scenario[];
  isScenarioFeatureActive: boolean;
  isLoading: boolean;
} => {
  const { data = [], isLoading } = useQuery(ScenarioStoreKeys.AllRecords(config), () =>
    ScenarioApi.fetchScenarios(config),
  );

  return {
    scenarios: data,
    isScenarioFeatureActive: data.length > 1,
    isLoading,
  };
};
