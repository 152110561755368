import { Spinner } from 'components/ui/atomic-components';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { useLists } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { type FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Redirection decider while landing on /lists page
 * If lists is already present in the /model API, the page redirects to /new
 * @returns EmptyComponent
 */
export const ListsRedirectPage: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { data, isFetched } = useLists();

  useEffect(() => {
    if (data?.[0]) {
      const isDimGroup = !!(data?.[0] as DimensionGroup)?.uniqueKeyColumns;
      const listId = isDimGroup ? `${DIM_GROUP_ID_PREFIX}${data?.[0].id}` : data?.[0].id;

      navigate(`/lists/${listId}`, { replace: true });
    } else if (isFetched) {
      navigate('/lists/new', { replace: true });
    }
  }, [navigate, isFetched, data]);

  return (
    <>
      <Spinner />
    </>
  );
};
