import { forwardRef } from 'react';
import { type GroupBase } from 'react-select';
import ReactSelect, { type CreatableProps } from 'react-select/creatable';
import { type OptionBaseOld, SelectOption } from './option';
import { type SelectSizes } from './select';
import { getSmallSizeStyles, getMediumSizeStyles, getLargeSizeStyles } from './styles';

export interface SelectProps<T, IsMulti extends boolean = false>
  extends CreatableProps<T, IsMulti, GroupBase<T>> {
  size?: SelectSizes;
}

const SelectBase = <T extends OptionBaseOld, IsMulti extends boolean>(
  {
    classNamePrefix,
    size = 'medium',
    components,
    styles,
    isSearchable = false,
    ...props
  }: SelectProps<T, IsMulti>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  const customStyles = {
    small: getSmallSizeStyles<T, IsMulti>(),
    medium: getMediumSizeStyles<T, IsMulti>(),
    large: getLargeSizeStyles<T, IsMulti>(),
  }[size];

  return (
    <ReactSelect<T, IsMulti>
      classNamePrefix={classNamePrefix || 'react-select'}
      components={{
        Option: SelectOption,
        ...components,
      }}
      isSearchable={isSearchable}
      styles={{
        ...customStyles,
        ...styles,
      }}
      {...props}
      ref={ref}
    />
  );
};

export const CreatableSelect = forwardRef(SelectBase) as typeof ReactSelect;
