import { create } from 'zustand';

export interface LeftNavStore {
  showSecondaryLeftNav: boolean;
  setShowSecondaryLeftNav: (val: boolean) => void;
}

export const useLeftNavStore = create<LeftNavStore>()((set, get) => ({
  showSecondaryLeftNav: true,
  setShowSecondaryLeftNav: (val) => set({ showSecondaryLeftNav: val }),
}));
