import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { BoardsApi } from 'data/boards/api';
import { type Board } from 'data/boards/types';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useCreateBoardMutation = (): UseMutationResult<
  Board,
  unknown,
  Partial<Board>,
  unknown
> => {
  const navigate = useNavigate();

  const createBoardMutation = useMutation((board: Partial<Board>) => BoardsApi.createBoard(board), {
    onSuccess: (board) => {
      navigate({
        pathname: `/reports/${board.id}`,
        search: `?${createSearchParams({
          edit_mode: '1',
        })}`,
      });
    },
    onError: defaultApiErrorHandler,
  });

  return createBoardMutation;
};
