import { type UseMutationResult, useMutation } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { type QueryTableColumnDataType } from 'data/big-query';
import { ListsApi } from 'data/modelling/lists';
import { isNil } from 'lodash';
import { useListStoreContext } from 'store/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { replaceSubstringsToUppercase } from './utils';

interface ColumnProps {
  columnName: string;
  type?: QueryTableColumnDataType;
  formula?: string;
  dateFormat?: string;
  query?: string;
}

interface RenameColumnProps extends ColumnProps {
  newColumnName?: string;
}

interface Mutations {
  onAddColumn: UseMutationResult<{ name: string }, AxiosError<unknown>, ColumnProps, unknown>;
  onUpdateColumn: UseMutationResult<{ name: string }, unknown, RenameColumnProps, unknown>;
  onDeleteColumn: UseMutationResult<void, unknown, ColumnProps, unknown>;
}

const functionNamesThatNeedConversionToUppercase = ['lookup', 'concat', 'if'];

export const useColumnMutations = (): Mutations => {
  const activeListId = useListStoreContext((s) => s.activeListId);

  const onAddColumn = useMutation(
    ({ columnName, type, formula, dateFormat, query }: ColumnProps) => {
      if (isNil(activeListId)) {
        return Promise.reject();
      }

      const processedFormula = replaceSubstringsToUppercase(
        formula,
        functionNamesThatNeedConversionToUppercase,
      );

      const processedQuery = replaceSubstringsToUppercase(
        query,
        functionNamesThatNeedConversionToUppercase,
      );

      return ListsApi.addColumn(
        activeListId,
        columnName,
        type,
        dateFormat,
        processedFormula,
        processedQuery,
      );
    },
    {
      onError: defaultApiErrorHandler,
    },
  );

  const onUpdateColumn = useMutation(
    (props: RenameColumnProps) => {
      if (isNil(activeListId)) {
        return Promise.reject();
      }

      const processedFormula = replaceSubstringsToUppercase(
        props.formula,
        functionNamesThatNeedConversionToUppercase,
      );
      const processedQuery = replaceSubstringsToUppercase(
        props.query,
        functionNamesThatNeedConversionToUppercase,
      );

      return ListsApi.updateColumn({
        ...props,
        formula: processedFormula,
        listId: activeListId,
        query: processedQuery,
      });
    },
    {
      onError: defaultApiErrorHandler,
    },
  );

  const onDeleteColumn = useMutation(
    ({ columnName, type }: ColumnProps) => {
      if (isNil(activeListId)) {
        return Promise.reject();
      }

      return ListsApi.deleteColumn(activeListId, columnName, type);
    },
    {
      onError: defaultApiErrorHandler,
    },
  );

  return {
    onAddColumn,
    onUpdateColumn,
    onDeleteColumn,
  };
};
