import { PUSHER_API_KEY, PUSHER_CLUSTER_NAME } from 'config/environment';
import { ConversationEventType } from 'data/conversations';
import Pusher from 'pusher-js';

// In case we want to log every pusher messages
// Pusher.logToConsole = true;

// Events that we can skip for showing notifications
export const EventsToSkipNotifications: ConversationEventType[] = [
  ConversationEventType.AddMemberOthers,
];

export const pusher = new Pusher(PUSHER_API_KEY, {
  cluster: PUSHER_CLUSTER_NAME,
});
