import { type CheckboxProps } from 'antd';
import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { controlLabelStyles } from '../utils/text-color';

export const checkboxStyles = ({
  theme,
}: CheckboxProps & {
  theme: DefaultTheme;
  size?: 'extraSmall' | 'small' | 'middle' | 'large';
}): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    /* stylelint-disable no-duplicate-selectors */
    .ant-checkbox + span {
      ${controlLabelStyles({ theme })}
    }

    .ant-checkbox-inner {
      border: 1px solid ${({ theme }) => theme.colors.buttonOutlineBorder};
    }

    &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
      .ant-checkbox-inner {
        background: ${({ theme }) => theme.colors.bgLight};
        border: 1px solid ${({ theme }) => theme.colors.buttonOutlineBorder};
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner {
        background: ${({ theme }) => theme.colors.buttonFillHoverPrimary};
        border: 1px solid ${({ theme }) => theme.colors.buttonFillHoverPrimary};
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background: ${({ theme }) => theme.colors.buttonFillHoverPrimary};
        border: 1px solid ${({ theme }) => theme.colors.buttonFillHoverPrimary};
      }
    }

    .ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
      outline: 1px solid ${({ theme }) => theme.colors.controlOutline};
      outline-offset: 2px;
    }

    .ant-checkbox.ant-checkbox-indeterminate
      .ant-checkbox-input:focus-visible
      + .ant-checkbox-inner {
      outline: 1px solid ${({ theme }) => theme.colors.primary300};
      outline-offset: 2px;
    }

    .ant-checkbox.ant-checkbox-checked .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
      outline: 1px solid ${({ theme }) => theme.colors.primary300};
      outline-offset: 2px;
    }

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        background: ${({ theme }) => theme.colors.gray00};
        transform: translate(-50%, -50%) scale(1);
        height: 2px;
        top: 50%;
        left: 50%;
      }

      .ant-checkbox-inner {
        background: ${({ theme }) => theme.colors.buttonFillPrimary};
        border: 1px solid ${({ theme }) => theme.colors.buttonFillPrimary};
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: ${({ theme }) => theme.colors.buttonFillPrimary};
      border: 1px solid ${({ theme }) => theme.colors.buttonFillPrimary};
    }

    &.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      .ant-checkbox .ant-checkbox-inner {
        background: ${({ theme }) => theme.colors.bgDark};
      }

      .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
        background: ${({ theme }) => theme.colors.primary200};
        border: 1px solid ${({ theme }) => theme.colors.gray200};

        ::after {
          border-color: ${({ theme }) => theme.colors.gray00};
        }
      }

      .ant-checkbox + span {
        color: ${({ theme }) => theme.colors.textPlaceholder};
      }
    }

    ${switchProp(prop('size', 'middle'), {
      extraSmall: css`
        .ant-checkbox-inner {
          border-radius: ${({ theme }) => theme.borderRadius.xs};
          height: 12px;
          width: 12px;
          margin-top: 1px;

          &::after {
            transform: rotate(45deg) scale(0.55);
            top: 2px;
            left: 3px;
          }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          width: calc(100% - 8px);
        }
      `,
      small: css`
        .ant-checkbox-inner {
          border-radius: ${({ theme }) => theme.borderRadius.xs};
          height: 16px;
          width: 16px;

          &::after {
            transform: rotate(45deg) scale(0.72);
            top: 3px;
            left: 5px;
          }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          width: calc(100% - 10px);
        }
      `,
      middle: css`
        .ant-checkbox-inner {
          border-radius: ${({ theme }) => theme.borderRadius.s};
          height: 18px;
          width: 18px;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          width: calc(100% - 10px);
        }
      `,
      large: css`
        .ant-checkbox-inner {
          border-radius: ${({ theme }) => theme.borderRadius.s};
          height: 20px;
          width: 20px;

          &::after {
            transform: rotate(45deg) scale(1.27);
            top: 4px;
            left: 7px;
          }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          width: calc(100% - 10px);
        }
      `,
    })};
  `;
};
