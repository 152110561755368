import { ReactComponent as CopyIcon } from 'assets/v2/copy.svg';
import { Button, IconShell, notification } from 'components/ui/atomic-components';
import { clone } from 'lodash';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { generateRandomNumber } from 'utils';
import { type ListGridRow } from '../grid/types';
import { type UndoStackItem } from '../grid/undo-redo/types';

interface Props {
  onSaveListEntry: (o: { rowNode: ListGridRow; undoContext: UndoStackItem }) => Promise<void>;
}

export const DuplicateAction = ({ onSaveListEntry }: Props): ReactElement => {
  const intl = useIntl();

  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);
  const setSelectedRowIds = useListStoreContext((s) => s.setSelectedRowIds);
  const store = useListStoreInstance();

  const onDuplicate = () => {
    const { rows, gridApi, bulkAddNewRows } = store.getState();

    const rowNodesToAdd: ListGridRow[] = [];

    rows.forEach((row) => {
      if (selectedRowIds.includes(row.id)) {
        const node = {
          id: generateRandomNumber(),
          data: clone(row.data),
          position: (rows.at(-1)?.position ?? 0) + rowNodesToAdd.length,
        } as ListGridRow;

        rowNodesToAdd.push(node);

        const undoContext: UndoStackItem = {
          type: 'cell-value-change',
          meta: {
            rowId: `${node.id}`,
            column: '',
            value: undefined,
          },
        };

        // todo update login on bulk save of rows support is added
        onSaveListEntry({ rowNode: node, undoContext }).then(() => {
          notification.success({
            message: intl.formatMessage(
              { id: 'lists.floating.duplicate_success' },
              { count: rowNodesToAdd.length },
            ),
          });
        });
      }
    });

    bulkAddNewRows(rowNodesToAdd);
    setSelectedRowIds([]);

    setTimeout(() => {
      gridApi?.ensureIndexVisible(rows.length - 1 + rowNodesToAdd.length);
    }, 100);
  };

  // remove once BE adds support for bulk adding of rows
  if (selectedRowIds.length !== 1) {
    return <></>;
  }

  return (
    <Button
      icon={<IconShell color="textTitle2" icon={CopyIcon} />}
      size="small"
      onClick={onDuplicate}
    >
      <FormattedMessage id="planning.metric.duplicate" />
    </Button>
  );
};
