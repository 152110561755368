import { Avatar as AntdAvatar, type AvatarProps } from 'antd';
import { type GroupProps } from 'antd/es/avatar';
import { type ReactElement, type FC, type ReactNode } from 'react';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { FontXxs } from 'styles/typography';
import { Tooltip } from '../tooltip';
import { getAvatarColorBase } from './utils';

type AvatarSize = 'extraSmall' | 'small' | 'medium' | 'large';
type Props = Omit<AvatarProps, 'size'> & { name?: string; size: AvatarSize; tooltip?: ReactNode };

const sizeMap: Record<AvatarSize, number> = {
  extraSmall: 20,
  small: 24,
  medium: 32,
  large: 40,
};

const sizeFontMap: Record<AvatarSize, string> = {
  extraSmall: '10px',
  small: '11px',
  medium: '13px',
  large: '15px',
};

const StyledAvatar = styled(AntdAvatar)<
  AvatarProps & { $color: string; $bgColor: string; $size: AvatarSize }
>`
  ${FontXxs};

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.gray00};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ $color }) => $color};
  background: ${({ $bgColor }) => $bgColor};
  flex-shrink: 0;

  .ant-avatar-string {
    font-size: ${({ $size }) => sizeFontMap[$size]};
  }
`;

export const Avatar: FC<React.PropsWithChildren<Props>> = ({
  name,
  tooltip,
  children,
  size = 'medium',
  ...rest
}) => {
  const [fistName, lastName] = name?.split(' ') || [];
  const formattedName = `${(fistName?.[0] || lastName?.[0])?.toUpperCase() || ''}`;

  const baseColor = getAvatarColorBase(name);

  return (
    <Tooltip placement="top" title={tooltip || name}>
      <StyledAvatar
        $bgColor={defaultTheme.colors[`${baseColor}200` as keyof typeof defaultTheme.colors]}
        $color={defaultTheme.colors[`${baseColor}700` as keyof typeof defaultTheme.colors]}
        $size={size}
        data-id="avatar"
        size={sizeMap[size]}
        {...rest}
      >
        {children || formattedName}
      </StyledAvatar>
    </Tooltip>
  );
};

const StyledAvatarGroup = styled(AntdAvatar.Group)<{ $size: AvatarSize }>`
  *:not(:first-child) {
    margin-inline-start: -${({ $size, theme }) => {
        if ($size === 'large') {
          return theme.spacing[8];
        }

        return theme.spacing[4];
      }};
  }

  .ant-avatar:not([data-id='avatar']) {
    background: transparent;
    color: ${({ theme }) => theme.colors.textTitle2};
    border: none;
    margin-inline-start: ${({ theme }) => -theme.spacing[4]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    flex-shrink: 0;

    .ant-avatar-string {
      font-size: ${({ $size }) => sizeFontMap[$size]};
    }
  }
`;

export const AvatarGroup = ({
  size = 'medium',
  ...rest
}: Omit<GroupProps, 'size'> & { size?: AvatarSize }): ReactElement => {
  return <StyledAvatarGroup {...rest} $size={size} size={sizeMap[size]} />;
};
