import { ListType } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ListTypeItem } from './list-type-item';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

interface Props {
  listType: ListType;
  setListType: (type: ListType) => void;
}

export const ListTypeSelector = ({ listType, setListType }: Props): ReactElement => {
  return (
    <Wrapper>
      <ListTypeItem
        description={<FormattedMessage id="lists.create_modal.simple_list.description" />}
        isSelected={listType === ListType.SimpleTable}
        title={<FormattedMessage id="lists.create_modal.simple_list.title" />}
        onClick={() => setListType(ListType.SimpleTable)}
      />
      <ListTypeItem
        description={<FormattedMessage id="lists.create_modal.from_existing_data.description" />}
        isSelected={listType === ListType.FromExistingData}
        title={<FormattedMessage id="lists.create_modal.from_existing_data.title" />}
        onClick={() => setListType(ListType.FromExistingData)}
      />
    </Wrapper>
  );
};
