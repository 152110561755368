import { type CheckboxProps } from 'antd';
import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { checkboxStyles } from '../checkbox/styles';

export const checkboxGroupStyles = ({
  theme,
  size,
}: CheckboxProps & {
  theme: DefaultTheme;
  size?: 'extraSmall' | 'small' | 'middle' | 'large';
}): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    .ant-checkbox-wrapper {
      ${checkboxStyles({ theme, size })}
    }
  `;
};
