import styled from 'styled-components';
import { StyledPaddings, type StyledPaddingProps } from 'styles/styled-atoms';

export const PopoverFooter = styled.div<StyledPaddingProps>`
  ${StyledPaddings};

  border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing[32]};

  > div:last-child {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;
