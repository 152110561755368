import { ReactComponent as PopoutLinkIcon } from 'assets/ic_popout_link.svg';
import { AlertOld, Button, IconShell } from 'components/ui/atomic-components';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';
import { NetsuiteForm } from './form';

const { StyledP } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer, FullWidthWrapper, RightPanel, RightTitle } =
  FormConnectorStyles;

export const NetsuiteContent: FC<React.PropsWithChildren<ModalContentProps>> = ({
  item,
  onConnectionSuccess,
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const br = <br />;
  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/accounting-and-erp/integrating-with-netsuite';

  return (
    <>
      {hasError && (
        <AlertOld
          closable
          message={
            errorMessage || <FormattedMessage id="integrations.add_data_modal.common_error" />
          }
          type="warning"
        />
      )}
      <FullWidthWrapper>
        <LeftPanel>
          <ImageContainer
            alt=""
            src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
          />
          <Title>{item.displayName}</Title>

          <StyledP>
            <FormattedMessage id="integrations.netsuite.modal.desc" />
          </StyledP>
          <StyledP>
            <strong>
              <FormattedMessage id="integrations.netsuite.modal.para" />
            </strong>
          </StyledP>
          <StyledP>
            <FormattedMessage id="integrations.netsuite.modal.list" values={{ br }} />
          </StyledP>
          <StyledP>
            <FormattedMessage id="integrations.netsuite.modal.line2" />
          </StyledP>
          <StyledP>
            <strong>
              <FormattedMessage id="integrations.modal.add_resources" />
            </strong>
          </StyledP>
          <Button
            icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
            onClick={() => window.open(docLink, '_blank', 'noopener')}
          >
            <FormattedMessage id="integrations.modal.linktext" />
          </Button>
          <CommonContent {...item} />
        </LeftPanel>
        <RightPanel>
          <RightTitle>
            <FormattedMessage
              id="integrations.sfdc.modal.title"
              values={{ name: item.displayName }}
            />
          </RightTitle>
          <NetsuiteForm
            service={item.id}
            setErrorMessage={setErrorMessage}
            setHasError={setHasError}
            onConnectionSuccess={onConnectionSuccess}
          />
        </RightPanel>
      </FullWidthWrapper>{' '}
    </>
  );
};
