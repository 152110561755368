import { QueryTableColumnDataType } from 'data/big-query';
import { type DataFormatWithType } from 'data/metrics';
import { type ListRow } from 'data/modelling/lists';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useListStoreContext } from 'store/lists';
import { numberFormatter } from 'utils/data-formatter';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  ZeroFormatType,
} from 'utils/data-formatter/types';
import { checkIfListRowMatchesFilter } from '../../../../hooks/use-grid-row-data/utils';

export type ListFilterOption = {
  id: string;
  key: string;
  title: string;
  value: string;
};

const FilterBlankLabel = '(Blank)';

const generateOptions = ({
  listRows,
  colName,
  columnFilters,
  columnNamesSet,
  checkedValues,
  type,
  dataFormat,
}: {
  listRows: ListRow[];
  colName: string;
  checkedValues?: Set<string>;
  columnFilters: Record<string, Set<string>>;
  columnNamesSet: Set<string>;
  type: QueryTableColumnDataType;
  dataFormat?: DataFormatWithType;
}): { allUniqueOptions: ListFilterOption[]; allUniqueValuesMap: Set<string | number> } => {
  const allUniqueValuesMap = new Set<string | number>();
  const checkedResults: ListFilterOption[] = [];
  const unCheckedResults: ListFilterOption[] = [];

  const columnFiltersClone = { ...columnFilters };

  // don't consider current column filter while showing value options
  delete columnFiltersClone[colName];

  listRows.forEach((row) => {
    if (
      checkIfListRowMatchesFilter({
        row,
        columnFilters: columnFiltersClone,
        columnNamesSet,
      })
    ) {
      const value = row.data[colName] || '';

      if (!allUniqueValuesMap.has(value)) {
        const valueString = String(value ?? '');

        const formattedValue =
          type === QueryTableColumnDataType.Numeric
            ? numberFormatter({
                value,
                ...dataFormat,
                displayUnit: dataFormat?.displayUnit ?? DisplayUnitType.Full,
                digitsAfterDecimal:
                  dataFormat?.digitsAfterDecimal ?? (Number(value) % 1 !== 0 ? 2 : 0),
                zeroFormat: dataFormat?.zeroFormat ?? ZeroFormatType.Number,
                blankValueFormat: dataFormat?.blankValueFormat ?? BlankValueType.Blank,
                type: dataFormat?.type ?? DataFormattingType.Number,
              })
            : valueString;

        if (checkedValues?.has(valueString)) {
          checkedResults.push({
            id: valueString,
            key: valueString,
            title: formattedValue || FilterBlankLabel,
            value: valueString,
          });
        } else {
          unCheckedResults.push({
            id: valueString,
            key: valueString,
            title: formattedValue || FilterBlankLabel,
            value: valueString,
          });
        }
        allUniqueValuesMap.add(value);
      }
    }
  });

  return {
    allUniqueOptions: [...sortBy(checkedResults, 'title'), ...sortBy(unCheckedResults, 'title')],
    allUniqueValuesMap,
  };
};

interface Props {
  colName: string;
}

export const useGetColAllUniqueValues = ({
  colName,
}: Props): { allUniqueOptions: ListFilterOption[]; allUniqueValuesMap: Set<string | number> } => {
  const rows = useListStoreContext((s) => s.rows);
  const columnFilters = useListStoreContext((s) => s.columnFilters);
  const config = useListStoreContext((s) => s.config);

  const dataFormat = config.dataFormatMap?.[colName];
  const type = config.columnTypeMap?.[colName];

  return useMemo(() => {
    return generateOptions({
      listRows: rows,
      colName,
      checkedValues: columnFilters?.[colName],
      columnNamesSet: new Set(config.columnOrder || []),
      columnFilters: columnFilters || {},
      type,
      dataFormat,
    });
  }, [colName, columnFilters, config.columnOrder, dataFormat, rows, type]);
};
