import { DimSpace, type Dimension, type DimensionQueryParamsObject } from 'data/dimension';
import { type ModuleMetric } from 'data/modelling/metric';
import { type ModuleQueryParams } from 'data/modelling/modules';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { type Frame } from 'data/reports/types';
import { generateDataLookupKey } from 'data/reports/utils';
import { isEmpty } from 'lodash';
import { type MutableRefObject } from 'react';
import { type DecodedValueMap } from 'use-query-params';
import { type ModelStore } from '..';

export type MetricCellEditableState = Map<
  string,
  {
    cell: boolean;
    formulaCell: boolean;
  }
>;

interface Props {
  metrics: ModuleMetric[];
  filter: DimensionQueryParamsObject;
  pageDimensionNames: string[];
  metricPivotMap: Map<
    string,
    {
      rows: string[];
      columns: string[];
    }
  >;
  tempSccl: boolean;
  queryRef: MutableRefObject<DecodedValueMap<ModuleQueryParams>>;
  frame: Frame;
  dataLookupMap: ModelStore['dataLookupMap'];
}

export const computeMetricForWhichLeafRowsEditable = ({
  metrics,
  filter,
  pageDimensionNames,
  metricPivotMap,
  tempSccl,
  queryRef,
  frame,
  dataLookupMap,
}: Props): MetricCellEditableState => {
  const metricForWhichLeafRowsEditable = new Map<
    string,
    {
      cell: boolean;
      formulaCell: boolean;
    }
  >();

  metrics.forEach((metric) => {
    const metricPivot = metricPivotMap.get(metric.name);

    const keyToSearch = generateDataLookupKey({
      dimensions: { [PIVOT_DIMENSION_METRIC_ROW_NAME]: metric.name },
      rowsOrder: [PIVOT_DIMENSION_METRIC_ROW_NAME],
      columnsOrder: [],
      firstVersion: '',
    });

    const checkIfData = () => Object.keys(dataLookupMap).some((k) => k.startsWith(keyToSearch));
    const hasPageFilter = !isEmpty(filter);
    const hasNoChildRows = isEmpty(frame.rows?.find((node) => node.key === metric.name)?.children);

    // All edits are blocked for this metric if:
    // 1. at least one filter is selected +
    // 2. no accrodions in frame +
    // 3. data is empty
    const allEditsBlockedForThisMetric = hasPageFilter && hasNoChildRows && !checkIfData();

    // metric's every dimension in page should have a filter value present
    const isFilterValuePresentForEveryMetricDim = metric.dimensions
      .filter(
        (dimension) =>
          pageDimensionNames.includes(dimension.name) &&
          !metricPivot?.rows.includes(dimension.name),
      )
      .every((dimension) => !isEmpty(filter[dimension.name]));

    const hasPlanningDimensionInPivot = tempSccl
      ? checkIfPlanningDimensionInPivot({ dimensions: metric.dimensions, metricPivot, queryRef })
      : true;

    metricForWhichLeafRowsEditable.set(metric.name, {
      cell:
        !allEditsBlockedForThisMetric &&
        isFilterValuePresentForEveryMetricDim &&
        hasPlanningDimensionInPivot,
      formulaCell: !allEditsBlockedForThisMetric,
    });
  });

  return metricForWhichLeafRowsEditable;
};

const checkIfPlanningDimensionInPivot = ({
  dimensions,
  metricPivot,
  queryRef,
}: {
  dimensions: Dimension[];
  metricPivot?: {
    rows: string[];
    columns: string[];
  };
  queryRef: MutableRefObject<DecodedValueMap<ModuleQueryParams>>;
}) => {
  const plannedDimension = dimensions.find((d) => d.dimSpace === DimSpace.Plan);

  if (!plannedDimension) {
    return true;
  }

  if (metricPivot?.rows.includes(plannedDimension.name)) {
    return true;
  }

  if (!isEmpty(queryRef.current?.f?.[plannedDimension.name])) {
    return true;
  }

  return false;
};
