import { weightedColorAverage } from 'utils/color-code-converters';

export const lightenOrDarkenColor = (colorInHex: string, amount: number): string => {
  const colorBlack = '#000000';
  const colorWhite = '#ffffff';

  return weightedColorAverage(
    colorInHex,
    amount < 0 ? colorBlack : colorWhite,
    1 - Math.abs(amount),
  );
};
