import {
  type PageTemplateElement,
  PivotViewType,
  PIVOT_DIMENSION_METRIC_ROW_NAME,
  TemplateElementType,
} from 'data/page-template';
import { type FrameElement, FrameType } from 'data/reports/types';
import { type Dictionary, isEmpty, keyBy } from 'lodash';

const getDisplayName = (element: PageTemplateElement, currentMetric?: FrameElement) => {
  if (element.type === TemplateElementType.Blank) {
    return '';
  }

  if (element.type === TemplateElementType.Section) {
    return element.displayName || '';
  }

  return currentMetric?.displayName || '';
};

const getIsDataLeaf = (element: PageTemplateElement, currentMetric?: FrameElement) => {
  if (element.type === TemplateElementType.Blank) {
    return true;
  }
  if ([TemplateElementType.Section, TemplateElementType.SectionMetric].includes(element.type)) {
    return false;
  }

  return currentMetric?.isDataLeaf;
};

const getChildren = ({
  element,
  metricsMap,
  metricsSet,
  viewType,
  currentMetric,
}: {
  element: PageTemplateElement;
  metricsMap: Dictionary<FrameElement>;
  metricsSet: Set<string>;
  viewType?: PivotViewType;
  currentMetric?: FrameElement;
}) => {
  if (element.type === TemplateElementType.Metric || viewType === PivotViewType.Table) {
    return currentMetric?.children || [];
  }

  return processElements({ elements: element.elements, metricsMap, metricsSet, viewType });
};

const processElements = ({
  elements,
  metricsMap,
  metricsSet,
  viewType,
}: {
  elements: PageTemplateElement[] | undefined;
  metricsMap: Dictionary<FrameElement>;
  metricsSet: Set<string>;
  viewType?: PivotViewType;
}): FrameElement[] => {
  const result: FrameElement[] = [];

  for (const element of elements || []) {
    const currentMetric = metricsMap[element.name];

    // skip adding sections for table view
    if (!(viewType === PivotViewType.Table && element.type === TemplateElementType.Section)) {
      // skip deleted metrics and section metrics
      if (
        [TemplateElementType.Metric, TemplateElementType.SectionMetric].includes(element.type) &&
        !currentMetric
      ) {
        continue;
      }

      metricsSet.delete(element.name);

      result.push({
        dimensionName: PIVOT_DIMENSION_METRIC_ROW_NAME,
        displayName: getDisplayName(element, currentMetric),
        isDataLeaf: getIsDataLeaf(element, currentMetric),
        isMasked: currentMetric?.isMasked,
        key: element.name,
        type: element.type as unknown as FrameType,
        children: getChildren({ element, metricsMap, metricsSet, viewType, currentMetric }),
      });
    }

    if (viewType === PivotViewType.Table) {
      result.push(
        ...processElements({ elements: element.elements, metricsMap, metricsSet, viewType }),
      );
    }
  }

  return result;
};

export const structureMetricsLevel = (
  rows: FrameElement[],
  elements: PageTemplateElement[],
  viewType?: PivotViewType,
  level = 0,
): FrameElement[] => {
  if (isEmpty(rows) && level !== 0) {
    return [];
  }

  const levelType = !isEmpty(rows) ? rows[0].type : FrameType.Metric;
  const newRows = [];

  if (levelType === FrameType.Metric) {
    const metricsMap = keyBy(rows, 'key');
    const metricsSet = new Set(rows.map((row) => row.key));
    const processedElements = processElements({ elements, metricsMap, metricsSet, viewType });

    const remainingMetrics = Array.from(metricsSet)
      .filter((metric) => metricsMap[metric])
      .map((metric) => metricsMap[metric]);

    newRows.push(...remainingMetrics, ...processedElements);
  } else {
    rows.forEach((row) => {
      newRows.push({
        ...row,
        children: structureMetricsLevel(row.children, elements, viewType, level + 1),
      });
    });
  }

  return newRows;
};
