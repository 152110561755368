import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as CrossIcon } from 'assets/v2/close.svg';
import { InitialsIconText } from 'components/modules/modelling/dimensions/right-section/top-nav/dimensions-dropdown/access-control/icon';
import { RoleOption } from 'components/modules/modelling/dimensions/right-section/top-nav/dimensions-dropdown/access-control/option';
import { AccessControlWidgetStyles } from 'components/modules/modelling/dimensions/right-section/top-nav/dimensions-dropdown/access-control/styles';
import {
  Modal,
  type OptionBase,
  Select,
  Button,
  IconShell,
  notification,
} from 'components/ui/atomic-components';
import { type List } from 'data/modelling/lists';
import { useAllUsersAndRoles } from 'data/roles/hooks/use-user-role';
import { useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { useAccessControlMutation } from '../hooks/mutation';

const {
  Label,
  ItemWrapper,
  List: RolesList,
  ButtonTextWrapper,
  ValueText,
} = AccessControlWidgetStyles;

export const EditAccessControlModal = ({
  isDerivedList,
  open,
  onClose,
}: {
  isDerivedList?: boolean;
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const listId = useListStoreContext((s) => s.id);
  const listName = useListStoreContext((s) => s.name);

  const { data, isLoading } = useAllUsersAndRoles();
  const { isLoading: isSettingAccessControl, mutateAsync: setAccessControl } =
    useAccessControlMutation({ isDerivedList });

  const [selectedRoles, setSelectedRoles] = useState<OptionBase[]>([]);

  const roleOptions =
    data?.roles.map((role) => ({
      label: role.name,
      value: role.id,
    })) ?? [];

  const link = (text: string) => <a href="/settings/roles">{text}</a>;

  const onChange = (newValue: OptionBase) => {
    if (!selectedRoles.find((m) => m.value === newValue.value)) {
      setSelectedRoles([...selectedRoles, newValue] as OptionBase[]);
    }
  };

  const onDelete = (option: OptionBase) => {
    setSelectedRoles([...selectedRoles.filter((m) => m.value !== option.value)]);
  };

  const onSave = () => {
    setAccessControl({
      listId,
      listName,
      isAccessControlled: true,
      roleIds: selectedRoles.map((m) => m.value),
    }).then(() => {
      onClose();
      setSelectedRoles([]);
      notification.success({
        message: intl.formatMessage({
          id: 'dimensions.access_control_modal.on_success.message',
        }),
        description: (
          <FormattedMessage
            id="dimensions.access_control_modal.on_success.description"
            values={{ a: link }}
          />
        ),
      });

      queryClient.setQueryData<List>(
        [isDerivedList ? 'dimension-groups' : 'lists', listId],
        (oldList) => {
          if (oldList) {
            return {
              ...oldList,
              accessControlled: true,
            };
          }
        },
      );

      queryClient.setQueryData<List[]>(['lists'], (existingLists) =>
        (existingLists || []).map((list) => {
          if (list.id === listId) {
            return {
              ...list,
              accessControlled: true,
            };
          }

          return list;
        }),
      );
    });
  };

  const onCancel = () => {
    setSelectedRoles([]);
    onClose();
  };

  return (
    <Modal
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={isSettingAccessControl}
      destroyOnClose
      okText={<FormattedMessage id="save" />}
      open={open}
      title={<FormattedMessage id="dimensions.set_access_control" />}
      onCancel={onCancel}
      onOk={onSave}
    >
      <Label>
        <FormattedMessage
          id="dimensions.access_control_modal.label"
          values={{ dimensionName: formatName(listName) }}
        />
      </Label>
      <Select
        components={{
          Option: RoleOption,
        }}
        inputValue=""
        isLoading={isLoading}
        isSearchable
        options={roleOptions}
        size="medium"
        value={null}
        onChange={(nv) => onChange(nv as OptionBase)}
      />
      <RolesList>
        {selectedRoles.map((option) => (
          <ItemWrapper key={option.value}>
            <InitialsIconText text={option.label as string} />
            <ButtonTextWrapper>
              <ValueText>
                <FormattedMessage id="lists.access_control_modal.list.full_access" />
              </ValueText>
              <Button
                icon={<IconShell color="textDisabled" icon={CrossIcon} size="sm" />}
                size="extraSmall"
                type="text"
                onClick={() => onDelete(option)}
              />
            </ButtonTextWrapper>
          </ItemWrapper>
        ))}
      </RolesList>
    </Modal>
  );
};
