import { type EditorView } from '@codemirror/view';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { type Granularity } from 'data';
import { getPeriodOption } from '../../utils';
import { generateCustomOption } from './custom-option/generate-custom-option';
import { MenuLabel } from './menu-label';
import { type PeriodOptionType } from './types';

const getOptions = ({
  options,
  selectedValue,
  onItemClick,
}: {
  options: PeriodOptionType[];
  selectedValue?: string;
  onItemClick?: (option: PeriodOptionType) => void;
}): ItemType[] => {
  const periodValue = selectedValue?.split('=')[1];

  return options.map((option) => ({
    key: option?.value || option.id,
    label: (
      <MenuLabel
        isSelected={option.value === periodValue}
        option={option}
        selectedValue={selectedValue}
      />
    ),
    children:
      option.children && !!option.children?.length
        ? getOptions({
            options: option.children,
            selectedValue,
            onItemClick,
          })
        : undefined,

    onClick: () => onItemClick?.(option),
  }));
};

interface Props {
  selectedValue?: string;
  isCustomOptionSelected?: boolean;
  onItemClick?: (option: PeriodOptionType) => void;
  onClickCustomOption?: () => void;
  onCustomOptionEnter?: (value: string) => void;
  setEditorView?: (editorView: EditorView | null) => void;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  timeFilterGranularity: Granularity;
}

export const constructPeriodOptions = ({
  selectedValue,
  isCustomOptionSelected,
  customTimeAutoCompletionOptions,
  timeFilterGranularity,
  onItemClick,
  onClickCustomOption,
  onCustomOptionEnter,
  setEditorView,
}: Props): ItemType[] => {
  const periodOptions = getOptions({
    options: getPeriodOption(timeFilterGranularity),
    selectedValue,
    onItemClick,
  });
  const customOption = generateCustomOption({
    isSelected: isCustomOptionSelected,
    selectedValue,
    customTimeAutoCompletionOptions,
    timeFilterGranularity,
    onSelect: onClickCustomOption,
    onPressEnter: onCustomOptionEnter,
    setEditorView,
  });

  return [...periodOptions, { key: 'divider', type: 'divider' }, customOption];
};
