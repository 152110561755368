import { Button } from 'components/ui/atomic-components';
import { CSVUploadWorkflowStage } from 'data/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RightSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
`;

interface Props {
  currentStage: CSVUploadWorkflowStage;
  validationLoading?: boolean;
  saveLoading?: boolean;
  noSavableRowsFound?: boolean;
  cellEditsFound?: boolean;
  onBack?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose: () => void;
  onSubmit: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onValidate: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const ModalFooter = ({
  currentStage,
  validationLoading,
  saveLoading,
  noSavableRowsFound,
  cellEditsFound,
  onClose,
  onBack,
  onSubmit,
  onValidate,
}: Props): ReactElement => {
  return (
    <Wrapper>
      <div>
        {onBack && currentStage !== CSVUploadWorkflowStage.ColumnMapping && (
          <Button size="small" onClick={(e) => onBack?.(e)}>
            <FormattedMessage id="go_back" />
          </Button>
        )}
      </div>

      <RightSection>
        {currentStage === CSVUploadWorkflowStage.ColumnMapping && (
          <>
            <Button size="small" onClick={(e) => onClose()}>
              <FormattedMessage id="cancel" />
            </Button>

            <Button
              loading={validationLoading}
              size="small"
              type="primary"
              onClick={(e) => onSubmit(e)}
            >
              <FormattedMessage id="next" />
            </Button>
          </>
        )}

        {currentStage === CSVUploadWorkflowStage.Preview && (
          <>
            <Button size="small" onClick={(e) => onClose()}>
              <FormattedMessage id="cancel" />
            </Button>

            <Button
              disabled={noSavableRowsFound && !cellEditsFound}
              loading={validationLoading || saveLoading}
              size="small"
              type="primary"
              onClick={async (e) => {
                await onValidate(e);
                onSubmit(e);
              }}
            >
              <FormattedMessage id="planning.dimensions.upload.csv_column_mapping.validate_save" />
            </Button>
          </>
        )}
      </RightSection>
    </Wrapper>
  );
};
