import { type ColumnResizedEvent } from 'ag-grid-community';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';

export const onColumnResized = (
  event: ColumnResizedEvent,
  listStore: StoreApi<ListsStoreApi>,
  inferGridContainerWidth: () => void,
  onColumnWidthSave: ({
    activeListId,
    updatedColumnWidthMap,
  }: {
    activeListId: number | null;
    updatedColumnWidthMap?: Record<string, number> | undefined;
  }) => void,
): void => {
  const { setColumnWidth } = listStore.getState();
  const { finished, column, source } = event;
  const colId = column?.getId() as string;

  if (!finished || !colId || ['sizeColumnsToFit'].includes(source)) {
    return;
  }

  const colWidth = column?.getActualWidth() ?? 0;

  setColumnWidth(colId, colWidth, onColumnWidthSave);
  inferGridContainerWidth();
};
