import { type SwitchProps } from 'antd';
import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { ifProp } from 'styled-tools';

export const toggleStyles = ({
  theme,
}: SwitchProps & { children?: string } & { theme: DefaultTheme }): FlattenInterpolation<
  ThemeProps<DefaultTheme>
> => {
  return css`
    /* stylelint-disable no-descending-specificity, no-duplicate-selectors */

    height: 20px;
    width: 30px;
    min-width: 30px;

    &:not(.ant-switch-checked) {
      background-color: ${theme.colors.bgDark};
    }

    :hover:not(.ant-switch-disabled) {
      &:not(.ant-switch-checked) {
        background-color: ${theme.colors.gray300};
      }

      &.ant-switch-checked {
        background-color: ${theme.colors.buttonFillHoverPrimary};
      }
    }

    :focus-visible {
      :not(.ant-switch-checked) {
        outline: 1px solid ${theme.colors.controlOutline};
        outline-offset: 2px;
      }
    }

    :focus-visible.ant-switch-checked {
      outline: 1px solid ${theme.colors.primary300};
      outline-offset: 2px;
    }

    .ant-switch-handle {
      height: 16px;
      width: 16px;
      border-radius: ${theme.borderRadius.m};
      background-color: ${theme.colors.gray00};

      ::before {
        border-radius: ${theme.borderRadius.m};
        box-shadow: none;
      }
    }

    &.ant-switch-small {
      min-width: 24px;
      width: 24px;
      height: 16px;
    }

    &.ant-switch-checked .ant-switch-handle {
      inset-inline-start: calc(100% - ${ifProp({ size: 'default' }, '18px', '14px')});
    }

    &.ant-switch-disabled {
      opacity: 1;

      &.ant-switch-checked {
        background-color: ${theme.colors.primary200};

        .ant-switch-handle::before {
          background-color: ${theme.colors.bgLight};
        }
      }

      .ant-switch-handle::before {
        background-color: ${theme.colors.bgLight};
      }
    }
  `;
};
