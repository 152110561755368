import { type InputRef } from 'antd';
import { ReactComponent as SearchBoldIcon } from 'assets/v2/search-bold.svg';
import { ReactComponent as SearchIcon } from 'assets/v2/search.svg';
import { ReactComponent as XIcon } from 'assets/v2/x.svg';
import { forwardRef, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { IconShell } from '../icon-shell';
import { Input, type InputProps } from '../input';

export const SearchBox = forwardRef<InputRef, InputProps>(({ ...props }, ref): ReactElement => {
  const intl = useIntl();

  return (
    <Input
      ref={ref}
      allowClear={{ clearIcon: <IconShell icon={XIcon} /> }}
      placeholder={intl.formatMessage({ id: 'search' })}
      prefix={
        props.$inputType === 'outline' ? (
          <IconShell icon={SearchBoldIcon} />
        ) : (
          <IconShell icon={SearchIcon} />
        )
      }
      {...props}
    />
  );
});

SearchBox.displayName = 'SearchBox';
