import { type DataFormattingType } from 'utils/data-formatter/types';
import { displayUnitFormattersIND } from '../config/en-in';
import { displayUnitFormattersUS } from '../config/en-us';
import { DisplayUnitType, type FormatterProps } from '../types';
import { defaultFormattersForMetricType } from './default-formatter-map';

export const formatters: {
  [key in DisplayUnitType | 'default']: {
    [key in DataFormattingType | 'default']: (props: FormatterProps) => string;
  };
} = {
  [DisplayUnitType.Short]: defaultFormattersForMetricType,

  [DisplayUnitType.Full]: defaultFormattersForMetricType,

  ...displayUnitFormattersUS,

  ...displayUnitFormattersIND,

  default: defaultFormattersForMetricType,
};
