import axios from 'axios';
import { type ID } from 'data';
import { type FormulaErrorResponse } from '../errors';
import { type PlanMetric } from '../metric';
import {
  type Model,
  type ModuleVisualiserData,
  type ModelDependentRequestData,
  type ModelDependentResponseData,
  type ModelUniqueKeysResponse,
} from './types';

export const ModelApi = {
  /**
   * Obtain model of an account
   */
  async find(): Promise<Model> {
    const { data } = await axios.get('/v1/models');

    return data;
  },

  /**
   * Update model for an account
   */
  updateModel(data: Partial<Model>): Promise<void> {
    return axios.patch('/v1/models', data);
  },

  /**
   * Find the model's metrics
   */
  async findMetrics(): Promise<PlanMetric[]> {
    const { data } = await axios.get('/v1/models/metrics');

    return data?.map((m: PlanMetric) => ({ ...m, domain: 'planning' }));
  },

  /**
   * Create a new plan from the model
   */
  async visualise(): Promise<ModuleVisualiserData[]> {
    const { data } = await axios.get('/v1/models/visualise');

    return data;
  },

  /**
   * To get metric dependencies before deletion
   */
  async getDependencies(deps: ModelDependentRequestData): Promise<ModelDependentResponseData[]> {
    return axios.post('v1/models/delete-impact', deps).then(({ data }) => data);
  },

  /**
   *  To get diagnostic data
   */
  async getDiagnosticData(): Promise<FormulaErrorResponse[]> {
    return axios.get('v1/models/diagnose').then(({ data }) => data);
  },

  /**
   * Delete a formula
   */
  async deleteFormula(formulaId: ID): Promise<void> {
    return axios.delete(`v1/models/formula/${formulaId}`);
  },

  /**
   * Get all the unique keys
   */
  async getUniqueKeys(): Promise<ModelUniqueKeysResponse> {
    const { data } = await axios.get('v1/models/plan-keys');

    return data;
  },
};
