import { Dropdown as AntdDropdown } from 'antd';
import { ConfigContext } from 'antd/es/config-provider';
import { type DropdownButtonProps, type DropdownProps } from 'antd/es/dropdown';
import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down.svg';
import { type FC, useContext } from 'react';
import { Button } from '../../button';
import { IconShell } from '../../icon-shell';
import styles from './styles';

const { ButtonGroup } = styles;

export type AtlasDropdownButtonProps = Omit<DropdownButtonProps, 'size'> & {
  size?: 'extraSmall' | 'small' | 'middle' | 'large' | 'extraLarge';
};

export const DropdownButton: FC<React.PropsWithChildren<AtlasDropdownButtonProps>> = ({
  type = 'primary',
  size,
  danger,
  disabled,
  loading,
  onClick,
  htmlType,
  children,
  className,
  menu,
  trigger,
  align,
  onOpenChange,
  open,
  placement,
  getPopupContainer,
  href,
  icon = <IconShell icon={ChevronDownIcon} />,
  title,
  mouseEnterDelay,
  mouseLeaveDelay,
  overlayClassName,
  overlayStyle,
  destroyPopupOnHide,
  prefixCls: customizePrefixCls,
  ...restProps
}) => {
  const { getPrefixCls, direction } = useContext(ConfigContext);

  const dropdownProps = {
    align,
    menu,
    disabled,
    trigger: disabled ? [] : trigger,
    onOpenChange,
    getPopupContainer,
    mouseEnterDelay,
    mouseLeaveDelay,
    overlayClassName,
    overlayStyle,
    destroyPopupOnHide,
    open,
  } as DropdownProps;

  if (placement) {
    dropdownProps.placement = placement;
  } else {
    dropdownProps.placement = direction === 'rtl' ? 'bottomLeft' : 'bottomRight';
  }

  const prefixCls = getPrefixCls('dropdown-button', customizePrefixCls);

  return (
    <ButtonGroup {...restProps} className={`${prefixCls} ${className || ''}`}>
      <Button
        danger={danger}
        disabled={disabled}
        href={href}
        htmlType={htmlType}
        loading={loading}
        size={size}
        title={title}
        type={type}
        onClick={onClick}
      >
        {children}
      </Button>

      <AntdDropdown {...dropdownProps}>
        <Button danger={danger} icon={icon} size={size} type={type} />
      </AntdDropdown>
    </ButtonGroup>
  );
};
