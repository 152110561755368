import { type ListUpdate } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { Styles } from './styles';
import { formatUpdatedValue } from './utils';

const { Update, UpdateKey, UpdateValue } = Styles;

interface Props {
  listUpdate: ListUpdate;
}

export const EssentialFields = ({ listUpdate }: Props): ReactElement => {
  const listConfig = useListStoreContext((s) => s.config);
  const reconConfig = useListStoreContext((s) => s.reconciliationConfig);

  return (
    <>
      {(reconConfig.essentialFields || []).map((key) => {
        const value = listUpdate.data[key];

        return (
          <Update key={key}>
            <UpdateKey>{`${formatName(key)}:`}</UpdateKey>

            <UpdateValue>
              {formatUpdatedValue({ columnTypeMap: listConfig.columnTypeMap, column: key, value })}
            </UpdateValue>
          </Update>
        );
      })}
    </>
  );
};
