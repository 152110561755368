import { type GroupBase, type StylesConfig } from 'react-select';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { type TextOptionType } from './types';

export default {
  Wrapper: styled.div<{ $width: number }>`
    width: ${({ $width }) => $width + 2}px;
    position: relative;
    top: -1px;
    left: -1px;
  `,
  selectStyles: {
    control: (base) => ({
      ...base,
      minHeight: 34,
      borderWidth: 1.5,
      borderRadius: 2,
      borderColor: defaultTheme.colors.green500,
      boxShadow: defaultTheme.shadow.hardMicro,

      '&:hover': {
        borderColor: defaultTheme.colors.green500,
      },
    }),

    valueContainer: (base) => ({
      ...base,
      padding: `0 calc(${defaultTheme.spacing[12]} - 1px)`, // 1px border consider
    }),

    dropdownIndicator: () => ({
      display: 'none',
    }),

    menu: (base) => ({
      ...base,
      minWidth: '200px',
    }),
  } as StylesConfig<TextOptionType, false, GroupBase<TextOptionType>>,
};
