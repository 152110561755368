/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  type ID,
  type ActualPeriod,
  type Granularity,
  type NewPeriod,
  type Period,
  type PeriodData,
  type PlanningStatus,
} from 'data';
import { type VisualQueryFilterRule } from 'data/big-query';
import { type Currency } from 'data/currencies';
import { type DimSpace, type Dimension, type DimensionQueryParamsObject } from 'data/dimension';
import { type MetricSourceType } from 'data/metric-builder/types';
import { type DimensionAggregationTypes, type TimeAggregationTypes } from 'data/modelling/metric';
import { type PageTemplate } from 'data/page-template';
import { type CellFormatRules, type ColGroupData } from 'data/page-template/grid';
import { type Pagination } from 'data/pagination';
import { type AccessAction } from 'data/roles/permissions';
import { type Dayjs } from 'dayjs';
import {
  type MetricDirection,
  type BlankValueType,
  type DataFormattingType,
  type DisplayUnitType,
  type NegativeValueType,
  type ZeroFormatType,
} from 'utils/data-formatter/types';
import { type HierarchyClanNode } from 'utils/tree-table';
import { type Method } from './constants';

export enum MetricTreeNodeTypes {
  Metric = 'METRIC',
  Dimension = 'DIMENSION',
  Section = 'SECTION',
  SectionMetric = 'SECTION_METRIC',
  Blank = 'BLANK',
  TempRow = 'TEMP-ROW',
}

export interface BaseMetric extends Required<Omit<ColGroupData, 'planned'>> {
  currency: Currency;
  dimensions: Dimension[];
  format: CellFormatRules;
  granularity: Granularity[] | Granularity;
  viewNodeType: MetricTreeNodeTypes;
  timeAggregation?: TimeAggregationTypes;
  dimensionAggregation?: DimensionAggregationTypes;
  name: string;
  description?: string;
  displayName: string;
  periods: Period[];
  planPeriods: NewPeriod[];
  type: DataFormattingType;
  summaryFormula?: string;
  formula?: string; // autocompute formula
  computeType?: ComputeType;
  dataFormat?: DataFormatType;
  dual?: string | null;
  categoryId?: ID | null;
}

type MetricDRI = {
  dri: boolean;
  source: MetricUpdateSource;
};

export interface DataFormatType {
  displayUnit?: DisplayUnitType;
  digitsAfterDecimal?: number;
  zeroFormat?: ZeroFormatType;
  negativeValueFormat?: NegativeValueType;
  blankValueFormat?: BlankValueType;
  currency?: Currency;
  direction?: MetricDirection;
}

export interface Metric extends BaseMetric {
  pivotData: Metric[];
  impactedBy: Metric[];
  impacting: Metric[];
  metricDri: MetricDRI;
  value: string | null;
  displayValue: string | null;
  chosenDimensions?: Dimension[];
  data: PeriodData;
  headLineValue: number | null; // simple explanation, for the time-range choosen what is the aggregated actual value for metric
  lastUpdatedAt?: string;
  template: PageTemplate;
  metricGranularity: Granularity;
  isFormulaLeaf: boolean;
  isDataLeaf: boolean;
  sources: string[];
  planName: string | null;
  categoryId?: number;
  applicableDimensions?: string[];
  isLeafEditable?: boolean;
  planEntryEndDate?: Dayjs;
  planEntryStartDate?: Dayjs;
}

export enum MetricUpdateSource {
  DTQL = 'DTQL',
  ManualUpload = 'MANUAL_UPLOAD',
  Spreadsheet = 'Spreadsheet',
}

export interface BaseMetricHistoryContent {
  name: string;
  source: MetricUpdateSource;
  t: string;
  value: string | number;
  period: ActualPeriod;
}
export interface MetricHistoryContent extends BaseMetricHistoryContent {
  approverId: number | null;
  createdAt: string;
  id: number;
  message: string | null;
  attributes: {
    [key: string]: string;
  };
  type: DataFormattingType;
  updatedAt: string;
  updatedBy: string | null;
}

export interface MetricWithHierarchy extends Metric {
  hierarchy: string[];
  hierarchyClan: HierarchyClanNode[];
  isLeaf: boolean;
  combineColumnHeaderFromIndex?: number; //for the row show dim headings from index
  metricInHierarchy?: string;
  rowDimensions: Record<string, string | null>;
}

export interface HierarchialMetricHistoryWithData extends MetricWithHierarchy {
  hierarchyMeta: DimensionQueryParamsObject;
  lastUpdatedValue?: string | number;
  lastUpdatedAt?: string;
  newValue?: NewValue;
  period: ActualPeriod;
}

interface NewValue {
  [value: string]: ActualValue;
}

export interface ActualValue {
  date: string;
  Actual: string;
  periodBreakupType: ActualPeriod;
}

export interface MetricHistory extends Pagination {
  content: MetricHistoryContent[];
}

export interface MetricHistoryContentWithDimensions extends BaseMetricHistoryContent {
  dimensions: {
    name: string;
    value: string | null;
  }[];
}

export interface MetricDimensionRequest {
  dimension: string;
  dimensionName?: string;
  fullName?: string;
  aggregation: DimensionAggregationTypes | TimeAggregationTypes;
  position?: number;
  planningStatus: PlanningStatus;
  dimSpace?: DimSpace;
}

export interface MetricDefinition extends BaseMetric {
  timeSummary: TimeAggregationTypes;
  dimensionSummary: DimensionAggregationTypes;
  wavgOnMetric?: string;
  wavgOnMetricDisplayName?: string;
  dual?: string | null;
  filters?: VisualQueryFilterRule[];
}

export type MetricDefinitionRequest = Omit<MetricDefinition, 'dimensions' | 'dataFormat'> & {
  dimensionSummary: DimensionAggregationTypes;
  timeSummary: TimeAggregationTypes;
  dataFormat: DataFormatType;
  metricSource: MetricSourceType;
  domain: MetricDomain;
  attributes?: {
    startDate?: string;
    endDate?: string;
    dateOption?: string;
    granularity?: string[];
  };
  isTracked?: boolean;
  accessAction?: AccessAction;
};

export enum ComputeType {
  Default = 'DEFAULT',
  AutoCompute = 'AUTO_COMPUTE',
}

export interface MetricsMetaResponseData
  extends Pick<
    Metric,
    'name' | 'displayName' | 'granularity' | 'timeAggregation' | 'dimensionAggregation'
  > {
  planId: string;
  actualSource: Method | null;
  createdAt: string;
  updatedAt: string;
  backfillable: boolean;
  backfillableFrom: string | null;
  backfillEnabled: boolean;
}

export interface MetricInfoType {
  description?: string;
  lastUpdatedAt?: string;
  sources?: string[];
}

export enum MetricDomain {
  Planning = 'PLANNING',
  Monitoring = 'MONITORING',
}

export enum MetricSortType {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE',
}

export interface MetricSortConfig {
  row: string;
  sortType: MetricSortType;
  column: string;
}

export type DataFormatWithType = DataFormatType & { type: DataFormattingType };
