import { type CSVListPreviewData } from 'data/lists';
import { createContext } from 'react';

export interface PreviewTableContextParams {
  colTypeMap?: CSVListPreviewData['columnTypeMap'];
  dateFormatMap?: CSVListPreviewData['dateFormatMap'];
  isColumnTypeEditMode: boolean;
  updatePropertyTypeMap: (
    columnTypeMap: CSVListPreviewData['columnTypeMap'],
    dateFormatMap?: CSVListPreviewData['dateFormatMap'],
  ) => void;
}

export const PreviewTableContext = createContext<PreviewTableContextParams>(
  {} as PreviewTableContextParams,
);
