import { type ArgsProps } from 'antd/lib/notification';

export type NotifierActions = {
  primaryLabel: string;
  primaryAction: () => void;
};

export interface NotifierProps extends ArgsProps {
  actions?: NotifierActions;
}

export enum NotificationType {
  OK = 0,
  WARN = 1,
  ERR = 2,
  INFO = 3,
  SPINNER = 4,
}
