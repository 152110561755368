import { type PlanningStatus } from 'data';
import type React from 'react';

export enum DimSpace {
  Plan = 'PLAN',
  View = 'VIEW',
}
export interface Dimension {
  name: string;
  value: string;
  displayValue: string;
  displayName: string;
  displayNamePlural?: string;
  fullName: string;
  tableDisplayName: string;
  icon?: string;
  children?: Dimension[];
  treePath: string[];
  allowed: boolean;
  position?: number;
  planningStatus?: PlanningStatus;
  subDimensionConfig?: SubDimensionConfig;
  hideRoot: boolean; // Sent as true for root value for dimensions where All is to be hidden(e.g Scenario). False for other cases.
  dataSourceConfig?: DataSourceConfig;
  dimSpace?: DimSpace;
  isUniqueKey?: boolean;
  isLoading?: boolean; // for FE use only
  timeSelectorType?: TimeDimensionType | null;
}

export interface DimensionProperty {
  displayName: string;
  name: string;
  type: DimensionPropertyType;
  value?: string;
  displayValue?: string;
  isValid?: boolean;
  usedInSubDimension?: boolean;
  fqName?: string;
  tableDisplayName?: string;
}

export interface MetricApplicableDimension {
  name: string;
  hideRoot: boolean;
  dimSpace?: DimSpace;
  allowed: boolean;
}

export interface MetricApplicableDimensionValue {
  name: string;
  value: string;
  hideRoot: boolean;
  allowed: boolean;
}

export interface SubDimensionConfig {
  superDimensionName: string;
  superDimensionId: number;
  filters: SubDimensionFilter[];
}

export interface SubDimensionFilter {
  lhs: string;
  operation: FilterOperation;
  rhs: string[];
}

export enum FilterOperation {
  Equals = 'EQUALS',
  In = 'IN',
}

export type Validatable = {
  isValid: boolean;
  value: string | undefined;
  label?: string;
};

export enum DimensionPropertyType {
  Number = 'NUMBER',
  Currency = 'CURRENCY',
  Date = 'TIME_PERIOD',
  String = 'STRING',
  Percentage = 'PERCENTAGE',
}

type DimensionValue = string;
export interface DimensionQueryParamsObject {
  [DimensionName: string]: DimensionValue[];
}

export interface DataSourceConfig {
  dataSourceQuery: string;
  lastRefreshTime: string;
}

export interface CSVPreviewRowItem {
  values: {
    [key: string]: string;
  };
  errors?: {
    [key: string]: {
      errorMessages: string[];
    };
  };
  rowId?: number;
}

export interface CSVPreviewData {
  rows?: CSVPreviewRowItem[];
  propertyTypeMap: { [key: string]: DimensionPropertyType };
}

export enum CSVUploadWorkflowStage {
  Default = 'DEFAULT',
  ConfirmUpload = 'CONFIRMATION',
  DimensionColumnSelection = 'DIMENSION_COLUMN_SELECTION',
  ColumnMapping = 'COLUMN_MAPPING',
  Preview = 'PREVIEW',
  PreSave = 'PRESAVE',
}

export type CSVColumnMappingItem = {
  propertyName: string;
  csvColumnIndex: number;
};

export interface DimHeaderItem extends DimensionProperty {
  key: React.Key;
}

export type CSVColumnSelection = {
  [key: string]: number;
};

export enum TimeDimensionType {
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
}
