import { defaultTheme } from 'styles/theme';

export const RECENT_COLORS_MAX_COUNT = 5;
export const RECENT_COLORS_STORAGE_KEY = 'recent-colors-selection';

const themeColors = defaultTheme.colors;

export const standardColors = [
  [
    themeColors.primary50,
    themeColors.magenta100,
    themeColors.blue100,
    themeColors.aqua100,
    themeColors.green100,
    themeColors.pink100,
    themeColors.yellow100,
    themeColors.orange100,
    themeColors.red100,
  ],
  [
    themeColors.primary100,
    themeColors.magenta200,
    themeColors.blue200,
    themeColors.aqua200,
    themeColors.green200,
    themeColors.pink200,
    themeColors.yellow200,
    themeColors.orange200,
    themeColors.red200,
  ],
  [
    themeColors.primary200,
    themeColors.magenta300,
    themeColors.blue300,
    themeColors.aqua300,
    themeColors.green300,
    themeColors.pink300,
    themeColors.yellow300,
    themeColors.orange300,
    themeColors.red300,
  ],
  [
    themeColors.primary300,
    themeColors.magenta400,
    themeColors.blue400,
    themeColors.aqua400,
    themeColors.green400,
    themeColors.pink400,
    themeColors.yellow400,
    themeColors.orange400,
    themeColors.red400,
  ],
  [
    themeColors.primary400,
    themeColors.magenta500,
    themeColors.blue500,
    themeColors.aqua500,
    themeColors.green500,
    themeColors.pink500,
    themeColors.yellow500,
    themeColors.orange500,
    themeColors.red500,
  ],
  [
    themeColors.primary500,
    themeColors.magenta600,
    themeColors.blue600,
    themeColors.aqua600,
    themeColors.green600,
    themeColors.pink600,
    themeColors.yellow600,
    themeColors.orange600,
    themeColors.red600,
  ],
  [
    themeColors.primary600,
    themeColors.magenta700,
    themeColors.blue700,
    themeColors.aqua700,
    themeColors.green700,
    themeColors.pink700,
    themeColors.yellow700,
    themeColors.orange700,
    themeColors.red700,
  ],
];

export const grayShades = [
  defaultTheme.colors.gray00,
  defaultTheme.colors.gray200,
  defaultTheme.colors.gray300,
  defaultTheme.colors.gray400,
  defaultTheme.colors.gray500,
  defaultTheme.colors.gray600,
  defaultTheme.colors.gray700,
];
