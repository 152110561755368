import { Drawer as AntdDrawer } from 'antd';
import styled from 'styled-components';
import { FontL } from 'styles/typography';

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-close {
    height: ${({ theme }) => theme.spacing[24]};
    position: absolute;
    top: 24px;
    right: 0;
  }

  .ant-drawer-header {
    padding: ${({ theme: { spacing } }) => `${spacing[24]} ${spacing[16]} ${spacing[16]}`};
  }

  .ant-drawer-title {
    ${FontL};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const IconContainer = styled.span`
  display: grid;
  align-content: center;
`;
