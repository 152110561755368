import { type ReactElement, type ReactNode, createContext, useRef } from 'react';
import { createListsStore, type ListsStore } from '../store';

export const ListStoreContext = createContext<ListsStore | null>(null);

export const ListsStoreProvider = ({ children }: { children?: ReactNode }): ReactElement => {
  const storeRef = useRef<ListsStore>();

  if (!storeRef.current) {
    storeRef.current = createListsStore();
  }

  return <ListStoreContext.Provider value={storeRef.current}>{children}</ListStoreContext.Provider>;
};
