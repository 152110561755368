import styled from 'styled-components';

export const styles = {
  PopoverContentWrapper: styled.div`
    .metric-info:last-child {
      border-bottom: none;
    }

    .metric-info:last-child > :last-child {
      border-radius: ${({ theme: { borderRadius } }) => `0 0 ${borderRadius.m} ${borderRadius.m}`};
    }
  `,
};
