import { type ModalProps as AntdModalProps, Modal as ModalAtom } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { type PropsWithChildren, type ReactNode } from 'react';
import { IconShell } from '../icon-shell';
import { ModalFooter } from './footer';

const { confirm } = ModalAtom;

export type ModalProps = {
  footerExtra?: React.ReactNode;
  hideCancelButton?: boolean;
  hideFooter?: boolean;
  titleIcon?: ReactNode;
} & Omit<AntdModalProps, 'closeIcon' | 'footer'>;

export const Modal = ({
  cancelButtonProps,
  cancelText = '',
  confirmLoading,
  okButtonProps,
  okText = '',
  onOk,
  footerExtra,
  hideCancelButton,
  hideFooter,
  title,
  titleIcon,
  ...props
}: PropsWithChildren<ModalProps>): React.ReactElement => {
  return (
    <ModalAtom
      closeIcon={<IconShell color="iconDefault" icon={CloseIcon} size="lg" />}
      destroyOnClose
      footer={
        !hideFooter &&
        (cancelText || okText || footerExtra) && (
          <ModalFooter
            cancelButtonProps={cancelButtonProps}
            cancelText={cancelText}
            confirmLoading={confirmLoading}
            footerExtra={footerExtra}
            hideCancelButton={hideCancelButton}
            okButtonProps={okButtonProps}
            okText={okText}
            onCancel={props.onCancel}
            onOk={onOk}
          />
        )
      }
      title={
        titleIcon || title ? (
          <>
            {titleIcon}
            {title}
          </>
        ) : undefined
      }
      {...props}
    />
  );
};

Modal.confirm = confirm;
