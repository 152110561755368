import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { getCallbackUrl, getReauthState, getState } from '../util';

export const salesforceLogin = ({
  item,
  instanceId,
  isSandboxTenant,
}: {
  item: IntegrationModalContent;
  instanceId?: number;
  isSandboxTenant?: boolean;
}): void => {
  const { clientId, id, loginUrl } = item;
  const state = instanceId ? getReauthState(id, instanceId) : getState(id);

  window.open(
    `${
      isSandboxTenant ? 'https://test.salesforce.com' : loginUrl
    }/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${getCallbackUrl()}&scope=api+refresh_token&access_type=offline&state=${state}`,
    '_self',
  );
};
