import { ListUpdateType } from 'data/modelling/lists';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const getTitleText = ({
  action,
  entityColumnName,
  isAutoReconAndShowUpdate,
  isRecommendation,
}: {
  action: ListUpdateType;
  entityColumnName: string;
  isAutoReconAndShowUpdate: boolean;
  isRecommendation: boolean;
}): ReactNode => {
  if (isAutoReconAndShowUpdate) {
    return getAutoReconText({ action });
  }

  if (isRecommendation) {
    return <FormattedMessage id="lists.reconciliation.recommendation.title" />;
  }

  if (action === ListUpdateType.Terminate) {
    return <FormattedMessage id="lists.reconciliation.terminate.title" />;
  }

  if (action === ListUpdateType.Delete) {
    return <FormattedMessage id="lists.reconciliation.delete.title" />;
  }

  if (action === ListUpdateType.Update) {
    return <FormattedMessage id="lists.reconciliation.update.title" />;
  }

  return <FormattedMessage id="lists.reconciliation.add.title" />;
};

const getAutoReconText = ({ action }: { action: ListUpdateType }) => {
  if (action === ListUpdateType.Delete) {
    return <FormattedMessage id="lists.reconciliation.delete.auto.title" />;
  }

  if (action === ListUpdateType.Update) {
    return <FormattedMessage id="lists.reconciliation.update.auto.title" />;
  }

  return <FormattedMessage id="lists.reconciliation.add.auto.title" />;
};
