import { ReactComponent as AlertIcon } from 'assets/v2/alert-triangle.svg';
import { ReactComponent as TickIcon } from 'assets/v2/circle-check-filled.svg';
import { ReactComponent as CrossIcon } from 'assets/v2/circle-x-filled.svg';
import { IconShell, IconText, Modal } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontS } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const MessageInfo = styled.div`
  ${FontS}

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const PreSaveModal: FC<
  React.PropsWithChildren<{
    visible: boolean;
    savableCells: number;
    errorCells: number;
    onSave: () => void;
    onBack: () => void;
  }>
> = ({ savableCells, errorCells, visible, onSave, onBack }) => {
  const intl = useIntl();

  return (
    <Modal
      cancelText={intl.formatMessage({
        id: 'lists.bulk_paste_rows.warning_modal.action_2',
      })}
      centered
      destroyOnClose
      okText={intl.formatMessage({
        id: 'lists.bulk_paste_rows.warning_modal.action_1',
      })}
      open={visible}
      title={
        <IconText color="yellow600" icon={AlertIcon} text={intl.formatMessage({ id: 'warning' })} />
      }
      width={560}
      onCancel={(e) => {
        const target = e.target as HTMLButtonElement;

        if (
          target.innerText &&
          target.innerText ===
            intl.formatMessage({
              id: 'lists.bulk_paste_rows.warning_modal.action_2',
            })
        ) {
          onSave();
        } else {
          onBack();
        }
      }}
      onOk={onBack}
    >
      <Wrapper>
        <MessageInfo>
          <FormattedMessage id="lists.bulk_paste_rows.warning_modal.content_text" />
        </MessageInfo>
        <MessageInfo>
          <IconShell color="green500" icon={TickIcon} />
          <FormattedMessage
            id="lists.bulk_paste_rows.warning_modal.savable_rows_text"
            values={{ count: savableCells }}
          />
        </MessageInfo>

        {errorCells > 0 && (
          <MessageInfo>
            <IconShell color="red500" icon={CrossIcon} />
            <FormattedMessage
              id="lists.bulk_paste_rows.warning_modal.error_rows_text"
              values={{ count: errorCells }}
            />
          </MessageInfo>
        )}
      </Wrapper>
    </Modal>
  );
};
