import styled, { css } from 'styled-components';

const CommonCss = css`
  ${({ theme }) => {
    return `font-size: ${theme.fontSize.caption}; color: ${theme.colors.textTertiary};`;
  }}
`;

export const CommonStyles = {
  Title: styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    ${CommonCss};
  `,

  SubTitle: styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    ${CommonCss};
  `,

  ItemWrapper: styled.div`
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing[8]};
    padding-bottom: ${({ theme }) => theme.spacing[8]};
  `,
};
