import { Radio } from 'components/ui/atomic-components/radio';
import { isEmpty } from 'lodash';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { type PeriodOptionType } from './types';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Value = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textPlaceholder};
`;

export const MenuLabel = ({
  option,
  isSelected,
  selectedValue,
}: {
  option: PeriodOptionType;
  isSelected?: boolean;
  selectedValue?: string;
}): ReactElement => {
  const periodValue = selectedValue?.split('=')[1];
  const selectedOption = option.children?.find((option) => option.value === periodValue);

  return (
    <Wrapper>
      <Wrapper>
        {isEmpty(option.children) && <Radio checked={isSelected} size="small" />}
        <div>{option.label}</div>
      </Wrapper>
      <Value>{selectedOption && selectedOption.label}</Value>
    </Wrapper>
  );
};
