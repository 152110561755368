import { type ColDef, type GridApi, type IRowNode } from 'ag-grid-community';
import { type ModelDimension } from 'data/modelling/dimension';
import { createContext } from 'react';
import { type DimensionGridData } from '../_commons';

export interface GridContext {
  gridApi: GridApi;
  colDefs: ColDef[];
  rowData: DimensionGridData[];
  onAddRow(gridApi: GridApi, dimension: ModelDimension): Promise<DimensionGridData[]>;
  onLoseFocus(node: IRowNode): void;
  onGridInit(gridApi: GridApi): void;
}

export interface ActiveDimension {
  activeDimensionId: number;
  setActiveDimensionId: (dimensionId: number) => void;
}

export const DimensionContext = createContext<GridContext>({} as GridContext);
export const ActiveDimensionContext = createContext<ActiveDimension>({} as ActiveDimension);
