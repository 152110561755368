import { type ICellRendererParams } from 'ag-grid-community';
import { ReactComponent as ErrorIcon } from 'assets/v2/exclamation-circle.svg';
import { IconShell, Tooltip } from 'components/ui/atomic-components';
import { QueryTableColumnDataType } from 'data/big-query';
import { type ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { usePreviewTableContext } from '../context';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const ValueItem = styled.span<{ isRightAligned: boolean }>`
  ${FontS};

  flex: 1;
  display: flex;
  justify-content: ${({ isRightAligned }) => (isRightAligned ? 'flex-end' : 'flex-start')};
`;

interface Props extends ICellRendererParams {
  colName: string;
}

export const PreviewCellItem = ({ data, valueFormatted, colName }: Props): ReactElement => {
  const errorInfo = useMemo(() => {
    return data?.errors?.[colName];
  }, [data, colName]);

  const { colTypeMap } = usePreviewTableContext();

  const isRightAligned = [QueryTableColumnDataType.Date, QueryTableColumnDataType.Numeric].includes(
    colTypeMap?.[colName] as QueryTableColumnDataType,
  );

  return (
    <Wrapper>
      <ValueItem isRightAligned={isRightAligned}>{valueFormatted}</ValueItem>

      {errorInfo && (
        <Tooltip title={(errorInfo?.errorMessages || []).join('. ')}>
          <IconShell color="red500" icon={ErrorIcon} />
        </Tooltip>
      )}
    </Wrapper>
  );
};
