import { type InputProps as AntInputProps, Input as AntInput, type InputRef } from 'antd';
import { type TextAreaProps, type TextAreaRef } from 'antd/es/input/TextArea';
import { type ForwardRefExoticComponent, type RefAttributes } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import { type StyledMarginsProps } from 'styles/styled-atoms';
import { inputStyles, textAreaStyles } from './styles';

export type InputProps = Omit<AntInputProps, 'size'> & {
  size?: 'extraSmall' | 'small' | 'medium';
  $inputType?: 'outline' | 'ghost' | 'ghostText';
} & RefAttributes<InputRef> &
  StyledMarginsProps;

type CompoundedComponent = ForwardRefExoticComponent<InputProps & RefAttributes<InputRef>> & {
  Group: typeof AntInput.Group;
  Search: typeof AntInput.Search;
  TextArea: typeof AntInput.TextArea;
  Password: typeof AntInput.Password;
};

export const Input = styled(AntInput)<InputProps>`
  ${({ theme }: { theme: DefaultTheme } & InputProps) => inputStyles({ theme })};
` as unknown as CompoundedComponent;

type TextAreaType = ForwardRefExoticComponent<TextAreaProps & RefAttributes<TextAreaRef>> &
  StyledMarginsProps;
const TextAreaInput: TextAreaType = styled(AntInput.TextArea)<TextAreaType>`
  ${({ theme, ...props }: { theme: DefaultTheme } & TextAreaType) =>
    textAreaStyles({ theme, ...props })};
`;

Input.TextArea = TextAreaInput;
