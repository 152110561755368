import { ReactComponent as LayoutSidebarIcon } from 'assets/v2/layout-sidebar.svg';
import { IconShell } from 'components/ui/atomic-components';
import { isEmpty } from 'lodash';
import { type ReactElement, type ReactNode } from 'react';
import { useListStoreContext } from 'store/lists';
import { useUser } from 'utils/hooks';
import { DerivedListDescription } from './derived-list-description';
import { Description } from './description';
import { ErrorPopover } from './error-popover';
import { MutationSpinner } from './mutation-spinner';
import { styles } from './styles';
import { InlineTitleInput } from './title';

const { Wrapper, Title, ActionButtonsSection, BoundingBox, TitleSpinner, TitleDescWrapper } =
  styles;

interface Props {
  listName: string;
  toggleShowLeftNav: () => void;
  showLeftNav: boolean;
  rightItems: ReactNode;
  isDerivedList?: boolean;
  isReadOnly?: boolean;
}

export const GridTopBar = ({
  listName,
  toggleShowLeftNav,
  showLeftNav,
  rightItems,
  isDerivedList,
  isReadOnly,
}: Props): ReactElement => {
  const { isDtUser } = useUser();

  const errors = useListStoreContext((state) => state.errors);

  return (
    <Wrapper>
      <Title>
        <BoundingBox $isLeftNavActive={showLeftNav}>
          <IconShell
            className="left-nav-toggle-button"
            color="iconDefault"
            cursor="pointer"
            icon={LayoutSidebarIcon}
            onClick={toggleShowLeftNav}
          />
        </BoundingBox>
        <TitleDescWrapper>
          <TitleSpinner>
            <InlineTitleInput isReadOnly={isReadOnly} name={listName} />
            <MutationSpinner />
            {!isEmpty(errors) && isDtUser && <ErrorPopover errors={errors} />}
          </TitleSpinner>
          {isDerivedList ? (
            <DerivedListDescription isReadOnly={isReadOnly} />
          ) : (
            <Description isReadOnly={isReadOnly} />
          )}
        </TitleDescWrapper>
      </Title>

      <ActionButtonsSection>{rightItems}</ActionButtonsSection>
    </Wrapper>
  );
};
