import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { Button } from 'components/ui/atomic-components/button';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export const BackButton = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Button
      icon={<IconShell color="iconDefault" icon={ChevronLeftIcon} />}
      type="text"
      onClick={() => navigate('/models')}
    />
  );
};
