import { NegativeValueType } from '../types';

export const formattersForNegativeType: {
  [key in NegativeValueType | 'default']: (formattedDataWithoutMinusSign: string) => string;
} = {
  [NegativeValueType.Brackets]: (formattedDataWithoutMinusSign: string): string =>
    `(${formattedDataWithoutMinusSign})`,

  [NegativeValueType.Sign]: (formattedDataWithoutMinusSign: string): string =>
    `-${formattedDataWithoutMinusSign}`,

  default: (formattedDataWithoutMinusSign: string): string =>
    formattersForNegativeType[NegativeValueType.Sign](formattedDataWithoutMinusSign),
};
