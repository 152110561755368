import { datadogRum } from '@datadog/browser-rum';
import { DEPLOY_ENV } from 'config/environment';
import { routes } from 'pages/auth-routes/routes';
import { useEffect } from 'react';
import { matchRoutes, useLocation, type RouteObject } from 'react-router-dom';

const isProd = DEPLOY_ENV === 'production';

export const useDataDogMonitoring = (): void => {
  const location = useLocation();

  // Compute view name out of routeMatches
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const computeViewName = (routeMatches: any) => {
    let viewName = '';

    for (let index = 0; index < routeMatches.length; index += 1) {
      const routeMatch = routeMatches[index];
      const { path } = routeMatch.route;

      // Skip pathless routes
      if (!path) {
        continue;
      }

      if (path.startsWith('/')) {
        // Handle absolute child route paths
        viewName = path;
      } else {
        // Handle route paths ending with "/"
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }

    return viewName || '/';
  };

  useEffect(() => {
    if (!isProd) {
      return;
    }

    const routeMatches = matchRoutes(routes as RouteObject[], location.pathname);
    const viewName = routeMatches && computeViewName(routeMatches);

    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);
};
