import data from '@emoji-mart/data/sets/15/native.json';
import { init } from 'emoji-mart';
import { type ReactElement } from 'react';

// https://github.com/missive/emoji-mart/issues/607
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'em-emoji': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        id?: string;
        shortcodes?: string;
        native?: string;
        size?: string | number;
        fallback?: string;
        set?: 'native' | 'apple' | 'facebook' | 'google' | 'twitter';
        skin?: string | number;
      };
    }
  }
}

init({ data });

interface Props {
  id: string;
  size: string;
}

export const EmojiRenderer = ({ id, size }: Props): ReactElement => {
  return <em-emoji id={id} set="native" size={size}></em-emoji>;
};
