import { type RowDragEndEvent } from 'ag-grid-community';
import { type ListRow } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';
import { type ListGridRow } from '../../types';
import { type UndoStackItem } from '../../undo-redo/types';
import { useCreateDeleteMutations } from './use-create-delete-mutations';
import { useReorderRow } from './use-row-reorder';
import { useRunRowUpdatesAfterCreate } from './use-run-row-updates-after-create';
import { getUpdatedRowData } from './utils';

interface ReturnProps {
  onSaveListEntry: (o: { rowNode: ListGridRow; undoContext: UndoStackItem }) => Promise<void>;
  onDeleteListRows: (rowNode: ListGridRow[]) => void;
  onRowDragEnd: (params: RowDragEndEvent) => Promise<void>;
}

export const useGridCrud = (): ReturnProps => {
  const activeListId = useListStoreContext((s) => s.id);
  const removeRows = useListStoreContext((s) => s.removeRows);
  const updateRow = useListStoreContext((s) => s.updateRow);
  const pushToUndoRedoStack = useListStoreContext((s) => s.pushToUndoRedoStack);
  const maskedColumns = useListStoreContext((s) => s.maskedColumns);

  const { onRowDragEnd } = useReorderRow();

  const { onCreateRow, onUpdateRowEntry, onDeleteRow } = useCreateDeleteMutations();

  const {
    onNewRowCreateStart,
    onNewRowCreateEnd,
    checkIfRowBeingCreated,
    runAfterRowCreated,
    isLastTaskOfRow,
    onRowUpdateStart,
  } = useRunRowUpdatesAfterCreate();

  const onSaveListEntry = async ({
    rowNode,
    undoContext,
  }: {
    rowNode: ListGridRow;
    undoContext: UndoStackItem;
  }) => {
    pushToUndoRedoStack(undoContext);

    const data = {
      id: rowNode.backendId,
      listId: activeListId,
      data: rowNode.data,
      position: rowNode.position,
    } as ListRow;

    // create row case
    if (!rowNode.backendId && !checkIfRowBeingCreated(rowNode.id)) {
      const currentActionTaskId = onNewRowCreateStart(rowNode.id);

      onCreateRow
        .mutateAsync(data)
        .then((responseData) => {
          onNewRowCreateEnd({ success: true, rowId: rowNode.id, backendId: responseData.id });

          if (isLastTaskOfRow(rowNode.id, currentActionTaskId)) {
            updateRow({
              ...rowNode,
              data: {
                ...responseData.data,
              },
              position: responseData.position,
              backendId: responseData.id,
              isError: false,
            });
          }
        })
        .catch(() => {
          onNewRowCreateEnd({ success: false, rowId: rowNode.id });

          if (isLastTaskOfRow(rowNode.id, currentActionTaskId)) {
            updateRow({
              ...rowNode,
              isError: true,
            });
          }
        });
    } else {
      // update row case
      const currentActionTaskId = onRowUpdateStart(rowNode.id);

      runAfterRowCreated(rowNode.id, (rowBackendId?: number) => {
        return onUpdateRowEntry
          .mutateAsync({
            ...data,
            data: getUpdatedRowData(data.data, maskedColumns),
            id: rowBackendId || data.id,
          })
          .then((responseData) => {
            if (isLastTaskOfRow(rowNode.id, currentActionTaskId)) {
              updateRow({
                ...rowNode,
                data: {
                  ...responseData.data,
                },

                backendId: rowBackendId || data.id,
                isError: false,
              });
            }
          })
          .catch(() => {
            if (isLastTaskOfRow(rowNode.id, currentActionTaskId)) {
              updateRow({
                ...rowNode,
                isError: true,
              });
            }
          });
      });
    }
  };

  const onDeleteListRows = (rowNode: ListGridRow[]) => {
    removeRows(rowNode.map((r) => r.id));
    const rowsWithBackendId: number[] = [];

    rowNode.forEach((row) => {
      if (checkIfRowBeingCreated(row.id)) {
        runAfterRowCreated(row.id, (rowBackendId?: number) =>
          onDeleteRow.mutateAsync([rowBackendId] as number[]),
        );
      } else if (row.backendId) {
        rowsWithBackendId.push(row.backendId);
      }
    });

    if (rowsWithBackendId.length) {
      onDeleteRow.mutateAsync(rowsWithBackendId);
    }
  };

  return { onSaveListEntry, onDeleteListRows, onRowDragEnd };
};
