import { css } from 'styled-components';

export const CellEditorPopupBaseStyles = css`
  ${({ $width }: { $width: number }) => {
    return css`
      width: ${$width + 2}px;
      height: 34px;
      border: 1.5px solid ${({ theme }) => theme.colors.green500};
      border-radius: 2px;
      box-shadow: ${({ theme }) => theme.shadow.hardMicro};
      top: -1px;
      left: -1px;

      :hover,
      :active,
      :focus,
      :focus-within {
        border-color: ${({ theme }) => theme.colors.green500};
        box-shadow: ${({ theme }) => theme.shadow.hardMicro};
      }
    `;
  }}
`;
