import { Divider, Modal, NumberStepper, Segmented } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontL, FontXs, FontXxs } from 'styles/typography';

export const styles = {
  StyledModal: styled(Modal)`
    .ant-modal-content .ant-modal-body {
      padding: ${({ theme }) => theme.spacing[0]};
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Caption: styled.div``,
  Unit: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: ${({ theme: { spacing } }) => `0 ${spacing[16]}`};
  `,
  UnitLabel: styled.div`
    ${FontXs};

    color: ${({ theme }) => theme.colors.gray700};
  `,
  SampleSection: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 79px;
    padding: ${({ theme: { spacing } }) => `${spacing[16]}`};
    background-color: ${({ theme }) => theme.colors.gray50};
  `,
  SampleHeader: styled.div`
    ${FontXxs};

    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.textDisabled};
  `,
  SampleBody: styled.div`
    ${FontL};

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.colors.textLabel};
  `,
  SampleNumber: styled.div`
    width: 140px;
  `,
  DisplayedAsNumber: styled.div`
    width: 160px;
    text-align: right;
  `,
  UnitsSection: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[16]};
    padding-top: ${({ theme }) => theme.spacing[16]};
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  `,
  MetricTypeText: styled.div`
    ${FontXs};

    color: ${({ theme }) => theme.colors.textDisabled};
    display: flex;
    flex-flow: row;
    align-items: center;
  `,
  StyledDivider: styled(Divider)`
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  `,

  StyledSegmented: styled(Segmented)`
    ${FontXs};

    .ant-segmented-item {
      width: 65px;
    }
  `,

  StyledStepper: styled(NumberStepper)`
    width: 130px;

    .ant-input-number {
      display: flex;
      justify-content: center;
    }
  `,
};
