import { Compartment, EditorState } from '@codemirror/state';
import { EditorView, placeholder } from '@codemirror/view';
import { type MetricDomainName } from 'data/metrics/constants';
import { useRef, useEffect, type ReactElement } from 'react';
import {
  dtmlLanguage,
  syntaxHighlighting,
  closeBrackets,
  expressionConstructs,
} from './extensions';
import { useFormulaEditorMetrics } from './hooks/use-formula-editor-metrics';
import styles from './styles';

const { StyledEditor } = styles;

const expressionConstructsCompartment = new Compartment();

interface Props {
  value?: string;
  grayOut?: boolean;
  domain?: MetricDomainName;
  setEditorView?: (editorView: EditorView | null) => void;
}

export const ReadOnlyFormula = ({
  value,
  grayOut,
  domain = 'planning',
  setEditorView,
}: Props): ReactElement => {
  const editorDomRef = useRef(null);
  const editorViewRef = useRef<EditorView>();

  const { data: metrics, isLoading } = useFormulaEditorMetrics(domain);

  useEffect(() => {
    if (editorDomRef.current === null || isLoading) {
      return;
    }

    const startState = EditorState.create({
      doc: value,
      extensions: [
        dtmlLanguage,
        syntaxHighlighting,
        closeBrackets,
        expressionConstructsCompartment.of(
          expressionConstructs({
            metrics,
            readOnly: true,
          }),
        ),
        placeholder(''),
        EditorState.readOnly.of(true),
      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editorDomRef.current,
    });

    setEditorView?.(view);
    editorViewRef.current = view;

    return () => {
      view.destroy();
      setEditorView?.(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, value]);

  useEffect(() => {
    editorViewRef.current?.dispatch({
      effects: expressionConstructsCompartment.reconfigure(
        expressionConstructs({
          metrics,
          readOnly: true,
        }),
      ),
    });
  }, [domain, metrics]);

  if (isLoading) {
    return <></>;
  }

  return <StyledEditor ref={editorDomRef} grayOut={grayOut} />;
};
