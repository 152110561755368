import { type GroupBase, type StylesConfig } from 'react-select';
import { defaultTheme } from 'styles/theme';
import { getCommonStyles } from './common';

export const getLargeSizeStyles = <T, IsMulti extends boolean = boolean>(): StylesConfig<
  T,
  IsMulti,
  GroupBase<T>
> => {
  const CommonStyles = getCommonStyles<T, IsMulti>();

  return {
    ...CommonStyles,

    control: (base, props) => ({
      ...CommonStyles.control?.(base, props),
      borderRadius: defaultTheme.borderRadius.s,
      fontSize: props.isMulti ? defaultTheme.fontSize.xs : defaultTheme.fontSize.s,
      minHeight: props.isMulti ? 40 : 32,
      lineHeight: props.isMulti ? defaultTheme.lineHeight.xs : defaultTheme.lineHeight.s,
      padding: defaultTheme.spacing[8],
    }),

    placeholder: (base, props) => ({
      ...CommonStyles.placeholder?.(base, props),
      fontSize: props.isMulti ? defaultTheme.fontSize.xs : defaultTheme.fontSize.s,
      lineHeight: props.isMulti ? defaultTheme.lineHeight.xs : defaultTheme.lineHeight.s,
    }),
  };
};
