import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right_16.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const Wrapper = styled.div`
  ${FontXs};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.spacing[16]};
  gap: ${({ theme }) => theme.spacing[2]};
`;

interface Props {
  currentIdx: number;
  maxIdx: number;
  onClickShowAllUpdates: () => void;
  onNavigateToNext?: () => void;
  onNavigateToPrevious?: () => void;
}

export const Navigator = ({
  currentIdx,
  maxIdx,
  onClickShowAllUpdates,
  onNavigateToNext,
  onNavigateToPrevious,
}: Props): ReactElement => {
  return (
    <Wrapper>
      <Button
        disabled={currentIdx <= 0}
        icon={<IconShell icon={ChevronLeftIcon} />}
        size="extraSmall"
        type="text"
        onClick={onNavigateToPrevious}
      />
      <Button
        disabled={currentIdx >= maxIdx}
        icon={<IconShell icon={ChevronRightIcon} />}
        size="extraSmall"
        type="text"
        onClick={onNavigateToNext}
      />
      <Button size="extraSmall" type="link" onClick={onClickShowAllUpdates}>
        <FormattedMessage
          id="lists.reconciliation.navigator.text"
          values={{ max: maxIdx + 1, current: currentIdx + 1 }}
        />
      </Button>
    </Wrapper>
  );
};
