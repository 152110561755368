import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { PopupModalStyles } from './styles';
import { type ModalContentProps } from './types';

const { FullWidthWrapper } = PopupModalStyles;

export const PremiumContent: FC<React.PropsWithChildren<ModalContentProps>> = ({ item }) => {
  return (
    <FullWidthWrapper>
      <FormattedMessage id="integrations.premium.content" />
    </FullWidthWrapper>
  );
};
