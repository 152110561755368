import { routes } from 'pages/auth-routes/routes';
import { PreloadPriority, type RouteObjWithPreload } from 'pages/auth-routes/types';
import { useEffect } from 'react';

const getPreloadRoutePromisesList = (
  routes: RouteObjWithPreload[],
  highPriorityPreloads: (() => Promise<unknown>)[] = [],
  lowPriorityPreloads: (() => Promise<unknown>)[] = [],
) => {
  routes.forEach((route) => {
    if (route.preload) {
      if (route.preloadPriority === PreloadPriority.High) {
        highPriorityPreloads.push(route.preload);
      } else {
        lowPriorityPreloads.push(route.preload);
      }
    }
    if (route.children) {
      getPreloadRoutePromisesList(route.children, highPriorityPreloads, lowPriorityPreloads);
    }
  });

  return [highPriorityPreloads, lowPriorityPreloads];
};

export const usePreloadRoutes = (): void => {
  useEffect(() => {
    const [highPriorityPreloads, lowPriorityPreloads] = getPreloadRoutePromisesList(routes);

    requestIdleCallback(() => Promise.all(highPriorityPreloads.map((preloadFn) => preloadFn())), {
      timeout: 1000 * 10, //10s
    });

    setTimeout(() => {
      requestIdleCallback(
        () => {
          Promise.all(lowPriorityPreloads.map((preloadFn) => preloadFn()));
        },
        {
          timeout: 1000 * 60, //1m
        },
      );
    }, 1000 * 5 /*10s*/);
  }, []);
};
