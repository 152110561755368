import { type ValueFormatterParams, type ValueGetterParams } from 'ag-grid-community';
import { type Dimension } from 'data/dimension';
import { MetricTreeNodeTypes, type MetricWithHierarchy } from 'data/metrics';
import {
  type Pivot,
  PivotDimensionsSummaryType,
  PIVOT_DIMENSION_METRIC_ROW_NAME,
} from 'data/page-template';
import { isNumber } from 'lodash';
import { type HierarchyClanNode } from 'utils/tree-table';

interface Props {
  params: ValueGetterParams | ValueFormatterParams;
  dimension: Pick<Dimension, 'name' | 'displayName' | 'displayNamePlural'>;
  pivotHeaderDimensions: Pick<Dimension, 'name' | 'displayName' | 'displayNamePlural'>[];
  columnIndex: number;
  pivot: Pivot;
  repeatRowLabels?: boolean;
}

const BlankLabel = '(Blank)';

export const headerCellValueGetter = ({
  params,
  dimension,
  pivotHeaderDimensions,
  columnIndex,
  repeatRowLabels,
  pivot,
}: Props): string | undefined => {
  return dimensionSummaryGetterFns[pivot.showTotals || PivotDimensionsSummaryType.Top]({
    params,
    dimension,
    pivotHeaderDimensions,
    columnIndex,
    pivot,
    repeatRowLabels,
  });
};

const dimensionSummaryTopGetter = ({
  params,
  dimension,
  pivotHeaderDimensions,
  columnIndex,
  repeatRowLabels,
}: Props) => {
  const data = params?.data;

  if (data.viewNodeType === MetricTreeNodeTypes.Blank) {
    return null;
  }

  if (
    dimension.name === PIVOT_DIMENSION_METRIC_ROW_NAME &&
    data.viewNodeType === MetricTreeNodeTypes.Metric
  ) {
    // Use display value for MMPP from displayName and from displayValue for views
    return data.displayName || data.displayValue;
  }

  if (data.name === dimension.name) {
    return data.displayValue || BlankLabel;
  }

  if (
    checkIfToShowHeaderForAggregateCell({
      data,
      pivotHeaderDimensions,
      columnIndex,
    })
  ) {
    return `${dimension.displayName}:All`;
  }
  if (repeatRowLabels) {
    return renderCellValueBasedOnHierarchyClan({
      combineColumnHeaderFromIndex: data.combineColumnHeaderFromIndex,
      hierarchyClan: data.hierarchyClan,
      dimension,
      columnIndex,
      repeatRowLabels,
    });
  }

  return null;
};

const dimensionSummaryBottomGetter = ({
  params,
  dimension,
  pivotHeaderDimensions,
  columnIndex,
  repeatRowLabels,
}: Props) => {
  const data = params?.data;

  if (data.viewNodeType === MetricTreeNodeTypes.Blank) {
    return null;
  }

  if (
    data.isLeaf &&
    dimension.name === PIVOT_DIMENSION_METRIC_ROW_NAME &&
    data.viewNodeType === MetricTreeNodeTypes.Metric
  ) {
    return data.displayValue;
  }

  if (data.isLeaf && data.name === dimension.name) {
    return data.displayValue || BlankLabel;
  }

  if (
    checkIfToShowHeaderForAggregateCell({
      data,
      pivotHeaderDimensions,
      columnIndex,
    })
  ) {
    return `${dimension.displayName}:All`;
  }

  if (repeatRowLabels || (data.isLeaf && isNumber(data.combineColumnHeaderFromIndex))) {
    return renderCellValueBasedOnHierarchyClan({
      combineColumnHeaderFromIndex: data.combineColumnHeaderFromIndex,
      hierarchyClan: data.hierarchyClan,
      dimension,
      columnIndex,
      repeatRowLabels,
    });
  }

  return null;
};

const dimensionSummaryNoneGetter = ({ params, dimension, columnIndex, repeatRowLabels }: Props) => {
  const data = params?.data;

  if (data.viewNodeType === MetricTreeNodeTypes.Blank) {
    return null;
  }

  if (
    dimension.name === PIVOT_DIMENSION_METRIC_ROW_NAME &&
    data.viewNodeType === MetricTreeNodeTypes.Metric
  ) {
    return data.displayValue;
  }

  if (data.name === dimension.name) {
    return data.displayValue || BlankLabel;
  }

  if (repeatRowLabels || isNumber(data.combineColumnHeaderFromIndex)) {
    return renderCellValueBasedOnHierarchyClan({
      combineColumnHeaderFromIndex: data.combineColumnHeaderFromIndex,
      hierarchyClan: data.hierarchyClan,
      dimension,
      columnIndex,
      repeatRowLabels,
    });
  }

  return null;
};

const dimensionSummaryGetterFns = {
  [PivotDimensionsSummaryType.Top]: dimensionSummaryTopGetter,
  [PivotDimensionsSummaryType.Bottom]: dimensionSummaryBottomGetter,
  [PivotDimensionsSummaryType.None]: dimensionSummaryNoneGetter,
};

// to show prev all dim-name in aggregate cell eg: All regions
const checkIfToShowHeaderForAggregateCell = ({
  data,
  pivotHeaderDimensions,
  columnIndex,
}: {
  data: MetricWithHierarchy;
  pivotHeaderDimensions: Pick<Dimension, 'name' | 'displayName' | 'displayNamePlural'>[];
  columnIndex: number;
}) => {
  return (
    (pivotHeaderDimensions[columnIndex - 1]?.name === PIVOT_DIMENSION_METRIC_ROW_NAME &&
      data.viewNodeType === MetricTreeNodeTypes.Metric) ||
    pivotHeaderDimensions[columnIndex - 1]?.name === data.name
  );
};

const renderCellValueBasedOnHierarchyClan = ({
  combineColumnHeaderFromIndex,
  hierarchyClan,
  dimension,
  columnIndex,
  repeatRowLabels,
}: {
  combineColumnHeaderFromIndex: number;
  hierarchyClan: HierarchyClanNode[];
  dimension: Pick<Dimension, 'name' | 'displayName' | 'displayNamePlural'>;
  columnIndex: number;
  repeatRowLabels?: boolean;
}) => {
  /* combineColumnHeaderFromIndex is true when in table viewType & dim-summary is bottom/none
      Both aggregate row headers and leaf row data are shown in one row
    */
  if (repeatRowLabels || columnIndex >= combineColumnHeaderFromIndex) {
    const clan = hierarchyClan[columnIndex];

    if (dimension.name === PIVOT_DIMENSION_METRIC_ROW_NAME) {
      return clan?.displayName;
    }

    return clan?.displayValue;
  }
};
