import { useQueryClient } from '@tanstack/react-query';
import { type GridOptions } from 'ag-grid-community';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useListStoreInstance } from 'store/lists';
import { addRowToGrid } from '../../cell-components/add-row-footer/utils';
import { GridHeaderHeight, GridRowHeight } from './constants';
import { onColumnMoved, processListDataFromClipboard } from './utils';

export const useGridOptions = (): {
  gridOptions: GridOptions;
} => {
  const listStore = useListStoreInstance();
  const { updateListMutation } = useListMutations();
  const queryClient = useQueryClient();
  const intl = useIntl();

  const gridOptions = useMemo(() => {
    const onAddRow = () => {
      const { gridApi, addNewRow, config, rows, filteredRows } = listStore.getState();

      addRowToGrid({ api: gridApi, config, rows, filteredRows, addNewRow });
    };

    return {
      defaultColDef: {
        menuTabs: [],
        minWidth: 140,
        resizable: true,
        sortable: false,
        suppressMovable: false,
        cellClassRules: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'dt-list-row-error': (params) => {
            if (params.data.isError) {
              return true;
            }
          },
        },
        suppressKeyboardEvent: (params) => {
          if (params.data?.fullWidth) {
            const { code } = params.event;

            if (code === 'Enter') {
              onAddRow();
            }
          }

          return params.editing;
        },
      },
      getRowId: ({ data }) => `${data.id}`,
      headerHeight: GridHeaderHeight,
      isFullWidthRow: ({ rowNode }) => !!rowNode.data?.fullWidth,
      rowDragManaged: true,
      rowHeight: GridRowHeight,
      suppressDragLeaveHidesColumns: true,
      suppressNoRowsOverlay: true,
      enableRangeSelection: true,
      enableFillHandle: true,
      rowDragMultiRow: true,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      suppressHeaderFocus: true,
      enterNavigatesVerticallyAfterEdit: true,
      onColumnMoved: (e) => onColumnMoved(e, listStore, updateListMutation),
      processCellForClipboard: (params) => params.value,
      processDataFromClipboard: (data) =>
        processListDataFromClipboard(data, listStore, queryClient, intl),
    } as GridOptions;
  }, [intl, listStore, queryClient, updateListMutation]);

  return { gridOptions };
};
