import { QueryTableColumnDataType, VisualQueryFilterOperator } from '../types';

export const DTQL_VISUAL_QUERY_OPERATOR_LIST: {
  value: VisualQueryFilterOperator;
  label: string;
  supportedDataTypes: QueryTableColumnDataType[];
}[] = [
  {
    value: VisualQueryFilterOperator.GreaterThan,
    label: 'edit_actuals.visual.operators.greater_than',
    supportedDataTypes: [
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Timestamp,
    ],
  },
  {
    value: VisualQueryFilterOperator.GreaterThanOrEqualTo,
    label: 'edit_actuals.visual.operators.greater_than_or_equal_to',
    supportedDataTypes: [
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Timestamp,
    ],
  },
  {
    value: VisualQueryFilterOperator.LessThan,
    label: 'edit_actuals.visual.operators.less_than',
    supportedDataTypes: [
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Timestamp,
    ],
  },
  {
    value: VisualQueryFilterOperator.LessThanOrEqualTo,
    label: 'edit_actuals.visual.operators.less_than_or_equal_to',
    supportedDataTypes: [
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Timestamp,
    ],
  },
  {
    value: VisualQueryFilterOperator.SelectFromValues,
    label: 'edit_actuals.visual.operators.select_from_values',
    supportedDataTypes: [
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.String,
    ],
  },
  {
    value: VisualQueryFilterOperator.NotOneOf,
    label: 'edit_actuals.visual.operators.select_not_one_of',
    supportedDataTypes: [
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.String,
    ],
  },
  {
    value: VisualQueryFilterOperator.Between,
    label: 'edit_actuals.visual.operators.between',
    supportedDataTypes: [
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Integer,
    ],
  },
  {
    value: VisualQueryFilterOperator.EqualTo,
    label: 'edit_actuals.visual.operators.equal_to',
    supportedDataTypes: [
      QueryTableColumnDataType.String,
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Timestamp,
      QueryTableColumnDataType.Boolean,
    ],
  },
  {
    value: VisualQueryFilterOperator.NotEqualTo,
    label: 'edit_actuals.visual.operators.not_equal_to',
    supportedDataTypes: [
      QueryTableColumnDataType.String,
      QueryTableColumnDataType.Integer,
      QueryTableColumnDataType.Float,
      QueryTableColumnDataType.Numeric,
      QueryTableColumnDataType.BigNumeric,
      QueryTableColumnDataType.Date,
      QueryTableColumnDataType.DateTime,
      QueryTableColumnDataType.Time,
      QueryTableColumnDataType.Timestamp,
      QueryTableColumnDataType.Boolean,
    ],
  },
  {
    value: VisualQueryFilterOperator.Contains,
    label: 'edit_actuals.visual.operators.contains',
    supportedDataTypes: [QueryTableColumnDataType.String],
  },
  {
    value: VisualQueryFilterOperator.DoesNotContain,
    label: 'edit_actuals.visual.operators.does_not_contain',
    supportedDataTypes: [QueryTableColumnDataType.String],
  },
  {
    value: VisualQueryFilterOperator.StartsWith,
    label: 'edit_actuals.visual.operators.starts_with',
    supportedDataTypes: [QueryTableColumnDataType.String],
  },
  {
    value: VisualQueryFilterOperator.EndsWith,
    label: 'edit_actuals.visual.operators.ends_with',
    supportedDataTypes: [QueryTableColumnDataType.String],
  },
  {
    value: VisualQueryFilterOperator.IsEmpty,
    label: 'edit_actuals.visual.operators.is_empty',
    supportedDataTypes: Object.values(QueryTableColumnDataType),
  },
  {
    value: VisualQueryFilterOperator.IsNotEmpty,
    label: 'edit_actuals.visual.operators.is_not_empty',
    supportedDataTypes: Object.values(QueryTableColumnDataType),
  },
];
