import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { ListsApi, type ListUpdate } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';

export const useListUpdates = (): UseQueryResult<ListUpdate[], unknown> => {
  const listId = useListStoreContext((s) => s.id);

  return useQuery(['list-updates', listId], () => ListsApi.getListUpdates(listId), {
    refetchOnWindowFocus: true,
  });
};
