import { useSystemAdminAction } from 'data/system/admin-action';
import { type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const SystemPage: FC<React.PropsWithChildren<unknown>> = () => {
  const { classification, operation } = useParams<{
    classification: string;
    operation: string;
  }>();

  const systemAdminActionMutation = useSystemAdminAction();

  useEffect(() => {
    if (classification && operation) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search));

      systemAdminActionMutation.mutateAsync({ classification, operation, queryParams: params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification, operation]);

  return (
    <div>
      <>
        {systemAdminActionMutation.data && (
          <div>
            <pre>{JSON.stringify(systemAdminActionMutation.data, null, 2)}</pre>
          </div>
        )}

        {systemAdminActionMutation.error && (
          <div>
            <pre>{JSON.stringify(systemAdminActionMutation.error, null, 2)}</pre>
          </div>
        )}
      </>
    </div>
  );
};
