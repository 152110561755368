import { useQueryClient } from '@tanstack/react-query';
import { type ListDataValidationConfig } from 'data/modelling/lists';
import { useEffect } from 'react';
import { useListStoreContext } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { prefetchDataValidationPermissibleValues } from '../../utils';

export const useGridDataValidations = (
  dataValidation?: Record<string, ListDataValidationConfig>,
): void => {
  const queryClient = useQueryClient();

  const gridApi = useListStoreContext((s) => s.gridApi);
  const setDataValidationPermissibleValuesMap = useListStoreContext(
    (s) => s.setDataValidationPermissibleValuesMap,
  );

  useEffect(() => {
    if (!dataValidation) {
      return;
    }

    prefetchDataValidationPermissibleValues(queryClient, dataValidation).then(
      (permissibleValuesMap) => {
        setDataValidationPermissibleValuesMap(permissibleValuesMap);

        queueMacroTask(() =>
          gridApi?.refreshCells({
            columns: Object.keys(dataValidation || {}),
            force: true,
          }),
        );
      },
    );
  }, [dataValidation, gridApi, queryClient, setDataValidationPermissibleValuesMap]);
};
