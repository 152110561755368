import axios from 'axios';
import {
  type Folder,
  type FolderType,
  type HierarchialResource,
  type ItemMoveRequest,
} from './types';

const ROOT_PATH = '/v1/folders';

export const FoldersApi = {
  async findQuery(folderType: FolderType): Promise<HierarchialResource[]> {
    return axios
      .get<HierarchialResource[]>(`${ROOT_PATH}?folderType=${folderType}`)
      .then(({ data }) => data);
  },

  reorder: (folderIds: number[], parentId?: number): Promise<void> => {
    return axios.patch(`${ROOT_PATH}/reorder`, {
      folderIds,
      parentId,
    });
  },

  delete(folderId: number): Promise<void> {
    return axios.delete(`${ROOT_PATH}/${folderId}`);
  },

  async createFolder(folder: AtLeast<Folder, 'displayName'>): Promise<Folder> {
    return axios.post(`${ROOT_PATH}`, folder).then(({ data }) => data);
  },

  async updateFolder(folder: AtLeast<Folder, 'id'>): Promise<Folder> {
    return axios.patch(`${ROOT_PATH}/${folder.id}`, folder).then(({ data }) => data);
  },

  async getFoldersById(folderId: number): Promise<Folder> {
    return axios.get(`${ROOT_PATH}/${folderId}`).then(({ data }) => data);
  },

  async moveItem(itemMoveRequest: ItemMoveRequest): Promise<void> {
    return axios.patch(`${ROOT_PATH}/move`, itemMoveRequest).then(({ data }) => data);
  },
};
