import axios from 'axios';
import {
  type MetricCategory,
  type MetricInfoWithCategory,
} from 'components/modules/boards-v2/custom-board/pivot-slider/left-section/data-tab/list/types';
import { type OptionBase } from 'components/ui/atomic-components';
import { type NewPeriod } from 'data';
import { type ScorecardMetric } from 'data/boards/charts/types';
import { type DateFilterPresetName } from 'data/bootstrap/types';
import {
  type MetricApplicableDimensionValue,
  type Dimension,
  type MetricApplicableDimension,
} from 'data/dimension';
import { type DimensionAggregationTypes, type TimeAggregationTypes } from 'data/modelling/metric';
import { type CellFormatRules } from 'data/page-template/grid';
import { type Scenario } from 'data/scenarios';
import { stringify } from 'query-string';
import { sanitizeMetricNameForApi } from 'utils';
import { type DataFormattingType } from 'utils/data-formatter/types';
import {
  type BaseMetric,
  type Metric,
  type MetricDefinition,
  type MetricDefinitionRequest,
  type MetricHistoryContentWithDimensions,
  type MetricInfoType,
  type MetricsMetaResponseData,
} from './types';

export type MetricDropdownOption = OptionBase<
  Metric & {
    moduleDisplayName: string | null;
    metricCategory?: string;
  }
>;

export const MetricsApi = {
  query: (search?: string): Promise<MetricDropdownOption[]> =>
    axios
      .get<MetricDropdownOption[]>('v1/search', {
        params: {
          q: search?.trim() || '',
        },
      })
      .then(({ data }) => {
        return data.map((d) => ({
          ...d,
          label: d.displayName,
          value: d.name,
        }));
      }),

  fetchInfo: async (metricName: string): Promise<MetricInfoType> => {
    const path = `v1/m/${sanitizeMetricNameForApi(metricName)}/info`;
    const { data } = await axios.get(path);

    return data;
  },

  addMetrics: (payload: MetricHistoryContentWithDimensions[]): Promise<void> => {
    return axios.post('v1/m/bulk', payload);
  },

  fetchSpaces: (
    metricName: string,
  ): Promise<{ dimensions: MetricApplicableDimension[]; scenarios?: Scenario[] }> => {
    return axios
      .get(`v1/m/${sanitizeMetricNameForApi(metricName)}/spaces`)
      .then(({ data }) => data);
  },

  fetchDimensionValues: (
    metricName: string,
    dimensionName: string,
  ): Promise<MetricApplicableDimensionValue[]> => {
    return axios
      .get(`v1/m/${sanitizeMetricNameForApi(metricName)}/dimensions/${dimensionName}/values`)
      .then(({ data }) => data);
  },

  fetchMetricsDimensionValues: (dimension: string, metricNames: string[]): Promise<Dimension[]> =>
    axios
      .post('v1/m/dimension-values', {
        metricNames,
        dimension,
      })
      .then(({ data }) => data),

  fetchSummary: (
    metricNames: string[],
    dateFilterPresetName?: DateFilterPresetName,
  ): Promise<{
    format: CellFormatRules;
    pivotData: ScorecardMetric[];
    planPeriods: NewPeriod[];
    dateOption: DateFilterPresetName;
  }> => {
    const path = 'v1/m/summary';

    return axios
      .get(path, {
        params: {
          names: metricNames.join(','),
          dateOption: dateFilterPresetName,
        },
      })
      .then(({ data }) => data);
  },

  getDefinition: async (
    metricName: string,
    fetchDimensions: boolean,
  ): Promise<MetricDefinition> => {
    const { data } = await axios.get(`v1/m/${sanitizeMetricNameForApi(metricName)}/definition`, {
      params: { fetchDimensions },
    });

    return data;
  },

  getDefinitions: async (metricNames: string[]): Promise<Record<string, MetricDefinition>> => {
    const { data } = await axios.post('v1/m/definitions', metricNames);

    return data;
  },

  createMetric: (metric: Partial<MetricDefinitionRequest>): Promise<BaseMetric> => {
    return axios.post('v1/m', metric).then(({ data }) => data);
  },

  updateMetric: (
    metricName: string,
    metric: Partial<MetricDefinitionRequest>,
  ): Promise<BaseMetric> => {
    return axios
      .patch(`v1/m/${sanitizeMetricNameForApi(metricName)}/definition`, metric)
      .then(({ data }) => data);
  },

  updateMetricSummary: async ({
    metricName,
    field,
    value,
  }: {
    metricName: string;
    field: 'timeAggregation' | 'dimensionAggregation';
    value: TimeAggregationTypes | DimensionAggregationTypes;
  }): Promise<MetricDefinition> => {
    const { data } = await axios.patch(
      `v1/m/${sanitizeMetricNameForApi(metricName)}/update_summary`,
      {
        field,
        value,
      },
    );

    return data;
  },

  updateRecord: (metric: AtLeast<MetricDefinitionRequest, 'name'>): Promise<BaseMetric> => {
    return axios
      .patch(`v1/m/${sanitizeMetricNameForApi(metric.name)}/definition`, metric)
      .then(({ data }) => data);
  },

  getMetrics: (): Promise<MetricsMetaResponseData[]> => axios.get('v1/m').then(({ data }) => data),

  getFieldData: ({ metricName, field }: { metricName: string; field: string }): Promise<string> =>
    axios
      .get(`v1/m/${sanitizeMetricNameForApi(metricName)}/field_details`, {
        params: { field },
      })
      .then(({ data }) => data),

  removeLink: async (metricName: string): Promise<void> =>
    await axios.delete(`v1/m/${sanitizeMetricNameForApi(metricName)}/link`),

  getCategories: async ({ includeMetrics = true }): Promise<MetricCategory[]> =>
    await axios.get('v1/categories', { params: { includeMetrics } }).then(({ data }) => {
      return data.map((item: MetricCategory) => ({
        name: item.name,
        displayName: item.displayName,
        id: item.id,
        metrics: !includeMetrics
          ? []
          : item.metrics?.map((el: MetricInfoWithCategory) => {
              return { ...el, categoryId: item.id };
            }),
        default: item.default,
      }));
    }),

  getMetricsFromCategories: async (metric: MetricCategory): Promise<MetricCategory> =>
    await axios.get(`v1/categories/${metric.name}`).then(({ data }) => {
      return {
        name: data.name,
        displayName: data.displayName,
        id: data.id,
        metrics: data.metrics?.map((el: MetricInfoWithCategory) => {
          return { ...el, categoryId: data.id || 1 };
        }),
        default: data.default,
      };
    }),

  searchMonitoringMetrics: (
    query?: string,
  ): Promise<
    {
      category: string;
      description: string;
      displayName: string;
      name: string;
      type: DataFormattingType;
    }[]
  > =>
    axios
      .get('v1/search/metric', {
        params: { q: query?.trim() || '' },
        paramsSerializer: (params) => stringify(params),
      })
      .then(({ data }) => data),
};
