import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useUser } from 'utils/hooks';
import zipy from 'zipyai';

export const useUserSessionZipyAlerts = (): void => {
  const { user, isDtUser } = useUser();
  const { isPocTenant } = useFlags();

  useEffect(() => {
    const isSessionOngoing = sessionStorage.getItem('isSessionOngoing');

    if (!isDtUser && isPocTenant && !isSessionOngoing) {
      zipy.sendAlertOnSlack(
        'POC login alert',
        `${user.firstName} ${user.lastName || ''} (${user.username}) has logged in`,
      );
    }

    if (!isSessionOngoing) {
      sessionStorage.setItem('isSessionOngoing', 'true');
    }
  }, [isDtUser, isPocTenant, user.firstName, user.lastName, user.tenant, user.username]);
};
