import { columnSortComparatorMap } from 'components/modules/modelling/lists/right-section/grid/hooks/use-grid-column-defs/utils/list-cell-utils';
import { type ListGridRow } from 'components/modules/modelling/lists/right-section/grid/types';
import { type QueryTableColumnDataType } from 'data/big-query';

export const sortedColumnValues = ({
  rows,
  columnName,
  columnType,
  isDescending,
}: {
  rows: ListGridRow[];
  columnName: string;
  columnType: QueryTableColumnDataType;
  isDescending: boolean;
}): ListGridRow[] => {
  return [...rows].sort((a, b) => {
    const sortFn = columnSortComparatorMap[columnType];

    return sortFn?.(a.data, b.data, columnName, isDescending);
  });
};
