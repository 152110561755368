import { type CellRendererSelectorResult, type ICellRendererParams } from 'ag-grid-community';
import { isUndefined } from 'lodash';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';
import { ValidationErrorCell } from '../../../../cell-components/validation-error-cell';

export const cellRendererSelectorFuncton = ({
  key,
  store,
}: {
  key: string;
  store: StoreApi<ListsStoreApi>;
}) => {
  return ({ value }: ICellRendererParams): CellRendererSelectorResult | undefined => {
    const { dataValidationPermissibleValuesMap } = store.getState();

    const permissibleValues = dataValidationPermissibleValuesMap?.[key]
      ? dataValidationPermissibleValuesMap[key]
      : undefined;

    const valueViolatesExistingColumnValidation =
      !!value && !isUndefined(permissibleValues) && !permissibleValues.has(value);

    if (valueViolatesExistingColumnValidation) {
      return { component: ValidationErrorCell };
    }
  };
};
