import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { FormConnectorStyles, PopupModalStyles } from '../styles';

const { StyledP, StyledOL, StyledLI } = PopupModalStyles;
const { LeftPanel, Title, Description, ImageContainer } = FormConnectorStyles;

export const AirtableInfoContent = ({ item }: { item: IntegrationModalContent }): ReactElement => {
  return (
    <LeftPanel>
      <ImageContainer alt="" src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)} />
      <Title>{item.displayName}</Title>
      <Description>
        <StyledP>
          <FormattedMessage id="integrations.airtable.info.desc" />
        </StyledP>
        <StyledP>
          <FormattedMessage id="integrations.airtable.info.steps" />
        </StyledP>

        <StyledOL>
          <StyledLI>
            <FormattedMessage id="integrations.airtable.info.steps_1" />
          </StyledLI>
          <StyledLI>
            <FormattedMessage id="integrations.airtable.info.steps_2" />
          </StyledLI>
        </StyledOL>
      </Description>

      <CommonContent {...item} />
    </LeftPanel>
  );
};
