import { type OptionBase } from 'components/ui/atomic-components';
import { ListDataValidationCondition } from 'data/modelling/lists';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useConditionOptions = (): OptionBase[] => {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'lists.data_validation.condition.existing_column' }),
        value: ListDataValidationCondition.ExistingColumn,
      },
    ],
    [intl],
  );
};
