import { type DimensionValue } from '../types';

export const getModifiedSeriesDimensions = (
  dimensions: Record<string, DimensionValue>,
): Record<string, string> => {
  const newSeriesDimensions: Record<string, string> = {};

  Object.keys(dimensions).forEach((key) => {
    newSeriesDimensions[key] = dimensions[key].value;
  });

  return newSeriesDimensions;
};
