import {
  SelectOptionOld,
  type SelectOptionPropsOld,
} from 'components/ui/atomic-components/select-old/option';
import { type MetricDropdownOption } from 'data/metrics';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';
import { constructMetricSearchUrl } from 'utils/search/construct-metric-search-url';

type GlobalSearchBoxOption = SelectOptionPropsOld<MetricDropdownOption, false>;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const MetricWrapper = styled.span`
  ${FontS};
`;

const ModuleWrapper = styled.span`
  ${FontXs};

  color: ${({ theme }) => theme.colors.gray400};
`;

const SearchOptionComponent: FC<
  React.PropsWithChildren<{
    metricDisplayName: string;
    moduleDisplayName: string | null;
  }>
> = ({ metricDisplayName, moduleDisplayName }) => {
  return (
    <Wrapper>
      <MetricWrapper>{metricDisplayName}</MetricWrapper>
      {moduleDisplayName && <ModuleWrapper>{moduleDisplayName}</ModuleWrapper>}
    </Wrapper>
  );
};

export const MetricSearchOption: FC<React.PropsWithChildren<GlobalSearchBoxOption>> = ({
  data,
  label,
  ...rest
}) => {
  return (
    <SelectOptionOld data={data} label={label} {...rest}>
      <SearchOptionComponent metricDisplayName={label} moduleDisplayName={data.moduleDisplayName} />
    </SelectOptionOld>
  );
};

export const GlobalSearchBoxOption: FC<React.PropsWithChildren<GlobalSearchBoxOption>> = ({
  data,
  label,
  ...rest
}) => {
  const url = constructMetricSearchUrl({
    metricName: data.name,
    q: data.displayName,
  });

  return (
    <SelectOptionOld data={data} label={label} {...rest}>
      <Link to={url}>
        <SearchOptionComponent
          metricDisplayName={label}
          moduleDisplayName={data.moduleDisplayName}
        />
      </Link>
    </SelectOptionOld>
  );
};
