import { type MutableRefObject, useEffect, useRef } from 'react';

/**
 * Many times an object changes due to change in data from API call. However you want the ref.current to update as when the object changes.
 * So you use a useEffect for keeping ref.current updated to the latest value. This hook automatically keeps the output ref updated to the latest value of the object.
 * @param state The object which you want store as reference.
 * @returns The useRef reference to your object
 */
export const useRefSync = <S>(state: S): MutableRefObject<S> => {
  const ref = useRef(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref;
};
