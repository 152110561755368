import { Tree as AntdTree } from 'antd';
import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';

const TreeCheckboxTicketDimensions = {
  width: '5.7142857142857135px',
  height: '9.142857142857142px',
};

export const Tree = styled(AntdTree)`
  .ant-tree-treenode {
    width: calc(100% - 8px);
    padding: ${({ theme: { spacing } }) => `0 ${spacing[6]}`};
    margin-left: ${({ theme }) => theme.spacing[4]};
    border-radius: ${({ theme }) => theme.borderRadius.xs};

    &:hover {
      background-color: ${({ theme }) => theme.colors.bgMedium};
    }

    .ant-tree-node-content-wrapper {
      ${SingleLineEllipsis};

      padding: 0;
      width: 100%;
      min-width: 0;

      &:hover,
      &.ant-tree-node-selected {
        background: transparent;
      }
    }

    .ant-tree-switcher-noop {
      display: none;
    }

    .ant-tree-checkbox {
      display: flex;
      align-items: center;
      height: 32px;
      margin-top: auto;
      margin-bottom: auto;

      .ant-tree-checkbox-inner {
        border-radius: ${({ theme }) => theme.borderRadius.xs};
        border: 1px solid ${({ theme }) => theme.colors.buttonOutlineBorder};
        height: 12px;
        width: 12px;

        &::after {
          width: ${TreeCheckboxTicketDimensions.width};
          height: ${TreeCheckboxTicketDimensions.height};
          transform: rotate(45deg) scale(0.54);
          top: 11px;
          left: 3px;
        }
      }

      :not(.ant-tree-checkbox-disabled):hover {
        .ant-tree-checkbox-inner {
          background: ${({ theme }) => theme.colors.bgLight};
          border: 1px solid ${({ theme }) => theme.colors.buttonOutlineBorder};
        }

        &.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
          background: ${({ theme }) => theme.colors.buttonFillHoverPrimary};
          border: 1px solid ${({ theme }) => theme.colors.buttonFillHoverPrimary};
        }

        &.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
          background: ${({ theme }) => theme.colors.buttonFillHoverPrimary};
          border: 1px solid ${({ theme }) => theme.colors.buttonFillHoverPrimary};
        }
      }

      &.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background: ${({ theme }) => theme.colors.buttonFillPrimary};
        border: 1px solid ${({ theme }) => theme.colors.buttonFillPrimary};
      }
    }
  }
`;
