import { Result } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const UnderMaintenancePage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <Result
        icon={<img alt={''} src={getAssetUrl('under_maintenance.svg')} />}
        status="info"
        subTitle={
          <div>
            <FormattedMessage id="maintenance.desc_1" /> <br></br>
            <FormattedMessage id="maintenance.desc_2" />
          </div>
        }
        title={<FormattedMessage id="maintenance.title" />}
      />
    </Wrapper>
  );
};
