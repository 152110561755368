import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { type IntegrationAuthFlowComp } from '../types';

interface Props {
  flow: IntegrationAuthFlowComp;
  item: IntegrationModalContent;
  callbackUrl: string;
  onConnectionSuccess?: () => void;
}

export const ComponentFlow = ({
  flow,
  item,
  callbackUrl,
  onConnectionSuccess,
}: Props): ReactElement => {
  const Component = flow.component;

  return (
    <Component callbackUrl={callbackUrl} item={item} onConnectionSuccess={onConnectionSuccess} />
  );
};
