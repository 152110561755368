import { type DimensionQueryParamsObject } from 'data/dimension';

/****
 * We have customized the original encodeObject and decodeObject utils, from the following source
 * https://github.com/pbeshai/use-query-params/blob/b14c97ec2e7b1dfaca51d4d17439f9c306b34dba/packages/serialize-query-params/src/serialize.ts#L504
 ****/

/**
 * Interprets an encoded string and returns either the string or null/undefined if not available.
 * Ignores array inputs (takes just first element in array)
 * @param input encoded string
 */
const getEncodedValue = (
  input: string | (string | null)[] | null | undefined,
  allowEmptyString?: boolean,
): string | null | undefined => {
  if (input == null) {
    return input;
  }
  // '' or []
  if (input.length === 0 && (!allowEmptyString || (allowEmptyString && input !== ''))) {
    return null;
  }

  const str = input instanceof Array ? input[0] : input;

  if (str == null) {
    return str;
  }
  if (!allowEmptyString && str === '') {
    return null;
  }

  return str;
};

/**
 * Encode value, a string array,
 * @param {String} val The value to encode
 * @param {String} valueSeparator The separator between the values
 * @return {String} The encoded object
 */
const encodeValue = (val: string[], valueSeparator: string): string => {
  if (val instanceof Array) {
    return val.join(valueSeparator);
  }

  return `${val}`;
};

/**
 * Decode value, a string,
 * @param {String} valStr The encoded object
 * @param {String} valueSeparator The separator between the values
 * @return {String[]} The decoded object
 */
const decodeValue = (valStr: string, valueSeparator: string): string[] => {
  if (valStr.includes(valueSeparator)) {
    const valArray = valStr.split(valueSeparator);

    return valArray;
  }

  return [valStr];
};

/**
 * Encode simple objects as readable strings.
 * @param {Object} object The object to encode
 * @param {String} keyValSeparator The separator between keys and values
 * @param {String} entrySeparator The separator between entries
 * @param {String} valueSeparator The separator between values
 * @return {String} The encoded object
 */
export const encodeObject = (
  obj: { [key: string]: null | undefined | string[] } | null | undefined,
  keyValSeparator: string,
  entrySeparator: string,
  valueSeparator: string,
): string | null | undefined => {
  if (obj == null) {
    return obj;
  } // null or undefined
  if (!Object.keys(obj).length) {
    return '';
  } // {} case

  return Object.keys(obj)
    .map((key) => `${key}${keyValSeparator}${encodeValue(obj[key] || [], valueSeparator)}`)
    .join(entrySeparator);
};

/**
 * Decodes a simple object to javascript.
 * @param {String} input The object string to decode
 * @param {String} keyValSeparator The separator between keys and values
 * @param {String} entrySeparator The separator between entries
 * @param {String} valueSeparator The separator between values
 * @return {Object} The javascript object
 */
export const decodeObject = (
  input: string | (string | null)[] | null | undefined,
  keyValSeparator: string,
  entrySeparator: string,
  valueSeparator: string,
): DimensionQueryParamsObject | null | undefined => {
  const objStr = getEncodedValue(input, true);

  if (objStr == null) {
    return objStr;
  }
  if (objStr === '') {
    return {};
  }

  const obj: DimensionQueryParamsObject = {};

  const keyValSeparatorRegExp = new RegExp(`${keyValSeparator}(.*)`);

  objStr.split(entrySeparator).forEach((entryStr) => {
    const [key, value] = entryStr.split(keyValSeparatorRegExp);

    obj[key] = decodeValue(value, valueSeparator);
  });

  return obj;
};
