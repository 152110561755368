import styled from 'styled-components';
import { FontXs } from 'styles/typography';

export const styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 350px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  `,
  MetricWrapper: styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
    padding: ${({ theme }) => theme.spacing[16]};
  `,
  Description: styled.p`
    ${FontXs}

    color: ${({ theme }) => theme.colors.textBody1};
    margin-top: ${({ theme }) => theme.spacing[4]};
  `,
  FooterText: styled.div`
    ${FontXs}

    display: grid;
    row-gap: ${({ theme }) => theme.spacing[12]};
    color: ${({ theme }) => theme.colors.gray800};
    background: ${({ theme }) => theme.colors.gray100};
    padding: ${({ theme }) => theme.spacing[16]};
  `,
  StyledTitle: styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.m};
    color: ${({ theme }) => theme.colors.textTitle1};
    cursor: default;
  `,
  StyledAnchor: styled.a`
    color: ${({ theme }) => theme.colors.textTitle1};

    :hover {
      color: ${({ theme }) => theme.colors.blue60};
      text-decoration: underline;
    }
  `,
};
