import { type MetricDropdownOption } from 'data/metrics';
import { type FC } from 'react';
import { components, type MenuProps } from 'react-select';

export const GlobalSearchBoxMenu: FC<
  React.PropsWithChildren<MenuProps<MetricDropdownOption, false>>
> = (props) => {
  if (props.selectProps.inputValue?.length === 0) {
    return null;
  }

  return <components.Menu {...props} />;
};
