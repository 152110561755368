import { type FolderType } from 'data/folder';
import { type ReactElement, type ReactNode, useState } from 'react';
import { ExpandCollapseArrowBtn } from './expand-collapse-arrow-btn';
import { Wrapper, NavWrapper, VisibilityWrapper, TitleSection, SideNavMenu } from './styles';
import { LeftNavTree } from './tree';

export const SecondaryLeftNav = ({
  children,
  canShowExpandArrow = true,
  isExpanded,
  uniqueKey,
}: {
  canShowExpandArrow?: boolean;
  children: ReactNode;
  isExpanded?: boolean;
  uniqueKey?: 'boards' | 'plans' | 'smv' | FolderType;
}): ReactElement => {
  const [isFixedExpanded, setIsFixedExpanded] = useState(
    sessionStorage.getItem(`${uniqueKey}-expanded-left-nav`) !== 'false',
  );
  const [canShowTooltip, setCanShowTooltip] = useState(true);
  const [openTransitionDelay, setOpenTransitionDelay] = useState('500ms');

  const onExpandCollapseBtnClick = () => {
    setOpenTransitionDelay('0');
    setCanShowTooltip(false);

    setTimeout(() => {
      setCanShowTooltip(true);
      setOpenTransitionDelay('500ms');
    }, 300);

    setIsFixedExpanded((value) => {
      uniqueKey && sessionStorage.setItem(`${uniqueKey}-expanded-left-nav`, String(!value));

      return !value;
    });
  };

  const commonUICompProps = {
    isExpanded: isExpanded ?? isFixedExpanded,
    transitionDelay: openTransitionDelay,
  };

  return (
    <Wrapper {...commonUICompProps}>
      <NavWrapper {...commonUICompProps}>
        <VisibilityWrapper {...commonUICompProps}>{children}</VisibilityWrapper>

        {!isFixedExpanded && canShowExpandArrow && (
          // this is so that on hover open of nav drawer, arrow btn is clickable without having the drawer being closed.
          <ExpandCollapseArrowBtn
            canShowTooltip={canShowTooltip}
            className="expand-collapse-arrow--inside"
            commonUICompProps={commonUICompProps}
            isExpanded={isFixedExpanded}
            onClick={onExpandCollapseBtnClick}
          />
        )}
      </NavWrapper>
      {canShowExpandArrow && (
        <ExpandCollapseArrowBtn
          canShowTooltip={canShowTooltip}
          className="expand-collapse-arrow--outside"
          commonUICompProps={commonUICompProps}
          isExpanded={isFixedExpanded}
          onClick={onExpandCollapseBtnClick}
        />
      )}
    </Wrapper>
  );
};

SecondaryLeftNav.TitleSection = TitleSection;
SecondaryLeftNav.Menu = SideNavMenu;
SecondaryLeftNav.Tree = LeftNavTree;
