import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { type MenuListProps, components } from 'react-select';
import { type TextOptionType } from '../types';
import { styles } from './styles';

const { Title } = styles;

export const CustomMenuListForValidatedColumn: FC<
  React.PropsWithChildren<MenuListProps<TextOptionType, false>>
> = (props) => {
  return (
    <div>
      <Title>
        <FormattedMessage id="lists.select_option" />
      </Title>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
};
