import styled from 'styled-components';
import { FontS } from 'styles/typography';

export default {
  Wrapper: styled.div`
    position: absolute;
    top: ${({ theme: { spacing } }) => spacing[0]};
    left: ${({ theme: { spacing } }) => spacing[0]};
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    z-index: ${({ theme: { zIndex } }) => zIndex[50]};
  `,
  BannerBar: styled.div`
    background-color: ${({ theme }) => theme.colors.orange400};
    height: 4px;
    z-index: ${({ theme: { zIndex } }) => zIndex[10]};
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.full};
  `,

  BannerTag: styled.div`
    height: 36px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.orange400};
    padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
    gap: ${({ theme: { spacing } }) => spacing[8]};
    color: ${({ theme: { colors } }) => colors.gray00};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.m};
  `,

  Title: styled.div`
    ${FontS};

    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing[4]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  CloseWrapper: styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing[4]};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.colors.iconHover};
    }
  `,
};
