import axios, { type AxiosRequestConfig } from 'axios';
import { type ID } from 'data';
import { type Scenario } from './types';

const apiPath = '/v1/scenarios';

export const ScenarioApi = {
  fetchScenarios: async (config?: AxiosRequestConfig): Promise<Scenario[]> => {
    const path = apiPath;
    const { data } = await axios.get(path, config);

    return data;
  },

  fetchScenario: async (id: ID): Promise<Scenario> => {
    const path = `${apiPath}/${id}`;
    const { data } = await axios.get(path);

    return data;
  },

  createScenario: async ({
    moduleName,
    displayName,
    id,
  }: {
    moduleName: string;
    displayName?: string;
    id?: ID;
  }): Promise<Scenario> => {
    const path = apiPath;
    const { data } = await axios.post(path, { displayName, moduleName, id });

    return data;
  },

  updateScenario: async (id: ID, displayName: string): Promise<Scenario> => {
    const path = `${apiPath}/${id}`;
    const { data } = await axios.patch(path, { displayName });

    return data;
  },

  deleteScenario: async (id: ID, moduleName: string): Promise<void> => {
    const path = `${apiPath}/${id}`;
    const { data } = await axios.delete(path, { data: { moduleName } });

    return data;
  },
};
