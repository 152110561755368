import { TimeDimensionName } from 'data/dimension';

export const convertTimeValueToExpression = (value: string): string => {
  if (value.includes('=')) {
    return value;
  }

  const lhs = ['q', 'y'].includes(value[0]) ? value[0] : TimeDimensionName;
  const rhs = value;

  return `${lhs}=${rhs}`;
};
