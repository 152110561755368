import { DimensionAllMarker, TimeDimensionName } from 'data/dimension';
import { BASE_CASE_NAME, SCENARIO } from 'data/scenarios';
import { ACTUAL, VERSION } from 'data/versions';
import { isUndefined } from 'lodash';

export const TimeValueKeyDelimiter = '--';

export const constructTimeDimKey = (key: string, granularity?: string | null): string => {
  return granularity ? `${granularity}${TimeValueKeyDelimiter}${key}` : key;
};

export const generateDataLookupKey = ({
  dimensions,
  rowsOrder,
  columnsOrder,
  firstVersion = ACTUAL,
}: {
  dimensions: Record<string, string>;
  rowsOrder: string[];
  columnsOrder: string[];
  firstVersion?: string;
}): string => {
  const keyValues = [];

  for (const name of [...rowsOrder, ...columnsOrder]) {
    // For the levels above the version level (Version entry not present in dimensions), add the default version
    if (name === VERSION && !dimensions?.[name]) {
      keyValues.push(`${name}:${firstVersion}`);
    } else if (name === SCENARIO && !dimensions?.[name] && dimensions[VERSION] !== ACTUAL) {
      // For the levels above the scenario level (Scenario entry not present in dimensions), add the base scenario and
      // don't include scenario key when version is actual
      keyValues.push(`${name}:${BASE_CASE_NAME}`);
    } else if (name === TimeDimensionName) {
      const value = dimensions?.[name];

      const timeDimensionValue = dimensions?.period
        ? constructTimeDimKey(value, dimensions?.period)
        : value;

      if (isUndefined(value)) {
        continue;
      }

      keyValues.push(`${TimeDimensionName}:${timeDimensionValue}`);
    } else {
      const value = dimensions?.[name];

      if (
        !isUndefined(value) &&
        value !== DimensionAllMarker &&
        !(name === SCENARIO && dimensions[VERSION] === ACTUAL) // don't include scenario key when version is actual
      ) {
        keyValues.push(`${name}:${value}`);
      }
    }
  }

  return keyValues.join('~');
};

//A helper function
export const transformKeyToDimensions = (lookupKey: string): Record<string, string> => {
  const dimensions: Record<string, string> = {};

  lookupKey.split('~').forEach((pairExpression) => {
    const [dimensionName, value] = pairExpression.split(':');

    dimensions[dimensionName] = value;
  });

  return dimensions;
};
