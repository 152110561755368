import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { getAssetUrl } from 'utils';
import { useUser } from 'utils/hooks';
import { G2Footer } from './footer';
import styles from './styles';
import { useShowPopupTimer } from './use-show-popup';
import { MAX_VIEW_LIMIT, generateSrc, getBodyText } from './utils';

const { Modal, ImageContainer, Label, G2Iframe } = styles;

export const G2ReviewPopup = (): ReactElement => {
  const { g2RewardForUsers, g2RewardForExistingUsers } = useFlags();
  const { viewCount, token } = useShowPopupTimer();
  const { user } = useUser();
  const intl = useIntl();

  const [showPopup, setShowPopup] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const reward = g2RewardForUsers[user.username];
  const reReviewReward = g2RewardForExistingUsers[user.username];

  useEffect(() => {
    if (viewCount >= 1) {
      setShowCancel(true);
    }
    if (token) {
      setShowPopup(true);
    }
  }, [token, viewCount]);

  const bodyText = getBodyText(reReviewReward ?? reward, !!reReviewReward);
  const titleText = intl.formatMessage({
    id: showForm ? 'g2review.popup.title.post_popup' : 'g2review.popup.title',
  });

  if (!(reward || reReviewReward) || viewCount >= MAX_VIEW_LIMIT) {
    return <></>;
  }

  return (
    <Modal
      $isFormVisible={!!showForm}
      $showCancel={showCancel}
      centered
      destroyOnClose
      footerExtra={
        <G2Footer
          existingUser={!!reReviewReward}
          setShowForm={setShowForm}
          setShowPopup={setShowPopup}
          showCancel={showCancel}
          showForm={showForm}
        />
      }
      open={showPopup}
      title={titleText}
      onCancel={(e) => {
        setShowPopup(false);
      }}
    >
      {showForm && <G2Iframe src={generateSrc(token, user.username)} title="g2review"></G2Iframe>}
      {!showForm && (
        <>
          <ImageContainer src={getAssetUrl('g2reward.svg')} />
          <Label>{bodyText}</Label>
        </>
      )}
    </Modal>
  );
};
