import { ReactComponent as AlertIcon } from 'assets/v2/alert-triangle.svg';
import { ReactComponent as TickIcon } from 'assets/v2/circle-check-filled.svg';
import { ReactComponent as CrossIcon } from 'assets/v2/circle-x-filled.svg';
import { IconShell, IconText, Modal } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontS } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const MessageInfo = styled.div`
  ${FontS}

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const PreSaveModal: FC<
  React.PropsWithChildren<{
    visible: boolean;
    savableRows: number;
    errorRows: number;
    onSave: () => void;
    onBack: () => void;
  }>
> = ({ savableRows, errorRows, visible, onSave, onBack }) => {
  const intl = useIntl();

  return (
    <Modal
      cancelText={intl.formatMessage({
        id: 'planning.lists.upload.pre_save_modal.fix_errors_label',
      })}
      centered
      destroyOnClose
      okText={intl.formatMessage({
        id: 'save',
      })}
      open={visible}
      title={
        <IconText
          color="yellow600"
          icon={AlertIcon}
          text={intl.formatMessage({ id: 'planning.lists.upload.pre_save_modal.title' })}
        />
      }
      width={560}
      onCancel={onBack}
      onOk={onSave}
    >
      <Wrapper>
        <MessageInfo>
          <IconShell color="green500" icon={TickIcon} />
          <FormattedMessage
            id="planning.lists.upload.pre_save_modal.message_1"
            values={{ rowCount: savableRows }}
          />
        </MessageInfo>

        {errorRows > 0 && (
          <MessageInfo>
            <IconShell color="red500" icon={CrossIcon} />
            <FormattedMessage
              id="planning.lists.upload.pre_save_modal.message_2"
              values={{ rowCount: errorRows }}
            />
          </MessageInfo>
        )}
      </Wrapper>
    </Modal>
  );
};
