import { Divider as AntDivider, type DividerProps as AntDividerProps } from 'antd';
import styled from 'styled-components';
import { StyledMargins, type StyledMarginsProps } from 'styles/styled-atoms';
import { type defaultTheme } from 'styles/theme';

export type DividerProps = AntDividerProps &
  StyledMarginsProps & { color?: keyof typeof defaultTheme.colors };

export const Divider = styled(AntDivider)<DividerProps>`
  ${StyledMargins}

  border-top: 1px solid ${({ theme, color = 'outlineDefault' }) => theme.colors[color]};

  .ant-divider-inner-text {
    display: none;
  }
`;
