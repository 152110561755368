import { type DimensionQueryParamsObject } from 'data/dimension';
import { useLastDimensionSelectionStore } from './store';

interface Props {
  orderInWhichSelectionsMade: string[];
  fValue: DimensionQueryParamsObject;
  selectedDimensionName: string;
}
export const getLastFilterWhichHasValue = ({
  orderInWhichSelectionsMade,
  fValue,
  selectedDimensionName,
}: Props): string | undefined => {
  let lastFilterWhichHasValue: string | undefined;

  // if selected option other than all in filter
  if (fValue[selectedDimensionName]) {
    lastFilterWhichHasValue = selectedDimensionName;
  } else {
    // if selected all option in filter
    [...orderInWhichSelectionsMade].reverse().some((dimName) => {
      if (fValue[dimName]) {
        lastFilterWhichHasValue = dimName;

        return true;
      }
    });
  }

  return lastFilterWhichHasValue;
};

export const processLastFilterDimNameToSendInApi = (
  id: string | number,
  lastFilterDimName?: string,
): string | undefined => {
  if (useLastDimensionSelectionStore.getState()?.hasLastFilterSelectionValuesInMemory?.[id]) {
    return undefined;
  }

  return lastFilterDimName;
};
