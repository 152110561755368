import { AsyncSwitcher } from 'components/ui/async-switcher';
import { PageLayout } from 'components/ui/layout';
import { FolderType, type HierarchialResource, useHierarchialResourcesByType } from 'data/folder';
import { findDeep } from 'deepdash-es/standalone';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { NoReportsCreatedPlaceholder } from './no-reports-placeholder';

export const FindAndRedirectLastBoard: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'board.title' });

  const folderQuery = useHierarchialResourcesByType(FolderType.Board);

  return (
    <PageLayout title={title}>
      <AsyncSwitcher {...folderQuery} components={{ Empty: NoReportsCreatedPlaceholder }}>
        {(data: HierarchialResource[]) => {
          const lastBoard = findDeep(
            // https://sonarcloud.io/project/issues?id=DrivetrainAi_drive-frontend&pullRequest=3277&resolved=false&types=CODE_SMELL
            [...data].reverse(),
            (v) => v.type === FolderType.Board,
            {
              childrenPath: ['children'],
            },
          )?.value;

          return lastBoard?.id ? (
            <Navigate replace to={`/reports/${lastBoard.id}`} />
          ) : (
            <NoReportsCreatedPlaceholder />
          );
        }}
      </AsyncSwitcher>
    </PageLayout>
  );
};
