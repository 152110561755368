import { type GroupBase, type StylesConfig } from 'react-select';
import { defaultTheme } from 'styles/theme';
import { getCommonStyles } from './common';

export const getSmallSizeStyles = <T, IsMulti extends boolean = boolean>(): StylesConfig<
  T,
  IsMulti,
  GroupBase<T>
> => {
  const CommonStyles = getCommonStyles<T, IsMulti>();

  return {
    ...CommonStyles,

    control: (base, props) => ({
      ...CommonStyles.control?.(base, props),
      fontSize: defaultTheme.fontSize.xs,
      height: 24,
      lineHeight: defaultTheme.lineHeight.xs,
      borderRadius: defaultTheme.borderRadius.s,
      minHeight: 24,
    }),

    placeholder: (base, props) => ({
      ...CommonStyles.placeholder?.(base, props),
      fontSize: defaultTheme.fontSize.xs,
      lineHeight: defaultTheme.lineHeight.xs,
    }),

    multiValue: (base, props) => ({
      ...CommonStyles.multiValue?.(base, props),
      height: 24,
      margin: 0,
      marginTop: 0,
    }),

    multiValueLabel: (base, props) => ({
      ...CommonStyles.multiValueLabel?.(base, props),
      fontSize: 14,
      padding: `0 ${defaultTheme.spacing[8]}`,
      lineHeight: 28,
    }),

    multiValueRemove: (base, props) => ({
      ...CommonStyles.multiValueRemove?.(base, props),
      height: '100%',
      borderLeft: `solid 1px ${defaultTheme.colors.outlineDefault}`,
      borderRadius: 0,
      width: 24,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
    }),
  };
};
