import { Granularity, type NewPeriod } from 'data';
import { constructTimeDimKey } from 'data/reports/utils';
import { type Dictionary, keyBy } from 'lodash';

export const createPeriodRangesMap = (periodRanges: NewPeriod[]): Dictionary<NewPeriod> => {
  if (!periodRanges) {
    return {};
  }
  const periodRangesMap = keyBy(periodRanges, (p) => {
    if (p.type === Granularity.Yearly) {
      return constructTimeDimKey(`${p.startDate}`, 'Y');
    }
    if (p.type === Granularity.Weekly) {
      return constructTimeDimKey(`${p.startDate}`, 'W');
    }
    if (p.type !== Granularity.Monthly) {
      const periodType = p.key.split(' ')[0]; //Q1 '23 => Q1;

      return constructTimeDimKey(`${p.startDate}`, periodType);
    }

    return `${p.startDate}`;
  });

  return periodRangesMap;
};
