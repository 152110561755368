import { Button, Form } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { type IntegrationInputFlowComp } from '../../types';
import { useConnectInputFlow } from './hooks/use-connect-input-flow';
import { FormInput, FormPassword } from './input-blocks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing[8]};
  gap: ${({ theme }) => theme.spacing[16]};
  background: ${({ theme }) => theme.colors.gray100};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

interface Props {
  flow: IntegrationInputFlowComp;
  onConnectionSuccess: () => void;
  item: IntegrationModalContent;
}

export const ConnectForm = ({ flow, item, onConnectionSuccess }: Props): ReactElement => {
  const [form] = Form.useForm();

  const { mutate, isLoading } = useConnectInputFlow(item, onConnectionSuccess);

  const onFinish = (): void => {
    form.validateFields().then((data) => {
      flow.inputs.forEach((input) => {
        if (input.outputFormatter && data[input.name]) {
          data[input.name] = input.outputFormatter(data[input.name]);
        }
      });
      mutate(data);
    });
  };

  return (
    <Wrapper>
      <span>{flow.inputTitle}</span>

      <StyledForm form={form} onFinish={onFinish}>
        {flow.inputs.map((input) => {
          if (input.type === 'input') {
            return <FormInput key={input.name} input={input} />;
          } else if (input.type === 'password') {
            return <FormPassword key={input.name} input={input} />;
          }
        })}

        <Form.Item>
          <Button $mt={12} block htmlType="submit" loading={isLoading} type="primary">
            <FormattedMessage id="submit" />
          </Button>
        </Form.Item>
      </StyledForm>
    </Wrapper>
  );
};
