import { DimensionAllMarker } from 'data/dimension';
import { type TraceOutputTerm } from 'data/formula-trace/types';
import { BASE_CASE_NAME, SCENARIO } from 'data/scenarios';
import { VERSION } from 'data/versions';
import { omit } from 'lodash';
import { formatName } from 'utils/data-formatter';

export const getDimsLabel = (dims: { [key: string]: string }): string => {
  const dimItems: string[] = [];

  Object.entries(dims || {}).forEach(([key, val]) => {
    if ([DimensionAllMarker, '**'].includes(val) || (key === SCENARIO && val === BASE_CASE_NAME)) {
      return;
    }
    dimItems.push(`${formatName(key)} = ${val}`);
  });

  return dimItems.join('; ');
};

const combineAllDims = (cell: TraceOutputTerm, showVersion: boolean): { [key: string]: string } => {
  return {
    ...(cell?.dims || {}),
    ...(cell?.scenario ? { [SCENARIO]: cell?.scenario } : {}),
    ...(cell?.version && showVersion ? { [VERSION]: cell?.version } : {}),
    ...(cell?.t || cell?.period ? { time: cell?.period || cell?.t } : {}),
  };
};

export const getDimsLabelForCell = (cell: TraceOutputTerm, showVersion: boolean): string => {
  return getDimsLabel(combineAllDims(cell, showVersion));
};

export const getDimsLabelForCellComponent = ({
  cell,
  parentCell,
  sameMetricsAsParent,
  showVersion,
}: {
  cell: TraceOutputTerm;
  parentCell: TraceOutputTerm;
  sameMetricsAsParent: boolean;
  showVersion: boolean;
}): string => {
  if (sameMetricsAsParent) {
    const parentDims = combineAllDims(parentCell, showVersion);
    const componentDims = combineAllDims(cell, showVersion);

    const refinedDims = omit(
      componentDims,
      Object.keys(componentDims).filter(
        (key) => parentDims[key] && parentDims[key] === componentDims[key],
      ),
    );

    return getDimsLabel(refinedDims);
  }

  return getDimsLabel(combineAllDims(cell, showVersion));
};
