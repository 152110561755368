import { type QueryTableColumnDataType, type VisualQueryFilterOperator } from 'data/big-query';
import { DTQL_VISUAL_QUERY_OPERATOR_LIST } from 'data/big-query/constants/dtql-visual';
import { type IntlShape } from 'react-intl';

interface Props {
  columnType?: QueryTableColumnDataType;
  operator?: VisualQueryFilterOperator;
  intl: IntlShape;
}

interface ReturnProps {
  operatorOptions: {
    label: string;
    value: VisualQueryFilterOperator;
    supportedDataTypes: QueryTableColumnDataType[];
  }[];
  operatorValueOption?: {
    label: string;
    value: VisualQueryFilterOperator;
    supportedDataTypes: QueryTableColumnDataType[];
  };
}

export const constructRuleOperatorOptions = ({
  columnType,
  operator,
  intl,
}: Props): ReturnProps => {
  const operatorOptions = DTQL_VISUAL_QUERY_OPERATOR_LIST.filter(
    (option) => columnType && option.supportedDataTypes.includes(columnType),
  ).map((o) => ({
    ...o,
    label: intl.formatMessage({ id: o.label }),
  }));

  const operatorValueOption = operatorOptions.find((o) => o.value === operator);

  return {
    operatorOptions,
    operatorValueOption,
  };
};

export const getDefaultOperator = (
  dataType: QueryTableColumnDataType,
):
  | {
      value: VisualQueryFilterOperator;
      label: string;
      supportedDataTypes: QueryTableColumnDataType[];
    }
  | undefined => {
  return DTQL_VISUAL_QUERY_OPERATOR_LIST.find(
    (option) => dataType && option.supportedDataTypes.includes(dataType),
  );
};
