import { type QueryClient } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension';
import { ListDataValidationCondition, type ListDataValidationConfig } from 'data/modelling/lists';

export const prefetchDataValidationPermissibleValues = async (
  queryClient: QueryClient,
  dataValidation?: Record<string, ListDataValidationConfig>,
): Promise<Record<string, Set<string>>> => {
  const dataValidationPermissibleValuesMap: Record<string, Set<string>> = {};

  for (const [columnName, validation] of Object.entries(dataValidation || {})) {
    if (validation?.condition === ListDataValidationCondition.ExistingColumn) {
      const queryKey = ['selected-column-values', validation.source];

      const queryFn = async () => {
        return ModelDimensionApi.getColumnValues(validation.source);
      };

      const columnValues = (
        await queryClient.fetchQuery({
          queryKey,
          queryFn,
          staleTime: StaleTime.Short,
        })
      ).map((v) => v.value);

      dataValidationPermissibleValuesMap[columnName] = new Set(columnValues);
    }
  }

  return dataValidationPermissibleValuesMap;
};
