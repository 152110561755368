import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down.svg';
import { type ReactElement, type ReactNode } from 'react';
import styled from 'styled-components';
import { IconShell } from '../../../../icon-shell';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textBody1};
  background-color: ${({ theme }) => theme.colors.gray200};
  padding: 0 ${({ theme }) => theme.spacing[6]};
  gap: ${({ theme }) => theme.spacing[4]};
  white-space: nowrap;
  cursor: default;
`;

interface Props {
  label: ReactNode;
}

export const SubscriptTag = ({ label }: Props): ReactElement => {
  return (
    <Wrapper>
      <div>{label}</div>
      <IconShell color="iconDefault" cursor="pointer" icon={ChevronDownIcon} />
    </Wrapper>
  );
};
