import styled from 'styled-components';
import { Button } from '../../button';

export default {
  ButtonGroup: styled(Button.Group)`
    &.ant-btn-group > .ant-btn-primary:not(:first-child) {
      &:not(:disabled) {
        border-inline-start-color: ${({ theme }) => theme.colors.primary600};
      }

      &:active {
        border-inline-start-color: ${({ theme }) => theme.colors.primary700};
      }

      &[disabled] {
        border-left: 1px solid ${({ theme }) => theme.colors.gray500};
      }
    }

    &.ant-btn-group > .ant-btn-primary:not(:last-child) {
      &:not(:disabled) {
        border-inline-end-color: ${({ theme }) => theme.colors.primary600};
      }

      &:active {
        border-inline-end-color: ${({ theme }) => theme.colors.primary700};
      }
    }
  `,
};
