import { ReactComponent as BookIcon } from 'assets/v2/book-2.svg';
import { ReactComponent as GiftIcon } from 'assets/v2/gift.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: ${({ theme: { spacing } }) => `${spacing[4]} 0`};
  gap: ${({ theme }) => theme.spacing[4]};
  overflow: hidden;
`;

const MenuItem = styled.div`
  ${FontXs};

  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => `${spacing[6]}`};
  margin: ${({ theme: { spacing } }) => `0 ${spacing[4]}`};
  gap: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.textTitle1};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.bgMedium};
  }
`;

const Icon = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing[2]};
`;

declare global {
  interface Window {
    Beamer?: {
      show: () => void;
    };
  }
}

export const Content = (): ReactElement => {
  const triggerWhatsNewDrawer = () => {
    window.Beamer?.show();
  };

  return (
    <Wrapper>
      <MenuItem onClick={triggerWhatsNewDrawer}>
        <Icon>
          <IconShell color="textBody2" icon={GiftIcon} />
        </Icon>
        <FormattedMessage id="help.whats_new" />
      </MenuItem>

      <a href="https://docs.drivetrain.ai" rel="noreferrer" target="_blank">
        <MenuItem>
          <Icon>
            <IconShell color="textBody2" icon={BookIcon} />
          </Icon>
          <FormattedMessage id="help.view_documentation" />
        </MenuItem>
      </a>
    </Wrapper>
  );
};
