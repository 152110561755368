/* eslint-disable filenames/match-regex */
import { HtmlHead } from 'components/ui/html-head';
import { type PropsWithChildren, type ReactElement } from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: ${({ theme: { font } }) => font.text};
  color: ${({ theme: { colors } }) => colors.textBody1};
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const PageLayout = ({
  children,
  title,
}: PropsWithChildren<{ title?: string[] }>): ReactElement => (
  <Layout>
    {title && <HtmlHead titles={title} />}
    <Content>{children}</Content>
  </Layout>
);
