import { forwardRef, type ReactElement, type Ref, type RefObject } from 'react';
import ReactSelect, { type GroupBase, type Props } from 'react-select';
import { InlineDropdownIndicator } from './inline-dropdown-indicator';
import { type OptionBase, SelectOptionOld } from './option';
import { getInlineDropdownStyles } from './styles/inline-select';

export type InlineSelectProps<T, IsMulti extends boolean = false> = Props<
  T,
  IsMulti,
  GroupBase<T>
> & { ref?: RefObject<unknown> };

const InlineSelectBase = <T extends OptionBase, IsMulti extends boolean>(
  { classNamePrefix, components, styles, ...props }: InlineSelectProps<T, IsMulti>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  return (
    <ReactSelect<T, IsMulti>
      classNamePrefix={classNamePrefix || 'react-select'}
      components={{
        Option: SelectOptionOld,
        DropdownIndicator: InlineDropdownIndicator,
        ...components,
      }}
      isSearchable={false}
      styles={{
        ...getInlineDropdownStyles<T, IsMulti>(),
        ...styles,
      }}
      {...props}
      ref={ref}
    />
  );
};

export const InlineSelectOld = forwardRef(InlineSelectBase) as <
  T extends OptionBase = OptionBase,
  IsMulti extends boolean = false,
>(
  p: InlineSelectProps<T, IsMulti> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
