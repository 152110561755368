import { type RawData } from 'data/reports/types';
import { generateKeyPath } from 'data/reports/utils';

export const generateDataLookupMap = ({
  data,
  metricPivotMap,
}: {
  data: RawData[];
  metricPivotMap: Map<
    string,
    {
      rows: string[];
      columns: string[];
    }
  >;
}): Record<string, number | null> => {
  const entry: Record<string, number | null> = {};

  for (const dataPoint of data) {
    const metricName = dataPoint.metric;

    const pivot = metricPivotMap.get(metricName as string);
    const allDimensions = [...(pivot?.rows || []), ...(pivot?.columns || [])];

    const key = generateKeyPath(dataPoint, allDimensions);

    // generateKeyPath returns null if the data entry is invalid
    if (key !== null) {
      entry[key] = dataPoint.result as number | null;
    }
  }

  return entry;
};
