import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension/api';
import { type ModelDimension } from 'data/modelling/dimension/types';

export const useDimensions = (): UseQueryResult<ModelDimension[], unknown> => {
  const allDimensionsQuery = useQuery(['dimensions'], () => ModelDimensionApi.findAll(), {
    keepPreviousData: false,
    staleTime: StaleTime.Short,
  });

  return allDimensionsQuery;
};
