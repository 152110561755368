import { type UseQueryResult } from '@tanstack/react-query';
import { isEmpty as _isEmpty } from 'lodash';
import { type ReactElement, type FC, type ReactNode } from 'react';
import { Empty } from '../atomic-components/empty';
import { Spinner } from '../atomic-components/spinner';
import { DefaultErrorComp } from './default-error-comp';

export type AsyncSwitcherProps = Pick<
  UseQueryResult<unknown, unknown>,
  'isLoading' | 'isError' | 'data'
> & {
  // TODO:: Move this data to generic type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children(data: any): ReactNode;
  /**
   * {Boolean}
   * To override the isEmpty check within a nested construct
   */
  isEmpty?: boolean;
  showError?: boolean;
  error?: unknown;
  components?: {
    Empty?: typeof Empty;
    Error?: ({ error }: { error?: unknown }) => ReactElement;
    Spinner?: typeof Spinner;
  };
  loaderSize?: 'small' | 'medium' | 'large';
  emptyTitle?: ReactNode;
};
export const AsyncSwitcher: FC<AsyncSwitcherProps> = ({
  children,
  isLoading,
  isError,
  error,
  showError = true,
  data,
  components,
  isEmpty = _isEmpty(data),
  loaderSize,
  emptyTitle,
}) => {
  const compsToRender = {
    Empty: components?.Empty || Empty,
    Error: components?.Error || DefaultErrorComp,
    Spinner: components?.Spinner || Spinner,
  };

  if (isLoading) {
    return <compsToRender.Spinner size={loaderSize || 'large'} />;
  }

  if (showError && isError) {
    return <compsToRender.Error error={error} />;
  }

  if (isEmpty) {
    return <compsToRender.Empty title={emptyTitle} />;
  }

  return <>{children(data)}</>;
};
