import { Popover } from 'components/ui/atomic-components';
import { type PropsWithChildren, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

const { Wrapper, Header, ListWrapper } = styles;

export const MetricErrorPopover = ({
  errors,
  children,
}: PropsWithChildren<{ errors: string[] }>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      content={<Content errors={errors} />}
      destroyTooltipOnHide
      open={isOpen}
      placement="bottomRight"
      trigger={['hover']}
      onEsc={() => setIsOpen(false)}
      onOpenChange={(v) => setIsOpen(v)}
    >
      {children}
    </Popover>
  );
};

const Content = ({ errors }: { errors: string[] }): ReactElement => {
  return (
    <Wrapper>
      <Header>
        <FormattedMessage
          id="planning.metric.error_popup.header"
          values={{ count: errors.length }}
        />
      </Header>
      <ListWrapper>
        {errors.map((e) => (
          <li key={e}>{e}</li>
        ))}
      </ListWrapper>
    </Wrapper>
  );
};
