import { type FC } from 'react';
import styled from 'styled-components';
import { StyledMargins, type StyledMarginsProps } from 'styles/styled-atoms';
import { type SizeType, SpinnerIcon } from '../spinner-icon';

const SpinnerWrapper = styled.div`
  ${StyledMargins};

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Spinner: FC<
  React.PropsWithChildren<Partial<SizeType> & { className?: string } & StyledMarginsProps>
> = ({ className, size = 'large', ...rest }) => {
  return (
    <SpinnerWrapper className={className} {...rest}>
      <SpinnerIcon size={size} />
    </SpinnerWrapper>
  );
};
