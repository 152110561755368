import { type OptionBaseOld, Select } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectorItem } from './select-item';

interface Props {
  options: OptionBaseOld[];
  value?: OptionBaseOld;
  onChange?: (value: OptionBaseOld) => void;
}

export const OperatorSelector = ({ value, options, onChange }: Props): ReactElement => {
  return (
    <SelectorItem
      title={<FormattedMessage id="module.dimension.new_filter.operator_input_label" />}
    >
      <Select
        isSearchable
        options={options}
        placeholder={
          <FormattedMessage id="module.dimension.new_filter.operator_input_placeholder" />
        }
        size="medium"
        value={value || null}
        width={320}
        onChange={(option) => option && onChange?.(option)}
      />
    </SelectorItem>
  );
};
