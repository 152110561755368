import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXl } from 'styles/typography';
import { BackButton } from './back-button';
import { NewListButton } from './new-list-button';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing[16]};
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const TitleText = styled.div`
  ${FontXl};

  color: ${({ theme }) => theme.colors.gray800};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const PageHeader = (): ReactElement => {
  return (
    <Wrapper>
      <Title>
        <BackButton />
        <TitleText>
          <FormattedMessage id="lists.list.header" />
        </TitleText>
      </Title>
      <NewListButton />
    </Wrapper>
  );
};
