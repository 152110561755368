import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { ListsApi } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useCleanupListRecon = (): UseMutationResult<
  void,
  AxiosError,
  {
    listId: number;
  },
  unknown
> => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  return useMutation(({ listId }: { listId: number }) => ListsApi.cleanupListRecon({ listId }), {
    onSuccess: (_, { listId }) => {
      queryClient.invalidateQueries(['lists', listId]);

      notification.success({
        message: intl.formatMessage({ id: 'lists.cleanup_recon.success' }),
      });
    },
    onError: defaultApiErrorHandler,
  });
};
