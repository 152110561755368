/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

export interface TesseractCellDebugToolStore {
  data: any;
  setData: (data: any) => void;
}

export const useTesseractCellDebugToolStore = create<TesseractCellDebugToolStore>()((set, get) => ({
  data: false,

  setData: (state) => set({ data: state }),
}));
