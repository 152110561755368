import { ReactComponent as CollapsibleExpandIcon } from 'assets/ic_breadcrumb_right.svg';
import { IconShell, Tooltip } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { type CommonProps } from './types';

interface Props {
  className?: string;
  canShowTooltip: boolean;
  isExpanded: boolean;
  commonUICompProps: {
    isExpanded: boolean;
    transitionDelay: string;
  };
  onClick: () => void;
}
const ExpandCollapseArrow = styled.button<CommonProps>`
  /* position css */
  position: fixed;
  top: 18px;
  left: ${() => `calc(var(--left-sidebar-width, 24px) + ${56}px - 14px)`};
  transition: left 300ms cubic-bezier(0.2, 0, 0, 1);
  transition-delay: ${({ transitionDelay }) => transitionDelay};
  z-index: ${({ theme }) => theme.zIndex[30]};

  /* looks css */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  color: ${({ theme }) => theme.colors.black300};
  border-radius: ${({ theme }) => theme.border.radius.full};
  border: 1px solid ${({ theme }) => theme.colors.black40};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow[2]};

  :hover {
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.blue60};
    background-color: ${({ theme }) => theme.colors.blue60};
  }

  svg {
    transform: ${({ isExpanded }) => isExpanded && 'rotate(180deg)'};
  }
`;

export const ExpandCollapseArrowBtn: FC<React.PropsWithChildren<Props>> = ({
  className,
  canShowTooltip,
  isExpanded,
  commonUICompProps,
  onClick,
}) => {
  return (
    <Tooltip
      placement="right"
      title={isExpanded ? <FormattedMessage id="collapse" /> : <FormattedMessage id="expand" />}
      zIndex={canShowTooltip ? 999 : -1}
    >
      <ExpandCollapseArrow className={className} {...commonUICompProps} onClick={onClick}>
        <IconShell icon={CollapsibleExpandIcon} />
      </ExpandCollapseArrow>
    </Tooltip>
  );
};
