import { type TooltipPlacement } from 'antd/es/tooltip';
import { type FC, type ReactNode, type SVGProps } from 'react';
import styled from 'styled-components';
import { type defaultTheme } from 'styles/theme';
import { IconShell, type IconType, type SizeValues, type SvgIconColor } from '../icon-shell';
import { Tooltip } from '../tooltip';

const Wrapper = styled.div<{ iconPosition: 'before' | 'after'; gap: number }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ gap }) => `${gap}px`};
  flex-direction: ${({ iconPosition }: { iconPosition: 'before' | 'after' }) => {
    return iconPosition === 'after' ? 'row-reverse' : 'row';
  }};
`;

export const IconText: FC<
  React.PropsWithChildren<
    SVGProps<SVGSVGElement> & {
      color?: SvgIconColor;
      fill?: SvgIconColor;
      icon: IconType;
      iconPosition?: 'before' | 'after';
      iconTooltip?: ReactNode;
      size?: SizeValues;
      text?: ReactNode;
      tooltipPlacement?: TooltipPlacement;
      gap?: keyof typeof defaultTheme.spacing;
    }
  >
> = ({
  className,
  icon,
  iconTooltip,
  text,
  iconPosition = 'before',
  children,
  color = 'iconDefault',
  tooltipPlacement,
  gap,
  ...rest
}) => (
  <Wrapper className={className} gap={gap || 8} iconPosition={iconPosition}>
    {iconTooltip ? (
      <>
        <Tooltip placement={tooltipPlacement} title={iconTooltip}>
          <IconShell color={color} icon={icon} {...rest} />
        </Tooltip>
      </>
    ) : (
      <IconShell color={color} icon={icon} {...rest} />
    )}
    {text || children}
  </Wrapper>
);
