import { useCurrencies } from 'data/currencies/hooks/use-currencies';
import { useTenantCurrencies } from 'data/currencies/hooks/use-tenant-currencies';
import { currencyIconMap } from './constants';
import { type CurrencySelectOption } from './types';

interface Props {
  enableAllCurrenciesOptions?: boolean;
}

export const useCurrencyOptions = ({
  enableAllCurrenciesOptions,
}: Props): CurrencySelectOption[] => {
  const allCurrencies = useCurrencies();
  const tenantCurrencies = useTenantCurrencies();

  const options: CurrencySelectOption[] = [];

  for (const currency of enableAllCurrenciesOptions ? allCurrencies : tenantCurrencies) {
    const option: CurrencySelectOption = {
      label: currency.displayName,
      value: currency.name,
      icon: currencyIconMap[currency.name],
    };

    options.push(option);
  }

  return options;
};
