import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { DataFormatModal } from 'components/ui/data-format-modal';
import { type DataFormatType, type DataFormatWithType } from 'data/metrics';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { type DataFormattingType } from 'utils/data-formatter/types';

interface Props {
  open: boolean;
  colName: string;
  onClose: () => void;
}

export const ColumnNumberFormat = ({ open, colName, onClose }: Props): ReactElement => {
  const intl = useIntl();

  const { updateListMutation } = useListMutations();

  const listId = useListStoreContext((s) => s.id);
  const dataFormatMap = useListStoreContext((s) => s.config.dataFormatMap);
  const config = useListStoreContext((s) => s.config);
  const setConfig = useListStoreContext((s) => s.setConfig);

  const dataFormat = dataFormatMap?.[colName] as DataFormatWithType;

  const onSubmit = async (type: DataFormattingType, updatedDataFormat: DataFormatType) => {
    const updatedConfig = {
      ...config,
      dataFormatMap: {
        ...dataFormatMap,
        [colName]: { type, ...updatedDataFormat },
      },
    };

    setConfig(updatedConfig);

    updateListMutation
      .mutateAsync({
        id: listId,
        list: { config: updatedConfig },
        skipToast: true,
      })
      .catch(() => {
        setConfig(config);
      });

    onClose();
  };

  return (
    <DataFormatModal
      dataFormat={dataFormat}
      title={intl.formatMessage({ id: 'data_format.modal.menu_title' })}
      type={dataFormat?.type}
      visible={open}
      onCancel={onClose}
      onSave={onSubmit}
    />
  );
};
