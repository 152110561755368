import { type Extension } from '@codemirror/state';
import { EditorView, type ViewUpdate } from '@codemirror/view';

export const updateListener = ({
  onChange,
}: {
  onChange?: (value?: string) => void;
}): Extension => {
  return EditorView.updateListener.of((view: ViewUpdate) => {
    if (view.docChanged) {
      onChange?.(view.state.doc.toString());
    }
  });
};
