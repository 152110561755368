import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down.svg';
import { IconShell, type OptionBase } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { components, type DropdownIndicatorProps } from 'react-select';

export const DropdownIndicator = <T extends OptionBase, IsMulti extends boolean>(
  props: DropdownIndicatorProps<T, IsMulti>,
): ReactElement => {
  return (
    <components.DropdownIndicator {...props}>
      <IconShell icon={ChevronDownIcon} size="xs" />
    </components.DropdownIndicator>
  );
};
