import { css } from 'styled-components';

export const TooltipStyles = css`
  .ant-tooltip {
    max-width: 319px;
  }

  .ant-tooltip .ant-tooltip-inner {
    padding: ${({ theme: { spacing } }) => `${spacing[4]}`};
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({ theme: { colors } }) => colors.gray900};
    box-shadow: ${({ theme: { shadow } }) => shadow.softMini};
    border-radius: ${({ theme: { borderRadius } }) => borderRadius.xs};
    min-height: auto;
    min-width: auto;
  }

  .ant-tooltip.comfortable .ant-tooltip-inner {
    padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[8]}`};
    border-radius: ${({ theme: { borderRadius } }) => borderRadius.m};
  }
`;
