import { IconShell } from 'components/ui/atomic-components';
import { QueryTableColumnDataType } from 'data/big-query';
import { useSupportedDateFormats } from 'data/date-format/hooks/use-supported-date-formats';
import {
  SAMPLE_DATE,
  formatTransformer,
  formattedDate,
} from 'data/date-format/utils/formatted-date';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';
import { type AllowedTypes, IconMapper, type ColumnType, LabelKeyMapper } from './utils';

const DateFormatOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 100%;
`;

const DateTypeOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing[12]};
`;

const PrimaryLabel = styled.div`
  ${FontS}

  color: ${({ theme }) => theme.colors.textTitle1};
`;

const SecondaryLabel = styled.div`
  ${FontXs}

  color: ${({ theme }) => theme.colors.gray400};
`;

interface Props {
  dateFormat?: string;
}

export const useListColumnTypes = ({ dateFormat }: Props): ColumnType[] => {
  const intl = useIntl();
  const { tempSccl } = useFlags();

  const dateFormats = useSupportedDateFormats();

  return [
    {
      key: QueryTableColumnDataType.String,
      label: intl.formatMessage({ id: LabelKeyMapper[QueryTableColumnDataType.String] }),
      icon: (
        <div>
          <IconShell
            color="iconDefault"
            icon={IconMapper[QueryTableColumnDataType.String as AllowedTypes]}
          />
        </div>
      ),
    },
    {
      key: QueryTableColumnDataType.Date,
      label: (
        <DateTypeOption>
          <PrimaryLabel>
            {intl.formatMessage({ id: LabelKeyMapper[QueryTableColumnDataType.Date] })}
          </PrimaryLabel>
          <SecondaryLabel>{dateFormat && formatTransformer(dateFormat)}</SecondaryLabel>
        </DateTypeOption>
      ),
      dateFormat,
      icon: (
        <div>
          <IconShell
            color="iconDefault"
            icon={IconMapper[QueryTableColumnDataType.Date as AllowedTypes]}
          />
        </div>
      ),
      children: dateFormats?.map((f) => {
        return {
          label: (
            <DateFormatOption>
              <PrimaryLabel>{formatTransformer(f)}</PrimaryLabel>
              <SecondaryLabel>{formattedDate(f, SAMPLE_DATE)}</SecondaryLabel>
            </DateFormatOption>
          ),
          key: `df~${f}`,
        };
      }),
    },
    {
      key: QueryTableColumnDataType.Numeric,
      label: intl.formatMessage({ id: LabelKeyMapper[QueryTableColumnDataType.Numeric] }),
      icon: (
        <div>
          <IconShell
            color="iconDefault"
            icon={IconMapper[QueryTableColumnDataType.Numeric as AllowedTypes]}
          />
        </div>
      ),
    },
    ...(tempSccl
      ? [
          {
            key: 'FORMULA',
            label: intl.formatMessage({ id: LabelKeyMapper.FORMULA }),
            icon: (
              <div>
                <IconShell color="iconDefault" icon={IconMapper.FORMULA} />
              </div>
            ),
          },
        ]
      : []),
  ];
};
