import { Radio } from 'antd';
import styled, { css } from 'styled-components';
import { switchProp, prop } from 'styled-tools';

type CompoundComp = typeof RadioGroup & {
  Button: typeof RadioButtonGroupButton;
};

// this has been tested only with two radio buttons in it
const RadioGroup = styled(Radio.Group)`
  background-color: ${({ theme }) => theme.colors.bgMedium};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  display: flex;
  ${switchProp(prop('size', 'middle'), {
    small: css`
      height: 28px;
    `,
    middle: css`
      height: 32px;
    `,
  })};
`;

const RadioButtonGroupButton = styled(Radio.Button)`
  && {
    background-color: ${({ theme }) => theme.colors.bgMedium};
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    color: ${({ theme }) => theme.colors.textSecondary};
    height: 100%;
    padding-left: ${({ theme }) => theme.spacing[4]};
    padding-right: ${({ theme }) => theme.spacing[4]};
    transition: color 0.3s, background 0.3s;

    > span {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &&&.ant-radio-button-wrapper-checked {
    height: 100%;
    color: ${({ theme }) => theme.colors.textPrimary};
    background-color: ${({ theme }) => theme.colors.primary100};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
  }

  &.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;

export const RadioButtonGroup: CompoundComp = RadioGroup as CompoundComp;
RadioButtonGroup.Button = RadioButtonGroupButton;
