import { notification as antdnotification } from 'antd';
import { ReactComponent as CommentsIcon } from 'assets/ic_comments.svg';
import { ReactComponent as WarningIcon } from 'assets/v2/alert-circle-filled.svg';
import { ReactComponent as SuccessIcon } from 'assets/v2/circle-check-filled.svg';
import { ReactComponent as DangerIcon } from 'assets/v2/circle-x-filled.svg';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { ReactComponent as InfoIcon } from 'assets/v2/info-circle-filled.svg';
import { ReactComponent as XIcon } from 'assets/v2/x.svg';
import { PorscheThemeWrapper } from 'components/ui/porsche-theme-wrapper';
import { type ReactNode } from 'react';
import { IconShell } from '../icon-shell';
import { SpinnerIcon } from '../spinner-icon';
import { TextAndButtons } from './action-buttons';
import { NotificationType, type NotifierProps } from './types';

antdnotification.config({
  placement: 'top',
  closeIcon: <IconShell icon={CloseIcon} size="sm" />,
  duration: 3,
  prefixCls: 'dt-notification',
});

const notify = (type: number, props: NotifierProps) => {
  const { actions, message, duration, ...rest } = props;

  const closeNotifier = (type: number) => {
    switch (type) {
      case NotificationType.OK:
        antdnotification.destroy();
        break;
      case NotificationType.WARN:
        antdnotification.destroy();
        break;
      case NotificationType.ERR:
        antdnotification.destroy();
        break;
      case NotificationType.INFO:
        antdnotification.destroy();
    }
  };
  const messageComp: ReactNode = actions ? (
    <TextAndButtons cleanUp={() => closeNotifier(type)} message={message} {...actions} />
  ) : (
    message
  );
  const timePeriod: number = actions ? 0 : duration ?? 3;

  switch (type) {
    case NotificationType.OK:
      antdnotification.success({
        key: actions ? 'ok' : undefined,
        message: messageComp,
        icon: <IconShell color="green500" icon={SuccessIcon} size="sm" />,
        duration: timePeriod,
        closeIcon: <IconShell icon={XIcon} />,
        ...rest,
      });
      break;
    case NotificationType.WARN:
      antdnotification.warning({
        key: actions ? 'warn' : undefined,
        message: messageComp,
        icon: <IconShell color="yellow500" icon={WarningIcon} size="sm" />,
        duration: timePeriod,
        closeIcon: <IconShell icon={XIcon} />,
        ...rest,
      });
      break;
    case NotificationType.INFO:
      antdnotification.info({
        key: actions ? 'info' : undefined,
        message: messageComp,
        icon: <IconShell color="primary500" icon={InfoIcon} size="sm" />,
        duration: timePeriod,
        closeIcon: <IconShell icon={XIcon} />,
        ...rest,
      });
      break;
    case NotificationType.SPINNER:
      antdnotification.info({
        message: messageComp,
        icon: (
          <PorscheThemeWrapper>
            <SpinnerIcon size="small" />
          </PorscheThemeWrapper>
        ),
        duration: null,
        closable: false,
        className: 'dt-notification-spinner',
        ...rest,
      });
      break;
    default:
      antdnotification.error({
        key: actions ? 'error' : undefined,
        message: messageComp,
        icon: <IconShell color="red500" icon={DangerIcon} size="sm" />,
        duration: timePeriod,
        closeIcon: <IconShell icon={XIcon} />,
        ...rest,
      });
  }
};

export const notification = {
  success: (props: NotifierProps): void => {
    notify(NotificationType.OK, props);
  },
  warning: (props: NotifierProps): void => {
    notify(NotificationType.WARN, props);
  },
  error: (props: NotifierProps): void => {
    notify(NotificationType.ERR, props);
  },
  info: (props: NotifierProps): void => {
    notify(NotificationType.INFO, props);
  },
  spinner: (props: NotifierProps & { key: string }): void => {
    notify(NotificationType.SPINNER, props);
  },
  /**
   * Couple more options as per Antd notification may be needed
   * - open(), warn(), err(), close(), destroy()
   */
};

export const conversationNotification = {
  info: (props: NotifierProps): void => {
    antdnotification.info({
      ...props,
      key: 'conversations-info',
      icon: <CommentsIcon />,
      placement: 'bottomLeft',
      duration: 6,
      onClick: () => {
        antdnotification.destroy();
        if (props?.onClick) {
          props?.onClick();
        }
      },
    });
  },
};
