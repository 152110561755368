import { useQueryClient } from '@tanstack/react-query';
import { prefetchDataValidationPermissibleValues } from 'components/modules/modelling/lists/right-section/utils';
import { type ListDataValidationConfig } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';

export const useUpdateDataValidationPermissibleValuesMap = (): ((
  updatedDataValidation?: Record<string, ListDataValidationConfig>,
) => Promise<void>) => {
  const queryClient = useQueryClient();

  const setDataValidationPermissibleValuesMap = useListStoreContext(
    (s) => s.setDataValidationPermissibleValuesMap,
  );

  return async (
    updatedDataValidation?: Record<string, ListDataValidationConfig>,
  ): Promise<void> => {
    const dataValidationPermissibleValuesMap = await prefetchDataValidationPermissibleValues(
      queryClient,
      updatedDataValidation,
    );

    setDataValidationPermissibleValuesMap(dataValidationPermissibleValuesMap);
  };
};
