import { ReactComponent as CheckIcon } from 'assets/v2/check.svg';
import { Alert, IconShell } from 'components/ui/atomic-components';
import { CSVUploadWorkflowStage } from 'data/lists';
import { type SetStateAction, type ReactElement, type Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM } from 'styles/typography';

const Wrapper = styled.div`
  ${FontM};

  display: flex;
  margin-top: ${({ theme }) => theme.spacing[16]};
  align-items: center;
  color: ${({ theme }) => theme.colors.textBody2};
`;

const ErrorActionText = styled.u`
  cursor: pointer;
`;

interface Props {
  currentStage: CSVUploadWorkflowStage;
  errorRowCount: number;
  errorFilter: boolean;
  setErrorFilter: Dispatch<SetStateAction<boolean>>;
}

export const ImportInfo = ({
  currentStage,
  errorRowCount,
  errorFilter,
  setErrorFilter,
}: Props): ReactElement => {
  if (currentStage === CSVUploadWorkflowStage.ColumnMapping) {
    return (
      <Wrapper>
        <FormattedMessage id="planning.lists.upload.csv_column_mapping.info" />
      </Wrapper>
    );
  } else if (errorRowCount) {
    return (
      <Alert
        closable={false}
        message={
          errorFilter ? (
            <>
              <FormattedMessage
                id="planning.dimensions.upload.preview_modal.errors_only_label"
                values={{ errorRowCount }}
              />{' '}
              <ErrorActionText onClick={() => setErrorFilter(false)}>
                <FormattedMessage id="planning.lists.upload.show_all_rows" />
              </ErrorActionText>
            </>
          ) : (
            <>
              <FormattedMessage
                id="planning.dimensions.upload.preview_modal.errors_label"
                values={{ errorRowCount }}
              />{' '}
              <ErrorActionText onClick={() => setErrorFilter(true)}>
                <FormattedMessage id="planning.lists.upload.show_error_rows" />
              </ErrorActionText>
            </>
          )
        }
        type="error"
      />
    );
  }

  return (
    <Alert
      closable={false}
      icon={<IconShell color="green600" icon={CheckIcon} />}
      message="The data is clean - no errors found!"
      showIcon
      type="success"
    />
  );
};
