import { Button } from 'components/ui/atomic-components';
import { Menu } from 'components/ui/atomic-components/menu';
import styled, { css } from 'styled-components';
import { P, SingleLineEllipsis } from 'styles/typography';
import { type CommonProps } from './types';

export const Wrapper = styled.div<CommonProps>`
  position: relative;
  width: ${({ isExpanded }) => (isExpanded ? '217px' : '24px')};
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1), box-shadow 300ms linear;
  transition-delay: ${({ transitionDelay }) => transitionDelay};
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      --left-sidebar-width: 217px;
    `}

  & + * {
    flex: 1;
  }

  ${Button}.ant-btn-text:hover {
    background-color: ${({ theme: { colors } }) => colors.blue20};
  }
`;

export const VisibilityWrapper = styled.div<CommonProps>`
  height: 100%;
  width: 100%;
  min-width: 217px;
  overflow: ${({ isExpanded }) => (isExpanded ? 'auto' : 'hidden')};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  visibility: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
  transition: opacity 350ms linear, visibility 350ms linear;
  transition-delay: ${({ transitionDelay }) => transitionDelay};
  display: flex;
  flex-direction: column;
`;

export const NavWrapper = styled.div<CommonProps>`
  /* pos css */
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex[30]};
  overflow: hidden;

  /* looks css */
  height: 100%;
  width: var(--left-sidebar-width, 24px);
  border-right: 1px solid ${({ theme }) => theme.colors.black40};
  background-color: ${({ theme }) => theme.colors.black20};
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1), box-shadow 300ms linear;
  transition-delay: ${({ transitionDelay }) => transitionDelay};

  .expand-collapse-arrow--inside {
    visibility: hidden;
  }

  :hover {
    --left-sidebar-width: 217px;

    box-shadow: ${({ theme }) => theme.boxShadow[12]};

    ${({ isExpanded }) =>
      isExpanded &&
      css`
        box-shadow: none;
      `}

    > ${VisibilityWrapper} {
      opacity: 1;
      overflow: auto;
      visibility: visible;
      transition: opacity 150ms linear, visibility 150ms linear;
      transition-delay: ${({ transitionDelay }) => transitionDelay};
    }

    & + button {
      --left-sidebar-width: 217px;
    }

    .expand-collapse-arrow--inside {
      visibility: visible;
    }

    + .expand-collapse-arrow--outside {
      visibility: ${({ isExpanded }) => (isExpanded ? 'visible' : 'hidden')};
    }
  }
`;

export const TitleSection = styled.div`
  padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[12]}`};
  margin-bottom: ${({ theme: { spacing } }) => spacing[8]};
  position: sticky;
  top: 0;
  left: 0;
  background: ${({ theme: { colors } }) => colors.black20};
  z-index: ${({ theme: { zIndex } }) => zIndex[10]};

  h5 {
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
  }
`;

export const SideNavMenu = styled(Menu)`
  overflow: hidden;
  overflow-y: auto;
  padding: 0;

  &&& {
    box-shadow: none;
  }

  && .ant-menu-item-group {
    margin-bottom: ${({ theme: { spacing } }) => spacing[24]};

    &:last-child {
      border: none;
      padding: 0;
    }
  }

  && .ant-menu-item-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${({ theme: { spacing } }) => spacing[12]};
    height: ${({ theme }) => theme.height.xl}px;
    border-bottom: solid 1px ${({ theme: { border } }) => border.color};
  }

  &&& .ant-menu-item {
    ${P};

    display: flex;
    align-items: center;
    margin-bottom: ${({ theme: { spacing } }) => spacing[8]};
    height: ${({ theme }) => theme.height.lg}px;
    padding: ${({ theme: { spacing } }) => `0 ${spacing[12]} 0`};

    &.ant-menu-item-selected {
      border-right: solid 3px ${({ theme: { colors } }) => colors.blue70};
    }

    .ant-menu-title-content {
      max-width: 100%;

      a {
        ${SingleLineEllipsis};

        font-size: ${({ theme: { fontSize } }) => fontSize.caption};
      }
    }
  }
`;
