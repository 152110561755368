import { type AxiosRequestConfig } from 'axios';

const BASE_QUERY_KEY = 'monitoring-metrics';

export const MonitoringMetricsKeys = {
  MonitoringMetricsKey: BASE_QUERY_KEY,
  MetricList: [BASE_QUERY_KEY, null],
  ConnectedIntegrations: (
    config?: AxiosRequestConfig,
  ): (string | AxiosRequestConfig | undefined)[] => {
    const key: (string | AxiosRequestConfig | undefined)[] = [
      BASE_QUERY_KEY,
      'connection-instances',
    ];

    if (config) {
      key.push(config);
    }

    return key;
  },
  MetricsByQuery: (query: string | null | undefined): (string | null | undefined)[] => [
    BASE_QUERY_KEY,
    query,
  ],
};
