import { ReactComponent as NumberIcon } from 'assets/v2/123.svg';
import { ReactComponent as StringIcon } from 'assets/v2/abc.svg';
import { ReactComponent as CalendarIcon } from 'assets/v2/calendar-due.svg';
import { ReactComponent as FormulaIcon } from 'assets/v2/math-function.svg';
import { type ItemType } from 'components/ui/atomic-components';

export type AllowedTypes = 'STRING' | 'FORMULA' | 'NUMERIC' | 'DATE';

export type ColumnType = ItemType & { dateFormat?: string };

export const IconMapper = {
  STRING: StringIcon,
  DATE: CalendarIcon,
  NUMERIC: NumberIcon,
  FORMULA: FormulaIcon,
};

export const LabelKeyMapper = {
  STRING: 'text',
  DATE: 'date',
  NUMERIC: 'number',
  FORMULA: 'formula',
};
