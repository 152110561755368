import { getBaseColorVersion } from './get-base-color-version';

export const getVersionsColorShadeMap = (
  versions: string[],
  isScenarioPresent: boolean,
): Record<string, number> => {
  const baseColorVersion = getBaseColorVersion(versions, isScenarioPresent);

  const versionsColorShadeMap: Record<string, number> = {};

  const modifedVersions = [baseColorVersion, ...versions.filter((v) => v !== baseColorVersion)];

  for (let idx = 0; idx < modifedVersions.length; idx += 1) {
    const currentVersion = modifedVersions[idx];

    versionsColorShadeMap[currentVersion] = idx / modifedVersions.length;
  }

  return versionsColorShadeMap;
};
