import { ReactComponent as CloseIcon } from 'assets/ic_close.svg';
import { type FC } from 'react';
import { IconShell } from '../icon-shell';
import { Tooltip } from '../tooltip';
import { styles } from './styles';
import { type TagProps } from './types';

const { TagCloseBtnIcon, ImageIconWrapper, Tag: StyledTag } = styles;

export const Tag: FC<React.PropsWithChildren<TagProps>> = ({
  disabled,
  closable,
  isDragging,
  children,
  size = 'middle',
  icon,
  color,
  onClose,
  onHoverCloseBtnTooltipTitle,
  ...rest
}) => {
  return (
    <StyledTag
      $isDragging={isDragging}
      closable={closable}
      color={color}
      disabled={disabled}
      size={size}
      {...rest}
    >
      {icon && <ImageIconWrapper>{icon}</ImageIconWrapper>}
      <span>{children}</span>
      {closable && (
        <Tooltip title={onHoverCloseBtnTooltipTitle ?? ''}>
          <TagCloseBtnIcon
            disabled={disabled}
            icon={<IconShell icon={CloseIcon} />}
            size={size}
            onClick={onClose}
          />
        </Tooltip>
      )}
    </StyledTag>
  );
};
