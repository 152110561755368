import {
  QueryTableColumnDataType,
  type VisualQueryBasicFilterRuleValue,
  VisualQueryFilterOperator,
} from 'data/big-query';
import { BetweenDate } from './between-date';
import { BetweenInput } from './between-input';
import { BooleanSelector } from './boolean-select';
import {
  arrayTypeValidator,
  betweenDateValidator,
  betweenTypeValidator,
  booleanTypeValidator,
  dateTypeValidator,
  numberTypeValidator,
  stringTypeValidator,
} from './data-validators';
import { DateInput } from './date-input';
import { NumberInput } from './number-input';
import { StringInput } from './string-input';
import { StringListInput } from './string-list-input';
import { StringMultiSelect } from './string-multi-select';
import { type VisualRuleEditRenderObj } from './types';

const emptyRenderMap: {
  [key in VisualQueryFilterOperator]: VisualRuleEditRenderObj;
} = {
  [VisualQueryFilterOperator.GreaterThan]: null,
  [VisualQueryFilterOperator.GreaterThanOrEqualTo]: null,
  [VisualQueryFilterOperator.LessThan]: null,
  [VisualQueryFilterOperator.LessThanOrEqualTo]: null,
  [VisualQueryFilterOperator.NotEqualTo]: null,
  [VisualQueryFilterOperator.SelectFromValues]: null,
  [VisualQueryFilterOperator.NotOneOf]: null,
  [VisualQueryFilterOperator.Between]: null,
  [VisualQueryFilterOperator.EqualTo]: null,
  [VisualQueryFilterOperator.Contains]: null,
  [VisualQueryFilterOperator.StartsWith]: null,
  [VisualQueryFilterOperator.EndsWith]: null,
  [VisualQueryFilterOperator.DoesNotContain]: null,
  [VisualQueryFilterOperator.IsEmpty]: null,
  [VisualQueryFilterOperator.IsNotEmpty]: null,
};

const numberRenderers: {
  [key in VisualQueryFilterOperator]: VisualRuleEditRenderObj;
} = {
  ...emptyRenderMap,
  [VisualQueryFilterOperator.GreaterThan]: {
    renderer: NumberInput,
    dataValidator: numberTypeValidator,
  },
  [VisualQueryFilterOperator.GreaterThanOrEqualTo]: {
    renderer: NumberInput,
    dataValidator: numberTypeValidator,
  },
  [VisualQueryFilterOperator.LessThan]: {
    renderer: NumberInput,
    dataValidator: numberTypeValidator,
  },
  [VisualQueryFilterOperator.LessThanOrEqualTo]: {
    renderer: NumberInput,
    dataValidator: numberTypeValidator,
  },
  [VisualQueryFilterOperator.EqualTo]: {
    renderer: NumberInput,
    dataValidator: numberTypeValidator,
  },
  [VisualQueryFilterOperator.NotEqualTo]: {
    renderer: NumberInput,
    dataValidator: numberTypeValidator,
  },
  [VisualQueryFilterOperator.SelectFromValues]: {
    renderer: StringMultiSelect,
    dataValidator: arrayTypeValidator,
    requireMetaData: true,
  },
  [VisualQueryFilterOperator.NotOneOf]: {
    renderer: StringMultiSelect,
    dataValidator: arrayTypeValidator,
    requireMetaData: true,
  },
  [VisualQueryFilterOperator.Contains]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.Between]: {
    renderer: BetweenInput,
    dataValidator: betweenTypeValidator,
  },
  [VisualQueryFilterOperator.IsEmpty]: null,
  [VisualQueryFilterOperator.IsNotEmpty]: null,
};

const dateRenderers: { [key in VisualQueryFilterOperator]: VisualRuleEditRenderObj } = {
  ...emptyRenderMap,
  [VisualQueryFilterOperator.GreaterThan]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.GreaterThanOrEqualTo]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.LessThan]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.LessThanOrEqualTo]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.EqualTo]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.NotEqualTo]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.SelectFromValues]: {
    renderer: StringListInput,
    dataValidator: arrayTypeValidator,
  },
  [VisualQueryFilterOperator.NotOneOf]: {
    renderer: StringListInput,
    dataValidator: arrayTypeValidator,
  },
  [VisualQueryFilterOperator.Contains]: {
    renderer: DateInput,
    dataValidator: dateTypeValidator,
  },
  [VisualQueryFilterOperator.IsEmpty]: null,
  [VisualQueryFilterOperator.IsNotEmpty]: null,
  [VisualQueryFilterOperator.Between]: {
    renderer: BetweenDate,
    dataValidator: betweenDateValidator,
  },
};

const stringRenderers: {
  [key in VisualQueryFilterOperator]: VisualRuleEditRenderObj;
} = {
  ...emptyRenderMap,
  [VisualQueryFilterOperator.EqualTo]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.NotEqualTo]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.Contains]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.DoesNotContain]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.StartsWith]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.EndsWith]: {
    renderer: StringInput,
    dataValidator: stringTypeValidator,
  },
  [VisualQueryFilterOperator.IsEmpty]: null,
  [VisualQueryFilterOperator.IsNotEmpty]: null,
  [VisualQueryFilterOperator.SelectFromValues]: {
    renderer: StringMultiSelect,
    dataValidator: arrayTypeValidator,
    requireMetaData: true,
  },
  [VisualQueryFilterOperator.NotOneOf]: {
    renderer: StringMultiSelect,
    dataValidator: arrayTypeValidator,
    requireMetaData: true,
  },
};

const booleanRenderers: {
  [key in VisualQueryFilterOperator]: VisualRuleEditRenderObj;
} = {
  ...emptyRenderMap,
  [VisualQueryFilterOperator.EqualTo]: {
    renderer: BooleanSelector,
    dataValidator: booleanTypeValidator,
  },
  [VisualQueryFilterOperator.NotEqualTo]: {
    renderer: BooleanSelector,
    dataValidator: booleanTypeValidator,
  },
};

export const RuleValueRendererMap: {
  [key in QueryTableColumnDataType]: {
    [key in VisualQueryFilterOperator]: VisualRuleEditRenderObj;
  };
} = {
  [QueryTableColumnDataType.Integer]: {
    ...numberRenderers,
  },
  [QueryTableColumnDataType.Float]: {
    ...numberRenderers,
  },
  [QueryTableColumnDataType.Numeric]: {
    ...numberRenderers,
  },
  [QueryTableColumnDataType.BigNumeric]: {
    ...numberRenderers,
  },
  [QueryTableColumnDataType.Timestamp]: {
    ...dateRenderers,
  },
  [QueryTableColumnDataType.Date]: {
    ...dateRenderers,
  },
  [QueryTableColumnDataType.DateTime]: {
    ...dateRenderers,
  },
  [QueryTableColumnDataType.Time]: {
    ...dateRenderers,
  },
  [QueryTableColumnDataType.String]: {
    ...stringRenderers,
  },
  [QueryTableColumnDataType.Boolean]: { ...booleanRenderers },
  [QueryTableColumnDataType.Bytes]: emptyRenderMap,
  [QueryTableColumnDataType.Struct]: emptyRenderMap,
  [QueryTableColumnDataType.Array]: emptyRenderMap,
  [QueryTableColumnDataType.Geography]: emptyRenderMap,
};

export const isMetaDataRequired = (
  colType?: QueryTableColumnDataType,
  operator?: VisualQueryFilterOperator,
): boolean => {
  if (colType && operator) {
    return RuleValueRendererMap[colType][operator]?.requireMetaData || false;
  }

  return false;
};

export const checkIfDataValid = ({
  data,
  dataType,
  operator,
}: {
  dataType?: QueryTableColumnDataType;
  operator?: VisualQueryFilterOperator;
  data?: VisualQueryBasicFilterRuleValue[];
}): boolean => {
  if (operator && dataType) {
    const validator = RuleValueRendererMap[dataType][operator]?.dataValidator;

    if (!validator) {
      return true;
    }

    return validator(data);
  }

  return false;
};
