import { type ICellRendererParams } from 'ag-grid-community';
import { ReactComponent as InfoCircleIcon } from 'assets/v2/info-circle.svg';
import { MetricErrorPopover } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-error-popover';
import { IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ValidationErrorCell = ({ value, column }: ICellRendererParams): ReactElement => {
  const intl = useIntl();

  return (
    <Wrapper>
      {value}

      <MetricErrorPopover
        errors={[intl.formatMessage({ id: 'lists.data_validation.error_message' })]}
      >
        <IconShell color="red400" icon={InfoCircleIcon} size="sm" />
      </MetricErrorPopover>
    </Wrapper>
  );
};
