import { useMemo } from 'react';
import { getDetailedTableName } from 'utils/dimensions';
import { ColoringOptions, DefaultColoringOption } from './utils';

interface Props {
  dims: string[];
}

export const useChipColoring = ({
  dims,
}: Props): ((dimName: string) => {
  background: string;
  border: string;
}) => {
  const chipColoringScheme = useMemo(() => {
    const scheme: { [key: string]: { background: string; border: string } } = {};
    let coloringIndex = 0;

    dims?.forEach((dim) => {
      const detailedTableName = getDetailedTableName(dim);

      if (!(detailedTableName in scheme)) {
        scheme[detailedTableName] = ColoringOptions?.[coloringIndex] || DefaultColoringOption;
        coloringIndex += 1;
      }
    });

    return (dimName: string) => {
      return scheme[getDetailedTableName(dimName)] || DefaultColoringOption;
    };
  }, [dims]);

  return chipColoringScheme;
};
