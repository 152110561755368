/**
 * Chart colors constants defined by plan vs actual in a tuple
 * The key corresponds to Figma's reference key. For instance, Figma says 1a, 1b,
 * which implies ChartColors[1][0], ChartColors[1][1].
 */
export const ChartColors: { [key: string]: [plan: string, actual: string] } = {
  1: ['#9EDAE5', '#17BECF'],
  2: ['#DBDB8D', '#BCBD22'],
  3: ['#FFDD71', '#FFC156'],
  4: ['#F7B6D2', '#E377C2'],
  5: ['#C49C94', '#8C564B'],
  6: ['#C5B0D5', '#A078C4'],
  7: ['#FF9896', '#D62728'],
  8: ['#98DF8A', '#2CA02C'],
  9: ['#FFBB78', '#FF7F0E'],
  10: ['#AEC7E8', '#1F77B4'],
};

export const GaugeChartColors: { [key: string]: [plan: string, actual: string] } = {
  1: ['#9EDAE5', '#17BECF'],
  2: ['#FFDD71', '#FFC156'],
  3: ['#F7B6D2', '#E377C2'],
  4: ['#C49C94', '#8C564B'],
  5: ['#C5B0D5', '#A078C4'],
  6: ['#FFBB78', '#FF7F0E'],
  7: ['#AEC7E8', '#1F77B4'],
};
