import { ReactComponent as ListIcon } from 'assets/v2/menu-2.svg';
import { type DirectoryTreeNodeDataType } from 'components/ui/atomic-components/directory-tree/types';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type List } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';

export const constructFilteredTreeData = ({
  lists,
  searchQuery,
  listId,
}: {
  lists: (List | DimensionGroup)[];
  searchQuery: string;
  listId: string;
}): DirectoryTreeNodeDataType[] => {
  const treeData = lists.map((list) => {
    const isDimGroup = !!(list as DimensionGroup)?.uniqueKeyColumns;
    const listIdParam = isDimGroup ? `${DIM_GROUP_ID_PREFIX}${list.id}` : list.id;

    return {
      id: list.id,
      title: list.displayName || '',
      key: String(listIdParam),
      icon: <IconShell icon={ListIcon} />,
      isLeaf: true,
      linkTo: `/lists/${listIdParam}`,
    };
  });

  const filteredTreeData = treeData.filter((data) => {
    return !searchQuery || data.title?.toLowerCase().includes(searchQuery.toLowerCase().trim());
  });

  return filteredTreeData;
};
