import { type GroupBase, type StylesConfig } from 'react-select';
import { defaultTheme } from 'styles/theme-old';
import { getCommonStyles } from './common';

export const getDropdownSmallSize = <T, IsMulti extends boolean = boolean>(): StylesConfig<
  T,
  IsMulti,
  GroupBase<T>
> => {
  const CommonStyles = getCommonStyles<T, IsMulti>();

  return {
    control: (base, props) => ({
      ...CommonStyles.control?.(base, props),
      fontSize: defaultTheme.fontSize.caption,
      height: defaultTheme.height.sm,
      lineHeight: defaultTheme.lineHeight.caption,
      minHeight: defaultTheme.height.sm,
      padding: `0 ${defaultTheme.spacing[8]} 0 ${defaultTheme.spacing[12]}`,
    }),

    dropdownIndicator: CommonStyles.dropdownIndicator,

    indicatorSeparator: CommonStyles.indicatorSeparator,

    input: CommonStyles.input,

    menu: CommonStyles.menu,

    option: (base, props) => ({
      ...CommonStyles.option?.(base, props),
      fontSize: defaultTheme.fontSize.caption,
      lineHeight: `${defaultTheme.height.sm}px`,
      minHeight: defaultTheme.height.sm,
    }),

    placeholder: CommonStyles.placeholder,

    singleValue: CommonStyles.singleValue,

    valueContainer: CommonStyles.valueContainer,
  };
};
