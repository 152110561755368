// base code taken from https://github.com/okta/okta-auth-js/blob/master/lib/browser/browserStorage.ts
// cookieOptions.domain and type fixes are are the change done on top of okta base code
// to set custom cookie domain, so user can login from website login button

import Cookies from 'js-cookie';

type CookieAttributes = typeof Cookies.attributes;

const isProdEnv = import.meta.env.PROD && window.location.hostname.endsWith('drivetrain.ai');
const cookieDomain = '.drivetrain.ai';

const storage = {
  get(name?: string): string | Record<string, string> {
    // return all cookies when no args is provided
    if (!name) {
      return Cookies.get();
    }

    return Cookies.get(name) as string;
  },

  set(name: string, value: string, expiresAt: string, options: CookieAttributes): string {
    const { sameSite, secure } = options;

    const cookieOptions: CookieAttributes = {
      path: options.path || '/',
      secure,
      sameSite,
    };

    if (isProdEnv) {
      cookieOptions.domain = cookieDomain;
    }

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!Date.parse(expiresAt)) {
      // Expires value can be converted to a Date object.
      //
      // If the 'expiresAt' value is not provided, or the value cannot be
      // parsed as a Date object, the cookie will set as a session cookie.
      cookieOptions.expires = new Date(expiresAt);
    }

    Cookies.set(name, value, cookieOptions);

    return this.get(name) as string;
  },

  delete(name: string): void {
    const options: CookieAttributes = { path: '/' };

    if (isProdEnv) {
      options.domain = cookieDomain;
    }

    return Cookies.remove(name, options);
  },
};

const storageClient = {
  getItem: storage.get,
  setItem: (key: string, value: string, expiresAt = '2200-01-01T00:00:00.000Z') => {
    storage.set(key, value, expiresAt, {
      secure: true,
      sameSite: 'lax',
    });
  },
  removeItem: (key: string) => {
    storage.delete(key);
  },
};

export const customStorageManager = {
  getItem(key: string): string {
    const data = storageClient.getItem(); // read all cookies
    const value: Record<string, string> = {};

    Object.keys(data).forEach((k) => {
      if (k.indexOf(key) === 0) {
        // filter out unrelated cookies
        value[k.replace(`${key}_`, '')] = JSON.parse((data as Record<string, string>)[k]); // populate with cookie data
      }
    });

    return JSON.stringify(value);
  },

  setItem(key: string, value: string): void {
    const existingValues = JSON.parse(this.getItem(key));

    // eslint-disable-next-line no-param-reassign
    value = JSON.parse(value);
    // Set key-value pairs from input to cookies
    Object.keys(value).forEach((k) => {
      const storageKey = `${key}_${k}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const valueToStore = JSON.stringify((value as any)[k]);

      storageClient.setItem(storageKey, valueToStore);
      delete existingValues[k];
    });
    // Delete unmatched keys from existing cookies
    Object.keys(existingValues).forEach((k) => {
      storageClient.removeItem(`${key}_${k}`);
    });
  },

  removeItem(key: string): void {
    const existingValues = JSON.parse(this.getItem(key));

    Object.keys(existingValues).forEach((k) => {
      storageClient.removeItem(`${key}_${k}`);
    });
  },
};
