import { type OptionBase } from 'components/ui/atomic-components';
import { type IntegrationConnectionItem } from 'data/integrations/fivetran';

export const getOptionsFromData = (data: IntegrationConnectionItem[]): OptionBase[] => {
  const newOptions: OptionBase[] = [{ label: 'All Categories', value: 'All Categories' }];

  data.forEach((item) => {
    if (!newOptions.find((n) => n.value === item.category)) {
      newOptions.push({
        label: item.category,
        value: item.category,
      });
    }
  });

  return newOptions;
};
