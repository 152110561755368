import { type GridApi } from 'ag-grid-community';
import {
  type ValueGetterFunc,
  type RangeSelectionChangedEvent,
  type ValueGetterParams,
  type ColDef,
  type IRowNode,
} from 'ag-grid-enterprise';
import { max, min } from 'lodash';
import { useEffect, type ReactElement, useState } from 'react';
import { type AggItem, AggSelector, AggType } from './selector';

interface Props {
  api: GridApi;
  formatter?: (row: IRowNode, colDef: ColDef) => (value: number) => string;
}

export const StatusBar = ({ api, formatter }: Props): ReactElement => {
  const [aggItems, setAggItems] = useState<AggItem[]>([]);

  useEffect(() => {
    if (!api) {
      return;
    }

    const handleRangeSelectionChange = (e: RangeSelectionChangedEvent) => {
      const { api } = e;
      const ranges = api?.getCellRanges();

      let sum = 0;
      let count = 0;
      let numberCellsCount = 0;
      let numFormatter: ((value: number) => string) | undefined = undefined;

      ranges?.forEach((r) => {
        r.columns.forEach((c) => {
          const start = min([r.startRow?.rowIndex, r.endRow?.rowIndex]);
          const end = max([r.startRow?.rowIndex, r.endRow?.rowIndex]);

          if (typeof start !== 'number' || typeof end !== 'number') {
            return;
          }

          for (let i = start; i <= end; i += 1) {
            const row = api.getDisplayedRowAtIndex(i);

            const colDef = c.getColDef();

            const val =
              typeof colDef?.valueGetter === 'function' &&
              (colDef?.valueGetter as ValueGetterFunc)?.({
                data: row?.data,
                node: row,
              } as ValueGetterParams);

            if ((typeof val === 'number' || typeof val === 'string') && isFinite(+val)) {
              if (!numFormatter) {
                numFormatter = row && formatter ? formatter(row, colDef) : undefined;
              }
              numberCellsCount += 1;
              count += 1;
              sum += Number(val);
            } else {
              count += 1;
            }
          }
        });
      });

      if (count > 1) {
        const currentAggItems: AggItem[] = [{ type: AggType.CNT, value: count }];

        if (numberCellsCount > 0) {
          const formatVal = (val: number) => {
            return numFormatter ? (numFormatter as (value: number) => string)(val) : val;
          };

          currentAggItems.push(
            {
              type: AggType.SUM,
              value: formatVal(sum),
            },
            { type: AggType.AVG, value: formatVal(sum / count) },
          );
        }
        setAggItems(currentAggItems);
      } else {
        setAggItems([]);
      }
    };

    api.addEventListener('rangeSelectionChanged', handleRangeSelectionChange);

    return () => api.removeEventListener('rangeSelectionChanged', handleRangeSelectionChange);
  }, [api, formatter]);

  return <AggSelector aggItems={aggItems} />;
};
