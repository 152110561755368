import { Button } from 'components/ui/atomic-components';
import { type ResourceUsageResp } from 'data/resource-usage/types';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { UsageList } from '../usage-modal/usage-list';

const TextWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[2]};
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

interface Props {
  displayName: string;
  data: ResourceUsageResp;
}

export const DeleteUsageMessage = ({ displayName, data }: Props): ReactElement => {
  const [showUsage, setShowUsage] = useState(false);

  return (
    <div>
      <FormattedMessage
        id="resource_usage.delete_modal.primary_msg"
        values={{ resourceDisplayName: displayName }}
      />

      <TextWrapper>
        <FormattedMessage id="resource_usage.delete_modal.view_usage" />
        <Button size="large" type="link" onClick={() => setShowUsage((prev) => !prev)}>
          {showUsage ? (
            <FormattedMessage id="resource_usage.delete_modal.hide_usage" />
          ) : (
            <FormattedMessage id="resource_usage.delete_modal.show_usage" />
          )}
        </Button>
      </TextWrapper>

      {showUsage && <UsageList dependencies={data.dependencies} />}
    </div>
  );
};
