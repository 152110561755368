import { create } from 'zustand';

export interface LastDimensionSelectionStore {
  hasLastFilterSelectionValuesInMemory: Record<string, boolean>;
  setHasLastFilterSelectionValuesInMemory: (id: number | string, value: boolean) => void;
}

export const useLastDimensionSelectionStore = create<LastDimensionSelectionStore>()((set, get) => ({
  hasLastFilterSelectionValuesInMemory: {},

  setHasLastFilterSelectionValuesInMemory: (id, value) => {
    let hasLastFilterSelectionValuesInMemory = get().hasLastFilterSelectionValuesInMemory || {};

    if (value) {
      hasLastFilterSelectionValuesInMemory = {
        ...hasLastFilterSelectionValuesInMemory,
        [id]: true,
      };
    } else {
      delete hasLastFilterSelectionValuesInMemory[id];
    }

    set({
      hasLastFilterSelectionValuesInMemory,
    });
  },
}));
