import { type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { type ListGridRow } from '../grid/types';
import { type UndoStackItem } from '../grid/undo-redo/types';
import { CloseAction } from './close-action';
import { DeleteAction } from './delete-action';
import { DuplicateAction } from './duplicate-action';
import { SelectedCount } from './selected-count';
import styles from './styles';

const { Wrapper } = styles;

interface Props {
  onDeleteListRows: (rowNode: ListGridRow[]) => void;
  onSaveListEntry: (o: { rowNode: ListGridRow; undoContext: UndoStackItem }) => Promise<void>;
}

export const ActionsFloatingWidget = ({
  onDeleteListRows,
  onSaveListEntry,
}: Props): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);

  if (!selectedRowIds?.length) {
    return <></>;
  }

  return (
    <Wrapper bottom={-20}>
      <SelectedCount />

      <DuplicateAction onSaveListEntry={onSaveListEntry} />

      <DeleteAction onDeleteListRows={onDeleteListRows} />

      <CloseAction />
    </Wrapper>
  );
};
