import { type ColDef } from 'ag-grid-community';
import { type FormInstance } from 'antd';
import { Form, Input } from 'components/ui/atomic-components';
import { isEmpty } from 'lodash';
import { useEffect, useRef, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled, { css } from 'styled-components';
import { formatName } from 'utils/data-formatter';
import { LIST_NAMING_PATTERN } from '../../../constants';
import { type ColumnType } from './column-type-select/utils';

const StyledFormItem = styled(Form.Item)<{ width?: number }>`
  ${({ width }) =>
    width &&
    css`
      .ant-form-item-explain-error {
        max-width: ${width}px;
      }
    `}
`;

export const ColumnNameFormItem = ({
  colName,
  form,
  isEdit,
  onPressEnter,
  popoverWrapperRef,
}: {
  form: FormInstance<{
    name: string;
    type: ColumnType;
    formula?: {
      formulaText: string | undefined;
      query: string | undefined;
    };
    aiEnabledFormula?: boolean;
    error?: string;
  }>;
  colName?: string;
  isEdit?: boolean;
  onPressEnter: () => void;
  popoverWrapperRef: React.RefObject<HTMLDivElement>;
}): ReactElement => {
  const intl = useIntl();
  const inputRef = useRef(null);

  const gridApi = useListStoreContext((s) => s.gridApi);
  const columnOrder = useListStoreContext((s) => s.config.columnOrder);

  const name = Form.useWatch('name', form);
  const type = Form.useWatch('type', form);

  const newColumnText = intl.formatMessage({ id: 'lists.property.name.placeholder' });

  const width = popoverWrapperRef.current?.clientWidth
    ? popoverWrapperRef.current?.clientWidth - 32
    : undefined;

  const checkDuplicates = (_: unknown, value: string) => {
    if (columnOrder.find((c) => formatName(c) === value) && value !== formatName(colName)) {
      return Promise.reject(
        new Error(
          intl.formatMessage({
            id: 'lists.property.duplicate_name',
          }),
        ),
      );
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (inputRef.current !== null) {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (gridApi?.isDestroyed() || isEdit) {
      return;
    }

    const colDefs = gridApi?.getGridOption('columnDefs');

    gridApi?.setGridOption(
      'columnDefs',
      (colDefs ?? []).map((c: ColDef) => {
        if (c.colId === 'temp-col') {
          return {
            ...c,
            headerComponentParams: {
              displayName: isEmpty(name) ? newColumnText : name,
              hideOptions: true,
              columnType: type?.key,
            },
          } as ColDef;
        }

        return c;
      }),
    );
  }, [gridApi, isEdit, name, newColumnText, type?.key]);

  return (
    <StyledFormItem
      label={<FormattedMessage id="lists.property.name" />}
      name="name"
      rules={[
        { required: true, message: intl.formatMessage({ id: 'lists.property.specify_name' }) },
        {
          pattern: LIST_NAMING_PATTERN,
          message: intl.formatMessage({ id: 'lists.column_name.error' }),
        },
        {
          validator: checkDuplicates,
        },
      ]}
      width={width}
    >
      <Input
        ref={inputRef}
        autoComplete="off"
        placeholder={intl.formatMessage({ id: 'lists.property.name.placeholder' })}
        onPressEnter={onPressEnter}
      />
    </StyledFormItem>
  );
};
