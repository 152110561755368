import { ListSortOrder, type ListConfig } from 'data/modelling/lists';

export const getActiveKey = (config: ListConfig, colName: string): string | undefined => {
  if (config.sortedColumn !== colName) {
    return undefined;
  }

  if (config.sortOrder === ListSortOrder.Asc) {
    return 'sort-asc';
  }

  if (config.sortOrder === ListSortOrder.Desc) {
    return 'sort-desc';
  }

  return 'default';
};
