import axios from 'axios';

interface Response {
  token: string;
  mergeIntegrationId: string;
}

export const MergeDevApi = {
  getLinkToken: (): Promise<Response> =>
    axios.get('v1/integrations/merge/link-token').then(({ data }) => data),

  getConnectorLinkToken: (connectionId?: number): Promise<Response> =>
    axios
      .get(`v1/integrations/merge/link-token?connectionId=${connectionId}`)
      .then(({ data }) => data),
};
