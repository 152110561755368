import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { SystemApi } from './api';

export const useSystemAdminAction = (): UseMutationResult<
  AxiosError,
  unknown,
  { classification: string; operation: string; queryParams?: Record<string, string> }
> => {
  const systemData = useMutation(
    ({
      classification,
      operation,
      queryParams,
    }: {
      classification: string;
      operation: string;
      queryParams?: Record<string, string>;
    }) => {
      return SystemApi.postAction({ classification, operation, queryParams });
    },
    {
      onError: defaultApiErrorHandler,
    },
  );

  return systemData;
};
