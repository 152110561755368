import { ReactComponent as CrownIcon } from 'assets/v2/crown.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM } from 'styles/typography';

const HeaderWrapper = styled.div`
  ${FontM};

  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  height: 22px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  align-items: center;
`;

export const Header = ({ title }: { title: string }): ReactElement => {
  return (
    <HeaderWrapper>
      <IconShell color="yellow500" icon={CrownIcon} />
      <FormattedMessage id="integrations.premium.content.title" values={{ name: title }} />
    </HeaderWrapper>
  );
};
