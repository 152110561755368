import { ReactComponent as DataFormatIcon } from 'assets/data-format.svg';
import { metricTypesListV2 } from 'components/modules/metrics/constants';
import { IconText, Segmented, Select, NumberStepper } from 'components/ui/atomic-components';
import { CurrencySelector } from 'components/ui/currency-selector';
import { MetricTypeIconMapping } from 'components/ui/metric/icon-generator/metric-type-icon-mapping';
import { type DataFormatType } from 'data/metrics';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { defaultTheme } from 'styles/theme';
import {
  type BlankValueType,
  DataFormattingType,
  type NegativeValueType,
  type ZeroFormatType,
} from 'utils/data-formatter/types';
import {
  blankValueOptions,
  displayUnitOptions,
  negativeValueOptions,
  zeroFormatOptions,
} from './constants';
import { useDataFormatHook } from './hooks/use-data-format';
import { Sample } from './sample-section';
import { styles } from './styles';
import { type CustomTypeFormat } from './types';
import { ValueFormatUnit } from './value-format';

const { Wrapper, Unit, UnitsSection, StyledModal, UnitLabel } = styles;

interface Props {
  disableTypeSelect?: boolean;
  dataFormat?: DataFormatType;
  visible: boolean;
  onSave(metricType: DataFormattingType, updatedDataFormat: DataFormatType): void;
  onCancel(): void;
  type?: DataFormattingType;
  title?: string;
  footerExtra?: ReactElement;
}

export const DataFormatModal = ({
  disableTypeSelect,
  dataFormat,
  visible,
  type: metricType,
  title,
  footerExtra,
  onSave,
  onCancel,
}: Props): ReactElement => {
  const intl = useIntl();

  const {
    digitsAfterDecimal,
    displayUnit,
    blankValueFormat,
    negativeValueFormat,
    zeroFormat,
    fullNumberOption,
    currency,
    type,
    handleCurrencyChange,
    handleMetricTypeChange,
    setBlankValueFormat,
    setDigitsAfterDecimal,
    setDisplayUnit,
    setZeroFormat,
    setNegativeValueFormat,
  } = useDataFormatHook({ initialType: metricType, dataFormat });

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      centered
      destroyOnClose
      footerExtra={footerExtra}
      okText={<FormattedMessage id="data_format.modal.apply_format" />}
      open={visible}
      title={
        <IconText
          icon={metricType ? MetricTypeIconMapping[metricType] : DataFormatIcon}
          text={
            title ||
            `${intl.formatMessage({
              id: 'loading',
            })}...`
          }
        />
      }
      width={487}
      onCancel={onCancel}
      onOk={() => {
        onSave(type, {
          blankValueFormat: blankValueFormat.value,
          currency,
          digitsAfterDecimal,
          displayUnit: displayUnit.value,
          negativeValueFormat: negativeValueFormat.value,
          zeroFormat: zeroFormat.value,
        });
      }}
    >
      <Wrapper>
        <Sample
          currency={currency}
          digitsAfterDecimal={digitsAfterDecimal}
          displayUnit={displayUnit.value}
          type={type}
        />

        <UnitsSection>
          <Unit>
            <UnitLabel>
              <FormattedMessage id="data_format.modal.data_type" />
            </UnitLabel>

            <Segmented
              disabled={disableTypeSelect}
              options={metricTypesListV2.map((o) => ({ value: o.value, icon: o.icon }))}
              type="icon"
              value={metricTypesListV2.find((option) => option.value === type)?.value}
              onChange={(value) => handleMetricTypeChange(value as DataFormattingType)}
            />
          </Unit>

          {type === DataFormattingType.Currency && (
            <Unit>
              <UnitLabel>
                <FormattedMessage id="currency" />
              </UnitLabel>

              <CurrencySelector
                enableAllCurrenciesOptions
                value={currency}
                onChange={handleCurrencyChange}
              />
            </Unit>
          )}

          <Unit>
            <UnitLabel>
              <FormattedMessage id="data_format.modal.display_type" />
            </UnitLabel>

            <Select
              options={
                type !== DataFormattingType.Percentage
                  ? displayUnitOptions.filter((option) => option.currencies.includes(currency))
                  : [fullNumberOption]
              }
              styles={{
                menu: (base) => {
                  return {
                    ...base,
                    background: defaultTheme.colors.gray00,
                    minWidth: 150,
                  };
                },
              }}
              value={displayUnit}
              onChange={(updatedValue) =>
                setDisplayUnit(
                  displayUnitOptions.find(
                    (opt) =>
                      opt.value ===
                      (
                        updatedValue as {
                          value: string;
                        }
                      ).value,
                  ) || displayUnitOptions[0],
                )
              }
            />
          </Unit>

          <Unit>
            <UnitLabel>
              <FormattedMessage id="data_format.modal.digits_after_decimal" />
            </UnitLabel>

            <NumberStepper
              max={4}
              min={0}
              value={digitsAfterDecimal}
              onDecrease={setDigitsAfterDecimal}
              onIncrease={setDigitsAfterDecimal}
            />
          </Unit>

          <ValueFormatUnit<CustomTypeFormat<BlankValueType>>
            currentValue={blankValueFormat.value}
            options={blankValueOptions}
            title={intl.formatMessage({ id: 'data_format.modal.blank_values' })}
            onChange={(updatedValue) =>
              setBlankValueFormat(
                blankValueOptions.find((opt) => opt.value === updatedValue) || blankValueOptions[0],
              )
            }
          />

          <ValueFormatUnit<CustomTypeFormat<ZeroFormatType>>
            currentValue={zeroFormat.value}
            options={zeroFormatOptions}
            title={intl.formatMessage({ id: 'data_format.modal.zero_values' })}
            onChange={(updatedValue) =>
              setZeroFormat(
                zeroFormatOptions.find((opt) => opt.value === updatedValue) || zeroFormatOptions[0],
              )
            }
          />

          <ValueFormatUnit<CustomTypeFormat<NegativeValueType>>
            currentValue={negativeValueFormat.value}
            options={negativeValueOptions}
            title={intl.formatMessage({ id: 'data_format.modal.negative_values' })}
            onChange={(updatedValue) =>
              setNegativeValueFormat(
                negativeValueOptions.find((opt) => opt.value === updatedValue) ||
                  negativeValueOptions[0],
              )
            }
          />
        </UnitsSection>
      </Wrapper>
    </StyledModal>
  );
};
