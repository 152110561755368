import { defaultKeymap } from '@codemirror/commands';
import { Compartment, EditorState } from '@codemirror/state';
import { EditorView, keymap, placeholder, tooltips } from '@codemirror/view';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { useRef, useEffect, type ReactElement } from 'react';
import { autoCompletion } from './extensions/auto-completion';
import { expressionConstructs } from './extensions/expression-constructs';
import { styles } from './styles';

const { StyledEditor, TooltipWrapper } = styles;

const expressionConstructsCompartment = new Compartment();
const autoCompletionCompartment = new Compartment();

interface Props {
  value?: string;
  placeholderText?: string;
  autoCompletionOptions?: FormulaAutocompleteOption[];
  onPressEnter?: (value: string) => void;
  setEditorView?: (editorView: EditorView | null) => void;
}

export const CustomTimeAutoComplete = ({
  value,
  placeholderText,
  autoCompletionOptions,
  onPressEnter,
  setEditorView,
}: Props): ReactElement => {
  const editorDomRef = useRef(null);
  const editorViewRef = useRef<EditorView>();
  const tooltipDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editorDomRef.current === null || tooltipDivRef.current === null) {
      return;
    }

    const startState = EditorState.create({
      doc: value,
      extensions: [
        expressionConstructsCompartment.of(expressionConstructs()),
        placeholder(placeholderText || ''),
        keymap.of([
          {
            key: 'Enter',
            run: (view) => {
              onPressEnter?.(view.state.doc.toString());

              return true;
            },
          },
          ...defaultKeymap,
        ]),
        tooltips({ parent: tooltipDivRef.current, position: 'absolute' }),
        autoCompletionCompartment.of(autoCompletion(autoCompletionOptions || [])),
      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editorDomRef.current,
    });

    // auto-focus and set the cursor position to the end of the line
    view.focus();
    view.dispatch({ selection: { anchor: value?.length ?? 0, head: value?.length ?? 0 } });

    setEditorView?.(view);
    editorViewRef.current = view;

    return () => {
      view.destroy();
      setEditorView?.(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    editorViewRef.current?.dispatch({
      effects: expressionConstructsCompartment.reconfigure(expressionConstructs()),
    });
  }, []);

  useEffect(() => {
    editorViewRef.current?.dispatch({
      effects: autoCompletionCompartment.reconfigure(autoCompletion(autoCompletionOptions || [])),
    });
  }, [autoCompletionOptions]);

  return (
    <>
      <StyledEditor ref={editorDomRef} />
      <TooltipWrapper ref={tooltipDivRef} />
    </>
  );
};
