import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { getCallbackUrl, getReauthState, getState, GoogleSheetScopes } from '../util';

export const gsheetLogin = ({
  item,
  instanceId,
}: {
  item: IntegrationModalContent;
  instanceId?: number;
}): void => {
  const { clientId, id } = item;
  const state = instanceId ? getReauthState(id, instanceId) : getState(id);

  window.open(
    `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&response_type=code&state=${state}&scope=${GoogleSheetScopes.join(
      ' ',
    )}&redirect_uri=${getCallbackUrl()}&prompt=consent&include_granted_scopes=true&access_type=offline`,
    '_self',
  );
};
