import { ReactComponent as AccordionOpenIcon } from 'assets/ic_accordion_open.svg';
import { type ReactElement } from 'react';
import { components, type DropdownIndicatorProps } from 'react-select';
import { IconShell } from '../icon-shell';
import { type OptionBase } from './option';

export const InlineDropdownIndicator = <T extends OptionBase, IsMulti extends boolean>(
  props: DropdownIndicatorProps<T, IsMulti>,
): ReactElement => {
  return (
    <components.DropdownIndicator {...props}>
      <IconShell icon={AccordionOpenIcon} />
    </components.DropdownIndicator>
  );
};
