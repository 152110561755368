import { Empty } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';

const Wrapper = styled.div`
  display: flex;
  width: 192px;
  padding-top: 80px;
`;

const StyledEmpty = styled(Empty)`
  margin: 0 -${({ theme }) => theme.spacing[8]};
`;

const ImageTag = styled.img``;

export const ListsEmpty = (): ReactElement => {
  return (
    <Wrapper>
      <StyledEmpty
        description={<FormattedMessage id="lists.left_nav.no_lists.desc" />}
        image={<ImageTag src={getAssetUrl('v2/empty/no-lists-to-show-placeholder.webp')} />}
      />
    </Wrapper>
  );
};
