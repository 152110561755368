import { forwardRef, type ReactElement, type Ref, type RefObject } from 'react';
import { type GroupBase, type Props } from 'react-select';
import ReactAsyncSelect, { type AsyncProps } from 'react-select/async';
import { type OptionBase, SelectOptionOld } from './option';
import { getDropdownMediumSize, getDropdownSmallSize, getDropdownLargeSize } from './styles';

type AsyncSelectType<T, IsMulti extends boolean> = Props<T, IsMulti> &
  AsyncProps<T, IsMulti, GroupBase<T>> & { ref?: RefObject<unknown> };
export interface AsyncSelectProps<T, IsMulti extends boolean = false>
  extends AsyncSelectType<T, IsMulti> {
  size?: 'small' | 'default' | 'large';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncSelectBase = <T extends OptionBase, IsMulti extends boolean>(
  { classNamePrefix, size = 'default', components, styles, ...props }: AsyncSelectProps<T, IsMulti>,
  // TODO:: @abhilashlr: Fix this properly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
): ReactElement => {
  const customStyles = {
    small: getDropdownSmallSize<T, IsMulti>(),
    default: getDropdownMediumSize<T, IsMulti>(),
    large: getDropdownLargeSize<T, IsMulti>(),
  }[size];

  return (
    <ReactAsyncSelect<T, IsMulti>
      ref={ref}
      classNamePrefix={classNamePrefix || 'react-select'}
      components={{
        Option: SelectOptionOld,
        ...components,
      }}
      styles={{ ...customStyles, ...styles }}
      {...props}
    />
  );
};

export const AsyncSelectOld = forwardRef(AsyncSelectBase) as <
  T extends OptionBase = OptionBase,
  IsMulti extends boolean = false,
>(
  p: AsyncSelectProps<T, IsMulti> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
