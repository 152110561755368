import { type UseMutationResult, useMutation } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { type ListRow, ListsApi } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';

interface ReturnProps {
  onCreateRow: UseMutationResult<ListRow, AxiosError, Partial<ListRow> | undefined, unknown>;
  onUpdateRowEntry: UseMutationResult<Partial<ListRow>, AxiosError, Partial<ListRow>, unknown>;
  onDeleteRow: UseMutationResult<void, AxiosError, number[], unknown>;
}

export const useCreateDeleteMutations = (): ReturnProps => {
  const activeListId = useListStoreContext((s) => s.id);

  const onCreateRow = useMutation(
    (rowToBeAdded?: Partial<ListRow>) => {
      return ListsApi.addRow(activeListId, rowToBeAdded);
    },
    {
      onError: (err: AxiosError) => {
        defaultApiErrorHandler(err);
      },
    },
  );

  const onUpdateRowEntry = useMutation(
    (listNode: Partial<ListRow>) => {
      return ListsApi.updateRow(activeListId, listNode);
    },
    {
      onError: (err: AxiosError) => {
        defaultApiErrorHandler(err);
      },
    },
  );

  const onDeleteRow = useMutation((rowIds: number[]) => ListsApi.deleteRow(activeListId, rowIds), {
    onError: (err: AxiosError) => {
      defaultApiErrorHandler(err);
    },
  });

  return {
    onCreateRow,
    onUpdateRowEntry,
    onDeleteRow,
  };
};
