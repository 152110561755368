import { type UseMutationResult } from '@tanstack/react-query';
import { type ColumnMovedEvent } from 'ag-grid-community';
import { type AxiosError } from 'axios';
import { type List } from 'data/modelling/lists';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';

export const onColumnMoved = (
  event: ColumnMovedEvent,
  listStore: StoreApi<ListsStoreApi>,
  updateListMutation: UseMutationResult<
    void,
    AxiosError,
    {
      id: number;
      list: Partial<List>;
      skipToast?: boolean;
    },
    unknown
  >,
): void => {
  const { finished, column, toIndex } = event;

  if (!finished || !toIndex || !column) {
    return;
  }

  const element = column.getColId();
  const { id, config, updateColumnOrder } = listStore.getState();

  const columnOrder = [...config.columnOrder];
  const fromIndex = config.columnOrder.indexOf(element);

  columnOrder.splice(fromIndex, 1);
  columnOrder.splice(toIndex - 1, 0, element);
  const frozenColumns = columnOrder.filter((col) => config.frozenColumns?.includes(col));

  updateColumnOrder(columnOrder, frozenColumns);

  updateListMutation.mutate({
    id,
    list: { config: { ...config, columnOrder, frozenColumns } },
    skipToast: true,
  });
};
