import { isNil } from 'lodash';

export const generateInitialValue = ({
  value,
  eventKey,
}: {
  value: number;
  eventKey: string | null;
}): string | undefined => {
  if (eventKey && eventKey !== 'Enter') {
    return `${eventKey}`;
  }

  if (isNil(value)) {
    return undefined;
  }

  return `${value}`;
};
