import { Modal } from 'components/ui/atomic-components/modal';
import { Spinner } from 'components/ui/atomic-components/spinner';
import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme: { spacing } }) => `${spacing[32]} ${spacing[16]}`};
  gap: ${({ theme }) => theme.spacing[20]};
`;

const StyledModal = styled(Modal)`
  &&& .ant-modal-footer {
    display: none;
  }
`;

interface Props {
  open: boolean;
  text?: string | ReactNode;
}

export const ActionInProgressOverlay = ({ open, text }: Props): ReactElement => {
  return (
    <StyledModal centered closable={false} open={open} width={300}>
      <Wrapper>
        <Spinner size="large" />
        <div>{text}</div>
      </Wrapper>
    </StyledModal>
  );
};
