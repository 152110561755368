import { type BaseCellEditor } from 'ag-grid-community';
import { type CustomCellEditorProps } from 'ag-grid-react';
import { DatePicker } from 'components/ui/atomic-components';
import { DateTimeFormats } from 'config/constants';
import { formatTransformer } from 'data/date-format/utils/formatted-date';
import { DEFAULT_DATE_FORMAT } from 'data/modelling/lists/constants';
import dayjs, { type Dayjs } from 'dayjs';
import {
  type KeyboardEvent,
  forwardRef,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { checkIfLastRow } from '../utils';
import { CellEditorPopupBaseStyles } from '../utils/cell-editor-styles';
import { useCloseOnClickOutside } from '../utils/hooks/use-close-on-click-outside';
import { createInitialState } from './utils';

const StyledDatePicker = styled(DatePicker)<{ $width: number }>`
  &.ant-picker {
    ${CellEditorPopupBaseStyles};
  }
`;

export const GridEditorDatePicker = forwardRef<BaseCellEditor, CustomCellEditorProps>(
  (
    {
      eventKey,
      value,
      api,
      rowIndex,
      column,
      colDef,
      onKeyDown: defaultOnKeyDown,
      stopEditing,
      onValueChange,
    },
    ref,
  ) => {
    const dateFormat = colDef?.headerComponentParams?.dateFormat;

    const wrapperDomRef = useRef<HTMLDivElement>(null);

    const cellWidth = column.getActualWidth();

    const initialState = createInitialState({ eventKey, value });

    const [inputValue, setInputValue] = useState<Dayjs | null | undefined>(
      initialState.value ? dayjs(initialState.value) : undefined,
    );

    const [open, setOpen] = useState(true);

    const onStopEditing = useCallback(() => {
      setOpen(false);
      queueMicrotask(() => stopEditing(true));
    }, [stopEditing]);

    const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
      if (e.code === 'Escape') {
        setInputValue(value);
        queueMicrotask(() => stopEditing(true));
      }

      if (e.code === 'Tab') {
        setOpen(false);
        queueMicrotask(() => {
          stopEditing(true);
          defaultOnKeyDown(e as unknown as globalThis.KeyboardEvent);
        });
      }

      if (e.code === 'Enter') {
        const isLastRow = checkIfLastRow({ rowIndex: rowIndex + 1, api });

        setOpen(false);
        queueMicrotask(() => stopEditing(isLastRow));
      }
    };

    useCloseOnClickOutside(wrapperDomRef, onStopEditing);

    useImperativeHandle(ref, () => {
      return {
        isCancelAfterEnd: () => false,
      };
    });

    return (
      <div ref={wrapperDomRef}>
        <StyledDatePicker
          $width={cellWidth}
          allowClear={false}
          autoFocus
          format={formatTransformer(dateFormat || DEFAULT_DATE_FORMAT)}
          open={open}
          showNow={false}
          value={inputValue}
          onChange={(args) => {
            setInputValue(args as Dayjs | null | undefined);
            onValueChange(args !== null ? (args as Dayjs).format(DateTimeFormats.APIDate) : null);

            onStopEditing();
          }}
          onKeyDown={onKeyDown}
          onPanelChange={() => setOpen(true)}
        />
      </div>
    );
  },
);

GridEditorDatePicker.displayName = 'GridEditorDatePicker';
