import {
  type CsvExportParams,
  type ColDef,
  type GridApi,
  type ProcessCellForExportParams,
} from 'ag-grid-community';
import { QueryTableColumnDataType } from 'data/big-query';

const CsvExportOptions: CsvExportParams = {
  skipPinnedBottom: true,
};

const sanitizeOutput = (params: ProcessCellForExportParams) => {
  const { value, formatValue, column } = params;

  const columnType = column.getColDef().headerComponentParams?.columnType;
  const isDateColumn = columnType === QueryTableColumnDataType.Date;

  const outValue = isDateColumn ? formatValue(value) : value;

  // https://owasp.org/www-community/attacks/CSV_Injection
  const csvInjectionRegex = new RegExp(/^[-=@+]|^0x09|^0x0D/g);
  const isNumberRegex = new RegExp(/^-?\d+(\.\d+)?$/);

  return csvInjectionRegex.test(outValue) && !isNumberRegex.test(outValue)
    ? `"${outValue}"`
    : outValue;
};

export const exportListAsCsv = ({
  listName,
  gridApi,
}: {
  listName: string;
  gridApi?: GridApi;
}): void => {
  CsvExportOptions.fileName = `${listName}_${Date.now()}.csv`;

  CsvExportOptions.processCellCallback = sanitizeOutput;

  CsvExportOptions.columnKeys = gridApi
    ?.getColumnDefs()
    ?.filter((col: ColDef) => !col?.hide)
    ?.slice(1) // skip the # column
    ?.map(({ colId }: ColDef) => colId || '');

  gridApi?.exportDataAsCsv(CsvExportOptions);
};
