import { ConfirmationModal, Input } from 'components/ui/atomic-components';
import { useEffect, useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { useEditListRecon } from './hooks/use-edit-list-recon';
import { useSetupListRecon } from './hooks/use-setup-list-recon';

const { TextArea } = Input;

const StyledTextArea = styled(TextArea)`
  && {
    width: 600px;
    height: 600px;
    background-color: ${({ theme }) => theme.colors.bgMedium};
    margin-bottom: -${({ theme }) => theme.spacing[16]};
  }
`;

export const EditInputJsonModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}): ReactElement => {
  const intl = useIntl();
  const [inputJson, setInputJson] = useState<string>();
  const listId = useListStoreContext((s) => s.id);
  const reconConfig = useListStoreContext((s) => s.reconciliationConfig);
  const setupListReconMutation = useSetupListRecon();
  const editListReconMutation = useEditListRecon();

  const handleSave = async () => {
    if (!inputJson) {
      return;
    }

    if (reconConfig) {
      await editListReconMutation.mutateAsync({
        listId,
        request: JSON.parse(inputJson),
      });
    } else {
      await setupListReconMutation.mutateAsync({
        listId,
        request: JSON.parse(inputJson),
      });
    }

    setOpen(false);
  };

  useEffect(() => {
    if (reconConfig) {
      setInputJson(JSON.stringify(reconConfig, null, 4));
    } else {
      setInputJson(undefined);
    }
  }, [reconConfig]);

  return (
    <ConfirmationModal
      confirmLoading={setupListReconMutation.isLoading}
      okText={<FormattedMessage id="save" />}
      open={open}
      title={<FormattedMessage id="lists.input_json.title" />}
      width={600}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
    >
      <StyledTextArea
        placeholder={intl.formatMessage({ id: 'lists.input_json_modal.placeholder' })}
        value={inputJson}
        onChange={(e) => setInputJson(e.target.value)}
      />
    </ConfirmationModal>
  );
};
