import { Select } from 'components/ui/atomic-components';
import { type ID } from 'data';
import { type ListUpdate } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { useListReconMatchOptions } from '../../hooks/use-list-recon-match-options';

const StyledSelect = styled(Select)`
  min-width: 120px;
` as typeof Select;

interface Props {
  listUpdate: ListUpdate;
  onPickRecommendation?: (key?: ID) => void;
}

export const MatchOptionSelect = ({ listUpdate, onPickRecommendation }: Props): ReactElement => {
  const { data: recommendedListRows = [], isLoading } = useListReconMatchOptions();
  const { entityColumnName, matchCandidatesCriteria } = useListStoreContext(
    (s) => s.reconciliationConfig,
  );
  const recommendationOptions = recommendedListRows.map((row) => ({
    label: row.data[matchCandidatesCriteria?.displayColumn ?? entityColumnName],
    value: row.id,
  }));

  return (
    <StyledSelect
      key={listUpdate.id}
      isLoading={isLoading}
      isSearchable
      options={recommendationOptions}
      onChange={(option) => onPickRecommendation?.(option?.value)}
    />
  );
};
