import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { Form, IconText, Modal, Select } from 'components/ui/atomic-components';
import { type ReactElement, type Dispatch, type SetStateAction, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatName } from 'utils/data-formatter';
import styles from './styles';
import { columnOptionMapper } from './utils';

const { ModalContent, Label, DescriptionWrapper } = styles;

export interface PivotKeyFormProps {
  uniqueKeyColumns: string[];
  uniqueKey: string;
}

interface Props {
  listColumns: string[];
  defaultUniqueKeyColumns: string[];
  defaultUniqueKey: string;
  onSave: (data: PivotKeyFormProps) => Promise<void>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const PivotKeyModal = ({
  listColumns,
  defaultUniqueKeyColumns,
  defaultUniqueKey,
  onSave,
  open,
  setOpen,
}: Props): ReactElement => {
  const { updateListMutation } = useListMutations();

  const [form] = Form.useForm<PivotKeyFormProps>();

  const uniqueKeyColumns = Form.useWatch('uniqueKeyColumns', form) || [];

  const handleUniqueKeySave = async () => {
    const data = await form.validateFields();

    await onSave(data);

    setOpen(false);
  };

  const uniqueKeyOptions = useMemo(() => {
    const uniqueKeyOptions = listColumns.map(columnOptionMapper);

    uniqueKeyOptions.sort((o1, o2) => o1.label.localeCompare(o2.label));

    return uniqueKeyOptions;
  }, [listColumns]);

  useEffect(() => {
    form.setFieldsValue({
      uniqueKeyColumns: defaultUniqueKeyColumns || [],
      uniqueKey: formatName(defaultUniqueKey),
    });
  }, [defaultUniqueKeyColumns, defaultUniqueKey, form]);

  return (
    <Modal
      afterClose={() => form.resetFields()}
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={updateListMutation.isLoading}
      destroyOnClose
      okText={<FormattedMessage id="apply" />}
      open={open}
      title={<IconText icon={KeyIcon} text={<FormattedMessage id="lists.set_unique_key" />} />}
      onCancel={() => setOpen(false)}
      onOk={() => handleUniqueKeySave()}
    >
      <Form
        form={form}
        initialValues={{
          uniqueKeyColumns: defaultUniqueKeyColumns || [],
          uniqueKey: formatName(defaultUniqueKey),
        }}
        preserve={false}
      >
        <ModalContent>
          <div>
            <DescriptionWrapper>
              <FormattedMessage id="lists.pivot_key_description" />
            </DescriptionWrapper>
            <Label>
              <FormattedMessage id="lists.unique_key_modal.set_unique_key_with.label" />
            </Label>

            <Select
              isClearable={false}
              isMulti
              isSearchable
              options={uniqueKeyOptions}
              size="large"
              value={uniqueKeyColumns.map(columnOptionMapper)}
              onChange={(updatedValue) =>
                form.setFieldValue(
                  'uniqueKeyColumns',
                  updatedValue.map((value) => value.value),
                )
              }
            />
            <Form.Item hidden name="uniqueKeyColumns" />
          </div>
        </ModalContent>
      </Form>
    </Modal>
  );
};
