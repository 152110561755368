import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as TrashIcon } from 'assets/v2/delete.svg';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { IconShell } from 'components/ui/atomic-components';
import { DirectoryTree } from 'components/ui/atomic-components/directory-tree';
import { type DirectoryTreeNodeDataType } from 'components/ui/atomic-components/directory-tree/types';
import { type ItemType } from 'components/ui/atomic-components/menu';
import { DeleteConfirmWithUsage } from 'components/ui/resource-usage-widget';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type List } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { AccessAction } from 'data/roles/permissions';
import { isEmpty } from 'lodash';
import { useMemo, type ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { b } from 'styles/html-elements';
import { formatName } from 'utils/data-formatter';
import { DeleteConfirmationModal } from '../../delete-confirmation-modal';
import { useListMutations } from '../../hooks';
import { ListsEmpty } from '../lists-empty';
import { constructFilteredTreeData } from './utils';

const Wrapper = styled.div`
  overflow: auto;
`;

interface Props {
  lists: (List | DimensionGroup)[];
  searchQuery: string;
}

export const Directory = ({ lists, searchQuery }: Props): ReactElement => {
  const { listId } = useParams() as { listId: string };
  const queryClient = useQueryClient();
  const allLists: (List | DimensionGroup)[] = queryClient.getQueryData(['lists']) ?? [];
  const [currentList, setCurrentList] = useState<List | DimensionGroup | undefined>();
  const [confirmNormalListDelete, setConfirmNormalListDelete] = useState(false);
  const [confirmDerivedListDelete, setConfirmDerivedListDelete] = useState(false);

  const { deleteListMutation } = useListMutations();
  const { deleteDimensionGroup } = useDimensionGroupMutations();
  const isReadOnly = isInVersionMode([VersionAccessModes.View, VersionAccessModes.Edit]);

  const treeData = useMemo(
    () => constructFilteredTreeData({ lists, searchQuery, listId }),
    [lists, searchQuery, listId],
  );

  const noSearchResults = isEmpty(treeData);

  const overflowMenuItems = (directoryTreeNodeData: DirectoryTreeNodeDataType): ItemType[] => {
    const isDimGroup = directoryTreeNodeData.key?.toString()?.startsWith(DIM_GROUP_ID_PREFIX);
    const itemId = isDimGroup
      ? directoryTreeNodeData.key.toString().split('-')?.[1]
      : directoryTreeNodeData.key;
    const currentItem = allLists.find(
      (list) =>
        list.id === Number(itemId) &&
        (isDimGroup ? (list as DimensionGroup)?.uniqueKeyColumns : true),
    );

    if (
      !(currentItem as DimensionGroup)?.uniqueKeyColumns &&
      (currentItem as List)?.accessAction !== AccessAction.FullAccess
    ) {
      return [];
    }

    return [
      {
        key: 'delete-row',
        danger: true,
        icon: <IconShell icon={TrashIcon} />,
        label: <FormattedMessage id="lists.delete_list" />,
        onClick: () => {
          setCurrentList(currentItem);
          if (isDimGroup) {
            setConfirmDerivedListDelete(true);
          } else {
            setConfirmNormalListDelete(true);
          }
        },
      },
    ];
  };

  return (
    <>
      <Wrapper>
        <DirectoryTree
          overflowMenuItems={isReadOnly ? undefined : overflowMenuItems}
          selectedKeys={[listId]}
          treeData={treeData}
        />

        {noSearchResults && <ListsEmpty />}
      </Wrapper>

      <DeleteConfirmWithUsage
        confirmLoading={deleteListMutation?.isLoading}
        displayName={currentList?.displayName || ''}
        name={currentList?.name || ''}
        noUsagesMessage={
          <FormattedMessage
            id="delete_modal_formula_del.list.question"
            values={{ list: formatName(currentList?.name), b }}
          />
        }
        open={confirmNormalListDelete}
        title={<FormattedMessage id="lists.delete_list_modal.title" />}
        type="LISTS"
        onCancel={() => setConfirmNormalListDelete(false)}
        onOk={() => {
          if (!currentList) {
            return;
          }

          deleteListMutation
            .mutateAsync(currentList.id)
            .then(() => setConfirmNormalListDelete(false));
        }}
      />

      <DeleteConfirmationModal
        deleteInProgress={deleteDimensionGroup?.isLoading}
        listName={currentList?.displayName || ''}
        open={confirmDerivedListDelete}
        onClose={() => setConfirmDerivedListDelete(false)}
        onOk={() => {
          if (!currentList) {
            return;
          }

          deleteDimensionGroup.mutateAsync(currentList as DimensionGroup);
        }}
      />
    </>
  );
};
