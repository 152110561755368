import { type ListRow } from 'data/modelling/lists';
import { type TextOptionType } from './types';

export const generateOptions = (
  listRows: ListRow[],
  colId: string,
  cellValue?: string,
): TextOptionType[] => {
  const uniqueValuesMap = new Set();
  const result: TextOptionType[] = [];

  listRows.forEach((row) => {
    const value = row.data[colId];

    if (value && !uniqueValuesMap.has(value)) {
      if (value === cellValue) {
        result.unshift({
          value,
          label: value,
        });
      } else {
        result.push({
          value,
          label: value,
        });
      }
      uniqueValuesMap.add(value);
    }
  });

  return result;
};

export const getSelectedOptions = (value: string): TextOptionType | null => {
  if (!value) {
    return null;
  }

  return {
    label: value,
    value,
  };
};

export const filterOption = ({
  inputValue,
  option,
  isSelectiveAccessColumn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  inputValue: string;
  isSelectiveAccessColumn: boolean;
}): boolean => {
  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
  const isCreateOption = (option?.data as any).__isNew__;

  if (isCreateOption && isSelectiveAccessColumn) {
    return false;
  }

  return true;
};

export const generateInitialValue = (eventKey: string | null): string | undefined => {
  if (eventKey) {
    if (eventKey !== 'Enter') {
      return String(eventKey);
    }
  }
};
