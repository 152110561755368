import { Card as AntdCard, type CardProps } from 'antd';
import styled from 'styled-components';
import { P } from 'styles/typography';

interface CardAtomProps extends CardProps {
  $compact?: boolean;
}

export const Card = styled(AntdCard)<CardAtomProps>`
  border-color: ${({ theme }) => theme.border.color};
  border-radius: ${({ theme }) => theme.border.radius.lg};
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-feature-settings: 'tnum' 0;

  .ant-card-head {
    border: 0;
    min-height: 0;
    padding: 0 ${({ theme }) => theme.spacing[16]};

    &-title {
      ${P};

      font-weight: ${({ theme: { fontWeight } }) => fontWeight.normal};
      padding-bottom: ${({ $compact, theme }) => {
        return $compact ? theme.spacing[16] : 0;
      }};
    }
  }

  .ant-card-body {
    padding: ${({ $compact, theme }) => ($compact ? 0 : theme.spacing[16])};
  }

  .ant-card-actions > li > span {
    cursor: inherit;
  }

  .ant-table-container table tr {
    th:first-child,
    td:first-child {
      padding-left: ${({ theme: { spacing } }) => spacing[16]};
    }
  }
`;
