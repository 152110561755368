import { type OptionBaseOld, SelectOption } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { type OptionProps } from 'react-select';
import { renderDimensionOption } from '../../utils';

export const OptionComponent = ({
  data,
  label,
  ...rest
}: OptionProps<OptionBaseOld>): ReactElement => {
  return (
    <SelectOption data={data} label={label} {...rest}>
      {renderDimensionOption({
        label: String(data.label),
        value: data.value,
        parentSourceDisplayName: (data as { sourceTableDisplayName: string })
          .sourceTableDisplayName,
      })}
    </SelectOption>
  );
};
