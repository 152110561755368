import { type AxiosRequestConfig } from 'axios';

export const BASE_CASE_NAME = 'Base_Case';
export const SCENARIO = 'Scenario';

const BASE_QUERY_KEY = 'scenarios';

export const ScenarioStoreKeys = {
  QueryKey: BASE_QUERY_KEY,
  AllRecords: (config?: AxiosRequestConfig): (string | AxiosRequestConfig)[] => {
    const key: (string | AxiosRequestConfig)[] = [ScenarioStoreKeys.QueryKey];

    if (config) {
      key.push(config);
    }

    return key;
  },
};
