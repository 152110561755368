import { type FC, useMemo, createContext } from 'react';
import { type PageTemplate } from '../types';

export interface PageTemplateContextProps {
  template: PageTemplate;
}

export const PageTemplateContext = createContext<PageTemplateContextProps | undefined>(undefined);

export const PageTemplateProvider: FC<React.PropsWithChildren<{ data: PageTemplate }>> = ({
  data,
  children,
}) => {
  const value = useMemo(() => {
    return {
      template: data,
    };
  }, [data]);

  return <PageTemplateContext.Provider value={value}>{children}</PageTemplateContext.Provider>;
};
