import { ReactComponent as ErrorIcon } from 'assets/v2/exclamation-circle.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const Alert = styled.div`
  width: 100%;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme }) => theme.colors.red100};
  border: 1px solid ${({ theme }) => theme.colors.red200};
  padding: ${({ theme }) => theme.spacing[8]};
  gap: ${({ theme }) => theme.spacing[8]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const ErrorAlert = ({ error }: { error: string }): ReactElement => {
  return (
    <Alert>
      <IconShell color="red500" icon={ErrorIcon} />
      {error}
    </Alert>
  );
};
