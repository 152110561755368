import { AuthorisedAccess } from 'components/modules/authorised-access';
import { EmptyLists } from 'components/modules/modelling/lists/empty';
import { PageLayout } from 'components/ui/layout';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { useLists } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { PermissionList } from 'data/roles/permissions';
import { isEmpty } from 'lodash';
import { type FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const ListsNewPage: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'lists' });
  const { data = [], isFetched } = useLists();

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (!isEmpty(data)) {
      const isDimGroup = !!(data?.[0] as DimensionGroup)?.uniqueKeyColumns;
      const listId = isDimGroup ? `${DIM_GROUP_ID_PREFIX}${data?.[0].id}` : data?.[0].id;

      navigate(`/lists/${listId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched]);

  return (
    <AuthorisedAccess permissionType={PermissionList.Model}>
      <PageLayout title={title}>
        <EmptyLists />
      </PageLayout>
    </AuthorisedAccess>
  );
};
