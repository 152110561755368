import { useQueryClient } from '@tanstack/react-query';
import { conversationNotification } from 'components/ui/atomic-components';
import { pusher, EventsToSkipNotifications } from 'config/pusher';
import { isArray, isEqual } from 'lodash';
import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'utils/hooks';
import { ConversationApi } from './api';
import { CONVERSATION_EVENT_NAME, ConversationsStoreKeys } from './constants';
import { type Conversation, type ConversationWSData } from './types';
import { getConversationIdentifier } from './utils';

export const useLiveConversations = (): void => {
  const { user } = useUser();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateConversationStore = useCallback(
    (data: ConversationWSData): void => {
      const containerStoreKey = [
        ConversationsStoreKeys.BaseKey,
        data?.resourceType,
        data?.resourceId,
      ];

      if (!queryClient.getQueriesData(containerStoreKey)) {
        return;
      }
      ConversationApi.findRecord([data?.conversationId]).then(([convData]) => {
        const identifier = getConversationIdentifier(convData);
        const resourceStoreKey = [...containerStoreKey, identifier];

        if (!queryClient.getQueriesData(resourceStoreKey)) {
          queryClient.setQueriesData(resourceStoreKey, []);
        }

        queryClient.setQueriesData(
          {
            predicate: ({ queryKey }) => {
              if (!isArray(queryKey) || queryKey[0] !== 'conversations') {
                return false;
              }

              if (isArray(queryKey[1]) && queryKey[1]?.includes(data?.conversationId)) {
                return true;
              }

              return isEqual(queryKey, containerStoreKey) || isEqual(queryKey, resourceStoreKey);
            },
          },
          (oldData?: Conversation[]) => [
            convData,
            ...(oldData || []).filter((conv) => conv.id !== data?.conversationId),
          ],
        );
      });
    },
    [queryClient],
  );

  const showNotification = useCallback(
    (data: ConversationWSData): void => {
      // show the toast notifications
      const redirectionURL = new URL(data?.redirectionLink);
      const urlPath = `${redirectionURL.pathname}${redirectionURL.search}`;

      conversationNotification.info({
        message: data?.notificationHeader,
        description: data?.notificationMessage,
        onClick: (): void => {
          navigate(urlPath);
        },
      });
    },
    [navigate],
  );

  const onMessage = useCallback(
    (data: ConversationWSData): void => {
      // Ignore if the message sender is same as current user
      if (data?.createdBy === user?.username) {
        return;
      }
      updateConversationStore(data);

      if (!EventsToSkipNotifications.includes(data?.eventName)) {
        showNotification(data);
      }
    },
    [showNotification, updateConversationStore, user?.username],
  );

  useEffect(() => {
    if (user?.username && user?.tenant) {
      const userChannelName = `${user?.username}-${user?.tenant}`;
      const publicChannelName = `${user?.tenant}`;
      const userChannel = pusher.subscribe(userChannelName);
      const publicChannel = pusher.subscribe(publicChannelName);

      userChannel.bind(CONVERSATION_EVENT_NAME, onMessage);
      publicChannel.bind(CONVERSATION_EVENT_NAME, onMessage);

      return () => {
        pusher.unsubscribe(userChannelName);
        pusher.unsubscribe(publicChannelName);
      };
    }
  }, [onMessage, user]);
};
