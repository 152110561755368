import { type ButtonProps } from 'antd';
import {
  css,
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
} from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { StyledMargins } from 'styles/styled-atoms';
import { FontM, FontS, FontXs } from 'styles/typography';

export const buttonStyle = ({
  theme,
}: ButtonProps & { theme: DefaultTheme }): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    ${StyledMargins};

    /* stylelint-disable no-duplicate-selectors */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: ${theme.fontWeight.medium};

    &&:not(.ant-btn-icon-only) > .ant-btn-icon {
      margin-inline-end: 0;
    }

    & .btn-content {
      margin-inline-start: ${theme.spacing[4]};
      margin-inline-end: ${theme.spacing[4]};
    }

    .ant-btn-icon.ant-btn-loading-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${switchProp(prop('size', 'middle'), {
      extraSmall: css`
        ${FontXs};

        height: 24px;
        border-radius: ${theme.borderRadius.s};
        padding: ${({ theme: { spacing } }) => `${spacing[2]} ${spacing[8]}`};

        &.ant-btn-icon-only {
          width: 24px;
        }

        .ant-btn-icon.ant-btn-loading-icon {
          width: 12px;
          height: 12px;
        }
      `,
      small: css`
        ${FontXs};

        height: 28px;
        border-radius: ${theme.borderRadius.s};
        padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[8]}`};

        &.ant-btn-icon-only {
          width: 28px;
        }

        &.ant-btn-sm {
          ${FontXs};
        }
      `,
      middle: css`
        ${FontS};

        height: 36px;
        border-radius: ${theme.borderRadius.m};
        padding: ${({ theme: { spacing } }) => `${spacing[8]}`};

        &.ant-btn-icon-only {
          width: 36px;
        }
      `,
      large: css`
        ${FontM};

        height: 40px;
        border-radius: ${theme.borderRadius.m};
        padding: ${({ theme: { spacing } }) => `${spacing[8]}`};

        &.ant-btn-icon-only {
          width: 40px;
        }

        &.ant-btn.ant-btn-lg {
          ${FontM};

          padding: ${({ theme: { spacing } }) => `${spacing[8]}`};
        }
      `,
      extraLarge: css`
        ${FontM};

        height: 48px;
        border-radius: ${theme.borderRadius.l};
        padding: ${({ theme: { spacing } }) => `${spacing[12]} ${spacing[8]}`};

        &.ant-btn-icon-only {
          width: 48px;
        }
      `,
    })};

    ${switchProp(prop('type', 'default'), {
      default: css`
        color: ${theme.colors.textTitle2};
        border: 1px solid ${theme.colors.buttonOutlineBorder};
        box-shadow: none;

        &.ant-btn-dangerous {
          border: 1px solid ${theme.colors.buttonOutlineBorder};
          box-shadow: none;
        }

        &.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
          background-color: ${theme.colors.buttonOutlineHover};
          color: ${theme.colors.red600};
          border-color: ${theme.colors.buttonOutlineBorder};
        }

        &.ant-btn-dangerous:disabled,
        &.ant-btn-dangerous.ant-btn-disabled {
          background: ${theme.colors.gray00};
        }

        :not(:disabled):not(:active):hover {
          color: ${theme.colors.textTitle1};
          background: ${theme.colors.buttonOutlineHover};
          border-color: ${theme.colors.buttonOutlineBorder};
        }

        :not(:disabled):not(.ant-btn-disabled):active {
          color: ${theme.colors.textTitle1};
          background: ${theme.colors.buttonOutlineHover};
          border-color: ${theme.colors.buttonOutlineBorder};
        }

        &[disabled] {
          background: ${theme.colors.gray00};
          color: ${theme.colors.textDisabled};
        }

        &:not(:disabled):focus-visible {
          outline: 1px solid #e5e7ebcc;
          outline-offset: 2px;
        }
      `,
      primary: css`
        background: ${theme.colors.buttonFillPrimary};

        &.ant-btn-dangerous {
          box-shadow: none;
        }

        &.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
          background-color: ${theme.colors.buttonFillNegativeHover};
        }

        &.ant-btn-dangerous:not(:disabled):focus-visible {
          outline: 1px solid ${theme.colors.red500};
          outline-offset: 2px;
        }

        &[disabled] {
          background: ${theme.colors.buttonFillDisabledPrimary};
          box-shadow: none;
          color: ${theme.colors.textNone};
          border: transparent;
        }

        &:not(:disabled):focus-visible {
          outline: 1px solid ${theme.colors.primary500};
          outline-offset: 2px;
        }
      `,
      text: css`
        & {
          color: ${theme.colors.textBody2};
        }

        :not(:disabled):not(:active):hover {
          background: ${theme.colors.buttonOutlineGhostHover};
          color: ${theme.colors.textTitle1};
        }

        &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
          background: ${theme.colors.buttonOutlineGhostHover};
          color: ${theme.colors.textTitle1};
        }

        &:not(:disabled):focus-visible {
          box-shadow: 0 0 0 2px #fff, 0 0 0 3 #e5e7eb;
          outline: none;
          background: ${theme.colors.gray00};
        }

        &.ant-btn-dangerous {
          box-shadow: none;
        }

        &.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
          background-color: ${theme.colors.buttonOutlineGhostHover};
          color: ${theme.colors.buttonFillNegativeHover};
        }
      `,
      link: css`
        padding: 0;

        & {
          ${switchProp(prop('size', 'middle'), {
            extraSmall: css`
              height: 18px;
            `,
            small: css`
              height: 19px;
            `,
            middle: css`
              height: 20px;
            `,
            large: css`
              height: 22px;

              &.ant-btn.ant-btn-lg {
                ${FontM};

                padding: ${({ theme: { spacing } }) => `0 ${spacing[2]}`};
              }
            `,
          })};
        }
      `,
    })};
  `;
};
