import { type CompletionContext, autocompletion, type Completion } from '@codemirror/autocomplete';
import { type EditorState, type Extension } from '@codemirror/state';
import { type EditorView } from '@codemirror/view';
import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { getFunctionNameFromState } from './function-info-tooltip/utils/get-function-name-from-state';

const checkIfDimensionIsArgumentTo = ({
  functionsToCheckFor,
  functionName,
  optionType,
}: {
  functionsToCheckFor: string[];
  functionName: string | null;
  optionType: FormulaBarTypes;
}) => {
  if (!functionName) {
    return false;
  }

  return (
    [
      FormulaBarTypes.DatasetExpression,
      FormulaBarTypes.DimensionExpression,
      FormulaBarTypes.DimensionGroupExpression,
    ].includes(optionType) && functionsToCheckFor.includes(functionName)
  );
};

const applyCompletion = (option: FormulaAutocompleteOption, state: EditorState) => {
  return (view: EditorView, completion: Completion, from: number, to: number) => {
    let { replacementText } = option;

    if (!replacementText) {
      return;
    }

    // add a non-breaking space at the end of text
    replacementText += ' ';

    let cursorPosition = from + replacementText.length;

    const dimensionIsArgument = checkIfDimensionIsArgumentTo({
      functionsToCheckFor: ['summarize', 'collapse'],
      functionName: getFunctionNameFromState({ state, from }),
      optionType: option.formulaType,
    });

    if (dimensionIsArgument) {
      replacementText = getColumnNameFromRawDimensionName(replacementText);
      cursorPosition = from + replacementText.length;
    } else if (option.formulaType === FormulaBarTypes.Math) {
      cursorPosition = from + replacementText.length - 3;
    }

    view.dispatch({
      changes: { from, to, insert: replacementText },
      selection: { anchor: cursorPosition, head: cursorPosition },
    });
  };
};

export const autoCompletion = (autoCompletionOptions: FormulaAutocompleteOption[]): Extension => {
  const completions = (context: CompletionContext) => {
    const word = context.matchBefore(/[\w@#$%^&!]*/);

    if (!word || (word.from === word.to && !context.explicit)) {
      return null;
    }

    return {
      from: word.from,
      options: autoCompletionOptions.map((option) => ({
        label: option.displayText,
        displayLabel: option.displayText,
        type: option.type as string,
        apply: applyCompletion(option, context.state),
        detail: option.secondaryDisplayText,
      })),
      validFor: /^\s*.*\s*$/,
    };
  };

  return autocompletion({
    override: [completions],
    icons: true,
    optionClass: (completion: Completion) => `autocomplete-option-${completion.type}`,
  });
};
