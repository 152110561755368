import { type InputRef } from 'antd';
import { type ReactElement, useRef, useState, type ReactNode } from 'react';
import { flushSync } from 'react-dom';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { FontM, FontS, FontXl, SingleLineEllipsis } from 'styles/typography';
import { Input, type InputProps } from '../input';

const Wrapper = styled.div<{ size: InputProps['size']; editable?: boolean; isEmpty: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  max-width: 100%;
  padding: ${({ theme: { spacing } }) => `${spacing[2]} ${spacing[4]}`};
  color: ${({ theme: { colors }, isEmpty }) => {
    return isEmpty ? colors.textPlaceholder : colors.textTitle1;
  }};

  ${switchProp(prop('size', 'medium'), {
    extraSmall: css`
      ${FontS};

      height: 24px;
      border-radius: ${({ theme }) => theme.borderRadius.xs};
    `,
    small: css`
      ${FontM};

      height: 28px;
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      border-radius: ${({ theme }) => theme.borderRadius.s};
    `,
    medium: css`
      ${FontXl};

      height: 36px;
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      border-radius: ${({ theme }) => theme.borderRadius.m};
    `,
  })};

  > span {
    ${SingleLineEllipsis};
  }

  ${(props) =>
    props.editable &&
    css`
      :hover {
        background-color: ${({ theme }) => theme.colors.bgMedium};
      }
    `};

  ${switchProp(prop('size', 'medium'), {
    extraSmall: css`
      ${FontS};
    `,
    small: css`
      ${FontM};

      font-weight: ${({ theme }) => theme.fontWeight.medium};
    `,
    medium: css`
      ${FontXl};

      font-weight: ${({ theme }) => theme.fontWeight.medium};
    `,
  })};
`;

const StyledInput = styled(Input)``;

interface Props extends InputProps {
  editable?: boolean;
}

export const InlineEditInput = ({ editable, ...inputProps }: Props): ReactElement => {
  const ref = useRef<InputRef>(null);

  const [isInEditState, setIsInEditState] = useState(false);

  if (isInEditState) {
    return (
      <StyledInput
        $inputType={'ghostText'}
        {...inputProps}
        ref={ref}
        onBlur={(e) => {
          inputProps?.onBlur?.(e);
          setIsInEditState(false);
        }}
        onPressEnter={() => ref.current?.blur()}
      />
    );
  }

  return (
    <Wrapper
      className={inputProps.className}
      editable={editable}
      isEmpty={!inputProps.value}
      size={inputProps.size}
      onClick={() => {
        if (editable) {
          flushSync(() => {
            setIsInEditState(true);
          });
          ref.current?.focus({ cursor: 'end' });
        }
      }}
    >
      <span title={inputProps.value as string}>
        {(inputProps.value || inputProps.placeholder) as ReactNode}
      </span>
    </Wrapper>
  );
};
