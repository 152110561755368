import { Decoration, MatchDecorator, type ViewPlugin, WidgetType } from '@codemirror/view';
import { DimensionPropertyTag } from 'components/ui/atomic-components/formula-editor-v2/tag-components/dimension-property-tag';
import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import { type ExpressionSecondaryTextLookup } from 'components/ui/codemirror-v2/formula-bar/types';
import { createRoot } from 'react-dom/client';
import { FormulaTermRegexMap } from './constants';
import { constructViewPlugin } from './utils';

class DimensionPropertyConstruct extends WidgetType {
  propertyTerm: RegExpExecArray;
  secondaryTextLookup?: ExpressionSecondaryTextLookup;
  readOnly?: boolean;

  constructor(
    propertyTerm: RegExpExecArray,
    secondaryTextLookup?: ExpressionSecondaryTextLookup,
    readOnly?: boolean,
  ) {
    super();
    this.propertyTerm = propertyTerm;
    this.secondaryTextLookup = secondaryTextLookup;
    this.readOnly = readOnly;
  }

  toDOM() {
    const wrapper = document.createElement('span');
    const wrapperRoot = createRoot(wrapper);

    wrapperRoot.render(
      <DimensionPropertyTag
        propertyTerm={this.propertyTerm}
        readOnly={this.readOnly}
        secondaryTextLookup={this.secondaryTextLookup}
      />,
    );

    return wrapper;
  }
}

export const constructDatasetPropertyMatchPlugin = (
  readOnly?: boolean,
  secondaryTextLookup?: ExpressionSecondaryTextLookup,
): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.DatasetExpression],
    decoration: (match) => {
      return Decoration.replace({
        widget: new DimensionPropertyConstruct(match, secondaryTextLookup, readOnly),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};

export const constructDatasetColumnPropertyMatchPlugin = (
  readOnly?: boolean,
  secondaryTextLookup?: ExpressionSecondaryTextLookup,
): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.DatasetColumnExpression],
    decoration: (match) => {
      return Decoration.replace({
        widget: new DimensionPropertyConstruct(match, secondaryTextLookup, readOnly),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};

export const constructListPropertyMatchPlugin = (
  readOnly?: boolean,
  secondaryTextLookup?: ExpressionSecondaryTextLookup,
): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.DimensionExpression],
    decoration: (match) => {
      return Decoration.replace({
        widget: new DimensionPropertyConstruct(match, secondaryTextLookup, readOnly),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};

export const constructDimGroupPropertyMatchPlugin = (
  readOnly?: boolean,
  secondaryTextLookup?: ExpressionSecondaryTextLookup,
): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.DimensionGroupExpression],
    decoration: (match) => {
      return Decoration.replace({
        widget: new DimensionPropertyConstruct(match, secondaryTextLookup, readOnly),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};

export const constructListColumnMatchPlugin = (
  readOnly?: boolean,
  secondaryTextLookup?: ExpressionSecondaryTextLookup,
): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.ListColumnExpression],
    decoration: (match) => {
      return Decoration.replace({
        widget: new DimensionPropertyConstruct(match, secondaryTextLookup, readOnly),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};
