import { type QueryClient } from '@tanstack/react-query';
import { type ID } from 'data';
import { type List } from 'data/modelling/lists';
import { isEmpty } from 'lodash';

export const removeCacheForAllListsWithFormulaColumns = (
  queryClient: QueryClient,
  currentListId: ID,
): void => {
  queryClient.removeQueries({
    predicate: (query) => {
      const { queryKey } = query;

      if (queryKey.includes('lists')) {
        const list: List | undefined = queryClient.getQueryData(queryKey);

        return !isEmpty(list?.config?.columnFormulae) && list?.id !== currentListId;
      }

      return false;
    },
  });

  queryClient.invalidateQueries(['lists', currentListId], {
    predicate: (query) => {
      const { queryKey } = query;

      const list: List | undefined = queryClient.getQueryData(queryKey);

      return !isEmpty(list?.config?.columnFormulae);
    },
  });
};
