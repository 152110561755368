import { type ReactElement } from 'react';
import { PanelResizeHandle } from 'react-resizable-panels';
import styled from 'styled-components';

const Wrapper = styled(PanelResizeHandle)<{ direction: 'horizontal' | 'vertical' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ direction }) => (direction === 'vertical' ? '16px' : '100%')};
  width: ${({ direction }) => (direction === 'horizontal' ? '16px' : '100%')};
  background: transparent;

  --background-color: transparent;

  :hover {
    --background-color: ${({ theme }) => theme.colors.gray100};
  }
`;

const Handle = styled.div`
  height: 4px;
  display: flex;
  width: calc(100% - ${({ theme }) => theme.spacing[32]});
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
`;

type ResizeHandleProps = {
  direction: 'horizontal' | 'vertical';
};

export const ResizeHandle = ({ direction }: ResizeHandleProps): ReactElement => {
  return (
    <Wrapper direction={direction}>
      <Handle />
    </Wrapper>
  );
};
