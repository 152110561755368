import {
  type QueryTableColumnDataType,
  type VisualQueryBasicFilterRuleValue,
  type VisualQueryFilterOperator,
} from 'data/big-query';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { RuleValueRendererMap } from './value-renderers';
import { type RuleValueMetaInfo } from './value-renderers/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const Label = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.gray600};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

interface Props {
  data?: VisualQueryBasicFilterRuleValue[];
  dataType?: QueryTableColumnDataType;
  operator?: VisualQueryFilterOperator;
  meta: RuleValueMetaInfo;
  onChange: (value: VisualQueryBasicFilterRuleValue[]) => void;
}

export const FilterRuleValueSelector = ({
  data,
  dataType,
  operator,
  onChange,
  meta,
}: Props): ReactElement => {
  const onInputChange = (value: VisualQueryBasicFilterRuleValue[]) => {
    onChange(value);
  };

  if (!dataType || !operator) {
    return <></>;
  }

  const Renderer = RuleValueRendererMap[dataType][operator]?.renderer;

  if (!Renderer) {
    return <></>;
  }

  return (
    <Wrapper>
      <Label>
        <FormattedMessage id="values" />
      </Label>
      <Renderer data={data} meta={meta} onChange={onInputChange} />
    </Wrapper>
  );
};
