const BASE_QUERY_KEY = 'lists';

export const ListStoreKeys = {
  QueryKey: BASE_QUERY_KEY,
  SingleRecord: (
    listId: number,
    options?: Record<string, unknown>,
  ): (string | number | Record<string, unknown>)[] => {
    const key: (string | number | Record<string, unknown>)[] = ['lists', listId];

    if (options) {
      key.push(options);
    }

    return key;
  },
};
