import { type ReconciliationConfig } from 'data/modelling/lists';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { type FormProps } from '../types';

export const setInitialValues = (reconConfig: ReconciliationConfig): Partial<FormProps> => {
  return {
    dimensions: reconConfig.sourceDatasetColumns?.map((col) => ({
      parentSourceDisplayName: col.tableDisplayName,
      columnType: col.type,
      label: formatName(col.colName),
      value: col.source,
      name: col.colName,
      tableDisplayName: col.tableDisplayName,
    })),
    uniqueKeyColumns: reconConfig.uniqueColumns?.map((col) => {
      const selectedCol = reconConfig.sourceDatasetColumns?.find((dim) => dim.colName === col);

      return {
        value: selectedCol?.source || col,
        label: getColumnNameFromRawDimensionName(formatName(col)),
        parentSourceDisplayName: selectedCol?.tableDisplayName || '',
      };
    }),
    filters: reconConfig?.sourceDatasetFilters,
  };
};
