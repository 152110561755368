import { type ReactElement } from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme.colors.red500};
  border-radius: ${({ theme }) => theme.borderRadius.full};
`;

export const ErrorIndicator = (): ReactElement => {
  return <Indicator />;
};
