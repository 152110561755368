import { type ReactElement, type ReactNode } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { ContextWrapper } from '../context-wrapper';

const Wrapper = styled.div`
  ${FontXs};

  display: inline-block;
  background-color: ${({ theme }) => theme.colors.green200};
  padding: 0 ${({ theme }) => theme.spacing[6]};
  color: ${({ theme }) => theme.colors.green600};
  border-radius: 2px;
  cursor: pointer;
  margin: 0 ${({ theme }) => theme.spacing[2]};
  white-space: nowrap;
`;

export const TextTag = ({ text }: { text: string | ReactNode | ReactElement }): ReactElement => {
  return (
    <ContextWrapper>
      <Wrapper>{text}</Wrapper>
    </ContextWrapper>
  );
};
