import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension/api';
import { type DimTableDetailsResponse } from 'data/modelling/dimension/types';

export const useApplicableColumns = (
  dimensionNames: string[],
): UseQueryResult<DimTableDetailsResponse, unknown> => {
  const query = useQuery(
    ['dimension-table-columns', dimensionNames],
    () => ModelDimensionApi.getTableDetails(dimensionNames),
    {
      staleTime: StaleTime.Short,
    },
  );

  return query;
};
