/* eslint-disable @calm/react-intl/missing-formatted-message */
import { ReactComponent as PopoutLinkIcon } from 'assets/ic_popout_link.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { FormConnectorStyles, PopupModalStyles } from '../styles';

const { StyledP, StyledOL, StyledLI } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer } = FormConnectorStyles;

export const SnowFlakeUserNamePassInfoContent = ({
  item,
}: {
  item: IntegrationModalContent;
}): ReactElement => {
  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/databases-and-warehouses/integrating-with-snowflake';

  return (
    <LeftPanel>
      <ImageContainer alt="" src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)} />
      <Title>{item.displayName}</Title>

      <StyledP>
        <FormattedMessage id="integrations.snowflake.info.desc" />
      </StyledP>

      <StyledP>
        <FormattedMessage id="integrations.snowflake.info.prerequisites" />
      </StyledP>

      <StyledOL>
        <StyledLI>Account ID</StyledLI>
        <StyledLI>Username</StyledLI>
        <StyledLI>Password</StyledLI>
        <StyledLI>Warehouse</StyledLI>
        <StyledLI>Database</StyledLI>
      </StyledOL>

      <StyledP>
        <strong>
          <FormattedMessage id="integrations.modal.add_resources" />
        </strong>
      </StyledP>

      <Button
        icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
        onClick={() => window.open(docLink, '_blank', 'noopener')}
      >
        <FormattedMessage id="integrations.modal.linktext" />
      </Button>
      <CommonContent {...item} />
    </LeftPanel>
  );
};

export const SnowFlakeKeyPairInfoContent = ({
  item,
}: {
  item: IntegrationModalContent;
}): ReactElement => {
  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/databases-and-warehouses/integrating-with-snowflake';

  return (
    <LeftPanel>
      <ImageContainer alt="" src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)} />
      <Title>{item.displayName}</Title>

      <StyledP>
        <FormattedMessage id="integrations.snowflake.info.desc" />
      </StyledP>

      <StyledP>
        <FormattedMessage id="integrations.snowflake.info.prerequisites" />
      </StyledP>

      <StyledOL>
        <StyledLI>Account ID</StyledLI>
        <StyledLI>Username</StyledLI>
        <StyledLI>Private Key</StyledLI>
        <StyledLI>Warehouse</StyledLI>
        <StyledLI>Database</StyledLI>
      </StyledOL>

      <StyledP>
        <strong>
          <FormattedMessage id="integrations.modal.add_resources" />
        </strong>
      </StyledP>

      <Button
        icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
        onClick={() => window.open(docLink, '_blank', 'noopener')}
      >
        <FormattedMessage id="integrations.modal.linktext" />
      </Button>
      <CommonContent {...item} />
    </LeftPanel>
  );
};
