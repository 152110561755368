import { ReactComponent as EyeIcon } from 'assets/v2/scan-eye.svg';
import { AsyncSwitcher } from 'components/ui/async-switcher';
import { IconShell, Modal } from 'components/ui/atomic-components';
import { useLoadResourceUsage } from 'data/resource-usage/hooks';
import { type ResourceUsageResp, type ResourceUsageType } from 'data/resource-usage/types';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { b } from 'styles/html-elements';
import { UsageList } from './usage-list';

interface Props {
  usageProps?: {
    name: string;
    displayName: string;
    type: ResourceUsageType;
  };
  onClose: () => void;
}

export const ResourceUsageModal = ({ usageProps, onClose }: Props): ReactElement => {
  const { name, displayName, type } = usageProps || {};

  const query = useLoadResourceUsage({ name, type, options: { enabled: !!name && !!type } });

  return (
    <Modal
      cancelText={<FormattedMessage id="close" />}
      open={!!usageProps}
      title={<FormattedMessage id="resource_usage.modal.title" />}
      titleIcon={<IconShell icon={EyeIcon} />}
      width={765}
      onCancel={onClose}
    >
      <AsyncSwitcher {...query}>
        {(data: ResourceUsageResp) => (
          <>
            <FormattedMessage
              id="resource_usage.modal.headline"
              values={{ resourceDisplayName: displayName, b }}
            />

            <UsageList dependencies={data.dependencies} />
          </>
        )}
      </AsyncSwitcher>
    </Modal>
  );
};
