import { Tag } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { CommonStyles } from './styles';

const { ItemWrapper, Title, SubTitle } = CommonStyles;

export const CommonContent = (item: IntegrationModalContent): ReactElement => {
  return (
    <>
      <ItemWrapper>
        <Tag size="small">{item.category}</Tag>
      </ItemWrapper>
      {item.supportUrl && (
        <ItemWrapper>
          <Title>
            <FormattedMessage id="integrations.modal.common.support" />
          </Title>
          <SubTitle>{item.supportUrl}</SubTitle>
        </ItemWrapper>
      )}
      {item.contactUsUrl && (
        <ItemWrapper>
          <Title>
            <FormattedMessage id="integrations.modal.common.contact" />
          </Title>
          <SubTitle>{item.contactUsUrl}</SubTitle>
        </ItemWrapper>
      )}
    </>
  );
};
