import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { b } from 'styles/html-elements';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { updateVersion } from '../utils';

export const usePublishVersionMutation = (): {
  publishModelVersion: UseMutationResult<ModelVersion, AxiosError<unknown>, void, unknown>;
} => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const publishModelVersion = useMutation(
    () => {
      return ModelVersionApi.saveVersion();
    },
    {
      onSuccess: (data) => {
        notification.success({
          message: intl.formatMessage({
            id: 'model.versions.save.success.title',
          }),
          description: (
            <FormattedMessage
              id="model.versions.save.success.desc"
              values={{
                value: data.name,
                b,
              }}
            />
          ),
        });

        updateVersion(queryClient, data);
      },
      onError: defaultApiErrorHandler,
    },
  );

  return { publishModelVersion };
};
