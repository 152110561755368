import { DatePicker } from 'components/ui/atomic-components';
import { DateTimeFormats } from 'config/constants';
import dayjs, { type Dayjs } from 'dayjs';
import { DateSelectPopupClassName } from './constants';
import { type VisualRuleEditRenderCompParams } from './types';

export const DateInput: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  return (
    <DatePicker
      rootClassName={DateSelectPopupClassName}
      value={data?.[0] ? dayjs(data?.[0]?.value) : null}
      onChange={(args) => {
        if (args) {
          onChange([{ value: dayjs(args as Dayjs).format(DateTimeFormats.APIDate) }]);
        } else {
          onChange([]);
        }
      }}
    />
  );
};
