import { type OktaAuth } from '@okta/okta-auth-js';
import { type QueryClient } from '@tanstack/react-query';
import axios, { type AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { type LDClient } from 'launchdarkly-react-client-sdk';
import { handle401Response } from './utils/handle-401-response';

axios.defaults.baseURL = '/drive/api/';

export const setupAxios = (
  oktaAuth: OktaAuth,
  queryClient: QueryClient,
  ldClient?: LDClient,
): void => {
  axios.interceptors.request.use(async (config) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accessToken = await (oktaAuth as any).getOrRenewAccessToken();

    const samlCookie = Cookies.get('dt-sm');

    if (samlCookie) {
      // TODO(tark)
      config.headers.Authorization = 'Basic dXNlcjo=';
    } else {
      config.headers.Authorization = `Bearer ${accessToken || ''}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        handle401Response({ error, oktaAuth, queryClient, ldClient });
      }

      throw error;
    },
  );
};
