import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension/api';
import { type ColumnValueItem } from 'data/modelling/dimension/types';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useSelectedColumnValues = (
  colName?: string,
): UseQueryResult<ColumnValueItem[], unknown> => {
  const query = useQuery(
    ['selected-column-values', colName],
    () => {
      if (!colName) {
        throw 'invalid request';
      }

      return ModelDimensionApi.getColumnValues(colName);
    },
    {
      staleTime: StaleTime.Short,
      enabled: Boolean(colName),
      onError: defaultApiErrorHandler,
    },
  );

  return query;
};
