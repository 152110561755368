import axios from 'axios';

export const XeroSignupApi = {
  authorize: (
    code: string,
  ): Promise<{
    username: string;
    fullName: string;
  }> => {
    return axios
      .post('v1/public/webhooks/xero/oauth', {
        code,
      })
      .then(({ data }) => data);
  },
};
