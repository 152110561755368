import { ReactComponent as BahtIcon } from 'assets/v2/currencies/baht.svg';
import { ReactComponent as ColonIcon } from 'assets/v2/currencies/colon.svg';
import { ReactComponent as DaiIcon } from 'assets/v2/currencies/dai.svg';
import { ReactComponent as DirhamIcon } from 'assets/v2/currencies/dirham.svg';
import { ReactComponent as AustralianDollarIcon } from 'assets/v2/currencies/dollar-australian.svg';
import { ReactComponent as CanadianDollarIcon } from 'assets/v2/currencies/dollar-canadian.svg';
import { ReactComponent as SingaporeDollarIcon } from 'assets/v2/currencies/dollar-singapore.svg';
import { ReactComponent as DollarIcon } from 'assets/v2/currencies/dollar.svg';
import { ReactComponent as EtheriumIcon } from 'assets/v2/currencies/etherium.svg';
import { ReactComponent as EuroIcon } from 'assets/v2/currencies/euro.svg';
import { ReactComponent as FrankIcon } from 'assets/v2/currencies/frank.svg';
import { ReactComponent as KroneIcon } from 'assets/v2/currencies/krone-danish.svg';
import { ReactComponent as LeuIcon } from 'assets/v2/currencies/leu.svg';
import { ReactComponent as PesoChileanIcon } from 'assets/v2/currencies/peso-chilean.svg';
import { ReactComponent as PesoMexicanIcon } from 'assets/v2/currencies/peso-mexican.svg';
import { ReactComponent as PoundIcon } from 'assets/v2/currencies/pound.svg';
import { ReactComponent as RandIcon } from 'assets/v2/currencies/rand.svg';
import { ReactComponent as RealIcon } from 'assets/v2/currencies/real.svg';
import { ReactComponent as RupeeIcon } from 'assets/v2/currencies/rupee.svg';
import { ReactComponent as RupiahIcon } from 'assets/v2/currencies/rupiah.svg';
import { ReactComponent as ShekelIcon } from 'assets/v2/currencies/shekel.svg';
import { ReactComponent as SomIcon } from 'assets/v2/currencies/som.svg';
import { ReactComponent as WonIcon } from 'assets/v2/currencies/won.svg';
import { ReactComponent as YenIcon } from 'assets/v2/currencies/yen.svg';
import { ReactComponent as YuanIcon } from 'assets/v2/currencies/yuan.svg';
import { Currency } from 'data/currencies';

export const currencyIconMap = {
  [Currency.AED]: DirhamIcon,
  [Currency.AUD]: AustralianDollarIcon,
  [Currency.CAD]: CanadianDollarIcon,
  [Currency.EUR]: EuroIcon,
  [Currency.GBP]: PoundIcon,
  [Currency.INR]: RupeeIcon,
  [Currency.SGD]: SingaporeDollarIcon,
  [Currency.USD]: DollarIcon,
  [Currency.ZAR]: RandIcon,
  [Currency.THB]: BahtIcon,
  [Currency.ILS]: ShekelIcon,
  [Currency.JPY]: YenIcon,
  [Currency.CNY]: YuanIcon,
  [Currency.ETH]: EtheriumIcon,
  [Currency.DAI]: DaiIcon,
  [Currency.DKK]: KroneIcon,
  [Currency.BGN]: SomIcon,
  [Currency.BRL]: RealIcon,
  [Currency.RON]: LeuIcon,
  [Currency.CHF]: FrankIcon,
  [Currency.IDR]: RupiahIcon,
  [Currency.MXN]: PesoMexicanIcon,
  [Currency.KRW]: WonIcon,
  [Currency.CRC]: ColonIcon,
  [Currency.CLP]: PesoChileanIcon,
};
