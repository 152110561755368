import { ReactComponent as PopoutLinkIcon } from 'assets/ic_popout_link.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { quickbooksLogin } from 'components/modules/settings/fivetran/common/connectors-auth';
import { IconShell, Button } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { useCommonLoginFlow } from '../hooks/use-common-flow';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';

const { StyledLI, StyledP, StyledButton } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer, FullWidthWrapper, RightPanel, RightTitle } =
  FormConnectorStyles;

export const QuickbooksContent: FC<React.PropsWithChildren<ModalContentProps>> = ({ item }) => {
  const onClick = () => {
    quickbooksLogin({ item });
  };

  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/accounting-and-erp/integrating-with-quickbooks';

  const { isLoading } = useCommonLoginFlow(item);

  return (
    <FullWidthWrapper>
      <LeftPanel>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Title>{item.displayName}</Title>
        <StyledP>
          <FormattedMessage id="integrations.qbooks.modal.desc" />
        </StyledP>
        <StyledP>
          <strong>
            <FormattedMessage
              id="integrations.modal.common.para"
              values={{ service: item.displayName }}
            />
          </strong>
        </StyledP>
        <ol>
          <StyledLI>
            <FormattedMessage id="integrations.qbooks.modal.line1" />
          </StyledLI>
          <StyledLI>
            <FormattedMessage id="integrations.qbooks.modal.line2" />
          </StyledLI>
        </ol>
        <Button
          icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
          onClick={() => window.open(docLink, '_blank', 'noopener')}
        >
          <FormattedMessage id="integrations.modal.linktext" />
        </Button>
        <CommonContent {...item} />
      </LeftPanel>
      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <StyledButton loading={isLoading} type="primary" onClick={onClick}>
          <FormattedMessage id="integrations.qbooks.modal.button_text" />
          <IconShell color="gray00" icon={ArrowRightIcon} />
        </StyledButton>
      </RightPanel>
    </FullWidthWrapper>
  );
};
