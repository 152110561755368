import { type GridApi } from 'ag-grid-community';
import { GRID_AUTO_COLUMN_ID } from 'components/ui/atomic-components/grid/constants';

interface Props {
  gridApi?: GridApi;
  fileName?: string;
  columnKeys?: string[];
}

const TAB_SPACE = 4;

export const downloadGridAsExcel = ({ gridApi, fileName, columnKeys }: Props): void => {
  gridApi?.exportDataAsExcel({
    author: 'Drivetrain',
    fileName: `${fileName || 'export'}.xlsx`,
    sheetName: 'Sheet 1',
    rowGroupExpandState: 'collapsed',
    /**
     * Ag-grid works well with alignment: indent: x but that doesn't work
     * well when the excel sheet is imported into google sheets. To make it
     * work for that scenario, we will have to manually prepend spaces by hand.
     * This method basically does that:
     *
     * Ag-grid export invokes this method on every cell.
     * While the process per cell happens, we run through to check
     * if the column is first grouping column.
     * If yes, then a tab space is prepended depending on the hierarchy
     * in which the cell is.
     * For the first row and first cell, there is no spacing added but if a metric
     * has 3 dimensions, the second row would correspond to a dimension, and hence
     * its hierarchy length will be 2. We then figure out the right tab space using
     * this hierarchy length and prepend that to the output cell value.
     * @returns String
     */
    processCellCallback: ({ column, node, value }) => {
      if (column.getColId() !== GRID_AUTO_COLUMN_ID || !value) {
        return value;
      }

      const spaceToPrepend = new Array((node?.data.hierarchy.length - 1) * TAB_SPACE)
        .fill(' ')
        .join('');

      // Fixing an ag-grid ENG-40141
      if (column.getColId() === GRID_AUTO_COLUMN_ID && String(value).trim().startsWith('->')) {
        return `${spaceToPrepend}${node?.data.displayValue}`;
      }

      return `${spaceToPrepend}${value}`;
    },
    columnKeys,
  });
};
