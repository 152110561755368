import { InlineEditInput } from 'components/ui/atomic-components/inline-edit-input';
import { DESC_CHAR_LIMIT } from 'data/modelling/constants';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { useListMutations } from '../hooks';

interface Props {
  isReadOnly?: boolean;
}

export const Description = ({ isReadOnly }: Props): ReactElement => {
  const intl = useIntl();

  const listId = useListStoreContext((s) => s.id);
  const description = useListStoreContext((s) => s.description);
  const setDescription = useListStoreContext((s) => s.setDescription);

  const { updateListMutation } = useListMutations();

  const saveDescription = () => {
    updateListMutation.mutate({ id: listId, list: { description: (description || '').trim() } });
  };

  return (
    <InlineEditInput
      $inputType={'ghostText'}
      editable={!isReadOnly}
      maxLength={DESC_CHAR_LIMIT}
      placeholder={intl.formatMessage({
        id: 'description_placeholder_with_ellipsis',
      })}
      size="extraSmall"
      value={description || ''}
      onBlur={() => saveDescription()}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
};
