import { ConfirmationModal } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { b } from 'styles/html-elements';
import { formatName } from 'utils/data-formatter';

interface Props {
  listName: string;
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  deleteInProgress?: boolean;
}

export const DeleteConfirmationModal = ({
  open,
  onClose,
  listName,
  onOk,
  deleteInProgress,
}: Props): ReactElement => {
  return (
    <ConfirmationModal
      confirmLoading={deleteInProgress}
      open={open}
      title={<FormattedMessage id="lists.delete_list_modal.title" />}
      type="delete"
      onCancel={onClose}
      onOk={onOk}
    >
      <FormattedMessage
        id="delete_modal_formula_del.list.question"
        values={{ list: formatName(listName), b }}
      />
    </ConfirmationModal>
  );
};
