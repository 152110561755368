import { ReactComponent as TickIcon } from 'assets/ic_tick.svg';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';
import { IconShell } from '../icon-shell';
import { Caption as CaptionAtom } from '../typography';

const Wrapper = styled.div`
  display: flex;
  white-space: initial;
  line-height: ${({ theme }) => theme.lineHeight.caption};
  width: 100%;
`;

const Caption = styled(CaptionAtom)`
  margin-bottom: ${({ theme: { spacing } }) => spacing[4]};
`;

const IconWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing[12]};
  color: ${({ theme }) => theme.colors.black300};
`;

const Text = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.black900};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  margin: ${({ theme: { spacing } }) => `${spacing[4]} 0 ${spacing[4]} 0`};
`;

const SelectedIcon = styled(TickIcon).attrs({
  className: 'descriptive-menu-item-selected-icon',
})`
  color: ${({ theme: { colors } }) => colors.blue60};
  min-width: ${({ theme: { iconSize } }) => iconSize[16]};
  margin-top: ${({ theme: { spacing } }) => spacing[4]};
  margin-left: ${({ theme: { spacing } }) => spacing[8]};
  display: none;
`;

/**
 * @deprecated non porsche component
 */
export const DescriptiveMenuItem: FC<
  React.PropsWithChildren<{
    description?: ReactNode | string;
    icon?: ReactNode;
    title: ReactNode | string;
  }>
> = ({ description, icon, title }) => {
  return (
    <Wrapper>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Text>
        <div>{title}</div>
        {description && <Caption $muted>{description}</Caption>}
      </Text>
      <IconShell icon={SelectedIcon} />
    </Wrapper>
  );
};
