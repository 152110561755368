import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { UsersApi } from '../api';
import { type User } from '../types';

export const useUsersByQuery = (searchQuery?: string): UseQueryResult<User[]> =>
  useQuery(['users', searchQuery], () => UsersApi.getUsers(searchQuery), {
    enabled: !!searchQuery,
    staleTime: Infinity,
  });

export const useUsers = (): UseQueryResult<User[]> =>
  useQuery(['users'], () => UsersApi.getAllUsers(), {
    staleTime: Infinity,
  });
