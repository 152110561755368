import axios from 'axios';
import { type ID } from 'data';
import { type ModelVersion } from './types';

export const ModelVersionApi = {
  /**
   * Save the model version
   */
  async saveVersion(): Promise<ModelVersion> {
    const { data } = await axios.put('/v1/model/publish');

    return data;
  },

  async saveAsNewVersion(version: AtLeast<ModelVersion, 'displayName'>): Promise<ModelVersion> {
    const { data } = await axios.post('/v1/versions', version);

    return data;
  },

  async getVersions({
    includeCurrent,
    includeActual,
    includeArchived,
  }: {
    includeCurrent?: boolean;
    includeActual?: boolean;
    includeArchived?: boolean;
  }): Promise<ModelVersion[]> {
    const { data } = await axios.get('/v1/versions', {
      params: {
        includeCurrent,
        includeActual,
        includeArchived,
        includeSCCLInfo: true,
      },
    });

    return data;
  },

  async delete(versionId: ID): Promise<void> {
    const { data } = await axios.delete(`/v1/versions/${versionId}`);

    return data;
  },

  async update(version: AtLeast<ModelVersion, 'id'>): Promise<ModelVersion> {
    const { data } = await axios.patch(`/v1/versions/${version.id}`, version);

    return data;
  },

  async archiveVersion(versionId: ID): Promise<ModelVersion> {
    const { data } = await axios.put(`/v1/versions/${versionId}/archive`);

    return data;
  },

  async unArchiveVersion(versionId: ID): Promise<ModelVersion> {
    const { data } = await axios.put(`/v1/versions/${versionId}/unarchive`);

    return data;
  },

  async exitVersionMode({ discard }: { discard?: boolean }): Promise<void> {
    const { data } = await axios.get(`/v1/models/${discard ? 'discard' : 'save'}-version-edits`);

    return data;
  },
};
