import { type CheckboxGroupProps } from 'antd/es/checkbox';
import styled from 'styled-components';
import { Checkbox } from '../checkbox';
import { checkboxGroupStyles } from './styles';

type Props = CheckboxGroupProps & { size?: 'extraSmall' | 'small' | 'middle' | 'large' };

export const CheckboxGroup = styled(Checkbox.Group)<Props>`
  ${({ theme, size }) => checkboxGroupStyles({ theme, size })};
`;
