import { syntaxTree } from '@codemirror/language';
import { type EditorState } from '@codemirror/state';

export const getFunctionNameFromState = ({
  state,
  from,
}: {
  state: EditorState;
  from: number;
}): string | null => {
  const syntaxTreeObj = syntaxTree(state);

  let syntaxNode: ReturnType<typeof syntaxTreeObj.resolveInner> | null = syntaxTreeObj.resolveInner(
    from,
    0,
  );

  while (syntaxNode && syntaxNode.type.name !== 'Function') {
    syntaxNode = syntaxNode.parent;
  }

  if (!syntaxNode || !syntaxNode.firstChild) {
    return null;
  }

  const functionName = state.sliceDoc(syntaxNode.firstChild?.from, syntaxNode.firstChild?.to);

  return functionName?.trim();
};
