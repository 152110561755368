import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { RolesApi } from '../api';
import { type Role } from '../types';

export const useRoles = (): Omit<UseQueryResult<Role[]>, 'data'> & {
  roles?: Role[];
} => {
  const { data, ...rest } = useQuery(['roles'], () => RolesApi.getAllRoles());

  return {
    roles: data,
    ...rest,
  };
};
