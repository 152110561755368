import { type GridApi } from 'ag-grid-community';
import { ReactComponent as FilterIcon } from 'assets/v2/filter.svg';
import { Button, IconShell, Popover } from 'components/ui/atomic-components';
import { type QueryTableColumnDataType } from 'data/big-query';
import { clone, isEmpty } from 'lodash';
import { type ReactElement, useState } from 'react';
import { useListStoreContext } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { getFilteredRows } from '../../../hooks/use-grid-row-data/utils';
import { Content } from './content';

export const ListsFilter = ({
  colName,
  type,
  api,
}: {
  colName: string;
  api: GridApi;
  type: QueryTableColumnDataType;
}): ReactElement => {
  const columnFilters = useListStoreContext((s) => s.columnFilters);
  const setColumnFilters = useListStoreContext((s) => s.setColumnFilters);
  const pushToUndoStack = useListStoreContext((s) => s.pushToUndoRedoStack);
  const setFilteredRows = useListStoreContext((s) => s.setFilteredRows);
  const searchQuery = useListStoreContext((s) => s.searchQuery);
  const rows = useListStoreContext((s) => s.rows);
  const config = useListStoreContext((s) => s.config);

  const [open, setOpen] = useState(false);

  const filterPresentForColumn = !!columnFilters?.[colName];

  const onApply = (selectedFilterValues: string[]) => {
    const updatedFilter = {
      ...columnFilters,
    };

    if (!isEmpty(selectedFilterValues)) {
      updatedFilter[colName] = new Set(selectedFilterValues);
    } else {
      delete updatedFilter[colName];
    }

    setColumnFilters(updatedFilter);

    setFilteredRows(
      getFilteredRows({
        rowData: rows,
        columnFilters: updatedFilter,
        columnNames: config?.columnOrder || [],
        columnTypeMap: config?.columnTypeMap || {},
        searchQuery,
      }),
    );

    queueMacroTask(() => api.refreshCells({ columns: ['row-index'] })); // to update row number column

    pushToUndoStack({
      type: 'column-filter-change',
      meta: {
        column: colName,
        previousFilter: clone(columnFilters),
        currentFilter: clone(updatedFilter),
      },
    });

    setOpen(false);
  };

  return (
    <Popover
      content={<Content colName={colName} type={type} onApply={onApply} />}
      destroyTooltipOnHide
      open={open}
      placement="bottomRight"
      onOpenChange={(v) => setOpen(v)}
    >
      <Button
        className={filterPresentForColumn ? 'column-filter-applied' : ''}
        icon={
          <IconShell
            color="iconDefault"
            fill={filterPresentForColumn ? 'iconDefault' : undefined}
            icon={FilterIcon}
            size="sm"
          />
        }
        size="extraSmall"
        type="text"
      />
    </Popover>
  );
};
