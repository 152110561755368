import { TimeDimensionName, type Dimension, DimSpace } from 'data/dimension';
import { type ModuleMetric } from 'data/modelling/metric';
import { PIVOT_DIMENSION_METRIC_ROW_NAME, type Pivot } from 'data/page-template';
import { type Dictionary } from 'lodash';

export const generateMetricsMaps = (
  metrics: ModuleMetric[],
  pivot: Pivot,
  tempSccl?: boolean,
): {
  metricsMap: Dictionary<ModuleMetric>;
  metricPivotMap: Map<string, { rows: string[]; columns: string[] }>;
} => {
  const metricsMap: Dictionary<ModuleMetric> = {};
  const metricPivotMap = new Map<string, { rows: string[]; columns: string[] }>();

  metrics.forEach((metric) => {
    const { name } = metric;

    metricsMap[name] = metric;

    const rows = getRowsFromDimensions(metric.dimensions, tempSccl);

    metricPivotMap.set(name, { rows, columns: [TimeDimensionName] });
  });

  return {
    metricsMap,
    metricPivotMap,
  };
};

const getRowsFromDimensions = (dimensions: Dimension[], tempSccl?: boolean): string[] => {
  const result: string[] = [PIVOT_DIMENSION_METRIC_ROW_NAME];

  dimensions.forEach((dimension) => {
    if (dimension.dimSpace === (tempSccl ? DimSpace.View : DimSpace.Plan)) {
      result.push(dimension.name);
    }
  });

  return result;
};
