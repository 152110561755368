import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';
import { MoreActionsDropdown } from '../../dropdown';

export const styles = {
  DirectoryNodeWrapper: styled.div<{ hideLine?: boolean }>`
    cursor: pointer;
    padding-left: ${({ theme }) => theme.spacing[28]};
    padding-top: ${({ theme }) => theme.spacing[8]};
    height: fit-content;
    position: relative;

    .node-active {
      color: ${({ theme }) => theme.colors.textTitle2};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      background-color: ${({ theme }) => theme.colors.bgMedium};

      > div > a:active {
        color: ${({ theme }) => theme.colors.textTitle2};
      }
    }

    .more-actions {
      display: none;
    }

    .node-focus,
    .node-active-focus {
      .more-actions {
        display: flex;
        background-color: ${({ theme }) => theme.colors.bgDark};
      }
    }

    .node-focus {
      background-color: ${({ theme }) => theme.colors.gray50};
    }

    .node-active-focus {
      background-color: ${({ theme }) => theme.colors.bgMedium};
    }

    ::before {
      content: '';
      background-color: white;
      position: absolute;
      top: ${({ theme }) => theme.spacing[20]};
      bottom: 0;
      left: ${({ theme }) => theme.spacing[12]};
      z-index: 1;
      width: ${({ hideLine }) => (hideLine ? '1px' : '0')};
    }
  `,

  DirectoryNodeContent: styled.div<{
    hideLine?: boolean;
    isDragging?: boolean;
    className?: string;
  }>`
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    padding-left: ${({ theme }) => theme.spacing[6]};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    height: 28px;
    color: ${({ theme }) => theme.colors.gray600};
    pointer-events: ${({ isDragging }) => (isDragging ? 'none' : 'auto')};
    background-color: ${({ theme: { colors }, isDragging }) => {
      return isDragging ? colors.bgMedium : colors.gray00;
    }};

    &:hover {
      background-color: ${({ theme, className }) => theme.colors.bgMedium};

      .more-actions {
        display: flex;
      }
    }

    ::before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      bottom: ${({ theme }) => theme.spacing[12]};
      left: -${({ theme }) => theme.spacing[16]};
      border-bottom: ${({ hideLine, theme }) => {
        return hideLine ? 'none' : `1px solid ${theme.colors.gray200}`;
      }};
      border-left: ${({ hideLine, theme }) => {
        return hideLine ? 'none' : `1px solid ${theme.colors.gray200}`;
      }};
      border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
    }
  `,

  DirectoryNodeTitle: styled(Highlighter)`
    ${SingleLineEllipsis}

    font-size: ${({ theme }) => theme.fontSize.xs};

    .highlight {
      background-color: ${({ theme }) => theme.colors.textHighlight};
      padding: 0;
      white-space: pre;
    }

    .unhighlight {
      white-space: pre;
    }

    user-select: none; /* disable text selection */
  `,

  StyledMoreActionsDropdown: styled(MoreActionsDropdown)`
    &.ant-btn-icon-only {
      width: 20px;
      height: 20px;
    }

    &&&:hover {
      background-color: ${({ theme }) => theme.colors.bgDark};
    }
  `,
};
