import { type ICellRendererParams } from 'ag-grid-community';
import { Button } from 'components/ui/atomic-components';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { type TraceOutputTerm } from 'data/formula-trace/types';
import { type ReactElement } from 'react';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType } from 'utils/data-formatter/types';
import { useFormulaTraceStore } from '../../store';

export const ComponentValue = ({ value, data }: ICellRendererParams): ReactElement => {
  const currency = useCurrency();
  const traceNode = useFormulaTraceStore((state) => state.traceNode);
  const setTraceNode = useFormulaTraceStore((state) => state.setTraceNode);

  return (
    <Button
      type="link"
      onClick={() => {
        const term = data.term as TraceOutputTerm;

        if (term && traceNode?.payload) {
          setTraceNode({
            payload: traceNode?.payload,
            term,
            previousNode: traceNode,
            isLoading: false,
          });
        }
      }}
    >
      {numberFormatter({
        value,
        type: DataFormattingType.Number,
        digitsAfterDecimal: 2,
        currency,
      })}
    </Button>
  );
};
