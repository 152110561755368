import { forwardRef, type ReactElement, type Ref, type RefObject } from 'react';
import ReactSelect, { type GroupBase, type Props } from 'react-select';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { type OptionBase, SelectOptionOld } from './option';
import { getDropdownSmallSize, getDropdownMediumSize, getDropdownLargeSize } from './styles';

export type SelectSizesOld = 'small' | 'default' | 'large';

type BaseSelectProps<T, IsMulti extends boolean> = Props<T, IsMulti, GroupBase<T>> & {
  ref?: RefObject<unknown>;
};
export interface SelectProps<T, IsMulti extends boolean = false>
  extends BaseSelectProps<T, IsMulti> {
  size?: SelectSizesOld;
  width?: number;
}

type ReactSelectProps = {
  width?: number;
  $size: SelectSizesOld;
};
const StyledReactSelect = styled.div<ReactSelectProps>`
  width: ${({ width }) => width}px;

  .select-option {
    ${switchProp(prop('$size', 'default'), {
      small: css`
        height: ${({ theme }) => theme.height.sm}px;
        max-height: ${({ theme }) => theme.height.sm}px;
      `,
      default: css`
        height: ${({ theme }) => theme.height.md}px;
        max-height: ${({ theme }) => theme.height.md}px;
      `,
      large: css`
        height: ${({ theme }) => theme.height.lg}px;
        max-height: ${({ theme }) => theme.height.lg}px;
      `,
    })}
  }
`;

const SelectBase = <T extends OptionBase, IsMulti extends boolean>(
  {
    classNamePrefix,
    size = 'default',
    components,
    width,
    styles,
    isSearchable = false,
    ...props
  }: SelectProps<T, IsMulti>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  const customStyles = {
    small: getDropdownSmallSize<T, IsMulti>(),
    default: getDropdownMediumSize<T, IsMulti>(),
    large: getDropdownLargeSize<T, IsMulti>(),
  }[size];

  return (
    <StyledReactSelect $size={size} width={width}>
      <ReactSelect<T, IsMulti>
        classNamePrefix={classNamePrefix || 'react-select'}
        components={{
          Option: SelectOptionOld,
          ...components,
        }}
        isSearchable={isSearchable}
        styles={{
          ...customStyles,
          ...styles,
        }}
        {...props}
        ref={ref}
      />
    </StyledReactSelect>
  );
};

export const SelectOld = forwardRef(SelectBase) as <
  T extends OptionBase,
  IsMulti extends boolean = false,
>(
  p: SelectProps<T, IsMulti> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
