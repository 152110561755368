import { type DataNode } from 'antd/es/tree';
import { ReactComponent as HandlerIcon } from 'assets/ic_handle.svg';
import { AsyncSwitcher } from 'components/ui/async-switcher';
import { IconShell, IconText, type IconType, Tree } from 'components/ui/atomic-components';
import { type FC, type ReactElement, type ReactNode, useEffect, useState, type Key } from 'react';
import styled from 'styled-components';
import { Caption } from 'styles/typography';
import { type TreeDraggableProps } from './types';

const TitleWrapper = styled.div`
  ${Caption};

  display: flex;
  width: 100%;
  height: ${({ theme: { height } }) => height.xl}px;
  padding: 0 ${({ theme: { spacing } }) => spacing[12]};
  border-bottom: solid 1px ${({ theme: { border } }) => border.color};
  box-shadow: ${({ theme: { boxShadow } }) => boxShadow[2]};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
  align-items: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing[4]};

  > :first-child {
    flex: 1;
  }
`;

const LeftNavTreeTitle: FC<React.PropsWithChildren<{ icon: IconType; title: ReactNode }>> = ({
  children,
  icon,
  title,
}) => {
  return (
    <TitleWrapper>
      <IconText icon={icon} text={title} />
      {children}
    </TitleWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-tree {
    background: transparent;
    color: ${({ theme: { colors } }) => colors.black900};

    .ant-tree-list-holder-inner {
      min-width: 100%;
    }

    .ant-tree-title {
      display: flex;

      a {
        color: inherit;
      }

      svg {
        min-width: 16px;
      }

      > :first-child {
        flex: 1;
      }

      .ant-dropdown-trigger {
        display: none;
      }

      .ant-dropdown-open {
        display: inherit;
      }

      &:hover,
      &:active {
        .ant-dropdown-trigger {
          display: inherit;
        }
      }
    }

    .ant-tree-treenode.ant-tree-treenode-selected {
      border-right: solid 3px ${({ theme: { colors } }) => colors.blue70};
      font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
      color: ${({ theme: { colors } }) => colors.blue70};
    }

    .ant-tree-switcher.ant-tree-switcher-noop {
      width: ${({ theme: { iconSize } }) => iconSize[32]};
    }

    .tree-folder-node--open {
      display: none;
    }

    .ant-tree-node-content-wrapper-open {
      .tree-folder-node--open {
        display: inline;
      }

      .tree-folder-node--closed {
        display: none;
      }
    }

    .ant-tree-draggable-icon {
      margin-top: ${({ theme }) => theme.spacing[8]};
      padding-left: ${({ theme }) => theme.spacing[12]};
      opacity: 0;
    }

    .ant-tree-treenode-draggable {
      padding-left: ${({ theme }) => theme.spacing[0]};

      .ant-tree-switcher.ant-tree-switcher-noop {
        width: 0;
        padding-right: ${({ theme }) => theme.spacing[12]};
      }

      .ant-tree-title {
        padding-right: ${({ theme }) => theme.spacing[12]};
      }

      :hover,
      :active {
        background: ${({ theme }) => theme.colors.black30};

        .ant-tree-draggable-icon {
          opacity: 0.4;
        }
      }
    }
  }
`;

export const LeftNavTree = ({
  children,
  expandedKeys,
  isError = false,
  isLoading = false,
  selectedKeys,
  treeData,
  onDrop,
  draggable,
  onExpandKeys,
  className,
  treeClassName,
}: {
  children?: ReactNode;
  expandedKeys?: string[];
  isError?: boolean;
  isLoading?: boolean;
  selectedKeys?: string[];
  treeData: DataNode[];
  draggable?: boolean;
  onDrop?: (info: TreeDraggableProps) => void;
  onExpandKeys?: (keys: string[]) => void;
  className?: string;
  treeClassName?: string;
}): ReactElement => {
  const [controlledExpandedKeys, setControlledExpandedKeys] = useState<Key[] | undefined>([]);

  useEffect(() => {
    setControlledExpandedKeys(expandedKeys);
  }, [expandedKeys]);

  return (
    <Wrapper className={className}>
      {children}
      <AsyncSwitcher
        components={{ Empty: () => <></> }}
        data={treeData}
        isError={isError}
        isLoading={isLoading}
        loaderSize="medium"
      >
        {() => {
          return (
            <Tree
              className={treeClassName}
              defaultExpandParent
              draggable={draggable && { icon: <IconShell icon={HandlerIcon} /> }}
              expandedKeys={controlledExpandedKeys}
              selectedKeys={selectedKeys}
              style={{ overflow: 'auto' }}
              treeData={treeData}
              onDrop={onDrop}
              onExpand={(expandedKeys) => {
                setControlledExpandedKeys(expandedKeys);
                onExpandKeys?.(expandedKeys as string[]);
              }}
            />
          );
        }}
      </AsyncSwitcher>
    </Wrapper>
  );
};

LeftNavTree.Title = LeftNavTreeTitle;
