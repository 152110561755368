import { useContext } from 'react';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi, useStore } from 'zustand';
import { ListStoreContext } from '../provider';

export const useListStoreContext = <T>(selector: (state: ListsStoreApi) => T): T => {
  const store = useContext(ListStoreContext);

  if (!store) {
    throw new Error('Missing ListStoreContext');
  }

  return useStore(store, selector);
};

export const useListStoreInstance = (): StoreApi<ListsStoreApi> => {
  const store = useContext(ListStoreContext);

  if (!store) {
    throw new Error('Missing ListStoreContext');
  }

  return store;
};
