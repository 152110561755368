import { Space } from 'antd';
import { ReactComponent as HelpIcon } from 'assets/v2/help-circle-contained-filled.svg';
import { type PropsWithChildren, type ReactElement, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IconShell } from '../icon-shell';
import { Tooltip as AntdTooltip } from '../tooltip';

const Wrapper = styled(Space)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled(Space)`
  .ant-space-item {
    display: flex;
  }
`;

const OptionalText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.colors.gray400};
  line-height: ${({ theme }) => theme.lineHeight.xxs};
`;

interface Props {
  helpText?: ReactNode;
  optional?: boolean;
}

const Tooltip = styled(AntdTooltip)`
  display: flex;
`;

export const FormLabel = ({
  helpText,
  optional,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <Wrapper>
      <Label size={4}>
        {children}
        {helpText && (
          <Tooltip spacing="comfortable" title={helpText}>
            <IconShell color="iconDefault" icon={HelpIcon} />
          </Tooltip>
        )}
      </Label>
      {optional && (
        <OptionalText>
          <FormattedMessage id="optional" />
        </OptionalText>
      )}
    </Wrapper>
  );
};
