import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query';
import {
  DEMO_MODEL_TEMPLATE,
  PREVIEW_MODEL_TEMPLATE,
  VersionAccessModes,
} from 'components/modules/modelling/model/version-view-banner/constants';
import { useVersionMode } from 'components/modules/modelling/model/version-view-banner/hooks/use-version-mode';
import { notification } from 'components/ui/atomic-components';
import { type ModelVersion } from 'data/modelling/model/versions';
import { ModelVersionStoreKeys } from 'data/modelling/model/versions/store/constants';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { TemplatesApi } from '../api';
import { type ModelTemplate } from '../types';

export const useTemplateMutations = (): {
  createTemplateMutation: UseMutationResult<
    void,
    unknown,
    {
      template: ModelTemplate;
      viewContext: 'preview' | 'demo';
    },
    unknown
  >;
  applyTemplateMutation: UseMutationResult<void, unknown, ModelTemplate, unknown>;
} => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const intl = useIntl();

  const { setVersionMode } = useVersionMode();

  const createTemplateMutation = useMutation(
    ({ template }: { template: ModelTemplate; viewContext: 'preview' | 'demo' }) =>
      TemplatesApi.create(template.name, template),
    {
      onSuccess: async (_, { viewContext, template }) => {
        await queryClient.invalidateQueries(ModelVersionStoreKeys.QueryKey);

        const versions = (await queryClient.getQueryData(
          ModelVersionStoreKeys.findVersionsWithArchived(),
        )) as Array<ModelVersion>;

        const version = versions?.find((v) => v.name === template.name);

        if (version) {
          if (viewContext === 'preview') {
            Cookies.set(PREVIEW_MODEL_TEMPLATE, JSON.stringify(template));
          } else if (viewContext === 'demo') {
            Cookies.set(DEMO_MODEL_TEMPLATE, template.name);
          }

          setVersionMode(version.id, VersionAccessModes.View);
        }
      },
      onError: defaultApiErrorHandler,
    },
  );

  const applyTemplateMutation = useMutation(
    (template: ModelTemplate) => TemplatesApi.apply(template.name, template),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(ModelVersionStoreKeys.QueryKey);
        await queryClient.invalidateQueries(['model-templates']);

        navigate('/models');

        notification.success({
          message: intl.formatMessage({ id: 'model.templates.use_template.apply_success' }),
        });
      },
      onError: defaultApiErrorHandler,
    },
  );

  return {
    createTemplateMutation,
    applyTemplateMutation,
  };
};
