import { type SizeColumnsToFitGridColumnLimits } from 'ag-grid-community';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';
import { DataColumnDefaultWidth } from './constants';

export const getGridColumnLimits = (
  store: StoreApi<ListsStoreApi>,
): SizeColumnsToFitGridColumnLimits[] => {
  const { columnWidthMap, config } = store.getState();

  const columns = config.columnOrder;

  return columns.map((colName) => {
    return { colId: colName, minWidth: columnWidthMap?.[colName] || DataColumnDefaultWidth };
  });
};
