import { type DateTimeFormatsType } from 'data';

const commonDateFormats = {
  HumanReadableMonthYear: 'MMM YY',
  HumanReadableMonthDate: 'MMM D',
  HumanReadableTime: 'hh:mm:ss A',
  HumanReadableShortTime: 'h:mm A',
  APIDate: 'YYYY-MM-DD',
  APIYearMonth: 'YYYY-MM',
};

const DateTimeFormatsUS: DateTimeFormatsType = {
  ...commonDateFormats,
  HumanReadableDate: 'MMM D, YY',
  HumanReadableDateTime: 'MMM DD, YY, hh:mm a',
  HumanReadableFullYearDate: 'MMM-DD-YYYY',
  HumanReadableFullYearDate2: 'MMM D, YYYY',
  DateInputFormat: 'MM/DD/YYYY',
};

const DateTimeFormatsRest: DateTimeFormatsType = {
  ...commonDateFormats,
  HumanReadableDate: 'D MMM, YY',
  HumanReadableDateTime: 'DD MMM, YY, hh:mm a',
  HumanReadableFullYearDate: 'DD-MMM-YYYY',
  HumanReadableFullYearDate2: 'D MMM YYYY',
  DateInputFormat: 'DD/MM/YYYY',
};

const userLocale = navigator.language;

export const DateTimeFormats = ['en-US'].includes(userLocale)
  ? DateTimeFormatsUS
  : DateTimeFormatsRest;

export const DRAWER_CLOSE_DURATION = 300;
export const KEYBOARD_DEBOUNCE_INTERVAL = 500;

export const NBSP = '\u00A0';
