import styled from 'styled-components';
import { FontXs } from 'styles/typography';

export const styles = {
  Wrapper: styled.div`
    min-width: 264px;
    max-width: 400px;
    max-height: 400px;
    overflow-y: auto;
  `,
  Header: styled.div`
    ${FontXs}

    height: 35px;
    background-color: ${({ theme }) => theme.colors.buttonOutlineNegativeHover};
    color: ${({ theme }) => theme.colors.textNegative};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[16]};
  `,
  ListWrapper: styled.ul`
    margin-top: ${({ theme }) => theme.spacing[12]};
    gap: ${({ theme }) => theme.spacing[8]};
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    margin-right: ${({ theme }) => theme.spacing[12]};
  `,
};
