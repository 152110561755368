import { AlertOld, Button, SelectOld } from 'components/ui/atomic-components';
import styled from 'styled-components';

export const ConnectedStyles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  Text: styled.span`
    color: ${({ theme }) => theme.colors.textSecondary};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: ${({ theme }) => theme.fontSize.caption};
  `,

  ConnectedWrapper: styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing[16]};
    width: 480px;

    > * {
      width: 100%;
    }
  `,

  StyledSelect: styled(SelectOld)`
    & .react-select__control {
      padding: 0;
      padding-right: ${({ theme }) => theme.spacing[8]};
    }

    & .react-select__single-value {
      display: flex;
      align-items: center;
    }
  `,

  ImageContainer: styled.img`
    width: 40px;
    height: auto;
    padding-right: 8px;
    object-fit: contain;
  `,

  NSubmitButton: styled(Button)`
    margin-top: ${({ theme }) => theme.spacing[12]};
    justify-content: center;
  `,

  WarningAlert: styled(AlertOld)`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  `,
};
