import dayjs from 'dayjs';

export const SAMPLE_DATE = '1996-04-09';

export const formatTransformer = (format: string): string => {
  return format.replaceAll('d', 'D').replaceAll('y', 'Y').replaceAll('m', 'M');
};

export const formattedDate = (format: string, dateStr?: string): string => {
  return dayjs(dateStr).format(formatTransformer(format));
};
