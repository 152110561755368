import { css } from 'styled-components';

export const LinkStyles = css`
  a {
    color: ${({ theme }) => theme.colors.blue60};

    &:hover {
      color: ${({ theme }) => theme.colors.blue70};
    }

    &:active {
      color: ${({ theme }) => theme.colors.blue60};
    }

    &[disabled] {
      color: ${({ theme }) => theme.colors.textTertiary};
    }
  }
`;
