import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { ReactComponent as BreadcrumbRightIcon } from 'assets/ic_breadcrumb_right.svg';
import styled from 'styled-components';
import { Caption } from 'styles/typography';
import { IconShell } from '../icon-shell';

export const Breadcrumb = styled(AntdBreadcrumb).attrs({
  separator: <IconShell icon={BreadcrumbRightIcon} />,
})`
  ${Caption};

  ol > li,
  .ant-breadcrumb-link,
  .ant-breadcrumb-link a,
  .ant-breadcrumb-separator {
    display: flex;
    align-items: center;
  }

  .ant-breadcrumb-link a {
    color: ${({ theme: { colors } }) => colors.textBody2};

    svg {
      color: ${({ theme: { colors } }) => colors.iconDefault};
      transition: color 0.3s;
    }

    &:hover {
      &,
      svg {
        color: ${({ theme: { colors } }) => colors.iconPrimary};
      }
    }
  }

  .ant-breadcrumb-separator {
    color: ${({ theme: { colors } }) => colors.iconDefault};
  }

  ol > li:last-child {
    color: ${({ theme: { colors } }) => colors.textTitle1};
    pointer-events: none;
  }
`;
