import { type Granularity, type NewPeriod, type Period } from 'data';
import { type VisualQueryFilterRule } from 'data/big-query';
import { type Dimension } from 'data/dimension';
import { type DataFormatType, type Metric } from 'data/metrics';
import { type MetricDomainName } from 'data/metrics/constants';
import { type PageTemplate } from 'data/page-template';
import { type AccessAction } from 'data/roles/permissions';
import { type DataFormattingType } from 'utils/data-formatter/types';
import { type ModuleAttributes, type ModuleDimensionProperties } from '../modules';
import { type FORMULA_LOOKUP_DIMENSION_PREFIX } from './constants';

export interface SmppDataResponse {
  applicableDimensionNames: string[];
  dimensions: Dimension[];
  formulae: TypeFormula[];
  metric: ModuleMetric;
  metrics: Metric[];
  planPeriods: NewPeriod[];
  prevPeriods: NewPeriod[];
  futurePeriods: NewPeriod[];
  template: PageTemplate;
  actualStartDate?: string;
  actualEndDate?: string;
  moduleAttributes: ModuleAttributes;
  dimensionProperties?: ModuleDimensionProperties;
}

export enum ModuleMetricType {
  Input = 'INPUT',
  Calculations = 'CALCULATIONS',
}

export interface ModuleMetric extends Omit<SmppDataResponse, 'metrics'> {
  name: string;
  displayName: string;
  modelName: string;
  moduleName: string;
  moduleDisplayName: string;
  description: string | null;
  type: DataFormattingType;
  dimensionSummary: DimensionAggregationTypes;
  timeSummary: TimeAggregationTypes;
  metricType: ModuleMetricType;
  dataFormat?: DataFormatType;
  dual?: string | null;
  isBlank?: boolean;
  applicableDimensions?: string[];
  filters?: VisualQueryFilterRule[];
  summaryFormula?: string;
  timeSummaryGranularFormulas?: Record<Granularity, string>;
  isTracked?: boolean;
  errors?: MetricError[];
  leafEditable?: boolean;
  domain?: MetricDomainName;
  baseGranularity?: Granularity.Monthly | Granularity.Weekly;
}

interface MetricError {
  code: string;
  msg: string;
  resource: string;
  resourceType: number;
}

export type PlanMetric = Pick<
  ModuleMetric,
  | 'dimensionSummary'
  | 'displayName'
  | 'moduleDisplayName'
  | 'moduleName'
  | 'name'
  | 'timeSummary'
  | 'type'
  | 'domain'
  | 'baseGranularity'
> & { accessAction?: AccessAction };
export type FormulaId = number | string;

export interface TypeFormula {
  dimensions: Dimension[];
  periods: Period[];
  formula: string;
  id?: FormulaId;
  type?: ForecastMethods;
  config?: TemplateForecastConfig;
  scenario?: string;

  // Frontend-only params to handle undo/redo and in-place edits
  uid?: string;
  oldIds?: Set<FormulaId>;
}

export type TemplateForecastConfig =
  | LinearRegressionForecastConfig
  | MovingAverageForecastConfig
  | AverageForecastConfig
  | FixedValueForecastConfig
  | DriverBasedForecastConfig
  | DataDrivenForecastConfig;

export interface LinearRegressionForecastConfig {
  lastNPeriods: number;
}

export interface MovingAverageForecastConfig {
  lastNPeriods: number;
}

export interface AverageForecastConfig {
  startDate: string;
  endDate: string;
}

export interface FixedValueForecastConfig {
  value: string;
  granularity: Granularity;
}

export interface DriverBasedForecastConfig {
  value: string;
  separator: 'OF' | 'PER';
  metricName: string;
  dimensions: Record<string, string[]>;
}

export interface DataDrivenForecastConfig {
  datasetDisplayName: string;
  instanceName: string;
}

export type FormulaLookupNode = {
  // dimension names
  [key: string]: {
    // dimension values
    [key: string]: {
      formula?: TypeFormula;
      inheritedByDefault?: boolean;
      singleColumn?: boolean;
      [FORMULA_LOOKUP_DIMENSION_PREFIX]?: FormulaLookupNode;
    };
  };
};

export type FormulaLookupMap = {
  [FORMULA_LOOKUP_DIMENSION_PREFIX]: FormulaLookupNode;
};

export enum ForecastMethods {
  Formula = 'FORMULA',
  Manual = 'MANUAL',
  FixedValue = 'FIXED_VALUE',
  LinearRegression = 'LINEAR_REGRESSION',
  Average = 'AVERAGE',
  MovingAverage = 'MOVING_AVERAGE',
  DataDriven = 'DATA_DRIVEN',
  DriverBased = 'DRIVER_BASED',
}

// TODO:: Add BaseAggregation types for metric and extend in both agg types.
export enum DimensionAggregationTypes {
  Sum = 'SUM',
  Formula = 'FORMULA',
  Average = 'AVG',
  None = 'NONE',
  WeightedAvg = 'WEIGHTED_AVG',
  Unique = 'UNIQUE',
}

export enum TimeAggregationTypes {
  Sum = 'SUM',
  Average = 'AVG',
  BeginningOfPeriod = 'BEGINNING_OF_PERIOD',
  EndOfPeriod = 'END_OF_PERIOD',
  Formula = 'FORMULA',
  None = 'NONE',
  Constant = 'CONSTANT',
  WeightedAvg = 'WEIGHTED_AVG',
  Unique = 'UNIQUE',
  CountUnique = 'COUNT_UNIQUE',
}

export enum FormulaComputationStates {
  Computing = 'COMPUTING',
  Error = 'Error',
}
