import { type QueryClient, type QueryKey } from '@tanstack/react-query';
import { type ID } from 'data';

export const updateItemInItemsCache = <T extends { id: ID }>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updatedItem: Partial<T>,
): void => {
  const items = queryClient.getQueryData<T[]>(queryKey);

  queryClient.setQueryData(
    queryKey,
    items?.map((item) => {
      return item.id === updatedItem.id ? { ...item, ...updatedItem } : item;
    }),
  );
};
