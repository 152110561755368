import { type IRowNode, type GridApi } from 'ag-grid-community';
import { type ID } from 'data';

export const bulkSelectRows = ({
  gridApi,
  lastSelectedRow,
  currentSelectedRow,
  addRowToSelectedRow,
}: {
  gridApi?: GridApi;
  lastSelectedRow?: ID;
  currentSelectedRow: ID;
  addRowToSelectedRow: (rowIds: ID[]) => void;
}): void => {
  const gridRowNodes: IRowNode[] = [];
  const selectedRowIds: ID[] = [];

  let lastSelectedRowIndex = 0;
  let currentSelectedRowIndex = 0;

  gridApi?.forEachNode((node, idx) => {
    gridRowNodes.push(node);
    if (node.data.id === lastSelectedRow) {
      lastSelectedRowIndex = idx;
    }

    if (node.data.id === currentSelectedRow) {
      currentSelectedRowIndex = idx;
    }
  });

  const difference = lastSelectedRowIndex - currentSelectedRowIndex;
  const startIndex = difference >= 0 ? currentSelectedRowIndex + 1 : lastSelectedRowIndex + 1;
  const endIndex = startIndex + Math.abs(difference);

  for (let i = startIndex; i < endIndex; i += 1) {
    gridRowNodes[i].setSelected(true);
    selectedRowIds.push(gridRowNodes[i].data.id);
  }

  addRowToSelectedRow(selectedRowIds);
};
