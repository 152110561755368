import { ListsApi } from 'data/modelling/lists';
import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';
import { sortGridRows } from '../../cell-components/column-header/overflow-menu/utils';
import { type UndoSortChangeMeta } from '../types';

interface Props {
  meta: UndoSortChangeMeta;
  listStore: StoreApi<ListsStoreApi>;
  type: 'undo' | 'redo';
}

export const undoColumnSortChange = ({ meta, listStore, type }: Props): void => {
  const {
    gridApi,
    activeListId,
    config,
    startUndoOperation,
    startRedoOperation,
    sortRows,
    pushToUndoRedoStack,
  } = listStore.getState();

  const { previousSort, currentSort } = meta;

  if (type === 'undo') {
    startUndoOperation();
  } else {
    startRedoOperation();
  }

  sortGridRows({
    gridApi,
    currentSort: { ...previousSort },
    previousSort: { ...currentSort },
    sortRows,
    pushToUndoRedoStack,
  });

  ListsApi.editList(activeListId as number, {
    config: { ...config, sortedColumn: previousSort?.column, sortOrder: previousSort?.order },
  });
};
