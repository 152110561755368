import { type Layout } from '@drivetrainai/optimus-layout';
import { type CellStyle } from 'ag-grid-community';
import { type Granularity, type ID } from 'data';
import { type TableFormattingRuleStyle } from 'data/boards/charts/types/table';
import { type CellFormatRules, type ColGroup } from './grid';
import { type Pivot } from './pivots/types';

type PageTemplateStyle = CellStyle;

export type PageTemplateGranularityRollupCustomisations = {
  type: Granularity;
  defaultSelected: boolean;
};

/**
 * PageTemplateRelativeTableHeaderCustomisations is a relative table header column customisation configuration
 * which deals with time along the y axis (columns).
 */
export type PageTemplateRelativeTableHeaderCustomisations = {
  boards?: BoardPeriodBasedLabelConf;
  overriddenCurrentDate?: string;
  past: string;
  current: string;
  future: string;
};
export interface PageTemplateCustomizations {
  page: {
    format?: CellFormatRules;
    cols?: PageTemplateRelativeTableHeaderCustomisations;
    granularity?: PageTemplateGranularityRollupCustomisations[];
    rollup?: PageTemplateGranularityRollupCustomisations[];
    rowHeight?: number;
    gridCommonStyles?: PageTemplateStyle;
    defaultExpandedCols?: string[];
    textRenameOverrides: TextRenameOverrides;
    hideHeadlineValues?: boolean;
    hideColumnAggregates?: boolean;
    hideColumnHeaders?: boolean;
  };
  rows: {
    [name: string]: PageTemplateStyle;
  };
  columns: {
    [name: string]: PageTemplateStyle;
  };
  sections?: {
    [name: string]: { style: TableFormattingRuleStyle };
  };
  cells: {
    [name: string]: PageTemplateStyle;
  };
  gridLayout: Layout | null;
  hideTimeSelector?: boolean;
}

export interface PageTemplateResponse {
  id: number;
  status: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  name: string;
  type: 'PAGE' | 'BASE';
  attributes: string;
}

export enum TemplateElementType {
  Blank = 'BLANK',
  Metric = 'METRIC',
  Section = 'SECTION',
  SectionMetric = 'SECTION_METRIC',
}

export interface PageTemplateElement {
  name: string;
  displayName?: string; // displayName only used for sections for now
  type: TemplateElementType;
  elements?: PageTemplateElement[];
  nestingDepth?: number | null;
}

export interface PageTemplate {
  id: number;
  name: string;
  extends: 'grid-bordered' | 'grid-borderless';
  style: PageTemplateCustomizations;
  body: {
    elements: PageTemplateElement[];
  };
  pivots: Pivot[];
}

export type PageTemplateURLParams = {
  templateId: ID;
  template: Partial<PageTemplate>;
};

export type TextRenameOverrides = {
  [key in ColGroup]?: string;
};

export interface BoardPeriodBasedLabelConf {
  past: ColGroup[];
  current: ColGroup[];
  future: ColGroup[];
}
