import { InlineEditInput } from 'components/ui/atomic-components/inline-edit-input';
import { DESC_CHAR_LIMIT } from 'data/modelling/constants';
import { useDimensionGroup } from 'data/modelling/dimension-group/hooks/use-dimension-group';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { useState, type ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

interface Props {
  isReadOnly?: boolean;
}

export const DerivedListDescription = ({ isReadOnly }: Props): ReactElement => {
  const intl = useIntl();

  const { listId } = useParams() as { listId: string };
  const id = Number(listId.replace(DIM_GROUP_ID_PREFIX, ''));

  const { updateDimensionGroup } = useDimensionGroupMutations();

  const { data: derivedList } = useDimensionGroup(id);

  const [description, setDescription] = useState(derivedList?.description || '');

  const saveDescription = () => {
    if (derivedList?.description === description) {
      return;
    }
    updateDimensionGroup.mutate({ id, description: (description || '').trim() });
  };

  useEffect(() => {
    setDescription(derivedList?.description || '');
  }, [derivedList?.description]);

  return (
    <InlineEditInput
      $inputType={'ghostText'}
      editable={!isReadOnly}
      maxLength={DESC_CHAR_LIMIT}
      placeholder={intl.formatMessage({
        id: 'description_placeholder_with_ellipsis',
      })}
      size="extraSmall"
      value={description || ''}
      onBlur={() => saveDescription()}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
};
