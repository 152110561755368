import { ReactComponent as WarningIcon } from 'assets/ic_alert-warning.svg';
import { IconText, Modal } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM } from 'styles/typography';
import { useVersionMode } from './hooks/use-version-mode';

const DISCARD_BTN_ATTRIBUTE_NAME = 'data-discard-change';

const MessageWrapper = styled.div`
  ${FontM}

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.colors.textBody1};
`;

interface Props {
  versionName?: string;
  isPreSavePromptOpen: boolean;
  setIsPreSavePromptOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PreSavePromptModal = ({
  versionName,
  isPreSavePromptOpen,
  setIsPreSavePromptOpen,
}: Props): ReactElement => {
  const { exitCurrentMode } = useVersionMode();

  return (
    <Modal
      cancelButtonProps={{ [DISCARD_BTN_ATTRIBUTE_NAME]: 'true' }}
      cancelText={<FormattedMessage id="model.versions.view.discard_and_exit" />}
      confirmLoading={false}
      destroyOnClose
      okText={<FormattedMessage id="model.versions.view.save_and_exit" />}
      open={isPreSavePromptOpen}
      title={
        <IconText
          icon={WarningIcon}
          text={<FormattedMessage id="model.versions.view.presave_prompt_title" />}
        />
      }
      onCancel={(e: React.MouseEvent<HTMLButtonElement>) => {
        const targetElement = e.target as HTMLElement;
        // Check if the target element has a specific attribute
        const hasAttribute = targetElement.hasAttribute(DISCARD_BTN_ATTRIBUTE_NAME);

        if (hasAttribute) {
          exitCurrentMode();
        } else {
          setIsPreSavePromptOpen(false);
        }
      }}
      onOk={() => exitCurrentMode(false)}
    >
      <MessageWrapper>
        <div>
          <FormattedMessage
            id="model.versions.view.presave_prompt_message_part1"
            values={{ versionName }}
          />
        </div>
        <div>
          <FormattedMessage id="model.versions.view.presave_prompt_message_part2" />
        </div>
      </MessageWrapper>
    </Modal>
  );
};
