import { IconShell } from 'components/ui/atomic-components';
import { MetricTypeIconMapping } from 'components/ui/metric/icon-generator/metric-type-icon-mapping';
import { type ResourceUsageVariable } from 'data/resource-usage/types';
import { type ReactElement } from 'react';
import { UsagePillWrapper } from './usage-pill-wrapper';

interface Props {
  data: ResourceUsageVariable;
}

export const VariablePill = ({ data }: Props): ReactElement => {
  return (
    <UsagePillWrapper
      icon={<IconShell color="gray600" icon={MetricTypeIconMapping[data.type]} />}
      navigateTo={`/models/m/${data.moduleName}`}
    >
      {data.displayName}
    </UsagePillWrapper>
  );
};
