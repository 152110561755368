import { LRLanguage, LanguageSupport } from '@codemirror/language';
import { parser } from '@drivetrainai/optimus-formula-parser';
import { styleTags } from '@drivetrainai/optimus-formula-parser/dist/highlight';

const dtml = LRLanguage.define({
  parser: parser.configure({
    props: [styleTags],
  }),
});

export const dtmlLanguage = new LanguageSupport(dtml);
