import { useQueryClient, useMutation, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { updateVersion } from '../utils';

export const useUpdateVersionMutation = (): {
  updateModelVersion: UseMutationResult<
    ModelVersion,
    AxiosError<unknown>,
    AtLeast<ModelVersion, 'id'>,
    unknown
  >;
} => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const updateModelVersion = useMutation(
    (version: AtLeast<ModelVersion, 'id'>) => {
      return ModelVersionApi.update(version);
    },
    {
      onSuccess: (data: ModelVersion) => {
        notification.success({
          message: intl.formatMessage({
            id: 'model.versions.update.success.title',
          }),
        });

        updateVersion(queryClient, data);
      },
      onError: defaultApiErrorHandler,
    },
  );

  return { updateModelVersion };
};
