import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';

const PrimaryWrapper = styled.div`
  max-width: 80%;
  width: 100%;
  padding-right: ${({ theme: { spacing } }) => spacing[16]};
`;

export const Primary = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}): ReactElement => <PrimaryWrapper className={className}>{children}</PrimaryWrapper>;
