import { type DataFormattingType } from 'utils/data-formatter/types';
import { DisplayUnitType, type FormatterProps } from '../types';
import { otherFormattersForMetricType } from '../utils/other-formatter-map';

export const displayUnitFormattersUS: {
  [key in DisplayUnitType.K | DisplayUnitType.M]: {
    [key in DataFormattingType | 'default']: (props: FormatterProps) => string;
  };
} = {
  [DisplayUnitType.K]: otherFormattersForMetricType,
  [DisplayUnitType.M]: otherFormattersForMetricType,
};
