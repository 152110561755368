import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { IntegrationsApi } from '../api';
import { IntegrationKeys } from '../constants';
import { type IntegrationDataSetListItem } from '../types';

export const useGetTableMeta = (
  tableName: string,
  enabled: boolean,
): UseQueryResult<IntegrationDataSetListItem, unknown> => {
  return useQuery(
    IntegrationKeys.TableMeta(tableName),
    () => IntegrationsApi.getTableMeta(tableName),
    {
      enabled,
    },
  );
};
