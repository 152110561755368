import { IconShell, SelectOption } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { type GroupBase, type OptionProps } from 'react-select';
import styled from 'styled-components';
import { FontS, SingleLineEllipsis } from 'styles/typography';
import { type CurrencySelectOption } from '../types';

const SelectOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 24px;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const Label = styled.div`
  ${FontS};
  ${SingleLineEllipsis}

  color: ${({ theme }) => theme.colors.gray800};
`;

export const Option = ({
  data,
  label,
  ...rest
}: OptionProps<CurrencySelectOption, false, GroupBase<CurrencySelectOption>>): ReactElement => {
  return (
    <SelectOption data={data} label={label} {...rest}>
      <SelectOptionWrapper>
        <IconShell color="iconDefault" icon={data.icon} size="xs" />
        <Label>{data.label}</Label>
      </SelectOptionWrapper>
    </SelectOption>
  );
};
