import { type ButtonSize } from 'antd/es/button';
import { FormOld as FormAtom } from 'components/ui/atomic-components/form-old';
import styled, { css } from 'styled-components';
import { switchProp, prop } from 'styled-tools';
import { InlineEditStyles } from '../styles';

export const InlineEditInputStyles = {
  ...InlineEditStyles,
  Form: styled(FormAtom)<{ width?: number }>`
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : 'auto')};

    .ant-form-item-control-input {
      min-height: ${({ theme }) => theme.height.sm}px;
    }

    .ant-form-item-explain-error {
      width: 75%;
      padding-right: ${({ theme }) => theme.spacing[8]};
    }
  `,
  FormItem: styled(FormAtom.Item)`
    margin: 0;
  `,
  Action: styled.div<{ size?: ButtonSize; $customTop?: number }>`
    background: ${({ theme }) => theme.colors.white};
    border: solid 1px ${({ theme }) => theme.border.color};
    border-radius: ${({ theme }) => theme.border.radius.md};
    margin-top: ${({ theme }) => theme.spacing[4]};
    position: absolute;
    right: 0;
    z-index: ${({ theme }) => theme.zIndex[10]};
    ${switchProp(prop('size', 'middle'), {
      small: css`
        top: ${({ theme }) => theme.height.sm}px;
      `,
      middle: css`
        top: ${({ theme }) => theme.height.md}px;
      `,
      large: css`
        top: ${({ theme }) => theme.height.lg}px;
      `,
    })}

    ${(props) =>
      props.$customTop &&
      css`
        top: ${() => `${props.$customTop}px`};
      `};

    .ant-btn {
      margin-left: 0;

      &:first-child {
        border-radius: 0;
        border-right-color: ${({ theme }) => theme.border.color};

        &:focus {
          border-right-color: ${({ theme }) => theme.colors.blue60};
        }
      }
    }
  `,
};
