import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontL } from 'styles/typography';
import { Space } from '../atomic-components';
import { Drawer } from '../atomic-components/drawer';
import { useFormulaTraceStore } from './store';
import { TraceContent } from './trace-content';

const StyledHeader = styled(Space)`
  ${FontL}

  color: ${({ theme }) => theme.colors.textTitle1};
  padding-left: ${({ theme }) => theme.spacing[8]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const FormulaTrace = (): ReactElement => {
  const traceNode = useFormulaTraceStore((state) => state.traceNode);
  const clear = useFormulaTraceStore((state) => state.clear);

  return (
    <Drawer
      destroyOnClose
      mask={false}
      open={!!traceNode}
      placement={'right'}
      title={
        <StyledHeader>
          <FormattedMessage id="formula_trace.drawer.title" />
        </StyledHeader>
      }
      width={640}
      onClose={() => clear()}
    >
      {!!traceNode && <TraceContent />}
    </Drawer>
  );
};
