import { type DateFilterPreset, DateFilterPresetName } from 'data/bootstrap/types';
import { TimeAggregationTypes } from 'data/modelling/metric/types';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { findSelectedPresetDateOption } from 'utils/date';

const GeneralDateRangeTextMapping = {
  [DateFilterPresetName.CurrentMonth]: (
    <FormattedMessage id="board.headline.general.current_month" />
  ),
  [DateFilterPresetName.LastMonth]: <FormattedMessage id="board.headline.general.last_month" />,
  [DateFilterPresetName.CurrentQuarter]: (
    <FormattedMessage id="board.headline.general.current_quarter" />
  ),
  [DateFilterPresetName.PreviousQuarter]: (
    <FormattedMessage id="board.headline.general.previous_quarter" />
  ),
  [DateFilterPresetName.CurrentYear]: <FormattedMessage id="board.headline.general.current_year" />,
  [DateFilterPresetName.CustomRange]: <FormattedMessage id="board.headline.general.custom_range" />,
  [DateFilterPresetName.YearTillDate]: '',
  [DateFilterPresetName.LastTwelveMonths]: '',
};

const EopDateRangeTextMapping = {
  [DateFilterPresetName.CurrentMonth]: <FormattedMessage id="board.headline.eop.current_month" />,
  [DateFilterPresetName.LastMonth]: <FormattedMessage id="board.headline.eop.last_month" />,
  [DateFilterPresetName.CurrentQuarter]: (
    <FormattedMessage id="board.headline.eop.current_quarter" />
  ),
  [DateFilterPresetName.PreviousQuarter]: (
    <FormattedMessage id="board.headline.eop.previous_quarter" />
  ),
  [DateFilterPresetName.CurrentYear]: <FormattedMessage id="board.headline.eop.current_year" />,
  [DateFilterPresetName.CustomRange]: <FormattedMessage id="board.headline.eop.custom" />,
  [DateFilterPresetName.YearTillDate]: '',
  [DateFilterPresetName.LastTwelveMonths]: '',
};

export const constructHeadlineTextForMetric = ({
  timeAggregation,
  datePresetOptions,
  dateOption,
}: {
  timeAggregation?: TimeAggregationTypes;
  datePresetOptions: DateFilterPreset[];
  dateOption?: DateFilterPresetName;
}): ReactNode => {
  const selectedPresetDateOption = findSelectedPresetDateOption([], datePresetOptions, dateOption);

  if (timeAggregation === TimeAggregationTypes.EndOfPeriod) {
    if (selectedPresetDateOption) {
      return EopDateRangeTextMapping[selectedPresetDateOption.name];
    }

    return <FormattedMessage id="board.headline.eop.custom" />;
  }

  if (selectedPresetDateOption) {
    return GeneralDateRangeTextMapping[selectedPresetDateOption.name];
  }

  return '';
};
