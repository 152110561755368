import { type ColDef, type IRowNode } from 'ag-grid-community';
import { FORMULA_COLUMN_ID } from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { type ID } from 'data';
import { TimeDimensionName } from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { BASE_CASE_NAME, SCENARIO } from 'data/scenarios/constants';
import { ACTUAL, VERSION } from 'data/versions';
import { sortBy } from 'lodash';
import { ContainerType, type Conversation } from './types';

const serializeCommentReference = ({
  version,
  scenario,
  columnType,
  metricName,
  baselineVersion,
  dimensions,
  chartId,
}: {
  version: string;
  scenario: string;
  columnType?: string;
  metricName: string;
  baselineVersion?: string;
  chartId?: ID;
  dimensions: Record<string, string>;
}) => {
  const combinedMap = { ...dimensions };

  combinedMap[PIVOT_DIMENSION_METRIC_ROW_NAME] = metricName;
  combinedMap[VERSION] = version || ACTUAL;
  combinedMap[SCENARIO] = scenario;
  combinedMap.columnType = columnType || VERSION;
  combinedMap.baselineVersion = baselineVersion || ACTUAL;
  combinedMap.chartId = String(chartId || 0);

  return sortBy(Object.keys(combinedMap).map((key) => `${key}:${combinedMap[key]}`)).join('~');
};

export const isChartLevelComment = (conv: Conversation): boolean => {
  const { container, meta } = conv;

  return (
    container === ContainerType.Boards &&
    Object.keys(meta.reference).length === 1 &&
    !!meta.reference.chartId
  );
};

export const isFormulaComment = (conversationItem: Conversation): boolean => {
  return conversationItem.meta.reference.dimensions?.[TimeDimensionName] === FORMULA_COLUMN_ID;
};

// Generate converstion cell identifier from API data.
export const getConversationIdentifier = (conv: Conversation): string => {
  const { meta } = conv;

  if (isChartLevelComment(conv)) {
    return getChartIdentifierFromChartId(Number(meta.reference.chartId));
  }

  const {
    [VERSION]: version = meta?.reference?.version || ACTUAL,
    [SCENARIO]: scenario = meta?.reference?.scenario || BASE_CASE_NAME,
    [PIVOT_DIMENSION_METRIC_ROW_NAME]: metricName = conv.metricName,
    ...restDimensions
  } = meta?.reference?.dimensions || {};

  const columnType = meta?.reference?.columnType;
  const baselineVersion = meta?.reference?.baselineVersion;
  const chartId = meta?.reference.chartId;

  return serializeCommentReference({
    version: version as string,
    scenario: scenario as string,
    columnType,
    metricName: metricName as string,
    baselineVersion,
    dimensions: restDimensions as Record<string, string>,
    chartId,
  });
};

// Generate converstion cell identifier from grid data.
export const getCellIdenitifierFromGridData = (colDef: ColDef, node: IRowNode): string => {
  if (!node) {
    return '';
  }
  const { data } = node;

  const columnType = colDef?.headerComponentParams?.columnType;
  const baselineVersion = colDef?.headerComponentParams?.baselineVersion;
  const chartId = colDef?.headerComponentParams?.chartId;

  const allDimensions = {
    ...data.rowDimensions,
    ...colDef?.headerComponentParams?.colDims,
    ...colDef?.headerComponentParams?.pageFilters,
  };

  // If scenario is null or Base_Case, scenario will be assumed to be Base_Case.
  // If version is null, version will be assumed to be firstVersion (which is passed as the first filtered version).
  const {
    [VERSION]: version = colDef?.headerComponentParams?.firstVersion,
    [SCENARIO]: scenario = BASE_CASE_NAME,
    [PIVOT_DIMENSION_METRIC_ROW_NAME]: metricName = data?.metricInHierarchy,
    ...restDimensions
  } = allDimensions;

  // TODO(arnav): Need to unify comment identification logic and get rid of string comparision between getCellIdenitifierFromGridData() and getConversationIdentifier().

  return serializeCommentReference({
    version,
    scenario,
    columnType,
    metricName,
    baselineVersion,
    dimensions: restDimensions,
    chartId,
  });
};

export const getChartIdentifierFromChartId = (chartId: number): string => {
  return `chart~~${chartId}`;
};

export const deserializeConversationIdentifier = (identifier: string): Partial<Conversation> => {
  const result: Record<string, string> = {};

  identifier.split('~').forEach((entry) => {
    const [key, value] = entry.split(':');

    result[key] = value;
  });

  const {
    [PIVOT_DIMENSION_METRIC_ROW_NAME]: metricName,
    [VERSION]: version,
    [SCENARIO]: scenario,
    columnType,
    baselineVersion,
    chartId,
    ...dimensions
  } = result;

  return {
    metricName,
    meta: {
      reference: {
        version,
        scenario,
        baselineVersion,
        chartId: Number(chartId),
        columnType,
        dimensions,
      },
    },
  };
};
