import { Input } from 'components/ui/atomic-components';
import { type ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { type VisualRuleEditRenderCompParams } from './types';

export const StringInput: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  const intl = useIntl();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onChange([
      {
        value,
      },
    ]);
  };

  return (
    <Input
      placeholder={intl.formatMessage({ id: 'edit_actuals.visual.value_field_placeholder' })}
      value={data?.[0]?.value}
      onChange={onInputChange}
    />
  );
};
