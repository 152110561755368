import { QueryTableColumnDataType } from 'data/big-query';
import { type ReconciliationConfig } from 'data/modelling/lists';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { type FormProps } from '../types';

export const generateReconRequest = (formData: FormProps): Partial<ReconciliationConfig> => {
  return {
    sourceDatasetColumns: formData.dimensions.map((dim) => ({
      source: dim.value,
      type: (dim.columnType as QueryTableColumnDataType) || QueryTableColumnDataType.String,
      colName: dim.name || '',
      tableDisplayName: dim.tableDisplayName || '',
    })),
    uniqueColumns: formData.uniqueKeyColumns.map((c) => getColumnNameFromRawDimensionName(c.value)),
    sourceDatasetFilters: formData.filters,
  };
};
