import { type GridApi } from 'ag-grid-community';
import { type ListSortOrder } from 'data/modelling/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { type UndoStackItem } from '../../../../undo-redo/types';

export const sortGridRows = ({
  gridApi,
  currentSort,
  previousSort,
  sortRows,
  pushToUndoRedoStack,
}: {
  gridApi: GridApi | undefined;
  currentSort?: { column?: string; order?: ListSortOrder | null };
  previousSort?: { column?: string; order?: ListSortOrder | null };
  sortRows: (columnName: string, sort: ListSortOrder | null | undefined) => void;
  pushToUndoRedoStack: (item: UndoStackItem) => void;
}): void => {
  pushToUndoRedoStack({
    type: 'column-sort-change',
    meta: {
      currentSort,
      previousSort,
    },
  });

  if (currentSort?.column) {
    sortRows(currentSort.column, currentSort.order);
    queueMacroTask(() => gridApi?.refreshCells({ columns: ['row-index'] }));
  }
};
