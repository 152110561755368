import axios, { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components/notification';

export const defaultApiErrorHandler = (errorResp: AxiosError): void => {
  let message = 'Something unexpected happened. Please try again later.';

  if (axios.isAxiosError(errorResp)) {
    if ((errorResp?.response?.data as { message: string })?.message) {
      message = (errorResp?.response?.data as { message: string })?.message;
    } else if (errorResp?.response?.status === 404) {
      message = 'Resource not found';
    }
  }

  notification.error({
    message,
  });
};
