import { Button } from 'components/ui/atomic-components';
import { EntityStatus } from 'data';
import { type ListUpdate, ListUpdateType } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { AcceptButton } from './accept-button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
`;

const ButtonSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
`;

interface Props {
  listUpdate: ListUpdate;
  isRecommendation: boolean;
  onAccept: () => void;
  onIgnore: () => void;
  onClickLinkButton?: () => void;
}

export const RightSection = ({
  listUpdate,
  isRecommendation,
  onAccept,
  onIgnore,
  onClickLinkButton,
}: Props): ReactElement => {
  const { hideReject, matchCandidatesCriteria } = useListStoreContext(
    (s) => s.reconciliationConfig,
  );

  return (
    <Wrapper>
      <ButtonSection>
        {!hideReject && listUpdate.status === EntityStatus.Active && (
          <Button size="small" type="text" onClick={onIgnore}>
            <FormattedMessage id="discard" />
          </Button>
        )}
        <AcceptButton listUpdate={listUpdate} onAccept={onAccept} />
      </ButtonSection>
      {listUpdate.status === EntityStatus.Active &&
        listUpdate.cardType === ListUpdateType.Create &&
        !!matchCandidatesCriteria && (
          <Button size="small" type="link" onClick={onClickLinkButton}>
            {isRecommendation ? (
              <FormattedMessage id="lists.reconciliation.add_as_new.button_text" />
            ) : (
              <FormattedMessage id="lists.reconciliation.map_to_existing.title" />
            )}
          </Button>
        )}
    </Wrapper>
  );
};
