import { useDimensions } from 'data/modelling/dimension/hooks/use-dimensions';
import { type FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Redirection decider while landing on /dimensions page
 * If dimensions is already present in the /model API, the page redirects to /new
 * @returns EmptyComponent
 */
export const DimensionsRedirectPage: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const { data, isFetched } = useDimensions();

  useEffect(() => {
    if (data?.[0]) {
      navigate(`/dimensions/${data?.[0].id}`, { replace: true });
    } else if (isFetched) {
      navigate('/dimensions/new', { replace: true });
    }
  }, [navigate, isFetched, data]);

  return <></>;
};
