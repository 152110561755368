import { type RefObject, useEffect } from 'react';

export const useCloseOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  onCancel: () => void,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (ref.current && ref.current.contains(target)) {
        return;
      }

      // if clicked inside date picket panel
      if (target?.closest('.ant-picker-dropdown')) {
        return;
      }

      onCancel();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel, ref]);
};
