import { type ReactElement, type ReactNode } from 'react';
import { Panel, PanelGroup, type PanelOnResize } from 'react-resizable-panels';
import styled from 'styled-components';
import { Hide } from 'styles/styled-atoms';
import { NavResizeHandle } from './nav-resize-handle';

const PanelWithHideProp = styled(Panel)`
  ${Hide};
`;

const ResizableLeftPanel = ({
  children,
  $hide,
  onPanelResize,
  defaultWidth,
}: {
  children?: ReactNode;
  $hide?: boolean;
  onPanelResize?: PanelOnResize;
  defaultWidth?: number;
}) => {
  return (
    <PanelWithHideProp
      $hide={$hide}
      defaultSize={defaultWidth || 12}
      maxSize={40}
      minSize={10}
      onResize={onPanelResize}
    >
      {children}
    </PanelWithHideProp>
  );
};

export const LeftNavLayout = ({ children }: { children?: ReactNode }): ReactElement => {
  return <PanelGroup direction="horizontal">{children}</PanelGroup>;
};

LeftNavLayout.ResizableLeftPanel = ResizableLeftPanel;
LeftNavLayout.ResizableRightPanel = Panel;
LeftNavLayout.ResizeHandle = NavResizeHandle;
