import { Collapse as AntdCollapse } from 'antd';
import { ReactComponent as CollapsibleExpandIcon } from 'assets/v2/chevron-right.svg';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  height: ${({ theme }) => theme.spacing[20]};
`;

export const Collapse = styled(AntdCollapse).attrs((props) => ({
  expandIconPosition: props.expandIconPosition || 'end',
  expandIcon: ({ isActive }: { isActive?: boolean }): ReactNode => (
    <IconWrapper>
      <CollapsibleExpandIcon transform={isActive ? 'rotate(90)' : 'rotate(0)'} />
    </IconWrapper>
  ),
  ghost: true,
}))`
  &&& {
    .ant-collapse-arrow {
      color: ${({ theme }) => theme.colors.gray400};
      right: 0;
    }
  }

  .ant-collapse-item {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      border-bottom: solid 1px ${({ theme: { colors } }) => colors.outlineDefault};
      margin-bottom: ${({ theme: { spacing } }) => spacing[24]};
    }

    &:last-child .ant-collapse-content > .ant-collapse-content-box {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: ${({ theme: { spacing } }) => spacing[8]} 0;
  }
`;
