import { AuthorisedAccess } from 'components/modules/authorised-access';
import { AsyncSwitcher } from 'components/ui/async-switcher';
import { Spinner } from 'components/ui/atomic-components';
import { useGetAllConnections } from 'data/integrations/fivetran/hooks';
import { PermissionList } from 'data/roles/permissions';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { AllConnectorsPopup } from './collapsible';

const IntegrationsWrapper = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const AllConnectorsPage = ({
  isParentOpen,
  setOpen,
}: {
  isParentOpen: boolean;
  setOpen: (open: boolean) => void;
}): ReactElement => {
  const integrationsQuery = useGetAllConnections();

  return (
    <AuthorisedAccess permissionType={PermissionList.All}>
      <IntegrationsWrapper>
        <AsyncSwitcher {...integrationsQuery} components={{ Spinner }}>
          {(data) => (
            <AllConnectorsPopup data={data} isParentOpen={isParentOpen} setOpen={setOpen} />
          )}
        </AsyncSwitcher>
      </IntegrationsWrapper>
    </AuthorisedAccess>
  );
};
