import {
  type QueryClient,
  useMutation,
  type UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { type ID } from 'data';
import { BoardsApi } from 'data/boards/api';
import { FoldersApi } from 'data/folder/api';
import { type Folder, FolderType, type ItemMoveRequest } from 'data/folder/types';
import { ModuleApi } from 'data/modelling/modules';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';

const MoveRequestMap: { [key in FolderType]: (req: ItemMoveRequest) => Promise<void> } = {
  [FolderType.Module]: ModuleApi.move,
  [FolderType.Board]: BoardsApi.moveBoard,
  [FolderType.Folder]: FoldersApi.moveItem,
};

export const useFolderMutations = (): {
  createFolder: UseMutationResult<Folder, unknown, Folder, unknown>;
  deleteFolder: UseMutationResult<void, unknown, ID, unknown>;
  updateFolder: UseMutationResult<Folder, unknown, AtLeast<Folder, 'id'>, unknown>;
  moveItem: UseMutationResult<void, unknown, ItemMoveRequest, unknown>;
} => {
  const queryClient = useQueryClient();
  const { folderId } = useParams() as { folderId: string };

  const createFolder = useMutation(
    (folder: Folder) => FoldersApi.createFolder(omit(folder, 'id')),
    {
      onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
      onError: defaultApiErrorHandler,
    },
  );
  const updateFolder = useMutation(
    (folder: AtLeast<Folder, 'id'>) => FoldersApi.updateFolder(folder),
    {
      onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
    },
  );
  const moveItem = useMutation(
    (itemMoveRequest: ItemMoveRequest) => {
      return MoveRequestMap[itemMoveRequest.folderType]?.(itemMoveRequest);
    },
    {
      onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
      onError: defaultApiErrorHandler,
    },
  );
  const deleteFolder = useMutation((folderId: ID) => FoldersApi.delete(folderId), {
    onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
  });

  return {
    createFolder,
    deleteFolder,
    updateFolder,
    moveItem,
  };
};

const invalidationOfDataOnCurrentPage = (queryClient: QueryClient, folderId?: string) => {
  if (folderId) {
    queryClient.invalidateQueries(['folders', Number(folderId)]);
  } else {
    queryClient.invalidateQueries(['model']);
  }
};
