import { css } from 'styled-components';

export const BeamerGlobalStyles = css`
  /* stylelint-disable selector-id-pattern */
  #beamerLastPostTitle.active {
    top: -15px !important;
    left: 54px !important;

    .popper__arrow {
      left: 0 !important;
    }

    && .popper__arrow::before {
      border-color: transparent rgb(0 0 0 / 11%) transparent transparent;
      left: -9px;
      right: auto;
    }

    && .popper__arrow::after {
      border-width: 8px 10px 8px 0;
      border-color: transparent white transparent transparent;
      left: -8px;
      right: auto;
    }
  }
`;
