import { QueryClientProvider } from '@tanstack/react-query';
import { PorscheThemeWrapper } from 'components/ui/porsche-theme-wrapper';
import { setupQueryClient } from 'config/query-client';
import { type ReactElement, type ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import translations from 'translations/en-us.json';

const queryClient = setupQueryClient();

interface Props {
  children?: ReactNode | undefined;
}

export const ContextWrapper = ({ children }: Props): ReactElement => {
  return (
    <PorscheThemeWrapper>
      <IntlProvider defaultLocale="en" locale={navigator.language} messages={translations}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </IntlProvider>
    </PorscheThemeWrapper>
  );
};
