import { Button } from 'components/ui/atomic-components/button';
import styled from 'styled-components';

export const styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
    width: 304px;
  `,

  Title: styled.div`
    color: ${({ theme }) => theme.colors.textLabel};
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]} 0 ${theme.spacing[12]}`};
    cursor: default;
  `,

  Palette: styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  ColorPaletteRow: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  CustomColorSection: styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[12]}`};
    gap: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  `,

  CustomColorTitle: styled.div`
    color: ${({ theme }) => theme.colors.textDisabled};
    font-size: ${({ theme }) => theme.fontSize.xxs};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  CustomColors: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  AddCustomColorButton: styled(Button)`
    border: 1px dashed ${({ theme }) => theme.colors.outlineDefault};
    border-radius: ${({ theme }) => theme.borderRadius.xs};

    &.ant-btn-icon-only {
      width: 20px;
      height: 20px;
    }

    color: ${({ theme }) => theme.colors.iconDefault};
    padding: 0;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.iconHover};
    }
  `,
};
