import { Form as AntdForm } from 'antd';
import styled from 'styled-components';
import { Caption } from 'styles/typography';

/**
 * @deprecated non porsche component
 */
export const FormOld = styled(AntdForm).attrs({
  layout: 'vertical',
})`
  .ant-form-item-label > label::after {
    display: none;
  }

  label {
    ${Caption};

    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    margin-bottom: ${({ theme }) => theme.spacing[8]};

    &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
      &::before {
        content: initial;
      }

      &::after {
        display: inline-block;
        color: ${({ theme }) => theme.colors.red60};
        content: '*';
      }
    }
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    ${Caption};

    color: ${({ theme }) => theme.colors.textSecondary};
    min-height: initial;
    padding-top: ${({ theme }) => theme.spacing[4]};
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: ${({ theme }) => theme.colors.red60};
  }

  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-input-affix-wrapper:focus,
  .ant-form-item-has-error .ant-input-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper-focused,
  .ant-form-item-has-error .react-select__control {
    border-color: ${({ theme }) => theme.colors.red60};
    box-shadow: ${({ theme }) => theme.boxShadow[2]};
  }

  /* FIXME:: This is not the right place for this CSS, move to atomic later */
  .ant-picker {
    width: 100%;
  }

  .ant-input-group {
    display: flex;

    .react-select__control {
      border-radius: ${({ theme: { border } }) => `${border.radius.md} 0 0 ${border.radius.md}`};
      box-shadow: none;
    }
  }
`;
