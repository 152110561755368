import { ReactComponent as DeleteIcon } from 'assets/v2/x.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type VisualQueryFilterRule } from 'data/big-query';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { EditPopover } from './edit-popover';

const Wrapper = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing[8]};
`;

interface Props {
  selectedDimensions: string[];
  rule: VisualQueryFilterRule;
  onDelete: () => void;
  onUpdate: (rule: VisualQueryFilterRule) => void;
}

export const ActionButtons = ({
  selectedDimensions,
  rule,
  onDelete,
  onUpdate,
}: Props): ReactElement => {
  return (
    <Wrapper className="metric-filter-rule-actions">
      <EditPopover rule={rule} selectedDimensions={selectedDimensions} onUpdate={onUpdate} />
      <Button
        icon={<IconShell color="gray400" icon={DeleteIcon} />}
        size="extraSmall"
        type="text"
        onClick={onDelete}
      />
    </Wrapper>
  );
};
