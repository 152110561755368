import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import { ListsApi, type ReconciliationConfig } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useEditDerivedListRecon = (): UseMutationResult<
  void,
  AxiosError,
  {
    listId: number;
    request: Partial<ReconciliationConfig>;
  },
  unknown
> => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  return useMutation(
    ({ listId, request }: { listId: number; request: Partial<ReconciliationConfig> }) =>
      ListsApi.editDerivedListRecon({ listId, request }),
    {
      onSuccess: (_, { listId }) => {
        queryClient.invalidateQueries(['lists', listId]);

        notification.success({
          message: intl.formatMessage({ id: 'lists.update_from_data.success' }),
        });
      },
      onError: defaultApiErrorHandler,
    },
  );
};
