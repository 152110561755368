import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { getCallbackUrl, getState } from 'components/modules/settings/fivetran/common/util';
import { IconShell } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { useCommonLoginFlow } from '../hooks/use-common-flow';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';

const { StyledP, StyledButton } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer, FullWidthWrapper, RightPanel, RightTitle } =
  FormConnectorStyles;

export const XeroContent: FC<React.PropsWithChildren<ModalContentProps>> = ({ item }) => {
  const { clientId } = item;
  const state = getState(item.id);

  const { isLoading } = useCommonLoginFlow(item);

  const onClick = () => {
    window.open(
      `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${getCallbackUrl()}&scope=offline_access accounting.settings accounting.transactions accounting.reports.read accounting.journals.read accounting.contacts&state=${state}`,
      '_self',
    );
  };

  return (
    <FullWidthWrapper>
      <LeftPanel>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Title>{item.displayName}</Title>
        <StyledP>
          <FormattedMessage id="integrations.xero.modal.desc" />
        </StyledP>
        <CommonContent {...item} />
      </LeftPanel>
      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <StyledButton loading={isLoading} type="primary" onClick={onClick}>
          <FormattedMessage id="integrations.sfdc.modal.button_text" />
          <IconShell color="gray00" icon={ArrowRightIcon} />
        </StyledButton>
      </RightPanel>
    </FullWidthWrapper>
  );
};
