import { Layout as LayoutAtom } from 'components/ui/atomic-components';
import { HtmlHead } from 'components/ui/html-head';
import { type FC } from 'react';
import styled from 'styled-components';

const Layout = styled(LayoutAtom)`
  height: 100%;
  width: 100%;
  overflow: hidden;

  > .ant-layout-content {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
`;

const { Content: AntdContent } = Layout;

const Content = styled(AntdContent)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
`;

export const PageLayout: FC<
  React.PropsWithChildren<{
    children: JSX.Element[] | JSX.Element;
    title?: string;
  }>
> = ({ children, title }) => (
  <Layout>
    {title && <HtmlHead titles={[title]} />}
    <Content>{children}</Content>
  </Layout>
);
