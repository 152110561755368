import { css } from 'styled-components';
import { FontS } from 'styles/typography';

export const NotificationStyles = css`
  .dt-notification {
    && .dt-notification-notice {
      display: flex;
      align-items: center;
      min-height: 48px;
      width: max-content;
      max-width: 420px;
      font-family: ${({ theme }) => theme.font.text};
      padding: ${({ theme }) => theme.spacing[12]};
      background-color: ${({ theme: { colors } }) => colors.gray00};
      border-radius: ${(props) => props.theme.borderRadius.m};
      border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
      box-shadow: ${({ theme: { shadow } }) => shadow.softSmall};
    }

    &&& .dt-notification-notice-icon {
      font-size: inherit;
      line-height: 16px;
      top: 16.5px;
    }

    && .dt-notification-notice .dt-notification-notice-with-icon .dt-notification-notice-message {
      ${FontS};

      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme: { colors } }) => colors.textTitle2};
      padding-inline-end: ${({ theme }) => theme.spacing[28]};
      margin-left: ${({ theme }) => theme.spacing[20]};
      margin-bottom: 0;
    }

    &&
      .dt-notification-notice
      .dt-notification-notice-with-icon
      .dt-notification-notice-description {
      color: ${({ theme: { colors } }) => colors.textBody2};
      margin-inline-start: ${({ theme }) => theme.spacing[20]};
      font-size: ${({ theme }) => theme.fontSize.xs};
      line-height: ${({ theme }) => theme.lineHeight.xs};

      :empty {
        display: none;
      }

      :not(:empty) {
        margin-top: ${({ theme }) => theme.spacing[4]};
      }
    }

    && .dt-notification-spinner {
      .dt-notification-notice-close {
        display: none;
      }

      .dt-notification-notice-with-icon .dt-notification-notice-message {
        padding-inline-end: 0;
      }
    }

    & .dt-notification-notice .dt-notification-notice-close {
      width: 24px;
      height: 24px;
      top: 13px;
      right: 12px;
      color: ${({ theme: { colors } }) => colors.iconDefault};

      &:hover {
        background-color: ${({ theme: { colors } }) => colors.gray100};
        color: ${({ theme: { colors } }) => colors.gray600};
      }
    }
  }
`;
