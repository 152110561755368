import { type ReactElement } from 'react';
import styled from 'styled-components';
import { type ItemType } from '../menu';
import { Directory } from './directory';
import { type DirectoryTreeNodeDataType } from './types';

const DirectoryTreeWrapper = styled.div`
  margin-top: -${({ theme }) => theme.spacing[8]};
  margin-left: -${({ theme }) => theme.spacing[28]};
`;

interface Props {
  treeData: DirectoryTreeNodeDataType[];
  selectedKeys?: (string | number)[];
  expandedKeys?: (string | number)[];
  defaultExpandAll?: boolean;
  enableDragAndDrop?: boolean;
  textToHighlight?: string;
  overflowMenuItems?: (
    directoryTreeNodeData: DirectoryTreeNodeDataType,
    level: number,
  ) => ItemType[];
  onSelect?: (directoryTreeNode: DirectoryTreeNodeDataType) => void;
}

export const DirectoryTree = ({
  treeData,
  selectedKeys,
  expandedKeys,
  defaultExpandAll,
  enableDragAndDrop,
  textToHighlight,
  overflowMenuItems,
  onSelect,
}: Props): ReactElement => {
  return (
    <DirectoryTreeWrapper>
      <Directory
        defaultExpandAll={defaultExpandAll}
        enableDragAndDrop={enableDragAndDrop}
        expandedKeys={expandedKeys}
        overflowMenuItems={overflowMenuItems}
        selectedKeys={selectedKeys}
        textToHighlight={textToHighlight}
        treeData={treeData}
        onSelect={onSelect}
      />
    </DirectoryTreeWrapper>
  );
};
