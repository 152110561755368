import { Button, Result } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const NotFoundPage: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Result
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            {intl.formatMessage({ id: 'go_back_home' })}
          </Button>
        }
        status="404"
        subTitle={intl.formatMessage({ id: '404.sub_title' })}
        title="404"
      />
    </Wrapper>
  );
};
