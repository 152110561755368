import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelApi } from 'data/modelling/model';
import { type PlanMetric } from '../metric';

export const useGetPlanMetrics = (options?: {
  enabled?: boolean;
}): {
  metrics: PlanMetric[];
  isLoading: boolean;
} => {
  const { data: metrics = [], isLoading } = useQuery(
    ['model-metrics'],
    () => ModelApi.findMetrics(),
    {
      staleTime: StaleTime.Short,
      enabled: options?.enabled ?? true,
    },
  );

  return {
    metrics,
    isLoading,
  };
};
