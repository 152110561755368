import { XeroSignupApi } from 'data/integrations/xero-signup/api';
import { useEffect, type ReactElement } from 'react';

export const SignUpWithXero = (): ReactElement => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const xeroAuthCode = urlParams.get('code');

    if (xeroAuthCode) {
      XeroSignupApi.authorize(xeroAuthCode).then(({ fullName, username }) => {
        window.open(
          `https://www.drivetrain.ai/book-a-demo?full_name=${fullName}&email=${username}`,
        );
      });
    } else {
      window.open(
        'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=A19C11A9A8CD4D22BBDC4139ADD3F6E6&redirect_uri=https://id.drivetrain.ai/signup/xero&scope=openid profile email',
      );
    }
  }, []);

  return <></>;
};
