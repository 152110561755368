import { Select } from 'components/ui/atomic-components';
import { useMemo } from 'react';
import { type SingleValue } from 'react-select';
import { type VisualRuleEditRenderCompParams } from './types';

export const BooleanSelector: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  const current = useMemo(
    () => ({ label: data?.[0]?.value || '', value: data?.[0]?.value || '' }),
    [data],
  );
  const options = useMemo(() => {
    return ['true', 'false'].map((i) => ({ label: i, value: i }));
  }, []);

  const onOptionChange = (
    option: SingleValue<{
      label: string | number | undefined;
      value: string | number | undefined;
    }>,
  ) => onChange([{ value: option?.value || '' }]);

  return <Select options={options} value={current} onChange={onOptionChange} />;
};
