import { AsyncSwitcher } from 'components/ui/async-switcher';
import { type List } from 'data/modelling/lists';
import { useState, type ReactElement, useEffect } from 'react';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { useActiveList } from '../hooks';
import { ListGrid } from './grid';
import { useGridDataValidations } from './grid/hooks/use-grid-data-validations';
import { ListUpdates } from './list-updates';
import { SecondaryTopBar } from './secondary-top-bar';
import { loadLocalData, saveLocalData } from './utils';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const GridWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[16]} ${spacing[16]}`};
  padding-bottom: 0;
`;

interface Props {
  toggleShowLeftNav: () => void;
  showLeftNav: boolean;
}

export const RightSectionList = ({ toggleShowLeftNav, showLeftNav }: Props): ReactElement => {
  const [hasStoreInitialized, setHasStoreInitialized] = useState(false);
  const activeListQuery = useActiveList();

  const initializeStore = useListStoreContext((s) => s.initializeStore);
  const initializeLocalData = useListStoreContext((s) => s.initializeLocalData);

  useEffect(() => {
    const data = activeListQuery?.data;

    if (data) {
      initializeStore({ data, embedPlace: 'global-list' });

      loadLocalData(data.id).then((localData) => {
        initializeLocalData(localData as { columnWidthMap: Record<string, number> });
        setHasStoreInitialized(true);
      });
    }
  }, [activeListQuery?.data, initializeLocalData, initializeStore]);

  useGridDataValidations(activeListQuery.data?.config?.dataValidation);

  return (
    <Wrapper>
      <AsyncSwitcher {...activeListQuery} isLoading={activeListQuery.isLoading}>
        {(listData: List) =>
          hasStoreInitialized && (
            <>
              <SecondaryTopBar showLeftNav={showLeftNav} toggleShowLeftNav={toggleShowLeftNav} />
              {listData.reconciliationEnabled && <ListUpdates />}
              <GridWrapper>
                <ListGrid onColumnWidthSave={saveLocalData} />
              </GridWrapper>
            </>
          )
        }
      </AsyncSwitcher>
    </Wrapper>
  );
};
