import { useQueryClient } from '@tanstack/react-query';
import { type UploadFile } from 'antd/es/upload/interface';
import { type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components';
import {
  CSVUploadWorkflowStage,
  type CSVPreviewRowItem,
  type CSVListPreviewData,
  type ImportErrorDetails,
} from 'data/lists';
import { ListsApi } from 'data/modelling/lists';
import { isEmpty } from 'lodash';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ImportErrorModal } from './import-error-modal';
import { PreSaveModal } from './pre-save-modal';
import { PreviewModal } from './preview-modal';
import { attachRowId, getColumnNamesFromCSVFile, validateColumnNames } from './util';

export const UploadFlow: FC<
  React.PropsWithChildren<{
    file?: UploadFile<string | Blob>;
    onUploadFlowEnded: () => void;
  }>
> = ({ file, onUploadFlowEnded }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const listId = useListStoreContext((s) => s.id);
  const listConfig = useListStoreContext((s) => s.config);
  const listRows = useListStoreContext((s) => s.rows);
  const resetUndoRedoStack = useListStoreContext((s) => s.resetUndoRedoStack);

  const [currentStage, setCurrentStage] = useState(CSVUploadWorkflowStage.Default);
  const [importErrorDetails, setImportErrorDetails] = useState<ImportErrorDetails | undefined>();
  const [validateLoading, setValidateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [previewData, setPreviewData] = useState<CSVListPreviewData>();
  const [edits, setEdits] = useState<{ [rowId: number]: { [propName: string]: string } }>({});

  const [savableRows, errorRows] = useMemo(() => {
    const total = (previewData?.rows || []).length;
    const errorRows = (previewData?.rows || []).filter((row) => !isEmpty(row?.errors)).length;

    return [total - errorRows, errorRows];
  }, [previewData?.rows]);

  const isFreshUpload = useMemo(() => {
    return listConfig?.columnOrder?.length <= 1 && isEmpty(listRows);
  }, [listConfig, listRows]);

  const onUpdatePropertyTypeMap = useCallback(
    (
      columnTypeMap: CSVListPreviewData['columnTypeMap'],
      dateFormatMap?: CSVListPreviewData['dateFormatMap'],
    ) => {
      setPreviewData({
        ...previewData,
        columnTypeMap,
        ...(dateFormatMap ? { dateFormatMap } : {}),
        columnOrder: previewData?.columnOrder || [],
      } as CSVListPreviewData);
    },
    [previewData],
  );

  const onCancel = () => {
    setCurrentStage(CSVUploadWorkflowStage.Default);
    setImportErrorDetails(undefined);
    onUploadFlowEnded();
  };

  const createPayloadFromPreviewData = (skipErrors?: boolean): CSVListPreviewData => {
    const inputData: CSVPreviewRowItem[] = [];

    (previewData?.rows || []).forEach((row) => {
      if (skipErrors && !isEmpty(row?.errors || [])) {
        return;
      }

      inputData.push({ values: { ...(row?.values || {}), ...(edits[row?.rowId || 0] || {}) } });
    });

    return {
      columnTypeMap: previewData?.columnTypeMap || {},
      dateFormatMap: previewData?.dateFormatMap || {},
      columnOrder: previewData?.columnOrder || [],
      rows: inputData,
    };
  };

  const onValidate = async () => {
    setValidateLoading(true);
    const payload: CSVListPreviewData = createPayloadFromPreviewData();

    try {
      const data = await ListsApi.validateListCSVContent(listId, payload);

      setPreviewData({
        ...data,
        rows: attachRowId(data?.rows || []),
      });
      setEdits({});
    } catch (error: unknown) {
      defaultApiErrorHandler(error as AxiosError);
    }
    setValidateLoading(false);
  };

  const onValidatePropertyMapping = async () => {
    try {
      await onValidate();
      setCurrentStage(CSVUploadWorkflowStage.Preview);
    } catch (err) {
      defaultApiErrorHandler(err as AxiosError);
    }
  };

  const onSave = async () => {
    setSaveLoading(true);
    const payload: CSVListPreviewData = createPayloadFromPreviewData(true);

    if (!isEmpty(payload?.rows)) {
      try {
        const response = await ListsApi.saveListCSVContent(listId, payload);

        if (response?.success) {
          setCurrentStage(CSVUploadWorkflowStage.Default);
          onUploadFlowEnded();
          setPreviewData(undefined);
          setEdits({});
          notification.success({
            message: intl.formatMessage({
              id: 'lists.upload.success',
            }),
          });

          resetUndoRedoStack();
          queryClient.invalidateQueries({ queryKey: ['lists', listId] });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: 'lists.upload.failed',
            }),
          });
        }
      } catch (error: unknown) {
        defaultApiErrorHandler(error as AxiosError);
      }
    }
    setSaveLoading(false);
  };

  const downloadPreviewAsExcel = async () => {
    if (!isEmpty(previewData?.rows)) {
      try {
        await ListsApi.downloadListPreviewData(listId, previewData?.rows || []);
      } catch (err) {
        defaultApiErrorHandler(err as AxiosError);
      }
    }
  };

  const onEditCell = (rowId: number, propName: string, newVal: string) => {
    setEdits((oldVal) => ({
      ...oldVal,
      [rowId]: { ...(oldVal[rowId] || {}), [propName]: newVal || '' },
    }));
  };

  const onSubmitCSV = useCallback(async () => {
    const formData = new FormData();

    formData.append('file', file as unknown as Blob);

    try {
      const data: CSVListPreviewData = await ListsApi.uploadListsCSV(listId, formData);

      setPreviewData({
        ...data,
        rows: attachRowId(data?.rows || []),
      });
      setEdits({});
      setCurrentStage(CSVUploadWorkflowStage.ColumnMapping);
    } catch (error) {
      defaultApiErrorHandler(error as AxiosError);
    }
  }, [listId, file]);

  const onSubmitAndValidateCSV = useCallback(async () => {
    const formData = new FormData();

    formData.append('file', file as unknown as Blob);

    try {
      const data: CSVListPreviewData = await ListsApi.uploadAndValidateListsCSV(listId, formData);

      const { csvErrors } = data;

      if (!isEmpty(csvErrors?.missingColHeaders) || !isEmpty(csvErrors?.newColHeaders)) {
        setCurrentStage(CSVUploadWorkflowStage.ImportError);
        setImportErrorDetails({
          missingCols: (csvErrors?.missingColHeaders || []).map(formatName),
          newCols: (csvErrors?.newColHeaders || []).map(formatName),
        });

        return;
      }

      setPreviewData({
        ...data,
        rows: attachRowId(data?.rows || []),
      });
      setEdits({});
      setCurrentStage(CSVUploadWorkflowStage.Preview);
    } catch (error) {
      defaultApiErrorHandler(error as AxiosError);
    }
  }, [listId, file]);

  const processCSVFile = useCallback(async () => {
    const columnHeaderNames = await getColumnNamesFromCSVFile(file);

    const { error } = validateColumnNames(columnHeaderNames);

    if (error) {
      notification.error({
        message: intl.formatMessage({
          id: error,
        }),
      });

      return;
    }

    if (isFreshUpload) {
      await onSubmitCSV();
    } else {
      await onSubmitAndValidateCSV();
    }
  }, [file, intl, isFreshUpload, onSubmitAndValidateCSV, onSubmitCSV]);

  useEffect(() => {
    if (file) {
      processCSVFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <PreviewModal
        cellEditsFound={!isEmpty(edits)}
        columnNames={previewData?.columnOrder || []}
        currentStage={currentStage}
        data={previewData}
        downloadAsExcel={downloadPreviewAsExcel}
        fileName={file?.name}
        isFreshUpload={isFreshUpload}
        noSavableRowsFound={savableRows === 0}
        saveLoading={saveLoading}
        updatePropertyTypeMap={onUpdatePropertyTypeMap}
        validationLoading={validateLoading}
        visible={[CSVUploadWorkflowStage.ColumnMapping, CSVUploadWorkflowStage.Preview].includes(
          currentStage,
        )}
        onBack={() => setCurrentStage(CSVUploadWorkflowStage.ColumnMapping)}
        onClose={onCancel}
        onEdit={onEditCell}
        onSubmit={() => {
          if (currentStage === CSVUploadWorkflowStage.ColumnMapping) {
            onValidatePropertyMapping();
          } else {
            if (errorRows) {
              setCurrentStage(CSVUploadWorkflowStage.PreSave);
            } else {
              onSave();
            }
          }
        }}
        onValidate={onValidate}
      />

      <PreSaveModal
        errorRows={errorRows}
        savableRows={savableRows}
        visible={currentStage === CSVUploadWorkflowStage.PreSave}
        onBack={() => setCurrentStage(CSVUploadWorkflowStage.Preview)}
        onSave={onSave}
      />

      <ImportErrorModal
        missingCols={importErrorDetails?.missingCols || []}
        newCols={importErrorDetails?.newCols || []}
        visible={currentStage === CSVUploadWorkflowStage.ImportError}
        onClose={onCancel}
      />
    </>
  );
};
