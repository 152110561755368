import styled from 'styled-components';
import { FontXs } from 'styles/typography';

export const styles = {
  Title: styled.div`
    ${FontXs};

    height: 28px;
    display: flex;
    align-items: center;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textLabel};
    margin: ${({ theme: { spacing } }) => `0 ${spacing[12]}`};
  `,
};
