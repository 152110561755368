import { type ReactElement } from 'react';
import styled from 'styled-components';
import { ResizeHandle } from './resize-handle';

const Wrapper = styled.div`
  width: 2px;
  overflow: hidden;
  transition: background-color ease-in-out 0.25s;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray300};
  }
`;

export const NavResizeHandle = (): ReactElement => {
  return (
    <Wrapper>
      <ResizeHandle direction="horizontal" />
    </Wrapper>
  );
};
