import { type IRowNode, type GridApi } from 'ag-grid-community';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { type ID, type NewPeriod } from 'data';
import { type Dimension } from 'data/dimension';
import { type MetricSortConfig, type Metric } from 'data/metrics';
import { constructListObjFromDimGroup } from 'data/modelling/dimension-group/utils';
import { type List } from 'data/modelling/lists';
import {
  type FormulaLookupMap,
  type FormulaComputationStates,
  type ModuleMetric,
  type TypeFormula,
} from 'data/modelling/metric';
import {
  type ModuleQueryParams,
  type Module,
  type ModuleAttributes,
  type ModuleDimensionProperties,
} from 'data/modelling/modules';
import { type UndoRedoStackType } from 'data/modelling/modules/undo-redo/types';
import { type PageTemplate } from 'data/page-template';
import { type Frame } from 'data/reports/types';
import { AccessAction } from 'data/roles/permissions';
import { type Dictionary } from 'lodash';
import { type DecodedValueMap } from 'use-query-params';
import { create } from 'zustand';
import {
  type MetricCellEditableState,
  computeMetricForWhichLeafRowsEditable,
  generateMetricsMaps,
  preProcessFrame,
} from './utils';
import { generateDataLookupMap } from './utils/generate-data-lookup-map';
import { generateFormulaeLookup } from './utils/generate-formulae-lookup';

export interface ModelStore {
  gridApi?: GridApi;
  metricsWithAccordionOpened?: Record<string, { node: IRowNode; isDataLoading: boolean }>;

  id: ID;
  name: string;
  displayName: string;
  description: string | null;
  metrics: ModuleMetric[];
  metricPivotMap: Map<
    string,
    {
      rows: string[];
      columns: string[];
    }
  >;
  metricsMap: Dictionary<ModuleMetric>;
  pivotData: Metric[];
  planPeriods: NewPeriod[];
  prevPeriods: NewPeriod[];
  futurePeriods: NewPeriod[];
  attributes: ModuleAttributes;
  lists?: List[];
  dimensionProperties?: ModuleDimensionProperties;
  dimensions: Dimension[];
  pageDimensions: Dimension[];
  showSummaryRows: boolean;

  formulae: {
    formulaLookup?: FormulaLookupMap;
  };
  formulaComputationStates: { [key: string]: FormulaComputationStates };
  selectedFormulaNode?: string;
  unsavedErroneousFormulae: Record<string, string>;

  frame: Frame;
  dataLookupMap: Record<string, number | null>;

  metricForWhichLeafRowsEditable: MetricCellEditableState;
  concurrentRunningTasks?: Set<string>;

  undoStack: UndoRedoStackType;
  redoStack: UndoRedoStackType;
  unrefinedFormulaeMap: Record<string, TypeFormula[]>;
  pendingVariableFormulaeCacheReset: Set<string>;

  rowIndexOnHover: number;
  accessAction: AccessAction;
  isReadOnly: boolean;

  moduleRefetchPending: boolean;
  templateSavePending: boolean;
  moduleReloadTaskId: string;
  sortConfigs: MetricSortConfig[];
  sortedRowOrderMap?: Record<string, Record<string, number>>;
  hideEmptyRows?: boolean;

  openConversationCellIdentifier?: string;

  initializeStore: (o: {
    module: Module;
    template: PageTemplate;
    queryRef: React.MutableRefObject<DecodedValueMap<ModuleQueryParams>>;
    tempSccl: boolean;
  }) => void;
  setGridApi: (api: GridApi) => void;
  setModuleScenarios: (moduleScenarios: string[]) => void;
  pushToLoadAccordionDataList: (metrics: string, node: IRowNode) => void;
  setConcurrentRunningTasks: (value?: Set<string>) => void;
  setSortConfigs: (sortConfigs: MetricSortConfig[]) => void;
  removeSortConfigForMetric: (metric: string) => void;
  setOpenConversationCellIdentifier: (cellIdentifier?: string) => void;
}

export const useModelStore = create<ModelStore>()((set, get) => ({
  id: 1,
  name: '',
  displayName: '',
  description: null,
  metrics: [],
  metricPivotMap: new Map(),
  metricsMap: {},
  pivotData: [],
  planPeriods: [],
  prevPeriods: [],
  futurePeriods: [],
  attributes: {} as ModuleAttributes,
  frame: {} as Frame,
  dataLookupMap: {},
  dimensions: [],
  pageDimensions: [],
  metricForWhichLeafRowsEditable: new Map(),
  sortConfigs: [],
  showSummaryRows: false,

  formulaComputationStates: {},
  formulae: { formulaLookup: {} as FormulaLookupMap },
  selectedFormulaNode: undefined,
  unsavedErroneousFormulae: {},
  pendingVariableFormulaeCacheReset: new Set(),

  rowIndexOnHover: -1,
  accessAction: AccessAction.RemoveAccess,
  isReadOnly: false,

  moduleRefetchPending: false,
  templateSavePending: false,
  moduleReloadTaskId: '',

  undoStack: [],
  redoStack: [],
  unrefinedFormulaeMap: {},

  openConversationCellIdentifier: undefined,

  setGridApi: (gridApi) => set({ gridApi }),
  setModuleScenarios: (moduleScenarios: string[]) => {
    const { attributes = {} } = get();

    set({ attributes: { ...attributes, scenarios: moduleScenarios } });
  },
  pushToLoadAccordionDataList: (metric, node) => {
    const { metricsWithAccordionOpened = {} } = get();

    metricsWithAccordionOpened[metric] = { node, isDataLoading: true };

    set({
      metricsWithAccordionOpened: { ...metricsWithAccordionOpened },
    });
  },
  setConcurrentRunningTasks: (value) => set({ concurrentRunningTasks: value }),
  setSortConfigs: (sortConfigs) => set({ sortConfigs }),
  removeSortConfigForMetric: (metric) => {
    const { sortConfigs } = get();

    const updatedSortConfigs = sortConfigs.filter((config) => !config.row.includes(metric));

    set({ sortConfigs: updatedSortConfigs });
  },

  setOpenConversationCellIdentifier: (value) => set({ openConversationCellIdentifier: value }),

  initializeStore: ({ module, template, queryRef, tempSccl }) => {
    const pivot = template.pivots[0];
    const { metricsMap, metricPivotMap } = generateMetricsMaps(module.metrics, pivot, tempSccl);

    const formulaeLookup = generateFormulaeLookup(module, metricPivotMap, queryRef, tempSccl);

    const dataLookupMap = generateDataLookupMap({
      data: module.data,
      metricPivotMap,
    });

    set({
      id: module.id,
      name: module.name,
      displayName: module.displayName,
      description: module.description,
      metrics: module.metrics,
      metricsMap,
      metricPivotMap,
      planPeriods: module.planPeriods,
      attributes: module.attributes,
      lists: [
        ...(module?.lists || []),
        ...(module?.dimGroups || []).map((d) => constructListObjFromDimGroup(d)),
      ],
      dimensionProperties: module.dimensionProperties,
      dimensions: module.dimensions,
      pageDimensions: module.pageDimensions,
      frame: preProcessFrame({ frame: module.frame, template }),
      dataLookupMap,
      formulae: formulaeLookup,
      metricForWhichLeafRowsEditable: computeMetricForWhichLeafRowsEditable({
        metrics: module.metrics,
        filter: queryRef.current.f || {},
        pageDimensionNames: pivot.dimensions.page || [],
        metricPivotMap,
        tempSccl,
        queryRef,
        frame: module.frame,
        dataLookupMap,
      }),
      accessAction: module.accessAction,
      isReadOnly:
        [AccessAction.ReadAccess, AccessAction.ContributorAccess].includes(module.accessAction) ||
        isInVersionMode([VersionAccessModes.View]),
      hideEmptyRows:
        get().name !== module.name // only on initial load set hideEmptyRows state
          ? module.attributes.hideEmptyRows
          : get().hideEmptyRows,
    });

    // Since the metric names are unique across modules, we have unique keys
    // This will make sure the unrefinedFormulaeMap is updated safely on module change
    set((prevStore) => {
      const unrefinedFormulaeMapModified: ModelStore['unrefinedFormulaeMap'] = {};

      Object.keys(metricsMap).forEach((metricName) => {
        if (
          !prevStore.unrefinedFormulaeMap[metricName] ||
          prevStore.pendingVariableFormulaeCacheReset.has(metricName)
        ) {
          unrefinedFormulaeMapModified[metricName] = metricsMap[metricName].formulae;
        } else {
          unrefinedFormulaeMapModified[metricName] = prevStore.unrefinedFormulaeMap[metricName];
        }
      });

      return {
        unrefinedFormulaeMap: unrefinedFormulaeMapModified,
        pendingVariableFormulaeCacheReset: new Set(),
      };
    });
  },
}));
