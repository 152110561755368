import { type ACTUAL, type CURRENT } from 'data/versions';

export type FixedColGroup = typeof ACTUAL | typeof CURRENT;

export type ChartValueKey = FixedColGroup | 'WhatIf';
export type ColGroup = FixedColGroup | VaryingColGroup | 'WhatIf';

export enum VaryingColGroup {
  Variance = 'variance',
  VariancePercentage = 'variancePercentage',
  Attainment = 'attainment',
  VarianceAndAttainment = 'varianceAndAttainment',
}

export type ColGroupData = {
  values: { [key in ColGroup]?: number | null };
  displayValue: string | null;
  data?: { [key: string]: ColGroupData };
  order?: string[];
};

export type FormatEval = string;

export type CellFormatConditionalRules = {
  [key in ColGroup]: {
    expr: FormatEval;
    color: string;
  }[];
};

export type CellFormatRules = {
  cols: VaryingColGroup[];
  conditionals: CellFormatConditionalRules | null;
};
