import { type Currency, currencyLocaleMap } from 'data/currencies';
import { DataFormattingType } from 'utils/data-formatter/types';
import { ZeroFormatType } from '../types';
import { customCurrencySymbolFormatter } from './custom-currency-symbol-formatter';

const zeroNumberFormattersForMetricType: {
  [key in DataFormattingType | 'default']: (props: {
    currency: Currency;
    digitsAfterDecimal: number;
  }) => string;
} = {
  [DataFormattingType.Currency]: ({ currency, digitsAfterDecimal }) => {
    const formattedValue = Intl.NumberFormat(currencyLocaleMap[currency], {
      minimumFractionDigits: digitsAfterDecimal,
      maximumFractionDigits: digitsAfterDecimal,
      currency,
      style: 'currency',
    }).format(0);

    return customCurrencySymbolFormatter(formattedValue, currency);
  },

  [DataFormattingType.Percentage]: ({ currency, digitsAfterDecimal }) =>
    Intl.NumberFormat(currencyLocaleMap[currency], {
      minimumFractionDigits: digitsAfterDecimal,
      maximumFractionDigits: digitsAfterDecimal,
      style: 'percent',
    }).format(0),

  [DataFormattingType.Number]: ({ currency, digitsAfterDecimal }) =>
    zeroNumberFormattersForMetricType.default({ currency, digitsAfterDecimal }),

  default: ({ currency, digitsAfterDecimal }) =>
    Intl.NumberFormat(currencyLocaleMap[currency], {
      minimumFractionDigits: digitsAfterDecimal,
      maximumFractionDigits: digitsAfterDecimal,
    }).format(0),
};

const zeroDashFormattersForMetricType: {
  [key in DataFormattingType | 'default']: (props: { currency: Currency }) => string;
} = {
  [DataFormattingType.Currency]: () => '-',
  [DataFormattingType.Percentage]: () => '-',
  [DataFormattingType.Number]: () => '-',
  default: () => '-',
};

export const formattersForZeroType: {
  [key in ZeroFormatType | 'default']: {
    [key in DataFormattingType]: (props: {
      currency: Currency;
      digitsAfterDecimal: number;
    }) => string;
  };
} = {
  [ZeroFormatType.Number]: zeroNumberFormattersForMetricType,

  [ZeroFormatType.Dash]: zeroDashFormattersForMetricType,

  default: zeroNumberFormattersForMetricType,
};
