import { type GroupBase, type StylesConfig } from 'react-select';
import { defaultTheme } from 'styles/theme-old';
import { type OptionBase } from '../option';
import { getCommonStyles } from './common';
import { getDropdownSmallSize } from './small';

export const getInlineDropdownStyles = <
  T extends OptionBase = OptionBase,
  IsMulti extends boolean = boolean,
>(): StylesConfig<T, IsMulti, GroupBase<T>> => {
  const CommonStyles = getCommonStyles<T, IsMulti>();

  const DropdownSmallSize = getDropdownSmallSize<T, IsMulti>();

  return {
    ...DropdownSmallSize,
    control: (base, props) => ({
      ...DropdownSmallSize.control?.(base, props),
      padding: `0 ${defaultTheme.spacing[8]}`,
      border: '1px solid transparent',
      boxShadow: 'none',
      color: defaultTheme.colors.black900,
      background: 'none',

      ':hover': {
        backgroundColor: defaultTheme.colors.blue10,
        borderColor: 'transparent',
      },

      ':focus-within': {
        backgroundColor: defaultTheme.colors.white,
        boxShadow: defaultTheme.boxShadow[2],
        borderColor: defaultTheme.colors.blue60,
      },
    }),

    singleValue: (base, props) => ({
      ...DropdownSmallSize.singleValue?.(base, props),
      position: 'static',
      transform: 'none',
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: defaultTheme.spacing[4],
    }),

    menu: (base, props) => ({
      ...CommonStyles.menu?.(base, props),
      minWidth: '200px',
      width: 'min-content',
    }),
    dropdownIndicator: (base, props) => ({
      ...CommonStyles.dropdownIndicator?.(base, props),
      padding: 0,
      width: defaultTheme.iconSize[16],
      height: defaultTheme.iconSize[16],
    }),
  };
};
