import { type FormInstance } from 'antd';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { Button } from 'components/ui/atomic-components';
import { type ListConfig } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { useUpdateDataValidationPermissibleValuesMap } from './hooks/use-update-data-validation-permissible-values-map';
import { type FormType } from './types';

interface Props {
  form: FormInstance<FormType>;
  columnName: string;
  closePopup: () => void;
}

export const RemoveButton = ({ form, columnName, closePopup }: Props): ReactElement => {
  const { updateListMutation } = useListMutations();

  const listId = useListStoreContext((s) => s.id);
  const config = useListStoreContext((s) => s.config);
  const setConfig = useListStoreContext((s) => s.setConfig);
  const gridApi = useListStoreContext((s) => s.gridApi);

  const updateDataValidationPermissibleValuesMap = useUpdateDataValidationPermissibleValuesMap();

  const handleRemove = async () => {
    const updatedDataValidation = { ...config.dataValidation };

    if (!updatedDataValidation[columnName]) {
      form.setFieldValue('sourceColumn', null);
      closePopup();

      return;
    }

    delete updatedDataValidation[columnName];

    const updatedConfig = {
      ...config,
      dataValidation: updatedDataValidation,
    } as ListConfig;

    await updateListMutation.mutateAsync({
      id: listId,
      list: { config: updatedConfig },
    });

    setConfig(updatedConfig);

    await updateDataValidationPermissibleValuesMap(updatedDataValidation);

    queueMacroTask(() =>
      gridApi?.refreshCells({
        columns: [columnName],
        force: true,
      }),
    );

    closePopup();
  };

  return (
    <Button loading={updateListMutation.isLoading} size="small" type="text" onClick={handleRemove}>
      <FormattedMessage id="remove" />
    </Button>
  );
};
