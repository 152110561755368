import { AuthorisedAccess } from 'components/modules/authorised-access';
import { PageLayout } from 'components/ui/layout';
import { PermissionList } from 'data/roles/permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type FC } from 'react';
import { SystemPage } from '.';

const SystemPageViewer: FC<React.PropsWithChildren<unknown>> = () => {
  const { toolsPage } = useFlags();

  if (!toolsPage) {
    return <></>;
  }

  return (
    <AuthorisedAccess permissionType={PermissionList.All}>
      <PageLayout>
        <SystemPage />
      </PageLayout>
    </AuthorisedAccess>
  );
};

export { SystemPageViewer as default };
