import { useIsMutating } from '@tanstack/react-query';
import { Spinner } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing[4]};
`;

export const MutationSpinner = (): ReactElement => {
  const mutationCount = useIsMutating();

  if (mutationCount <= 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <Spinner size="small" />
    </Wrapper>
  );
};
