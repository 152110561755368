import { type CellValueChangedEvent, type GridReadyEvent } from 'ag-grid-community';
import { StatusBar } from 'components/ui/status-bar';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { type ReactElement } from 'react';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType } from 'utils/data-formatter/types';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { ActionsFloatingWidget } from '../floating-widget';
import { AddListColumn } from './add-column';
import { BulkPasteFlow } from './bulk-paste-flow';
import { AddRowFooter } from './cell-components';
import {
  useDoesGridHaveScroll,
  useGetListContextItems,
  useGridColumnDefs,
  useGridCrud,
  useGridOptions,
  useGridRowData,
} from './hooks';
import { getGridColumnLimits } from './hooks/use-grid-column-defs/utils';
import { isAllRowValuesEmpty } from './hooks/use-grid-crud/utils';
import { onColumnResized } from './hooks/use-grid-options/utils';
import styles from './styles';
import { type ListGridRow } from './types';
import { useListsUndoRedoListener } from './undo-redo/hooks/use-undo-redo';
import { type UndoStackItem } from './undo-redo/types';

const { Wrapper, Grid, GridContainer, FooterContainer } = styles;

interface Props {
  onColumnWidthSave: ({
    activeListId,
    updatedColumnWidthMap,
  }: {
    activeListId: number | null;
    updatedColumnWidthMap?: Record<string, number> | undefined;
  }) => void;
}

export const ListGrid = ({ onColumnWidthSave }: Props): ReactElement => {
  const accessAction = useListStoreContext((s) => s.accessAction);
  const isReadOnly = useListStoreContext((s) => s.isReadOnly);
  const isDerivedList = useListStoreContext((s) => s.isDerivedList);
  const setGridApi = useListStoreContext((s) => s.setGridApi);
  const gridApi = useListStoreContext((s) => s.gridApi);
  const embedPlace = useListStoreContext((s) => s.embedPlace);
  const config = useListStoreContext((s) => s.config);

  const listStore = useListStoreInstance();

  const { gridWrapperDomRef, isHorizontalScrollPresent, gridWidth, inferGridContainerWidth } =
    useDoesGridHaveScroll();

  const { gridOptions } = useGridOptions();
  const columnDefs = useGridColumnDefs();
  const { rowData, pinnedBottomRowData } = useGridRowData();
  const currency = useCurrency();

  const canAddNewRows =
    !isDerivedList && hasListAccess(accessAction, isReadOnly, config.backedByBQ).addNewRow;

  const noOfRows = canAddNewRows ? rowData.length + 2 : rowData.length + 1;

  const { getContextMenuItems } = useGetListContextItems();

  const { onSaveListEntry, onDeleteListRows, onRowDragEnd } = useGridCrud();

  const onCellValueChanged = ({
    data,
    newValue,
    oldValue,
    node,
    column,
  }: CellValueChangedEvent<ListGridRow>) => {
    if (newValue === oldValue) {
      return;
    }

    if (isAllRowValuesEmpty(data.data)) {
      onDeleteListRows([data]);

      return;
    }

    const undoContext: UndoStackItem = {
      type: 'cell-value-change',
      meta: {
        rowId: node.id,
        column: column.getId(),
        value: oldValue || undefined,
      },
    };

    onSaveListEntry({ rowNode: data, undoContext });
  };

  const onGridReady = (event: GridReadyEvent) => {
    const { api } = event;

    setGridApi(api);
  };

  const numFormatter = () => {
    return (value: number) =>
      numberFormatter({
        value,
        type: DataFormattingType.Number,
        currency,
        digitsAfterDecimal: 2,
      });
  };

  useListsUndoRedoListener();

  return (
    <>
      <Wrapper>
        <GridContainer
          ref={gridWrapperDomRef}
          $gridWidth={embedPlace === 'global-list' ? gridWidth : null}
          $isHorizontalScrollPresent={isHorizontalScrollPresent}
          $noOfRows={noOfRows}
        >
          <Grid<ListGridRow>
            // eslint-disable-next-line
            // @ts-ignore
            $isReadOnly={!canAddNewRows}
            alwaysShowVerticalScroll
            autoSizeStrategy={{
              type: 'fitGridWidth',
              columnLimits: getGridColumnLimits(listStore),
            }}
            columnDefs={columnDefs}
            fullWidthCellRenderer={AddRowFooter}
            getContextMenuItems={getContextMenuItems}
            gridOptions={gridOptions}
            isFullWidthRow={({ rowNode }) => !!rowNode.data?.fullWidth}
            pinnedBottomRowData={canAddNewRows ? pinnedBottomRowData : undefined}
            rowData={rowData as ListGridRow[]}
            suppressLastEmptyLineOnPaste
            onCellValueChanged={onCellValueChanged}
            onColumnResized={(e) =>
              onColumnResized(e, listStore, inferGridContainerWidth, onColumnWidthSave)
            }
            onGridReady={onGridReady}
            onRowDragEnd={onRowDragEnd}
          />
          <AddListColumn inferGridContainerWidth={inferGridContainerWidth} />
        </GridContainer>

        <ActionsFloatingWidget
          onDeleteListRows={onDeleteListRows}
          onSaveListEntry={onSaveListEntry}
        />
      </Wrapper>
      <FooterContainer>
        {gridApi && <StatusBar api={gridApi} formatter={numFormatter} />}
      </FooterContainer>
      <BulkPasteFlow />
    </>
  );
};
