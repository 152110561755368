import { type GridApi, type Column } from 'ag-grid-community';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as StringIcon } from 'assets/metrics/ic_string.svg';
import { ReactComponent as NumberIcon } from 'assets/metrics/number.svg';
import { IconText, type IconType } from 'components/ui/atomic-components';
import { QueryTableColumnDataType } from 'data/big-query';
import { type ListDataTypes } from 'data/modelling/lists';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs, SingleLineEllipsis } from 'styles/typography';
import { ColumnTypeSelector } from '../../../../grid/column-editor/column-type-select/selector';
import { useListColumnTypes } from '../../../../grid/column-editor/column-type-select/use-list-column-types';
import { type ColumnType } from '../../../../grid/column-editor/column-type-select/utils';
import { usePreviewTableContext } from './context';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[12]}`};
`;

const TypeSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 68px;
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
  background: ${({ theme }) => theme.colors.primary50};
`;

const ColumnTypeLabel = styled.span`
  ${FontXs};

  color: ${({ theme }) => theme.colors.gray600};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const Ellipsized = styled.span`
  ${SingleLineEllipsis}
`;

const StyledIconText = styled(IconText)`
  ${SingleLineEllipsis}
`;

const IconMapper: { [key: string]: IconType } = {
  [QueryTableColumnDataType.Date]: CalendarIcon,
  [QueryTableColumnDataType.String]: StringIcon,
  [QueryTableColumnDataType.Numeric]: NumberIcon,
};

export const ColumnHeader: FC<
  React.PropsWithChildren<{
    displayName: string;
    column: Column;
    api: GridApi;
  }>
> = ({ displayName, column, api }) => {
  const colName = column.getId();
  const {
    isColumnTypeEditMode,
    colTypeMap,
    dateFormatMap = {},
    updatePropertyTypeMap,
  } = usePreviewTableContext();
  const type = colTypeMap?.[colName] || QueryTableColumnDataType.String;
  const dateFormat = dateFormatMap?.[colName];

  const columnOptions = useListColumnTypes({ dateFormat });

  const onColumnTypeChange = (opt?: ColumnType) => {
    if (opt?.key as ListDataTypes) {
      const updatedDateFormatMap =
        opt?.key === QueryTableColumnDataType.Date && opt?.dateFormat
          ? { ...dateFormatMap, [colName]: opt?.dateFormat }
          : undefined;

      updatePropertyTypeMap(
        { ...colTypeMap, [colName]: opt?.key as ListDataTypes },
        updatedDateFormatMap,
      );
      api.refreshCells({ force: true });
    }
  };

  return (
    <Wrapper>
      <Header>
        {isColumnTypeEditMode && <Ellipsized title={displayName}>{displayName}</Ellipsized>}
        {!isColumnTypeEditMode && (
          <StyledIconText
            icon={IconMapper[type]}
            size="sm"
            text={<Ellipsized title={displayName}>{displayName}</Ellipsized>}
          />
        )}
      </Header>

      {isColumnTypeEditMode && (
        <TypeSelectWrapper>
          <ColumnTypeLabel>
            <FormattedMessage id="dimensions.property.type" />
          </ColumnTypeLabel>
          <ColumnTypeSelector
            columnOptions={columnOptions.filter((o) => o.key !== 'FORMULA')}
            disabled={false}
            value={columnOptions.find((o) => o.key === type)}
            onChange={(option) => onColumnTypeChange(option)}
          />
        </TypeSelectWrapper>
      )}
    </Wrapper>
  );
};
