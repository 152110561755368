import { create } from 'zustand';

interface VersionStore {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const useVersionStore = create<VersionStore>()((set, get) => ({
  isLoading: false,
  setIsLoading: (isLoading) => {
    set({
      isLoading,
    });
  },
}));
