import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { IconText, Modal } from 'components/ui/atomic-components';
import { Form } from 'components/ui/atomic-components/form';
import { Analytics } from 'config/analytics';
import { QueryTableColumnDataType } from 'data/big-query';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { ListType } from 'data/modelling/lists';
import { PermissionList } from 'data/roles/permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useHasScope } from 'utils/hooks';
import { useListMutations } from '../../hooks';
import { DimensionGroupCreateEditForm } from '../dimension-group-create-edit-form';
import { type FormProps } from '../dimension-group-create-edit-form/types';
import { ListTypeSelector } from './list-type-selector';
import { ListForm } from './simple-list-form';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
  }
`;

interface Props {
  open: boolean;
  closeModal: () => void;
}

export const CreateNewListModal = ({ open, closeModal }: Props): ReactElement => {
  const [listType, setListType] = useState<ListType>(ListType.SimpleTable);
  const { createListMutation } = useListMutations();
  const { addDimensionGroup } = useDimensionGroupMutations();

  const [duplicatesData, setDuplicatesData] = useState<DimensionGroup | undefined>();

  const { tempSccl } = useFlags();

  const hasScope = useHasScope();

  const showDerivedListOptions = tempSccl && hasScope(PermissionList.All);

  const [listForm] = Form.useForm<{ name: string }>();
  const [dimGroupForm] = Form.useForm<FormProps>();

  const resetForms = () => {
    listForm.resetFields();
    dimGroupForm.resetFields();
  };

  const handleCreateList = async () => {
    const data = await listForm.validateFields();

    await createListMutation.mutateAsync({ displayName: data.name.trim() });

    closeModal();
    resetForms();
  };

  const handleCreateDimGroup = async () => {
    const data = await dimGroupForm.validateFields();

    const duplicatesData = await addDimensionGroup.mutateAsync({
      displayName: data.groupName,
      columns: (data.groupDimensions || []).map((dimGrp) => ({
        source: dimGrp.value,
        type: (dimGrp.columnType || QueryTableColumnDataType.String) as QueryTableColumnDataType,
        colName: dimGrp.name || '',
        tableDisplayName: dimGrp.tableDisplayName || '',
        formula: dimGrp.formula || null,
      })),
      uniqueKeyColumns: [],
      filters: data.filters,
    });

    if (duplicatesData?.uniquenessCheckFailed) {
      setDuplicatesData(duplicatesData);
    } else {
      setDuplicatesData(undefined);
      closeModal();
      resetForms();
    }
  };

  const handleConfirm = async () => {
    if (listType === ListType.SimpleTable) {
      await handleCreateList();

      Analytics.track('List created', {
        category: 'Lists',
        meta: 'Blank list',
      });
    } else {
      await handleCreateDimGroup();

      Analytics.track('List created', {
        category: 'Lists',
        meta: 'From existing data',
      });
    }
  };

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={createListMutation.isLoading || addDimensionGroup.isLoading}
      destroyOnClose
      okText={<FormattedMessage id="create" />}
      open={open}
      title={<IconText icon={PlusIcon} text={<FormattedMessage id="lists.create_modal.title" />} />}
      onCancel={() => {
        setDuplicatesData(undefined);
        closeModal();
        resetForms();
      }}
      onOk={handleConfirm}
    >
      {showDerivedListOptions && <ListTypeSelector listType={listType} setListType={setListType} />}
      {listType === ListType.SimpleTable ? (
        <ListForm form={listForm} handleCreateList={handleCreateList} />
      ) : (
        <DimensionGroupCreateEditForm duplicatesData={duplicatesData} form={dimGroupForm} />
      )}
    </StyledModal>
  );
};
