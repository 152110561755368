import { css } from 'styled-components';

export const MenuStyles = css`
  .ant-menu.ant-menu-sub.ant-menu-vertical {
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-radius: ${({ theme }) => theme.borderRadius.m};
  }

  .ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
    max-height: 50vh;
  }
`;
