import { Empty } from 'components/ui/atomic-components/empty';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';

const Image = styled.img``;

const NoMatch: FC<React.PropsWithChildren<{ searchText?: string }>> = ({ searchText }) => (
  <Empty
    description={<FormattedMessage id="integrations.tables_grid.no_match_search_title" />}
    image={<Image src={getAssetUrl('v2/empty/no-data-found.webp')} />}
    title={
      searchText ? (
        <FormattedMessage
          id="integrations.tables_grid.search.title_value"
          values={{ searchText }}
        />
      ) : null
    }
  />
);

export { NoMatch };
