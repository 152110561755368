import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down.svg';
import { IconShell, type ItemType, Dropdown } from 'components/ui/atomic-components';
import { useState, useMemo, type ReactElement, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import styles from './styles';

const { ControlItem, ItemWrapper } = styles;

export enum AggType {
  SUM = 'sum',
  AVG = 'avg',
  CNT = 'count',
}

export interface AggItem {
  type: AggType;
  value: number | string;
}

interface Props {
  aggItems: AggItem[];
}

export const AggSelector = ({ aggItems }: Props): ReactElement => {
  const [selectedItem, setSelectedItem] = useState<AggItem>();
  const [lastSelectedType, setLastSelectedType] = useState<AggType | undefined>(
    () => (localStorage.getItem('status-bar-aggr') as AggType) || undefined,
  );
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const onSelectOption = useCallback(
    (opt: ItemType) => {
      setSelectedItem(aggItems.find((item) => item.type === opt.key));
      setLastSelectedType(opt.key as AggType);
      localStorage.setItem('status-bar-aggr', opt.key as string);
      setOpen(false);
    },
    [aggItems],
  );

  const options = useMemo(() => {
    return aggItems.map((item) => ({
      key: item.type,
      label: (
        <ItemWrapper>
          <span>
            {intl.formatMessage({
              id: `module.grid.range_selection_status_bar.${item.type}.label`,
            })}
          </span>
          <span>{item.value}</span>
        </ItemWrapper>
      ),
    }));
  }, [aggItems, intl]);

  useEffect(() => {
    const item = aggItems.find((item) => item.type === lastSelectedType);

    if (item) {
      setSelectedItem(item);
    } else if (aggItems?.[0]) {
      setSelectedItem(aggItems?.[0]);
    }
  }, [aggItems, lastSelectedType]);

  return (
    <Dropdown
      menu={{ items: options as ItemType[], onClick: onSelectOption }}
      open={open}
      trigger={options.length > 1 ? ['click'] : []}
      onOpenChange={(s) => setOpen(s)}
    >
      {selectedItem ? (
        <ControlItem size="small" type="text">
          {intl.formatMessage({
            id: `module.grid.range_selection_status_bar.${selectedItem.type}.label`,
          })}
          : {selectedItem.value}
          {options.length > 1 && <IconShell color="gray400" icon={ChevronDownIcon} />}
        </ControlItem>
      ) : (
        <div />
      )}
    </Dropdown>
  );
};
