import styled from 'styled-components';

export default {
  TooltipTitle: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
    padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[4]};
  `,
  ModuleInfoWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
  `,
};
