import Papa from 'papaparse';

// reads CSV file and returns the content as row data
export const readCsv = (
  file: Papa.LocalFile,
  config: { preview?: number },
): Promise<Papa.ParseResult<unknown>> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      ...(config || {}),
      complete(results) {
        resolve(results);
      },
      error(err, file) {
        reject(err);
      },
    });
  });
};
