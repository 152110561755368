import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { getState } from 'components/modules/settings/fivetran/common/util';
import { IconShell } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCommonLoginFlow } from '../integrations/hooks/use-common-flow';
import { FormConnectorStyles, PopupModalStyles } from '../integrations/styles';
import { type IntegrationAuthFlowAuth } from '../types';

const { Wrapper, Content, StyledButton } = PopupModalStyles;
const { RightTitle } = FormConnectorStyles;

interface Props {
  flow: IntegrationAuthFlowAuth;
  item: IntegrationModalContent;
}

export const OauthFlow = ({ flow, item }: Props): ReactElement => {
  const InfoComp = flow.infoContent;

  const onConnect = () => {
    const { id } = item;

    const stateString = `${getState(id)}`;

    const authUrl = flow.getAuthUrl({ item, stateString });

    window.open(authUrl, '_self');
  };

  const { isLoading } = useCommonLoginFlow(item);

  return (
    <Wrapper>
      {InfoComp && <InfoComp item={item} />}

      <Content $isFullWidth={!InfoComp}>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <StyledButton loading={isLoading} type="primary" onClick={onConnect}>
          <FormattedMessage id="integrations.sfdc.modal.button_text" />
          <IconShell color="gray00" icon={ArrowRightIcon} />
        </StyledButton>
      </Content>
    </Wrapper>
  );
};
