import { type FrameElement, FrameType } from 'data/reports/types';
import { isEmpty } from 'lodash';

const isBlankValue = (element: FrameElement) =>
  element.type === FrameType.Dimension && element.key === '';

const hasChainOfBlanks = (element: FrameElement): boolean => {
  if (!isBlankValue(element)) {
    return false;
  }

  if (isEmpty(element.children)) {
    return true;
  }

  return element.children.length === 1 && hasChainOfBlanks(element.children[0]);
};

export const eliminateChainOfLoneBlanks = (elements: FrameElement[]): FrameElement[] => {
  const res: FrameElement[] = [];

  for (const element of elements) {
    if (elements.length === 1 && hasChainOfBlanks(element)) {
      continue;
    }

    const children = eliminateChainOfLoneBlanks(element.children);

    res.push({ ...element, isDataLeaf: isEmpty(children), children });
  }

  return res;
};
