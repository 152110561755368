import { type GroupBase, type StylesConfig } from 'react-select';
import { defaultTheme } from 'styles/theme-old';

export const getBgColor = ({
  isDisabled,
  isFocused,
  isSelected,
}: {
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}): string => {
  if (isSelected) {
    return defaultTheme.colors.blue10;
  } else if (isFocused) {
    return defaultTheme.colors.black20;
  } else if (isDisabled) {
    return defaultTheme.colors.black20;
  }

  return defaultTheme.colors.white;
};
const getColor = ({
  isDisabled,
  isFocused,
  isSelected,
}: {
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}): string => {
  if (isSelected) {
    return defaultTheme.colors.blue10;
  } else if (isFocused) {
    return defaultTheme.colors.black20;
  } else if (isDisabled) {
    return defaultTheme.colors.black70;
  }

  return defaultTheme.colors.black900;
};

export const getCommonStyles = <T, IsMulti extends boolean = boolean>(): StylesConfig<
  T,
  IsMulti,
  GroupBase<T>
> => ({
  control: (base, props) => ({
    ...base,
    background: getBgColor({ isDisabled: props.isDisabled }),
    cursor: 'pointer',
    fontWeight: defaultTheme.fontWeight.normal,
    borderRadius: defaultTheme.border.radius.md,
    border: `1px solid ${defaultTheme.border.color}`,
    boxShadow: defaultTheme.boxShadow[2],
    borderColor: getBgColor({
      isDisabled: props.isDisabled,
      isFocused: props.isFocused,
    }),

    ':hover': {
      borderColor: defaultTheme.colors.blue60,
    },

    ':focus-within': {
      borderColor: defaultTheme.colors.blue60,
    },
  }),

  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    width: 16,
    color: defaultTheme.colors.black300,
    '&:hover': {
      color: defaultTheme.colors.black300,
    },
  }),

  indicatorSeparator: () => ({}),

  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),

  clearIndicator: (base) => ({
    ...base,
    margin: '-8px 0',
  }),

  menu: (base) => ({
    ...base,
    borderRadius: defaultTheme.border.radius.md,
    boxShadow: defaultTheme.boxShadow[16],
    margin: `${defaultTheme.spacing[4]} 0`,
    zIndex: 9999,
  }),

  option: (base, props) => ({
    ...base,
    alignItems: 'center',
    color: getColor({
      isDisabled: props.isDisabled,
    }),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',

    background: getBgColor({
      isFocused: props.isFocused,
      isSelected: props.isSelected,
    }),

    padding: `0 ${defaultTheme.spacing[12]}`,

    a: {
      display: 'block',
      width: '100%',
      color: defaultTheme.colors.black900,
    },

    ':active': {
      background: defaultTheme.colors.blue10,
    },
  }),

  placeholder: (base) => ({
    ...base,
    color: defaultTheme.colors.textTertiary,
    margin: 0,
  }),

  singleValue: (base, props) => ({
    ...base,
    color: props.selectProps.menuIsOpen ? defaultTheme.colors.textTertiary : base.color,
    margin: 0,
    display: 'inline-flex',
  }),

  valueContainer: (base) => ({
    ...base,
    fontWeight: defaultTheme.fontWeight.normal,
    padding: 0,
    marginRight: defaultTheme.spacing[8],
  }),

  multiValue: (base) => ({
    ...base,
    marginTop: defaultTheme.spacing[4],
  }),
});
