import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';

export default {
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[16]};
    padding-bottom: ${({ theme }) => theme.spacing[32]};
  `,
  Label: styled.div`
    ${FontXs};

    color: ${({ theme }) => theme.colors.gray600};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  `,
  DescriptionWrapper: styled.div`
    ${FontS}

    padding-bottom: ${({ theme }) => theme.spacing[16]};
    color: ${({ theme }) => theme.colors.textBody1};
    line-height: ${({ theme }) => theme.lineHeight.s};
  `,
};
