import { QueryTableColumnDataType } from 'data/big-query';

export const isNumericBigQueryType = (type: QueryTableColumnDataType): boolean => {
  return [
    QueryTableColumnDataType.BigNumeric,
    QueryTableColumnDataType.Float,
    QueryTableColumnDataType.Integer,
    QueryTableColumnDataType.Numeric,
  ].includes(type);
};
