import { type RefObject, useEffect, useRef, useState, useCallback } from 'react';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { DataColumnDefaultWidth, IndexColumnWidth } from './use-grid-column-defs/utils/constants';

const isProdBuild = import.meta.env.PROD;

export const useDoesGridHaveScroll = (): {
  gridWrapperDomRef: RefObject<HTMLDivElement>;
  isHorizontalScrollPresent: boolean;
  gridWidth: number | null;
  inferGridContainerWidth: () => void;
} => {
  const listStore = useListStoreInstance();
  const config = useListStoreContext((s) => s.config);
  const isReadOnly = useListStoreContext((s) => s.isReadOnly);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const isDerivedList = useListStoreContext((s) => s.isDerivedList);

  const { columnWidthMap, embedPlace } = listStore.getState();

  const addColumnButtonWidth =
    embedPlace === 'report' ||
    isDerivedList ||
    !hasListAccess(accessAction, isReadOnly, config.backedByBQ).addColumn
      ? 1
      : 35;

  const gridWrapperDomRef = useRef<HTMLDivElement>(null);
  const isInitialRender = useRef(isProdBuild ? 0 : -1); // in dev mode useEffect run twice
  const [isHorizontalScrollPresent, setIsHorizontalScrollPresent] = useState(false);

  const [gridWidth, setGridWidth] = useState(() => {
    let widthSum = 0;

    const scrollBarWidth = 15;

    config.columnOrder.forEach((col) => {
      const width = columnWidthMap?.[col] || DataColumnDefaultWidth;

      widthSum += width;
    });

    widthSum += IndexColumnWidth + scrollBarWidth + addColumnButtonWidth;

    return widthSum;
  });

  const inferGridContainerWidth = useCallback(() => {
    if (embedPlace === 'report') {
      checkForHorizontalScrollBar();

      return null;
    }

    const scrollWidth =
      gridWrapperDomRef.current?.querySelector('.ag-header-container .ag-header-row')
        ?.scrollWidth ?? 0;
    const pinnedWidth =
      gridWrapperDomRef.current?.querySelector('.ag-pinned-left-header')?.scrollWidth ?? 0;

    const scrollBarWidth =
      gridWrapperDomRef.current?.querySelector(
        '.ag-body-vertical-scroll:not(.ag-hidden):not(.ag-scrollbar-invisible)',
      )?.scrollWidth ?? 0;

    setGridWidth(pinnedWidth + scrollWidth + scrollBarWidth + addColumnButtonWidth);
  }, [addColumnButtonWidth, embedPlace]);

  const checkForHorizontalScrollBar = () => {
    const isScrollPresent = !!gridWrapperDomRef.current?.querySelector(
      '.ag-body-horizontal-scroll:not(.ag-hidden):not(.ag-scrollbar-invisible)',
    );

    setIsHorizontalScrollPresent(isScrollPresent);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      checkForHorizontalScrollBar();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [gridWidth]);

  useEffect(() => {
    if (isInitialRender.current >= 1 && embedPlace === 'global-list') {
      queueMacroTask(() => inferGridContainerWidth());
    }
    isInitialRender.current += 1;
  }, [config.columnOrder.length, inferGridContainerWidth, embedPlace]);

  return {
    gridWrapperDomRef,
    isHorizontalScrollPresent,
    gridWidth,
    inferGridContainerWidth,
  };
};
