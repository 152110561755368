import { type EditorView } from '@codemirror/view';
import { Popover } from 'components/ui/atomic-components/popover';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { type Granularity } from 'data';
import { type ReactElement, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { convertTimeValueToExpression } from '../../../utils/convert-time-value-to-expression';
import { FormulaBarPopoverOverlayClass } from '../../constants';
import { TimeValueSelector } from '../dimensions-selector-menu/time-value-selector';
import { SubscriptTag } from './subscript-tag';
import { getTimeSubscriptDisplayValue } from './utils';

interface Props {
  timeValueSubscript: string;
  onSelect: (val: string) => void;
  readOnly?: boolean;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  timeFilterGranularity: Granularity;
}

export const TimeSubscriptTag = ({
  timeValueSubscript,
  onSelect,
  readOnly,
  customTimeAutoCompletionOptions,
  timeFilterGranularity,
}: Props): ReactElement => {
  const intl = useIntl();

  const [value, setValue] = useState(timeValueSubscript);
  const [open, setOpen] = useState(false);

  const customEditorView = useRef<EditorView | null>(null);

  const timeDisplayValue = useMemo(
    () => getTimeSubscriptDisplayValue(intl, timeValueSubscript, timeFilterGranularity),
    [intl, timeFilterGranularity, timeValueSubscript],
  );

  const handleChange = (newValue: string) => {
    if (!newValue) {
      setOpen(false);

      return;
    }
    setValue(newValue);
    onSelect(newValue);
    setOpen(false);
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);

    if (open) {
      return;
    }

    const value = customEditorView.current?.state.doc.toString();

    if (!value) {
      return;
    }

    const expr = convertTimeValueToExpression(value);

    setValue(expr);
    onSelect(expr);
  };

  if (readOnly) {
    return <div>{timeDisplayValue && <SubscriptTag label={timeDisplayValue} />}</div>;
  }

  return (
    <Popover
      content={
        <TimeValueSelector
          customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
          setCustomEditorView={(view) => (customEditorView.current = view)}
          timeFilterGranularity={timeFilterGranularity}
          value={value}
          onCustomValueEnter={handleChange}
          onItemSelect={handleChange}
        />
      }
      destroyTooltipOnHide
      open={open}
      overlayClassName={FormulaBarPopoverOverlayClass}
      placement="bottomLeft"
      onOpenChange={handleOpenChange}
    >
      <div>{timeDisplayValue && <SubscriptTag label={timeDisplayValue} />}</div>
    </Popover>
  );
};
