import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { type List } from 'data/modelling/lists';
import { DimensionGroupApi } from '../api';
import { constructListObjFromDimGroup } from '../utils';

export const useDimensionGroup = (dimensionGroupId?: number): UseQueryResult<List, unknown> => {
  const dimensionGroupQuery = useQuery(
    ['dimension-groups', dimensionGroupId],
    () => {
      if (!dimensionGroupId) {
        return {} as List;
      }

      return DimensionGroupApi.findRecord(dimensionGroupId).then((data) => {
        return constructListObjFromDimGroup(data);
      });
    },
    {
      staleTime: StaleTime.Shortest,
      enabled: !!dimensionGroupId,
    },
  );

  return dimensionGroupQuery;
};
