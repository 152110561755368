import { type ResourceUsageResp } from 'data/resource-usage/types';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { ResourceUsage } from './generic-resource-usage';
import { ResourceRenderMapping } from './resource-render-mapping';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing[20]};
  max-height: 300px;
  overflow: auto;
`;

interface Props {
  dependencies: ResourceUsageResp['dependencies'];
}

export const UsageList = ({ dependencies }: Props): ReactElement => {
  const dependenciesKeys = Object.keys(dependencies);

  return (
    <Wrapper>
      {dependenciesKeys.map((resource, idx) => {
        const resourceName = resource as keyof ResourceUsageResp['dependencies'];
        const mapping = ResourceRenderMapping[resourceName];

        if (!mapping) {
          return <></>;
        }

        return (
          <ResourceUsage
            key={resource}
            data={dependencies[resourceName]}
            icon={mapping.icon}
            isLastItem={idx === dependenciesKeys.length - 1}
            itemRenderer={mapping.itemRenderer}
            resourceDisplayLabel={mapping.resourceDisplayLabel}
          />
        );
      })}
    </Wrapper>
  );
};
