import { ReactComponent as MappingArrowIcon } from 'assets/ic_mapping-arrow.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type Currency } from 'data/currencies';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType, DisplayUnitType } from 'utils/data-formatter/types';
import { styles } from './styles';

const { SampleSection, SampleHeader, SampleBody, SampleNumber, DisplayedAsNumber, Caption } =
  styles;

export const Sample: FC<
  React.PropsWithChildren<{
    type: DataFormattingType;
    currency?: Currency;
    displayUnit: DisplayUnitType;
    digitsAfterDecimal: number;
  }>
> = ({ type, currency, displayUnit, digitsAfterDecimal }) => {
  const sample = 1234;
  const sampleForPercentageType = 0.1234;

  return (
    <SampleSection>
      <SampleHeader>
        <Caption>
          <FormattedMessage id="data_format.modal.sample" />
        </Caption>
        <Caption>
          <FormattedMessage id="data_format.modal.displayed_as" />
        </Caption>
      </SampleHeader>

      <SampleBody>
        <SampleNumber>
          {numberFormatter({
            value: type === DataFormattingType.Percentage ? sampleForPercentageType : sample,
            type,
            currency,
            displayUnit: DisplayUnitType.Full,
            digitsAfterDecimal: type === DataFormattingType.Percentage ? 4 : 0,
          })}
        </SampleNumber>
        <IconShell icon={MappingArrowIcon} size="lg" />
        <DisplayedAsNumber>
          {numberFormatter({
            value: type === DataFormattingType.Percentage ? sampleForPercentageType : sample,
            type,
            currency,
            displayUnit,
            digitsAfterDecimal,
          })}
        </DisplayedAsNumber>
      </SampleBody>
    </SampleSection>
  );
};
