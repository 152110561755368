import { ReactComponent as ChartPieIcon } from 'assets/v2/chart-pie-2.svg';
import { ReactComponent as CubeIcon } from 'assets/v2/cube.svg';
import { ReactComponent as DatabaseIcon } from 'assets/v2/database-12.svg';
import { ReactComponent as MenuIcon } from 'assets/v2/menu-2.svg';
import { ReactComponent as ReportIcon } from 'assets/v2/report-analytics.svg';
import { IconShell } from 'components/ui/atomic-components';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import {
  type ResourceUsageMetric,
  type ResourceUsageDependency,
  type ResourceUsageResp,
  type ResourceUsageVariable,
  type ResourceUsageList,
  type ResourceUsageReport,
  type ResourceUsageDimension,
  type ResourceUsageDataset,
} from 'data/resource-usage/types';
import { type ReactNode } from 'react';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { formatName } from 'utils/data-formatter';
import { MetricPill, VariablePill } from './pills';
import { UsagePillWrapper } from './pills/usage-pill-wrapper';

const IconCircle = styled.div<{ bgColor: string }>`
  width: 28px;
  height: 28px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
`;

interface RenderComponents {
  icon: ReactNode;
  resourceDisplayLabel: string;
  itemRenderer: (item: ResourceUsageDependency) => ReactNode;
}

export const ResourceRenderMapping: Record<
  keyof ResourceUsageResp['dependencies'],
  RenderComponents
> = {
  lists: {
    icon: (
      <IconCircle bgColor={defaultTheme.colors.yellow200}>
        <IconShell color="yellow600" icon={MenuIcon} />
      </IconCircle>
    ),
    resourceDisplayLabel: 'Lists',
    itemRenderer: (data) => {
      const item = data as ResourceUsageList;

      return (
        <UsagePillWrapper
          key={`${item.id}-${item.isDerived}`}
          navigateTo={
            item.isDerived ? `/lists/${DIM_GROUP_ID_PREFIX}${item.id}` : `/lists/${item.id}`
          }
        >
          {item.displayName}
        </UsagePillWrapper>
      );
    },
  },
  boards: {
    icon: (
      <IconCircle bgColor={defaultTheme.colors.aqua200}>
        <IconShell color="aqua600" icon={ReportIcon} />
      </IconCircle>
    ),
    resourceDisplayLabel: 'Reports',
    itemRenderer: (data) => {
      const item = data as ResourceUsageReport;

      return (
        <UsagePillWrapper key={item.id} navigateTo={`/reports/${item.id}`}>
          {item.name}
        </UsagePillWrapper>
      );
    },
  },
  metrics: {
    icon: (
      <IconCircle bgColor={defaultTheme.colors.primary100}>
        <IconShell color="primary600" icon={ChartPieIcon} />
      </IconCircle>
    ),
    resourceDisplayLabel: 'Metrics',
    itemRenderer: (item) => <MetricPill key={item.name} data={item as ResourceUsageMetric} />,
  },
  variables: {
    icon: (
      <IconCircle bgColor={defaultTheme.colors.green200}>
        <IconShell color="green600" icon={ChartPieIcon} />
      </IconCircle>
    ),
    resourceDisplayLabel: 'Variables',
    itemRenderer: (item) => <VariablePill key={item.name} data={item as ResourceUsageVariable} />,
  },
  dimensions: {
    icon: (
      <IconCircle bgColor={defaultTheme.colors.magenta200}>
        <IconShell color="magenta600" icon={CubeIcon} />
      </IconCircle>
    ),
    resourceDisplayLabel: 'Dimensions',
    itemRenderer: (data) => {
      const item = data as ResourceUsageDimension;

      return (
        <UsagePillWrapper key={item.name} navigateTo={`/dimensions/${item.id}`}>
          {formatName(item.name)}
        </UsagePillWrapper>
      );
    },
  },
  datasets: {
    icon: (
      <IconCircle bgColor={defaultTheme.colors.aqua200}>
        <IconShell color="aqua600" icon={DatabaseIcon} size="sm" />
      </IconCircle>
    ),
    resourceDisplayLabel: 'Datasets',
    itemRenderer: (data) => {
      const item = data as ResourceUsageDataset;

      return (
        <UsagePillWrapper key={item.name} navigateTo={`/data/datasets/${item.id}/${item.name}`}>
          {item.displayName}
        </UsagePillWrapper>
      );
    },
  },
};
