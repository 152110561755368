import { DatePicker } from 'components/ui/atomic-components';
import { DateTimeFormats } from 'config/constants';
import { type VisualQueryBasicFilterRuleValue } from 'data/big-query';
import dayjs, { type Dayjs } from 'dayjs';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DateSelectPopupClassName } from './constants';
import { type VisualRuleEditRenderCompParams } from './types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const BetweenDate: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  const onDateChange = (pos: number, args: Dayjs) => {
    const updatedData = [...(data || [[], []])] as [
      VisualQueryBasicFilterRuleValue,
      VisualQueryBasicFilterRuleValue,
    ];

    updatedData[pos] = { value: dayjs(args).format(DateTimeFormats.APIDate) };
    onChange(updatedData);
  };

  return (
    <Wrapper>
      <DatePicker
        rootClassName={DateSelectPopupClassName}
        value={data?.[0] ? dayjs(data?.[0]?.value) : null}
        onChange={(args) => args && onDateChange(0, args as Dayjs)}
      />

      <FormattedMessage id="and" />

      <DatePicker
        rootClassName={DateSelectPopupClassName}
        value={data?.[1] ? dayjs(data?.[1]?.value) : null}
        onChange={(args) => args && onDateChange(1, args as Dayjs)}
      />
    </Wrapper>
  );
};
