import { Layout as AntdLayout } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/ic_close.svg';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';
import { IconShell } from '../icon-shell';

/**
 * Brutally copy pasting from modal's header
 */
const SiderHeader: FC<React.PropsWithChildren<{ children: ReactNode; onClose(): void }>> = ({
  children,
  onClose,
}) => {
  return (
    <div className="ant-drawer-header">
      <div className="ant-drawer-header-title">
        <div className="ant-drawer-title">{children}</div>
        <button aria-label="Close" className="ant-drawer-close" type="button" onClick={onClose}>
          <span aria-label="close" className="anticon ant-modal-close-icon">
            <IconShell icon={CloseIcon} />
          </span>
        </button>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Layout: any = styled(AntdLayout)`
  background: transparent;
  flex-direction: row;

  &.ant-layout-has-sider {
    overflow: hidden;

    > .ant-layout-content {
      overflow-y: auto;
    }
  }

  .ant-layout-sider-children {
    border-left: 1px solid ${({ theme: { colors } }) => colors.red10};
    padding: 0 ${({ theme: { spacing } }) => spacing[8]};
    overflow: auto;
  }

  .ant-drawer-header {
    padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[8]}`};
    margin-bottom: ${({ theme: { spacing } }) => spacing[8]};
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

const Content = styled(Layout.Content)`
  scroll-behavior: smooth;
`;

Layout.Content = Content;

Layout.Sider.Header = SiderHeader;
