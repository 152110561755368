import axios from 'axios';
import { stringify } from 'query-string';
import { formatDimNames, formatName } from 'utils/data-formatter';
import { type ModelDimension } from '../dimension';
import {
  type SortConfigPayload,
  type ColumnValueItem,
  type DimTableDetailsResponse,
  type ModelDimensionChildren,
  type UploadedEntry,
} from './types';

const API_PATH = '/v1/dimensions';

export const ModelDimensionApi = {
  /**
   * Get all dimension
   */
  async findAll(): Promise<ModelDimension[]> {
    const { data } = await axios.get(API_PATH);

    formatDimNames(data);

    return data;
  },

  /**
   * Create dimension on the model
   */
  async createRecord(dimension: Partial<ModelDimension>): Promise<ModelDimension> {
    const { data } = await axios.post(API_PATH, dimension);

    formatDimNames(data && [data]);

    return data;
  },

  /**
   * Get a particular dimension
   */
  async findRecord(dimensionId: number): Promise<ModelDimension> {
    const { data } = await axios.get(`${API_PATH}/${dimensionId}`);

    formatDimNames(data && [data]);

    return data;
  },

  /**
   * Update the entire dimension
   */
  async save(dimension: ModelDimension): Promise<ModelDimension> {
    const { data } = await axios.post(`${API_PATH}/${dimension.id}`, dimension);

    formatDimNames(data && [data]);

    return data;
  },

  /**
   * Update partial dimension attributes
   */
  async updateRecord(
    dimension: Partial<ModelDimension>,
    activeDim: ModelDimension | undefined,
  ): Promise<ModelDimension> {
    const { data } = await axios.patch(`${API_PATH}/${activeDim?.id}`, dimension);

    formatDimNames(data && [data]);

    return data;
  },

  /**
   * Delete a particular dimension
   */
  async deleteRecord(dimension: ModelDimension): Promise<{ [key: string]: string }> {
    return axios.delete(`${API_PATH}/${dimension.id}`);
  },

  /**
   * @param dimensionName Name of the dimension
   * @param dimensionChild Node to be created
   * @returns A response data of ModelDimensionChildren
   */
  async createDimensionEntry(
    dimensionId: number,
    nodeInfo: { dimensionValue: Partial<ModelDimensionChildren>; position: number | null },
  ): Promise<ModelDimensionChildren> {
    const { data } = await axios.post(`${API_PATH}/${dimensionId}/values`, nodeInfo);

    return data;
  },

  /**
   * @param dimensionName Name of the dimension
   * @param dimensionChild Node to be created
   * @returns A response data of ModelDimensionChildren
   */
  async updateDimensionEntry(
    dimensionId: number,
    dimensionNodeValue: string,
    dimensionChild: Partial<ModelDimensionChildren>,
  ): Promise<ModelDimensionChildren> {
    const { data } = await axios.patch(
      `${API_PATH}/${dimensionId}/values/${dimensionNodeValue}`,
      dimensionChild,
    );

    return data;
  },

  /**

   * @param dimensionName Name of the dimension
   * @param dimensionNodeValue Cell ID of value which needs to be deleted
   * @returns A response data in the form of {'success': true}
   */
  async deleteDimensionEntry(
    dimensionId: number,
    dimensionNodeValue: string,
  ): Promise<{ [key: string]: string }> {
    return axios.delete(`${API_PATH}/${dimensionId}/values/${dimensionNodeValue}`);
  },

  async uploadDimensions(dimensionId: number, formData: FormData): Promise<UploadedEntry[]> {
    const { data } = await axios.post(`/v1/models/dimensions/${dimensionId}/upload`, formData);

    return data;
  },

  async search(
    queryString: string,
  ): Promise<{ value: string; label: string; parentSourceDisplayName: string }[]> {
    const { data } = await axios.get('/v1/search/dimensions', {
      params: { q: queryString },
      paramsSerializer: (params) => stringify(params),
    });

    return data.map(
      (item: {
        fullDimensionName: string;
        tableDisplayName: string;
        parentDisplayName: string;
      }) => ({
        value: item.fullDimensionName,
        label: formatName(item.fullDimensionName),
        parentSourceDisplayName: item.tableDisplayName || item.parentDisplayName,
      }),
    );
  },

  /**
   * Get the dataset/list table details for given dimensions
   */
  async getTableDetails(dimensionNames: string[]): Promise<DimTableDetailsResponse> {
    const { data } = await axios.post(`${API_PATH}/table-details`, dimensionNames);

    return data;
  },

  /**
   * Get all values corresponding to a dataset/list table column
   */
  async getColumnValues(colName: string): Promise<ColumnValueItem[]> {
    const { data } = await axios.get(`${API_PATH}/${colName}/values`);

    return data;
  },

  /**
   * Patch call to update config
   */
  async updateSortConfig(
    dimensionId: number,
    config: { sortOrderConfig?: SortConfigPayload; source?: string },
  ): Promise<ModelDimension> {
    const { data } = await axios.patch(`${API_PATH}/${dimensionId}`, config);

    return data;
  },

  /**
   * POST call for dimension access modification
   */
  async setAccessControl({
    dimension,
    isAccessControlled,
    roleIds,
  }: {
    dimension: ModelDimension;
    isAccessControlled?: boolean;
    roleIds?: number[];
  }): Promise<void> {
    const { data } = await axios.post(`${API_PATH}/${dimension.id}/control-access`, {
      name: dimension.name,
      accessControlled: isAccessControlled,
      fullAccessRoleIds: roleIds,
    });

    return data;
  },

  async getPotentialDimensions(name: string): Promise<{ columns: { name: string }[] }> {
    const { data } = await axios.get(`${API_PATH}/get-potential-dimensions`, {
      params: {
        query: name,
      },
    });

    return data;
  },
};
