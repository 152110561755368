import { forwardRef, type ReactElement, type Ref, type RefObject } from 'react';
import { type GroupBase, type Props } from 'react-select';
import ReactAsyncSelect, { type AsyncProps } from 'react-select/async';
import { MultiValueRemove } from './mult-value-remove';
import { type OptionBaseOld, SelectOption } from './option';
import { getSmallSizeStyles, getMediumSizeStyles, getLargeSizeStyles } from './styles';

export type SelectSizes = 'small' | 'medium';

type BaseSelectProps<T, IsMulti extends boolean> = Props<T, IsMulti, GroupBase<T>> & {
  ref?: RefObject<unknown>;
};

type AsyncSelectType<T, IsMulti extends boolean> = Props<T, IsMulti> &
  AsyncProps<T, IsMulti, GroupBase<T>> & { ref?: RefObject<unknown> };

export interface SelectProps<T, IsMulti extends boolean = false>
  extends BaseSelectProps<T, IsMulti> {
  size?: SelectSizes;
  width?: number;
}

export interface AsyncSelectProps<T, IsMulti extends boolean = false>
  extends AsyncSelectType<T, IsMulti> {
  size?: 'small' | 'medium' | 'large';
}

const AsyncSelectBase = <T extends OptionBaseOld, IsMulti extends boolean>(
  {
    classNamePrefix,
    size = 'small',
    components,
    width,
    styles,
    isSearchable = false,
    ...props
  }: SelectProps<T, IsMulti>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  const customStyles = {
    small: getSmallSizeStyles<T, IsMulti>(),
    medium: getMediumSizeStyles<T, IsMulti>(),
    large: getLargeSizeStyles<T, IsMulti>(),
  }[size];

  return (
    <ReactAsyncSelect<T, IsMulti>
      classNamePrefix={classNamePrefix || 'react-select'}
      components={{
        Option: SelectOption,
        MultiValueRemove,
        ...components,
      }}
      isSearchable={isSearchable}
      styles={{
        ...customStyles,
        ...styles,
      }}
      {...props}
      ref={ref}
    />
  );
};

export const AsyncSelect = forwardRef(AsyncSelectBase) as <
  T extends OptionBaseOld = OptionBaseOld,
  IsMulti extends boolean = false,
>(
  p: AsyncSelectProps<T, IsMulti> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
