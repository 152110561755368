import { forwardRef, type ReactElement, type Ref, type RefObject } from 'react';
import ReactSelect, { type GroupBase, type Props } from 'react-select';
import styled from 'styled-components';
import { MultiValueRemove } from './mult-value-remove';
import { type OptionBaseOld, SelectOption } from './option';
import { getSmallSizeStyles, getMediumSizeStyles, getLargeSizeStyles } from './styles';

export type SelectSizes = 'small' | 'medium' | 'large';

type BaseSelectProps<T, IsMulti extends boolean> = Props<T, IsMulti, GroupBase<T>> & {
  ref?: RefObject<unknown>;
};
export interface SelectPropsOld<T, IsMulti extends boolean = false>
  extends BaseSelectProps<T, IsMulti> {
  size?: SelectSizes;
  width?: number;
}

type ReactSelectProps = {
  width?: number;
  $size: SelectSizes;
};
const StyledReactSelect = styled.div<ReactSelectProps>`
  width: ${({ width }) => width}px;
`;

const SelectBase = <T extends OptionBaseOld, IsMulti extends boolean>(
  {
    classNamePrefix,
    size = 'medium',
    components,
    width,
    styles,
    isSearchable = false,
    ...props
  }: SelectPropsOld<T, IsMulti>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) => {
  const customStyles = {
    small: getSmallSizeStyles<T, IsMulti>(),
    medium: getMediumSizeStyles<T, IsMulti>(),
    large: getLargeSizeStyles<T, IsMulti>(),
  }[size];

  return (
    <StyledReactSelect $size={size} width={width}>
      <ReactSelect<T, IsMulti>
        classNamePrefix={classNamePrefix || 'react-select'}
        components={{
          Option: SelectOption,
          MultiValueRemove,
          ...components,
        }}
        isSearchable={isSearchable}
        styles={{
          ...customStyles,
          ...styles,
        }}
        {...props}
        ref={ref}
      />
    </StyledReactSelect>
  );
};

export const Select = forwardRef(SelectBase) as <
  T extends OptionBaseOld,
  IsMulti extends boolean = false,
>(
  p: SelectPropsOld<T, IsMulti> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
