import { type QueryTableColumnDataType } from 'data/big-query';
import { isNumericBigQueryType } from 'utils/big-query/is-numeric-big-query-type';
import { formatName, numberFormatter } from 'utils/data-formatter';
import { DataFormattingType, ZeroFormatType } from 'utils/data-formatter/types';

const blankLabel = '(Blank)';

export const areValuesEqual = ({
  columnTypeMap,
  column,
  oldValue,
  newValue,
}: {
  columnTypeMap: {
    [key: string]: QueryTableColumnDataType;
  };
  column: string;
  oldValue: string | number;
  newValue: string | number;
}): boolean => {
  if (!oldValue && !newValue) {
    return true;
  }

  if (isNumericBigQueryType(columnTypeMap[column])) {
    const oldNumericValue = Number(oldValue);
    const newNumericValue = Number(newValue);

    if (isNaN(oldNumericValue) || isNaN(newNumericValue)) {
      return oldValue === newValue;
    }

    return Math.abs(oldNumericValue - newNumericValue) < 0.001;
  }

  return oldValue === newValue;
};

export const formatUpdatedValue = ({
  columnTypeMap,
  column,
  value,
}: {
  columnTypeMap: {
    [key: string]: QueryTableColumnDataType;
  };
  column: string;
  value: string | number;
}): string => {
  if (!value && typeof value !== 'number') {
    return blankLabel;
  }

  if (isNumericBigQueryType(columnTypeMap[column])) {
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
      return String(numericValue);
    }

    return numberFormatter({
      value,
      type: DataFormattingType.Number,
      digitsAfterDecimal: Number.isInteger(numericValue) ? 0 : 2,
      zeroFormat: ZeroFormatType.Number,
    });
  }

  return formatName(value as string);
};
