import generatePicker from 'antd/es/date-picker/generatePicker';
import { type Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import styled from 'styled-components';
import { Caption } from 'styles/typography';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as any);

const { RangePicker } = DatePicker;

const DATE_ITEM_SIZE = 30;

const StyledRangePicker = styled(RangePicker)`
  border: 1px solid ${({ theme: { border } }) => border.color};
  border-radius: ${({ theme: { border } }) => border.radius.md};
  height: ${({ theme }) => `${theme.height.md}px`};

  .ant-picker-focused {
    border-color: ${({ theme }) => theme.colors.blue60};
  }

  & .ant-picker-input input {
    ${Caption}

    color: ${({ theme }) => theme.colors.black900};
    cursor: pointer;

    ::placeholder {
      color: ${({ theme }) => theme.colors.textTertiary};
      opacity: 1; /* Firefox */
    }
  }
`;

const DatePickerContainer = styled.div`
  & .ant-picker-date-panel .ant-picker-year-btn,
  .ant-picker-date-panel .ant-picker-month-btn {
    color: ${({ theme }) => theme.colors.black900};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: ${({ theme }) => theme.fontSize.h5};
  }

  & .ant-picker-content th {
    color: ${({ theme }) => theme.colors.black900};
    font-size: ${({ theme }) => theme.fontSize.paragraph};
  }

  & .ant-picker-content .ant-picker-cell-in-view .ant-picker-cell-inner {
    font-size: ${({ theme }) => theme.fontSize.paragraph};
    color: ${({ theme }) => theme.colors.black900};
    min-width: ${`${DATE_ITEM_SIZE}px`};
    height: ${`${DATE_ITEM_SIZE}px`};
    line-height: ${`${DATE_ITEM_SIZE}px`};
  }

  && .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: ${({ theme }) => theme.colors.blue70};
    color: ${({ theme }) => theme.colors.white};
    border-start-start-radius: 20px !important;
    border-end-start-radius: 20px !important;
    border-start-end-radius: 20px !important;
    border-end-end-radius: 20px !important;
    min-width: ${`${DATE_ITEM_SIZE}px`};
    height: ${`${DATE_ITEM_SIZE}px`};
    line-height: ${`${DATE_ITEM_SIZE}px`};
  }

  & .ant-picker-panel-container .ant-picker-presets ul li {
    margin: ${({ theme }) => theme.spacing['12']} 0;
  }
`;

export const styles = {
  StyledRangePicker,
  DatePickerContainer,
};
