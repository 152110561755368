import { useMutation } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { type ID } from 'data';
import {
  type IntegrationConnectedItem,
  type IntegrationModalContent,
  IntegrationProvider,
  IntegrationsApi,
} from 'data/integrations/fivetran';
import { useGetAllConnections } from 'data/integrations/fivetran/hooks/use-get-all-connections';
import { type FC, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { gsheetLogin, salesforceLogin, quickbooksLogin } from '../../common/connectors-auth';
import { getCallbackUrl } from '../../common/util';
import { ConnectorAuthContext } from './connector-auth-context';

const TokenExpiryMessage = 'Refresh token expired';

type ReauthEnabledConnector =
  | IntegrationProvider.GoogleSheets
  | IntegrationProvider.Salesforce
  | IntegrationProvider.Quickbooks
  | IntegrationProvider.SalesforceSandbox;

const loginActionLookup: {
  [key in ReauthEnabledConnector]: ({
    item,
    instanceId,
    isSandboxTenant,
  }: {
    item: IntegrationModalContent;
    instanceId?: number;
    isSandboxTenant?: boolean;
  }) => void;
} = {
  [IntegrationProvider.GoogleSheets]: gsheetLogin,
  [IntegrationProvider.Salesforce]: salesforceLogin,
  [IntegrationProvider.Quickbooks]: quickbooksLogin,
  [IntegrationProvider.SalesforceSandbox]: salesforceLogin,
};

export const ConnectorAuthContextProvider: FC<
  React.PropsWithChildren<{
    children: ReactNode;
    instance?: IntegrationConnectedItem;
  }>
> = ({ children, instance }) => {
  const [authError, setAuthError] = useState(false);

  const { instanceId: currentInstanceIdInUrl } = useParams() as { instanceId: string };
  const [{ code, service, realmId, reAuth }] = useQueryParams({
    code: StringParam,
    service: StringParam,
    realmId: StringParam,
    reAuth: StringParam,
  });

  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();

  const { data } = useGetAllConnections();

  const relogin = useCallback(() => {
    const connectorItem = (data || []).find((item) => item.name === instance?.name);

    if (!connectorItem) {
      return;
    }

    const login = loginActionLookup[instance?.name as ReauthEnabledConnector];

    if (login) {
      login({
        item: connectorItem,
        instanceId: instance?.id,
        isSandboxTenant: false,
      });
    }
  }, [data, instance?.id, instance?.name]);

  const handleError = useCallback(
    (errorResp: AxiosError, errorInstanceId: ID) => {
      if (String(errorInstanceId) !== String(currentInstanceIdInUrl)) {
        return;
      }

      if ((errorResp?.response?.data as { message: string })?.message === TokenExpiryMessage) {
        setAuthError(true);
      } else {
        defaultApiErrorHandler(errorResp);
      }
    },
    [currentInstanceIdInUrl],
  );

  const { mutate: reAuthorize, isLoading: reAuthorizing } = useMutation(
    ({
      code,
      redirectUrl,
      realmId,
    }: {
      code: string;
      redirectUrl: string;
      realmId?: string | null;
    }) => IntegrationsApi.reAuthorize(instance?.id as ID, code, redirectUrl, realmId),
    {
      onError: defaultApiErrorHandler,
      onSettled: () => {
        navigate(currentPath);
        setAuthError(false);
      },
    },
  );

  useEffect(() => {
    if (code && service && reAuth) {
      setAuthError(true);
      reAuthorize({ code, redirectUrl: getCallbackUrl(), realmId });
    }
  }, [code, reAuth, reAuthorize, realmId, service]);

  const contextParams = useMemo(
    () => ({
      handleError,
      errorModalOpen: authError,
      closeModal: () => setAuthError(false),
      relogin,
      reAuthorizing,
    }),
    [authError, handleError, relogin, reAuthorizing],
  );

  return (
    <ConnectorAuthContext.Provider value={contextParams}>{children}</ConnectorAuthContext.Provider>
  );
};
