import { type PropsWithChildren, type ReactElement } from 'react';
import styled from 'styled-components';
import { type defaultTheme } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

const BorderHighlight = styled.div<{ color: keyof typeof defaultTheme.colors }>`
  width: 6px;
  background-color: ${({ theme, color }) => theme.colors[color]};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
`;

const Content = styled.div`
  width: 100%;
  padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[12]}`};
`;

interface Props {
  color?: keyof typeof defaultTheme.colors;
}

export const CardHighlight = ({
  color = 'yellow200',
  children,
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <Wrapper>
      <BorderHighlight color={color} />
      <Content>{children}</Content>
    </Wrapper>
  );
};
