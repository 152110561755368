import { css } from 'styled-components';
import { FontM } from 'styles/typography';

export const ModalStyles = css`
  .ant-modal-mask {
    animation-timing-function: ease;
  }

  .ant-modal-root {
    .ant-modal-wrap {
      left: 72px;
    }

    .ant-modal-mask {
      background-color: rgb(17 24 39 / 8%);
    }
  }

  .ant-drawer .ant-drawer-mask {
    background-color: rgb(17 24 39 / 8%);
  }

  .ant-modal {
    max-width: calc(100vw - 72px);
    transform-origin: center !important;
    animation-timing-function: ease;

    .ant-modal-body {
      padding: ${({ theme }) => theme.spacing[16]};
    }

    .ant-modal-content {
      padding: 0;

      .ant-modal-close {
        top: ${({ theme }) => theme.spacing[12]};
        right: ${({ theme }) => theme.spacing[16]};
        width: 24px;
        height: 24px;

        .ant-modal-close-x {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .ant-modal-header {
        padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};
        border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
        margin-bottom: 0;

        .ant-modal-title {
          ${FontM};

          display: flex;
          align-items: center;
          gap: ${({ theme }) => theme.spacing[8]};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
          color: ${({ theme }) => theme.colors.textTitle2};
        }
      }

      .ant-modal-body {
        ${FontM};

        color: ${({ theme }) => theme.colors.textBody1};
      }

      .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 0;
        border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
        padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};

        .ant-btn + .ant-btn {
          margin-inline-start: 0;
        }
      }
    }

    &.ant-zoom-enter.ant-zoom-enter-active,
    &.ant-zoom-appear.ant-zoom-appear-active {
      animation-name: zoom-in;
    }

    &.ant-zoom-leave.ant-zoom-leave-active {
      animation-name: zoom-out;
    }

    @keyframes zoom-in {
      0% {
        transform: scale(0.97);
        opacity: 0;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @keyframes zoom-out {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(0.97);
        opacity: 0;
      }
    }
  }
`;
