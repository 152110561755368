import { type MetricApplicableDimension, DimensionAllMarker } from 'data/dimension';
import { isEmpty } from 'lodash';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { formatName } from 'utils/data-formatter';
import { MenuItem } from './menu-item';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  max-height: 175px;
  overflow: auto;
`;

interface Props {
  options: MetricApplicableDimension[];
  selectionsMap: Record<string, string[]>;
  onClick?: (dimension: MetricApplicableDimension) => void;
}

export const DimensionOptions = ({ options, selectionsMap, onClick }: Props): ReactElement => {
  const intl = useIntl();

  return (
    <Wrapper>
      {options.map((dim) => {
        const selectedDimensionDisplayValue =
          isEmpty(selectionsMap[dim.name]) || selectionsMap[dim.name][0] === DimensionAllMarker
            ? intl.formatMessage({ id: 'all' })
            : intl.formatMessage(
                { id: 'count_selected' },
                { count: selectionsMap[dim.name].length },
              );

        return (
          <MenuItem
            key={dim.name}
            label={formatName(dim.name)}
            secondaryLabel={selectedDimensionDisplayValue}
            onClick={() => onClick?.(dim)}
          />
        );
      })}
    </Wrapper>
  );
};
