import styled from 'styled-components';
import { FontM } from 'styles/typography';

export const styles = {
  Title: styled.div`
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 0;
  `,

  Description: styled.div`
    ${FontM};

    padding-top: ${({ theme }) => theme.spacing[16]};
  `,

  SecondaryTitle: styled.div`
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding-top: ${({ theme }) => theme.spacing[24]};
  `,

  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing[48]};
    width: 100%;
    height: 100%;
  `,

  SubContainer: styled.div`
    height: 100%;
    max-width: 600px;
  `,

  EmphasizedContainer: styled.div`
    background: ${({ theme }) => theme.colors.gray100};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    padding: ${({ theme }) => theme.spacing[16]};
  `,
};
