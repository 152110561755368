import { type ReactElement } from 'react';
import { GlobalStylesV1 } from './global-styles-v1';
import { GlobalStylesV2 } from './global-styles-v2';

export const GlobalStylesWrapper = (): ReactElement => {
  return (
    <>
      <GlobalStylesV1 />

      <GlobalStylesV2 />
    </>
  );
};
