const {
  REACT_APP_AG_GRID_LICENSE = '',
  REACT_APP_FEATURE_FLAG_API_KEY = '',
  REACT_APP_HOTGLUE_API_KEY = '',
  REACT_APP_HOTGLUE_ENV_ID = '',
  REACT_APP_DEPLOY_ENV = 'development',
  REACT_APP_DISABLE_QUERY_DEV_TOOLS,
  REACT_APP_FINCH_INTEGRATION_CLIENT_ID = '',
  REACT_APP_PUBLIC_URL = '',
  REACT_APP_PUSHER_API_KEY = '',
  REACT_APP_PUSHER_CLUSTER_NAME = '',
} = import.meta.env;

export const AG_GRID_LICENSE = REACT_APP_AG_GRID_LICENSE.trim();
export const FEATURE_FLAG_API_KEY = REACT_APP_FEATURE_FLAG_API_KEY.trim();
export const HOTGLUE_API_KEY = REACT_APP_HOTGLUE_API_KEY.trim();
export const HOTGLUE_ENV_ID = REACT_APP_HOTGLUE_ENV_ID.trim();
export const DEPLOY_ENV = REACT_APP_DEPLOY_ENV.trim();
export const DISABLE_REACT_QUERY_DEV_TOOLS = REACT_APP_DISABLE_QUERY_DEV_TOOLS === 'true';
export const FINCH_INTEGRATION_CLIENT_ID = REACT_APP_FINCH_INTEGRATION_CLIENT_ID.trim();
export const PUBLIC_URL = REACT_APP_PUBLIC_URL.replace(/\/$/, '');
export const PUSHER_API_KEY = REACT_APP_PUSHER_API_KEY.trim();
export const PUSHER_CLUSTER_NAME = REACT_APP_PUSHER_CLUSTER_NAME.trim();
