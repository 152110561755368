import styled from 'styled-components';
import { FontXs } from 'styles/typography';

export const styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    ${FontXs};

    display: flex;
    padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[12]}`};
    padding-bottom: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.colors.gray600};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  SearchBoxWrapper: styled.div`
    padding: ${({ theme }) => theme.spacing[8]};
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
    padding: ${({ theme }) => theme.spacing[6]} 0;
  `,
};
