import { type ListsStoreApi } from 'store/lists/store';
import { type StoreApi } from 'zustand';
import { type UndoCellValueChangeMeta } from '../types';

interface Props {
  meta: UndoCellValueChangeMeta;
  listStore: StoreApi<ListsStoreApi>;
  type: 'undo' | 'redo';
}
export const undoCellChange = ({ meta, listStore, type }: Props): void => {
  const { gridApi, startUndoOperation, startRedoOperation } = listStore.getState();

  const { rowId, column, value } = meta;

  if (!rowId) {
    return;
  }

  const currentRow = gridApi?.getRowNode(rowId);

  if (!currentRow) {
    return;
  }

  const rowIndex = currentRow.rowIndex as number;

  gridApi?.ensureIndexVisible(rowIndex);
  gridApi?.ensureColumnVisible(column);
  gridApi?.clearRangeSelection();
  gridApi?.setFocusedCell(rowIndex, column);

  if (type === 'undo') {
    startUndoOperation();
  } else {
    startRedoOperation();
  }

  currentRow.setDataValue(column, value);
};
