import { Currency } from 'data/currencies';

export const customCurrencySymbolFormatter = (
  formattedValue: string,
  currency: Currency,
): string => {
  if (currency === Currency.CAD) {
    return formattedValue.replace('A', '');
  }

  if (currency === Currency.SGD) {
    return `S${formattedValue}`;
  }

  return formattedValue;
};
