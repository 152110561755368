import { defaultKeymap } from '@codemirror/commands';
import { type Extension } from '@codemirror/state';
import { type EditorView, keymap as codemirrorKeymap } from '@codemirror/view';

export const keymap = ({
  onPressEnter,
}: {
  onPressEnter?: (value?: string) => void;
}): Extension => {
  const customKeymap = [...defaultKeymap];

  if (onPressEnter) {
    customKeymap.unshift({
      key: 'Enter',
      run: (view: EditorView) => {
        onPressEnter(view.state.doc.toString());

        return true;
      },
    });
  }

  return codemirrorKeymap.of(customKeymap);
};
