import { AccessAction } from 'data/roles/permissions';

export const hasListAccess = (
  accessAction: AccessAction,
  isReadOnly: boolean,
  backedByBQ?: boolean,
): {
  uploadCsv: boolean;
  addColumn: boolean;
  deleteList: boolean;
  topOverflowMenu: boolean;
  uniqueKeyConfigure: boolean;
  columnOverflowMenu: boolean;
  editGrid: boolean;
  selectRows: boolean;
  addNewRow: boolean;
  reorderRows: boolean;
  reorderColumns: boolean;
} => {
  if (isReadOnly || accessAction === AccessAction.ReadAccess) {
    return {
      uploadCsv: false,
      addColumn: false,
      deleteList: false,
      topOverflowMenu: false,
      uniqueKeyConfigure: false,
      columnOverflowMenu: false,
      editGrid: false,
      selectRows: false,
      addNewRow: false,
      reorderRows: false,
      reorderColumns: false,
    };
  }

  if (backedByBQ) {
    return {
      uploadCsv: false,
      addColumn: false,
      deleteList: false,
      topOverflowMenu: false,
      uniqueKeyConfigure: accessAction === AccessAction.FullAccess,
      columnOverflowMenu: accessAction === AccessAction.FullAccess,
      editGrid: true,
      selectRows: false,
      addNewRow: false,
      reorderRows: false,
      reorderColumns: false,
    };
  }

  if (accessAction === AccessAction.ContributorAccess) {
    return {
      uploadCsv: false,
      addColumn: false,
      deleteList: false,
      topOverflowMenu: false,
      uniqueKeyConfigure: false,
      columnOverflowMenu: false,
      editGrid: true,
      selectRows: true,
      addNewRow: true,
      reorderRows: true,
      reorderColumns: false,
    };
  }

  return {
    uploadCsv: true,
    addColumn: true,
    deleteList: true,
    topOverflowMenu: true,
    uniqueKeyConfigure: true,
    columnOverflowMenu: true,
    editGrid: true,
    selectRows: true,
    addNewRow: true,
    reorderRows: true,
    reorderColumns: true,
  };
};
