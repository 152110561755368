import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { Button, IconShell, NoMatch } from 'components/ui/atomic-components';
import {
  type IntegrationModalContent,
  type IntegrationConnectionItem,
} from 'data/integrations/fivetran';
import { keyBy } from 'lodash';
import { useMemo, useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchBlock } from '../search-block';
import { getOptionsFromData } from '../util';
import { CategoriesSelector } from './categories-selector';
import { ConnectorsList } from './connectors-list';
import { styles } from './styles';

const { LeftNavSection, AllConnectorsWrapper, RightNavSection, PopoverTitle } = styles;

export const AllConnectorsList = ({
  setOpen,
  items,
  mergeIntegrationIdRef,
  setActiveItem,
  setMergeToken,
  setShowPopupModal,
}: {
  setOpen: (open: boolean) => void;
  items: IntegrationConnectionItem[];
  mergeIntegrationIdRef: React.MutableRefObject<string>;
  setActiveItem: React.Dispatch<React.SetStateAction<IntegrationModalContent | null>>;
  setMergeToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowPopupModal: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const intl = useIntl();
  const allDatasourceKey = intl.formatMessage({
    id: 'integrations.popup.categories.all_datasource',
  });
  const options = getOptionsFromData(items);

  const [selectedCategory, setSelectedCategory] = useState<string>(allDatasourceKey);
  const [searchText, setSearchText] = useState('');

  const categorisedItems = useMemo(() => {
    const filteredItems = items.filter((item) =>
      item.displayName.toLowerCase().includes(searchText.toLowerCase()),
    );
    const categories = Object.keys(keyBy(filteredItems, 'category'));

    return categories.map((c) => {
      return {
        category: c,
        items: filteredItems.filter((i) => i.category === c),
      };
    });
  }, [items, searchText]);

  const categoriesList = [allDatasourceKey, ...categorisedItems.map((item) => item.category)];

  const onCategoryChange = (category: string) => {
    setSelectedCategory(category);
    const parent = document.getElementById('connectors-list');
    const child = document.getElementById(category);
    const yShift = child ? child.offsetTop - 150 : 0;

    parent?.scrollTo({
      top: yShift,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <PopoverTitle>
        <FormattedMessage id="integrations.popup.title" />
        <Button
          icon={<IconShell color="textBody2" icon={CloseIcon} size="md" />}
          size="small"
          type="text"
          onClick={() => setOpen(false)}
        />
      </PopoverTitle>
      <AllConnectorsWrapper>
        <LeftNavSection>
          <CategoriesSelector
            categories={categoriesList}
            selectedKey={selectedCategory}
            setSelectedCategory={onCategoryChange}
          />
        </LeftNavSection>
        <RightNavSection>
          <SearchBlock searchText={searchText} onSearchTextChange={setSearchText} />
          {categorisedItems.length === 0 && <NoMatch searchText={searchText} />}
          {categorisedItems.length > 0 && (
            <ConnectorsList
              categorisedItems={categorisedItems}
              mergeIntegrationIdRef={mergeIntegrationIdRef}
              options={options}
              searchText={searchText}
              setActiveItem={setActiveItem}
              setMergeToken={setMergeToken}
              setShowPopupModal={setShowPopupModal}
            />
          )}
        </RightNavSection>
      </AllConnectorsWrapper>
    </>
  );
};
