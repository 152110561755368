import { type SegmentedLabeledOption, type SegmentedValue } from 'antd/es/segmented';
import { type ReactElement } from 'react';
import { styles } from './styles';

const { Unit, UnitLabel, StyledSegmented } = styles;

export const ValueFormatUnit = <T extends SegmentedLabeledOption>({
  options,
  currentValue,
  title,
  onChange,
}: {
  options: T[];
  currentValue: SegmentedValue;
  title: string;
  onChange: (val: SegmentedValue) => void;
}): ReactElement => {
  return (
    <Unit>
      <UnitLabel>{title}</UnitLabel>

      <StyledSegmented
        options={options}
        type="secondary"
        value={currentValue}
        onChange={onChange}
      />
    </Unit>
  );
};
