import { ReactComponent as ChevronDownIcon } from 'assets/v2/chevron-down.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type IntegrationConnectionItem } from 'data/integrations/fivetran';
import { useGetConnectedIntegrations } from 'data/integrations/fivetran/hooks';
import { type FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntegrationGridItem } from './grid-item';
import { GridStyles } from './styles';

const { GridWrapper, GridHeader, ItemsWrapper, StyledButton } = GridStyles;

export const GridSection: FC<
  React.PropsWithChildren<{
    header: string;
    items: IntegrationConnectionItem[];
    onActivateItem(item: IntegrationConnectionItem): void;
    searchTerm: string;
  }>
> = ({ items, header, onActivateItem, searchTerm }) => {
  const [showAll, setShowAll] = useState(false);
  const { data: connectedItems } = useGetConnectedIntegrations();

  const intl = useIntl();

  const getCount = useCallback(
    (item: IntegrationConnectionItem) => {
      const count = connectedItems?.filter((c) => c.name === item.name).length;

      return count;
    },
    [connectedItems],
  );

  return (
    <GridWrapper>
      <GridHeader id={header}>{header}</GridHeader>
      <ItemsWrapper>
        {(showAll ? items : items.slice(0, 8))
          .filter((i) => i.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((item) => {
            return (
              <IntegrationGridItem
                key={`${item.id}-${item.name}`}
                count={getCount(item)}
                item={item}
                onActivateItem={onActivateItem}
              />
            );
          })}
      </ItemsWrapper>
      {!showAll && items.length > 8 && (
        <StyledButton size="small" type="text" onClick={() => setShowAll(true)}>
          {intl.formatMessage(
            { id: 'integrations.all_connectors.show_all' },
            { count: items.length - 8 },
          )}
          <IconShell icon={ChevronDownIcon} />
        </StyledButton>
      )}
    </GridWrapper>
  );
};
