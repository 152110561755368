import { Alert } from 'components/ui/atomic-components';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { DuplicateValuesAlertContent } from './duplicate-values-alert-content';
import { DimensionGroupGrid } from './grid';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const GridWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  max-height: 200px;
`;

interface Props {
  data: DimensionGroup;
}

export const DuplicateRowsData = ({ data }: Props): ReactElement => {
  const [showGrid, setShowGrid] = useState(false);

  const gridHeight = ((data.rows?.length ?? 0) + 1) * 32 + 1;

  return (
    <Wrapper>
      <Alert
        closable={false}
        message={
          <DuplicateValuesAlertContent
            showGrid={showGrid}
            onToggle={() => setShowGrid((state) => !state)}
          />
        }
        showIcon
        type="error"
      />
      {showGrid && (
        <GridWrapper height={gridHeight}>
          <DimensionGroupGrid dimensionGroup={data} />
        </GridWrapper>
      )}
    </Wrapper>
  );
};
