import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { FormConnectorStyles } from '../styles';

const { LeftPanel, Title, Description, ImageContainer } = FormConnectorStyles;

export const PipeDriveInfoContent = ({ item }: { item: IntegrationModalContent }): ReactElement => {
  return (
    <LeftPanel>
      <ImageContainer alt="" src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)} />
      <Title>{item.displayName}</Title>
      <Description>
        <FormattedMessage id="integrations.pipedrive.modal.desc" />
      </Description>
      <CommonContent {...item} />
    </LeftPanel>
  );
};
