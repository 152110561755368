import styled from 'styled-components';
import { FontXs } from 'styles/typography';

export const WrapperStyles = {
  TopText: styled.span`
    ${FontXs};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.gray600};
  `,
};
