import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { Button, Empty, IconShell } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';
import { IntegrationsPopover } from '../connected-integrations-page-v2/left-nav-section/integrations-popover';

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const StyledEmpty = styled(Empty)`
  .ant-empty-footer {
    margin-top: ${({ theme }) => theme.spacing[0]};
  }
`;

export const EmptyDataPage = (): ReactElement => {
  const [showAddDataPopup, setShowAddDataPopup] = useState(false);

  const title = <FormattedMessage id="integrations.no_data.title" />;
  const description = <FormattedMessage id="integrations.no_data.description" />;

  return (
    <Wrapper>
      <StyledEmpty
        description={description}
        image={<img alt="no datasets" src={getAssetUrl('no-dataset.png')} />}
        title={title}
      >
        <IntegrationsPopover open={showAddDataPopup} setOpen={(v) => setShowAddDataPopup(v)}>
          <Button
            data-testid="@data/add-data-source"
            icon={<IconShell icon={PlusIcon} />}
            type="primary"
            onClick={() => setShowAddDataPopup(true)}
          >
            <FormattedMessage id="integrations.no_data.add_data_source" />
          </Button>
        </IntegrationsPopover>
      </StyledEmpty>
    </Wrapper>
  );
};
