import styled from 'styled-components';
import { FontS, FontXs } from 'styles/typography';

export const AccessControlWidgetStyles = {
  Label: styled.div`
    ${FontXs};

    color: ${({ theme }) => theme.colors.textBody2};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding-bottom: ${({ theme }) => theme.spacing[4]};
  `,

  List: styled.div`
    overflow: auto;
    width: 100%;
    height: 100%;
    padding-top: ${({ theme }) => theme.spacing[16]};
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing[16]};
  `,

  ItemWrapper: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[8]};
  `,

  ValueText: styled.span`
    ${FontS};

    color: ${({ theme }) => theme.colors.textDisabled};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  ButtonTextWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[16]};
  `,
};
