import { Popover } from 'components/ui/atomic-components';
import { type ReactElement, type ReactNode } from 'react';
import { AllConnectorsPage } from '../../all-connectors-page';

export const IntegrationsPopover = ({
  children,
  open,
  setOpen,
}: {
  children?: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}): ReactElement => {
  return (
    <Popover
      content={<AllConnectorsPage isParentOpen={open} setOpen={setOpen} />}
      open={open}
      type={'full-width'}
    >
      {children}
    </Popover>
  );
};
