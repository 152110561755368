import { useSelectedColumnValues } from 'data/modelling/dimension';
import { ListDataValidationCondition, type ListConfig, type ListRow } from 'data/modelling/lists';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { type TextOptionType } from './types';
import { generateOptions } from './utils';

interface Props {
  config: ListConfig;
  listRows: ListRow[];
  colId: string;
  cellValue?: string;
}

export const useGenerateOptions = ({
  colId,
  config,
  cellValue,
  listRows,
}: Props): { isLoading: boolean; options: TextOptionType[] } => {
  const intl = useIntl();

  const columnHasExistingColumnValidation =
    config.dataValidation?.[colId]?.condition === ListDataValidationCondition.ExistingColumn;

  const { data: permissibleOptions = [], isLoading } = useSelectedColumnValues(
    columnHasExistingColumnValidation ? config.dataValidation?.[colId]?.source : undefined,
  );

  return useMemo(() => {
    if (config.uniqueKey === colId) {
      return { isLoading: false, options: [] };
    }

    if (columnHasExistingColumnValidation) {
      const options = permissibleOptions.map((option) => ({
        value: option.value,
        label: option.value || intl.formatMessage({ id: 'dimensions.blank' }),
      }));

      return { isLoading, options };
    }

    return { isLoading: false, options: generateOptions(listRows, colId, cellValue) };
  }, [
    cellValue,
    colId,
    columnHasExistingColumnValidation,
    config.uniqueKey,
    intl,
    isLoading,
    listRows,
    permissibleOptions,
  ]);
};
