import { InputNumber } from 'components/ui/atomic-components';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { type VisualRuleEditRenderCompParams } from './types';

const StyledInput = styled(InputNumber)`
  width: 100%;
`;

export const NumberInput: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  const intl = useIntl();

  const onInputChange = (value: string | number | null) => {
    onChange([
      {
        value: value as string,
      },
    ]);
  };

  return (
    <StyledInput
      placeholder={intl.formatMessage({ id: 'edit_actuals.visual.value_field_placeholder' })}
      type="number"
      value={data?.[0]?.value}
      onChange={onInputChange}
    />
  );
};
