import { type MetricTreeNodeTypes } from 'data/metrics';
import { type VaryingColGroup } from 'data/page-template/grid';
import { type HierarchyClanNode } from 'utils/tree-table';

export interface RawData {
  [key: string]: string | number | null;
}

export interface Frame {
  rows: FrameElement[];
  columns: FrameElement[];
}

export enum FrameType {
  Time = 'TIME',
  Metric = 'METRIC',
  Version = 'VERSION',
  Scenario = 'SCENARIO',
  Dimension = 'DIMENSION',
  Blank = 'BLANK',
  Section = 'SECTION',
  SectionMetric = 'SECTION_METRIC',
  Statistic = 'STATISTIC',
}

export interface FrameElement {
  key: string;
  type: FrameType;
  period?: string;
  dimensionName: string;
  displayName: string;
  isDataLeaf?: boolean;
  statistic?: VaryingColGroup;
  children: FrameElement[];
  isMasked: boolean;
}

export interface TableRowData {
  dataLookupMap: Record<string, number | null>;
  hierarchy: string[];
  hierarchyClan: HierarchyClanNode[];
  name: string;
  displayValue: string;
  isLeaf: boolean;
  isDataLeaf: boolean;
  isMasked: boolean;
  value: string;
  viewNodeType: MetricTreeNodeTypes | FrameType;
  metricInHierarchy: string | null;
  rowDimensions: Record<string, string>;
  indexHierarchy: number[];
  combineColumnHeaderFromIndex?: number;
}
