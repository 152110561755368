import { type CellValueChangedEvent, type RowClassParams } from 'ag-grid-community';
import { type CSVListPreviewData, type CSVPreviewRowItem } from 'data/lists';
import { isEmpty } from 'lodash';
import { type FC } from 'react';
import { PreviewTableContext, type PreviewTableContextParams } from './context';
import { usePreviewGridColDefs } from './hooks/use-preview-grid-column-defs';
import { styles } from './styles';

const { Grid, GridWrapper } = styles;

const ROW_HEIGHT = 32;

export const PreviewDataGrid: FC<
  React.PropsWithChildren<{
    data: CSVPreviewRowItem[];
    columnNames: string[];
    colTypeMap?: CSVListPreviewData['columnTypeMap'];
    dateFormatMap?: CSVListPreviewData['dateFormatMap'];
    isColumnTypeEditMode: boolean;
    onCellEdit: (rowId: number, propName: string, newVal: string) => void;
    updatePropertyTypeMap: PreviewTableContextParams['updatePropertyTypeMap'];
  }>
> = ({
  data,
  columnNames,
  isColumnTypeEditMode,
  colTypeMap,
  dateFormatMap,
  onCellEdit,
  updatePropertyTypeMap,
}) => {
  const contextValues = {
    colTypeMap,
    dateFormatMap,
    isColumnTypeEditMode,
    updatePropertyTypeMap,
  };

  const columnDefs = usePreviewGridColDefs({
    columnNames,
    colTypeMap,
  });

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    const propName = (params.colDef?.field || '').split('.')?.[1];

    onCellEdit(params?.data?.rowId, propName, params.newValue);
    params.node.setData({
      ...params.data,
      edits: {
        ...(params.data?.edits || {}),
        [propName]: params.oldValue,
      },
    });
  };

  const getRowClass = (params: RowClassParams) => {
    if (isColumnTypeEditMode) {
      return 'row-edits-disabled';
    }

    if (!isEmpty(params.node?.data?.errors)) {
      return 'row-with-error';
    }
  };

  return (
    <GridWrapper>
      <PreviewTableContext.Provider value={contextValues}>
        <Grid
          columnDefs={columnDefs}
          getRowClass={getRowClass}
          headerHeight={isColumnTypeEditMode ? 100 : 32}
          rowData={data}
          rowHeight={ROW_HEIGHT}
          stopEditingWhenCellsLoseFocus
          suppressContextMenu
          suppressRowHoverHighlight
          onCellValueChanged={onCellValueChanged}
        />
      </PreviewTableContext.Provider>
    </GridWrapper>
  );
};
