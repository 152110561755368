/* eslint-disable filenames/match-regex */
import { defaultTheme as oldTheme } from './theme-old';

const primitivesColors = {
  gray00: '#ffffff',
  gray50: '#f9fafb',
  gray100: '#f3f4f6',
  gray200: '#e5e7eb',
  gray300: '#d1d5db',
  gray400: '#9ca3af',
  gray500: '#6b7280',
  gray600: '#4b5563',
  gray700: '#374151',
  gray800: '#1f2937',
  gray900: '#111827',

  primary50: '#f6f4ff',
  primary100: '#dedaff',
  primary200: '#beb5ff',
  primary300: '#9d8fff',
  primary400: '#7d6aff',
  primary500: '#5c45ff',
  primary600: '#4a37cc',
  primary700: '#372999',
  primary800: '#251c66',
  primary900: '#120e33',

  red100: '#fee2e2',
  red200: '#fca5a5',
  red300: '#f87171',
  red400: '#ef4444',
  red500: '#dc2626',
  red600: '#b91c1c',
  red700: '#991b1b',

  yellow100: '#fefbed',
  yellow200: '#fdedb7',
  yellow300: '#fbe081',
  yellow400: '#f9d34b',
  yellow500: '#c7a93c',
  yellow600: '#957f2d',
  yellow700: '#64541e',

  green100: '#f1fbf2',
  green200: '#c7efcb',
  green300: '#9ee3a4',
  green400: '#74d77d',
  green500: '#5dac64',
  green600: '#46814b',
  green700: '#2e5632',

  magenta100: '#f8edfa',
  magenta200: '#eac8f1',
  magenta300: '#d491e3',
  magenta400: '#b847d1',
  magenta500: '#9339a7',
  magenta600: '#6e2b7d',
  magenta700: '#5c2469',

  orange100: '#fef4ef',
  orange200: '#fbd2be',
  orange300: '#f8b08d',
  orange400: '#f58e5c',
  orange500: '#c4724a',
  orange600: '#935537',
  orange700: '#623925',

  aqua100: '#eff9fe',
  aqua200: '#bfe7fa',
  aqua300: '#90d4f7',
  aqua400: '#60c2f3',
  aqua500: '#4d9bc2',
  aqua600: '#3a7492',
  aqua700: '#264e61',

  pink100: '#fef2f7',
  pink200: '#fcd9e8',
  pink300: '#f9a5ca',
  pink400: '#f67fb3',
  pink500: '#c5668f',
  pink600: '#944c6b',
  pink700: '#623348',

  blue100: '#f1f3fd',
  blue200: '#c8d0f7',
  blue300: '#9facf2',
  blue400: '#7689ec',
  blue500: '#5e6ebd',
  blue600: '#47528e',
  blue700: '#3b4576',
} as const;

const chartColors = {
  base7: ['#677cea', '#f571aa', '#64d36f', '#b847d1', '#f8ce37', '#4ebbf1', '#f3824a'],
  base14: [
    '#677cea',
    '#f8b492',
    '#f571aa',
    '#a4b0f2',
    '#f3824a',
    '#95d6f7',
    '#f9aacc',
    '#64d36f',
    '#fbe287',
    '#4ebbf1',
    '#b847d1',
    '#a2e5a9',
    '#f8ce37',
    '#d491e3',
  ],
  base21: [
    '#677cea',
    '#f8b492',
    '#f571aa',
    '#4857a4',
    '#95d6f7',
    '#aa5b34',
    '#64d36f',
    '#ac4f77',
    '#a4b0f2',
    '#f3824a',
    '#3783a9',
    '#fbe287',
    '#b847d1',
    '#50a959',
    '#f9aacc',
    '#4ebbf1',
    '#ae9027',
    '#d491e3',
    '#f8ce37',
    '#9339a7',
    '#a2e5a9',
  ],
  base28: [
    '#677cea',
    '#f8b492',
    '#f571aa',
    '#4857a4',
    '#95d6f7',
    '#7a4125',
    '#64d36f',
    '#ac4f77',
    '#343e75',
    '#aa5b34',
    '#275e79',
    '#fbe287',
    '#b847d1',
    '#50a959',
    '#7b3955',
    '#a4b0f2',
    '#f3824a',
    '#3783a9',
    '#7c671c',
    '#d491e3',
    '#f8ce37',
    '#9339a7',
    '#3c7f43',
    '#f9aacc',
    '#4ebbf1',
    '#ae9027',
    '#6e2b7d',
    '#a2e5a9',
  ],
};

export const themeV2 = {
  ...oldTheme,

  spacing: {
    0: '0',
    2: '2px',
    4: '4px',
    6: '6px',
    8: '8px',
    12: '12px',
    16: '16px',
    20: '20px',
    24: '24px',
    28: '28px',
    32: '32px',
    36: '36px',
    40: '40px',
    44: '44px',
    48: '48px',
    56: '56px',
  },

  borderRadius: {
    none: '0',
    xs: '4px',
    s: '6px',
    m: '8px',
    l: '10px',
    xl: '12px',
    xxl: '16px',
    xxxl: '20px',
    full: '100px',
  },

  chartColors,

  colors: {
    ...oldTheme.colors,

    ...primitivesColors,
    textTitle1: primitivesColors.gray800,
    textTitle2: primitivesColors.gray700,
    textBody1: primitivesColors.gray700,
    textBody2: primitivesColors.gray600,
    textPrimary: primitivesColors.primary500,
    textPlaceholder: primitivesColors.gray400,
    textDisabled: primitivesColors.gray400,
    textNone: primitivesColors.gray00,
    textHighlight: primitivesColors.yellow100,
    textLabel: primitivesColors.gray500,
    textNegative: primitivesColors.red500,

    outlineDefault: primitivesColors.gray200,
    outlineLight: primitivesColors.gray100,
    outlineColored: primitivesColors.primary500,
    outlineNegative: primitivesColors.red500,

    iconPrimary: primitivesColors.primary500,
    iconDefault: primitivesColors.gray400,
    iconDisabled: primitivesColors.gray300,
    iconHover: primitivesColors.gray600,
    iconNone: primitivesColors.gray00,
    iconNegative: primitivesColors.red500,

    bgMedium: primitivesColors.gray100,
    bgDark: primitivesColors.gray200,
    bgNone: primitivesColors.gray00,
    bgLight: primitivesColors.gray50,
    bgPrimaryLight: primitivesColors.primary100,

    buttonOutlineFill: primitivesColors.gray00,
    buttonOutlineFillHover: primitivesColors.gray50,
    buttonOutlineFillDisabled: primitivesColors.gray200,
    buttonOutlineBorder: primitivesColors.gray200,
    buttonOutlineHover: primitivesColors.gray50,
    buttonOutlineBorderSelected: primitivesColors.primary500,
    buttonOutlineGhostBorder: primitivesColors.gray300,
    buttonOutlineGhostHover: primitivesColors.gray100,
    buttonOutlineNegativeHover: '#FDECEC',

    buttonFillPrimary: primitivesColors.primary500,
    buttonFillHoverPrimary: primitivesColors.primary600,
    buttonFillSelectedPrimary: primitivesColors.primary600,
    buttonFillDisabledPrimary: primitivesColors.gray400,
    buttonFillHoverGhost: primitivesColors.gray100,
    buttonFillNegative: primitivesColors.red500,
    buttonFillNegativeHover: primitivesColors.red600,
    buttonFillNegativeSelected: primitivesColors.red600,

    controlOutline: '#eaecef',
  },

  shadow: {
    hardMicro:
      '0px 0px 0px 1px rgba(10, 9, 11, 0.05), 0px 2px 7px 0px rgba(10, 9, 11, 0.05), 0px 2px 5px -2px rgba(10, 9, 11, 0.06)',
    hardMini:
      '0px 0px 0px 1px rgba(10, 9, 11, 0.04), 0px 2px 2px -1px rgba(10, 9, 11, 0.03), 0px 2px 12px -1px rgba(10, 9, 11, 0.08)',
    hardSmall: '0px 0px 0px 1px rgba(10, 9, 11, 0.05), 0px 6px 16px 0px rgba(10, 9, 11, 0.08)',
    hardLarge:
      '0px 10px 10px -5px rgba(10, 9, 11, 0.04), 0px 20px 25px -5px rgba(10, 9, 11, 0.10), 0px 0px 0px 1px rgba(10, 9, 11, 0.05)',
    hardXLarge: '0px 25px 50px -12px rgba(10, 9, 11, 0.25), 0px 0px 0px 1px rgba(10, 9, 11, 0.05)',

    softMicro: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.11)',
    softMini: '0px 2px 4px -1px rgba(10, 9, 11, 0.03), 0px 5px 13px -5px rgba(10, 9, 11, 0.06)',
    softSmall: '0px 10px 18px -2px rgba(10, 9, 11, 0.07)',
    softMedium:
      '0px 4px 30px 0px rgba(0, 0, 0, 0.10), 0px 3px 17px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04)',

    buttonDefault: '0 4px 4px -1px rgba(0, 0, 0, 0.02), 0 1px 1px 0 rgba(0, 0, 0, 0.06)',
    buttonPrimary: '0 2px 4px -1px rgba(70, 54, 181, 0.32)',

    controlFocusShadowGray:
      '0px 0px 0px 1px rgba(255, 255, 255, 1), 0px 0px 0px 3px rgba(229, 231, 235, 0.8)',
    controlFocusShadowPrimary:
      ' rgb(255, 255, 255) 0px 0px 0px 1px, rgb(217, 212, 253) 0px 0px 0px 3px, rgba(70, 54, 181, 0.56) 0px 1.5px 4px -1px',
  },

  zIndex: {
    auto: 'auto',
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    1000: 1000,
    1030: 1030,
    9999: 9999,
  },

  screen: {
    small: '1350px' as string,
  },

  fontSize: {
    ...oldTheme.fontSize,

    xxs: '0.688rem', // 11px
    xs: '0.75rem', // 12px
    s: '0.813rem', // 13px
    m: '0.938rem', // 15px
    l: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    xxl: '1.5rem', // 24px
  },

  lineHeight: {
    ...oldTheme.lineHeight,

    xxs: '160%',
    xs: '160%',
    s: '152%',
    m: '136%',
    l: '136%',
    xl: '136%',
    xxl: '136%',
  },

  fontWeight: {
    ...oldTheme.fontWeight,

    regular: 400,
    medium: 500,
  },

  font: {
    text: `Graphik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif`,
  },
} as const;

export const defaultTheme = themeV2;
