import { type ValueGetterParams } from 'ag-grid-community';
import { DateTimeFormats } from 'config/constants';
import { type ListRow } from 'data/modelling/lists';
import dayjs from 'dayjs';
import { isFinite, isNil } from 'lodash';

export const stringGetter = ({
  params,
  key,
}: {
  params: ValueGetterParams;
  key: string;
}): string | undefined => {
  const { data } = params;

  return (data?.data?.[key] as string) || '';
};

export const dateGetter = (data: ListRow, key: string): string | undefined => {
  const value = data?.data?.[key];

  if (!value) {
    return undefined;
  }

  return value === '' || value === null ? '' : dayjs(value).format(DateTimeFormats.APIDate);
};

export const numericGetter = (data: ListRow, key: string): string | undefined => {
  const value = data?.data?.[key];

  if (isNil(value) || !isFinite(Number(value))) {
    return undefined;
  }

  return value as string;
};
