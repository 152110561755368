import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';
import { Primary } from './primary';
import { Secondary } from './secondary';

const ActionBarWrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  align-items: center;

  > div {
    padding: ${({ theme }) => theme.spacing[12]};
    align-items: center;
    display: flex;
    width: 100%;
  }
`;

export const ActionBar = ({
  children,
  className,
  bordered,
}: {
  children?: ReactNode;
  className?: string;
  bordered?: boolean;
}): ReactElement => (
  <ActionBarWrapper bordered={bordered} className={className}>
    <div>{children}</div>
  </ActionBarWrapper>
);

ActionBar.Primary = Primary;
ActionBar.Secondary = Secondary;
