import { ReactComponent as TickIcon } from 'assets/v2/tick.svg';
import { IconShell, Modal } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { useEffect, useState, type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ComponentFlow } from '../auth-flows/component-flow';
import { IntegrationAuthFlowMap } from '../render';
import { type IntegrationAuthFlow } from '../types';
import { Header } from './header';

const StyledModal = styled(Modal)`
  min-height: 640px;

  .ant-modal-content .ant-modal-body {
    padding: 0;
  }

  & .ant-modal-footer {
    display: flex;
    gap: ${({ theme }) => theme.spacing[8]};
    justify-content: flex-end;
  }
`;

export const PremiumModal: FC<{
  item: IntegrationModalContent | null;
  onClose: () => void;
  callbackUrl: string;
  mailSent: boolean;
  raiseMailRequest?: (id: number) => void;
  isSendMailLoading?: boolean;
}> = ({ item, onClose, mailSent, callbackUrl, raiseMailRequest, isSendMailLoading }) => {
  const [selectedAuthFlow, setSelectedAuthFlow] = useState<IntegrationAuthFlow>();

  useEffect(() => {
    if (item?.name) {
      const authFlows = IntegrationAuthFlowMap[item.name];

      if (authFlows?.length === 1) {
        setSelectedAuthFlow(authFlows[0]);
      } else {
        setSelectedAuthFlow(undefined);
      }
    }
  }, [item?.name]);

  if (!item || !item.name) {
    return <></>;
  }

  return (
    <StyledModal
      cancelText={<FormattedMessage id="integrations.premium.footer.cancelText" />}
      centered
      destroyOnClose
      hideCancelButton
      okButtonProps={{
        loading: isSendMailLoading,
        icon: mailSent ? <IconShell icon={TickIcon} size="xs" /> : <></>,
        type: mailSent ? 'text' : 'primary',
      }}
      okText={
        mailSent ? (
          <FormattedMessage id="integrations.premium.footer.okTextDone" />
        ) : (
          <FormattedMessage id="integrations.premium.footer.okText" />
        )
      }
      open
      title={<Header title={item.displayName} />}
      width={400}
      onCancel={onClose}
      onOk={mailSent ? onClose : () => raiseMailRequest?.(item?.id as number)}
    >
      {selectedAuthFlow?.type === 'component' && (
        <ComponentFlow callbackUrl={callbackUrl} flow={selectedAuthFlow} item={item} />
      )}
    </StyledModal>
  );
};
