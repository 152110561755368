import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { zohoBooksLogin } from 'components/modules/settings/fivetran/common/connectors-auth';
import { IconShell, type OptionBase, Select } from 'components/ui/atomic-components';
import { useState, type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { b } from 'styles/html-elements';
import { StringParam, useQueryParams } from 'use-query-params';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { useCommonLoginFlow } from '../hooks/use-common-flow';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';

const { StyledButton, StyledP, StyledLI } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer, FullWidthWrapper, RightPanel, RightTitle } =
  FormConnectorStyles;

const StyledSelect = styled(Select)`
  margin-bottom: ${({ theme }) => theme.spacing[12]};
`;

export const ZohoBooksContent: FC<ModalContentProps> = ({ item }) => {
  const intl = useIntl();

  const [{ region }] = useQueryParams({
    region: StringParam,
  });

  const regionOptions: OptionBase[] = [
    {
      label: intl.formatMessage({ id: 'integrations.zohobooks.modal.region.us' }),
      value: 'com',
    },
    {
      label: intl.formatMessage({ id: 'integrations.zohobooks.modal.region.eu' }),
      value: 'eu',
    },
    {
      label: intl.formatMessage({ id: 'integrations.zohobooks.modal.region.in' }),
      value: 'in',
    },
    {
      label: intl.formatMessage({ id: 'integrations.zohobooks.modal.region.au' }),
      value: 'com.au',
    },
    {
      label: intl.formatMessage({ id: 'integrations.zohobooks.modal.region.jp' }),
      value: 'jp',
    },
    {
      label: intl.formatMessage({ id: 'integrations.zohobooks.modal.region.ca' }),
      value: 'ca',
    },
  ];

  const [selectedRegion, setSelectedRegion] = useState<OptionBase | undefined>(
    regionOptions.find((option) => option.value === region),
  );

  const onConnect = () => {
    if (!selectedRegion) {
      return;
    }

    zohoBooksLogin({ item, region: selectedRegion.value });
  };

  const { isLoading } = useCommonLoginFlow(item);

  return (
    <FullWidthWrapper>
      <LeftPanel>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Title>{item.displayName}</Title>
        <StyledP>
          <FormattedMessage id="integrations.zohobooks.modal.desc" />
        </StyledP>
        <StyledP>
          <strong>
            <FormattedMessage
              id="integrations.modal.common.para"
              values={{ service: item.displayName }}
            />
          </strong>
        </StyledP>
        <ol>
          <StyledLI>
            <FormattedMessage id="integrations.zohobooks.modal.line1" values={{ b }} />
          </StyledLI>
          <StyledLI>
            <FormattedMessage id="integrations.zohobooks.modal.line2" />
          </StyledLI>
        </ol>
        <CommonContent {...item} />
      </LeftPanel>
      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <StyledSelect
          menuPortalTarget={document.body}
          options={regionOptions}
          placeholder={intl.formatMessage({ id: 'integrations.zohobooks.modal.select_region' })}
          size="medium"
          value={selectedRegion}
          onChange={(newValue) => {
            setSelectedRegion(newValue as OptionBase);
          }}
        />

        <StyledButton
          disabled={!selectedRegion}
          loading={isLoading}
          type="primary"
          onClick={onConnect}
        >
          <FormattedMessage id="integrations.sfdc.modal.button_text" />
          <IconShell color="gray00" icon={ArrowRightIcon} />
        </StyledButton>
      </RightPanel>
    </FullWidthWrapper>
  );
};
