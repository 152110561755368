import { Empty as EmptyAtom } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Empty = styled(EmptyAtom)`
  max-width: 384px;
  text-align: center;
  margin: ${({ theme: { spacing } }) => spacing[20]} auto;
`;

export const GlobalSearchNoOptionsMessage: FC<React.PropsWithChildren<unknown>> = () => (
  <Empty image={null} title={<FormattedMessage id="no_results_found" />} />
);
