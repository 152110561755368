import { type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const Wrapper = styled.span`
  ${FontXs}

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.gray00};
`;

interface Props {
  values: (string | number)[];
}

export const ValueTooltip = ({ values }: Props): ReactElement => {
  return (
    <Wrapper>
      {values?.map((item) => (
        <div key={item}>{item}</div>
      ))}
    </Wrapper>
  );
};
