import { type ReactElement } from 'react';
import styled from 'styled-components';
import { FontS, FontXs, SingleLineEllipsis } from 'styles/typography';
import { getUpperCaseInitials } from './util';

const Circle = styled.div`
  ${FontXs};
  ${SingleLineEllipsis};

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary100};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.black900};
`;

const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const RoleText = styled.span`
  ${FontS};

  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const InitialsIconText = ({ text }: { text: string }): ReactElement => {
  const joinedInitials = getUpperCaseInitials(text);

  return (
    <LabelIconWrapper>
      <Circle>{joinedInitials}</Circle>
      <RoleText>{text}</RoleText>
    </LabelIconWrapper>
  );
};
