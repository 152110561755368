import { type InputRef } from 'antd';
import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { Button, IconShell, SearchBox, Spinner, Tooltip } from 'components/ui/atomic-components';
import { DropdownItemLabel } from 'components/ui/atomic-components/dropdown/dropdown-item-label';
import { Menu } from 'components/ui/atomic-components/menu';
import { DimensionAllLabel, DimensionAllMarker } from 'data/dimension';
import { useGetMetricDimensionValues, useGetMetricSpaces } from 'data/metrics/hooks';
import { BASE_CASE_NAME, SCENARIO } from 'data/scenarios';
import { type ReactElement, useEffect, useRef, useState, type ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { formatName } from 'utils/data-formatter';

const Wrapper = styled.div`
  width: 240px;
  padding: ${({ theme }) => theme.spacing[4]};
`;

const Title = styled.div`
  ${FontXs};

  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  gap: ${({ theme }) => theme.spacing[4]};
`;

const DeleteFilterButton = styled(Button)`
  margin-left: auto;
`;

const InputWrapper = styled.div`
  margin: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[4]} ${spacing[8]}`};
`;

const MenuWrapper = styled.div`
  max-height: 228px;
  overflow: auto;
`;

const StyledMenu = styled(Menu)`
  &.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
  }

  .ant-menu-item {
    height: 28px;
    display: flex;
    align-items: center;

    .ant-menu-title-content {
      width: 100%;
    }
  }
`;

interface Props {
  metricName: string;
  dimensionName: string;
  title: ReactNode;
  selectedValue: string;
  onMenuItemClick: (value: string) => void;
  onClickBack?: () => void;
}

export const DimensionValueSingleSelect = ({
  metricName,
  dimensionName,
  title,
  selectedValue,
  onMenuItemClick,
  onClickBack,
}: Props): ReactElement => {
  const intl = useIntl();
  const inputRef = useRef<InputRef>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: { scenarios } = {} } = useGetMetricSpaces(metricName);

  const { data: options = [], isLoading } = useGetMetricDimensionValues(metricName, dimensionName);

  const menuItems = useMemo(() => {
    const items = [];

    if (dimensionName === SCENARIO) {
      for (const scenario of scenarios || []) {
        items.push({
          key: scenario.name,
          label: <DropdownItemLabel title={scenario.displayName} />,
          displayName: scenario.displayName,
        });
      }
    } else {
      items.push({
        key: DimensionAllMarker,
        label: <DropdownItemLabel title={DimensionAllLabel} />,
        displayName: DimensionAllLabel,
      });

      for (const option of options) {
        const displayValue =
          option.value === ''
            ? intl.formatMessage({ id: 'dimensions.blank' })
            : formatName(option.value);

        items.push({
          key: option.value,
          label: <DropdownItemLabel title={displayValue} />,
          displayName: displayValue,
        });
      }
    }

    return items;
  }, [dimensionName, intl, options, scenarios]);

  const selectedKey =
    dimensionName === SCENARIO && !scenarios?.map((s) => s.name).includes(selectedValue)
      ? BASE_CASE_NAME
      : selectedValue;

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  if (!dimensionName) {
    return <></>;
  }

  return (
    <Wrapper className="popover-prevent-chart-mouse-click">
      <Title>
        {onClickBack && (
          <Button
            icon={<IconShell icon={ChevronLeftIcon} />}
            size="extraSmall"
            type="text"
            onClick={onClickBack}
          />
        )}
        {title}
        <Tooltip title={<FormattedMessage id="remove_filter" />}>
          <DeleteFilterButton
            icon={<IconShell icon={DeleteIcon} />}
            size="extraSmall"
            type="text"
            onClick={() => onMenuItemClick(DimensionAllMarker)}
          />
        </Tooltip>
      </Title>
      <InputWrapper>
        <SearchBox ref={inputRef} onChange={(e) => setSearchTerm(e.target.value)} />
      </InputWrapper>
      <MenuWrapper>
        {!isLoading ? (
          <StyledMenu
            items={menuItems.filter((item) =>
              item.displayName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
            )}
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              if (e.key === BASE_CASE_NAME) {
                onMenuItemClick(DimensionAllMarker);
              } else {
                onMenuItemClick(e.key);
              }
            }}
          />
        ) : (
          <Spinner size="medium" />
        )}
      </MenuWrapper>
    </Wrapper>
  );
};
