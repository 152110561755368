import { DateTimeFormats } from 'config/constants';
import { QueryTableColumnDataType } from 'data/big-query';
import { type ListRow } from 'data/modelling/lists';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { type ListGridRow } from '../../types';

export const getFilteredRows = ({
  rowData,
  columnFilters,
  columnNames,
  columnTypeMap,
  searchQuery,
}: {
  rowData: ListGridRow[];
  columnFilters?: Record<string, Set<string>>;
  columnNames: string[];
  columnTypeMap: { [key: string]: QueryTableColumnDataType };
  searchQuery: string;
}): ListRow[] => {
  if (isEmpty(columnFilters) && searchQuery === '') {
    return rowData;
  }

  const columnNamesSet = new Set(columnNames);

  return rowData.filter((row) => {
    return (
      checkIfListRowMatchesFilter({
        row,
        columnFilters,
        columnNamesSet,
      }) && checkIfRowMatchesSearchQuery({ row, columnTypeMap, searchQuery })
    );
  });
};

export const checkIfListRowMatchesFilter = ({
  row,
  columnFilters,
  columnNamesSet,
}: {
  row: ListGridRow;
  columnFilters: Record<string, Set<string>> | undefined;
  columnNamesSet: Set<string>;
}): boolean => {
  if (isEmpty(columnFilters)) {
    return true;
  }

  return Object.keys(columnFilters || {}).every((column) => {
    // if rule for a deleted column skip that filter
    if (!columnNamesSet.has(column)) {
      return true;
    }

    const columnValue = row.data[column] || '';

    return columnFilters[column].has(String(columnValue));
  });
};

export const checkIfRowMatchesSearchQuery = ({
  row,
  columnTypeMap,
  searchQuery,
}: {
  row: ListGridRow;
  columnTypeMap: { [key: string]: QueryTableColumnDataType };
  searchQuery: string;
}): boolean => {
  if (searchQuery === '') {
    return true;
  }

  const rowCellDisplayValues = Object.entries(row.data).map(([col, val]) => {
    if (columnTypeMap[col] === QueryTableColumnDataType.Date) {
      return dayjs(val).format(DateTimeFormats.HumanReadableDate);
    }

    return val;
  });

  return rowCellDisplayValues.some((val) =>
    String(val).toLowerCase().includes(searchQuery.toLowerCase().trim()),
  );
};
