import { type ViewPlugin } from '@codemirror/view';
import {
  constructDatasetPropertyMatchPlugin,
  constructDimGroupPropertyMatchPlugin,
  constructListPropertyMatchPlugin,
} from 'components/ui/atomic-components/formula-editor-v2/extensions/expression-constructs/dimension-property-construct';
import { constructLastActualsDatePlugin } from 'components/ui/atomic-components/formula-editor-v2/extensions/expression-constructs/last-actuals-date-construct';

export const expressionConstructs = (): ViewPlugin<object>[] => {
  const lastActualsDatePlugin = constructLastActualsDatePlugin();
  const listPropertyPlugin = constructListPropertyMatchPlugin();
  const datasetPropertyPlugin = constructDatasetPropertyMatchPlugin();
  const dimGroupPropertyPlugin = constructDimGroupPropertyMatchPlugin();

  return [lastActualsDatePlugin, listPropertyPlugin, datasetPropertyPlugin, dimGroupPropertyPlugin];
};
