import { Analytics } from 'config/analytics';
import { useEffect } from 'react';
import { useUser } from 'utils/hooks/use-user';

export const useInitializeAnalytics = (): void => {
  const { user } = useUser();

  useEffect(() => {
    Analytics.init(user);
  }, [user]);
};
