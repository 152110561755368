import styled from 'styled-components';

export const styles = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing[16]};
  `,

  Title: styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing[4]};
    flex: 1;
    overflow: hidden;
  `,

  TitleSpinner: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  TitleDescWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[4]};
    flex: 1;
    overflow: hidden;
  `,

  ActionButtonsSection: styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  BoundingBox: styled.div<{ $isLeftNavActive: boolean }>`
    display: flex;
    width: 24px;
    height: 24px;
    padding: ${({ theme }) => theme.spacing[4]};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    cursor: pointer;
    transition: color, background 0.3s ease;

    .left-nav-toggle-button {
      color: ${({ theme, $isLeftNavActive }) => {
        return $isLeftNavActive ? theme.colors.iconPrimary : theme.colors.iconDefault;
      }};
    }

    :hover {
      background: ${({ theme, $isLeftNavActive }) => {
        return $isLeftNavActive ? theme.colors.primary50 : theme.colors.gray100;
      }};

      .left-nav-toggle-button {
        color: ${({ theme, $isLeftNavActive }) => {
          return $isLeftNavActive ? theme.colors.iconPrimary : theme.colors.iconHover;
        }};
      }
    }
  `,
};
