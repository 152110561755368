import { type QueryClient } from '@tanstack/react-query';
import { type ID } from 'data';
import { type QueryTableColumnDataType } from 'data/big-query';
import { type List } from 'data/modelling/lists';
import {
  cleanupConfigOnColumnUpdate,
  cleanupRowsOnColumnUpdate,
} from 'store/lists/utils/cleanup-config-rows-on-column-update';

interface Props {
  queryClient: QueryClient;
  listId: ID;
  columnName: string;
  updatedInfo: { columnName: string; type: QueryTableColumnDataType };
}

export const updateListColumnInCache = ({
  queryClient,
  listId,
  columnName,
  updatedInfo,
}: Props): void => {
  queryClient.setQueryData(['lists'], (existingLists?: List[]) => {
    return existingLists?.map((list) => {
      if (list.id === listId) {
        const newConfig = cleanupConfigOnColumnUpdate({
          oldConfig: list.config,
          oldColumnName: columnName,
          updatedColumnInfo: updatedInfo,
        });

        const updatedRows = cleanupRowsOnColumnUpdate({
          oldRows: list.rows || [],
          oldColumnName: columnName,
          newColumnName: updatedInfo.columnName,
        });

        return {
          ...list,
          config: newConfig,
          rows: updatedRows,
        };
      }

      return list;
    });
  });

  // queryClient.setQueryData(['lists', listId], (list?: List) => {
  //   if (!list) {
  //     return undefined;
  //   }

  //   return {
  //     ...list,
  //     config: {
  //       ...list.config,
  //       columnOrder: list.config.columnOrder.map((col) => {
  //         return col === columnName ? updatedInfo.columnName : col;
  //       }),
  //       columnTypeMap: {
  //         ...list.config.columnTypeMap,
  //         [updatedInfo.columnName]: updatedInfo.type,
  //       },
  //       uniqueKey:
  //         list.config.uniqueKey === columnName ? updatedInfo.columnName : list.config.uniqueKey,
  //       uniqueKeyColumns: list.config.uniqueKeyColumns?.map((col) => {
  //         return col === columnName ? updatedInfo.columnName : col;
  //       }),
  //     },
  //   } as List;
  // });
};
