export const downloadResponseData = ({
  data,
  fileName,
}: {
  data: BlobPart;
  fileName: string;
}): void => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
