import { useMutation } from '@tanstack/react-query';
import { type CheckboxValueType } from 'antd/es/checkbox/Group';
import { type BasicDataNode, type DataNode, type EventDataNode } from 'antd/es/tree';
import { Button, SearchBox, Tree, notification } from 'components/ui/atomic-components';
import { PageHeader } from 'components/ui/layout/header-v2';
import { useState, type FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { formatName } from 'utils/data-formatter';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { OutTablesApi } from './api';
import { useOutTablesQuery } from './hook';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;

  .ant-tree-checkbox {
    margin-top: 2px;
  }
`;

const TreeWrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { spacing } }) => ` ${spacing[8]} ${spacing[12]}`};
`;

export const OutTablesViewer: FC = () => {
  const { data: tables } = useOutTablesQuery();
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const treeHeight = window.innerHeight - 150;

  const treeData = useMemo(
    () =>
      (tables ?? [])
        .filter((table) => table.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((table) => ({ key: table, title: formatName(table) })),
    [tables, searchTerm],
  );

  const { mutateAsync: onSave, isLoading } = useMutation(
    (values: CheckboxValueType[]) => OutTablesApi.skipDBTExpectations(values as string[]),
    {
      onSuccess: () => {
        notification.success({ message: 'DBT expectations skipped for selected tables.' });
      },
      onError: defaultApiErrorHandler,
    },
  );

  const onSelect = (
    _: React.Key[],
    info: {
      event: 'select';
      selected: boolean;
      node: EventDataNode<DataNode | BasicDataNode>;
      selectedNodes: (DataNode | BasicDataNode)[];
      nativeEvent: MouseEvent;
    },
  ) => {
    let newOptions: CheckboxValueType[] = [];

    if ((checkedOptions ?? []).includes(info.node.key as string)) {
      newOptions = (checkedOptions ?? []).filter((o) => o !== info.node.key);
    } else {
      newOptions = [...(checkedOptions ?? []), info.node.key as string];
    }

    setCheckedOptions(newOptions as string[]);
  };

  return (
    <Wrapper>
      <PageHeader bordered>
        <PageHeader.Primary>
          <PageHeader.Title>
            <FormattedMessage id="tools.out_tables.title" />
          </PageHeader.Title>
        </PageHeader.Primary>
        <PageHeader.Secondary>
          <Button
            loading={isLoading}
            type="primary"
            onClick={() => {
              onSave(checkedOptions);
            }}
          >
            <FormattedMessage id="tools.skip_dbt_expectation" />
          </Button>
        </PageHeader.Secondary>
      </PageHeader>

      <TreeWrapper>
        <SearchBox onChange={(e) => setSearchTerm(e.target.value)} />

        <Tree
          checkable
          checkedKeys={checkedOptions as React.Key[]}
          height={treeHeight}
          treeData={treeData}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onCheck={onSelect as any}
          onSelect={onSelect}
        />
      </TreeWrapper>
    </Wrapper>
  );
};
