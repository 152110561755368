import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { getCallbackUrl, getState } from '../util';

export const zohoBooksLogin = ({
  item,
  region,
}: {
  item: IntegrationModalContent;
  region: string;
}): void => {
  const { clientId, id } = item;
  const state = `${getState(id)}~~region~${region}`;

  window.open(
    `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.contacts.READ ZohoBooks.settings.READ ZohoBooks.estimates.READ ZohoBooks.invoices.READ ZohoBooks.customerpayments.READ ZohoBooks.creditnotes.READ ZohoBooks.projects.READ ZohoBooks.expenses.READ ZohoBooks.salesorders.READ ZohoBooks.purchaseorders.READ ZohoBooks.bills.READ ZohoBooks.debitnotes.READ ZohoBooks.vendorpayments.READ ZohoBooks.banking.READ ZohoBooks.accountants.READ&response_type=code&access_type=offline&prompt=consent&client_id=${clientId}&redirect_uri=${getCallbackUrl()}&state=${state}`,
    '_self',
  );
};
