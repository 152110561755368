import { Menu as AntdMenu, type MenuProps as AntdMenuProps } from 'antd';
import { type ItemType as AntdItemType } from 'antd/lib/menu/hooks/useItems';
import { ReactComponent as RightArrowIcon } from 'assets/v2/chevron-right.svg';
import styled, { css } from 'styled-components';
import { FontS, FontXs } from 'styles/typography';
import { IconShell } from '../icon-shell';

const Icon = () => <IconShell className="ant-menu-submenu-expand-icon" icon={RightArrowIcon} />;

export type ItemType = AntdItemType & { hidden?: boolean };
export type MenuProps = Omit<AntdMenuProps, 'items'> & {
  items?: ItemType[];
};

export const Menu = styled(AntdMenu).attrs<MenuProps>((props: MenuProps) => ({
  expandIcon: Icon,
  items: props.items?.filter((i) => !i?.hidden),
}))`
  & {
    ${({ items }) =>
      items?.filter((i) => !(i as ItemType)?.hidden).length === 0 &&
      css`
        display: none;
      `}
  }

  background-color: transparent;
  border-inline-end: none;

  .ant-menu-item-divider {
    border-color: ${({ theme }) => theme.colors.gray200};
  }

  .ant-menu-item {
    ${FontXs};

    padding: ${({ theme }) => theme.spacing[8]};
    display: flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    color: ${({ theme }) => theme.colors.textTitle1};
    height: fit-content;

    .ant-menu-title-content {
      width: 100%;
    }
  }

  .ant-menu-item-selected {
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    .dropdown-item-label-icon {
      display: block;
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      ${FontS};

      padding: ${({ theme }) => theme.spacing[8]};
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.textTitle1};
    }

    .ant-menu-submenu-expand-icon {
      color: ${({ theme }) => theme.colors.gray400};
      right: ${({ theme }) => theme.spacing[8]};
    }

    .ant-menu-title-content {
      width: calc(100% - ${({ theme }) => theme.spacing[16]});
    }
  }
`;
