import { Steps as AntSteps } from 'antd';
import styled from 'styled-components';

export const Steps = styled(AntSteps)`
  &.ant-steps.ant-steps-navigation {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }

  &.ant-steps.ant-steps-navigation .ant-steps-item-container {
    padding-bottom: ${({ theme }) => theme.spacing[8]};
  }

  .ant-steps-item-title {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;
