import { type DimensionQueryParamsObject } from 'data/dimension';
import { MetricTreeNodeTypes, type MetricWithHierarchy } from 'data/metrics';
import { type TableRowData } from 'data/reports/types';
import { isEmpty } from 'lodash';
import { type HierarchyClanNode } from 'utils/tree-table';

export const constructRootHierarchyClanFromFilter = ({
  filter,
}: {
  filter?: DimensionQueryParamsObject;
}): HierarchyClanNode[] => {
  const hierarchyClan = [];

  if (filter && !isEmpty(filter)) {
    for (const [key, value] of Object.entries(filter)) {
      hierarchyClan.push({
        displayName: '',
        viewNodeType: MetricTreeNodeTypes.Dimension,
        name: key,
        value: value?.[0],
        displayValue: value?.[0],
      });
    }
  }

  return hierarchyClan;
};

export const getHierarchyWithFilter = ({
  data,
  filter = {},
}: {
  data?: TableRowData;
  filter?: DimensionQueryParamsObject;
}): MetricWithHierarchy[] => {
  const rootHierarchy = constructRootHierarchyClanFromFilter({
    filter,
  });

  // skip if the same filter is applied as in the row hierarchy
  const filteredHierarchyClan = (data?.hierarchyClan || []).filter((node) => {
    if (
      node.name in filter &&
      filter?.[node.name]?.length === 1 &&
      filter?.[node.name][0] === node.value
    ) {
      return false;
    }

    return true;
  });

  return [...rootHierarchy, ...filteredHierarchyClan] as MetricWithHierarchy[];
};
