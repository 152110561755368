import { type QueryClient, type QueryKey } from '@tanstack/react-query';
import { type ID } from 'data';

export const deleteItemFromItemsCache = <T extends { id: ID }>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  deletedItem: T,
): void => {
  const items = queryClient.getQueryData<T[]>(queryKey);

  queryClient.setQueryData(
    queryKey,
    items?.filter((item) => item.id !== deletedItem.id),
  );
};
