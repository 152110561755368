import { datadogRum } from '@datadog/browser-rum';

export const initializeDataDog = (): void => {
  datadogRum.init({
    applicationId: '0486fe2c-6d81-4dc8-a394-1bcf851940c4',
    clientToken: 'pub626dfbbb708d712b8955f946b07c92e0',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'drivetrain',
    env: 'production',
    trackViewsManually: true,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};
