import { ConfirmationModal } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontM } from 'styles/typography';

const MessageWrapper = styled.div`
  ${FontM}

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.colors.textBody1};
`;

interface Props {
  isOpen: boolean;
}

export const LaunchingVersionAlert = ({ isOpen }: Props): ReactElement => {
  return (
    <ConfirmationModal
      closable={false}
      confirmLoading={false}
      destroyOnClose
      hideFooter
      open={isOpen}
      title={<FormattedMessage id="model.versions.view.launching_version_mode_title" />}
    >
      <MessageWrapper>
        <FormattedMessage id="model.versions.view.launching_version_mode_message" />
      </MessageWrapper>
    </ConfirmationModal>
  );
};
