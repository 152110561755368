import { Decoration, MatchDecorator, type ViewPlugin, WidgetType } from '@codemirror/view';
import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import { createRoot } from 'react-dom/client';
import { FormattedMessage } from 'react-intl';
import { TextTag } from '../../tag-components/text-tag';
import { FormulaTermRegexMap } from './constants';
import { constructViewPlugin } from './utils';

class LastActualsDateConstruct extends WidgetType {
  toDOM() {
    const wrapper = document.createElement('span');
    const wrapperRoot = createRoot(wrapper);

    wrapperRoot.render(
      <TextTag text={<FormattedMessage id="formula.constructs.last_actuals_date" />} />,
    );

    return wrapper;
  }
}

export const constructLastActualsDatePlugin = (): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.LastActualsDate],
    decoration: () => {
      return Decoration.replace({
        widget: new LastActualsDateConstruct(),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};
