import { useEffect } from 'react';

export const useDismissOnEsc = ({
  enable,
  dismiss,
}: {
  enable: boolean;
  dismiss?: () => void;
}): void => {
  useEffect(() => {
    if (enable) {
      const closeOnEsc = (e: KeyboardEvent) => {
        if (enable && e.code === 'Escape') {
          dismiss?.();
        }
      };

      document.addEventListener('keydown', closeOnEsc);

      return () => document.removeEventListener('keydown', closeOnEsc);
    }
  }, [dismiss, enable]);
};
