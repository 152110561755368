import { type EditorState } from '@codemirror/state';
import { type Tooltip } from '@codemirror/view';
import { MathFunctionUsageMapping } from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { createRoot } from 'react-dom/client';
import { FormulaFunctionTooltip } from '../formula-function-tooltip';
import { getFunctionNameFromState } from './get-function-name-from-state';

export const getCursorTooltip = (state: EditorState): readonly Tooltip[] => {
  const tooltip: Tooltip[] = [];

  for (const range of state.selection.ranges) {
    if (!range.empty || (range.from === 0 && range.to === 0)) {
      continue;
    }

    const functionName = getFunctionNameFromState({ state, from: range.from });

    if (!functionName) {
      continue;
    }

    const functionInfo = MathFunctionUsageMapping[functionName];

    if (!functionInfo) {
      continue;
    }

    tooltip.push({
      pos: 0,
      create: () => {
        const dom = document.createElement('div');

        dom.className = 'formula-tooltip-wrapper';

        const domRoot = createRoot(dom);

        domRoot.render(<FormulaFunctionTooltip functionInfo={functionInfo} state={state} />);

        return {
          dom,
          offset: {
            x: -280,
            y: -24,
          },
        };
      },
    });
  }

  return tooltip;
};
