import { ReactComponent as ListIcon } from 'assets/bullet-list.svg';
import { ReactComponent as DatasetIcon } from 'assets/table.svg';
import { IconShell, IconText, Tooltip } from 'components/ui/atomic-components';
import { DatasetNamePrefix } from 'data/integrations/fivetran/constants/constants';
import { type ReactNode } from 'react';
import styled from 'styled-components';
import { FontS, FontXs, SingleLineEllipsis } from 'styles/typography';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { type IconType } from '../atomic-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  width: 100%;
`;

const ColumnName = styled.div`
  ${SingleLineEllipsis};
  ${FontXs};

  max-width: 234px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  margin: ${({ theme }) => theme.spacing[8]} 0;
  width: 100%;
`;

const OptionTitle = styled.div`
  ${SingleLineEllipsis};
  ${FontS};

  color: ${({ theme }) => theme.colors.textTitle1};
  max-width: 234px;
`;

const OptionSubTitle = styled.div`
  ${SingleLineEllipsis};

  ${FontXs}

  color: ${({ theme }) => theme.colors.gray400};
  max-width: 234px;
`;

export const getMetaInfoFromDimension = (dimension: {
  label: string;
  value: string;
  parentSourceDisplayName: string;
}): {
  columnName: string;
  tooltip: ReactNode;
  icon: IconType;
} => {
  const dimensionName = dimension?.label || '';
  const isListColumn = !dimension?.value?.startsWith(DatasetNamePrefix);
  const columnName = getColumnNameFromRawDimensionName(dimensionName);
  const tooltipText = `${formatName(dimension.parentSourceDisplayName)} > ${columnName}`;
  const icon = isListColumn ? ListIcon : DatasetIcon;
  const tooltip = <IconText color="gray500" icon={icon} text={tooltipText} />;

  return {
    columnName,
    tooltip,
    icon,
  };
};

export const renderDimensionValue = (dimension: {
  label: string;
  value: string;
  parentSourceDisplayName: string;
}): ReactNode => {
  const { tooltip, icon, columnName } = getMetaInfoFromDimension(dimension);

  return (
    <Tooltip spacing="compact" title={tooltip}>
      <Wrapper>
        <IconShell color="gray500" icon={icon} size="xs" />
        <ColumnName>{columnName}</ColumnName>
      </Wrapper>
    </Tooltip>
  );
};

export const renderDimensionOption = (dimension: {
  label: string;
  value: string;
  parentSourceDisplayName: string;
}): ReactNode => {
  const { tooltip, columnName } = getMetaInfoFromDimension(dimension);

  return (
    <Tooltip placement="right" title={tooltip}>
      <OptionWrapper>
        <OptionTitle>{columnName}</OptionTitle>
        <OptionSubTitle>{dimension?.parentSourceDisplayName || ''}</OptionSubTitle>
      </OptionWrapper>
    </Tooltip>
  );
};
