import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { BootstrapApi } from '../api';
import { BootstrapStoreKey } from '../constants';
import { type AccountBootstrapType } from '../types';

export const useBootstrap = (): UseQueryResult<AccountBootstrapType> => {
  return useQuery(BootstrapStoreKey, () => BootstrapApi.fetchBootstrapData(), {
    staleTime: Infinity,
  });
};
