import { Table as AntdTable } from 'antd';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { FontS } from 'styles/typography';

export const Table = styled(AntdTable)`
  /* stylelint-disable no-descending-specificity */
  .ant-table {
    font-feature-settings: 'tnum' 0;
  }

  ${({ theme }) => css`
    && {
      .ant-table-thead > tr > th {
        background: ${theme.colors.gray00};
      }

      ${switchProp(prop('size', 'default'), {
        small: css`
          .ant-table-title,
          .ant-table-footer,
          .ant-table-thead > tr > th,
          tfoot > tr > th,
          tfoot > tr > td {
            padding: ${theme.spacing[8]} ${theme.spacing[12]};
          }

          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            font-weight: ${theme.fontWeight.regular};
            line-height: ${theme.lineHeight.s};
          }

          .ant-table-thead > tr > th {
            ${FontS};

            border: solid 1px ${theme.colors.outlineDefault};
            color: ${theme.colors.textBody1};
            font-weight: ${theme.fontWeight.medium};

            :not(:last-child):not(.ant-table-selection-column):not(
                .ant-table-row-expand-icon-cell
              ):not([colspan])::before {
              content: none;
            }
          }

          .ant-table-tbody > tr > td {
            padding: ${theme.spacing[12]};
          }
        `,
      })}
    }
  `};
`;
