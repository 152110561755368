import { type MonitoringMetric } from 'data/metric-builder';
import { useMonitoringMetrics } from 'data/metric-builder/hooks/use-get-monitoring-metrics';
import { type MetricDomainName } from 'data/metrics/constants';
import { type PlanMetric } from 'data/modelling/metric';
import { useGetPlanMetrics } from 'data/modelling/model/use-get-plan-metrics';

export const useFormulaEditorMetrics = (
  domain: MetricDomainName,
): { data: (PlanMetric | MonitoringMetric)[]; isLoading: boolean } => {
  const isPlanningIncluded = ['planning', 'all'].includes(domain);
  const isMonitoringIncluded = ['monitoring', 'all'].includes(domain);

  const { metrics: planMetrics = [], isLoading: isLoadingPlanMetrics } = useGetPlanMetrics({
    enabled: isPlanningIncluded,
  });

  const { data: monitoringMetrics = [], isLoading: isLoadingMonitoringMetrics } =
    useMonitoringMetrics({
      options: { enabled: isMonitoringIncluded },
    });

  const data = [
    ...(isPlanningIncluded ? planMetrics : []),
    ...(isMonitoringIncluded ? monitoringMetrics : []),
  ];

  return {
    data,
    isLoading:
      (isPlanningIncluded && isLoadingPlanMetrics) ||
      (isMonitoringIncluded && isLoadingMonitoringMetrics),
  };
};
