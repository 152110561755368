import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right.svg';
import { ReactComponent as FilterIcon } from 'assets/v2/filter.svg';
import { ReactComponent as ModuleIcon } from 'assets/v2/geometry.svg';
import { Tooltip } from 'components/ui/atomic-components/tooltip';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { MetricTypeIconMapping } from 'components/ui/metric/icon-generator/metric-type-icon-mapping';
import { Granularity } from 'data';
import { TimeDimensionName } from 'data/dimension';
import { type MonitoringMetric } from 'data/metric-builder';
import { type PlanMetric, TimeAggregationTypes, type ModuleMetric } from 'data/modelling/metric';
import { isEmpty } from 'lodash';
import { useMemo, type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { IconShell } from '../../../icon-shell';
import styles from '../common/styles';
import { ContextWrapper } from '../context-wrapper';
import { FilterButton } from './filter-button';
import { SubscriptTags } from './subscript-tags';
import { constructSubscript, extractSubscriptValues } from './utils';

const { TooltipTitle, ModuleInfoWrapper } = styles;

const Wrapper = styled.div`
  ${FontXs};

  position: relative;
  top: ${({ theme }) => theme.spacing[4]};
  display: inline-flex;
  gap: 1px;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 ${({ theme }) => theme.spacing[2]};
`;

const MetricName = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green200};
  padding: 0 ${({ theme }) => theme.spacing[6]};
  color: ${({ theme }) => theme.colors.green600};
  gap: ${({ theme }) => theme.spacing[4]};
  white-space: nowrap;
  cursor: pointer;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray200};
  padding: 0 ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
`;

interface Props {
  metricTerm: RegExpExecArray;
  metric: PlanMetric | MonitoringMetric;
  updateEditorView: (val: string) => void;
  readOnly?: boolean;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  timeFilterGranularity?: Granularity;
}

export const MetricTag = ({
  metricTerm,
  metric,
  updateEditorView,
  readOnly,
  customTimeAutoCompletionOptions,
  timeFilterGranularity,
}: Props): ReactElement => {
  const subscriptsMap = useMemo(() => extractSubscriptValues(metricTerm), [metricTerm]);

  const { [TimeDimensionName]: timeSubscript, ...dimensionsSubscript } = subscriptsMap;

  const hideFilterButton =
    readOnly ||
    ((metric as ModuleMetric)?.timeSummary === TimeAggregationTypes.Constant &&
      isEmpty((metric as ModuleMetric)?.dimensions));

  const { domain } = metric;

  const navigateTo = () => {
    const navigateTo =
      domain === 'monitoring'
        ? `/metrics/edit-redirect/${metric.name}`
        : `/models/m/${(metric as ModuleMetric)?.moduleName}`;

    if (window.location.pathname !== navigateTo) {
      window.open(`${window.location.origin}${navigateTo}`, '_blank');
    }
  };

  const tooltipTitle = !readOnly && (
    <TooltipTitle>
      {domain === 'planning' && (
        <ModuleInfoWrapper>
          <IconShell color="iconDefault" icon={ModuleIcon} />
          {(metric as ModuleMetric)?.moduleDisplayName}
          <IconShell color="iconDefault" icon={ChevronRightIcon} />
        </ModuleInfoWrapper>
      )}
      {metric.displayName}
    </TooltipTitle>
  );

  const updatedTimeBaseGranularity =
    timeFilterGranularity || (metric as PlanMetric)?.baseGranularity || Granularity.Monthly;

  return (
    <ContextWrapper>
      <Wrapper>
        <Tooltip placement="bottomLeft" title={tooltipTitle}>
          <MetricName onClick={navigateTo}>
            <IconShell icon={MetricTypeIconMapping[metric.type]} />
            {metric.displayName}
          </MetricName>
        </Tooltip>

        {!hideFilterButton && (
          <FilterButton
            customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
            metricName={metric.name}
            subscriptsMap={subscriptsMap}
            timeFilterGranularity={updatedTimeBaseGranularity}
            onChange={(subscriptsMap) => {
              const { [TimeDimensionName]: timeSubscript, ...dimensionsSubscript } = subscriptsMap;
              const subscripts = constructSubscript(dimensionsSubscript, timeSubscript?.[0]);

              updateEditorView(subscripts);
            }}
          >
            {(popupOpen) => (
              <FilterWrapper>
                <IconShell color={popupOpen ? 'iconHover' : 'iconDefault'} icon={FilterIcon} />
              </FilterWrapper>
            )}
          </FilterButton>
        )}

        {(timeSubscript?.[0] || !isEmpty(dimensionsSubscript)) && (
          <SubscriptTags
            customTimeAutoCompletionOptions={customTimeAutoCompletionOptions}
            dimensionsSubscript={dimensionsSubscript}
            metricName={metric.name}
            readOnly={readOnly}
            timeFilterGranularity={updatedTimeBaseGranularity}
            timeSubscript={timeSubscript?.[0]}
            updateEditorView={updateEditorView}
          />
        )}
      </Wrapper>
    </ContextWrapper>
  );
};
