import { type ListRow } from 'data/modelling/lists';
import { useEffect, useMemo } from 'react';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';

export const useGridRowData = (): {
  rowData: ListRow[];
  pinnedBottomRowData: { id: string; fullWidth: boolean }[];
} => {
  const pinnedBottomRowData = useMemo(() => [{ id: 'add-entry', fullWidth: true }], []);

  const searchQuery = useListStoreContext((s) => s.searchQuery);
  const filteredRows = useListStoreContext((s) => s.filteredRows);
  const store = useListStoreInstance();

  useEffect(() => {
    queueMacroTask(() => store.getState().gridApi?.refreshCells()); // to update row number column and cell class rules
  }, [searchQuery, store]);

  return {
    rowData: filteredRows,
    pinnedBottomRowData,
  };
};
