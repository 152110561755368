import { DEPLOY_ENV } from 'config/environment';
import { type User } from 'data/users';
import mixpanel from 'mixpanel-browser';
import zipy from 'zipyai';
import { setupMixpanel } from './mixpanel';
import { type EventInfo } from './types';
import { setupZipy } from './zipyai';

const isProd = DEPLOY_ENV === 'production';

const actions = {
  init: (user: User): void => {
    if (isProd) {
      setupMixpanel(user);

      setupZipy(user);

      Analytics.track('Successful login');
    }
  },
  track: (name: string, props?: EventInfo): void => {
    if (isProd) {
      const baseOptions = {
        pageType: window.location.pathname.split('/')?.[1],
      };

      const trackingProps = {
        ...baseOptions,
        ...props,
        zipy_session_url: zipy.getCurrentSessionURL(),
      };

      try {
        mixpanel.track(name, trackingProps);
      } catch {
        // if analytics call blocked or fails, do nothing
      }
    }
  },
};

export const Analytics = actions;
