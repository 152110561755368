import { type ReactElement } from 'react';
import { components, type MultiValueGenericProps } from 'react-select';
import { renderDimensionValue } from './utils';
import { type DimensionSelectOption } from './index';

export const MultiLabelComponent = (
  props: MultiValueGenericProps<DimensionSelectOption>,
): ReactElement => {
  return (
    <components.MultiValueLabel {...props}>
      {renderDimensionValue({
        label: props.data.label,
        value: props.data.value,
        parentSourceDisplayName: props.data.parentSourceDisplayName,
      })}
    </components.MultiValueLabel>
  );
};
