import { ListUpdateType } from 'data/modelling/lists';
import { type defaultTheme } from 'styles/theme';

export const getCardColor = ({
  action,
  isRecommendation,
}: {
  action: ListUpdateType;
  isRecommendation: boolean;
}): keyof typeof defaultTheme.colors => {
  if ([ListUpdateType.Delete, ListUpdateType.Terminate].includes(action)) {
    return 'red200';
  }

  if (action === ListUpdateType.Update) {
    return 'blue200';
  }

  if (isRecommendation) {
    return 'green200';
  }

  return 'yellow200';
};
