import {
  type MathFunction,
  MathFunctionPrefix,
  MathFunctionPrefixMapping,
  MathFunctions,
} from 'components/modules/modelling/modules/detail-v2/utils/constants';
import { FormulaExpressionMapping } from 'components/ui/codemirror-v2/formula-bar/constants';
import { CurrencySymbolMapping, type Currency } from 'data/currencies';
import { type Dimension } from 'data/dimension';
import { DimensionAggregationTypes, TimeAggregationTypes } from 'data/modelling/metric';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType, NegativeValueType } from 'utils/data-formatter/types';
import { decodeString } from 'utils/decode-string';
import { encodeString } from 'utils/encode-string';
import { isNumeric } from 'utils/validators';

export const removeDataTypeFormatting = (formula: string, currency: Currency): string => {
  const regex = new RegExp(`[${CurrencySymbolMapping[currency]}%,]`);

  return formula.replace(regex, '');
};

// eg true for (5.1) -> -5.1
export const checkIfNegativeBracketNumber = (
  formula: string,
): { isNegativeNo: boolean; value?: string } => {
  if (formula?.[0] === '(' && formula?.at(-1) === ')') {
    const value = formula.replaceAll(/[(),]/g, '');

    return { isNegativeNo: isNumeric(value), value };
  }

  return { isNegativeNo: false };
};

export const isFormulaSimpleNumber = (formula: string, currency: Currency): boolean => {
  const formulaWithoutDataFormatting = removeDataTypeFormatting(formula, currency).replaceAll(
    /\s+/g,
    '',
  );

  if (checkIfNegativeBracketNumber(formula).isNegativeNo) {
    return true;
  }

  return isNumeric(formulaWithoutDataFormatting);
};

const formatFormulaByMetricType = (
  formula: string,
  type: DataFormattingType,
  currency: Currency,
): string => {
  if (isFormulaSimpleNumber(formula, currency)) {
    return numberFormatter({
      isCompact: false,
      value: formula,
      type,
      currency,
      roundingPrecision: 'morePrecision',
      negativeValueFormat: NegativeValueType.Sign,
    });
  }

  return formula;
};

export const stripMathFunctionPrefix = (formula: string): string => {
  let strippedFormula = formula;

  MathFunctions.forEach((mathFunction) => {
    if (MathFunctionPrefixMapping[mathFunction.id]) {
      strippedFormula = strippedFormula.replaceAll(
        new RegExp(
          String.raw`${MathFunctionPrefix}\.${generateMathFunctionRegex(mathFunction)}`,
          'g',
        ),
        (capture) => capture.replace(`${MathFunctionPrefix}.`, ''),
      );
    }
  });

  return strippedFormula;
};

export const formatFormulaReceivedFromApi = (
  formula: string,
  type: DataFormattingType,
  currency: Currency,
): string => {
  const formulaFormattedByMetricType = formatFormulaByMetricType(formula, type, currency);

  if (isFormulaSimpleNumber(formula, currency)) {
    return formulaFormattedByMetricType;
  }

  const strippedFormula = stripMathFunctionPrefix(formulaFormattedByMetricType);

  return unEscapeCharsInStrings(strippedFormula);
};

export const detectDimensionAggregation = (
  isFormulaeEmpty: boolean,
  formulaMetricType: DataFormattingType,
  metricDimensions: Dimension[],
): DimensionAggregationTypes | undefined => {
  if (metricDimensions.length === 0 || !isFormulaeEmpty) {
    return undefined;
  }

  if (formulaMetricType === DataFormattingType.Percentage) {
    return DimensionAggregationTypes.Average;
  }

  return DimensionAggregationTypes.Sum;
};

export const detectTimeAggregation = (
  isFormulaeEmpty: boolean,
  formulaMetricType: DataFormattingType,
  formula: string,
  metricName: string,
  metricTimeAgg: TimeAggregationTypes,
): TimeAggregationTypes | undefined => {
  if (!isFormulaeEmpty || metricTimeAgg === TimeAggregationTypes.Constant) {
    return undefined;
  }

  if (formula.includes(metricName)) {
    return TimeAggregationTypes.EndOfPeriod;
  }

  if (formulaMetricType === DataFormattingType.Percentage) {
    return TimeAggregationTypes.Average;
  }

  return TimeAggregationTypes.Sum;
};

const generateMathFunctionRegex = (mathFunction: MathFunction) => {
  // Lookahead regex to match 'func('
  return String.raw`\b${mathFunction.id}\s?(?=\()`;
};

const findNextOpeningBraceIndex = (str: string, pos: number) => {
  for (let i = pos + 1; i < str.length; i += 1) {
    if (str[i] === '(') {
      return i;
    }
  }

  return -1;
};

const findMatchingClosingBraceIndex = (str: string, pos: number) => {
  if (str[pos] !== '(') {
    return -1;
  }
  let depth = 1;

  for (let i = pos + 1; i < str.length; i += 1) {
    switch (str[i]) {
      case '(':
        depth += 1;
        break;
      case ')':
        depth -= 1;
        if (depth === 0) {
          return i;
        }
        break;
    }
  }

  return -1;
};

export const appendMathFunctionPrefixes = (formula: string): string => {
  let prefixedFormula = formula;

  MathFunctions.forEach((mathFunction) => {
    const prefix = MathFunctionPrefixMapping[mathFunction.id]
      ? `${MathFunctionPrefixMapping[mathFunction.id]}.`
      : '';

    prefixedFormula = prefixedFormula.replaceAll(
      new RegExp(generateMathFunctionRegex(mathFunction), 'gi'),
      `${prefix}${mathFunction.id} `,
    );
  });

  return prefixedFormula;
};

const findAllFunctionIndices = (formula: string) => {
  const mathFunctionNames = MathFunctions.map((f) => f.id);
  const functionRegex = String.raw`(${mathFunctionNames.join('|')})\s*?\(`;
  const matches = formula.matchAll(new RegExp(functionRegex, 'gi'));
  const functionIndices = [];

  for (const match of matches) {
    if (match.index !== undefined) {
      const openBracePos = findNextOpeningBraceIndex(formula, match.index);

      if (openBracePos !== -1) {
        const closeBracePos = findMatchingClosingBraceIndex(formula, openBracePos);

        if (closeBracePos !== -1) {
          functionIndices.push([match.index, closeBracePos]);
        }
      }
    }
  }

  return functionIndices;
};

// Remove commas for all numbers which are outside any function braces.
export const stripAllNonFunctionCommas = (formula: string): string => {
  const functionIndices = findAllFunctionIndices(formula);

  return formula.replaceAll(',', (match, offset) => {
    if (functionIndices.some((indices) => indices[0] <= offset && offset <= indices[1])) {
      return ',';
    }

    return '';
  });
};

export const hasFormulaChanged = (
  existingFormula: string,
  newFormula: string,
  currency: Currency,
): boolean => {
  const formattedFormula = isFormulaSimpleNumber(existingFormula.replaceAll(',', ''), currency)
    ? existingFormula.replaceAll(',', '')
    : existingFormula;

  return formattedFormula.trim() !== newFormula.trim();
};

export const escapeCharsInStrings = (rawFormula: string): string => {
  const segments: string[] = [];
  let startIndex = 0;

  for (let i = 0; i < rawFormula.length; i += 1) {
    if ((rawFormula[i] === "'" || rawFormula[i] === '"') && isValidQuote(rawFormula, i)) {
      segments.push(rawFormula.substring(startIndex, i));
      startIndex = i + 1;
    }
  }

  if (startIndex !== rawFormula.length) {
    segments.push(rawFormula.substring(startIndex, rawFormula.length));
  }

  if (segments.length % 2 !== 0) {
    const parsedSegments = [];

    for (let i = 0; i < segments.length; i += 1) {
      if (i % 2 === 0) {
        parsedSegments.push(` ${trimFormula(segments[i])} `);
      } else {
        parsedSegments.push(encodeString(segments[i]));
      }
    }

    return parsedSegments.join("'");
  }

  return rawFormula;
};

const isValidQuote = (rawFormula: string, position: number) => {
  let backslashCount = 0;
  let currentIndex = position - 1;

  while (rawFormula[currentIndex] === '\\' && currentIndex >= 0) {
    backslashCount += 1;
    currentIndex -= 1;
  }

  return backslashCount % 2 === 0;
};

const unEscapeCharsInStrings = (rawFormula: string) => {
  let formula = rawFormula;
  const segments = formula.split("'");

  if (segments.length % 2 !== 0) {
    const parsedSegments = [];

    for (let i = 0; i < segments.length; i += 1) {
      if (i % 2 === 0) {
        parsedSegments.push(segments[i]);
      } else {
        parsedSegments.push(decodeString(segments[i]));
      }
    }

    formula = parsedSegments.join("'");

    return formula;
  }

  return rawFormula;
};

const replacePercent = (rawFormula: string): string => {
  let formula = rawFormula;
  const metricTags: string[] = [];

  formula = formula.replace(
    new RegExp(FormulaExpressionMapping.MetricExpression as RegExp, 'g'),
    (match) => {
      metricTags.push(match);

      return '{metric}';
    },
  );

  formula = formula.replaceAll(
    /(\d+)(\.\d+)?%/g,
    (match) => `${parseFloat(match.replace('%', '')) / 100}`,
  );

  formula = formula.replace(/{metric}/g, () => {
    return metricTags.shift() ?? '';
  });

  return formula;
};

export const replacePercentNumbersWithNumerals = (rawFormula: string): string => {
  let formula = rawFormula;
  const segments = formula.split("'");

  if (segments.length % 2 !== 0) {
    const parsedSegments = [];

    for (let i = 0; i < segments.length; i += 1) {
      if (i % 2 === 0) {
        parsedSegments.push(replacePercent(segments[i]));
      } else {
        parsedSegments.push(segments[i]);
      }
    }

    formula = parsedSegments.join("'");

    return formula;
  }

  return replacePercent(rawFormula);
};

export const trimFormula = (rawFormula: string): string => {
  let formula = rawFormula.replace(/^\s*?=/, '').trim();

  const metricTags: string[] = [];

  // Remove all metric tags. Metric tags might contain operators which should be
  formula = formula.replace(
    new RegExp(FormulaExpressionMapping.MetricExpression as RegExp, 'g'),
    (match) => {
      metricTags.push(match);

      return '{metric}';
    },
  );

  const datasetDimTags: string[] = [];

  // Remove all dataset dim property tags. Dim tags might contain operators which should be
  formula = formula.replace(
    new RegExp(FormulaExpressionMapping.DatasetExpression as RegExp, 'g'),
    (match) => {
      datasetDimTags.push(match);

      return '{datasetDim}';
    },
  );

  const quoteSubStrs: string[] = [];

  // remove all substrings with quotes
  formula = formula.replace(/'([^']*)'/g, (match) => {
    quoteSubStrs.push(match);

    return '{quoteSubStrs}';
  });

  // Add a space before and after operator. Two char operators should come first in list so that they get matched first.
  formula = formula.replaceAll(/==|!=|<=|>=|=|<|>|\+|-|\*|\/|\^|\(|\)|,/g, ' $& ');

  // Add metric tags back to formula
  formula = formula.replace(/{metric}/g, () => {
    return metricTags.shift() ?? '';
  });

  // Add dataset dim tags back to formula
  formula = formula.replace(/{datasetDim}/g, () => {
    return datasetDimTags.shift() ?? '';
  });

  // Add substrings with qoutes back to formula
  formula = formula.replace(/{quoteSubStrs}/g, () => {
    return quoteSubStrs.shift() ?? '';
  });

  return formula.replaceAll(/\s+/gm, ' ').trim();
};

// Removes all whitespaces except those within qoutes
const removeWhitespaces = (str: string) => {
  const PLACEHOLDER = '__PLACEHOLDER__';

  const quotePattern = /'([^']*)'/g;
  const placeholders: string[] = [];
  const tempStr = str.replace(quotePattern, (match) => {
    placeholders.push(match);

    return PLACEHOLDER;
  });

  const whitespacePattern = /\s+/g;
  const modifiedStr = tempStr.replace(whitespacePattern, ' ');
  const restorationRegex = new RegExp(String.raw`${PLACEHOLDER}`, 'g');

  return modifiedStr.replace(restorationRegex, () => placeholders.shift() ?? '');
};

// adds a space before and after operators except those within square brackets (subscripts)
const spaceOutOperators = (str: string) => {
  const PLACEHOLDER = '__PLACEHOLDER__';

  const squareBracketPattern = /\[([^\]]*)\]/g;
  const placeholders: string[] = [];
  const tempStr = str.replace(squareBracketPattern, (match) => {
    placeholders.push(match);

    return PLACEHOLDER;
  });

  const operatorsPattern = /==|!=|<=|>=|&&|\|\||=|<|>|\+|-|\*|\/|\^|\(|\)|,/g;
  const modifiedStr = tempStr.replaceAll(operatorsPattern, ' $& ');
  const restorationRegex = new RegExp(String.raw`${PLACEHOLDER}`, 'g');

  return modifiedStr.replace(restorationRegex, () => placeholders.shift() ?? '');
};

export const compressFormulaString = (str: string): string => {
  const strWithStrippedWhitespaces = removeWhitespaces(str);

  const strWithSpaceSurroundingOperators = spaceOutOperators(strWithStrippedWhitespaces);

  return strWithSpaceSurroundingOperators.replace(new RegExp(' {2,}', 'g'), ' '); // replace double spaces with a single space;
};
