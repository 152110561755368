import { type ColDef } from 'ag-grid-community';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { MoreActionsDropdown } from 'components/ui/atomic-components';
import {
  DeleteConfirmWithUsage,
  ResourceUsageModal,
  useResourceUsageWidget,
} from 'components/ui/resource-usage-widget';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { AccessAction, PermissionList } from 'data/roles/permissions';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { b } from 'styles/html-elements';
import { formatName } from 'utils/data-formatter';
import { downloadGridAsExcel } from 'utils/grid';
import { useHasScope } from 'utils/hooks';
import { queueMacroTask } from 'utils/queue-macro-task';
import { GridTopBar } from '../../grid-top-bar';
import { EditAccessControlModal } from '../../right-section/secondary-top-bar/more-actions/edit-access-control-modal';
import { RemoveAccessControlModal } from '../../right-section/secondary-top-bar/more-actions/remove-access-control-modal';
import { EditDimensionGroupModal } from './edit-dimension-group-modal';
import { getMoreActionsOptions } from './more-actions-menu';
import { DimensionGroupPivotKeyEditModal } from './pivot-key-edit-modal';

interface Props {
  dimensionGroup: DimensionGroup;
  toggleShowLeftNav: () => void;
  showLeftNav: boolean;
}

export const DimensionGroupTopNav = ({
  dimensionGroup,
  toggleShowLeftNav,
  showLeftNav,
}: Props): ReactElement => {
  const hasScope = useHasScope();
  const isAdmin = hasScope(PermissionList.All);

  const gridApi = useListStoreContext((s) => s.gridApi);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const accessControlled = useListStoreContext((s) => s.accessControlled);

  const { deleteDimensionGroup } = useDimensionGroupMutations();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPivotKeyModal, setOpenPivotKeyModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editAccessModalVisible, setIsEditAccessModalVisible] = useState(false);
  const [removeAccessModalVisible, setIsRemoveAccessModalVisible] = useState(false);

  const isReadOnly =
    accessAction !== AccessAction.FullAccess ||
    isInVersionMode([VersionAccessModes.View, VersionAccessModes.Edit]);

  const [isMoreActionsMenuOpen, setIsMoreActionsMenuOpen] = useState(false);
  const { usageWidgetMenuItem, usageModalProps, isTryingToCloseWhileUsageFetch, closeUsageModal } =
    useResourceUsageWidget({
      name: dimensionGroup.name,
      displayName: dimensionGroup.displayName || '',
      type: 'DIMENSION_GROUPS',
      closeMenu: () => setIsMoreActionsMenuOpen(false),
    });

  const onDownloadAsExcel = () => {
    downloadGridAsExcel({
      gridApi,
      fileName: dimensionGroup.name,
      columnKeys: gridApi
        ?.getColumnDefs()
        ?.filter((col: ColDef) => !col?.hide)
        ?.slice(1) // skip the # column
        ?.map((col: ColDef) => col.colId || ''),
    });
  };

  const onClickAccessControl = () => {
    if (accessControlled) {
      setIsRemoveAccessModalVisible(true);
    } else {
      setIsEditAccessModalVisible(true);
    }
  };

  const onClickUniqueKeyOption = () => setOpenPivotKeyModal(true);

  const menuItems = getMoreActionsOptions({
    usageWidgetMenuItem,
    onDelete: () => setOpenDeleteModal(true),
    onEdit: () => setOpenEditModal(true),
    onDownload: onDownloadAsExcel,
    onClickAccessControl,
    onClickUniqueKeyOption,
    isAdmin,
    isReadOnly,
    accessControlled,
  });

  const handleDelete = async () => {
    await deleteDimensionGroup.mutateAsync({ ...dimensionGroup });

    setOpenDeleteModal(false);
  };

  return (
    <>
      <GridTopBar
        isDerivedList
        isReadOnly={isReadOnly}
        listName={dimensionGroup.displayName || ''}
        rightItems={
          <MoreActionsDropdown
            menu={{ items: menuItems }}
            open={isMoreActionsMenuOpen}
            onOpenChange={(toOpen) => {
              queueMacroTask(() => {
                if (isTryingToCloseWhileUsageFetch(toOpen)) {
                  return;
                }

                setIsMoreActionsMenuOpen(toOpen);
              });
            }}
          />
        }
        showLeftNav={showLeftNav}
        toggleShowLeftNav={toggleShowLeftNav}
      />

      <EditDimensionGroupModal
        dimensionGroup={dimensionGroup}
        open={openEditModal}
        setOpen={setOpenEditModal}
      />

      <DimensionGroupPivotKeyEditModal
        dimensionGroup={dimensionGroup}
        open={openPivotKeyModal}
        setOpen={setOpenPivotKeyModal}
      />

      <DeleteConfirmWithUsage
        displayName={dimensionGroup.displayName || ''}
        name={dimensionGroup.name}
        noUsagesMessage={
          <FormattedMessage
            id="delete_modal_formula_del.list.question"
            values={{ list: formatName(dimensionGroup.name), b }}
          />
        }
        open={openDeleteModal}
        title={<FormattedMessage id="lists.delete_list_modal.title" />}
        type="DIMENSION_GROUPS"
        onCancel={() => setOpenDeleteModal(false)}
        onOk={handleDelete}
      />

      <ResourceUsageModal usageProps={usageModalProps} onClose={closeUsageModal} />

      <EditAccessControlModal
        isDerivedList
        open={editAccessModalVisible}
        onClose={() => setIsEditAccessModalVisible(false)}
      />

      <RemoveAccessControlModal
        isDerivedList
        visible={removeAccessModalVisible}
        onCancel={() => setIsRemoveAccessModalVisible(false)}
      />
    </>
  );
};
