import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelVersionApi } from '../api';
import { type ModelVersion } from '../types';
import { ModelVersionStoreKeys } from './constants';

export const useModelVersion = ({
  includeCurrent = false,
  includeActual = false,
  includeArchived = false,
}: {
  includeCurrent?: boolean;
  includeActual?: boolean;
  includeArchived?: boolean;
} = {}): UseQueryResult<ModelVersion[]> => {
  return useQuery(
    [...ModelVersionStoreKeys.QueryKey, includeCurrent, includeActual, includeArchived],
    () => ModelVersionApi.getVersions({ includeCurrent, includeActual, includeArchived }),
    {
      staleTime: StaleTime.Long,
    },
  );
};
