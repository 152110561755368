import styled from 'styled-components';

export const styles = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  RightSection: styled.div`
    flex: 1;
    padding-bottom: 64px;
  `,
  GridWrapper: styled.div`
    padding: ${({ theme }) => theme.spacing[16]};
    padding-top: 0;
    height: 100%;
  `,
};
