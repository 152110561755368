import { type ColDef, type GridApi, type GridReadyEvent } from 'ag-grid-community';
import { type TraceOutputTerm } from 'data/formula-trace/types';
import { useEffect, useMemo, useState, type ReactElement } from 'react';
import { useFormulaTraceStore } from '../store';
import { getDimsLabelForCellComponent } from '../utils/get-dims-label';
import styles from './styles';
import { useColDefs } from './use-col-defs';

const CellHeight = 32;
const { StyledGrid } = styles;

interface Props {
  isLoading: boolean;
  parentTerm: TraceOutputTerm;
  terms: TraceOutputTerm[];
}

export const Grid = ({ isLoading, parentTerm, terms }: Props): ReactElement => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const requestSource = useFormulaTraceStore(
    (state) => state.traceNode?.payload?.resourceInfo?.requestSource,
  );

  const colDefs: ColDef[] = useColDefs();

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const sameMetricsAsParent = terms?.every((t) => t?.metricName === parentTerm?.metricName);

  const rowData = useMemo(() => {
    return terms.map((t) => {
      const dimsLabel = getDimsLabelForCellComponent({
        cell: t,
        parentCell: parentTerm,
        sameMetricsAsParent,
        showVersion: requestSource !== 'MMPP',
      });
      const ext = dimsLabel ? ` (${dimsLabel})` : '';
      const componentLabel = sameMetricsAsParent ? dimsLabel : `${t.metricDisplayName}${ext}`;

      return {
        name: componentLabel,
        value: t.value,
        term: t,
      };
    });
  }, [parentTerm, requestSource, sameMetricsAsParent, terms]);

  useEffect(() => {
    gridApi?.setGridOption('loading', isLoading);
  }, [gridApi, isLoading]);

  return (
    <StyledGrid
      columnDefs={colDefs}
      headerHeight={CellHeight}
      rowData={rowData}
      rowHeight={CellHeight}
      tooltipShowDelay={1}
      onGridReady={onGridReady}
    />
  );
};
