import { type FC } from 'react';
import { Helmet } from 'react-helmet-async';

const TITLE_DELIMITER = ' | ';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HelmetProxy: any = Helmet;

export const HtmlHead: FC<
  React.PropsWithChildren<{
    titles?: string[];
  }>
> = ({ titles }) => {
  return (
    <HelmetProxy>
      <title>{titles?.join(TITLE_DELIMITER)}</title>
    </HelmetProxy>
  );
};
