import { type BaseCellEditor } from 'ag-grid-community';
import { type CustomCellEditorProps } from 'ag-grid-react';
import { Input } from 'components/ui/atomic-components';
import {
  type KeyboardEvent,
  forwardRef,
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { queueMacroTask } from 'utils/queue-macro-task';
import { checkIfLastRow } from '../utils';
import { CellEditorPopupBaseStyles } from '../utils/cell-editor-styles';
import { useCloseOnClickOutside } from '../utils/hooks/use-close-on-click-outside';
import { generateInitialValue } from './utils';

const StyledInput = styled(Input)<{ $width: number }>`
  ${CellEditorPopupBaseStyles};

  padding: ${({ theme: { spacing } }) => `0 ${spacing[12]}`};
`;

export const NumberCellEditor = forwardRef<BaseCellEditor, CustomCellEditorProps>(
  (
    {
      value,
      column,
      api,
      rowIndex,
      eventKey,
      initialValue,
      onKeyDown: defaultOnKeyDown,
      stopEditing,
      onValueChange,
    },
    ref,
  ) => {
    const wrapperDomRef = useRef<HTMLDivElement>(null);

    const cellWidth = column.getActualWidth();

    const [inputValue, setInputValue] = useState<string | undefined>(
      generateInitialValue({ value, eventKey }),
    );

    const onStopEditing = useCallback(() => {
      onValueChange(inputValue);
      stopEditing(true);
    }, [inputValue, onValueChange, stopEditing]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Escape') {
        setInputValue(initialValue);
        onValueChange(initialValue);
        queueMacroTask(() => stopEditing(true));
      }

      if (e.code === 'Tab') {
        onValueChange(inputValue);
        stopEditing(true);
        queueMacroTask(() => defaultOnKeyDown(e as unknown as globalThis.KeyboardEvent));
      }

      if (e.code === 'Enter') {
        const isLastRow = checkIfLastRow({ rowIndex: rowIndex + 1, api });

        onValueChange(inputValue);
        stopEditing(isLastRow);
      }
    };

    useCloseOnClickOutside(wrapperDomRef, onStopEditing);

    useImperativeHandle(ref, () => {
      return {
        isCancelAfterEnd: () => inputValue === initialValue,
      };
    });

    return (
      <div ref={wrapperDomRef}>
        <StyledInput
          $width={cellWidth}
          autoFocus
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            onValueChange(e.target.value);
          }}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  },
);

NumberCellEditor.displayName = 'NumberCellEditor';
