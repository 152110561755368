import { type FormInstance } from 'antd';
import { type ChangeEventHandler } from 'react';

// Handler to restrict multiple spaces
export const onChangeWithSpaceHandling = (
  form: FormInstance,
  fieldName: string,
): ChangeEventHandler<HTMLInputElement> => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    // Replace multiple spaces with a single space
    const modifiedValue = value.replace(/\s{2,}/g, ' ');

    form.setFieldsValue({ [fieldName]: modifiedValue });
  };

  return onChange;
};
