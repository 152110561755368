import { type RadioProps } from 'antd';
import {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
  css,
} from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { controlLabelStyles } from '../utils/text-color';

export const radioStyles = ({
  theme,
}: RadioProps & { theme: DefaultTheme }): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-radio-inner {
      transition: none;
      height: ${switchProp(prop('size', 'middle'), {
        extraSmall: '14px',
        small: '16px',
        middle: '20px',
        large: '24px',
      })};
      width: ${switchProp(prop('size', 'middle'), {
        extraSmall: '14px',
        small: '16px',
        middle: '20px',
        large: '24px',
      })};
      border-radius: 50%;
      border: ${switchProp(prop('size', 'middle'), {
          extraSmall: '1.5px',
          small: '1.75px',
          middle: '1.75px',
          large: '2px',
        })}
        solid ${theme.colors.buttonOutlineBorder};
    }

    &:hover {
      &:not(.ant-radio-wrapper-checked):not(.ant-radio-wrapper-disabled) .ant-radio-inner {
        border-color: ${theme.colors.gray300};
        background: ${theme.colors.gray50};
      }

      &.ant-radio-wrapper-checked .ant-radio-inner {
        background-color: ${theme.colors.buttonFillHoverPrimary};
      }
    }

    .ant-radio-input:focus-visible + .ant-radio-inner {
      outline: 1px solid ${theme.colors.controlOutline};
      outline-offset: 2px;
    }

    .ant-radio-disabled {
      .ant-radio-inner {
        background-color: ${theme.colors.bgDark};
      }
    }

    .ant-radio-checked {
      .ant-radio-input:focus-visible + .ant-radio-inner {
        outline: 1px solid ${theme.colors.primary300};
        outline-offset: 2px;
      }

      &.ant-radio-disabled .ant-radio-inner {
        background-color: ${theme.colors.primary200};

        ::after {
          transform: scale(1);
          background-color: ${theme.colors.gray00};
        }
      }
    }

    .ant-radio-checked .ant-radio-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;

      ::after {
        transform: scale(1);
        inset-block-start: unset;
        inset-block-end: unset;
        margin-block-start: unset;
        margin-block-end: unset;
        inset-inline-start: unset;
        margin-inline-start: unset;
        transition: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${switchProp(prop('size', 'middle'), {
          extraSmall: '4.67px',
          small: '5.83px',
          middle: '7px',
          large: '9.33px',
        })};
        width: ${switchProp(prop('size', 'middle'), {
          extraSmall: '4.67px',
          small: '5.83px',
          middle: '7px',
          large: '9.33px',
        })};
      }
    }

    & span.ant-radio + * {
      ${controlLabelStyles({ theme })}
    }

    .ant-radio-disabled + span {
      color: ${theme.colors.textPlaceholder};
    }
  `;
};
