import { type ReactElement } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

const BoldedLabel = styled(Highlighter)`
  .bolden {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    padding: 0;
    background: none;
  }
`;

export const getBoldedPatternInLabel = ({
  label,
  searchQuery,
}: {
  label: string;
  searchQuery: string;
}): ReactElement => {
  return (
    <BoldedLabel
      autoEscape
      highlightClassName="bolden"
      searchWords={[searchQuery]}
      textToHighlight={label}
    />
  );
};
