import { Space, Spinner } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const SpaceWrap = styled.div`
  max-width: 384px;
  text-align: center;
  margin: ${({ theme: { spacing } }) => spacing[20]} auto;

  ${Space} {
    display: inline-flex;
  }
`;

export const GlobalSearchLoadingMessage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SpaceWrap>
      <Space>
        <Spinner />
        <FormattedMessage id="searching" />
      </Space>
    </SpaceWrap>
  );
};
