import { ReactComponent as EmptyIcon } from 'assets/v2/empty.svg';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => theme.spacing[16]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.textBody2};

  svg {
    color: ${({ theme }) => theme.colors.gray300};
  }
`;

export const EmptyMatch = (): ReactElement => {
  return (
    <Wrapper>
      <EmptyIcon />
      <span>
        <FormattedMessage id="no_results_found" />
      </span>
    </Wrapper>
  );
};
