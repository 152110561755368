import { Button } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray600};
  text-decoration: underline;

  &&&:hover {
    color: ${({ theme }) => theme.colors.gray600};
  }
`;

interface Props {
  showGrid: boolean;
  onToggle: () => void;
}

export const DuplicateValuesAlertContent = ({ showGrid, onToggle }: Props): ReactElement => {
  return (
    <Wrapper>
      <FormattedMessage id="dimension_groups.create_edit_form.duplicate_rows.duplicates_found" />
      <StyledButton size="small" type="link" onClick={onToggle}>
        {showGrid ? (
          <FormattedMessage id="dimension_groups.create_edit_form.duplicate_rows.hide_values" />
        ) : (
          <FormattedMessage id="dimension_groups.create_edit_form.duplicate_rows.show_values" />
        )}
      </StyledButton>
    </Wrapper>
  );
};
