import localforage from 'localforage';

export const loadLocalData = async (
  listId: number | string,
): Promise<{
  columnWidthMap: Record<string, number>;
}> => {
  const data = (await localforage.getItem(`list-${listId}`)) as {
    columnWidthMap: Record<string, number>;
  };

  return data;
};

export const saveLocalData = ({
  activeListId,
  updatedColumnWidthMap,
}: {
  activeListId: number | string | null;
  updatedColumnWidthMap?: Record<string, number>;
}): void => {
  if (!activeListId) {
    return;
  }

  localforage.setItem(`list-${activeListId}`, {
    columnWidthMap: updatedColumnWidthMap,
  });
};
