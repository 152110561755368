import { type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

const Label = styled.div<{ selected: boolean }>`
  ${FontXs};

  height: 28px;
  width: 100%;
  color: ${({ theme, selected }) => (selected ? theme.colors.textTitle1 : theme.colors.textTitle2)};
  background-color: ${({ theme, selected }) => {
    return selected ? theme.colors.bgMedium : theme.colors.gray00;
  }};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[8]}`};
  font-weight: ${({ theme, selected }) => {
    return selected ? theme.fontWeight.medium : theme.fontWeight.regular;
  }};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.bgMedium};
  }
`;

export const CategoriesSelector = ({
  categories,
  selectedKey,
  setSelectedCategory,
}: {
  categories: string[];
  selectedKey: string;
  setSelectedCategory: (category: string) => void;
}): ReactElement => {
  return (
    <>
      {categories.map((c) => (
        <Label key={c} selected={c === selectedKey} onClick={() => setSelectedCategory(c)}>
          {c}
        </Label>
      ))}
    </>
  );
};
