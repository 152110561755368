import { NBSP } from 'config/constants';
import { compressFormulaString } from '../utils';

//  Indent a formula with respect to opening '(' and closing ')' braces

//  Input:
//  IF(a==b,0,IF(c=d),1,2)

//  Output:
//  IF (
//      a == b,
//      0,
//      IF (
//          c == d,
//          1,
//          2
//      )
//  )

const INDENT_SIZE = 4;

export const indentFormula = (str?: string): string => {
  if (!str) {
    return '';
  }

  const formula = compressFormulaString(str);

  let indentLevel = 0;
  let indentFormula = '';

  const indent = NBSP.repeat(INDENT_SIZE);

  for (let i = 0; i < formula.length; i += 1) {
    if (formula[i] === '(') {
      indentLevel += 1;
      indentFormula += `(\n${indent.repeat(indentLevel)}`;
    } else if (formula[i] === ')') {
      indentLevel -= 1;
      indentFormula += `\n${indent.repeat(indentLevel)})`;
    } else if (formula[i] === ',') {
      indentFormula += `,\n${indent.repeat(indentLevel)}`;
    } else {
      indentFormula += formula[i];
    }
  }

  return indentFormula.trim();
};
