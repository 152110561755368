import { QueryClient } from '@tanstack/react-query';

export const setupQueryClient = (): QueryClient => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  return queryClient;
};

export enum StaleTime {
  Shortest = 1 * 60 * 1000,
  Shorter = 3 * 60 * 1000,
  Short = 5 * 60 * 1000,
  Default = 10 * 60 * 1000,
  Long = 30 * 60 * 1000,
  Last = Infinity,
}
