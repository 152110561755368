import { Button } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontS } from 'styles/typography';

export default {
  ItemWrapper: styled.div`
    ${FontS}

    color: ${({ theme }) => theme.colors.textTitle1};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    width: 180px;
    display: flex;
    justify-content: space-between;
  `,

  ControlItem: styled(Button)`
    display: flex;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.gray00};
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[12]}`};
    cursor: pointer;
  `,
};
