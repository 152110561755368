import { lazy, type ComponentType, type LazyExoticComponent } from 'react';

interface LazyPreload<Props>
  extends LazyExoticComponent<React.ComponentType<React.PropsWithChildren<Props>>> {
  preload: () => Promise<unknown>;
}

export const lazyWithPreload = <T>(
  importStatement: () => Promise<{ default: ComponentType<React.PropsWithChildren<T>> }>,
): LazyPreload<T> => {
  // use Object.assign to set preload
  // otherwise it will complain that Component doesn't have preload
  const Component: LazyPreload<T> = Object.assign(lazy(importStatement), {
    preload: importStatement,
  });

  return Component;
};
