import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { G2_REVIEW_KEY, MAX_VIEW_LIMIT, getToken } from './utils';

export const useShowPopupTimer = (): { viewCount: number; token: string } => {
  const [popupViewCount, setPopupViewCount] = useState(0);
  const [token, setToken] = useState('');

  useEffect(() => {
    setTimeout(async () => {
      const obj = localStorage.getItem(G2_REVIEW_KEY);
      const { lastViewedTime, viewCount = 0 } = JSON.parse(obj || '{}');

      setPopupViewCount(viewCount);
      if (viewCount >= MAX_VIEW_LIMIT) {
        return;
      }
      if (lastViewedTime) {
        const prevDate = dayjs(lastViewedTime).get('date');
        const currentDate = dayjs().get('date');

        if (currentDate === prevDate) {
          return;
        }
      }
      setToken(await getToken());

      localStorage.setItem(
        G2_REVIEW_KEY,
        JSON.stringify({
          lastViewedTime: dayjs(),
          viewCount: viewCount + 1,
        }),
      );
    }, 30000);
  }, []);

  return {
    viewCount: popupViewCount,
    token,
  };
};
