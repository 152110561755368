import { css } from 'styled-components';

export const FullscreenStyle = css`
  .fullscreen-enabled {
    overflow: auto;

    /* Black background fix for some browsers */
    background: ${({ theme: { colors } }) => colors.black10};
  }
`;
