/* eslint-disable @calm/react-intl/missing-formatted-message */
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { FormConnectorStyles, PopupModalStyles } from '../styles';

const { StyledP, StyledOL, StyledLI } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer } = FormConnectorStyles;

export const RipplingInfoContent = ({ item }: { item: IntegrationModalContent }): ReactElement => {
  return (
    <LeftPanel>
      <ImageContainer alt="" src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)} />
      <Title>{item.displayName}</Title>
      <StyledP>
        <FormattedMessage id="integrations.rippling.info.desc" />
      </StyledP>

      <StyledP>
        <FormattedMessage id="integrations.rippling.info.prerequisites" />
      </StyledP>

      <StyledOL>
        <StyledLI>API Key</StyledLI>
      </StyledOL>
    </LeftPanel>
  );
};
