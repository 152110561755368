import { type ListGridRow } from 'components/modules/modelling/lists/right-section/grid/types';
import { QueryTableColumnDataType } from 'data/big-query';
import { type ListConfig } from 'data/modelling/lists';
import { DEFAULT_DATE_FORMAT } from 'data/modelling/lists/constants';

export const cleanupConfigOnColumnUpdate = ({
  oldConfig,
  oldColumnName,
  updatedColumnInfo,
}: {
  oldConfig: ListConfig;
  oldColumnName: string;
  updatedColumnInfo: { columnName: string; type: QueryTableColumnDataType; dateFormat?: string };
}): ListConfig => {
  const { columnName, type, dateFormat } = updatedColumnInfo;

  return {
    ...oldConfig,
    columnOrder: oldConfig.columnOrder.map((col) => {
      if (col === oldColumnName) {
        return updatedColumnInfo.columnName;
      }

      return col;
    }),
    columnTypeMap: {
      ...oldConfig.columnTypeMap,
      [columnName]: type,
    },
    dateFormatMap: {
      ...(oldConfig.dateFormatMap || {}),
      ...(type === QueryTableColumnDataType.Date
        ? { [columnName]: dateFormat || DEFAULT_DATE_FORMAT }
        : {}),
    },
    frozenColumns: oldConfig.frozenColumns?.map((col) => {
      if (col === oldColumnName) {
        return updatedColumnInfo.columnName;
      }

      return col;
    }),
    sortedColumn:
      oldConfig.sortedColumn === oldColumnName
        ? updatedColumnInfo.columnName
        : oldConfig.sortedColumn,
    uniqueKey:
      oldColumnName === oldConfig.uniqueKey ? updatedColumnInfo.columnName : oldConfig.uniqueKey,
    uniqueKeyColumns: oldConfig.uniqueKeyColumns?.map((col) => {
      if (col === oldColumnName) {
        return updatedColumnInfo.columnName;
      }

      return col;
    }),
    dataValidation: {
      ...(oldConfig.dataValidation || {}),
      ...(oldConfig?.dataValidation?.[oldColumnName]
        ? { [columnName]: oldConfig?.dataValidation?.[oldColumnName] }
        : {}),
    },
  };
};

export const cleanupRowsOnColumnUpdate = ({
  oldRows,
  oldColumnName,
  newColumnName,
}: {
  oldRows: ListGridRow[];
  oldColumnName: string;
  newColumnName: string;
}): ListGridRow[] => {
  return oldRows.map((row) => {
    const oldValue = row.data[oldColumnName];

    delete row.data[oldColumnName];

    row.data[newColumnName] = oldValue;

    return row;
  });
};
