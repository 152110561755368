import { type MutableRefObject } from 'react';

export const hideEmailFieldInWidget = (
  widgetRef: MutableRefObject<HTMLDivElement | null>,
): void => {
  const inputEmailDom = widgetRef.current?.querySelector('form .o-form-content');
  const separatorDom = widgetRef.current?.querySelector('.sign-in-with-idp .separation-line');
  const nextButtonDom = widgetRef.current?.querySelector('.button.button-primary[value="Next"]');

  [inputEmailDom, separatorDom, nextButtonDom].forEach((dom) => {
    (dom as HTMLElement).style.display = 'none';
  });
};
