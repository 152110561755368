import { type ViewPlugin } from '@codemirror/view';
import {
  type ExpressionSecondaryTextLookup,
  type FormulaAutocompleteOption,
} from 'components/ui/codemirror-v2/formula-bar/types';
import { type Granularity } from 'data';
import { type MonitoringMetric } from 'data/metric-builder';
import { type PlanMetric } from 'data/modelling/metric';
import {
  constructDatasetPropertyMatchPlugin,
  constructDimGroupPropertyMatchPlugin,
  constructListColumnMatchPlugin,
  constructListPropertyMatchPlugin,
} from './dimension-property-construct';
import { constructLastActualsDatePlugin } from './last-actuals-date-construct';
import { constructMetricMatchPlugin } from './metric-construct';
import { constructThisMonthMatchPlugin } from './this-month-construct';
import { constructTimePeriodMatchPlugin } from './time-period-construct';

interface Props {
  metrics: (PlanMetric | MonitoringMetric)[];
  secondaryTextLookup?: ExpressionSecondaryTextLookup;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];
  readOnly?: boolean;
  timeFilterGranularity?: Granularity;
}

export const expressionConstructs = ({
  metrics,
  secondaryTextLookup,
  readOnly,
  customTimeAutoCompletionOptions,
  timeFilterGranularity,
}: Props): ViewPlugin<object>[] => {
  const metricMatchPlugin = constructMetricMatchPlugin(
    metrics,
    readOnly,
    customTimeAutoCompletionOptions,
    timeFilterGranularity,
  );
  const thisMonthMatchPlugin = constructThisMonthMatchPlugin();
  const timePeriodPlugin = constructTimePeriodMatchPlugin();
  const listPropertyPlugin = constructListPropertyMatchPlugin(readOnly, secondaryTextLookup);
  const datasetPropertyPlugin = constructDatasetPropertyMatchPlugin(readOnly, secondaryTextLookup);
  const dimGroupPropertyPlugin = constructDimGroupPropertyMatchPlugin(
    readOnly,
    secondaryTextLookup,
  );
  const lastActualsDatePlugin = constructLastActualsDatePlugin();
  const listColumnMatchPlugin = constructListColumnMatchPlugin(readOnly, secondaryTextLookup);

  return [
    metricMatchPlugin,
    thisMonthMatchPlugin,
    timePeriodPlugin,
    datasetPropertyPlugin,
    dimGroupPropertyPlugin,
    listPropertyPlugin,
    lastActualsDatePlugin,
    listColumnMatchPlugin,
  ];
};
