import { type DimensionQueryParamsObject } from 'data/dimension';
import { encodeObject, decodeObject } from './filter-params-encoder-utils';

const KEY_VALUE_DELIMITER = '~!~';
const ENTRY_DELIMITER = '~@~';
const DIM_VALUE_DELIMITER = '~*~';

export const DimensionFilterParam = {
  encode: (
    obj: { [key: string]: string[] | null | undefined } | null | undefined,
  ): string | null | undefined =>
    encodeObject(obj, KEY_VALUE_DELIMITER, ENTRY_DELIMITER, DIM_VALUE_DELIMITER),

  decode: (
    input: string | (string | null)[] | null | undefined,
  ): DimensionQueryParamsObject | null | undefined =>
    decodeObject(input, KEY_VALUE_DELIMITER, ENTRY_DELIMITER, DIM_VALUE_DELIMITER),
};
