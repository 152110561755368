import { type FormInstance } from 'antd';
import { AsyncDimensionsSelect } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select';
import { Form, Select } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { type FormType } from '../types';
import { useConditionOptions } from './use-condition-options';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[16]};
  width: 240px;
`;

interface Props {
  form: FormInstance<FormType>;
}

export const PopoverContent = ({ form }: Props): ReactElement => {
  const intl = useIntl();
  const conditionOptions = useConditionOptions();

  return (
    <Form form={form}>
      <Wrapper>
        <Form.Item
          label={<FormattedMessage id="lists.data_validation.condition" />}
          name="condition"
        >
          <Select options={conditionOptions} />
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id="lists.data_validation.source_column" />}
          name="sourceColumn"
        >
          <AsyncDimensionsSelect placeholder={intl.formatMessage({ id: 'search' })} size="small" />
        </Form.Item>
      </Wrapper>
    </Form>
  );
};
