import { Chips, Tooltip } from 'components/ui/atomic-components';
import { type PropsWithChildren, type ReactElement, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, type To } from 'react-router-dom';

interface Props {
  navigateTo: To;
  icon?: ReactNode;
}

export const UsagePillWrapper = ({
  navigateTo,
  icon,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <Tooltip title={<FormattedMessage id="resource_usage.modal.open_in_new_tab" />}>
      <Link rel="noopener noreferrer" target="_blank" to={navigateTo}>
        <Chips bgColor="gray100" borderColor="gray200" icon={icon} textColor="textTitle2">
          {children}
        </Chips>
      </Link>
    </Tooltip>
  );
};
