import { type FormInstance } from 'antd';
import { AsyncDimensionsSelect } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select';
import { Form, Select } from 'components/ui/atomic-components';
import { useUsers } from 'data/users';
import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { getUserFullName } from 'utils';
import { type FormType } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[16]};
  width: 240px;
  gap: ${({ theme }) => theme.spacing[4]};
`;

interface Props {
  form: FormInstance<FormType>;
}

export const DataMaskingPopoverContent = ({ form }: Props): ReactElement => {
  const intl = useIntl();
  const { data: allUsers = [] } = useUsers();

  const usersOptions = allUsers.map((u) => ({
    label: getUserFullName(u),
    value: u.username,
  }));

  return (
    <Form form={form}>
      <Wrapper>
        <Form.Item
          label={<FormattedMessage id="lists.data_masking.users_with_full_access" />}
          name="allowedUsers"
        >
          <Select isClearable={false} isMulti isSearchable options={usersOptions} size="large" />
        </Form.Item>

        <Form.Item
          extra={<FormattedMessage id="lists.data_masking.view_summaries_for.extra" />}
          label={<FormattedMessage id="lists.data_masking.view_summaries_for" />}
          name="allowedDimensions"
        >
          <AsyncDimensionsSelect
            isMulti
            placeholder={intl.formatMessage({ id: 'search' })}
            searchAllTables
            onChange={(v) => {
              form.setFieldValue(
                'allowedDimensions',
                v.map((val) => ({
                  label: val.label,
                  value: val.value,
                  name: val.name,
                })),
              );
            }}
          />
        </Form.Item>
      </Wrapper>
    </Form>
  );
};
