export const createInitialState = ({
  eventKey,
  value,
}: {
  eventKey: string | null;
  value?: string;
}): { value?: string } => {
  let startValue;

  if (eventKey === 'Backspace' || eventKey === 'Delete') {
    startValue = '';
  } else {
    startValue = value;
  }

  return {
    value: startValue,
  };
};
