import { useContext } from 'react';
import { PreviewTableContext, type PreviewTableContextParams } from './preview-context';

export const usePreviewTableContext = (): PreviewTableContextParams => {
  const context = useContext(PreviewTableContext);

  if (!context) {
    throw new Error('usePreviewTableContext to be used within PreviewTableContext');
  }

  return context;
};
