import { type GroupBase, type StylesConfig } from 'react-select';
import { defaultTheme } from 'styles/theme';

export const getBgColor = ({
  isDisabled,
  isFocused,
  isSelected,
}: {
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}): string => {
  if (isSelected) {
    return defaultTheme.colors.bgMedium;
  }
  if (isFocused) {
    return defaultTheme.colors.bgMedium;
  }
  if (isDisabled) {
    return defaultTheme.colors.gray100;
  }

  return defaultTheme.colors.gray00;
};
const getColor = ({ isDisabled }: { isDisabled?: boolean }): string => {
  if (isDisabled) {
    return defaultTheme.colors.textDisabled;
  }

  return defaultTheme.colors.textTitle1;
};

export const getCommonStyles = <T, IsMulti extends boolean = boolean>(): StylesConfig<
  T,
  IsMulti,
  GroupBase<T>
> => ({
  control: (base, props) => ({
    ...base,
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    fontWeight: defaultTheme.fontWeight.regular,
    borderRadius: defaultTheme.borderRadius.s,
    border: `1px solid ${defaultTheme.colors.outlineDefault}`,
    padding: `${defaultTheme.spacing[0]} ${defaultTheme.spacing[8]}`,
    boxShadow: 'none',

    ':hover': {
      borderColor: defaultTheme.colors.outlineColored,
    },

    ':focus-within': {
      borderColor: defaultTheme.colors.outlineColored,
    },
  }),

  dropdownIndicator: (base, props) => {
    let color = defaultTheme.colors.iconDefault as string;

    if (props.isDisabled) {
      color = defaultTheme.colors.iconDisabled;
    } else if (props.isFocused) {
      color = defaultTheme.colors.iconHover;
    }

    return {
      ...base,
      padding: 0,
      width: 16,
      color,
      '&:hover': {
        color: defaultTheme.colors.iconHover,
      },
    };
  },

  indicatorSeparator: () => ({}),

  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),

  clearIndicator: (base) => ({
    ...base,
    margin: '-8px 0',
  }),

  menuPortal: (base) => ({
    ...base,
    zIndex: defaultTheme.zIndex[9999],
  }),

  menu: (base) => ({
    ...base,
    borderRadius: defaultTheme.borderRadius.m,
    boxShadow: defaultTheme.shadow.softMedium,
    margin: `${defaultTheme.spacing[4]} 0`,
    zIndex: 9999,
    border: `1px solid ${defaultTheme.colors.outlineDefault}`,
  }),

  menuList: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'column',
    padding: defaultTheme.spacing[4],
    gap: '4px',
  }),

  option: (base, props) => ({
    ...base,
    alignItems: 'center',
    color: getColor({
      isDisabled: props.isDisabled,
    }),
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: defaultTheme.borderRadius.xs,
    fontSize: defaultTheme.fontSize.xs,
    background: getBgColor({
      isFocused: props.isFocused,
      isSelected: props.isSelected,
    }),

    padding: `0 ${defaultTheme.spacing[6]}`,

    a: {
      display: 'block',
      width: '100%',
      color: defaultTheme.colors.gray900,
    },

    ':active': {
      background: defaultTheme.colors.primary50,
    },
  }),

  placeholder: (base) => ({
    ...base,
    color: defaultTheme.colors.textPlaceholder,
    fontSize: defaultTheme.fontSize.s,
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),

  singleValue: (base, props) => ({
    ...base,
    color: props.selectProps.menuIsOpen ? defaultTheme.colors.textBody1 : base.color,
    margin: 0,
  }),

  valueContainer: (base, props) => ({
    ...base,
    fontWeight: defaultTheme.fontWeight.regular,
    padding: 0,
    marginRight: props.isMulti ? 0 : defaultTheme.spacing[8],
    gap: props.isMulti ? defaultTheme.spacing[8] : 0,
  }),

  multiValue: (base) => ({
    ...base,
    margin: 0,
    height: 24,
    background: defaultTheme.colors.primary100,
    fontSize: defaultTheme.fontSize.xs,
    lineHeight: defaultTheme.fontSize.xs,
    borderRadius: defaultTheme.borderRadius.s,
  }),

  multiValueLabel: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    paddingLeft: defaultTheme.spacing[8],
    paddingRight: defaultTheme.spacing[8],
    color: defaultTheme.colors.primary700,
  }),

  multiValueRemove: (base) => ({
    ...base,

    color: defaultTheme.colors.primary700,
    marginLeft: -parseInt(defaultTheme.spacing[4]),
    paddingLeft: 0,

    ':hover': {
      color: defaultTheme.colors.primary700,
    },
  }),
});
