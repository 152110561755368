import { Empty } from 'components/ui/atomic-components';
import { Layout as LayoutAtom } from 'components/ui/atomic-components/layout';
import { LeftNavV2 } from 'components/ui/layout';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { StackTrackBlock } from './stack-trace-block';

const Layout = styled(LayoutAtom)`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const isProdEnv = import.meta.env.PROD;

export const RootErrorBoundary = (): ReactElement => {
  return (
    <Layout>
      <LeftNavV2 />

      <Wrapper>
        {isProdEnv && <Empty title={<FormattedMessage id="error.unexpected" />} />}
        {!isProdEnv && <StackTrackBlock />}
      </Wrapper>
    </Layout>
  );
};
