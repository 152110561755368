import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right.svg';
import { ReactComponent as Database12Icon } from 'assets/v2/database-12.svg';
import { ReactComponent as ListIcon } from 'assets/v2/menu-2.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { Tooltip } from 'components/ui/atomic-components/tooltip';
import { type ExpressionSecondaryTextLookup } from 'components/ui/codemirror-v2/formula-bar/types';
import { useGetTableMeta } from 'data/integrations/fivetran/hooks';
import { useMemo, type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { formatName } from 'utils/data-formatter';
import styles from '../common/styles';
import { ContextWrapper } from '../context-wrapper';

const { TooltipTitle } = styles;

const Wrapper = styled.div`
  ${FontXs};

  display: inline-block;
  padding: 0 ${({ theme }) => theme.spacing[6]};
  color: ${({ theme }) => theme.colors.magenta500};
  background-color: ${({ theme }) => theme.colors.magenta100};
  border-radius: 2px;
  cursor: pointer;
  margin: 0 ${({ theme }) => theme.spacing[2]};
  white-space: nowrap;
`;

interface Props {
  propertyTerm: RegExpExecArray;
  secondaryTextLookup?: ExpressionSecondaryTextLookup;
  readOnly?: boolean;
}

const Tag = ({ propertyTerm, readOnly, secondaryTextLookup }: Props): ReactElement => {
  const tableName = propertyTerm[1];
  const isFromDataset = propertyTerm[0].startsWith('dims.dataset');

  // prevent table metadata API calls in readOnly mode or if it is a list
  const shouldFetchTableName = !readOnly && isFromDataset && !!tableName;
  const { data: tableMeta } = useGetTableMeta(tableName, shouldFetchTableName);

  const tableDisplayName = useMemo(() => {
    return isFromDataset ? tableMeta?.displayName || null : formatName(tableName);
  }, [isFromDataset, tableMeta?.displayName, tableName]);

  const souceTableDisplayName = secondaryTextLookup?.[propertyTerm[0]] || tableDisplayName;

  const propertyName = formatName(propertyTerm[2]);

  const tooltipTitle =
    !readOnly &&
    (tableDisplayName ? (
      <TooltipTitle>
        <IconShell color="iconDefault" icon={isFromDataset ? Database12Icon : ListIcon} />
        {souceTableDisplayName}
        <IconShell color="iconDefault" icon={ChevronRightIcon} />
        {propertyName}
      </TooltipTitle>
    ) : (
      propertyName
    ));

  return (
    <Tooltip placement="bottomLeft" title={tooltipTitle}>
      <Wrapper className="formula-tag">{propertyName}</Wrapper>
    </Tooltip>
  );
};

export const DimensionPropertyTag = ({
  propertyTerm,
  secondaryTextLookup,
  readOnly,
}: Props): ReactElement => {
  return (
    <ContextWrapper>
      <Tag
        propertyTerm={propertyTerm}
        readOnly={readOnly}
        secondaryTextLookup={secondaryTextLookup}
      />
    </ContextWrapper>
  );
};
