import { LoginCallback } from '@okta/okta-react';
import App from 'app';
import { RipplingIntegration } from 'components/modules/settings/integrations/rippling';
import { BoardLastViewRedirect } from 'pages/boards/boards-last-view-redirect';
import { DataRedirect } from 'pages/data/data-redirect';
import { IntegrationCallback } from 'pages/integration-callback';
import { Login } from 'pages/login';
import { LoginHelp } from 'pages/login-help';
import { DimensionsRedirectPage } from 'pages/modelling/dimensions/dimensions-redirect-page';
import { ListsRedirectPage, ListsNewPage } from 'pages/modelling/lists';
import { NotFoundPage } from 'pages/not-found';
import { OutTablesViewer } from 'pages/out-tables-viewer';
import { RolesSettingRedirectionPage } from 'pages/settings/roles-redirect';
import { SignUpWithXero } from 'pages/signup/xero';
import SystemPageViewer from 'pages/system/system-page-viewer';
import { UnderMaintenancePage } from 'pages/under-maintenance';
import { type ReactElement } from 'react';
import { type RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazyWithPreload } from 'utils/lazy-load-with-preload';
import { InitialPageRedirect } from './initial-page-redirect';
import { RootErrorBoundary } from './root-error-boundary';
import { SecureRoute } from './secure-route';
import { PreloadPriority, type RouteObjWithPreload } from './types';

const BoardsPage = lazyWithPreload(() => import('pages/boards'));

const MetricBuilderListPage = lazyWithPreload(() => import('pages/metric-builder/list'));
const MetricBuilderShowPage = lazyWithPreload(() => import('pages/metric-builder/show'));
const AdvancedBuilderPage = lazyWithPreload(() => import('pages/metric-builder/advanced-builder'));
const EditMetricRedirect = lazyWithPreload(
  () => import('pages/metric-builder/edit-metric-redirect'),
);

const ModelDetailPage = lazyWithPreload(() => import('pages/modelling/model/show'));
const ModelsRootFolder = lazyWithPreload(
  () => import('components/modules/modelling/model/root-folder'),
);
const ModelsNestedFolder = lazyWithPreload(
  () => import('components/modules/modelling/model/nested-folder'),
);
const ModuleDetailPage = lazyWithPreload(() => import('pages/modelling/model/modules'));

const ModelTemplatesPage = lazyWithPreload(() => import('pages/modelling/model/model-templates'));
const ModelTemplateContent = lazyWithPreload(
  () => import('pages/modelling/model/model-templates/template-content'),
);

const DimensionsNewPage = lazyWithPreload(() => import('pages/modelling/dimensions/new-page'));
const DimensionsEditorPage = lazyWithPreload(
  () => import('pages/modelling/dimensions/editor-page'),
);
const ListsEditorPage = lazyWithPreload(() => import('pages/modelling/lists/lists-page'));

const SettingsPage = lazyWithPreload(() => import('pages/settings/list'));
const RolesSettingPage = lazyWithPreload(() => import('pages/settings/roles'));
const ConnectedIntegrationsPage = lazyWithPreload(
  () => import('components/modules/settings/fivetran/connected-integrations-page-v2'),
);
const DatasetShowPage = lazyWithPreload(
  () => import('components/modules/settings/fivetran/dataset-show-page'),
);

const DtmlViewerPage = lazyWithPreload(() => import('pages/dtml-viewer'));
const ToolsViewerPage = lazyWithPreload(() => import('pages/tools'));
const ToolsTableViewerPage = lazyWithPreload(() => import('pages/tools/table-view'));
const ToolsHtmlViewerPage = lazyWithPreload(() => import('pages/tools/html-view'));
const DiagnosticsPage = lazyWithPreload(() => import('pages/errors'));
const PageTemplateViewer = lazyWithPreload(() => import('pages/page-template'));
const ChangelogPage = lazyWithPreload(() => import('pages/changelog/changelog-page'));
const AlertsPage = lazyWithPreload(() => import('pages/alerts'));
const HealthPage = lazyWithPreload(() => import('pages/health'));
const ModalTemplatesPage = lazyWithPreload(() => import('pages/model-templates'));

export const routes: RouteObjWithPreload[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/login/callback', element: <LoginCallback /> },
      { path: '/login/help', element: <LoginHelp /> },
      { path: '/signup/xero', element: <SignUpWithXero /> },
      { path: '/integrations/auth-callback', element: <IntegrationCallback /> },
      {
        path: '/',
        element: <SecureRoute />,
        errorElement: <RootErrorBoundary />,
        children: [
          {
            path: '/',
            element: <InitialPageRedirect />,
          },
          {
            path: '/reports',
            element: <BoardLastViewRedirect />,
          },
          {
            path: '/reports/:boardId',
            element: <BoardsPage />,
            preload: BoardsPage.preload,
            preloadPriority: PreloadPriority.High,
          },
          {
            path: '/metrics',
            element: <MetricBuilderListPage />,
            preload: MetricBuilderListPage.preload,
            preloadPriority: PreloadPriority.Low,
          },
          {
            path: '/metrics/edit-redirect/:metricName',
            element: <EditMetricRedirect />,
          },
          {
            path: '/metrics/new',
            element: <MetricBuilderShowPage />,
          },
          {
            path: '/metrics/new/advanced-builder',
            element: <AdvancedBuilderPage />,
          },
          {
            path: '/metrics/:metricName',
            element: <MetricBuilderShowPage />,
          },
          {
            path: '/metrics/:metricName/advanced-builder',
            element: <AdvancedBuilderPage />,
          },
          {
            path: '/lists',
            element: <ListsRedirectPage />,
          },
          {
            path: '/lists/new',
            element: <ListsNewPage />,
          },
          {
            path: '/lists/:listId',
            element: <ListsEditorPage />,
            preload: ListsEditorPage.preload,
          },
          {
            path: 'dimensions',
            element: <DimensionsRedirectPage />,
          },
          {
            path: 'dimensions/new',
            element: <DimensionsNewPage />,
            preload: DimensionsNewPage.preload,
          },
          {
            path: 'dimensions/:dimensionId',
            element: <DimensionsEditorPage />,
            preload: DimensionsEditorPage.preload,
          },
          {
            path: '/models',
            children: [
              {
                path: '',
                element: <ModelDetailPage />,
                preload: ModelDetailPage.preload,
                preloadPriority: PreloadPriority.High,
                children: [
                  {
                    path: '',
                    element: <ModelsRootFolder />,
                    preload: ModelsRootFolder.preload,
                    preloadPriority: PreloadPriority.High,
                  },
                  {
                    path: 'folders/:folderId',
                    element: <ModelsNestedFolder />,
                    preload: ModelsNestedFolder.preload,
                  },
                ],
              },
              {
                path: 'm/:moduleName',
                element: <ModuleDetailPage key="module" />,
                preload: ModuleDetailPage.preload,
                preloadPriority: PreloadPriority.High,
              },
              {
                path: 'm/:moduleName/:varName',
                element: <ModuleDetailPage key="module-variable" />,
                preload: ModuleDetailPage.preload,
                preloadPriority: PreloadPriority.High,
              },
              {
                path: 'changelog',
                element: <ChangelogPage />,
              },
              {
                path: 'model-templates',
                children: [
                  { path: '', element: <ModelTemplatesPage /> },
                  { path: ':templateName', element: <ModelTemplateContent /> },
                ],
              },
            ],
          },
          {
            path: '/settings',
            children: [
              { path: '', element: <SettingsPage />, preload: SettingsPage.preload },
              {
                path: 'roles',
                element: <RolesSettingRedirectionPage />,
              },
              {
                path: 'roles/:roleId',
                element: <RolesSettingPage />,
                preload: RolesSettingPage.preload,
              },
            ],
          },
          {
            path: '/data',
            children: [
              { path: '', element: <DataRedirect /> },
              {
                path: 'connected/:instanceId?',
                element: <ConnectedIntegrationsPage />,
                preload: ConnectedIntegrationsPage.preload,
              },
              {
                path: 'datasets/:instanceId/:tableName',
                element: <DatasetShowPage />,
                preload: DatasetShowPage.preload,
              },
            ],
          },
          {
            path: '/integrations',
            children: [
              {
                path: 'rippling/callback',
                element: <RipplingIntegration sandbox={false} />,
              },
            ],
          },
          {
            path: '/sandbox',
            children: [
              {
                path: 'integrations/rippling/callback',
                element: <RipplingIntegration sandbox />,
              },
            ],
          },
          { path: '/dtml', element: <DtmlViewerPage /> },
          { path: '/tools', element: <ToolsViewerPage /> },
          { path: '/tools/table-view/:toolName', element: <ToolsTableViewerPage /> },
          { path: '/tools/html-view/:toolName', element: <ToolsHtmlViewerPage /> },
          { path: '/diagnostics', element: <DiagnosticsPage /> },
          { path: '/alerts', element: <AlertsPage /> },
          { path: '/health', element: <HealthPage /> },
          { path: '/templates', element: <ModalTemplatesPage /> },
          {
            path: '/page-templates',
            element: <PageTemplateViewer />,
          },
          {
            path: '/page-templates/:templateId',
            element: <PageTemplateViewer />,
          },
          { path: '/under-maintenance', element: <UnderMaintenancePage /> },
          { path: '/system/:classification/:operation?', element: <SystemPageViewer /> },
          { path: '/tools/suppress-dbt-tests', element: <OutTablesViewer /> },
          { path: '*', element: <NotFoundPage /> },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes as RouteObject[]);

export const AppRoutes = (): ReactElement => {
  return <RouterProvider router={router} />;
};
