import { DimensionAggregationTypes, TimeAggregationTypes } from 'data/modelling/metric';
import { FormattedMessage } from 'react-intl';

export const metricTimeAggregationTypes = [
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.sum" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.sum_desc" />,
    value: TimeAggregationTypes.Sum,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.average" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.average_desc" />,
    value: TimeAggregationTypes.Average,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.bop" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.bop_desc" />,
    value: TimeAggregationTypes.BeginningOfPeriod,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.eop" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.eop_desc" />,
    value: TimeAggregationTypes.EndOfPeriod,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.formula" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.formula_desc" />,
    value: TimeAggregationTypes.Formula,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.none" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.none_desc" />,
    value: TimeAggregationTypes.None,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.constant" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.constant_desc" />,
    value: TimeAggregationTypes.Constant,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.count_unique" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.count_unique_desc" />,
    value: TimeAggregationTypes.CountUnique,
  },
  // {
  //   label: <FormattedMessage id="planning.metric.time_aggregation.weighted_avg" />,
  //   description: <FormattedMessage id="planning.metric.time_aggregation.weighted_avg_desc" />,
  //   value: TimeAggregationTypes.WeightedAvg,
  // },
];

export const metricDimAggregationTypes = [
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.sum" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.sum_desc" />,
    value: DimensionAggregationTypes.Sum,
  },
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.average" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.average_desc" />,
    value: DimensionAggregationTypes.Average,
  },
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.formula" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.formula_desc" />,
    value: DimensionAggregationTypes.Formula,
  },
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.none" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.none_desc" />,
    value: DimensionAggregationTypes.None,
  },
  // {
  //   label: <FormattedMessage id="planning.metric.dimension_aggregation.weighted_avg" />,
  //   description: <FormattedMessage id="planning.metric.dimension_aggregation.weighted_avg_desc" />,
  //   value: DimensionAggregationTypes.WeightedAvg,
  // },
];
