import { useMutation, type UseMutateFunction } from '@tanstack/react-query';
import { Modal, Spinner } from 'components/ui/atomic-components';
import { IntegrationsApi, type MergeConnectArgs } from 'data/integrations/fivetran';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { queueMacroTask } from 'utils/queue-macro-task';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing[24]};
  gap: ${({ theme }) => theme.spacing[24]};
  flex-flow: column;
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSize.paragraph};
  color: ${({ theme }) => theme.colors.black900};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const useMergeNavigate = (): {
  setActiveId: Dispatch<SetStateAction<number>>;
  LoadingComponent: JSX.Element | undefined;
  activeId: number;
  connectService: UseMutateFunction<
    {
      data: {
        id: number;
      };
    },
    unknown,
    MergeConnectArgs,
    unknown
  >;
} => {
  const [activeId, setActiveId] = useState(-1);
  const navigate = useNavigate();

  const {
    mutate: connectService,
    isLoading,
    isSuccess,
  } = useMutation((args: MergeConnectArgs) => IntegrationsApi.connectIntegration(args), {
    onSuccess: (data) => {
      setActiveId(data?.data?.id);
    },
  });

  useEffect(() => {
    if (activeId >= 0 && isSuccess) {
      queueMacroTask(() => navigate(`/data/connected/${activeId}?pending=add-data`));
    }
  }, [activeId, isSuccess, navigate]);

  /*
  This component will show up when waiting for the /connect API to get back after user closes the Merge dev modal.
  Since closing event of modal and response of connect API are async, we need to show this to users as feedback.
  The price we pay for smooth UX ;)
  */
  const LoadingComponent = (
    <Modal closable={false} open={activeId === -1 && isLoading}>
      <Wrapper>
        <Spinner size="large" />
        <Label>
          <FormattedMessage id="integrations.connections.merge.loader" />
        </Label>
      </Wrapper>
    </Modal>
  );

  return {
    setActiveId,
    LoadingComponent,
    connectService,
    activeId,
  };
};
