import { type EditorView } from '@codemirror/view';
import { type FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { ListsCustomFormulaEditor } from 'components/modules/modelling/lists/right-section/grid/column-editor/formula-editor';
import { Form } from 'components/ui/atomic-components';
import { type QueryTableColumnDataType } from 'data/big-query';
import { trimFormula } from 'data/formula-editor/utils';
import { type ReactElement, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatName } from 'utils/data-formatter';
import { ColumnNameFormItem } from './column-name-form-item';
import { ColumnTypeSelector } from './column-type-select/selector';
import { useListColumnTypes } from './column-type-select/use-list-column-types';
import { type ColumnType } from './column-type-select/utils';
import { ErrorAlert } from './formula-editor/error-alert';
import { FormulaHeader } from './formula-editor/formula-header';
import styles from './styles';

const { Wrapper, StyledForm } = styles;

export const ColumnEditor = ({
  form,
  colName,
  colType,
  dateFormat,
  colFormula,
  isEdit,
  setEditorView,
  onConfirm,
  editorViewRef,
}: {
  form: FormInstance<{
    name: string;
    type: ColumnType;
    formula?: {
      formulaText: string | undefined;
      query: string | undefined;
    };
    aiEnabledFormula?: boolean;
    error?: string;
  }>;
  editorViewRef: React.MutableRefObject<EditorView | null>;
  colName?: string;
  colType?: QueryTableColumnDataType | 'FORMULA';
  dateFormat?: string;
  colFormula?: {
    formulaText: string | undefined;
    query: string | undefined;
  };
  isEdit?: boolean;
  setEditorView: (editorView: EditorView | null) => EditorView | null;
  onConfirm: () => void;
}): ReactElement => {
  const intl = useIntl();
  const columnOptions = useListColumnTypes({ dateFormat });

  const type = useWatch('type', form);
  const formula = useWatch('formula', form);
  const aiEnabledFormula = useWatch('aiEnabledFormula', form);
  const error = useWatch('error', form);

  const popoverWrapperRef = useRef<HTMLDivElement>(null);

  const onPressEnter = () => {
    if (form.getFieldValue('type') !== 'FORMULA') {
      onConfirm();
    }
  };

  useEffect(() => {
    form.setFieldValue('formula', colFormula);
  }, [colFormula, form]);

  useEffect(() => {
    const type = columnOptions.find((o) => o.key === colType) ?? columnOptions[0];

    form.setFieldValue('type', type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colType, form]);

  const onFormulaChange = (value?: string) => {
    form.setFieldValue('error', undefined);
  };

  const placeholder = intl.formatMessage({
    id: aiEnabledFormula
      ? 'formula_bar.placeholder.write_prompt'
      : 'formula_bar.placeholder.enter_formula',
  });

  const toggleAIButton = () => {
    const trimmedFormula = trimFormula(editorViewRef.current?.state?.doc?.toString() ?? '');

    form.setFieldValue('formula', {
      ...formula,
      [aiEnabledFormula ? 'query' : 'formulaText']: trimmedFormula,
    });
    form.setFieldValue('aiEnabledFormula', !aiEnabledFormula);
  };

  const formulaValue = (aiEnabledFormula ? formula?.query : formula?.formulaText) ?? '';

  return (
    <Wrapper ref={popoverWrapperRef}>
      <StyledForm
        form={form}
        initialValues={{
          name: formatName(colName),
          type: columnOptions.find((o) => o.key === colType) ?? columnOptions[0],
          formula: colFormula,
        }}
        preserve={false}
      >
        <ColumnNameFormItem
          colName={colName}
          form={form}
          isEdit={isEdit}
          popoverWrapperRef={popoverWrapperRef}
          onPressEnter={onPressEnter}
        />

        <Form.Item
          label={<FormattedMessage id="lists.property.type" />}
          name="type"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'lists.property.specify_property' }),
            },
          ]}
        >
          <ColumnTypeSelector columnOptions={columnOptions} disabled={false} />
        </Form.Item>
        <Form.Item
          hidden={type?.key !== 'FORMULA'}
          label={
            <FormulaHeader aiEnabledFormula={!!aiEnabledFormula} toggleAIButton={toggleAIButton} />
          }
          name="formula"
        >
          <ListsCustomFormulaEditor
            aiEnabledFormula={!!aiEnabledFormula}
            formula={formulaValue}
            placeholder={placeholder}
            setEditorView={setEditorView}
            onFormulaChange={onFormulaChange}
          />
        </Form.Item>
        <Form.Item hidden={!error} name="error">
          <ErrorAlert error={error || ''} />
        </Form.Item>
      </StyledForm>
    </Wrapper>
  );
};
