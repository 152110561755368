import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { useFolderCreateMenuItem } from 'components/modules/folders-v2/hooks/use-folder-create-menu-item';
import { Button } from 'components/ui/atomic-components/button';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { PageLayout } from 'components/ui/layout/layout-v2';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import styles from './styles';

const {
  NoReportDescription,
  NoReportPlaceholder,
  NoReportTitle,
  NoReportContainer,
  TextContainer,
  PlaceholderImageContainer,
} = styles;

export const NoReportsCreatedPlaceholder = (): ReactElement => {
  const { isCreateNewResourceLoading, handleConfirmCreateResource } = useFolderCreateMenuItem({});

  return (
    <PageLayout>
      <NoReportContainer>
        <NoReportPlaceholder>
          <PlaceholderImageContainer
            alt="no report placeholder"
            height={150}
            src={getAssetUrl('v2/empty/no-data-placeholder-big.webp')}
            width={150}
          />
          <TextContainer>
            <NoReportTitle>
              <FormattedMessage id="boards.no_report_title" />
            </NoReportTitle>
            <NoReportDescription>
              <FormattedMessage id="boards.no_report_desc" />
            </NoReportDescription>
          </TextContainer>
        </NoReportPlaceholder>
        <Button
          icon={<IconShell icon={PlusIcon} />}
          loading={isCreateNewResourceLoading}
          type="primary"
          onClick={() => handleConfirmCreateResource()}
        >
          <FormattedMessage id="board.new_board" />
        </Button>
      </NoReportContainer>
    </PageLayout>
  );
};
