import { Input } from 'components/ui/atomic-components';
import { type VisualQueryBasicFilterRuleValue } from 'data/big-query';
import { type ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { type VisualRuleEditRenderCompParams } from './types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const BetweenInput: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  const intl = useIntl();

  const onInputChange = (pos: number, e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const updatedData = [...(data || [[], []])] as [
      VisualQueryBasicFilterRuleValue,
      VisualQueryBasicFilterRuleValue,
    ];

    updatedData[pos] = { value };
    onChange(updatedData);
  };

  return (
    <Wrapper>
      <Input
        placeholder={intl.formatMessage({ id: 'edit_actuals.visual.value_field_placeholder' })}
        value={data?.[0]?.value}
        onChange={(e) => onInputChange(0, e)}
      />

      <FormattedMessage id="and" />

      <Input
        placeholder={intl.formatMessage({ id: 'edit_actuals.visual.value_field_placeholder' })}
        value={data?.[1]?.value}
        onChange={(e) => onInputChange(1, e)}
      />
    </Wrapper>
  );
};
