import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';

export const FormulaTermRegexMap: Record<FormulaBarTypes, RegExp> = {
  [FormulaBarTypes.DatasetExpression]: /dims\.datasets\.[-\w.~%]+\.([-\w.~%]+)\.([-\w.~%]+)/g,
  [FormulaBarTypes.DimensionGroupExpression]: /dims\.dimgroups\.([-\w.~%]+)\.([-\w.~%]+)/g,
  [FormulaBarTypes.DimensionExpression]: /dims\.([-\w.~%]+)\.([-\w.~%]+)/g,
  [FormulaBarTypes.Math]: /()/g,
  [FormulaBarTypes.MetricExpression]: /(metrics\.\w+)(\[(.*?)\])*/g,
  [FormulaBarTypes.Period]: /time\.period/g,
  [FormulaBarTypes.Text]: /dims\.t/g,
  [FormulaBarTypes.LastActualsDate]: /time\.moduleActualsEndDate/g,
  [FormulaBarTypes.ListColumnExpression]: /tbl\.([-\w.~%]+)\.([-\w.~%]+)/g,
  [FormulaBarTypes.DatasetColumnExpression]: /datasets\.[-\w.~%]+\.([-\w.~%]+)\.([-\w.~%]+)/g,
};
