import { type ReactNode, type ReactElement } from 'react';
import styled from 'styled-components';
import { FontXs, FontXxs } from 'styles/typography';

const ItemWrapper = styled.div<{ $selected: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  border: 1px solid
    ${({ theme, $selected }) => ($selected ? theme.colors.primary500 : theme.colors.gray200)};
  background-color: ${({ theme, $selected }) =>
    `${$selected ? theme.colors.primary50 : theme.colors.gray00}`};
  cursor: pointer;
`;

const TitleItem = styled.div`
  ${FontXs}

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.gray600};
`;

const DescriptionItem = styled.div`
  ${FontXxs}

  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textBody2};
`;

export const ListTypeItem = ({
  title,
  description,
  isSelected,
  onClick,
}: {
  title: ReactNode;
  description: ReactNode;
  isSelected: boolean;
  onClick: () => void;
}): ReactElement => {
  return (
    <ItemWrapper $selected={isSelected} onClick={onClick}>
      <TitleItem>{title}</TitleItem>
      <DescriptionItem>{description}</DescriptionItem>
    </ItemWrapper>
  );
};
