import { ReactComponent as TickIcon } from 'assets/v2/circle-check-filled.svg';
import { IconShell, Steps } from 'components/ui/atomic-components';
import { CSVUploadWorkflowStage } from 'data/lists';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;

const StepWrapper = styled.div`
  max-width: 360px;
`;

interface Props {
  currentStage: CSVUploadWorkflowStage;
}

export const ImportSteps = ({ currentStage }: Props): ReactElement => {
  return (
    <Wrapper>
      <StepWrapper>
        <Steps
          current={currentStage === CSVUploadWorkflowStage.ColumnMapping ? 0 : 1}
          items={[
            {
              title: (
                <>
                  1. <FormattedMessage id="planning.lists.upload.set_col_type" />
                </>
              ),
              status: currentStage === CSVUploadWorkflowStage.ColumnMapping ? 'finish' : 'wait',
              icon: <IconShell icon={TickIcon} />,
            },
            {
              title: (
                <>
                  2. <FormattedMessage id="planning.lists.upload.review_data" />
                </>
              ),
              status: currentStage === CSVUploadWorkflowStage.Preview ? 'finish' : 'wait',
              icon: <IconShell icon={TickIcon} />,
            },
          ]}
          size="small"
          type="navigation"
        />
      </StepWrapper>
    </Wrapper>
  );
};
