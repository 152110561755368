import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { FoldersApi } from '../api';
import { StoreKeys } from '../constants';
import { type HierarchialResource, type FolderType } from '../types';

export const useHierarchialResourcesByType = (
  folderType: FolderType,
): UseQueryResult<HierarchialResource[], unknown> => {
  const query = useQuery(
    StoreKeys.SingleRecordByType(folderType),
    () => FoldersApi.findQuery(folderType),
    {
      staleTime: StaleTime.Short,
    },
  );

  return query;
};

export const useInvalidateHierarchialResourcesByType = (
  folderType: FolderType,
): {
  invalidate: () => Promise<void>;
} => {
  const queryClient = useQueryClient();

  const invalidate = () => {
    return queryClient.invalidateQueries(StoreKeys.SingleRecordByType(folderType));
  };

  return { invalidate };
};
