import styled, { css } from 'styled-components';
import { Caption as StyledCaption } from 'styles/typography';

export { Typography } from 'antd';

type CaptionProps = { $muted?: boolean; $active?: boolean };

export const Caption = styled.span<CaptionProps>`
  ${StyledCaption};

  ${({ $muted, $active }) => {
    if ($active) {
      return css`
        color: ${({ theme }) => theme.colors.blue60};
      `;
    } else if ($muted) {
      return css`
        color: ${({ theme }) => theme.colors.textTertiary};
      `;
    }
  }}
`;
