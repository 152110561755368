import { ConfigProvider } from 'antd';
import { type ReactElement, type ReactNode } from 'react';
import { type DefaultTheme, ThemeProvider } from 'styled-components';
import { antdTheme } from 'styles/antd-theme';
import { defaultTheme } from 'styles/theme';

interface Props {
  children?: ReactNode | undefined;
}

export const PorscheThemeWrapper = ({ children }: Props): ReactElement => {
  return (
    <ThemeProvider theme={defaultTheme as unknown as DefaultTheme}>
      <ConfigProvider theme={antdTheme} wave={{ disabled: true }}>
        {children}
      </ConfigProvider>
    </ThemeProvider>
  );
};
