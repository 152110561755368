import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { initializeDataDog } from 'config/data-dog/initialize';
import { AG_GRID_LICENSE, DEPLOY_ENV } from 'config/environment';
import { AppRoutes } from 'pages/auth-routes/routes';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import 'config/polyfills';

const isProd = DEPLOY_ENV === 'production';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

if (isProd) {
  Bugsnag.start({
    apiKey: '39d4549258b87bd8c17877f4474987ea',
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: '39d4549258b87bd8c17877f4474987ea' });

  initializeDataDog();
}

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <AppRoutes />
  </StrictMode>,
);
