import { Input } from 'components/ui/atomic-components';
import { type ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { type VisualRuleEditRenderCompParams } from './types';

export const StringListInput: VisualRuleEditRenderCompParams = ({ data, onChange }) => {
  const intl = useIntl();
  const value = data?.map((item) => item.value).join(',');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const data = value.split(',').map((item) => ({ value: item }));

    onChange(data);
  };

  return (
    <Input
      placeholder={intl.formatMessage({ id: 'edit_actuals.visual.value_field_placeholder' })}
      value={value}
      onChange={onInputChange}
    />
  );
};
