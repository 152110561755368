import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCheckIfInMaintenance = (): void => {
  const { isUnderMaintenance } = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUnderMaintenance) {
      navigate('/under-maintenance');
    }
  }, [isUnderMaintenance, navigate]);
};
