import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type MetricDropdownOption } from 'data/metrics';
import { type FC } from 'react';
import { components, type ControlProps } from 'react-select';
import styled from 'styled-components';

const ControlSearchBar = styled.span`
  color: ${({ theme: { colors } }) => colors.black60};
  cursor: pointer;
  display: flex;
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

type GlobalSearchBoxControl = ControlProps<MetricDropdownOption, false>;

export const GlobalSearchBoxControl: FC<React.PropsWithChildren<GlobalSearchBoxControl>> = (
  props,
) => {
  return (
    components.Control && (
      <components.Control {...props}>
        {props?.children}
        <ControlSearchBar>
          <IconShell icon={SearchIcon} size="lg" />
        </ControlSearchBar>
      </components.Control>
    )
  );
};
