import { SelectOption } from 'components/ui/atomic-components/select';
import { type ReactElement } from 'react';
import { type GroupBase, type OptionProps } from 'react-select';
import { renderDimensionOption } from './utils';
import { type DimensionSelectOption } from './index';

export const OptionComponent = ({
  data,
  label,
  ...rest
}: OptionProps<DimensionSelectOption, true, GroupBase<DimensionSelectOption>>): ReactElement => {
  return (
    <SelectOption data={data} label={label} {...rest}>
      {renderDimensionOption({
        label: String(data.label),
        value: data.value,
        parentSourceDisplayName: data.parentSourceDisplayName || '',
      })}
    </SelectOption>
  );
};
