import axios from 'axios';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { b, br } from 'styles/html-elements';

export const G2_REVIEW_KEY = 'g2-review-key';
export const MAX_VIEW_LIMIT = 1000;
const customerEmailId = 'customer@drivetrain.ai';
const noRewardIdentifier = 'none';

export const getToken = async (): Promise<string> => {
  const data = await axios.get('v1/g2/token').then(({ data }) => data);

  return data;
};

export const getBodyText = (reward: string, isExistingUser: boolean): ReactElement => {
  const emailEl = (): JSX.Element => <a href={`mailto:${customerEmailId}`}>{customerEmailId}</a>;

  if (reward === noRewardIdentifier && isExistingUser) {
    return <FormattedMessage id="g2review.popup.body.re_review.no_reward" values={{ br }} />;
  }
  if (reward && isExistingUser) {
    return (
      <FormattedMessage id="g2review.popup.body.re_review.reward" values={{ b, br, reward }} />
    );
  }
  if (reward && !isExistingUser) {
    return <FormattedMessage id="g2review.popup.body" values={{ b, br, reward, emailEl }} />;
  }

  return <></>;
};

export const generateSrc = (token: string, email: string): string => {
  const url = 'https://www.g2.com/partnerships/Drivetrain/users/login.embed';

  return `${url}?state=${token}&email=${encodeURIComponent(email)}`;
};
