/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { isEmpty } from 'lodash';

export const SystemApi = {
  async postAction({
    classification,
    operation,
    queryParams,
  }: {
    classification: string;
    operation: string;
    queryParams?: Record<string, string>;
  }): Promise<any> {
    const queryArray: string[] = [];

    if (queryParams) {
      Object.keys(queryParams).forEach((k) => {
        if (k !== 'method') {
          queryArray.push(`${k}=${queryParams[k]}`);
        }
      });
    }

    const url = isEmpty(queryArray)
      ? `/v1/system/${classification}/${operation}`
      : `/v1/system/${classification}/${operation}?${queryArray.join('&')}`;

    switch (queryParams?.method) {
      case 'GET': {
        const { data } = await axios.get<any>(url);

        return data;
      }

      case 'POST': {
        const { data } = await axios.post<any>(url);

        return data;
      }

      case 'PUT': {
        const { data } = await axios.put<any>(url);

        return data;
      }

      case 'PATCH': {
        const { data } = await axios.patch<any>(url);

        return data;
      }

      case 'DELETE': {
        const { data } = await axios.delete<any>(url);

        return data;
      }
    }

    const { data } = await axios.post<any>(url);

    return data;
  },
};
