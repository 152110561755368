import { type EditorView } from '@codemirror/view';
import { FormulaEditor } from 'components/ui/atomic-components/formula-editor-v2';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { useAutoCompletionOptions } from './hooks/use-auto-completion-options';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[4]};

  .cm-editor {
    outline: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    box-shadow: ${({ theme }) => theme.shadow.softMicro};
    min-height: 80px;
    max-height: 200px;
    min-width: 360px;
    max-width: 720px;
    resize: both;
    overflow: hidden;
  }

  .cm-tooltip.cm-tooltip-autocomplete > ul {
    min-width: auto;
  }
`;

interface Props {
  formula?: string;
  setEditorView: (editorView: EditorView | null) => EditorView | null;
  onFormulaChange?: (value?: string) => void;
  placeholder: string;
  aiEnabledFormula: boolean;
}

export const ListsCustomFormulaEditor = ({
  formula,
  placeholder,
  setEditorView,
  onFormulaChange,
  aiEnabledFormula,
}: Props): ReactElement => {
  const autoCompletionOptions = useAutoCompletionOptions({ ignoreFunctions: aiEnabledFormula });

  return (
    <Wrapper>
      <FormulaEditor
        autoCompletionOptions={autoCompletionOptions}
        placeholderText={placeholder}
        setEditorView={setEditorView}
        value={formula}
        onChange={onFormulaChange}
      />
    </Wrapper>
  );
};
