import { type ReactElement, type ReactText } from 'react';
import { components, type GroupBase, type OptionProps } from 'react-select';
import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';

export type OptionBase<
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  Props extends unknown = unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
  Value extends any = any,
> = {
  isDisabled?: boolean;
  label?: ReactText | JSX.Element;
  value?: Value;
} & Props;

export type SelectOptionPropsOld<T = OptionBase, IsMulti extends boolean = boolean> = OptionProps<
  T,
  IsMulti,
  GroupBase<T>
> & { $isCustom?: boolean };

const Text = styled.span<{ isSelected: boolean }>`
  ${SingleLineEllipsis};

  display: inline-block;
  flex: 1;

  .descriptive-menu-item-selected-icon {
    display: ${({ isSelected }) => isSelected && 'block'};
  }
`;

export const SelectOptionOld = <T extends OptionBase, IsMulti extends boolean>({
  $isCustom,
  ...props
}: SelectOptionPropsOld<T, IsMulti>): ReactElement => {
  return (
    <components.Option {...props} className={$isCustom ? '' : 'select-option'}>
      <Text isSelected={props.isSelected}>{props.children}</Text>
    </components.Option>
  );
};
