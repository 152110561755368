import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { AppWrapper } from 'components/modules/app-wrapper';
import { Spinner } from 'components/ui/atomic-components/spinner';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { type ReactElement, Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Halo } from './halo';

export const SecureRoute = (): ReactElement => {
  const client = useLDClient();
  const { samlLoginWidget } = useFlags();

  const [hasClientIdentified, setHasClientIdentified] = useState(false);
  const { oktaAuth, authState, _onAuthRequired } = useOktaAuth();

  useEffect(() => {
    client
      ?.identify({
        key: window.location.hostname,
        kind: 'subdomain',
      })
      .then(() => {
        setHasClientIdentified(true);
      });
  }, [client]);

  useEffect(() => {
    if (!authState || !hasClientIdentified || samlLoginWidget) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);

      oktaAuth.setOriginalUri(originalUri);
      _onAuthRequired?.(oktaAuth);
    }
  }, [authState, oktaAuth, _onAuthRequired, samlLoginWidget, hasClientIdentified]);

  if (!hasClientIdentified) {
    return <Spinner />;
  }

  if (!samlLoginWidget && (!authState || !authState?.isAuthenticated)) {
    return <Spinner />;
  }

  return (
    <Halo>
      <AppWrapper>
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </AppWrapper>
    </Halo>
  );
};
