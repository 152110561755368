import { notification } from 'components/ui/atomic-components';
import { DEPLOY_ENV } from 'config/environment';
import { pusher } from 'config/pusher';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

const CHANNEL_NAME = 'updates-channel';
const EVENT_NAME = 'version-update';

export const useVersionUpdates = (): void => {
  const intl = useIntl();

  const notifyUser = useCallback(() => {
    if (DEPLOY_ENV !== 'staging' && DEPLOY_ENV !== 'stagingv2') {
      notification.info({
        message: intl.formatMessage({ id: 'version_update_message' }),
        description: intl.formatMessage({ id: 'version_update_description' }),
        placement: 'bottomLeft',
        duration: 0,
        actions: {
          primaryLabel: intl.formatMessage({ id: 'reload' }),
          primaryAction() {
            window.location.reload();
          },
        },
      });
    }
  }, [intl]);

  useEffect(() => {
    pusher.subscribe(CHANNEL_NAME).bind(EVENT_NAME, notifyUser);

    return () => pusher.unsubscribe(CHANNEL_NAME);
  }, [notifyUser]);
};
