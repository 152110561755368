import { type SegmentedProps as AntSegmentedProps, Segmented as AntSegmented } from 'antd';
import { type FC } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import { segmentedStyles } from './styles';

export type SegmentedProps = Omit<AntSegmentedProps, 'size'> & {
  type?: 'primary' | 'secondary' | 'icon';
  size?: 'extraSmall' | 'small' | 'middle';
};

export const Segmented: FC<React.PropsWithChildren<SegmentedProps>> = styled(
  AntSegmented,
)<SegmentedProps>`
  ${({ theme, ...props }: { theme: DefaultTheme } & SegmentedProps) =>
    segmentedStyles({ theme, ...props })};
`;
