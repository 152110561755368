import axios from 'axios';
import { type ID, Status } from 'data';
import { type DimensionPermissionView, type PermissionList } from './permissions';
import {
  type Role,
  type UpdatedResourcPermissions,
  type UsersAndRoles,
  type ResourcePermissions,
} from './types';

export const RolesApi = {
  async getAllRoles(): Promise<Role[]> {
    const { data } = await axios.get('/v1/rbac/roles');

    return data.roles;
  },

  async getRoleById(roleId: number): Promise<Role & { status: Status }> {
    const { data } = await axios.get(`/v1/rbac/roles/${roleId}`);

    return {
      ...data,
      status: Status.Saved,
    };
  },

  async getAllUsersAndRoles(): Promise<UsersAndRoles> {
    const { data } = await axios.get('/v1/rbac/users-roles');

    return data;
  },

  async getRoleDimensionValues(
    roleId: number,
    dimName: string,
  ): Promise<DimensionPermissionView[]> {
    const { data } = await axios.get(`/v1/rbac/roles/${roleId}/dimensions/${dimName}`);

    return data;
  },

  async getAllUsersAndRolesWithPermissions(
    resourceType: PermissionList,
    resourceId: ID,
  ): Promise<ResourcePermissions> {
    const { data } = await axios.get(
      `/v1/rbac/permissions?resourceType=${resourceType}&resourceId=${resourceId}`,
    );

    return data;
  },

  async createRole(role: Omit<Role, 'id'>): Promise<Role> {
    const { data } = await axios.post<Role>('/v1/rbac/roles', role);

    return data;
  },
  async updateRole(role: Role): Promise<Role> {
    const { data } = await axios.put<Role>(`/v1/rbac/roles/${role.id}`, role);

    return data;
  },

  async updatePermissions(
    updatedUsersAndRolesForResource: UpdatedResourcPermissions,
  ): Promise<boolean> {
    const { data } = await axios.patch('/v1/rbac/permissions', updatedUsersAndRolesForResource);

    return data;
  },

  async removeRole(roleId: ID): Promise<void> {
    const { data } = await axios.delete(`v1/rbac/roles/${roleId}`);

    return data;
  },

  async editRoleName(roleId: ID, newName: string): Promise<void> {
    const { data } = await axios.patch(`v1/rbac/roles/${roleId}`, {
      name: newName,
    });

    return data;
  },
};
