import { PermissionList } from 'data/roles/permissions';
import { type User } from 'data/users';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isArray, memoize } from 'lodash';
import { useUser } from 'utils/hooks/use-user';

export enum Criterion {
  OR = 'OR',
  AND = 'AND',
}
type ReturnType = (
  resource: PermissionList | PermissionList[],
  criterion?: Criterion | keyof typeof Criterion,
) => boolean;

/**
 * NOTE: This is a memoized function that will cache based on type
 * and user Id. useCallback() is bad in this space since it will only deal with
 * `user.permsissions` which is a non clean array object though its
 * values don't change on every invocation.
 */
const hasScope = memoize(
  (type: PermissionList, user: User, flags: { [key: string]: unknown }) => {
    return !!user.permissions.find(
      (permission) => permission.type === type || permission.type === PermissionList.All,
    );
  },
  (type, user) => `${type}-${user.username}`,
);

export const useHasScope = (): ReturnType => {
  const { user } = useUser();
  const flags = useFlags();

  return (types: PermissionList | PermissionList[], criterion = Criterion.OR) => {
    if (isArray(types)) {
      return types
        .map((type) => {
          return hasScope(type, user, flags);
        })
        [criterion === Criterion.OR ? 'some' : 'every']((x) => x);
    }

    return hasScope(types, user, flags);
  };
};
