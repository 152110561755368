import { type AgGridReact } from 'ag-grid-react';
import { AgGrid } from 'components/ui/atomic-components';
import styled from 'styled-components';

const rowHeight = 32;

export default {
  Wrapper: styled.div`
    width: 100%;
    height: calc(100% - 40px);
    position: relative;
  `,
  FooterContainer: styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  GridContainer: styled.div<{
    $noOfRows: number;
    $isHorizontalScrollPresent: boolean;
    $gridWidth: number | null;
  }>`
    height: ${({ $noOfRows, $isHorizontalScrollPresent }) => {
      const extra = $isHorizontalScrollPresent ? 16 : 1;

      return `min(${$noOfRows * rowHeight + extra}px, 100%)`;
    }};
    width: ${({ $gridWidth }) => ($gridWidth ? `min(${$gridWidth}px, 100%)` : '100%')};
    max-width: 100%;
    overflow: hidden;
    display: flex;
  `,
  Grid: styled(AgGrid)<{ $isReadOnly?: boolean }>`
    /* overriding custom properties */
    --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[12]};
    --ag-font-size: ${({ theme }) => theme.fontSize.xs};
    --ag-data-color: ${({ theme: { colors } }) => colors.textTitle2};
    --ag-range-selection-background-color: ${({ theme: { colors } }) => colors.green100};
    --ag-range-selection-border-color: ${({ theme: { colors } }) => colors.green500};
    --ag-range-selection-highlight-color: ${({ theme: { colors } }) => colors.green200};
    --ag-header-column-resize-handle-color: ${({ theme }) => theme.colors.green500};
    --ag-input-focus-border-color: ${({ theme }) => theme.colors.green500};
    --ag-cell-widget-spacing: ${({ theme }) => theme.spacing[4]};
    --ag-selected-row-background-color: ${({ theme }) => theme.colors.bgLight};

    width: 100%;

    .ag-header-cell-moving {
      border-left: 2px solid ${({ theme }) => theme.colors.primary500};
    }

    .ag-row-dragging {
      opacity: 0.8;
    }

    .ag-center-cols-container > .ag-row-dragging {
      border-top: 2px solid ${({ theme }) => theme.colors.primary500};
    }

    .ag-center-cols-container > .ag-row-dragging ~ .ag-row-dragging {
      border-top: none;
    }

    .ag-header-container {
      background: ${({ theme }) => theme.colors.bgMedium};
    }

    .ag-header-cell {
      border-top: 1px solid ${({ theme }) => theme.colors.gray200};
      border-bottom: 1.5px solid transparent;
    }

    .ag-header {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
    }

    .ag-row-inline-editing {
      /* keep row hover styles when a cell inside is being edited */
      ::before {
        background-color: var(--ag-row-hover-color);
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        pointer-events: none;
      }
    }

    .ag-popup-child:not(.ag-tooltip-custom) {
      --ag-popup-shadow: none;
    }

    .ag-cell-range-selected.ag-cell-focus {
      background-color: var(--ag-range-selection-background-color);
      border-color: var(--ag-range-selection-border-color);
    }

    .row-index-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${({ theme }) => theme.colors.gray200};
      border-right: 1px solid ${({ theme }) => theme.colors.gray200};
      padding: ${({ theme: { spacing } }) => `0 ${spacing[8]}`};

      /* prevent cell from focus border */
      border-top: none !important;
      border-bottom: none !important;
      border-left-color: ${({ theme }) => theme.colors.gray200} !important;
      border-right-color: ${({ theme }) => theme.colors.gray200} !important;
      border-bottom-color: transparent !important;
      background-color: transparent !important;

      .ag-cell-wrapper {
        width: 100%;
        justify-content: center;
      }

      .ag-cell-value {
        display: flex;
        justify-content: center;
      }
    }

    .ag-row-last:not(.ag-full-width-row) {
      border-bottom: ${({ $isReadOnly }) => ($isReadOnly ? undefined : 0)};
    }

    .ag-full-width-focus {
      border-color: ${({ theme }) => theme.colors.green500};
      border-top: 1px solid ${({ theme }) => theme.colors.green500};

      .ant-btn {
        border-right: 1px solid ${({ theme }) => theme.colors.green500};
        border-color: ${({ theme }) => theme.colors.green500};

        :active {
          border-color: ${({ theme }) => theme.colors.green500};
        }
      }
    }

    .ag-row-pinned.ag-full-width-row {
      top: -1px;
    }

    .ag-column-last:not(.ag-cell-range-selected, .ag-cell-focus) {
      border-right: none;

      ::before {
        background-color: transparent;
      }
    }

    .dt-list-row-error {
      background: ${({ theme }) => theme.colors.red100};
    }

    .ag-drag-handle.ag-row-drag {
      display: none;
    }

    .ag-row-hover {
      .id-cell {
        display: ${({ $isReadOnly }) => ($isReadOnly ? 'inherit' : 'none')};
      }

      .ag-drag-handle.ag-row-drag:not(.ag-invisible) {
        display: flex;
      }

      .row-selection-cell {
        display: flex;
      }
    }

    .ag-row-selected {
      .ag-drag-handle.ag-row-drag {
        display: flex;
      }
    }

    .ag-group-value,
    .ag-cell-value {
      white-space: pre;
    }

    .id-cell {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .row-selection-cell {
      display: none;
    }

    .search-cell-highlight {
      background-color: ${({ theme }) => theme.colors.yellow100};
    }

    .temp-column,
    .ag-header-cell:has(.ant-dropdown-open, .ant-popover-open),
    .ag-header-cell:has(.ant-dropdown-open, .ant-popover-open):hover {
      --ag-header-cell-hover-background-color: ${({ theme }) => theme.colors.green100};

      border-bottom: 1.5px solid ${({ theme }) => theme.colors.green500};
      color: ${({ theme }) => theme.colors.green600};
      background: ${({ theme }) => theme.colors.green100};
    }

    && .ag-cell-last-left-pinned:not([col-id='row-index']),
    .ag-pinned-left-header .ag-header-cell:last-of-type:not([col-id='row-index']) {
      border-right: 4px solid ${({ theme }) => theme.colors.gray200};
    }
  ` as unknown as typeof AgGridReact,
};
